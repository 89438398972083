import { Component, OnInit } from '@angular/core';
import { SidebarService } from '@modules/shell/services/sidebar.service';
import { UserService } from '@modules/shell/services/user.service';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
    standalone: false
})
export class ShellComponent implements OnInit {
  isSidebarOpen: boolean;
  showMenu: boolean;

  constructor(
    private sidebarService: SidebarService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.sidebarService.isSidebarOpen$.subscribe((sidebarState) => {
      this.isSidebarOpen = sidebarState;
    });

    this.userService.user$.subscribe((user) => {
      this.showMenu = user !== null;
    });
  }
}
