import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { CalculateDto } from '../commons/models/scenario-dto';
import { ApiService } from '../services/api.service';
import { ScenariosService } from '@modules/scenarios/services/scenarios/scenarios.service';
import { EntityTrackerError } from '../commons/models/entity-tracker-error';
import { catchError } from 'rxjs/operators';
import { ROUTENAMES } from '../app-routing.names';

@Injectable({
  providedIn: 'root',
})
export class ScenariosResolver
  implements Resolve<CalculateDto[] | EntityTrackerError>
{
  constructor(
    private apiService: ApiService,
    private scenariosService: ScenariosService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CalculateDto[] | EntityTrackerError> {
    return this.scenariosService.getScenarios().pipe(
      catchError(() => {
        this.router.navigate([ROUTENAMES.UNAUTHORIZED]);
        return EMPTY;
      })
    );
  }
}
