import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { Unspsc, UnspscViewModel } from '../../models/unspsc';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { UnspscHandlerService } from './unspsc-handler.service';
import {
  OrganizationalUnit,
  OrganizationalUnitViewModel,
} from '../../models/organizational-unit';
import { ScenarioEditData } from '../../models/edit-data';
import { TranslateService } from '@ngx-translate/core';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ApiService } from 'src/app/services/api.service';
import { ItemNode } from '../tree-autocomplete/tree-autocomplete.component';
import { CalculatorService } from '@modules/scenarios/services/calculator/calculator.service';
import { CalculatorTableService } from '@modules/scenarios/services/calculator/calculator-table.service';
import { CalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.service';
import { UnspscSingleUnitResponse } from '../../models/unspsc-single-unit-response.model';
import { UnitMethod } from '../../models/unit-method.model';

@Component({
  selector: 'app-unspsc-handler',
  templateUrl: './unspsc-handler.component.html',
  styleUrls: ['./unspsc-handler.component.scss'],
})
export class UnspscHandlerComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() handlerForm: FormGroup;
  @Input() formBuilder: UntypedFormBuilder;
  @Input() editData: ScenarioEditData;
  @Input() formType: string;
  @Input() disabled: boolean = false;

  @Output() formChange = new EventEmitter<boolean>();

  public filteredUnspscOptions: Observable<UnspscViewModel[]>[] = [];
  public filteredUnspscOptionsList: UnspscViewModel[][] = [];

  public filteredOrganizationOptions: Observable<
    OrganizationalUnitViewModel[]
  >[] = [];

  public filteredUnspscs: UnspscViewModel[] = [];
  public filteredOrganizations: OrganizationalUnitViewModel[] = [];
  private handlerFormSubscription: Subscription;

  public isReady = false;

  trendMethod: UnitMethod[];
  simulationMethod: UnitMethod[];

  unspscInputChange: Subject<any> = new Subject<any>();

  removable: boolean = true;
  selectable: boolean = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  formArray: any;

  organizationOptions: ItemNode[] = [];
  organizationOptionsInitialized = false;

  forecastControl$: Subscription;
  adjustmentControl$: Subscription;
  organizationIDControl$: Subscription;
  trendMethodControl$: Subscription;
  simulationMethodControl$: Subscription;
  unitMethodControl$: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    public unspscHandlerService: UnspscHandlerService,
    private translateService: TranslateService,
    public scenarioCalculatorService: CalculatorService,
    private scenariosTableService: CalculatorTableService,
    private templatesTableService: CalculatorTableService,
    private tenantSelectService: TenantSelectService,
    public calculatorHelperService: CalculatorHelperService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.trendMethod = [
      { name: this.translateService.instant('unspsc.methods.linear'), id: '1' },
      {
        name: this.translateService.instant('unspsc.methods.constant'),
        id: '2',
      },
    ];
    this.simulationMethod = [
      {
        name: this.translateService.instant('unspsc.methods.percentage'),
        id: '1',
      },
      {
        name: this.translateService.instant('unspsc.methods.quantity'),
        id: '2',
      },
    ];
    this.unspscHandlerService.unitMethod = [[
      { name: this.tenantSelectService.userCurrency, id: '1' },
    ]];
  }

  ngAfterViewInit(): void {
    this.apiService.getOrganizationsTreeData().subscribe(x => {
      this.organizationOptions = x as ItemNode[];
      this.organizationOptionsInitialized = true;

      this.unspscHandlerService.resetFilteredViewModels();

      let initialArray;

      if (this.editData) {
        this.unspscHandlerService.filteredUnspscViewModels = [
          ...this.editData.unspscs,
          ...this.unspscHandlerService.filteredUnspscViewModels,
        ];

        this.unspscHandlerService.filteredOrganizationViewModels = [
          ...this.editData.unspscs,
          ...this.unspscHandlerService.filteredOrganizationViewModels,
        ];

        this.filteredUnspscs = [
          ...this.getUnspscViewModel(this.editData.unspscs),
        ];

        this.filteredOrganizations = [
          ...this.getOrganizationUnitViewModel(this.editData.unspscs),
        ];

        this.editData.unspscs.forEach((x, formIndex) => {
          if (!this.unspscHandlerService.unitMethod[formIndex]) {
            this.unspscHandlerService.unitMethod[formIndex] = [{ name: this.tenantSelectService.userCurrency, id: '1' }];
          }
        })
      }

      if (this.editData) {
        initialArray = this.initSelect(this.editData?.unspscs);
      } else {
        initialArray = this.initSelect();
      }

      this.handlerForm.addControl('unspscs', initialArray);

      if (this.editData) {
        this.editData.unspscs.forEach(async (el, index) => {
          await this.manageUnspscControl(index);
        });
      } else {
        this.manageUnspscControl(0);
      }

      this.unspscInputChange.pipe(
        debounceTime(1000)
      ).subscribe(async newValue => {
        this.filteredUnspscOptionsList[newValue.formIndex] = await this._filterUnspscs(newValue.text, newValue.formIndex).toPromise();
      });

      this.isReady = true;
      this.cdr.detectChanges();

      this.validateUnspscs();

      this.detectFormArrayChanges();

      this.calculate(this.unspscHandlerService.selectedUnspscs, this.editData ? true : false);
    });
  }

  organizationTextChanged(valid: boolean, formIndex: number) {
    if (!valid) {
      this.formArray.at(formIndex).controls.organizationID.setErrors({ error: true });
    }
  }

  detectFormArrayChanges(): void {
    this.forecastControl$ = merge(...this.unspscs.controls.map(control => control.get('forecastLength').valueChanges))
      .subscribe(x => {
        this.unspscHandlerService.unspscsChange.next(true);
      });

    this.adjustmentControl$ = merge(...this.unspscs.controls.map(control => control.get('adjustment').valueChanges))
      .subscribe(x => {
        this.unspscHandlerService.unspscsChange.next(true);
      });

    this.organizationIDControl$ = merge(...this.unspscs.controls.map(control => control.get('organizationID').valueChanges))
      .subscribe(x => {
        this.unspscHandlerService.unspscsChange.next(true);
      });

    this.trendMethodControl$ = merge(...this.unspscs.controls.map(control => control.get('trendMethod').valueChanges))
      .subscribe(x => {
        this.unspscHandlerService.unspscsChange.next(true);
      });

    this.simulationMethodControl$ = merge(...this.unspscs.controls.map(control => control.get('simulationMethod').valueChanges))
      .subscribe(x => {
        this.unspscHandlerService.unspscsChange.next(true);
      });

    this.unitMethodControl$ = merge(...this.unspscs.controls.map(control => control.get('unitMethod').valueChanges))
      .subscribe(x => {
        this.unspscHandlerService.unspscsChange.next(true);
      });
  }

  initSelect(data = null) {
    this.formArray = this.formBuilder.array([]);
    if (data) {
      data.forEach((el) => {
        //TODO prepare dynamic validator for adjustment whe simulation method is changed
        this.formArray.push(
          this.formBuilder.group({
            organizationID: el.organizationID,
            organizationDescription: el.organizationDescription,
            unspscClassID: [el.unspscClassID],
            unspscClassDescription: el.unspscClassDescription,
            forecastLength: [el.forecastLength || 1, Validators.required],
            trendMethod: [el.trendMethod.toString(), Validators.required],
            simulationMethod: [
              el.simulationMethod.toString(),
              Validators.required,
            ],
            unitMethod: [el.unitMethod.toString(), Validators.required],
            adjustment: [el.adjustment || 0, [Validators.required]],
          })
        );

        const formIndex = this.formArray.length - 1;
        const unspscDescriptions = el.unspscClassDescription.includes(';') ? el.unspscClassDescription.split(';') : [el.unspscClassDescription];
        const unspscIds = el.unspscClassID.includes(',') ? el.unspscClassID.split(',') : [el.unspscClassID];
        this.unspscHandlerService.selectedUnspscs[formIndex] = unspscIds.map((x, i) => <Unspsc>{ unspscClassID: x, unspscClassDescription: unspscDescriptions[i] });

        this.apiService.getUnspscUnit(this.unspscHandlerService.selectedUnspscs[formIndex].map(x => x.unspscClassID)).subscribe((response: UnspscSingleUnitResponse) => {
          if (response?.unit) {
            if (!this.unspscHandlerService.unitMethod[formIndex][1]) {
              this.unspscHandlerService.unitMethod[formIndex].push({ id: '2', name: response.unit } as UnitMethod);
            } else {
              this.unspscHandlerService.unitMethod[formIndex][1] = { id: '2', name: response.unit } as UnitMethod;
            }

            this.calculatorHelperService.unitMethodDropdownReloaded.next(this.unspscHandlerService.unitMethod);
          }
        });
      });
    } else {
      this.formArray.push(
        this.formBuilder.group({
          organizationID: [''],
          organizationDescription: '',
          unspscClassID: [''],
          unspscClassDescription: '',
          forecastLength: [1, Validators.required],
          trendMethod: ['2', Validators.required],
          simulationMethod: ['1', Validators.required],
          unitMethod: ['1', Validators.required],
          adjustment: ['', [Validators.required]],
        })
      );
    }
    return this.formArray;
  }

  calculate(unspscs, isEditMode: boolean) {
    let scenarioValues = Object.assign(
      { calculatorUnspsc: null },
      this.handlerForm.getRawValue()
    );

    this.scenariosTableService.getHistoricalDataFirstYear().then((firstYear: number) => {
      this.calculatorHelperService.firstYear = firstYear;

      if (isEditMode) {
        const form = this.calculatorHelperService.getUnspscsFormValues(scenarioValues, this.unspscHandlerService.selectedUnspscs, 'calculatorUnspsc');
        this.scenariosTableService.resetUnspscTable();
        
        this.scenarioCalculatorService.calculateAndShow(form);
        this.scenarioCalculatorService.areGraphsShown = true;
      }
    });
  }

  onUnspscInputChange(text: any, formIndex: number): void {
    this.unspscInputChange.next({ text: text, formIndex: formIndex });
  }

  addUnspsc() {
    this.unspscHandlerService.unspscsChange.next(true);
    const controls = <FormArray>this.handlerForm.controls['unspscs'];
    let formGroup = this.formBuilder.group({
      organizationID: [''],
      organizationDescription: '',
      unspscClassID: [''],
      unspscClassDescription: '',
      forecastLength: [1, Validators.required],
      trendMethod: ['2', Validators.required],
      simulationMethod: ['1', Validators.required],
      unitMethod: ['1', Validators.required],
      adjustment: ['', [Validators.required, Validators.pattern(/^-?\d+$/)]],
    });

    controls.push(formGroup);

    this.manageUnspscControl(controls.length - 1);

    if (!this.unspscHandlerService.unitMethod[controls.length]) {
      this.unspscHandlerService.unitMethod[controls.length] = [] as UnitMethod[]
    }
    this.unspscHandlerService.unitMethod[controls.length - 1] = [
      { name: this.tenantSelectService.userCurrency, id: '1' },
    ];

    this.detectFormArrayChanges();
  }

  add(event: MatChipInputEvent, formIndex: number): void {
    this.unspscHandlerService.unspscsChange.next(true);
    const value = (event.value || '').trim();

    if (value) {
      this.unspscHandlerService.selectedUnspscs[formIndex]
        .push(<Unspsc>{
          unspscClassID: value,
          unspscClassDescription: this.filteredUnspscs.filter(y => y.unspscClassID == value)[0].unspscClassDescription
        });
    }

    event.chipInput!.clear();

    let arrayControl = this.handlerForm.get('unspscs') as FormArray;

    arrayControl
      .at(formIndex)
      .get('unspscClassID').setValue(null);
  }

  removeUnspsc(i: number) {
    this.unspscHandlerService.unspscsChange.next(true);
    const controls = <FormArray>this.handlerForm.controls['unspscs'];
    if (controls.length >= 2) {
      controls.removeAt(i);
      if (this.unspscHandlerService.selectedUnspscs.at(i)?.length > 0)
        this.unspscHandlerService.selectedUnspscs.splice(i, 1);
      this.filteredUnspscOptions.splice(i, 1);

      // remove deleted sections' unit dropdown values
      if (this.unspscHandlerService.unitMethod.at(i)?.length > 0) {
        this.unspscHandlerService.unitMethod.splice(i, 1);
      }
    }
  }

  unspscDisplayFn(formIndex: number, unspsc: any,): string | undefined {
    if (typeof unspsc == 'string') {
      let displayedValue =
        this.filteredUnspscs.find((el) => el.unspscClassID == unspsc)
          ?.unspscViewDescription || '';
      return unspsc ? displayedValue : undefined;
    } else {
      return this.unspscHandlerService.selectedUnspscs[formIndex]?.join(',');
    }
  }

  organizationDisplayFn(organizationID?): string | undefined {
    let displayedValue =
      this.filteredOrganizations.find((el) => {
        return el.organizationID == organizationID;
      })?.organizationalUnitViewDescription || '';
    return organizationID ? displayedValue : undefined;
  }

  formatLabel(value: number) {
    return value + ' År';
  }

  get unspscs(): FormArray {
    let formarray = this.handlerForm.get('unspscs') as FormArray;
    return formarray;
  }

  getUnspscViewModel(unspscList: Unspsc[]): UnspscViewModel[] {
    return unspscList.map((el) => {
      return {
        unspscClassDescription: el.unspscClassDescription,
        unspscClassID: el.unspscClassID,
        unspscViewDescription: `${el.unspscClassID} ${el.unspscClassDescription}`,
      };
    });
  }

  getOrganizationUnitViewModel(
    organizationUnitList: OrganizationalUnit[]
  ): OrganizationalUnitViewModel[] {
    return organizationUnitList.map((el) => {
      return {
        organizationDescription: el.organizationDescription,
        organizationID: el.organizationID,
        organizationalUnitViewDescription: `${el.organizationID} ${el.organizationDescription}`,
      };
    });
  }

  changeValue() {
    let unspscs = this.handlerForm.value.unspscs;
    this.unspscHandlerService.unspscsLastYear =
      this.unspscHandlerService.getLastYear(unspscs, new Date().getFullYear());
  }

  toggleSelection(option: UnspscViewModel, index: number) {
    this.unspscHandlerService.unspscsChange.next(true);
    option.selected = !option.selected;
    if (option.selected) {
      this.unspscHandlerService.selectedUnspscs[index].push(
        <Unspsc>{
          unspscClassID: option.unspscClassID,
          unspscClassDescription: this.filteredUnspscs.filter(y => y.unspscClassID == option.unspscClassID)[0].unspscClassDescription
        });
    } else {
      const i = this.unspscHandlerService.selectedUnspscs[index].findIndex(value => value === option.unspscClassID);
      this.unspscHandlerService.selectedUnspscs[index].splice(i, 1);
    }

    this.validateUnspscs();

    this.reloadUnitMethodDropdown(index);
  }

  onAutocompleteClose(formIndex: number) {
    this.onUnspscInputChange('', formIndex);
    this.validateUnspscs();

    // this.reloadUnitMethodDropdown(formIndex);
  }

  remove(unspscClassID: string, formIndex: number) {
    this.unspscHandlerService.unspscsChange.next(true);
    const i = this.unspscHandlerService.selectedUnspscs[formIndex].map(x => x.unspscClassID).findIndex(value => value === unspscClassID);
    this.unspscHandlerService.selectedUnspscs[formIndex].splice(i, 1);

    const found = this.filteredUnspscOptionsList[formIndex].filter(value => value.unspscClassID === unspscClassID);
    if (found?.length > 0) {
      found[0].selected = false;
      this.validateUnspscs();
    }

    this.reloadUnitMethodDropdown(formIndex);
  }

  validateUnspscs() {
    for (let i = 0; i < this.unspscs.controls.length; i++) {
      const control = this.unspscs.controls[i].get('unspscClassID');
      if (this.unspscHandlerService?.selectedUnspscs[i]?.length <= 0) {
        control.markAsTouched();
        control.setErrors({ 'required': true });
      } else {
        control.setErrors(null);
      }
    }
  }

  unspcsSearchPlaceholder(): string {
    return this.disabled ? '' : this.translateService.instant('unspsc.methods.constant');
  }

  onTouch(): void {
    this.formChange.emit(true);
  }

  private async manageUnspscControl(index: number) {
    let arrayControl = this.handlerForm.get('unspscs') as FormArray;

    this.filteredUnspscOptionsList[index] = await this._filterUnspscs('', index).toPromise();

    this.filteredOrganizationOptions[index] = arrayControl
      .at(index)
      .get('organizationID')
      .valueChanges.pipe(
        debounceTime(1000),
        startWith<string | OrganizationalUnit>(''),
        map((value) => {
          return typeof value === 'string' ? value : value.organizationID;
        }),
        switchMap((organization) => {
          return this._filterOrganizations(organization);
        })
      );
  }

  private reloadUnitMethodDropdown(index: number): void {
    this.apiService.getUnspscUnit(this.unspscHandlerService.selectedUnspscs[index].map(x => x.unspscClassID)).subscribe((response: UnspscSingleUnitResponse) => {
      if (response?.unit) {
        if (!this.unspscHandlerService.unitMethod[index][1]) {
          this.unspscHandlerService.unitMethod[index].push({ id: '2', name: response.unit } as UnitMethod);
        } else {
          this.unspscHandlerService.unitMethod[index][1] = { id: '2', name: response.unit } as UnitMethod;
        }
      } else if (this.unspscHandlerService.unitMethod[index][1]) {
        this.unspscHandlerService.unitMethod[index].splice(1, 1);

        let arrayControl = this.handlerForm.get('unspscs') as FormArray;

        arrayControl
          .at(index)
          .get('unitMethod').setValue('1');
      }
    });
  }

  private _filterUnspscs(unspsc: string, formIndex: number): Observable<UnspscViewModel[]> {
    let filterValue = unspsc.toLowerCase();

    return this.unspscHandlerService
      .searchUnspsc({ likeValue: filterValue, searchValue: 1 })
      .pipe(
        switchMap((el) => {
          el.forEach(x => {
            if (this.unspscHandlerService.selectedUnspscs[formIndex]) {
              x.selected = this.unspscHandlerService.selectedUnspscs[formIndex].map(y => y.unspscClassID).indexOf(x.unspscClassID) > -1;
            } else {
              this.unspscHandlerService.selectedUnspscs[formIndex] = [];
            }
          });
          this.filteredUnspscs = [...this.filteredUnspscs, ...el];
          return of(el);
        })
      );
  }

  private _filterOrganizations(
    organization: string
  ): Observable<OrganizationalUnitViewModel[]> {
    let filterValue = organization.toLowerCase();

    return this.unspscHandlerService
      .searchOrganization({ likeValue: filterValue, searchValue: 1 })
      .pipe(
        switchMap((el) => {
          this.filteredOrganizations = [...this.filteredOrganizations, ...el];
          return of(el);
        })
      );
  }

  ngOnDestroy() {
    this.unspscHandlerService.resetUnspscLastYear();

    if (this.formType == 'scenarios') {
      this.scenariosTableService.resetUnspscTable();
    }

    if (this.formType == 'templates') {
      this.templatesTableService.resetUnspscTable();
    }

    this.handlerFormSubscription?.unsubscribe();

    this.unspscHandlerService.selectedUnspscs = [[]];

    this.forecastControl$?.unsubscribe();
    this.adjustmentControl$?.unsubscribe();
    this.organizationIDControl$?.unsubscribe();
    this.trendMethodControl$?.unsubscribe();
    this.simulationMethodControl$?.unsubscribe();
    this.unitMethodControl$?.unsubscribe();
  }
}
