import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { StatusDto } from '../../../../commons/models/status-dto';
import { Observable } from 'rxjs';
import { EntityTrackerError } from '../../../../commons/models/entity-tracker-error';
import { ITemplatesService } from '@modules/scenarios/services/templates/templates.interface';
import { CalculatorTableService } from '../calculator/calculator-table.service';
import { CalculateDto } from 'src/app/commons/models/scenario-dto';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService implements OnDestroy, ITemplatesService {
  statuses: StatusDto[];

  constructor(
    public apiService: ApiService,
    public templatesTableService: CalculatorTableService
  ) {}

  ngOnDestroy(): void {}

  getTemplates(): Observable<CalculateDto[] | EntityTrackerError> {
    return this.apiService.getTemplates();
  }

  calculateChanges(templates: CalculateDto[]) {
    let calculationsByTemplate: {
      calculationId: number;
      emissionChange: number;
      expenseChange: number;
    }[] = [];
    templates.map((template) => {
      let emissionChange: number = 0,
        expenseChange: number = 0;

      template.calculations.map((yearCalc) => {
        emissionChange = emissionChange + yearCalc.emissionChange;

        expenseChange = expenseChange + yearCalc.lineAmountChange;
      });

      calculationsByTemplate.push({
        calculationId: template.calculationId,
        emissionChange: Math.round(emissionChange),
        expenseChange: Math.round(expenseChange),
      });
    });

    return calculationsByTemplate;
  }
}
