import { Injectable, OnDestroy } from '@angular/core';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { BehaviorSubject, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import {
  EmbedTokenInfo,
  ReportData,
} from '../../../commons/models/powerbi-model';
import { PowerBiReport } from './power-bi-report';
import { PbiFilterTranslation } from 'src/app/commons/models/report/pbi-filter-translation.model';
import { GetParamsService } from 'src/app/services/get-params.service';
import { PreviousRouteService } from 'src/app/commons/services/previous-route.service';
import { InformationDialogService } from '@modules/shell/services/information-dialog.service';
import * as pbi from 'powerbi-client';
import { environment } from '../../../../environments/environment';

export enum ReportSource {
  PBISTATE,
  LOCALSTORAGE,
  DEFAULT,
}

export enum CustomerSwitch {
  AVAILABLE,
  DISABLED, loadReportFromLocalStorage
}

export enum ReportDropdown {
  AVAILABLE,
  DISABLED,
}

export enum BookmarksDropdown {
  AVAILABLE,
  DISABLED,
}

// There are 3 triggers to render the report - the customer switch, report dropdown, bookmarks dropdown.
// When we log in as a crossCustomerViewer then we can switch customers otherwise we are not able to do so.
// When there is more than one report we need to be able to switch the reports on dropdown change.
// Additionally, bookmarks can make a change. Bookmarks can be either loaded from backend or provided from the url (either pre login and post login)
// so we need to be able to handle all of these situations

// In terms of loading the report, at first we request default report from the api based on the user email and tenant.
// Together with the default report we receive the list of reports available.
// Additionally, we fetch bookmarks. Reports can be exported to PDF thanks to hosted service running in the background.

@Injectable({
  providedIn: 'root',
})
export class PbiReportService implements OnDestroy {
  report: pbi.Report;
  reportObj!: PowerBIReportEmbedComponent;
  reportConfig: IReportEmbedConfiguration;
  currentTimeout: NodeJS.Timeout;

  public refreshTokenSubscription: Subscription;

  public currentPbiState: string = '';
  public pbiReportId: string;
  public workspaceId: string;
  public description: string;
  public userReports: Subject<ReportData[]> = new BehaviorSubject<ReportData[]>(
    [{ pbiReportId: '', workspaceId: '', description: '' }]
  );
  userReports$: Observable<ReportData[]> = this.userReports.asObservable();
  reportSource: ReportSource;

  reportInitialized = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService, private pbiReport: PowerBiReport, private getParamsService: GetParamsService,
    private previousRouteService: PreviousRouteService, private informationDialogService: InformationDialogService) { }

  getDefaultReport() {
    return this.apiService.getDefultReport() as Observable<ReportData>;
  }

  getSpecificReport(workspaceId, reportId) {
    return this.apiService.getSpecificReport(workspaceId, reportId);
  }

  ngOnDestroy(): void {
    clearTimeout(this.currentTimeout);
  }

  public loadReport(embedTokenInfo: EmbedTokenInfo, lang: string, loadFromReportSelect: boolean = false) {
    const previousQeryParams = this.previousRouteService.getCurrentUrlParams();
    const previousUrl = this.previousRouteService.getCurrentUrl();
    if (previousQeryParams?.hasOwnProperty('reportId')) {
      this.loadReportFromLocalStorage(previousUrl, lang);
      return;
    }

    const currentUrl = window.location.href;
    const currentQueryParams = this.getParamsService.getParamsFromUrl(currentUrl);

    if (currentQueryParams?.hasOwnProperty('reportId') && !loadFromReportSelect) {
      this.loadReportFromLocalStorage(currentUrl, lang);
      return;
    }

    this.setConfig(embedTokenInfo, lang);
  }

  loadReportFromLocalStorage(bookmarkPath: string, lang: string) {
    const searchParams = this.getParamsService.getParamsFromUrl(bookmarkPath);
    const reportId = searchParams.reportId;
    const workspaceId = searchParams.workspaceId;

    this.getSpecificReport(workspaceId, reportId)
      .subscribe((embedTokenInfo: ReportData) => {
        this.setReportsMetaData(
          reportId,
          workspaceId,
          embedTokenInfo.description
        );
        this.setConfig(embedTokenInfo.embedTokenInfo, lang);
      }, error => { this.informationDialogService.showCommonHttpError(error) });
  }

  loadReportFromUrl(bookmarkPath: string) {
    const searchParams = this.getParamsService.getParamsFromUrl(bookmarkPath);
    const reportId = searchParams.reportId;
    const workspaceId = searchParams.workspaceId;
    const pbiState = decodeURIComponent(searchParams.pbiState);

    this.getSpecificReport(workspaceId, reportId)
      .subscribe((embedTokenInfo: ReportData) => {
        this.setReportsMetaData(
          reportId,
          workspaceId,
          embedTokenInfo.description
        );

        this.report.bookmarksManager.applyState(pbiState);
      }, error => { this.informationDialogService.showCommonHttpError(error) });
  }

  refreshToken(lang: string, renew: boolean = false) {
    if (this.refreshTokenSubscription) {
      this.refreshTokenSubscription.unsubscribe();
    }
    this.refreshTokenSubscription = interval(
      environment.powerBiTokenRenewalIntervalMs
    ).subscribe((x) => {
      this.getReport(lang, renew);
    });
  }

  getReport(lang: string, renew: boolean = false): void {
    this.getDefaultReport().subscribe((result: ReportData) => {
      this.userReports$ = of(result.reports);
      if (result) {
        this.pbiReportId = result.pbiReportId;
        this.workspaceId = result.workspaceId;
      }

      if (renew) {
        this.renewAccessToken(result.embedTokenInfo.token);
      } else {
        result.embedTokenInfo
        this.loadReport(result.embedTokenInfo, lang);
      }
    }, error => { this.informationDialogService.showCommonHttpError(error) });
  }

  private renewAccessToken(accessToken: string) {
    this.report.setAccessToken(accessToken);
  }

  setReportsMetaData(
    pbiReportId: string,
    workspaceId: string,
    description: string
  ) {
    this.pbiReportId = pbiReportId;
    this.workspaceId = workspaceId;
    this.description = description;
  }

  async resetFilters() {
    await this.pbiReport.resetReportFilters(this.report).then(x => {
      this.report = x;
      this.pbiReport.filterValues = [];
    });
  }

  public getColumnNameDisplayValue(columnKeys: string[]): Observable<PbiFilterTranslation> {
    return this.apiService.getPbiFilterNameTranslation(this.pbiReportId, columnKeys);
  }

  private setConfig(embedTokenInfo: EmbedTokenInfo, lang: string) {
    this.reportConfig = {
      type: 'report',
      embedUrl: embedTokenInfo.embedUrl,
      tokenType: models.TokenType.Embed,
      accessToken: embedTokenInfo.token,
      settings: <pbi.IEmbedSettings>{
        navContentPaneEnabled: false,
        localeSettings: {
          language: lang
        } as pbi.ILocaleSettings
      },
    } as IReportEmbedConfiguration;
  }

  clearTimeout(timeout: NodeJS.Timeout) {
    if (timeout) {
      clearTimeout(timeout);
    }
  }
}