import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '@modules/shell/services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateHelperService {
  public langList = ['da', 'en', 'no'];
  public defaultLang = 'da';

  constructor(
    private translateService: TranslateService,
    private spinnerService: SpinnerService
  ) {
    translateService.addLangs(this.langList);
    translateService.setDefaultLang(this.defaultLang);
    translateService.onLangChange.subscribe((e) => {
      this.spinnerService.addSpinner();

      setTimeout(() => {
        this.spinnerService.removeSpinner();
      }, 750);
    });
  }
}
