import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FiscalYearService } from '@modules/customers-csrd/services/fiscal-years/fiscal-years.service';
import { FormService } from '@modules/customers-csrd/services/fiscal-years/form.service';
import { UserService } from '@modules/shell/services/user.service';
import { Subscription } from 'rxjs';
import { CreateEditDialogComponent } from 'src/app/commons/components/create-edit-dialog/create-edit-dialog.component';
import { DialogResultStatus } from 'src/app/commons/existing-record-dialog/dialog-result-status';
import { FiscalYear } from 'src/app/commons/models/fiscal-year.model';
import { UniqueEntityValidationResponse } from 'src/app/commons/models/product-specific-emission-dto';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
    selector: 'app-fiscal-year-dialog',
    templateUrl: './fiscal-year-dialog.component.html',
    styleUrls: ['./fiscal-year-dialog.component.scss'],
    standalone: false
})
export class FiscalYearDialogComponent extends CreateEditDialogComponent implements OnInit, AfterViewChecked, OnDestroy {
  formInitializationSubscription: Subscription;

  unspscsOptionsInitialized: boolean = false;
  unitOptionsInitialized: boolean = false;
  supplierOptionsInitialized: boolean = false;
  overlappingDatesErrorMessage: string = 'TODO';
  periodChanged: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    dialogRef: MatDialogRef<any>,
    public formService: FormService,
    dialogService: DialogService,
    userService: UserService,
    private cdRef: ChangeDetectorRef,
    fiscalYearService: FiscalYearService) {
    super(editData,
      fiscalYearService,
      formService,
      dialogRef,
      dialogService,
      userService)
  }

  ngOnInit(): void {
    this.formService.form.get('StartDate').valueChanges.subscribe(() => {
      this.periodChanged = true;
    });

    this.formService.form.get('EndDate').valueChanges.subscribe(() => {
      this.periodChanged = true;
    });
  }

  add(recordToUnsetDefault?: FiscalYear, ignoreOverlapping: boolean = false, ignoreDefaultExistence: boolean = false): void {
    let uniqueKey = this.formService.getUniqueKeyModel();
    uniqueKey.ignoreOverlapping = ignoreOverlapping;
    uniqueKey.ignoreDefaultExistence = ignoreDefaultExistence;

    this.dataService.getByUniqueKey(uniqueKey)
      .subscribe((response: UniqueEntityValidationResponse<FiscalYear>) => {
        if (response.isValid) {
          // add new record and remove the default flag from the original one
          if (recordToUnsetDefault) {
            recordToUnsetDefault.defaultYear = false;
            this.dataService.update(recordToUnsetDefault).subscribe(() => {
              let createValues = this.formService.prepareCreateValues();
              this.dataService.add(createValues).subscribe(() => {
                this.dialogRef.close(DialogResultStatus.CREATE);
              });
            });
          } else {
            // just add a new record
            let createValues = this.formService.prepareCreateValues();
            this.dataService.add(createValues).subscribe(() => {
              this.dialogRef.close(DialogResultStatus.CREATE);
            });
          }
        } else {
          this.dialogService.editExistingRecordDialog(response.errorMessage, false, true, response.isOverlapping)
            .afterClosed()
            .subscribe((res) => {
              // user decided to ignore existence of a default record, remove its default flag and create a new record with default flag instead
              if (!response.isOverlapping && res === DialogResultStatus.YES && uniqueKey.defaultYear) {
                this.add(response.data, ignoreOverlapping, true); // still check if it there's no overlapping periods, but remember to delete default flag from the record
              } // user decided to ignore overlapping records and create a record 
              else if (response.isOverlapping && res === DialogResultStatus.YES) {
                this.add(recordToUnsetDefault, true, ignoreDefaultExistence); // still check if it there's no already existing default record, if default chosen
              }
              // user decided to do not add the record at all
              if (res === DialogResultStatus.ABORT) {
                this.dialogRef.close(DialogResultStatus.CANCEL);
              }
            });
        }
      });
  }

  edit(recordToUnsetDefault?: FiscalYear, ignoreOverlapping: boolean = false, ignoreDefaultExistence: boolean = false): void {
    let uniqueKey = this.formService.getUniqueKeyModel();
    uniqueKey.ignoreOverlapping = ignoreOverlapping;
    uniqueKey.ignoreDefaultExistence = ignoreDefaultExistence;

    this.dataService.getByUniqueKey(uniqueKey)
      .subscribe((response: UniqueEntityValidationResponse<FiscalYear>) => {
        if (response.isValid) {
          // update the record and remove the default flag from the original one
          if (recordToUnsetDefault) {
            recordToUnsetDefault.defaultYear = false;
            this.dataService.update(recordToUnsetDefault).subscribe(() => {
              let updateValues = this.formService.prepareUpdateValues(this.editData.id);
              this.dataService.update(updateValues).subscribe(() => {
                this.dialogRef.close(DialogResultStatus.UPDATE);
              });
            });
          } else {
            // just update the record
            let updateValues = this.formService.prepareUpdateValues(this.editData.id);
            this.dataService.update(updateValues).subscribe(() => {
              this.dialogRef.close(DialogResultStatus.UPDATE);
            });
          }
        } else {
          this.dialogService.editExistingRecordDialog(response.errorMessage, false, true, response.isOverlapping)
            .afterClosed()
            .subscribe((res) => {
              // user decided to ignore existence of a default record, remove its default flag and create a new record with default flag instead
              if (!response.isOverlapping && res === DialogResultStatus.YES && uniqueKey.defaultYear) {
                this.edit(response.data, ignoreOverlapping, true); // still check if it there's no overlapping periods, but remember to delete default flag from the record
              } // user decided to ignore overlapping records and create a record 
              else if (response.isOverlapping && res === DialogResultStatus.YES) {
                this.edit(recordToUnsetDefault, true, ignoreDefaultExistence); // still check if it there's no already existing default record, if default chosen
              }

              // user decided to do not update the record at all
              if (res === DialogResultStatus.ABORT) {
                this.dialogRef.close(DialogResultStatus.CANCEL);
              }
            });
        }
      });
  }

  ctaScenario(): void {
    if (this.formService.form.valid) {
      if (!this.editData) {
        this.add();
      } else {
        this.edit(null, !this.periodChanged, false);
      }
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.formService.resetEditData();
    this.formService.resetForm();
    this.formService.resetProductDataChange();

    if (this.formInitializationSubscription)
      this.formInitializationSubscription.unsubscribe();
  }
}
