import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ExportDto } from '../../../../commons/models/export-dto';
import { PBIReportExportService } from '@modules/report/services/pbi-report-export.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HttpResponse } from '@angular/common/http';
import { ActivityEventType, UserActivity } from 'src/app/commons/models/activity.model';
import { UserService } from '@modules/shell/services/user.service';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
    selector: 'app-pdf-order-list',
    templateUrl: './pdf-order-list.component.html',
    styleUrls: ['./pdf-order-list.component.scss'],
    standalone: false
})
export class PdfOrderListComponent implements OnInit {
  displayedColumns: string[] = [
    'reportName',
    'orderDate',
    'status',
    'fileDownload',
  ];
  dataSource: ExportDto[];

  constructor(
    private exportService: PBIReportExportService,
    public dialogRef: MatDialogRef<PdfOrderListComponent>,
    public translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private activityService: ActivityService,
    private userService: UserService
  ) {
    this.loadData();
  }

  ngOnInit(): void { }

  private loadData() {
    this.exportService.pBIReportExportGetExportList().subscribe((result) => {
      this.dataSource = result as ExportDto[];
    });
  }

  downloadFile(element) {
    this.exportService
      .pBIReportExportGetFile(element)
      .subscribe((response: HttpResponse<Blob>) => {
        const blob = new Blob([response.body], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank');
        this.activityService.sendActivity(new UserActivity('PDF Export downloaded', this.userService.currentUser.email ?? this.userService.currentUser.username, ActivityEventType.PdfExportDownloaded));
        this.exportService
          .pBIReportExportGetExportList()
          .subscribe((result) => {
            this.dataSource = result as ExportDto[];
            this.cdr.detectChanges();
          });
      });
  }

  close() {
    this.dialogRef.close('cancel');
  }
}
