import { Injectable, OnDestroy } from '@angular/core';
import { UserInfo } from '../commons/models/user-info';

export enum ViewerType {
  multiTenantViewer = 'MultiTenantViewer',
  singleTenantViewer = 'SingleTenantViewer',
}

@Injectable({
  providedIn: 'root',
})
export class AdminService implements OnDestroy {
  public isCrossCustomerViewer = false;
  public ScenarioTemplateCreator = false;
  public isSystemAdministrator = false;
  public CustomerAdministrator = false;
  public CustomerUser = false;
  public CustomerUserExternal = false;
  public selectedIdentityProvider: string = null;
  public viewerType;

  constructor() {}

  // reset user when the browser is reloaded
  resetUser() {
    this.isCrossCustomerViewer = false;
    this.ScenarioTemplateCreator = false;
    this.isSystemAdministrator = false;
    this.CustomerAdministrator = false;
    this.CustomerUser = false;
    this.CustomerUserExternal = false;
    this.selectedIdentityProvider = null;
  }

  // set the viewer type - to get the cases where
  // user can see one or more tenants databases
  setViewerType(userInfo: UserInfo) {
    if (userInfo.permissions.crossCustomerViewer) {
      this.viewerType = ViewerType.multiTenantViewer;
    } else {
      this.viewerType = ViewerType.singleTenantViewer;
    }
  }

  ngOnDestroy(): void {
    this.resetUser();
  }
}
