import { AfterViewInit, Component } from '@angular/core';
import { CalculatorFormService } from '@modules/scenarios/services/calculator/calculator-form.service';
import { CalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.service';
import { CalculatorTableService } from '@modules/scenarios/services/calculator/calculator-table.service';
import { CalculatorService } from '@modules/scenarios/services/calculator/calculator.service';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { UnitMethod } from 'src/app/commons/models/unit-method.model';

@Component({
  selector: 'app-calculations',
  templateUrl: './calculations.component.html',
  styleUrls: ['./calculations.component.scss']
})
export class CalculationsComponent implements AfterViewInit {
  allUnitMethodsUsesSameUnit = false;

  constructor(public calculatorHelperService: CalculatorHelperService,
    public calculatorTableService: CalculatorTableService,
    public calculatorService: CalculatorService,
    public tenantSelectService: TenantSelectService,
    public calculatorFormService: CalculatorFormService) {
  }

  ngAfterViewInit(): void {
    this.calculatorHelperService.unitMethodDropdownReloaded.subscribe(x => {
      if (this.calculatorFormService.form.value?.unspscs) {
        this.checkIfAllUnitMethodsAreSame(this.calculatorFormService.form.value?.unspscs, x);
      }
    });
  }

  checkIfAllUnitMethodsAreSame(unspscsData, unitMethod: UnitMethod[][]): void {
    let tempArray = [];

    unspscsData.filter(x => x.unitMethod == '2').forEach((el, index) => {
      if (unitMethod[index]) {
        const item = unitMethod[index][1]?.name;
        if (item)
          tempArray.push(item);
      }
    });

    this.allUnitMethodsUsesSameUnit = tempArray.every((val, i, arr) => val === arr[0]);
  }
}
