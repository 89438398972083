import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityTrackerError } from 'src/app/commons/models/entity-tracker-error';
import { PageResponse } from 'src/app/commons/models/page-response';
import { ApiService } from 'src/app/services/api.service';
import { CurrentLanguageService } from 'src/app/services/current-language.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersOwnDataCsvService {

  constructor(private apiService: ApiService, private currentLanguageService: CurrentLanguageService) {}

  importCsv(fileName: string): Observable<PageResponse | EntityTrackerError> {
    return this.apiService.importCodCsv(fileName, this.currentLanguageService.lang);
  }
}
