import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Chart, ChartItem } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { DK } from '../../../../constants/config.constants';
import { UnspscHandlerService } from 'src/app/commons/components/unspsc-handler/unspsc-handler.service';
import { CalculatorService } from '@modules/scenarios/services/calculator/calculator.service';
import { CalculatedGraphData, GraphData } from 'src/app/commons/models/calculated-graph.data';
import { CalculatorTableService } from '@modules/scenarios/services/calculator/calculator-table.service';

@Component({
    selector: 'app-scenario-calculator',
    templateUrl: './scenario-calculator.component.html',
    styleUrls: ['./scenario-calculator.component.scss'],
    standalone: false
})
export class ScenarioCalculatorComponent implements OnInit, AfterViewInit {
  @Input() graphName: string;
  @ViewChild('graph') graph!: ElementRef;
  public chart: Chart;

  unitTypes = {};

  constructor(
    public calculatorService: CalculatorService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private tenantSelectService: TenantSelectService,
    private unspscHandlerService: UnspscHandlerService,
    public calculatorTableService: CalculatorTableService
  ) {
    this.calculatorService.graphInit.subscribe({
      next: (value) => {
        this.showGraph(value);
      },
    });

    this.unitTypes = {
      expense: (this.tenantSelectService.userLanguageCurrency == DK
        ? '[DKK]'
        : '[NOK]'),
      emission: "KG",
      quantity: ''
    }
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.unspscHandlerService.unspscsChange.subscribe(() => {
      let graph = {} as CalculatedGraphData;
      graph[this.graphName] = {
        historicalPoints: [],
        trendPoints: [],
        simulationPoints: [],
        factorTrendPoints: []
      } as GraphData;
      this.showGraph(graph);
    });
  }

  showGraph(calculatedGraphData: CalculatedGraphData): void {
    const ctx = this.graph?.nativeElement as ChartItem;

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: [
          {
            type: 'scatter',
            label: this.translateService.instant(
              'scenarios.graphs.actual-' + this.graphName
            ),
            backgroundColor: 'rgba(233, 91, 39, 1)',
            borderColor: 'rgba(233, 91, 39, 1)',
            borderWidth: 0.5,
            pointRadius: 4,
            pointHoverRadius: 9,
            pointStyle: 'circle',
            pointBorderWidth: 1,
            pointBackgroundColor: 'rgba(233, 91, 39, 1)',
            pointHoverBackgroundColor: 'rgba(233, 91, 39, 1)',
            data: calculatedGraphData[this.graphName].historicalPoints,
          },
          {
            type: 'line',
            label: this.translateService.instant('scenarios.graphs.trend'),
            data: calculatedGraphData[this.graphName].trendPoints,
            backgroundColor: 'rgba(142, 196, 133, 1)',
            borderColor: 'rgba(142, 196, 133, 1)',
            pointRadius: 4,
            pointHoverRadius: 9,
            borderWidth: 1,
          },
          {
            type: 'line',
            label: this.translateService.instant('scenarios.graphs.simulation'),
            data: calculatedGraphData[this.graphName].simulationPoints,
            backgroundColor: 'rgba(0, 91, 145, 1)',
            borderColor: 'rgba(0, 91, 145, 1)',
            pointRadius: 4,
            pointHoverRadius: 6,
            borderWidth: 1,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US').format(
                    context.parsed.y
                  );
                }
                return label;
              },
            },
          },
        },
        scales: {
          x: {
            suggestedMin: 2019,
            suggestedMax: 2027,
            ticks: {
              callback: (tickValue) => tickValue,
              stepSize: 1,
            },
            title: {
              display: true,
              text: this.translateService.instant('scenarios.graphs.years'),
            },
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            // min: 0,
            title: {
              display: true,
              text:
                this.translateService.instant('scenarios.graphs.' + this.graphName) +
                ' ' +
                this.unitTypes[this.graphName],
            },
          },
        },
      },
    });

    this.cdr.detectChanges();
  }
}
