import { CreateEditCodDialogComponent } from "@modules/customers-own-data/components/create-edit-cod-dialog/create-edit-cod-dialog.component";
import { DataViewType } from "./data-view-type.enum";
import { CreateEditCapDialogComponent } from "@modules/customers-average-price/components/create-edit-cap-dialog/create-edit-cap-dialog.component";
import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { CreateEditPseDialogComponent } from "@modules/product-specific-emissions/components/create-edit-pse-dialog/create-edit-pse-dialog.component";
import { CreateEditExclusionDialogComponent } from "@modules/customers-own-data-exclusion/components/create-edit-exclusion-dialog/create-edit-exclusion-dialog.component";
import { FiscalYearDialogComponent } from "@modules/customers-csrd/components/fiscal-year-dialog/fiscal-year-dialog.component";
import { MeasuringPointDialogComponent } from "@modules/customers-csrd/components/measuring-point-dialog/measuring-point-dialog.component";

@Injectable({
    providedIn: 'root',
})
export class CreateEditDialogFactory {
    public getInstance(dialogComponentType: DataViewType): ComponentType<any> {
        if (dialogComponentType == DataViewType.CUSTOMERS_AVERAGE_PRICE) {
            return CreateEditCapDialogComponent;
        }
        if (dialogComponentType == DataViewType.CUSTOMERS_OWN_DATA) {
            return CreateEditCodDialogComponent;
        }
        if ([DataViewType.PSE_IN_USE, DataViewType.PSE_CANDIDATES, DataViewType.PSE_CAN_BE_USED, DataViewType.PSE_THE_REST].includes(dialogComponentType)) {
            return CreateEditPseDialogComponent;
        }
        if (dialogComponentType == DataViewType.CUSTOMERSOWNDATAEXCLUSION) {
            return CreateEditExclusionDialogComponent;
        }
        if (dialogComponentType == DataViewType.CSRD_FISCAL_YEARS) {
            return FiscalYearDialogComponent;
        }
        if (dialogComponentType == DataViewType.CSRD_MEASURING_POINTS) {
            return MeasuringPointDialogComponent;
        }

        console.error('Unable to resolve type for the dialog component.')
        return null;
    }
}