<div mat-dialog-content style="display: inline-flex; width: 100%">
  <div style="width: 6%;">
    <mat-icon fontIcon="error_outline"></mat-icon>
  </div>
  <div style="width: 94%;">
    <h1>
      <div style="margin-left: 0.5rem">{{ data.message || 'general.tenant-change-info' | translate }}</div>
    </h1>
  </div>
</div>
<div class="text-center" style="margin-bottom: 0.5rem">
  <button mat-raised-button color="primary" [mat-dialog-close]="false">
    {{ 'general.close' | translate }}
  </button>
</div>