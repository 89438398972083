import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PreviousRouteService {
  keyName = 'previousUrl';
  paramsKeyName = 'previousUrlParams';

  constructor(private localStorageService: LocalStorageService) { }

  saveCurrentUrl(currentUrl: string, params: Params): void {
    if (currentUrl) {
      this.localStorageService.addOrUpdateItem(this.keyName, currentUrl);
      this.localStorageService.addOrUpdateItem(this.paramsKeyName, JSON.stringify(params));
    }
  }

  getCurrentUrl(): string {
    return this.localStorageService.getItem(this.keyName)?.value;
  }

  getCurrentUrlParams(): Params {
    return this.localStorageService.getObject(this.paramsKeyName) as Params;
  }

  clearData(): void {
    this.localStorageService.removeItem(this.keyName);
    this.localStorageService.removeItem(this.paramsKeyName);
  }
}