import { Injectable } from '@angular/core';
import { Tenant, UserInfo } from '../../../../commons/models/user-info';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminService, ViewerType } from '../../../../services/admin.service';
import { Router } from '@angular/router';
import { SnackbarService } from '../../../../commons/services/snackbar.service';
import { Location } from '@angular/common';
import { DK, NOK } from '../../../../constants/config.constants';

@Injectable({
  providedIn: 'root',
})
export class TenantSelectService {
  public tenantHandlerForm!: FormGroup;
  public tenants: Tenant[] = [];
  public selectChanges: Subscription;
  public selectTenantState$: Observable<string>;
  public idp: string;
  public userIdp: string;
  public userCurrency: string;
  public userLanguageCurrency: string;
  public tenantChanged: Subject<any> = new Subject<any>();

  public valueChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackbarService: SnackbarService,
    private location: Location,
  ) {
    this.tenantHandlerForm = this.formBuilder.group({
      tenant: '',
    });
  }

  setTenants(user: UserInfo) {
    this.tenants = user.tenants.filter(
      (el) => el.identityProviderKey != 'viegandmaagoe-aad-prod'
    );
  }

  getIdpFromUrl(): string | null {
    let idpStart = this.location?.path().indexOf('idp=');

    if (idpStart >= 0) {
      let idp = this.location?.path().slice(idpStart + 4);
      this.idp = idp;
      return idp;
    }

    return null;
  }

  async setIdpUrlParam(idpKey): Promise<void> {
    const currentLocation = this.location.path();

    if (idpKey) {
      if (currentLocation === '/error-page') {
        await this.router.navigate(['/home'], { queryParams: { idp: idpKey } }).then(() => {
          this.location.go('/home', `idp=${idpKey}`);
        });
        return;
      }
      this.location.go(currentLocation, `idp=${idpKey}`);
    }
  }

  async setIdpOnViewInit(idp = null): Promise<void> {
    if (!this.getIdpFromUrl()) {
      await this.setIdpUrlParam(
        idp || this.getDefaultTenant()[0]?.identityProviderKey
      );
    }
  }

  async selectIdpUrlParam(): Promise<void> {
    if (this.adminService.viewerType == ViewerType.singleTenantViewer) {
      // adds current idp parameter to the url
      await this.setIdpOnViewInit(this.userIdp);
    } else if (this.adminService?.selectedIdentityProvider) {
      // if cross customer viewer adds the chosen tenant as url param
      await this.setIdpOnViewInit(this.adminService?.selectedIdentityProvider);
    }
  }

  async selectCustomIdpUrlParam(tenantCode: string): Promise<void> {
    if (this.adminService.viewerType == ViewerType.singleTenantViewer) {
      // adds current idp parameter to the url
      await this.setIdpOnViewInit(this.userIdp);
    } else if (this.adminService?.selectedIdentityProvider) {
      // if cross customer viewer adds the chosen tenant as url param
      await this.setIdpOnViewInit(tenantCode);
    }
  }

  removeUrlParam(): void {
    let urlWithoutParams = this.router.url.split('?')[0];
    this.location.go(urlWithoutParams);
  }

  getDefaultTenant() {
    let currentTenant = this.tenants?.filter((tenant) => {
      return tenant?.identityProviderKey === this.userIdp;
    });
    if (this.userIdp === 'dima-idp-prod') {
      currentTenant = this.tenants?.filter((tenant) => {
        return tenant?.identityProviderKey === 'soenderborg-ad-prod';
      });
    }

    let defaultTenant =
      currentTenant.length > 0 ? currentTenant[0] : this.tenants[0];

    return defaultTenant;
  }

  setSpecificTenant(idp: string) {
    let tenant =
      this.tenants.find((el) => el.identityProviderKey == idp) ||
      this.tenants[0];

    this.tenantHandlerForm.controls['tenant'].patchValue(
      tenant.identityProviderKey,
      {
        emitEvent: false,
      });
      
    this.adminService.selectedIdentityProvider = idp;
  }

  onSelectChanges(): void {
    this.selectChanges = this.tenantHandlerForm
      .get('tenant')
      .valueChanges.subscribe(async (el) => {
        this.valueChanged.next(true);

        this.removeUrlParam();
        await this.selectTenant(el);

        this.selectTenantState$ = el;
      });
  }

  async selectTenant(tenant): Promise<void> {
    this.snackbarService.openSnackBar('navigation.change-customer');
    this.adminService.selectedIdentityProvider = tenant;
    await this.reloadRoute(tenant);
    this.tenantChanged.next();
  }

  async reloadRoute(tenant): Promise<void> {
    await this.router.navigate([this.getCurrentRoute()]).then(async () => {
      await this.setIdpUrlParam(tenant);
    });
  }

  getCurrentRoute() {
    let urlWithoutParams = this.router.url.split('?')[0];
    return urlWithoutParams;
  }

  setUserTenant(idp: string) {
    this.userIdp = idp;
  }

  setCountrySpecificTranslations(isNorwegianExpression) {
    isNorwegianExpression
      ? (this.userCurrency = NOK)
      : (this.userCurrency = DK);
  }

  setLanguageSpecificTranslations(isNorwegianExpression: boolean) {
    isNorwegianExpression
      ? (this.userLanguageCurrency = NOK)
      : (this.userLanguageCurrency = DK);
  }
}
