<div class="col-12">
    <div class="row pb-5 mx-0">
        <div class="col-12 gx-0">
            <div class="mt-2">

                <mat-accordion [multi]="true">

                    <mat-expansion-panel [expanded]="calculatorHelperService.emissionPanelOpenState"
                        (opened)="calculatorHelperService.emissionPanelOpenState = true"
                        (closed)="calculatorHelperService.emissionPanelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="fw-bold fs-22">
                                    {{ 'scenarios.emission' | translate }}
                                </div>
                            </mat-panel-title>
                            <mat-panel-description>
                                <span *ngIf="calculatorHelperService.emissionPanelOpenState == false">
                                    {{ 'scenarios.emission' + '-open' | translate }}</span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <app-table-display
                            *ngIf="calculatorTableService.uniqueUnspscEmissionChangeView?.length && calculatorTableService.uniqueUnspscEmissionChangeView"
                            valueFieldName="cO2EmissionKG" headerTranslationKey="emission"
                            changeFieldName="emissionChange" changeSumFieldName="emissionChangeSum"
                            totalChangeFieldName="totalEmissionChange" yearlyTotalFieldName="yearlyTotalsEmissions"
                            panelOpenStateFieldName="emissionPanelOpenState"
                            uniqueUnspscChangeView="uniqueUnspscEmissionChangeView"
                            historicalArrayName="historicalEmissions">
                        </app-table-display>

                        <div class="row">
                            <div class="col-12 mt-4 mb-4" *ngIf="calculatorService.areGraphsShown">
                                <app-scenario-calculator graphName="emission"></app-scenario-calculator>

                                <div
                                    *ngIf="calculatorService.totalEmissionChange && calculatorService.totalExpenseChange">
                                    <div
                                        class="row justify-content-center align-items-stretch text-center full-height mt-4">
                                        <div class="col-6">
                                            <div class="row justify-content-center align-items-center full-height">
                                                <div class="col-12">
                                                    <p class="fw-bold fs-16">
                                                        {{ 'scenarios.total-emission' | translate }}
                                                    </p>
                                                    <div class="fw-bold fs-22">
                                                        {{
                                                        calculatorService.totalEmissionChange
                                                        | number: '1.0-0'
                                                        }}
                                                        CO2 KG
                                                        <app-amount-indicator
                                                            [amount]="calculatorService.totalEmissionChange"></app-amount-indicator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="calculatorHelperService.expensePanelOpenState"
                        (opened)="calculatorHelperService.expensePanelOpenState = true"
                        (closed)="calculatorHelperService.expensePanelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="fw-bold fs-22">
                                    {{ 'scenarios.expense' | translate }}
                                </div>
                            </mat-panel-title>
                            <mat-panel-description>
                                <span *ngIf="calculatorHelperService.expensePanelOpenState == false">
                                    {{ 'scenarios.emission' + '-open' | translate }}</span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <app-table-display
                            *ngIf="calculatorTableService.uniqueUnspscExpenseChangeView?.length && calculatorTableService.uniqueUnspscExpenseChangeView"
                            valueFieldName="amount" headerTranslationKey="expense" changeFieldName="expenseChange"
                            changeSumFieldName="expenseChangeSum" totalChangeFieldName="totalExpenseChange"
                            yearlyTotalFieldName="yearlyTotalsExpenses" panelOpenStateFieldName="expensePanelOpenState"
                            uniqueUnspscChangeView="uniqueUnspscExpenseChangeView"
                            historicalArrayName="historicalExpenses">
                        </app-table-display>

                        <div class="row">
                            <div class="col-12 mt-4 mb-4" *ngIf="calculatorService.areGraphsShown">
                                <app-scenario-calculator graphName="expense"></app-scenario-calculator>

                                <div *ngIf="calculatorService.totalExpenseChange">
                                    <div
                                        class="row justify-content-center align-items-stretch text-center full-height mt-4">
                                        <div class="col-6">
                                            <div class="row justify-content-center align-items-center full-height">
                                                <div class="col-12">
                                                    <p class="fw-bold fs-16">
                                                        {{ 'scenarios.total-expense' | translate }}
                                                    </p>
                                                    <div class="fw-bold fs-22">
                                                        {{
                                                        calculatorService.totalExpenseChange
                                                        | number: '1.0-0'
                                                        }}
                                                        {{ tenantSelectService.userCurrency }}
                                                        <app-amount-indicator
                                                            [amount]="calculatorService.totalExpenseChange"></app-amount-indicator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel
                        [ngStyle]="{ 'display': calculatorService.totalQuantityChange && allUnitMethodsUsesSameUnit ? 'block' : 'none' }"
                        [expanded]="calculatorHelperService.quantityPanelOpenState"
                        (opened)="calculatorHelperService.quantityPanelOpenState = true"
                        (closed)="calculatorHelperService.quantityPanelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="fw-bold fs-22">
                                    {{ 'scenarios.quantity' | translate }}
                                </div>
                            </mat-panel-title>
                            <mat-panel-description>
                                <span *ngIf="calculatorHelperService.quantityPanelOpenState == false">
                                    {{ 'scenarios.quantity' + '-open' | translate }}</span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <app-table-display valueFieldName="quantity" headerTranslationKey="quantity"
                            changeFieldName="quantityChange" changeSumFieldName="quantityChangeSum"
                            totalChangeFieldName="totalQuantityChange" yearlyTotalFieldName="yearlyTotalsQuantities"
                            panelOpenStateFieldName="quantityPanelOpenState"
                            uniqueUnspscChangeView="uniqueUnspscQuantityChangeView"
                            historicalArrayName="historicalEmissions">
                        </app-table-display>

                        <div class="col-12 mt-4 mb-4" *ngIf="calculatorService.areGraphsShown">
                            <app-scenario-calculator graphName="quantity">
                            </app-scenario-calculator>

                            <div *ngIf="calculatorService.totalQuantityChange">
                                <div
                                    class="row justify-content-center align-items-stretch text-center full-height mt-4">
                                    <div class="col-6">
                                        <div class="row justify-content-center align-items-center full-height">
                                            <div class="col-12">
                                                <p class="fw-bold fs-16">
                                                    {{ 'scenarios.total-quantity' | translate }}
                                                </p>
                                                <div class="fw-bold fs-22">
                                                    {{
                                                    calculatorService.totalQuantityChange
                                                    | number: '1.0-0'
                                                    }}
                                                    <!-- Units -->
                                                    <app-amount-indicator
                                                        [amount]="calculatorService.totalQuantityChange"></app-amount-indicator>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </mat-expansion-panel>

                </mat-accordion>

            </div>
        </div>
    </div>
</div>