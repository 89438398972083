import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ReportColumn } from 'src/app/commons/models/report/report-column.model';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsVisibleColumnsService {
  public paramQuery: Observable<ReportColumn[]>;
  public invisibleColumnQueryParams: Params = { _invisible: null };
  activatedRoute: ActivatedRoute;

  constructor(private route: ActivatedRoute, private router: Router) {}

  public getInvisibleColumnsFromSnapShot(): string[] {
    let columnNames: string[] = [];
    this.route.queryParams.subscribe((paramsValues: Params) => {
      Object.keys(paramsValues).forEach((key) => {
        if (key === '_invisible' && paramsValues !== undefined) {
          columnNames = paramsValues[key].split(',,') ?? undefined;
        } else columnNames = undefined;
      });
    });
    return columnNames;
  }

  public setQueryParams(columns: ReportColumn[]): void {
    let columnNames: string[] = [];
    if (columns !== undefined && columns.length > 0)
      columns.forEach((column) => {
        if (column.hide) columnNames.push(column.groupingId);
      });
    let newURL: String;
    newURL = columnNames.join(',,');

    this.invisibleColumnQueryParams = { _invisible: newURL };
    if (columns == undefined || columnNames.length === 0)
      this.invisibleColumnQueryParams = { _invisible: null };
    this.updateQueryParams();
  }
  public updateQueryParams(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.invisibleColumnQueryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }
}
