export enum DataViewType {
    CUSTOMERS_OWN_DATA,
    CUSTOMERS_AVERAGE_PRICE,
    CUSTOMERSOWNDATAEXCLUSION,
    PSE_IN_USE,
    PSE_CAN_BE_USED,
    PSE_CANDIDATES,
    PSE_THE_REST,
    CSRD_MEASURING_POINTS,
    CSRD_FISCAL_YEARS
}