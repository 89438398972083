import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CustomersOwnDataFormService } from '@modules/customers-own-data/services/customers-own-data-form.service';
import { CustomersOwnDataService } from '@modules/customers-own-data/services/customers-own-data.service';
import { DialogService } from '../../../../services/dialog.service';
import { CreateEditDialogComponent } from 'src/app/commons/components/create-edit-dialog/create-edit-dialog.component';
import { GhgCategoryService } from 'src/app/commons/services/ghg-category.service';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import { GhgCategoryUnspsc } from 'src/app/commons/models/ghg-category-unspsc-model';
import { GhgCategory } from 'src/app/commons/models/ghg-category-model';
import { OrganizationalUnit, OrganizationalUnitViewModel } from 'src/app/commons/models/organizational-unit';
import { UnspscHandlerService } from 'src/app/commons/components/unspsc-handler/unspsc-handler.service';
import { EntityTrackerError } from 'src/app/commons/models/entity-tracker-error';
import { ApiService } from 'src/app/services/api.service';
import { UnitViewDto, UnspscUnitDto } from 'src/app/commons/models/unspsc-unit-dto';
import { UserService } from '@modules/shell/services/user.service';
import { DialogConfigData } from 'src/app/commons/models/dialog-config-data';

@Component({
    selector: 'app-create-edit-cod-dialog',
    templateUrl: './create-edit-cod-dialog.component.html',
    styleUrls: ['./create-edit-cod-dialog.component.scss'],
    standalone: false
})
export class CreateEditCodDialogComponent extends CreateEditDialogComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  ghgCategoryOptions: Observable<GhgCategory[]>;
  ghgCategoryUnspscs: GhgCategoryUnspsc[] = [];
  categoryLabel: string;
  isInitialChange = true;

  unspscsOptions: any[] | EntityTrackerError = [];
  supplierOptions: any[] | EntityTrackerError = [];
  unitOptions: any[] | EntityTrackerError = [];
  unspscsOptionsSubscription: Subscription;
  formInitializationSubscription: Subscription;

  unspscsOptionsInitialized: boolean = false;
  unitOptionsInitialized: boolean = false;
  supplierOptionsInitialized: boolean = false;

  public filteredOrganizations: OrganizationalUnitViewModel[] = [];
  public filteredOrganizationOptionsSub: Observable<
    OrganizationalUnitViewModel[]>;

  filteredOrganizationOptions: OrganizationalUnitViewModel[] = [];

  organizationOptionsInitialized = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    public fb: FormBuilder,
    dialogRef: MatDialogRef<CreateEditCodDialogComponent>,
    private translateService: TranslateService,
    public formService: CustomersOwnDataFormService,
    customersOwnDataService: CustomersOwnDataService,
    dialogService: DialogService,
    userService: UserService,
    private ghgCategoryService: GhgCategoryService,
    private unspscHandlerService: UnspscHandlerService,
    private apiService: ApiService,
    private cdRef: ChangeDetectorRef) {
    super(editData,
      customersOwnDataService,
      formService,
      dialogRef,
      dialogService,
      userService)

    this.categoryLabel = translateService.instant('customers-own-data.category');
  }

  ngOnInit(): void {
    this.ghgCategoryOptions = this.ghgCategoryService.getAllGhgCategories();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.formService.form.controls.UnspscClassId
      .valueChanges.pipe(
        debounceTime(1000),
        startWith(this.editData?.unspscClassId ?? ''),
        map((value) => value),
        switchMap((unspsc) => {
          return unspsc ?
            this.ghgCategoryService.getGhgCategoriesByUnspsc(unspsc)
            : [];
        })
      ).subscribe(x => {
        this.ghgCategoryUnspscs = x;
        this.setMatchingGhgCategory();
        this.setGhgCategory();
        this.isInitialChange = false;
      }, (error) => console.error(error));

    this.filteredOrganizationOptionsSub = this.formService.form
      .controls.OrganizationID
      .valueChanges.pipe(
        debounceTime(1000),
        startWith<string | OrganizationalUnit>(''),
        map((value) => {
          return typeof value === 'string' ? value : value?.organizationID;
        }),
        switchMap((organization) => {
          return this._filterOrganizations(organization);
        })
      );

    this.filteredOrganizationOptionsSub.subscribe(x => {
      this.filteredOrganizationOptions = x;
      this.organizationOptionsInitialized = true;
    });

    this.apiService.getSuppliers().subscribe(x => {
      this.supplierOptions = x as any[];
      this.supplierOptionsInitialized = true;

      if (this.editData) {
        if (this.supplierOptions?.find(z => z.supplierId === this.editData.supplierID
          && z.supplierCountry === this.editData.supplierCountry)) {
          this.getUnspscAndUnits(this.editData.supplierID, this.editData.supplierCountry, false);
          this.formService.form.controls.SupplierCountry.disable();
        } else {
          this.getUnspscAndUnits(null, null, false);
          this.formService.form.controls.UnspscClassId.enable();
          this.formService.form.controls.SupplierCountry.enable();
        }
      } else {
        this.apiService.getUnspscWithUnits().subscribe(y => {
          this.unspscsOptions = y as any[];
          this.unspscsOptionsInitialized = true;

          this.unitOptions = (y as any[])?.find(z => z.unspscClassId === this.editData?.unspsc)?.units?.map(x => <UnitViewDto>{ unit: x.unit.toUpperCase(), unitLabel: x.unitLabel }) ?? [];
          if ((this.unitOptions as any[])?.length > 0) {
            this.formService.form.controls.Unit.setValue(this.unitOptions[0]?.unit);
          }

          this.unitOptionsInitialized = true;
        });
      }
    });
  }

  getMatchingGhgCategory(): string {
    let values = [];

    this.ghgCategoryUnspscs.forEach((option) => {
      values.push('Scope: ' + option.scope + ', ' +
        this.categoryLabel + ' ' + option.ghgCatNumber + ', ' +
        option.ghgCatTitle);
    });

    const result = values.join(" / ");

    return result !== '' ? result : 'None';
  }

  organizationDisplayFn(organizationID?): string | undefined {
    let displayedValue =
      this.filteredOrganizations.find((el) => {
        return el.organizationID == organizationID;
      })?.organizationalUnitViewDescription || '';
    return organizationID ? displayedValue : undefined;
  }

  private _filterOrganizations(
    organization: string
  ): Observable<OrganizationalUnitViewModel[]> {
    let filterValue = organization?.toLowerCase() ?? '';

    return this.unspscHandlerService
      .searchOrganization({ likeValue: filterValue, searchValue: 1 })
      .pipe(
        switchMap((el) => {
          this.filteredOrganizations = [...this.filteredOrganizations, ...el];
          return of(el);
        })
      );
  }

  submitForm(): void {
    if (this.validateOrganization()) {
      this.ctaScenario();
    } else {
      this.formService.form.markAllAsTouched();

      this.dialogService.inform(<DialogConfigData>{
        message: this.translateService.instant(
          'error-handling.form-error')
      });
    }
  }

  validateOrganization(): boolean {
    if (this.filteredOrganizationOptions.filter(x => x.organizationID == this.formService.form.get('OrganizationID').getRawValue())?.length < 1) {
      this.formService.form.get('OrganizationID').setErrors({ error: true });
      this.formService.form.updateValueAndValidity();
      return false;
    } else {
      this.formService.form.get('OrganizationID').setErrors(null);
      this.formService.form.updateValueAndValidity();
      return true;
    }
  }

  onSupplierSelectionChange(option: any): void {
    this.formService.form.controls.UnspscClassId.setValue('');
    this.formService.form.controls.UnspscClassId.enable();
    this.formService.form.controls.SupplierCountry
      .setValue(option.supplierCountry ?? '');
    this.getUnspscAndUnits(this.formService.form.controls.SupplierID.value,
      this.formService.form.controls.SupplierCountry.value, true);

    this.formService.form.controls.SupplierCountry.disable();
  }

  onUnspscSelectionChange(option: UnspscUnitDto): void {
    this.formService.form.controls.Unit.setValue('');
    this.formService.form.controls.Unit.enable();
    this.unitOptions = option.units;
    if ((this.unitOptions as any[])?.length > 0) {
      this.formService.form.controls.Unit.setValue(this.unitOptions[0]?.unit);
    }

    this.cdRef.detectChanges();
  }

  onSupplierValueCleared(): void {
    this.getUnspscAndUnits(null, null, false);
    this.formService.form.controls.SupplierCountry.enable();
  }

  onUnspscValueCleared(): void {
    this.formService.form.controls.Unit.setValue('');
    this.formService.form.controls.Unit.disable();
    this.unitOptions = [];
    this.cdRef.detectChanges();
  }

  onSupplierTextChanged(text: string): void {
    const foundSupplier = (this.supplierOptions as any[])
      .find(option => `${option.supplierName} (CVR:${option.supplierCountry}${option.supplierId})` == text);
    if (foundSupplier) {
      this.formService.form.controls.SupplierCountry.disable();
    } else {
      this.formService.form.controls.SupplierCountry.enable();
    }
  }

  getUnspscAndUnits(supplierId: string, supplierCountry: string, supplierSelectionChanged: boolean): void {
    this.unitOptionsInitialized = false;

    this.apiService.getUnspscWithUnits(supplierId, supplierCountry)
      .subscribe(x => {
        this.unspscsOptions = x as any[];
        this.unspscsOptionsInitialized = true;

        this.unitOptions = (x as any[])?.find(z => z.unspscClassId === this.formService.form.controls.UnspscClassId.value)?.units?.map(x => <UnitViewDto>{ unit: x.unit.toUpperCase(), unitLabel: x.unitLabel }) ?? [];
        this.unitOptionsInitialized = true;

        if (supplierSelectionChanged && !(this.unspscsOptions?.map(x => x.unspscClassId.toString())?.includes(this.formService.form.controls.UnspscClassId.value))) {
          this.formService.form.controls.UnspscClassId.setValue('');
          this.formService.form.controls.Unit.setValue('');
          this.formService.form.controls.Unit.disable();
        }
      });
  }

  private setMatchingGhgCategory(): void {
    if (!this.ghgCategoryUnspscs[0]?.ghgCatNumber && this.formService.form.controls.UnspscClassId?.value && !this.isInitialChange) {
      this.formService.form.controls.MatchingGhgCategory.setValue('None');
    } else {
      this.formService.form.controls.MatchingGhgCategory.setValue(this.getMatchingGhgCategory());
    }
  }

  private setGhgCategory(): void {
    if ((!this.isInitialChange || (this.isInitialChange && !this.editData)) && !this.editData?.ghgCatId) {
      if (this.formService.form.controls.MatchingGhgCategory?.value === 'None') {
        this.formService.form.controls.GhgCategory.setValue(undefined);
      } else {
        this.formService.form.controls.GhgCategory.setValue(0);
      }
    }
  }

  ngOnDestroy(): void {
    this.formService.resetEditData();
    this.formService.resetForm();
    this.formService.resetProductDataChange();
  }
}
