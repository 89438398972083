import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { StatusDto } from '../../../../commons/models/status-dto';
import { CalculateDto } from '../../../../commons/models/scenario-dto';
import { Observable, Subscription } from 'rxjs';
import { EntityTrackerError } from '../../../../commons/models/entity-tracker-error';
import { IScenariosService } from '@modules/scenarios/services/scenarios/scenarios.interface';
import { CalculatorTableService } from '../calculator/calculator-table.service';

@Injectable({
  providedIn: 'root',
})
export class ScenariosService implements OnDestroy, IScenariosService {
  statuses: StatusDto[];
  private downloadScenariosSubscription: Subscription;

  constructor(
    public apiService: ApiService,
    public calculatorTableService: CalculatorTableService
  ) {}

  ngOnDestroy(): void {}

  getScenarios(): Observable<CalculateDto[] | EntityTrackerError> {
    return this.apiService.getScenarios();
  }

  calculateChanges(scenarios: CalculateDto[]) {
    let calculationsByScenario: {
      calculationId: number;
      emissionChange: number;
      expenseChange: number;
    }[] = [];
    scenarios.map((scenario) => {
      let emissionChange: number = 0,
        expenseChange: number = 0;

      scenario.calculations.map((yearCalc) => {
        emissionChange = emissionChange + yearCalc.emissionChange;

        expenseChange = expenseChange + yearCalc.lineAmountChange;
      });

      calculationsByScenario.push({
        calculationId: scenario.calculationId,
        emissionChange: Math.round(emissionChange),
        expenseChange: Math.round(expenseChange),
      });
    });

    return calculationsByScenario;
  }

  downloadScenarios(element) {
    this.apiService.downloadScenarios(element);
  }
}
