import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EntityTrackerError } from '../../models/entity-tracker-error';
import { AbstractControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { DisplayPatternPipe } from '../../pipes/display-pattern.pipe';

@Component({
    selector: 'app-custom-autocomplete',
    templateUrl: './custom-autocomplete.component.html',
    styleUrls: ['./custom-autocomplete.component.scss'],
    providers: [DisplayPatternPipe],
    standalone: false
})
export class CustomAutocompleteComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() parentGroup: FormGroup;
  @Input() options: any[] | EntityTrackerError;
  @Input() controlName: string;
  @Input() filterFieldNames: string[];
  @Input() valueFieldName: string;
  @Input() displayFieldPattern: string;
  @Input() orderFieldName: string;
  @Input() labelTranslationKey: string;

  @Output() selectionChanged = new EventEmitter<any>();
  @Output() valueCleared = new EventEmitter();
  @Output() selectionDataInitialized = new EventEmitter();
  @Output() blur = new EventEmitter<any>();
  @Output() inputTextChanged = new EventEmitter<string>();
  @Output() scroll = new EventEmitter<any>();
  @Output() filter = new EventEmitter<string>();

  control: AbstractControl<any, any>;
  valueChosen = false;
  filteredOptions: Observable<any[]>;
  filteredOptionsList: any[] = [];
  filteredSub: Subscription;

  previouslySelectedValue: string = null;

  constructor(private patternPipe: DisplayPatternPipe, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.reloadOptions();
    this.selectionDataInitialized.emit();
  }

  ngAfterViewInit(): void {
    this.control = this.parentGroup.controls[this.controlName];

    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filter(value || '', this.filterFieldNames, this.options as any[]);
      }),
    );

    this.control.valueChanges.subscribe((value) => {
      this.filteredOptionsList = this._filter(value || '', this.filterFieldNames, this.options as any[]);
      if (value) {
        this.valueChosen = value;
      } else {
        this.valueCleared.emit();
      }
    });
  }

  ngOnChanges(event: SimpleChanges) {
    this.reloadOptions();
  }

  onSelectionChange(option: any): void {
    this.valueChosen = true;
    this.previouslySelectedValue = this.parentGroup.controls[this.controlName].value;
    this.selectionChanged.emit(option);
  }

  displayFn(value?: string) {
    const option = this.filteredOptionsList?.find(x => x[this.valueFieldName] == value);
    return value && option ? this.patternPipe.transform(this.displayFieldPattern, option) : value;
  }

  reloadOptions(): void {
    this.filteredOptionsList = (this.options as any[])?.length > 0 ? (this.options as any[])?.sort((a, b) => (a[this.orderFieldName] < b[this.orderFieldName] ? -1 : 1)) : [] as any;
    this.cdRef.detectChanges();
  }

  onBlur(): void {
    const option = this.filteredOptionsList?.find(x => x[this.valueFieldName] == this.parentGroup.controls[this.controlName].value);
    if (option) {
      this.blur.emit(option);
    }
  }

  inputTextChange(event: Event): void {
    this.inputTextChanged.emit((event.target as HTMLInputElement).value);
  }

  onScroll(): void {
    this.scroll.emit();
  }

  private _filter<T>(value: string, fieldNames: string[], options: T[]): T[] {
    if (value) {
      return (this.options as any[])?.filter(
        (item: any) => fieldNames.some((prop: any) =>
          (new RegExp(value.toLowerCase().replace(/[()]/g, '\\$&'))).test(item[prop]?.toLowerCase())
        )
      );
    }
    return options;
  }

  ngOnDestroy(): void {
    if (this.filteredSub)
      this.filteredSub.unsubscribe();
  }
}
