import { Component, Host, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataViewComponent } from 'src/app/commons/components/data-view/data-view.component';
import { CustomDialogService } from 'src/app/commons/dialog/services/custom-dialog.service';
import { CreateEditCapDialogComponent } from '../create-edit-cap-dialog/create-edit-cap-dialog.component';
import { DialogService } from 'src/app/services/dialog.service';
import { TableService } from '@modules/customers-average-price/services/table.service';
import { CustomersAveragePriceGetService, CustomersAveragePriceService } from '@modules/customers-average-price/services/customers-average-price.service';
import { FilterService } from '@modules/customers-average-price/services/filter.service';
import { SnackbarService } from 'src/app/commons/services/snackbar.service';

@Component({
  selector: 'app-cap-action-menu',
  templateUrl: './cap-action-menu.component.html',
  styleUrls: ['./cap-action-menu.component.scss'],
  providers: [DataViewComponent]
})
export class CapActionMenuComponent implements OnInit {
  translationKey = 'customers-average-price';
  parentComponent: DataViewComponent;
  isDeleteButtonDisabled = true;
  private customDialogService: CustomDialogService;

  constructor(@Host() parent: DataViewComponent,
    public translateService: TranslateService,
    dialogService: DialogService,
    tableService: TableService,
    dataService: CustomersAveragePriceService,
    getDataService: CustomersAveragePriceGetService,
    filterService: FilterService,
    snackbarService: SnackbarService) {
    this.customDialogService = new CustomDialogService(dialogService, getDataService, dataService, tableService, filterService, snackbarService, translateService);
    this.customDialogService.orderBy = 'order by Supplier.SupplierName asc, Unspsc asc, Unit asc';
    this.parentComponent = parent;
  }

  ngOnInit() {
    this.customDialogService.dialogComponent = CreateEditCapDialogComponent;
  }

  createDialog(): void {
    this.customDialogService.createDialog();
  }
}
