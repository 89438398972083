<div class="container">
    <div class="row align-items-center">
        <div class="col-12 mb-5">
            <div class="row">
                <div class="col-12 mt-5">
                    <div class="row">
                        <div class="col-4">
                            <img class="mt-3 mb-3" id="kmd-logo"
                                src="../../../../../assets/images/carbonkey-rgb-v2.svg" />
                            <h1 class="col-12 mt-5">
                                Support
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div [innerHtml]="safeHtmlContent" (click)="navigate($event)" class="col-12 mt-5"
                style="font-size: 15.5px;">
            </div>

        </div>
    </div>
</div>