import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogResultStatus } from './dialog-result-status';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'existing-record-dialog',
  templateUrl: './existing-record-dialog.component.html',
  styleUrls: ['./existing-record-dialog.component.scss'],
})
export class ExistingRecordDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ExistingRecordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close(DialogResultStatus.CANCEL);
  }

  confirmEdit(): void {
    this.dialogRef.close(DialogResultStatus.EDIT);
  }

  confirmUpdate(): void {
    this.dialogRef.close(DialogResultStatus.UPDATE);
  }

  confirmYes(): void {
    this.dialogRef.close(DialogResultStatus.YES);
  }

  confirmAbort(): void {
    this.dialogRef.close(DialogResultStatus.ABORT);
  }
}
