import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PbiBookmarkService } from '@modules/report/services/pbi-bookmark.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogConfigData } from 'src/app/commons/models/dialog-config-data';

@Component({
  selector: 'app-create-bookmark-dialog',
  templateUrl: './create-bookmark-dialog.component.html',
  styleUrls: ['./create-bookmark-dialog.component.scss'],
})
export class CreateBookmarkDialogComponent implements OnInit {
  isEditMode: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CreateBookmarkDialogComponent>,
    public translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public bookmarkService: PbiBookmarkService,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfigData
  ) { }

  ngOnInit(): void {
    this.bookmarkService.resetForm();

    if (this.data?.value) {
      this.isEditMode = true;
      this.bookmarkService.bookmarkForm.controls.bookmarkName.setValue(this.data.value);
    }
  }

  onEnterKeyPressed(event: any) {
    event.preventDefault();
    this.save();
  }

  close() {
    this.dialogRef.close('cancel');
  }

  save() {
    if (this.bookmarkService.bookmarkForm.valid) {
      this.dialogRef.close('save');
    }
  }

  checkIfNameAlreadyExists() {
    if (this.bookmarkService.bookmarkForm.get('bookmarkName').hasError('sameName')) {
      if (this.isEditMode && this.bookmarkService.bookmarkForm.controls.bookmarkName.value === this.data?.value) {
        this.bookmarkService.bookmarkForm.controls.bookmarkName.setErrors(null);
        this.bookmarkService.bookmarkForm.updateValueAndValidity();
        return false;
      }

      return true;
    }

    return false;
  }
}
