import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForbiddenComponent } from '@modules/auth/components/forbidden/forbidden.component';
import { LoginComponent } from '@modules/auth/components/login/login.component';
import { UnauthorizedComponent } from '@modules/auth/components/unauthorized/unauthorized.component';
import { AuthGuard } from '@modules/auth/guards/auth.guard';
import { HomeComponent } from '@modules/home/components/home/home.component';
import { ReportWrapperComponent } from '@modules/report/components/report-wrapper/report-wrapper.component';
import { ROUTENAMES } from './app-routing.names';
import { InitiativesComponent } from '@modules/initiatives/components/initiatives/initiatives.component';
import { NotFoundComponent } from './modules/auth/components/not-found/not-found.component';
import { MenuItemStatus } from './commons/models/menu-item-status';
import { LanguageResolver } from './resolvers/language.resolver';
import { ScenariosResolver } from './resolvers/scenarios.resolver';
import { InitiativesResolver } from './resolvers/initiatives.resolver';
import { SomethingWentWrongComponent } from '@modules/auth/components/something-went-wrong/something-went-wrong.component';
import { EmptyHomeScreenComponent } from '@modules/home/components/empty-home-screen/empty-home-screen.component';
import { ScenariosAndTemplatesWrapperComponent } from '@modules/scenarios/components/scenarios-and-templates-wrapper/scenarios-and-templates-wrapper.component';
import { TemplatesListComponent } from '@modules/scenarios/components/templates-list/templates-list.component';
import { TemplatesResolver } from './resolvers/templates.resolver';
import { UserGuideComponent } from '@modules/user-guide/user-guide.component';
import { ProductSpecificEmissionsResolver } from './resolvers/product-specific-emissions.resolver';
import { UserResolver } from './resolvers/user.resolver';
import { CustomersOwnDataResolver } from './resolvers/customers-own-data.resolver';
import { DataViewComponent } from './commons/components/data-view/data-view.component';
import { CustomersAveragePriceGetService, CustomersAveragePriceService } from '@modules/customers-average-price/services/customers-average-price.service';
import { CustomersAveragePriceResolver } from './resolvers/customers-average-price.resolver';
import { CustomersOwnDataGetService, CustomersOwnDataService } from '@modules/customers-own-data/services/customers-own-data.service';
import { TableService as CutomersAveragePricesTableService } from '@modules/customers-average-price/services/table.service';
import { TableService as CutomersOwnDataTableService } from '@modules/customers-own-data/services/table.service';
import { TableService as ProductSpecificEmissionsTableService } from '@modules/product-specific-emissions/services/table.service';
import { TableService as CustomersExclusionRulesTableService } from '@modules/customers-own-data-exclusion/services/table.service';
import { FilterService as CutomersOwnDataFilterService } from '@modules/customers-own-data/services/filter.service';
import { FilterService as CutomersExclusionRulesFilterService } from '@modules/customers-own-data-exclusion/services/filter.service';
import { FilterService as CutomersAveragePriceFilterService } from '@modules/customers-average-price/services/filter.service';
import { CustomersOwnDataFormService } from '@modules/customers-own-data/services/customers-own-data-form.service';
import { CustomersAveragePriceFormService } from '@modules/customers-average-price/services/customers-average-price-form.service';
import { DataViewType } from './commons/components/create-edit-dialog/data-view-type.enum';
import { ProductSpecificEmissionsGetService, ProductSpecificEmissionsService } from '@modules/product-specific-emissions/services/product-specific-emissions.service';
import { ProductSpecificEmissionsFormService } from '@modules/product-specific-emissions/services/product-specific-emissions-form.service';
import { PseActionMenuComponent } from '@modules/product-specific-emissions/components/pse-action-menu/pse-action-menu.component';
import { CodActionMenuComponent } from '@modules/customers-own-data/components/cod-action-menu/cod-action-menu.component';
import { CapActionMenuComponent } from '@modules/customers-average-price/components/cap-action-menu/cap-action-menu.component';
import { CodTabViewComponent } from '@modules/customers-own-data/cod-tab-view/cod-tab-view.component';
import { PbiReportsResolver } from './resolvers/pbi-reports.resolver';
import { CustomersOwnDataExclusionResolver } from './resolvers/customers-own-data-exclusion.resolver';
import { CodExclusionActionMenuComponent } from '@modules/customers-own-data-exclusion/components/cod-exclusion-action-menu/cod-exclusion-action-menu.component';
import { CustomersExclusionRulesGetService, CustomersExclusionRulesService } from '@modules/customers-own-data-exclusion/services/customers-exclusion-rules.service';
import { ActivityEventType } from './commons/models/activity.model';
import { PseTabViewComponent } from '@modules/product-specific-emissions/pse-tab-view/pse-tab-view.component';
import { ProductSpecificEmissionsAvailableService } from '@modules/product-specific-emissions/services/product-specific-emissions-available.service';
import { ProductSpecificEmissionsAvailableResolver } from './resolvers/product-specific-emissions-available.resolver';
import { ProductSpecificEmissionsCandidatesService } from '@modules/product-specific-emissions/services/product-specific-emissions-candidates.service';
import { ProductSpecificEmissionsCandidatesResolver } from './resolvers/product-specific-emissions-candidates-resolver';
import { ProductSpecificEmissionsOthersService } from '@modules/product-specific-emissions/services/product-specific-emissions-others.service';
import { ProductSpecificEmissionsOthersResolver } from './resolvers/product-specific-emissions-others.resolver';
import { FilterInUseService } from '@modules/product-specific-emissions/services/filter/filter-in-use.service';
import { FilterAvailableService } from '@modules/product-specific-emissions/services/filter/filter-available.service';
import { FilterCandidatesService } from '@modules/product-specific-emissions/services/filter/filter-candidates.service';
import { FilterOthersService } from '@modules/product-specific-emissions/services/filter/filter-others.service';
import { CsrdTabViewComponent } from '@modules/customers-csrd/components/csrd-tab-view/csrd-tab-view.component';
import { TableService as MeasuringPointsTableService } from '@modules/customers-csrd/services/measuring-points/table.service';
import { TableService as FiscalYearsTableService } from '@modules/customers-csrd/services/fiscal-years/table.service';
import { MeasuringPointsGetService } from '@modules/customers-csrd/services/measuring-points/measuring-points-get.service';
import { MeasuringPointsResolver } from './resolvers/measuring-points.resolver';
import { FiscalYearsGetService } from '@modules/customers-csrd/services/fiscal-years/fiscal-years-get.service';
import { FiscalYearsResolver } from './resolvers/fiscal-years.resolver';
import { FiscalYearActionMenuComponent } from '@modules/customers-csrd/components/fiscal-year-action-menu/fiscal-year-action-menu.component';
import { MeasuringPointActionMenuComponent } from '@modules/customers-csrd/components/measuring-point-action-menu/measuring-point-action-menu.component';
import { FilterService as MeasuringPointFilterService } from '@modules/customers-csrd/services/measuring-points/filter.service';
import { FilterService as FiscalYearFilterService } from '@modules/customers-csrd/services/fiscal-years/filter.service';
import { FiscalYearService } from '@modules/customers-csrd/services/fiscal-years/fiscal-years.service';
import { MeasuringPointsService } from '@modules/customers-csrd/services/measuring-points/measuring-points.service';
import { FormService as MeasuringPointsFormService } from '@modules/customers-csrd/services/measuring-points/form.service';
import { FormService as FiscalYearFormService } from '@modules/customers-csrd/services/fiscal-years/form.service';
import { FaqComponent } from './commons/components/faq/faq.component';
import { SupportComponent } from '@modules/support/support.component';
import { MainDataViewType } from './commons/components/create-edit-dialog/main-data-view-type.enum';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: { userData: UserResolver, langChosenPriorLogin: LanguageResolver },
    children: [
      {
        path: ROUTENAMES.HOME,
        component: HomeComponent,
        data: {
          title: 'Hjem',
          icon: 'home',
          description: 'This is the main page description.',
          translationKey: 'home',
          status: MenuItemStatus.POPULATED,
        },
        resolve: { langChosenPriorLogin: LanguageResolver },
      },
      {
        path: ROUTENAMES.ACCOUNTING,
        component: ReportWrapperComponent,
        data: {
          title: 'CO2 Accounting',
          icon: 'co2',
          description: 'CO2 Accounting',
          translationKey: 'accounting',
          status: MenuItemStatus.POPULATED,
        },
        resolve: { reports: PbiReportsResolver },
        runGuardsAndResolvers: 'always',
      },
      {
        path: ROUTENAMES.SCENARIOS,
        component: ScenariosAndTemplatesWrapperComponent,
        data: {
          title: 'Scenarios',
          icon: 'lightbulb_outline',
          description: 'Scenarios',
          translationKey: 'scenarios',
          status: MenuItemStatus.POPULATED
        },
        resolve: { scenarios: ScenariosResolver },
        runGuardsAndResolvers: 'always',
      },
      {
        path: ROUTENAMES.TEMPLATES,
        component: TemplatesListComponent,
        data: {
          title: 'Templates',
          icon: 'about',
          description: 'Templates',
          translationKey: 'templates',
          status: MenuItemStatus.EMPTY
        },
        resolve: { templates: TemplatesResolver },
        runGuardsAndResolvers: 'always',
      },
      {
        path: ROUTENAMES.INITIATIVES,
        component: InitiativesComponent,
        data: {
          title: 'Initiatives',
          icon: 'query_stats',
          description: 'Initiatives',
          translationKey: 'initiatives',
          status: MenuItemStatus.POPULATED
        },
        resolve: { initiatives: InitiativesResolver },
        runGuardsAndResolvers: 'always',
      },
      // {
      //   path: ROUTENAMES.CUSTOMERSOWNDATA,
      //   component: DataViewComponent,
      //   data: {
      //     title: 'Customers own data',
      //     icon: 'table_view',
      //     description: 'Customers own data',
      //     translationKey: 'customers-own-data',
      //     status: MenuItemStatus.POPULATED,
      //     dataViewType: DataViewType.CUSTOMERS_OWN_DATA,
      //     showActionMenu: true,
      //     menuComponent: CodActionMenuComponent
      //   },
      //   resolve: { data: CustomersOwnDataResolver },
      //   providers: [
      //     { provide: 'ITableService', useExisting: CutomersOwnDataTableService },
      //     { provide: 'IGetDataService', useExisting: CustomersOwnDataGetService },
      //     { provide: 'IDataService', useExisting: CustomersOwnDataService },
      //     { provide: 'IFilterService', useExisting: CutomersOwnDataFilterService },
      //     { provide: 'IFormService', useExisting: CustomersOwnDataFormService }
      //   ],
      //   runGuardsAndResolvers: 'always',
      // },
      {
        path: ROUTENAMES.CUSTOMERSOWNDATA,
        component: CodTabViewComponent,
        data: {
          title: 'Customers own data',
          icon: 'table_view',
          description: 'Customers own data',
          translationKey: 'customers-own-data'
        },
        children: [
          {
            path: '',
            component: DataViewComponent,
            data: {
              title: 'Customers own data',
              icon: 'table_view',
              description: 'Customers own data',
              translationKey: 'customers-own-data',
              status: MenuItemStatus.POPULATED,
              mainDataViewType: MainDataViewType.CUSTOMERS_OWN_DATA,
              dataViewType: DataViewType.CUSTOMERS_OWN_DATA,
              activityEventType: ActivityEventType.CustomersOwnDataViewActivated,
              showTitle: false,
              showActionMenu: true,
              orderBy: 'order by TransactionDate asc, SupplierDisplayValue asc, ProductID asc',
              menuComponent: CodActionMenuComponent
            },
            resolve: { data: CustomersOwnDataResolver },
            providers: [
              { provide: 'ITableService', useExisting: CutomersOwnDataTableService },
              { provide: 'IGetDataService', useExisting: CustomersOwnDataGetService },
              { provide: 'IDataService', useExisting: CustomersOwnDataService },
              { provide: 'IFilterService', useExisting: CutomersOwnDataFilterService },
              { provide: 'IFormService', useExisting: CustomersOwnDataFormService }
            ],
            runGuardsAndResolvers: 'always',
          },
          {
            path: ROUTENAMES.CUSTOMERSOWNDATAEXCLUSION,
            component: DataViewComponent,
            data: {
              title: 'Exclusion',
              icon: 'category_outline',
              description: 'Exclusion',
              translationKey: 'customers-own-data-exclusion',
              status: MenuItemStatus.POPULATED,
              mainDataViewType: MainDataViewType.CUSTOMERS_OWN_DATA,
              dataViewType: DataViewType.CUSTOMERSOWNDATAEXCLUSION,
              activityEventType: ActivityEventType.CustomersExclusionRuleViewActivated,
              sliderFieldName: 'ruleEnabled',
              showDeleteButton: true,
              showTitle: false,
              showActionMenu: true,
              orderBy: 'order by Id desc',
              menuComponent: CodExclusionActionMenuComponent
            },
            resolve: { data: CustomersOwnDataExclusionResolver },
            providers: [
              { provide: 'ITableService', useExisting: CustomersExclusionRulesTableService },
              { provide: 'IGetDataService', useExisting: CustomersExclusionRulesGetService },
              { provide: 'IDataService', useExisting: CustomersExclusionRulesService },
              { provide: 'IFilterService', useExisting: CutomersExclusionRulesFilterService },
              { provide: 'IFormService', useExisting: CustomersOwnDataFormService }
            ],
            runGuardsAndResolvers: 'always',
          }
        ]
      },
      {
        path: ROUTENAMES.PSE_IN_USE,
        component: PseTabViewComponent,
        data: {
          title: 'Product specific emissions',
          icon: 'category_outline',
          description: 'Product specific emissions',
          translationKey: 'product-specific-emissions',
          showActionMenu: false
        },
        children: [
          {
            path: '',
            component: DataViewComponent,
            data: {
              title: 'Product specific emissions',
              icon: 'category_outline',
              description: 'Product specific emissions',
              translationKey: 'product-specific-emissions',
              status: MenuItemStatus.POPULATED,
              mainDataViewType: MainDataViewType.PRODUCT_SPECIFIC_EMISSION,
              dataViewType: DataViewType.PSE_IN_USE,
              activityEventType: ActivityEventType.CustomersProductEmissionViewActivated,
              sliderFieldName: 'isActive',
              checkboxFieldName: 'isOwnRecord',
              showDeleteButton: true,
              showTitle: false,
              orderBy: 'order by SupplierDisplayValue asc, ItemIdDisplayValue asc, ItemName asc, UnitCode asc',
              menuComponent: PseActionMenuComponent
            },
            resolve: { data: ProductSpecificEmissionsResolver },
            providers: [
              { provide: 'ITableService', useExisting: ProductSpecificEmissionsTableService },
              { provide: 'IGetDataService', useExisting: ProductSpecificEmissionsGetService },
              { provide: 'IDataService', useExisting: ProductSpecificEmissionsService },
              { provide: 'IFilterService', useExisting: FilterInUseService },
              { provide: 'IFormService', useExisting: ProductSpecificEmissionsFormService },
            ],
            runGuardsAndResolvers: 'always',
          },
          {
            path: ROUTENAMES.PSE_CAN_BE_USED,
            component: DataViewComponent,
            data: {
              title: 'Product specific emissions',
              icon: 'category_outline',
              description: 'Product specific emissions',
              translationKey: 'product-specific-emissions',
              status: MenuItemStatus.POPULATED,
              mainDataViewType: MainDataViewType.PRODUCT_SPECIFIC_EMISSION,
              dataViewType: DataViewType.PSE_CAN_BE_USED,
              activityEventType: ActivityEventType.CustomersProductEmissionViewActivated,
              sliderFieldName: 'isActive',
              checkboxFieldName: 'isOwnRecord',
              showDeleteButton: true,
              showTitle: false,
              orderBy: 'order by SupplierDisplayValue asc, ItemIdDisplayValue asc, ItemName asc, UnitCode asc',
              menuComponent: PseActionMenuComponent
            },
            resolve: { data: ProductSpecificEmissionsAvailableResolver },
            providers: [
              { provide: 'ITableService', useExisting: ProductSpecificEmissionsTableService },
              { provide: 'IGetDataService', useExisting: ProductSpecificEmissionsAvailableService },
              { provide: 'IDataService', useExisting: ProductSpecificEmissionsService },
              { provide: 'IFilterService', useExisting: FilterAvailableService },
              { provide: 'IFormService', useExisting: ProductSpecificEmissionsFormService },
            ],
            runGuardsAndResolvers: 'always',
          },
          {
            path: ROUTENAMES.PSE_CANDIDATES,
            component: DataViewComponent,
            data: {
              title: 'Product specific emissions',
              icon: 'category_outline',
              description: 'Product specific emissions',
              translationKey: 'product-specific-emissions',
              status: MenuItemStatus.POPULATED,
              mainDataViewType: MainDataViewType.PRODUCT_SPECIFIC_EMISSION,
              dataViewType: DataViewType.PSE_CANDIDATES,
              activityEventType: ActivityEventType.CustomersProductEmissionViewActivated,
              sliderFieldName: 'isActive',
              checkboxFieldName: 'isOwnRecord',
              showDeleteButton: true,
              showTitle: false,
              orderBy: 'order by SupplierDisplayValue asc, ItemIdDisplayValue asc, ItemName asc, UnitCode asc',
              menuComponent: PseActionMenuComponent
            },
            resolve: { data: ProductSpecificEmissionsCandidatesResolver },
            providers: [
              { provide: 'ITableService', useExisting: ProductSpecificEmissionsTableService },
              { provide: 'IGetDataService', useExisting: ProductSpecificEmissionsCandidatesService },
              { provide: 'IDataService', useExisting: ProductSpecificEmissionsService },
              { provide: 'IFilterService', useExisting: FilterCandidatesService },
              { provide: 'IFormService', useExisting: ProductSpecificEmissionsFormService },
            ],
            runGuardsAndResolvers: 'always',
          },
          {
            path: ROUTENAMES.PSE_THE_REST,
            component: DataViewComponent,
            data: {
              title: 'Product specific emissions',
              icon: 'category_outline',
              description: 'Product specific emissions',
              translationKey: 'product-specific-emissions',
              status: MenuItemStatus.POPULATED,
              mainDataViewType: MainDataViewType.PRODUCT_SPECIFIC_EMISSION,
              dataViewType: DataViewType.PSE_THE_REST,
              activityEventType: ActivityEventType.CustomersProductEmissionViewActivated,
              sliderFieldName: 'isActive',
              checkboxFieldName: 'isOwnRecord',
              showDeleteButton: true,
              showTitle: false,
              orderBy: 'order by SupplierDisplayValue asc, ItemIdDisplayValue asc, ItemName asc, UnitCode asc',
              menuComponent: PseActionMenuComponent
            },
            resolve: { data: ProductSpecificEmissionsOthersResolver },
            providers: [
              { provide: 'ITableService', useExisting: ProductSpecificEmissionsTableService },
              { provide: 'IGetDataService', useExisting: ProductSpecificEmissionsOthersService },
              { provide: 'IDataService', useExisting: ProductSpecificEmissionsService },
              { provide: 'IFilterService', useExisting: FilterOthersService },
              { provide: 'IFormService', useExisting: ProductSpecificEmissionsFormService },
            ],
            runGuardsAndResolvers: 'always',
          },
        ]
      },
      // {
      //   path: ROUTENAMES.CUSTOMERSOWNDATA,
      //   component: DataViewComponent,
      //   data: {
      //     title: 'Customers own data',
      //     icon: 'table_view',
      //     description: 'Customers own data',
      //     translationKey: 'customers-own-data',
      //     status: MenuItemStatus.POPULATED,
      //     dataViewType: DataViewType.CUSTOMERS_OWN_DATA,
      //     menuComponent: CodActionMenuComponent
      //   },
      //   resolve: { data: CustomersOwnDataResolver },
      //   providers: [
      //     { provide: 'ITableService', useExisting: CutomersOwnDataTableService },
      //     { provide: 'IDataService', useExisting: CustomersOwnDataService },
      //     { provide: 'IFilterService', useExisting: CutomersOwnDataFilterService },
      //     { provide: 'IFormService', useExisting: CustomersOwnDataFormService }
      //   ],
      //   runGuardsAndResolvers: 'always',
      // },
      // EMISSIONS !!
      // {
      //   path: ROUTENAMES.PRODUCTSPECIFICEMISSIONS,
      //   component: DataViewComponent,
      //   data: {
      //     title: 'Product specific emissions',
      //     icon: 'category_outline',
      //     description: 'Product specific emissions',
      //     translationKey: 'product-specific-emissions',
      //     status: MenuItemStatus.POPULATED,
      //     dataViewType: DataViewType.PRODUCT_SPECIFIC_EMISSION,
      //     activityEventType: ActivityEventType.CustomersProductEmissionViewActivated,
      //     sliderFieldName: 'isActive',
      //     checkboxFieldName: 'isOwnRecord',
      //     showDeleteButton: true,
      //     orderBy: 'order by SupplierDisplayValue asc, ItemIdDisplayValue asc, ItemName asc, UnitCode asc',
      //     menuComponent: PseActionMenuComponent
      //   },
      //   resolve: { data: ProductSpecificEmissionsResolver },
      //   providers: [
      //     { provide: 'ITableService', useExisting: ProductSpecificEmissionsTableService },
      //     { provide: 'IDataService', useExisting: ProductSpecificEmissionsService },
      //     { provide: 'IFilterService', useExisting: ProductSpecificEmissionsFilterService },
      //     { provide: 'IFormService', useExisting: ProductSpecificEmissionsFormService },
      //   ],
      //   runGuardsAndResolvers: 'always',
      // },
      {
        path: ROUTENAMES.CUSTOMERSAVERAGEPRICES,
        component: DataViewComponent,
        data: {
          title: 'Customers average prices',
          icon: 'price_change',
          description: 'Customers average prices',
          translationKey: 'customers-average-price',
          status: MenuItemStatus.POPULATED,
          mainDataViewType: MainDataViewType.CUSTOMERS_AVERAGE_PRICE,
          dataViewType: DataViewType.CUSTOMERS_AVERAGE_PRICE,
          activityEventType: ActivityEventType.CustomersAveragePriceViewActivated,
          sliderFieldName: 'useEnteredPrice',
          showActionMenu: true,
          orderBy: 'order by Supplier.SupplierName asc, Unspsc asc, Unit asc',
          menuComponent: CapActionMenuComponent
        },
        resolve: { data: CustomersAveragePriceResolver },
        providers: [
          { provide: 'ITableService', useExisting: CutomersAveragePricesTableService },
          { provide: 'IGetDataService', useExisting: CustomersAveragePriceGetService },
          { provide: 'IDataService', useExisting: CustomersAveragePriceService },
          { provide: 'IFilterService', useExisting: CutomersAveragePriceFilterService },
          { provide: 'IFormService', useExisting: CustomersAveragePriceFormService },
        ],
        runGuardsAndResolvers: 'always',
      },
      {
        path: ROUTENAMES.CSRD_MEASURING_POINTS,
        component: CsrdTabViewComponent,
        data: {
          title: 'Data',
          icon: 'numbers',
          description: 'CData',
          translationKey: 'csrd'
        },
        children: [
          {
            path: '',
            component: DataViewComponent,
            data: {
              title: 'Measuring points',
              icon: 'table_view',
              description: 'Measuring points',
              translationKey: 'csrd',
              status: MenuItemStatus.POPULATED,
              mainDataViewType: MainDataViewType.CSRD,
              dataViewType: DataViewType.CSRD_MEASURING_POINTS,
              activityEventType: ActivityEventType.MeasuringPointViewActivated,
              showTitle: false,
              showActionMenu: true,
              orderBy: 'order by Id asc',
              menuComponent: MeasuringPointActionMenuComponent
            },
            resolve: { data: MeasuringPointsResolver },
            providers: [
              { provide: 'ITableService', useExisting: MeasuringPointsTableService },
              { provide: 'IGetDataService', useExisting: MeasuringPointsGetService },
              { provide: 'IDataService', useExisting: MeasuringPointsService },
              { provide: 'IFilterService', useExisting: MeasuringPointFilterService },
              { provide: 'IFormService', useExisting: MeasuringPointsFormService }
            ],
            runGuardsAndResolvers: 'always',
          },
          {
            path: ROUTENAMES.CSRD_FISCAL_YEARS,
            component: DataViewComponent,
            data: {
              title: 'Fiscal years',
              icon: 'category_outline',
              description: 'Fiscal years',
              translationKey: 'csrd',
              status: MenuItemStatus.POPULATED,
              mainDataViewType: MainDataViewType.CSRD,
              dataViewType: DataViewType.CSRD_FISCAL_YEARS,
              activityEventType: ActivityEventType.FiscalYearViewActivated,
              showDeleteButton: true,
              showTitle: false,
              showActionMenu: true,
              orderBy: 'order by Id asc',
              menuComponent: FiscalYearActionMenuComponent
            },
            resolve: { data: FiscalYearsResolver },
            providers: [
              { provide: 'ITableService', useExisting: FiscalYearsTableService },
              { provide: 'IGetDataService', useExisting: FiscalYearsGetService },
              { provide: 'IDataService', useExisting: FiscalYearService },
              { provide: 'IFilterService', useExisting: FiscalYearFilterService },
              { provide: 'IFormService', useExisting: FiscalYearFormService }
            ],
            runGuardsAndResolvers: 'always',
          }
        ]
      },
      {
        path: ROUTENAMES.USER_GUIDE,
        component: UserGuideComponent,
        data: {
          title: 'User guide',
          icon: 'live_help',
          description: 'User guide',
          translationKey: 'user-guide',
          status: MenuItemStatus.POPULATED,
        },
      },
      {
        path: ROUTENAMES.FAQ,
        component: FaqComponent,
        data: {
          title: 'FAQ',
          icon: 'help',
          description: 'FAQ',
          translationKey: 'help',
          status: MenuItemStatus.POPULATED,
        },
      },
      {
        path: ROUTENAMES.SUPPORT,
        component: SupportComponent,
        data: {
          title: 'Support',
          icon: 'support_agent',
          description: 'Support',
          translationKey: 'support',
          status: MenuItemStatus.POPULATED,
        },
      },
    ],
  },
  {
    path: ROUTENAMES.PROCESSING,
    component: NotFoundComponent,
    data: {
      title: 'Data processing',
      icon: 'about',
      description: 'Data processing',
      translationKey: 'data-processing',
      status: MenuItemStatus.EMPTY,
    },
  },
  {
    path: ROUTENAMES.REPORT_BUILDING,
    component: NotFoundComponent,
    data: {
      title: 'Report building',
      icon: 'about',
      description: 'Report building',
      translationKey: 'report-building',
      status: MenuItemStatus.EMPTY,
    },
  },
  {
    path: ROUTENAMES.ABOUT,
    component: NotFoundComponent,
    data: {
      title: 'About',
      icon: 'about',
      description: 'About the Co2 Calculator.',
      translationKey: 'about',
      status: MenuItemStatus.EMPTY,
    },
  },
  {
    path: ROUTENAMES.FORUM,
    component: NotFoundComponent,
    data: {
      title: 'Forum',
      icon: 'about',
      description: 'Forum',
      translationKey: 'forum',
      status: MenuItemStatus.EMPTY,
    },
  },
  {
    path: ROUTENAMES.CONTACT,
    component: NotFoundComponent,
    data: {
      title: 'Contact',
      icon: 'about',
      description: 'Contact',
      translationKey: 'contact',
      status: MenuItemStatus.EMPTY,
    },
  },
  {
    path: ROUTENAMES.SETTINGS,
    component: NotFoundComponent,
    data: {
      title: 'Settings',
      icon: 'about',
      description: 'Settings',
      translationKey: 'settings',
      status: MenuItemStatus.EMPTY,
    },
  },
  {
    path: ROUTENAMES.EMPTYHOME,
    component: EmptyHomeScreenComponent,
  },
  {
    path: ROUTENAMES.FORBIDDEN,
    component: ForbiddenComponent,
  },
  {
    path: ROUTENAMES.UNAUTHORIZED,
    component: UnauthorizedComponent,
  },
  {
    path: ROUTENAMES.LOGIN,
    component: LoginComponent,
  },
  {
    path: ROUTENAMES.NOTFOUND,
    component: NotFoundComponent,
  },
  {
    path: ROUTENAMES.ERROR,
    component: SomethingWentWrongComponent,
  },
  { path: '**', redirectTo: '/' + ROUTENAMES.NOTFOUND, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
