import { NgModule } from '@angular/core';
import { DataViewComponent } from './data-view.component';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// Custom modules
import { TranslateModule } from '@ngx-translate/core';
import { DimaTableLibraryModule } from '@dima/table-library';
import { BrowserModule } from '@angular/platform-browser';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    declarations: [
        DataViewComponent,
    ],
    imports: [
        BrowserModule,
        TranslateModule,
        DimaTableLibraryModule,
        MatIconModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatButtonModule,
        MatCheckboxModule
    ],
    exports: [DataViewComponent]
})
export class DataViewModule { }
