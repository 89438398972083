import { Component, OnInit } from '@angular/core';
import { PbiReportService } from '@modules/report/services/pbi-report.service';
import { async } from '@angular/core/testing';
import { ReportData } from '../../../../commons/models/powerbi-model';
import { CurrentLanguageService } from 'src/app/services/current-language.service';

@Component({
  selector: 'app-header-user-report-select',
  templateUrl: './header-user-report-select.component.html',
  styleUrls: ['./header-user-report-select.component.scss'],
})
export class HeaderUserReportSelectComponent implements OnInit {
  public list: ReportData;

  constructor(public reportService: PbiReportService, private currentLanguageService: CurrentLanguageService) {}

  ngOnInit(): void {}

  triggerReportChange(item: any) {
    this.reportService
      .getSpecificReport(item.pbiWorkspaceID, item.pbiReportID)
      .subscribe((embedTokenInfo: ReportData) => {
        this.reportService.setReportsMetaData(
          item.pbiReportID,
          item.pbiWorkspaceID,
          embedTokenInfo.description
        );

        this.reportService.loadReport(embedTokenInfo.embedTokenInfo, this.currentLanguageService.lang, true);
      });
  }

  protected readonly async = async;
}
