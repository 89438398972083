import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import * as tableLibrary from '@dima/table-library';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { PageResponse } from '../../models/page-response';
import { IDataService, IGetDataService } from './data-service.interface';
import { IFilterService } from './filter-service.interface';
import { ITableService } from './table-service.interface';
import { ComponentType } from '@angular/cdk/portal';
import { CreateEditDialogFactory } from '../create-edit-dialog/create-edit-dialog-factory';
import { DataViewType } from '../create-edit-dialog/data-view-type.enum';
import { CustomDialogService } from '../../dialog/services/custom-dialog.service';
import { DialogService } from 'src/app/services/dialog.service';
import { SnackbarService } from '../../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ActivityService } from 'src/app/services/activity.service';
import { ActivityEventType, UserActivity } from '../../models/activity.model';
import { UserService } from '@modules/shell/services/user.service';

@Component({
  selector: 'app-data-view',
  templateUrl: './data-view.component.html',
  styleUrls: ['./data-view.component.scss'],
})
export class DataViewComponent extends CustomDialogService implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild('table') table: tableLibrary.DimaTableComponent;
  @ViewChild('paginator') paginator: tableLibrary.DimaTablePaginatorComponent;
  @ViewChild('tableContainer', { static: false }) tableElement: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('dynamicContainer', { read: ViewContainerRef }) dynamicContainer: ViewContainerRef;
  private initialDataSubscription: Subscription;
  private capDeleteSubscription: Subscription;
  rowAllCheckedSubject = new BehaviorSubject(false);
  rowAllCheckedValue = false;
  rowAllCheckedPreviousValue = false;
  deleteSelectionMade = false;
  orderBy: string;
  translationKey: string;
  dataSubscription: Subscription;

  dialogComponent: ComponentType<any>;
  DataViewType = DataViewType;
  dataViewType: DataViewType;
  activityEventType: ActivityEventType;
  sliderFieldName: string;
  checkboxFieldNames: string[];
  showDeleteButton: boolean = true;
  showTitle: boolean = true;
  menuComponent: any;
  showActionMenu: boolean = true;

  filterSubject: Subject<tableLibrary.Filter>;

  constructor(
    @Inject('IGetDataService') public getDataService: IGetDataService,
    @Inject('IDataService') public dataService: IDataService,
    @Inject('ITableService') public tableService: ITableService,
    @Inject('IFilterService') public filterService: IFilterService,
    private activatedRoute: ActivatedRoute,
    private tenantSelectService: TenantSelectService,
    private cdRef: ChangeDetectorRef,
    private createEditDialogFactory: CreateEditDialogFactory,
    private el: ElementRef,
    private renderer: Renderer2,
    dialogService: DialogService,
    snackbarService: SnackbarService,
    translateService: TranslateService,
    private baseFilterService: tableLibrary.FilterService,
    private localStorageService: LocalStorageService,
    private activityService: ActivityService,
    private userService: UserService
  ) {
    super(dialogService, getDataService, dataService, tableService, filterService, snackbarService, translateService);
    this.tableService.setCols();
  }

  ngOnInit(): void {
    this.filterService.initializeTranslations();
    this.filterSubject = new Subject<tableLibrary.Filter>();
    this.filterSubject.pipe(debounceTime(500)).subscribe((filter: tableLibrary.Filter) => {
      this.tableService.pageSize = this.table.paginator.pageSize;
      this.tableService.page = this.paginator.paginator.pageIndex;

      let tableFiltersChanged = false;

      if (filter.column?.filterType == tableLibrary.FilterTypes.input
        || filter.column?.filterType == tableLibrary.FilterTypes.select
        || filter.column?.filterType == tableLibrary.FilterTypes.checksSearch) {
        let found = this.tableService.filters.filter(x => x?.column?.name == filter.column?.name)[0];
        if (found && filter.text == '') {
          this.tableService.filters = this.tableService.filters.filter(x => x?.column?.name != filter?.column?.name);
        }
        else if (found && filter.text != '' && found.text != filter.text) {
          found.text = this.filterService.setFilter(filter);
        }
        else {
          filter.text = this.filterService.setFilter(filter);
          const existing = this.tableService.filters.filter((x: tableLibrary.Filter) => x.column?.name == filter?.column?.name);
          if (existing?.length > 0) {
            filter.column.pipe = null;
            existing[0] = filter;
            tableFiltersChanged = true;
          } else if (filter.column?.name != 'GLOBALFILTER') {
            filter.column.pipe = null;
            this.tableService.filters.push(filter);
            tableFiltersChanged = true;
          }
        }
      } else if (filter.column?.filterType == tableLibrary.FilterTypes.range) {
        let found = this.tableService.filters.filter(x => x?.column?.name == filter.column?.name)[0] as tableLibrary.filterRange;
        const rangeFilter = filter as tableLibrary.filterRange;
        if (found && !rangeFilter.overValue && !rangeFilter.isValue && !rangeFilter.underValue) {
          this.tableService.filters = this.tableService.filters.filter(x => x?.column?.name != filter?.column?.name);
          tableFiltersChanged = true;
        }
        else if (found && (rangeFilter?.isValue != found?.isValue || rangeFilter?.underValue != found?.underValue || rangeFilter?.overValue != found?.overValue)) {
          found.isValue = rangeFilter?.isValue;
          found.underValue = rangeFilter?.underValue;
          found.overValue = rangeFilter?.overValue;
          tableFiltersChanged = true;
        } else {
          const existing = this.tableService.filters.filter((x: tableLibrary.Filter) => x.column?.name == filter?.column?.name);
          if (existing?.length > 0) {
            filter.column.pipe = null;
            existing[0] = filter;
            tableFiltersChanged = true;
          } else if (filter.column?.name != 'GLOBALFILTER') {
            filter.column.pipe = null;
            this.tableService.filters.push(filter);
            tableFiltersChanged = true;
          }
        }
      } else if (filter.column?.filterType == tableLibrary.FilterTypes.date) {
        let found = this.tableService.filters.filter(x => x?.column?.name == filter.column?.name)[0] as tableLibrary.filterDate;
        const dateFilter = filter as tableLibrary.filterDate;
        if (found && !dateFilter?.date) {
          this.tableService.filters = this.tableService.filters.filter(x => x?.column?.name != filter?.column?.name);
        }
        else if (found && found?.date != dateFilter?.date) {
          found.date = dateFilter.date;
        }
        else {
          const existing = this.tableService.filters.filter((x: tableLibrary.Filter) => x.column?.name == filter?.column?.name);
          if (existing?.length > 0) {
            filter.column.pipe = null;
            existing[0] = filter;
            tableFiltersChanged = true;
          } else if (filter.column?.name != 'GLOBALFILTER') {
            filter.column.pipe = null;
            this.tableService.filters.push(filter);
            tableFiltersChanged = true;
          }
        }
      }

      if (tableFiltersChanged) {
        this.tableService.page = 0;
        this.paginator.paginator.pageIndex = 0;
      }

      this.getDataService.get({
        search: 1,
        filters: this.tableService.filters,
        pageSize: this.tableService?.pageSize?.toString() ?? this.tableService.pageSize.toString(),
        orderBy: this.orderBy,
        page: this.tableService.page.toString(),
      }).subscribe((pageResponse: PageResponse) => {
        setTimeout(() => {
          this.tableService.setData(pageResponse);
          this.cdRef.detectChanges();
          this.tableService.totalCount = pageResponse.totalFilteredProducts;
        }, 0);
      });

    });

    this.baseFilterService.filterChange = (value) => {
      this.filterSubject.next(value);
    };

    this.table?.clearAllFilters();

    this.initialDataSubscription = this.activatedRoute.data.subscribe(
      (data) => {
        this.dataViewType = data.dataViewType;
        this.sliderFieldName = data.sliderFieldName;
        this.checkboxFieldNames = data.checkboxFieldNames;
        this.menuComponent = data.menuComponent;
        this.orderBy = data.orderBy ?? `order by Id desc`;
        this.activityEventType = data.activityEventType;
        this.activityService.sendActivity(new UserActivity(`${data.description} - view activated`, this.userService.currentUser.username, this.activityEventType));
        if (data.showDeleteButton !== undefined)
          this.showDeleteButton = data.showDeleteButton;
        if (data.showTitle !== undefined)
          this.showTitle = data.showTitle;
        this.dialogComponent = this.createEditDialogFactory.getInstance(this.dataViewType);
        this.translationKey = data.translationKey;
        this.showActionMenu = data.showActionMenu;
        this.tableService.setData(data.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  async ngAfterViewInit(): Promise<void> {
    this.filterService.resetFilters();
    await this.filterService.initializeFilters();
    this.cdRef.detectChanges();

    if (this.table) {
      this.cdRef.detectChanges();

      this.setHeaderIcons();
      this.setCustomHeaderTitles();

      this.tableService.clearTableSelection.subscribe(() => {
        this.table.selection.clear();
      });

      // Workaround:
      // 1. After changing the selected columns, find first table row and trigger click event on it in order to make column change visible.
      // 2. Set header icons again as they disappear after column selection change
      // TODO: handle the same when no rows found.
      this.table.columnsChanged.subscribe(() => {
        let visibleColumns = this.table.displayColumns;
        this.localStorageService.addOrUpdateItem(this.dataViewType.toString(), JSON.stringify(visibleColumns));

        const children = this.tableElement.nativeElement.getElementsByClassName('dima-table__row');
        if (children) {
          children[0].click();
          setTimeout(() => {

            this.setHeaderIcons();
            this.filterService.resetFilters();
            this.filterService.initializeTranslations();
            this.filterService.initializeFilters();

          }, 1000);
        }
        this.table.cellClick.next();
      })

      this.table.rowChecked.subscribe(x => {
        this.tableService.selectedRows = this.table?.selection?.selected;
        const anyRowSelected = this.tableService.selectedRows?.length > 0;
        this.tableService.anyRowSelectedSubject.next(anyRowSelected);
      });

      this.table.rowAllChecked.subscribe(x => {
        this.tableService.selectedRows = this.table?.selection?.selected;
        const anyRowSelected = this.tableService.selectedRows?.length > 0;
        this.tableService.anyRowSelectedSubject.next(anyRowSelected);
      });

      this.table.navigation.subscribe(() => {
        this.cdRef.detectChanges();
      });

      this.table.source.sortingDataAccessor = (data, sortHeader): any => { };
    }

    if (this.showActionMenu) {
      const compRef = this.dynamicContainer.createComponent(this.menuComponent);
      compRef.changeDetectorRef.detectChanges();
    }

    await this.tenantSelectService.selectIdpUrlParam();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  navigation(navigation: tableLibrary.Navigation): void {
    if (this.rowAllCheckedValue === true) {
      this.rowAllCheckedValue = false;
      return;
    }

    // When the navigation event is triggered (sorting, paginating, filtering, etc.)
    // navigation event parameters are stored in tableService to be used for purposes
    // of the table after different events (creation, editing, deletion, etc.)
    this.tableService.pageSize = navigation.pageSize;
    this.tableService.page = navigation.page;

    if (navigation.sortDirection !== 'Undefined' && navigation.sortColumn?.toLowerCase() !== 'selection') {
      const orderByStatement = `order by ${navigation.sortColumn.charAt(0).toUpperCase() + navigation.sortColumn.slice(1)}`;
      if (navigation.sortDirection === 'Increasing' && navigation.sortColumn) {
        this.orderBy = `${orderByStatement} asc`;
      }
      if (navigation.sortDirection === 'Decreasing' && navigation.sortColumn) {
        this.orderBy = `${orderByStatement} desc`;
      }
    }

    this.tableService.pagedModel = {
      search: 1,
      pageSize: navigation.pageSize.toString(),
      page: this.tableService.page.toString(),
      orderBy: this.orderBy,
      filters: this.tableService.filters || [],
    };

    this.dataSubscription = this.getDataService
      .get(this.tableService.pagedModel)
      .subscribe((data: PageResponse) => {
        this.tableService.setData(data);
      });
  }

  rowAllChecked(event: any) {
    this.rowAllCheckedValue = true;
  }

  async onSliderChange(row) {
    row[this.sliderFieldName] = !row[this.sliderFieldName];
    (await this.dataService.sliderChange(row, row[this.sliderFieldName])).subscribe((data: PageResponse) => {
      this.getDataService
        .get({
          search: 1,
          filters: this.tableService.filters,
          pageSize: this.tableService.pageSize.toString(),
          orderBy: this.orderBy,
          page: this.tableService.page.toString()
        })
        .subscribe((pageResponse: PageResponse) => {
          this.tableService.setData(pageResponse);
          this.filterService.resetFilters();
          this.filterService.initializeFilters();
          this.cdRef.detectChanges();
          this.tableService.totalCount = pageResponse.totalFilteredProducts;
        });
    });
  }

  rowDeleteButtonDisabled(row): boolean {
    // todo: create 'deleteButtonDisabled' property in the average price dto on the backend side
    if (row.averagePricePerUnitCalc && !row.averagePricePerUnitEntered) {
      return true;
    }
    if (row.deleteButtonDisabled) { // product emissions only
      return true;
    }

    return false;
  }

  isSliderDisabled(row): boolean {
    if (this.dataViewType === DataViewType.CUSTOMERS_AVERAGE_PRICE && !row.useEnteredPrice && !row.averagePricePerUnitEntered) {
      return true;
    } else if (this.dataViewType === DataViewType.PSE_IN_USE && row.verified) {
      return true;
    }

    return false;
  }

  setHeaderIcons(): void {
    const foundColumns = this.table.getVisibleColumns().filter(x => x.hasOwnProperty('exDataObj'));
    if (foundColumns) {
      foundColumns.forEach(item => {
        const elements = this.el.nativeElement.getElementsByClassName('mat-sort-header');

        for (let i = 0; i < elements.length; i++) {
          const foundElement = elements[i];
          if (foundElement.classList.contains(`mat-column-${item.name}`) && item.exDataObj.hasOwnProperty('headerIcon')) {
            const iconContent = `<img style="padding-left: 0.4rem; padding-top: 3px;" src="../../../../../assets/icons/${item.exDataObj.headerIcon}" />`;
            this.renderer.setProperty(foundElement, 'innerHTML', iconContent);
          }
        }
      });
    }
  }

  setCustomHeaderTitles(): void {
    const foundColumns = this.table.getVisibleColumns().filter(x => x.hasOwnProperty('exDataObj'));
    if (foundColumns) {
      foundColumns.forEach(item => {
        const elements = this.el.nativeElement.getElementsByClassName('mat-sort-header');

        for (let i = 0; i < elements.length; i++) {
          const foundElement = elements[i];
          if (foundElement.classList.contains(`mat-column-${item.name}`) && item.exDataObj.hasOwnProperty('customTitle')) {
            this.renderer.setProperty(foundElement, 'innerHTML', item.exDataObj.customTitle);
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.initialDataSubscription)
      this.initialDataSubscription.unsubscribe();

    if (this.capDeleteSubscription)
      this.capDeleteSubscription.unsubscribe();

    if (this.dataSubscription)
      this.dataSubscription.unsubscribe();
  }
}
