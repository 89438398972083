import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IGetDataService } from 'src/app/commons/components/data-view/data-service.interface';
import { EntityTrackerError } from 'src/app/commons/models/entity-tracker-error';
import { PageModel } from 'src/app/commons/models/page-model';
import { PageResponse } from 'src/app/commons/models/page-response';
import { ApiService } from 'src/app/services/api.service';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { DialogService } from 'src/app/services/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class ProductSpecificEmissionsOthersService implements IGetDataService {
  constructor(private apiService: ApiService, private dialogService: DialogService, private translateService: TranslateService, private languageService: CurrentLanguageService) { }
  get(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    const emptyItemId = pageModel.filters?.filter(x => x.text == this.translateService.instant('product-specific-emissions.item-unknown'))[0];
    if (emptyItemId) {
      emptyItemId.text = null;
      emptyItemId.exactText = '';
    }
    const emptySupplier = pageModel.filters?.filter(x => x.text == this.translateService.instant('product-specific-emissions.empty-supplier'))[0];
    if (emptySupplier) {
      emptySupplier.text = null;
      emptySupplier.exactText = '';
    }
    pageModel.languageCode = this.languageService.lang;
    return this.apiService.getOtherPse(pageModel);
  }
}
