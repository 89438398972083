import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ExpandableTableComponent,
  PaginatorI18n,
} from './expandable-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [ExpandableTableComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    TranslateModule,
    MatInputModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorI18n();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService],
    },
  ],
  exports: [ExpandableTableComponent],
})
export class ExpandableTableModule {}
