import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { DialogService } from '../../../../services/dialog.service';
import { ApiService } from '../../../../services/api.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTENAMES } from '../../../../app-routing.names';
import { TemplatesService } from '@modules/scenarios/services/templates/templates.service';
import { SnackbarService } from '../../../../commons/services/snackbar.service';
import { AdminService } from '../../../../services/admin.service';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { DK } from '../../../../constants/config.constants';
import { CalculateDto } from 'src/app/commons/models/scenario-dto';
import { UserService } from '@modules/shell/services/user.service';
import { UserActivity, ActivityEventType } from 'src/app/commons/models/activity.model';
import { ActivityService } from 'src/app/services/activity.service';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { CalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.service';
import { CalculatorTableService } from '@modules/scenarios/services/calculator/calculator-table.service';

@Component({
    selector: 'app-templates-list',
    templateUrl: './templates-list.component.html',
    styleUrls: ['./templates-list.component.scss'],
    standalone: false
})
export class TemplatesListComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public columnsConfigs = [
    {
      key: 'id',
      name: this.translateService.stream('scenarios.columns.id'),
      width: '5%',
    },
    {
      key: 'name',
      name: this.translateService.stream('scenarios.columns.name'),
      width: '45%',
    },
    {
      key: 'totalLineAmountChange',
      name: this.translateService.stream('scenarios.columns.expense-change'),
      width: '15%',
    },
    {
      key: 'totalEmissionChange',
      name: this.translateService.stream('scenarios.columns.emission-change'),
      width: '15%',
    },
    {
      key: 'email',
      name: this.translateService.stream('scenarios.columns.email'),
      width: '15%',
    },
  ];

  public dataSource: MatTableDataSource<CalculateDto>;
  private templatesSubscription?: Subscription;
  private templatesDeleteSubscription?: Subscription;
  private expenseAndEmissionChange: any;

  maxUnspscsLimit: number = 5;

  constructor(
    private activatedRoute: ActivatedRoute,
    private templatesService: TemplatesService,
    private router: Router,
    private snackbarService: SnackbarService,
    private activityService: ActivityService,
    private userService: UserService,
    private dialogService: DialogService,
    private apiService: ApiService,
    public adminService: AdminService,
    public translateService: TranslateService,
    public tenantSelectService: TenantSelectService,
    public calculatorHelperService: CalculatorHelperService,
    private currentLanguageService: CurrentLanguageService,
    private scenariosTableService: CalculatorTableService
  ) { }

  ngOnInit(): void {
    this.templatesSubscription = this.activatedRoute.data.subscribe(
      (data) => {
        let templates = data.templates;
        templates = this.addTotalExpenseAndEmissionChange(templates);
        templates = this.generateUnspscGridValues(templates);
        this.dataSource = new MatTableDataSource(templates);
      },
      (error) => {
        console.log(error);
      }
    );
    this.tenantSelectService.setCountrySpecificTranslations(
      this.tenantSelectService.userIdp == 'oslo-aad-prod' ||
      this.tenantSelectService.tenantHandlerForm.get('tenant').value ==
      'oslo-aad-prod'
    );

    this.tenantSelectService.setLanguageSpecificTranslations(this.currentLanguageService.lang === 'no');
  }

  ngAfterViewInit(): void {
    this.activityService.sendActivity(new UserActivity('Templates - view activated', this.userService.currentUser.email ?? this.userService.currentUser.username, ActivityEventType.TemplatesViewActivated));
    this.tenantSelectService.selectIdpUrlParam();
  }

  ngOnDestroy(): void {
    this.templatesSubscription.unsubscribe();
    if (this.templatesDeleteSubscription) {
      this.templatesDeleteSubscription.unsubscribe();
    }
  }

  addTotalExpenseAndEmissionChange(templates) {
    this.expenseAndEmissionChange =
      this.templatesService.calculateChanges(templates);
    templates.map((template) => {
      Object.assign(template, {
        totalLineAmountChange: this.expenseAndEmissionChange.find(
          (el) => template.calculationId == el.calculationId
        ).expenseChange,
      });
      Object.assign(template, {
        totalEmissionChange: this.expenseAndEmissionChange.find(
          (el) => template.calculationId == el.calculationId
        ).emissionChange,
      });
    });

    return templates;
  }

  createTemplateDialog() {
    this.scenariosTableService.checkIfHistoricalDataExists().then((result: boolean) => {
      if (result) {
        this.dialogService
          .createTemplate()
          .afterClosed()
          .subscribe((res) => {
            if (res !== 'cancel') {
              this.templatesService.getTemplates().subscribe((templates) => {
                templates = this.addTotalExpenseAndEmissionChange(templates);
                templates = this.generateUnspscGridValues(templates);
                this.dataSource = new MatTableDataSource(
                  templates as CalculateDto[]
                );
                this.snackbarService.openSnackBar('templates.template-created');
              });
            }
          });

      } else {
        this.dialogService.inform({
          message: this.translateService.instant(
            'general.no-historical-data'
          ),
        });
      }
    });
  }

  editTemplate(row) {
    this.dialogService
      .editTemplate(row)
      .afterClosed()
      .subscribe((res) => {
        if (res === 'update') {
          this.templatesService.getTemplates().subscribe((templates) => {
            templates = this.addTotalExpenseAndEmissionChange(templates);
            templates = this.generateUnspscGridValues(templates);
            this.dataSource = new MatTableDataSource(
              templates as CalculateDto[]
            );
            this.snackbarService.openSnackBar('templates.template-updated');
          });
        }
      });
  }

  deleteTemplate({ calculationId }) {
    this.dialogService
      .deleteDialog({
        message: this.translateService.instant('templates.confirmatory-text'),
        confirmText: this.translateService.instant('templates.yes'),
        cancelText: this.translateService.instant('templates.no'),
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === 'confirm') {
          this.templatesDeleteSubscription = this.apiService
            .deleteTemplate(calculationId)
            .subscribe(
              () => { },
              () => { },
              () => {
                this.templatesService.getTemplates().subscribe((templates) => {
                  templates = this.addTotalExpenseAndEmissionChange(templates);
                  templates = this.generateUnspscGridValues(templates);
                  this.dataSource = new MatTableDataSource(
                    templates as CalculateDto[]
                  );
                  this.snackbarService.openSnackBar(
                    'templates.template-deleted'
                  );
                });
              }
            );
        }
      });
  }

  backToScenarios() {
    this.router.navigate([ROUTENAMES.SCENARIOS]);
  }

  public getGridUnspscs(unspscsGrid: any, unspscIndex: number) {
    return unspscsGrid.filter(x => x.index == unspscIndex);
  }

  protected readonly DK = DK;

  private generateUnspscGridValues(templates: any): any {
    templates.forEach((x, i) => {
      let templatesToDisplay = [];
      x.index = x.id;
      x.unspscs
        .forEach((y, mainIndex) => {
          if (y.unspscClassId.includes(',')) {
            const descriptions = y.unspscClassDescription.split(';');
            templatesToDisplay.push(...y.unspscClassId.split(',').map((unspscClassId, i) => <any>{
              unspscClassId: unspscClassId,
              unspscClassDescription: descriptions[i],
              index: mainIndex
            }))
          } else {
            templatesToDisplay.push(<any>{
              unspscClassId: y.unspscClassId,
              unspscClassDescription: y.unspscClassDescription,
              index: mainIndex
            });
          }
        });
      x.unspscsGrid = templatesToDisplay;
    });

    return templates;
  }
}
