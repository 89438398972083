import { Injectable } from '@angular/core';
import * as forms from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@modules/shell/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import {
  OverWriteStatus,
  VerifiedStatus,
} from '../../../commons/models/product-specific-emission-dto';
import { DateTime } from 'luxon';
import { CustomersOwnDataDto } from 'src/app/commons/models/customers-own-data-dto';
import { CodUniqueKeyModel } from 'src/app/commons/models/cod-unique-key-model';
import { IFormService } from 'src/app/commons/models/form-service.interface';
import { CreateEditCodDialogComponent } from '../components/create-edit-cod-dialog/create-edit-cod-dialog.component';
import { BehaviorSubject } from 'rxjs';
import { DecimalValueService } from 'src/app/commons/services/decimal-value.service';
import { CurrentLanguageService } from 'src/app/services/current-language.service';

@Injectable({
  providedIn: 'root',
})
export class CustomersOwnDataFormService implements IFormService {
  public form!: forms.FormGroup;
  id: number;
  dialogRef: MatDialogRef<CreateEditCodDialogComponent>;
  isDataChanged: boolean = false;
  isDateChanged: boolean = false;
  editData: any;
  private initialFormValue: forms.ɵTypedOrUntyped<any, forms.ɵFormGroupValue<any>, any>;
  private initialDate: any;
  formInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public fb: forms.FormBuilder,
    public userService: UserService,
    private translateService: TranslateService,
    private decimalValueService: DecimalValueService,
    private languageService: CurrentLanguageService
  ) { }

  setEditData(editData) {
    this.editData = editData;
  }

  resetEditData() {
    this.editData = undefined;
  }

  resetForm() {
    this.form.reset();
  }

  setDialogRef(dialogRef) {
    this.dialogRef = dialogRef;
  }

  initializeForm(): void {
    this.form = this.fb.group({
      TransactionDate: ['', [forms.Validators.required, dateValidator()]],
      InvoiceNumber: [''],
      BuyersEAN: [''],
      SupplierID: ['', forms.Validators.required],
      SupplierCountry: ['', forms.Validators.required],
      OrganizationID: ['', forms.Validators.required],
      UnspscClassId: ['', [forms.Validators.required, forms.Validators.pattern(/^[0-9]{8}$/)]],
      LineAmountWithoutVAT: ['', [forms.Validators.required, forms.Validators.pattern(this.languageService.lang == 'da' ? /^-?\d+(?:.\d{3})*(?:\,\d+)?$/ : /^-?\d+(?:,\d{3})*(?:\.\d+)?$/)]],
      SupplierEmissionCo2: ['', forms.Validators.pattern(this.languageService.lang == 'da' ? /^-?\d+(?:.\d{3})*(?:\,\d+)?$/ : /^-?\d+(?:,\d{3})*(?:\.\d+)?$/)],
      Quantity: ['', [forms.Validators.required, forms.Validators.pattern(this.languageService.lang == 'da' ? /^-?\d+(?:.\d{3})*(?:\,\d+)?$/ : /^-?\d+(?:,\d{3})*(?:\.\d+)?$/)]],
      ProductID: ['', forms.Validators.required],
      ProductName: ['', forms.Validators.required],
      ProductDescription: [''],
      Unit: ['', forms.Validators.required],
      GhgCategory: ['', forms.Validators.required],
      MatchingGhgCategory: { value: '', disabled: true },
      CreationDate: [
        { value: new Date(), disabled: true },
        forms.Validators.required,
      ],
      CreatedBy: [
        { value: this.userService.currentUser.email, disabled: true },
        forms.Validators.required,
      ],
      ModifyDate: [{ value: new Date(), disabled: true }, forms.Validators.required],
      ModifiedBy: [
        { value: this.userService.currentUser.email, disabled: true },
        forms.Validators.required,
      ]
    });

    if (this.editData) {
      this.form.patchValue({
        Id: this.editData.id,
        TransactionDate: this.editData.transactionDate,
        InvoiceNumber: this.editData.invoiceNumber,
        BuyersEAN: this.editData.buyersEAN,
        SupplierID: this.editData.supplierID,
        SupplierCountry: this.editData.supplierCountry,
        OrganizationID: this.editData.organizationID,
        UnspscClassId: this.editData.unspscClassId,
        LineAmountWithoutVAT: this.decimalValueService.convertToValidPrice(this.editData.lineAmountWithoutVAT),
        SupplierEmissionCo2: this.decimalValueService.convertToValidPrice(this.editData.supplierEmissionCo2),
        Quantity: this.decimalValueService.convertToValidPrice(this.editData.quantity),
        ProductID: this.editData.productID,
        ProductName: this.editData.productName,
        ProductDescription: this.editData.productDescription,
        Unit: this.editData.unit,
        GhgCategory: this.editData.ghgCatId,
        CreationDate: this.editData.creationDate,
        CreatedBy: this.editData.createdBy,
        ModifyDate: this.editData.modifyDate,
        ModifiedBy: this.userService.currentUser.email,
      });
    }

    this.initialFormValue = this.form.getRawValue();

    this.formInitialized.next(true);

    this.initialDate = this.initialFormValue['EmissionValidfrom'];
    this.form.valueChanges.subscribe(() => {
      this.checkProductDataChanges();
    });
  }

  onDateChange(event) {
    let initialDate = DateTime.fromJSDate(this.initialDate).toISODate();
    let eventValue = DateTime.fromJSDate(event.value).toISODate();
    if (initialDate != eventValue) {
      this.isDateChanged = true;
    } else {
      this.isDateChanged = false;
    }
  }

  checkIfProductDataChanged() {
    if (this.isDataChanged) {
      return true;
    }
    return false;
  }

  checkProductDataChanges() {
    const isTransactionDateChanged = this.isFieldChanged('TransactionDate');
    const isCountryIDChanged = this.isFieldChanged('ProductID');
    const isSupplierIDChanged = this.isFieldChanged('SupplierID');
    const isSupplierCountryChanged = this.isFieldChanged('SupplierCountry');
    const isUnitChanged = this.isFieldChanged('Unit');

    this.isDataChanged = false;

    if (
      isTransactionDateChanged ||
      isCountryIDChanged ||
      isSupplierIDChanged ||
      isSupplierCountryChanged ||
      isUnitChanged
    ) {
      this.isDataChanged = true;
    }
  }

  isFieldChanged(fieldName: string): boolean {
    const currentValue = this.form.get(fieldName).value;
    const initialValue = this.initialFormValue[fieldName];

    return currentValue !== initialValue;
  }

  disableProductData() {
    this.disableField('TransactionDate');
    this.disableField('ProductID');
    this.disableField('SupplierID');
    this.disableField('SupplierCountry');
    this.disableField('Unit');
  }

  disableField(fieldName: string): void {
    let field = this.form.get(fieldName);
    field.disable();
  }

  getSelectedOverWriteStatus(status) {
    let possibleStatuses: OverWriteStatus[] = [
      {
        flag: true,
        translation: this.translateService.instant(
          'customers-own-data.can-overwrite'
        ),
      },
      {
        flag: false,
        translation: this.translateService.instant(
          'customers-own-data.cannot-overwrite'
        ),
      },
    ];
    let selectedStatus = possibleStatuses.find((el) => el.flag == status);

    return selectedStatus.flag;
  }

  getSelectedVerifiedStatus(status) {
    let possibleStatuses: VerifiedStatus[] = [
      {
        flag: true,
        translation: this.translateService.instant(
          'customers-own-data.can-overwrite'
        ),
      },
      {
        flag: false,
        translation: this.translateService.instant(
          'customers-own-data.cannot-overwrite'
        ),
      },
    ];
    let selectedStatus = possibleStatuses.find((el) => el.flag == status);

    return selectedStatus.flag;
  }

  getSelectedActiveStatus(status) {
    let possibleStatuses: VerifiedStatus[] = [
      {
        flag: true,
        translation: this.translateService.instant(
          'customers-own-data.active'
        ),
      },
      {
        flag: false,
        translation: this.translateService.instant(
          'customers-own-data.inactive'
        ),
      },
    ];
    let selectedStatus = possibleStatuses.find((el) => el.flag == status);

    return selectedStatus.flag;
  }

  resetProductDataChange() {
    this.isDataChanged = false;
    this.isDateChanged = false;
  }

  getUniqueKeyModel() {
    return {
      InvoiceNumber: this.form.getRawValue().InvoiceNumber,
      ProductID: this.form.getRawValue().ProductID,
      ProductName: this.form.getRawValue().ProductName,
      ProductDescription: this.form.getRawValue().ProductDescription,
      SupplierCountry: this.form.getRawValue().SupplierCountry,
      SupplierID: this.form.getRawValue().SupplierID,
      TransactionDate: this.form.getRawValue().TransactionDate,
      Unit: this.form.getRawValue().Unit,
      UnspscClassId: this.form.getRawValue().UnspscClassId
    } as CodUniqueKeyModel
  }

  prepareUpdateValues(id: number) {
    return {
      transactionDate: this.form.getRawValue().TransactionDate,
      invoiceNumber: this.form.getRawValue().InvoiceNumber,
      buyersEAN: this.form.getRawValue().BuyersEAN,
      supplierID: this.form.getRawValue().SupplierID,
      supplierCountry: this.form.getRawValue().SupplierCountry,
      organizationID: this.form.getRawValue().OrganizationID,
      unspscClassId: this.form.getRawValue().UnspscClassId,
      productID: this.form.getRawValue().ProductID,
      productName: this.form.getRawValue().ProductName,
      productDescription: this.form.getRawValue().ProductDescription,
      lineAmountWithoutVAT: this.decimalValueService.convertToValidPrice(this.form.getRawValue().LineAmountWithoutVAT),
      supplierEmissionCo2: this.decimalValueService.convertToValidPrice(this.form.getRawValue().SupplierEmissionCo2),
      quantity: this.decimalValueService.convertToValidPrice(this.form.getRawValue().Quantity),
      unit: this.form.getRawValue().Unit,
      modifiedBy: this.form.getRawValue().ModifiedBy,
      creationDate: new Date(),
      modifyDate: new Date(),
      id: this.editData?.id ?? id,
      createdBy: this.form.getRawValue().CreatedBy,
      filenameFromUpload: this.editData?.filenameFromUpload,
      ghgCatId: this.form.getRawValue().GhgCategory
    } as CustomersOwnDataDto;
  }

  prepareCreateValues() {
    return {
      transactionDate: this.form.getRawValue().TransactionDate,
      invoiceNumber: this.form.getRawValue().InvoiceNumber,
      buyersEAN: this.form.getRawValue().BuyersEAN,
      supplierID: this.form.getRawValue().SupplierID,
      supplierCountry: this.form.getRawValue().SupplierCountry,
      organizationID: this.form.getRawValue().OrganizationID,
      UnspscClassId: this.form.getRawValue().UnspscClassId,
      productID: this.form.getRawValue().ProductID,
      productName: this.form.getRawValue().ProductName,
      productDescription: this.form.getRawValue().ProductDescription,
      lineAmountWithoutVAT: this.decimalValueService.convertToValidPrice(this.form.getRawValue().LineAmountWithoutVAT),
      supplierEmissionCo2: this.decimalValueService.convertToValidPrice(this.form.getRawValue().SupplierEmissionCo2),
      quantity: this.decimalValueService.convertToValidPrice(this.form.getRawValue().Quantity),
      unit: this.form.getRawValue().Unit,
      modifiedBy: this.form.getRawValue().ModifiedBy,
      creationDate: new Date(),
      modifyDate: new Date(),
      id: 0,
      createdBy: this.form.getRawValue().CreatedBy,
      ghgCatId: this.form.getRawValue().GhgCategory
    };
  }
}

export function dateValidator(): forms.ValidatorFn {
  return (control: forms.AbstractControl): { [key: string]: any } | null => {
    if (!(control && control.value)) {
      return null;
    }

    const today = DateTime.fromJSDate(new Date());
    const dateSet = DateTime.fromJSDate(new Date(control.value));

    return dateSet > today
      ? { invalidDate: 'You cannot use future dates' }
      : null;
  }
}