import { ApiService } from '../../../services/api.service';
import { ExportDto, OrderExportDto } from '../../../commons/models/export-dto';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityTrackerError } from '../../../commons/models/entity-tracker-error';
import { PbiReportService } from '@modules/report/services/pbi-report.service';

@Injectable({
  providedIn: 'root',
})
export class PBIReportExportService {
  constructor(
    private apiService: ApiService,
    private reportService: PbiReportService
  ) {}

  ngOnDestroy(): void {}

  pBIReportExportGetExportList(): Observable<ExportDto[] | EntityTrackerError> {
    return this.apiService.getPbiPdfExports();
  }

  async pBIReportExportPostExport() {
    const report = this.reportService.report;
    const reportId = report.getId();

    const capturedBookmark = await report.bookmarksManager.capture();
    
    let orderExportDto: OrderExportDto = {
      ReportId: this.reportService.pbiReportId,
      workspaceId: this.reportService.workspaceId,
      state: capturedBookmark.state
    };

    return this.apiService.pBIReportExportPostExport(orderExportDto);
  }

  pBIReportExportGetFile(element) {
    return this.apiService.pBIReportExportGetFile(element);
  }
}
