import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ROUTENAMES } from '../../../../app-routing.names';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  errorTitle: any;
  errorCodeDescription: any;
  errorSubtitle: any;
  buttonName: any;
  navigationRoute: any;

  constructor(public translateService: TranslateService) {
    this.errorTitle = this.translateService.instant(
      'errors.unauthorized.title'
    );
    this.errorCodeDescription = this.translateService.instant(
      'errors.unauthorized.type'
    );
    this.errorSubtitle = this.translateService.instant(
      'errors.unauthorized.subtitle'
    );
    this.buttonName = this.translateService.instant('errors.unauthorized.cta');

    this.navigationRoute = ROUTENAMES.HOME;
  }
}
