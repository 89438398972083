<form [formGroup]="initiativesFormService.form">
  <div class="container gx-0">
    <div class="row justify-content-center">
      <div class="col-12 mt-3">
        <p class="fw-bold fs-30">
          {{ header }}
        </p>
      </div>

      <div class="col-12 mt-3">
        <div mat-dialog-content>
          <div class="row">
            <div class="col-12">
              <div class="mb-4">
                <mat-form-field appearance="fill">
                  <mat-label>{{
                    'initiatives.initiative-name' | translate
                    }}</mat-label>
                  <input required formControlName="initiativeName" matInput placeholder="{{
                      'initiatives.initiative-name' | translate
                    }}" />
                </mat-form-field>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-4">
                <mat-form-field appearance="fill">
                  <mat-label>{{
                    'initiatives.description' | translate
                    }}</mat-label>
                  <input required formControlName="initiativeDescription" matInput
                    placeholder="{{ 'initiatives.description' | translate }}" />
                </mat-form-field>
              </div>
            </div>


            <div class="col-6">
              <div class="mb-4">
                <mat-form-field appearance="fill">
                  <mat-label>{{
                    'initiatives.start-date' | translate
                    }}</mat-label>
                  <mat-select formControlName="initiativeStartYear">
                    <ng-container *ngFor="
                        let item of initiativesFormService.dropdownCurrentYear
                      ">
                      <mat-option value="{{ item }}">{{ item }}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="col-6">
              <div class="mb-4">
                <mat-form-field appearance="fill">
                  <mat-label>{{
                    'initiatives.priority' | translate
                    }}</mat-label>
                  <mat-select required formControlName="initiativePriority">
                    <ng-container *ngFor="let item of initiativesService.priorities">
                      <mat-option value="{{ item.dW_SK_Priority }}">{{
                        item.value
                        }}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="col-6">
              <div class="mb-4">
                <mat-form-field appearance="fill">
                  <mat-label>{{
                    'initiatives.end-date' | translate
                    }}</mat-label>
                  <input matInput formControlName="initiativeEndYear"
                    [value]="unspscHandlerService.unspscsLastYear.toString()" />
                </mat-form-field>
              </div>
            </div>

            <div class="col-6">
              <div class="mb-4">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'initiatives.status' | translate }}</mat-label>
                  <mat-select required formControlName="initiativeStatus">
                    <ng-container *ngFor="let item of initiativesService.statuses">
                      <mat-option value="{{ item.dW_SK_Status }}">{{
                        item.value
                        }}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="fw-bold fs-22">
              {{ 'scenarios.scenario' | translate }}
            </div>
            <div class="row">
              <div class="col-12 mt-3">
                <div class="row">
                  <div class="col-6">
                    <div class="mb-2">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'scenarios.scenario-name' | translate
                          }}</mat-label>
                        <input [readonly]="true" formControlName="scenarioName" matInput placeholder="{{
                              'scenarios.scenario-name' | translate
                            }}" />
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-6">
                    <mat-form-field appearance="fill">
                      <mat-label>{{
                        'scenarios.created-by' | translate
                        }}</mat-label>
                      <input [readonly]="true" formControlName="scenarioEmail" matInput placeholder="{{
                            'scenarios.created-by' | translate
                          }}" />
                    </mat-form-field>
                  </div>

                  <div class="col-12">
                    <mat-form-field appearance="fill">
                      <mat-label>{{
                        'scenarios.description' | translate
                        }}</mat-label>
                      <textarea [readonly]="true" formControlName="scenarioDescription" matInput cdkTextareaAutosize
                        placeholder="{{
                            'scenarios.description' | translate
                          }}"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12" *ngIf="initiativesFormService.form">

              <mat-card style="margin-bottom: 0.75rem;">
                <mat-card-content class="mat-body-1">
                  <div class="fw-bold fs-22">
                    {{ 'scenarios.unspscs' | translate }}
                  </div>
                  <div class="row">
                    <div class="col-12 mt-4">
                      <app-unspsc-handler [handlerForm]="initiativesFormService.form"
                        [formBuilder]="initiativesFormService.formBuilder" [editData]="editData"
                        [formType]="'initiatives'" [disabled]="true"></app-unspsc-handler>

                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div class="row">
              <div class="col-12">
                <app-calculations></app-calculations>
              </div>
            </div>

            <div class="col-6">
              <div class="mb-4">
                <mat-form-field appearance="fill">
                  <mat-label>{{
                    'initiatives.create-date' | translate
                    }}</mat-label>
                  <input formControlName="initiativeCreateDate" matInput [matDatepicker]="createDate" />
                  <mat-datepicker-toggle matSuffix [for]="createDate"></mat-datepicker-toggle>
                  <mat-datepicker #createDate></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

            <div class="col-6">
              <div class="mb-4">
                <mat-form-field appearance="fill">
                  <mat-label>{{
                    'initiatives.created-by' | translate
                    }}</mat-label>
                  <input formControlName="initiativeEmail" matInput
                    placeholder="{{ 'initiatives.created-by' | translate }}" />
                </mat-form-field>
              </div>
            </div>

            <div class="col-12">
              <app-table-display></app-table-display>
            </div>

          </div>
        </div>

        <div mat-dialog-actions class="custom-dialog-actions" [align]="'end'">
          <button mat-raised-button (click)="cancel()" [mat-dialog-close]="false">
            {{ 'general.close' | translate }}
          </button>

          <button mat-raised-button color="primary" [disabled]="initiativesFormService.form.invalid"
            (click)="ctaInitiative()">
            {{ 'initiatives.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>