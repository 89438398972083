import { Injectable } from '@angular/core';
import { GraphXYDTO } from '../../../../commons/models/graph-xy-dto';
import { CalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.service';
import { ApiService } from '../../../../services/api.service';
import { DeepCloneService } from '../../../../commons/services/deep-clone.service';
import { ICalculatorTableService } from '@modules/scenarios/services/calculator/calculator-table.interface';
import { UnspscCalculatorServiceDto } from '../../../../commons/models/unspsc';
import { CalculatorPrepareDataService } from './calculator-prepare-data.service';
import { HistoricalDataResponseDto } from 'src/app/commons/models/historical-data-dto';
import { UnspscHandlerService } from 'src/app/commons/components/unspsc-handler/unspsc-handler.service';

@Injectable({
  providedIn: 'root',
})
export class CalculatorTableService implements ICalculatorTableService {
  historicalColumns: number[] = [];
  forecastColumns: number[] = [];
  uniqueUnspscEmissionChangeView: any[] = [];
  uniqueUnspscExpenseChangeView: any[] = [];
  uniqueUnspscQuantityChangeView: any[] = [];
  yearlyTotalsExpenses: any;
  yearlyTotalsEmissions: any;
  yearlyTotalsQuantities: any;

  constructor(
    public calculatorHelperService: CalculatorHelperService,
    public prepareDataService: CalculatorPrepareDataService,
    public apiService: ApiService,
    public deepCloneService: DeepCloneService,
    private unspscHandlerService: UnspscHandlerService
  ) { }

  calculateColumns(unspscs) {
    let firstYear = this.calculatorHelperService.firstYear;
    let currentYear = new Date().getFullYear();

    for (let i = firstYear; i < currentYear; i++) {
      this.historicalColumns.push(i);
    }

    let arrayOfForecastLength = unspscs.map((obj) => {
      return obj.forecastLength;
    });

    let forecastLength = Math.max(...arrayOfForecastLength);

    for (let i = 0; i < forecastLength; i++) {
      this.forecastColumns.push(currentYear + i);
    }

    this.forecastColumns = Array.from(new Set(this.forecastColumns));
  }

  groupBy(array: any[], propertyName: string) {
    return array.reduce((result, item) => {
      result[item[propertyName]] = result[item[propertyName]] || [];
      result[item[propertyName]].push(item);
      return result;
    }, Object.create(null));
  }


  getChangeDataByUnspscForTable(historicalData: HistoricalDataResponseDto[], calculationUnspscs, unspscs) {

    this.calculateColumns(unspscs);
    let yearlyTotalsExpenses = [];
    let yearlyTotalsEmissions = [];
    let yearlyTotalsQuantities = [];
    let yearlyExpenseChangesByUnspsc: GraphXYDTO[][] = [];
    let yearlyEmissionChangesByUnspsc: GraphXYDTO[][] = [];
    let yearlyQuantityChangesByUnspsc: GraphXYDTO[][] = [];
    let maximumForecastLength =
      this.calculatorHelperService.getMaximumForecastLength(unspscs);

    if (historicalData instanceof Array && !historicalData.length) {
      return;
    }

    const grouped = this.groupBy(unspscs, 'formIndex');

    Object.keys(grouped).forEach(formIndex => {

      const historicalDataForm = historicalData.find(x => x.formIndex == formIndex.toString())?.trendPoints;

      const historicalDataGrouped = this.groupBy(
        historicalDataForm.filter(x => grouped[formIndex].map(y => y.unspscClassID).includes(x.unspscClassID)),
        'unspscClassID');

      let newestHistoricalRecords = [];
      Object.keys(historicalDataGrouped).forEach(x => {
        newestHistoricalRecords.push(historicalDataGrouped[x].sort(function (a, b) {
          return b.year - a.year;
        })[0]);
      });

      grouped[formIndex].forEach((unspsc) => {
        let historicalDataForOneUnspsc =
          historicalDataForm?.filter((el) => el.unspscClassID == unspsc.unspscClassID) || [];
        let unspscCode = unspsc.unspscClassID;

        let unspscObject: UnspscCalculatorServiceDto = unspscs.find(
          (el) => el.formIndex == formIndex && el.unspscClassID == unspscCode
        );

        let {
          forecastLength,
          adjustment,
          trendMethod,
          simulationMethod,
          unitMethod,
        } = unspscObject || {};

        let unspscDescription =
          this.unspscHandlerService.selectedUnspscs[formIndex].find((el) => el.unspscClassID == unspscCode)
            ?.unspscClassDescription || '';

        if (
          historicalDataForOneUnspsc instanceof Array &&
          !historicalDataForOneUnspsc.length
        ) {
          this.uniqueUnspscExpenseChangeView.push({
            unspsc: unspscCode,
            unspscClassDescription: unspscDescription,
            expenseChange: this.prepareEmptyForecastData(),
            expenseChangeSum: null,
            historicalExpenses: this.prepareEmptyHistoricalData(unspscCode),
            yearlyTrendPoints: this.prepareEmptyForecastData(),
            yearlySimulationPoints: this.prepareEmptyForecastData(),
          });

          this.uniqueUnspscEmissionChangeView.push({
            unspsc: unspscCode,
            unspscClassDescription: unspscDescription,
            emissionChange: this.prepareEmptyForecastData(),
            emissionChangeSum: null,
            historicalEmissions: this.prepareEmptyHistoricalData(unspscCode),
            yearlyTrendPoints: this.prepareEmptyForecastData(),
            yearlySimulationPoints: this.prepareEmptyForecastData(),
          });

          if (unitMethod == 2) {
            this.uniqueUnspscQuantityChangeView.push({
              unspsc: unspscCode,
              unspscClassDescription: unspscDescription,
              quantityChange: this.prepareEmptyForecastData(),
              quantityChangeSum: null,
              historicalQuantities: this.prepareEmptyHistoricalData(unspscCode),
              yearlyTrendPoints: this.prepareEmptyForecastData(),
              yearlySimulationPoints: this.prepareEmptyForecastData(),
            });
          }
        } else {
          // WE CALCULATE FOR THE MAXIMUM PERIOD

          // calculate adjustment proportions
          let chosenUnitMethod = unspscObject.unitMethod;
          let proportionFieldName = chosenUnitMethod == 1 ? 'amount' : 'quantity';
          const arg1 = Math.round(newestHistoricalRecords.filter(x => x.unspscClassID == unspscCode)[0]?.[proportionFieldName] * 1000) / 1000;
          const arg2 = Math.round(newestHistoricalRecords.filter(x => x.unspscClassID != unspscCode).map(y => y[proportionFieldName]).reduce((acc, cur) => acc + Number(cur), 0) * 1000) / 1000;

          let adjustmentProportionPercentage;
          if (arg2 >= 0) {
            adjustmentProportionPercentage = Math.round(((arg1 / (arg1 + arg2)) * 100) * 1000) / 1000;
          }

          let unspscConfig = {
            adjustment,
            trendMethod,
            simulationMethod,
            unitMethod,
            adjustmentProportionPercentage
          };


          let dataUnspscSpecific =
            this.prepareDataService.prepareCalculatedDataForUnspsc(
              historicalDataForOneUnspsc,
              maximumForecastLength,
              forecastLength,
              unspscConfig
            );

          // SUMMARY EXPENSE CHANGES FOR THE LAST COLUMN
          let expenseSum = this.calculatorHelperService.sumChangeData(
            dataUnspscSpecific.expense.change
          );

          // SUMMARY EMISSION CHANGES FOR THE LAST COLUMN
          let emissionSum = this.calculatorHelperService.sumChangeData(
            dataUnspscSpecific.emission.change
          );

          // SUMMARY UNIT QUANTITY CHANGES FOR THE LAST COLUMN
          let unitQuantitySum = this.calculatorHelperService.sumChangeData(
            dataUnspscSpecific.quantity.change
          );

          let yearlyExpenseTrendPoints = this.splitForecast(
            dataUnspscSpecific.expense.trendPoints,
            historicalDataForOneUnspsc
          );

          // WE SUBSTITUTE THE SIMULATION POINTS WITH TREND WHEN
          // THE FORECAST IS SHORTER THAN THE MAXIMUM CHOSEN FORECAST
          // FROM ALL OF THE UNSPSCS
          let yearlyExpenseSimulationPoints = this.splitForecast(
            dataUnspscSpecific.expense.simulationPoints,
            historicalDataForOneUnspsc
          );

          let yearlyEmissionTrendPoints = this.splitForecast(
            dataUnspscSpecific.emission.trendPoints,
            historicalDataForOneUnspsc
          );

          // WE SUBSTITUTE THE SIMULATION POINTS WITH TREND WHEN
          // THE FORECAST IS SHORTER THAN THE MAXIMUM CHOSEN FORECAST
          // FROM ALL OF THE UNSPSCS

          let yearlyEmissionSimulationPoints = this.splitForecast(
            dataUnspscSpecific.emission.simulationPoints,
            historicalDataForOneUnspsc
          );

          let yearlyQuantityTrendPoints = this.splitForecast(
            dataUnspscSpecific.quantity.trendPoints,
            historicalDataForOneUnspsc
          );

          let yearlyQuantitySimulationPoints = this.splitForecast(
            dataUnspscSpecific.quantity.simulationPoints,
            historicalDataForOneUnspsc
          );

          // YEARLY EXPENSE CHANGES FOR THE LAST ROW (ALL COLUMNS FOR 1 UNSPSC)
          yearlyExpenseChangesByUnspsc.push(dataUnspscSpecific.expense.change);

          yearlyExpenseChangesByUnspsc =
            this.fillWithZerosWhenDifferentForecastLengths(
              yearlyExpenseChangesByUnspsc
            );

          // YEARLY EMISSION CHANGES FOR THE LAST ROW (ALL COLUMNS FOR 1 UNSPSC)
          yearlyEmissionChangesByUnspsc.push(dataUnspscSpecific.emission.change);

          yearlyEmissionChangesByUnspsc =
            this.fillWithZerosWhenDifferentForecastLengths(
              yearlyEmissionChangesByUnspsc
            );

          // YEARLY QUANTITY CHANGES FOR THE LAST ROW (ALL COLUMNS FOR 1 UNSPSC)
          yearlyQuantityChangesByUnspsc.push(dataUnspscSpecific.quantity.change);

          yearlyQuantityChangesByUnspsc =
            this.fillWithZerosWhenDifferentForecastLengths(
              yearlyQuantityChangesByUnspsc
            );

          // FULL ROW OF EXPENSE DATA
          this.uniqueUnspscExpenseChangeView.push({
            unspsc: unspscCode,
            unspscClassDescription: unspscDescription,
            expenseChange: dataUnspscSpecific.expense.change,
            expenseChangeSum: expenseSum,
            historicalExpenses: historicalDataForm.filter(
              (el) => el?.unspscClassID == unspscCode
            ) || [],
            yearlyTrendPoints: yearlyExpenseTrendPoints,
            yearlySimulationPoints: yearlyExpenseSimulationPoints,
          });

          // FULL ROW OF EMISSION DATA
          this.uniqueUnspscEmissionChangeView.push({
            unspsc: unspscCode,
            unspscClassDescription: unspscDescription,
            emissionChange: dataUnspscSpecific.emission.change,
            emissionChangeSum: emissionSum,
            historicalEmissions: historicalDataForm.filter(
              (el) => el?.unspscClassID == unspscCode
            ) || [],
            yearlyTrendPoints: yearlyEmissionTrendPoints,
            yearlySimulationPoints: yearlyEmissionSimulationPoints,
          });

          // FULL ROW OF UNIT QUANTITY DATA
          if (unitMethod == 2) {
            this.uniqueUnspscQuantityChangeView.push({
              unspsc: unspscCode,
              unspscClassDescription: unspscDescription,
              quantityChange: dataUnspscSpecific.quantity.change,
              quantityChangeSum: unitQuantitySum,
              historicalEmissions: historicalDataForm.filter(
                (el) => el?.unspscClassID == unspscCode
              ) || [],
              yearlyTrendPoints: yearlyQuantityTrendPoints,
              yearlySimulationPoints: yearlyQuantitySimulationPoints,
            });
          }
        }

        console.log(
          'this.uniqueUnspscExpenseChangeView',
          this.uniqueUnspscExpenseChangeView
        );
        console.log(
          'this.uniqueUnspscEmissionChangeView',
          this.uniqueUnspscEmissionChangeView
        );

        let years = this.calculatorHelperService.getNestedYears(
          yearlyExpenseChangesByUnspsc
        );

        // ADDING ZEROS WHEN YEARLY CHANGES ARE EMPTY
        this.calculatorHelperService.addZeroWhenYearlyExpenseChangesByUnspscEmpty(
          yearlyExpenseChangesByUnspsc,
          years
        );
        this.calculatorHelperService.addZeroWhenYearlyExpenseChangesByUnspscEmpty(
          yearlyEmissionChangesByUnspsc,
          years
        );

        // AGGREGATING EXPENSE BY YEAR (VERTICALLY IN COLUMNS)
        years.forEach((year) => {
          let aggregatedExpenseByYear = 0;
          yearlyExpenseChangesByUnspsc.map((singleUnspscArray) => {
            let selectedYearData = singleUnspscArray.filter((el) => el.x == year);

            aggregatedExpenseByYear += selectedYearData[0]?.y || 0;
          });

          let expensePoint = { x: year, y: aggregatedExpenseByYear };

          yearlyTotalsExpenses.push(expensePoint);
        });

        // AGGREGATING EMISSION BY YEAR (VERTICALLY IN COLUMNS)
        years.forEach((year) => {
          let aggregatedEmissionByYear = 0;
          yearlyEmissionChangesByUnspsc.map((singleUnspscArray) => {
            let selectedYearData = singleUnspscArray.filter((el) => el.x == year);
            aggregatedEmissionByYear += selectedYearData[0]?.y || 0;
          });

          let emissionPoint = { x: year, y: aggregatedEmissionByYear };

          yearlyTotalsEmissions.push(emissionPoint);
        });

        // AGGREGATING QUANTITY BY YEAR (VERTICALLY IN COLUMNS)
        years.forEach((year) => {
          let aggregatedQuantityByYear = 0;
          yearlyQuantityChangesByUnspsc.map((singleUnspscArray) => {
            let selectedYearData = singleUnspscArray.filter((el) => el.x == year);
            aggregatedQuantityByYear += selectedYearData[0]?.y || 0;
          });

          let emissionPoint = { x: year, y: aggregatedQuantityByYear };

          yearlyTotalsQuantities.push(emissionPoint);
        });
      });
    })

    // SUMMARIZING EXPENSES
    this.yearlyTotalsExpenses =
      this.calculatorHelperService.sumYearlyTotals(
        yearlyTotalsExpenses
      );

    // SUMMARIZING EMISSIONS
    this.yearlyTotalsEmissions =
      this.calculatorHelperService.sumYearlyTotals(
        yearlyTotalsEmissions
      );

    // SUMMARIZING QUANTITIES
    this.yearlyTotalsQuantities =
      this.calculatorHelperService.sumYearlyTotals(
        yearlyTotalsQuantities
      );
  }

  resetUnspscTable(): void {
    this.uniqueUnspscExpenseChangeView = [];
    this.uniqueUnspscEmissionChangeView = [];
    this.uniqueUnspscQuantityChangeView = [];
    this.historicalColumns = [];
    this.forecastColumns = [];
  }

  getHistoricalDataFirstYear() {
    return this.apiService
      .getHistoricalDataFirstYear()
      .toPromise();
  }

  fillWithZerosWhenDifferentForecastLengths(yearlyChangesByUnspsc) {
    return yearlyChangesByUnspsc.map((yearlyChangesByUnspscArray) => {
      yearlyChangesByUnspscArray.sort((a, b) => a.x - b.x);

      let lastYear =
        yearlyChangesByUnspscArray[yearlyChangesByUnspscArray.length - 1]?.x;

      let forecastLengthDiff =
        this.forecastColumns.length - yearlyChangesByUnspscArray.length;

      for (let i = 0; i < forecastLengthDiff; i++) {
        yearlyChangesByUnspscArray.push({
          x: lastYear + i + 1,
          y: 0,
        });
      }

      return yearlyChangesByUnspscArray;
    });
  }

  splitForecast(xyPoints, historicalData) {
    let historicalPointsCopy = this.deepCloneService.deepClone(historicalData);
    let historicalYears =
      this.calculatorHelperService.getYearsOutOfHistoricalDataDto(
        historicalPointsCopy
      );
    let xyPointsCopy = this.deepCloneService.deepClone(xyPoints);

    let forecast = xyPointsCopy.filter((xyPointsEl) => {
      return !historicalYears.includes(xyPointsEl.x);
    });

    return forecast;
  }

  prepareEmptyForecastData() {
    let blankArray = [];
    for (let i = 0; i < this.forecastColumns.length; i++) {
      blankArray.push({
        x: 2022 + i,
        y: null,
      });
    }

    return blankArray;
  }

  prepareEmptyHistoricalData(unspsc) {
    let blankArray = [];
    for (let i = 0; i < this.historicalColumns.length; i++) {
      blankArray.push(
        this.calculatorHelperService.getEmptyHistoricalDataDto(
          this.historicalColumns[i],
          unspsc
        )
      );
    }

    return blankArray;
  }
}
