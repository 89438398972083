import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { UserInfo } from '../commons/models/user-info';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { UserService } from '@modules/shell/services/user.service';
import { AdminService } from '../services/admin.service';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { ROUTENAMES } from '../app-routing.names';
import { PreviousRouteService } from '../commons/services/previous-route.service';
import { ActivityService } from '../services/activity.service';
import { ActivityEventType, UserActivity } from '../commons/models/activity.model';

@Injectable({
  providedIn: 'root',
})
export class UserResolver implements Resolve<UserInfo> {
  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private adminService: AdminService,
    private tenantSelectService: TenantSelectService,
    private router: Router,
    private previousRouteService: PreviousRouteService,
    private activityService: ActivityService
  ) { }
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UserInfo> {
    return this.httpClient
      .get<UserInfo>(environment.apiDomain + '/api/userinfo')
      .pipe(
        switchMap((user) => {
          this.userService.user.next(user);
          this.adminService.isCrossCustomerViewer =
            user.permissions.crossCustomerViewer;
          this.adminService.ScenarioTemplateCreator =
            user.permissions.scenarioTemplateCreator;

          // when the topbar initializes we get the UserInfo object and set
          // tenants, permissions, and identity of the current user
          this.tenantSelectService.setTenants(user);
          this.tenantSelectService.setUserTenant(user.idp);

          this.adminService.setViewerType(user);

          this.userService.currentUser = user as UserInfo;
          return of(user);
        }),
        catchError(() => {
          this.router.navigate([ROUTENAMES.UNAUTHORIZED]);
          return EMPTY;
        }),
        finalize(() => {
          this.activityService.sendActivity(new UserActivity('User logged-in', this.userService.currentUser.username, ActivityEventType.UserLogin));

          // When authentication was successful current user is populated
          if (this.userService.currentUser) {
            const currentUrl = this.previousRouteService.getCurrentUrl();
            const queryParams = this.previousRouteService.getCurrentUrlParams();

            if (currentUrl && currentUrl !== '/') {
              // When page loaded, authentication passed and URL was pointing the report
              if (!this.isEmptyObject(queryParams) && queryParams?.hasOwnProperty('reportId')) {
                this.router.navigate([ROUTENAMES.ACCOUNTING]);
                // When page loaded, authentication passed and URL was pointing to the other page
              } else {
                this.router.navigateByUrl(currentUrl);
              }
              // When page loaded, authentication passed and URL was pointing to the home page
            } else if (state.url === '/' || state.url === '') {
              this.router.navigate([ROUTENAMES.HOME]);
            }
          }
        })
      );
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }
}
