import { Injectable } from '@angular/core';
import * as forms from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@modules/shell/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import {
  OverWriteStatus,
  VerifiedStatus,
} from '../../../commons/models/product-specific-emission-dto';
import { DateTime } from 'luxon';
import { CodUniqueKeyModel } from 'src/app/commons/models/cod-unique-key-model';
import { IFormService } from 'src/app/commons/models/form-service.interface';
import { BehaviorSubject } from 'rxjs';
import { DecimalValueService } from 'src/app/commons/services/decimal-value.service';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { CreateEditExclusionDialogComponent } from '../components/create-edit-exclusion-dialog/create-edit-exclusion-dialog.component';
import { CustomersExclusionRuleDto } from 'src/app/commons/models/customers-exclusion-rule-dto';

@Injectable({
  providedIn: 'root',
})
export class ExclusionsFormService implements IFormService {
  public form!: forms.FormGroup;
  id: number;
  dialogRef: MatDialogRef<CreateEditExclusionDialogComponent>;
  isDataChanged: boolean = false;
  isDateChanged: boolean = false;
  editData: any;
  private initialFormValue: forms.ɵTypedOrUntyped<any, forms.ɵFormGroupValue<any>, any>;
  private initialDate: any;
  formInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public fb: forms.FormBuilder,
    public userService: UserService,
    private translateService: TranslateService,
    private decimalValueService: DecimalValueService,
    private languageService: CurrentLanguageService
  ) { }

  setEditData(editData) {
    this.editData = editData;
  }

  resetEditData() {
    this.editData = undefined;
  }

  resetForm() {
    this.form.reset();
  }

  setDialogRef(dialogRef) {
    this.dialogRef = dialogRef;
  }

  disableProductData() {
  }

  checkProductDataChanges() {
  }

  initializeForm(): void {
    this.form = this.fb.group({
      InvoiceNumber: [],
      ItemId: [],
      SupplierId: [],
      SupplierCountry: [],
      OrganizationId: [],
      UnspscClassId: [],
      DateFrom: [],
      DateTo: []
    });

    if (this.editData) {
      this.form.patchValue({
        Id: this.editData.id,
        InvoiceNumber: this.editData.invoiceNumber,
        ItemId: this.editData.itemId,
        SupplierId: this.editData.supplierId,
        SupplierCountry: this.editData.supplierCountry,
        OrganizationId: this.editData.organizationId,
        UnspscClassId: this.editData.unspscClassId,
        DateFrom: this.editData.dateFrom,
        DateTo: this.editData.dateTo,
        CreationDate: this.editData.creationDate,
        CreatedBy: this.editData.createdBy,
        ModifyDate: this.editData.modifyDate,
        ModifiedBy: this.userService.currentUser.email,
      });
    }

    this.initialFormValue = this.form.getRawValue();

    this.formInitialized.next(true);

    this.form.valueChanges.subscribe(() => {

    });
  }

  onDateChange(event) {
    let initialDate = DateTime.fromJSDate(this.initialDate).toISODate();
    let eventValue = DateTime.fromJSDate(event.value).toISODate();
    if (initialDate != eventValue) {
      this.isDateChanged = true;
    } else {
      this.isDateChanged = false;
    }
  }

  checkIfProductDataChanged() {
    if (this.isDataChanged) {
      return true;
    }
    return false;
  }

  isFieldChanged(fieldName: string): boolean {
    const currentValue = this.form.get(fieldName).value;
    const initialValue = this.initialFormValue[fieldName];

    return currentValue !== initialValue;
  }

  disableField(fieldName: string): void {
    let field = this.form.get(fieldName);
    field.disable();
  }

  getSelectedOverWriteStatus(status) {
    let possibleStatuses: OverWriteStatus[] = [
      {
        flag: true,
        translation: this.translateService.instant(
          'customers-own-data.can-overwrite'
        ),
      },
      {
        flag: false,
        translation: this.translateService.instant(
          'customers-own-data.cannot-overwrite'
        ),
      },
    ];
    let selectedStatus = possibleStatuses.find((el) => el.flag == status);

    return selectedStatus.flag;
  }

  getSelectedVerifiedStatus(status) {
    let possibleStatuses: VerifiedStatus[] = [
      {
        flag: true,
        translation: this.translateService.instant(
          'customers-own-data.can-overwrite'
        ),
      },
      {
        flag: false,
        translation: this.translateService.instant(
          'customers-own-data.cannot-overwrite'
        ),
      },
    ];
    let selectedStatus = possibleStatuses.find((el) => el.flag == status);

    return selectedStatus.flag;
  }

  getSelectedActiveStatus(status) {
    let possibleStatuses: VerifiedStatus[] = [
      {
        flag: true,
        translation: this.translateService.instant(
          'customers-own-data.active'
        ),
      },
      {
        flag: false,
        translation: this.translateService.instant(
          'customers-own-data.inactive'
        ),
      },
    ];
    let selectedStatus = possibleStatuses.find((el) => el.flag == status);

    return selectedStatus.flag;
  }

  resetProductDataChange() {
    this.isDataChanged = false;
    this.isDateChanged = false;
  }

  getUniqueKeyModel() {
    return {
      InvoiceNumber: this.form.getRawValue().InvoiceNumber,
      ProductID: this.form.getRawValue().ProductID,
      ProductName: this.form.getRawValue().ProductName,
      ProductDescription: this.form.getRawValue().ProductDescription,
      SupplierCountry: this.form.getRawValue().SupplierCountry,
      SupplierID: this.form.getRawValue().SupplierID,
      TransactionDate: this.form.getRawValue().TransactionDate,
      Unit: this.form.getRawValue().Unit,
      UnspscClassID: this.form.getRawValue().UnspscClassID
    } as CodUniqueKeyModel
  }

  prepareUpdateValues(id: number) {
    return {
      invoiceNumber: this.form.getRawValue().InvoiceNumber,
      itemId: this.form.getRawValue().ItemId,
      supplierId: this.form.getRawValue().SupplierId,
      supplierCountry: this.form.getRawValue().SupplierCountry,
      dateFrom: this.form.getRawValue().DateFrom,
      dateTo: this.form.getRawValue().DateTo,
      invoiceDate: this.form.getRawValue().InvoiceDate,
      organizationId: this.form.getRawValue().OrganizationId,
      unspscClassId: this.form.getRawValue().UnspscClassId,
      modifiedBy: this.form.getRawValue().ModifiedBy,
      creationDate: new Date(),
      modifyDate: new Date(),
      id: this.editData?.id ?? id,
      ruleEnabled: this.editData?.ruleEnabled,
      createdBy: this.form.getRawValue().CreatedBy,
    } as CustomersExclusionRuleDto;
  }

  prepareCreateValues() {
    return {
      invoiceNumber: this.form.getRawValue().InvoiceNumber,
      itemId: this.form.getRawValue().ItemId,
      supplierId: this.form.getRawValue().SupplierId,
      supplierCountry: this.form.getRawValue().SupplierCountry,
      dateFrom: this.form.getRawValue().DateFrom,
      dateTo: this.form.getRawValue().DateTo,
      invoiceDate: this.form.getRawValue().InvoiceDate,
      organizationId: this.form.getRawValue().OrganizationId,
      unspscClassId: this.form.getRawValue().UnspscClassId,
      modifiedBy: this.form.getRawValue().ModifiedBy,
      creationDate: new Date(),
      modifyDate: new Date(),
      id: 0,
      createdBy: this.form.getRawValue().CreatedBy,
    } as CustomersExclusionRuleDto;
  }
}

export function dateValidator(): forms.ValidatorFn {
  return (control: forms.AbstractControl): { [key: string]: any } | null => {
    if (!(control && control.value)) {
      return null;
    }

    const today = DateTime.fromJSDate(new Date());
    const dateSet = DateTime.fromJSDate(new Date(control.value));

    return dateSet > today
      ? { invalidDate: 'You cannot use future dates' }
      : null;
  }
}