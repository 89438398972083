<div class="row mx-0" *ngIf="isReady && organizationOptionsInitialized">
  <div class="col-12" [formGroup]="handlerForm">
    <div class="mb-4" formArrayName="unspscs" *ngFor="let unspsc of unspscs.controls; let i = index">
      <ng-container [formGroupName]="i">
        <div class="row align-items-center justify-content-center unspsc-field">
          <div class="col-9 ms-2">
            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width mt-4 chip-list" appearance="fill">
                  <mat-label>
                    {{ 'scenarios.product-category' | translate }}
                    {{ i + 1 }} (UNSPSC)</mat-label>
                  <mat-chip-grid #chipGrid aria-label="Fruit selection">
                    <mat-chip-row *ngFor="let selectedUnspsc of unspscHandlerService.selectedUnspscs[i]"
                      [removable]="true">
                      {{ selectedUnspsc.unspscClassId }} {{selectedUnspsc.unspscClassDescription }}
                      <mat-icon *ngIf="!disabled" style="vertical-align: middle;" role="img" class="mat-icon"
                        (click)="remove(selectedUnspsc.unspscClassId, i)" aria-hidden="true"
                        data-mat-icon-type="font">cancel</mat-icon>
                    </mat-chip-row>
                  </mat-chip-grid>
                  <input [placeholder]="unspcsSearchPlaceholder()" #inputControl appSanityAsyncValidator required
                    type="text" matInput [matAutocomplete]="autoUnspscs" [matChipInputFor]="chipGrid"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" formControlName="unspscClassId"
                    (input)="onUnspscInputChange(unspsc.get('unspscClassId').value, i)" [readonly]="disabled" />
                  <mat-autocomplete #autoUnspscs="matAutocomplete" [displayWith]="unspscDisplayFn.bind(this, i)"
                    (closed)="inputControl.value = ''; onAutocompleteClose(i)">
                    <mat-option *ngFor="let option of filteredUnspscOptionsList[i]"
                      [value]="unspscHandlerService.selectedUnspscs[i]">
                      <mat-checkbox [checked]="option.selected" (change)="toggleSelection(option, i); onTouch()"
                        (click)="$event.stopPropagation()">
                        {{ option.idDescriptionUnspsc || '' }}
                      </mat-checkbox>
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="unspsc.get('unspscClassId').hasError('regexFail')">
                    {{ 'error-handling.not-allowed-character' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <app-tree-autocomplete (change)="onTouch()" [parentGroup]="formArray.at(i)" controlName="organizationID"
                  [options]="organizationOptions" (blur)="organizationTextChanged($event, i)"
                  valueFieldName="organizationId" displayFieldPattern="${organizationId} ${organizationDescription}"
                  [disabled]="disabled">
                </app-tree-autocomplete>
              </div>

              <div class="col-4">
                <div class="full-width mb-3 forecast">
                  <div class="full-width forecast">
                    <mat-label class="forecast-label">{{ 'scenarios.forecast-length' | translate }}</mat-label>
                    <mat-slider discrete class="full-width" [max]="5" [min]="1" [step]="1" [displayWith]="formatLabel"
                      [disabled]="disabled" (change)="changeValue()">
                      <input aria-labelledby="years" formControlName="forecastLength" matSliderThumb
                        [readonly]="disabled" (input)="onTouch()">
                    </mat-slider>
                  </div>
                </div>
              </div>

              <div class="col-2">
                <div class="full-width mb-3">
                  <mat-form-field class="full-width" floatLabel="always">
                    <mat-label>{{
                      'scenarios.scenario-adjustment' | translate
                      }}</mat-label>
                    <input required class="margin-custom-adjustment" formControlName="adjustment" [readonly]="disabled"
                      (input)="onTouch()" matInput placeholder="{{ 'scenarios.scenario-adjustment' | translate }}" />
                    <mat-error *ngIf="unspsc.get('adjustment').hasError('pattern')">
                      {{ 'error-handling.not-allowed-character' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-2">
                <div class="full-width mb-3">
                  <mat-form-field class="full-width">
                    <mat-label>{{
                      'scenarios.scenario-adjustment-simulation' | translate
                      }}</mat-label>
                    <mat-select (selectionChange)="onTouch()" class="margin-custom-adjustment" required
                      formControlName="simulationMethod">
                      <mat-option *ngFor="let item of simulationMethod" [value]="item.id" [disabled]="disabled">{{
                        item.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-2">
                <div class="full-width mb-3">
                  <mat-form-field class="full-width">
                    <mat-label>{{
                      'scenarios.scenario-adjustment-unit' | translate
                      }}</mat-label>
                    <mat-select (selectionChange)="onTouch()" class="margin-custom-adjustment" required
                      formControlName="unitMethod">
                      <mat-option *ngFor="let item of unspscHandlerService.unitMethod[i]" [value]="item.id">
                        {{ item.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-2">
                <div class="full-width mb-3">
                  <mat-form-field class="full-width">
                    <mat-label>{{
                      'scenarios.scenario-adjustment-trend' | translate
                      }}</mat-label>
                    <mat-select (selectionChange)="onTouch()" class="margin-custom-adjustment" required
                      formControlName="trendMethod">
                      <mat-option *ngFor="let item of trendMethod" [value]="item.id" [disabled]="disabled">{{ item.name
                        }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2" *ngIf="!disabled">
            <div class="row justify-content-center">
              <button mat-icon-button color="remove" (click)="removeUnspsc(i)"
                attr.aria-label="{{ 'scenarios.remove-unspscs' | translate }}">
                <mat-icon fontIcon="close"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="!disabled" class="col-12">
    <div class="row justify-content-center">
      <div class="col-9 ms-2"></div>
      <div class="col-2">
        <div class="row justify-content-center my-4">
          <button mat-icon-button color="add" (click)="addUnspsc()"
            attr.aria-label="{{ 'scenarios.add-unspscs' | translate }}">
            <mat-icon fontIcon="add"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>