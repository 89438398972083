import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogConfigData } from '../models/dialog-config-data';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public configData: DialogConfigData,
    private dialogRef: MatDialogRef<DialogComponent>
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close('cancel');
  }

  confirm() {
    this.dialogRef.close('confirm');
  }
}
