<aside [ngClass]="isSidebarOpen ? '' : 'closed'" (mouseleave)="onMouseLeave()">
  <ul>
    <ng-container *ngFor="let menuItem of menuItems">
      <ng-container *ngIf="!menuItem.children; else childrenMenu">
        <ng-container *ngIf="menuItem.status != 'EMPTY'">
          <li>
            <a
              routerLink="/{{ menuItem.path }}"
              [attr.aria-label]="menuItem.title"
              routerLinkActive="active"
              class="fw-regular"
            >
              <mat-icon fontIcon="{{ menuItem.icon }}"></mat-icon>
              <span>{{
                'navigation.' + menuItem.translationKey | translate
              }}</span>
            </a>
          </li>
        </ng-container>
      </ng-container>
      <ng-template #childrenMenu>
        <mat-accordion class="accordion">
          <mat-expansion-panel
            class="accordion__panel"
            [expanded]="menuItem.open"
          >
            <mat-expansion-panel-header
              class="accordion__header"
              [attr.aria-label]="menuItem.title"
              matTooltip="{{ menuItem.description }}"
            >
              <mat-icon fontIcon="{{ menuItem.icon }}"></mat-icon>
              <span>{{
                'navigation.' + menuItem.translationKey | translate
              }}</span>
            </mat-expansion-panel-header>
            <ul *ngIf="menuItem.children.length > 0">
              <li *ngFor="let child of menuItem.children">
                <a
                  [attr.aria-label]="child.title"
                  [matTooltip]="child.description + child.path"
                  [routerLink]="'/' + child.path"
                  routerLinkActive="active"
                >
                  <mat-icon fontIcon="{{ child.icon }}"></mat-icon>
                  <span>{{
                    'navigation.' + child.translationKey | translate
                  }}</span>
                </a>
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-template>
    </ng-container>
    <li>
      <a
        [href]="userService.currentUser.spendUrl"
        routerLinkActive="active"
        class="fw-regular"
        target="_blank"
      >
        <mat-icon fontIcon="shopping_cart"></mat-icon>
        <span>{{ 'navigation.spend-analysis' | translate }}</span>
      </a>
    </li>
  </ul>
</aside>
