<div class="container table-wrapper">
  <div class="row justify-content-between">
    <h1 *ngIf="showTitle" class="col-10 mt-5">
      {{ translationKey + '.header' | translate }}
    </h1>

    <div  class="col-6 mt-5">
      <ng-container *ngIf="showActionMenu" #dynamicContainer></ng-container>
    </div>

    <div class="col-6 mt-5">
      <DimaTable-paginator #paginator paginatorId="table"></DimaTable-paginator>
    </div>

    <div #tableContainer class="col-12 mt-5">
      <DimaTable #table id="table" [dataSource]="tableService.data" [columns]="tableService.cols" [useFilterRow]="true"
        [useFooterRow]="false" [usePaginator]="true" [pageSizeOptions]="[5, 10, 25, 50, 100, 1000]" [fixedData]="false"
        [length]="tableService.totalCount" [pageSize]="tableService.pageSize"
        [translations]="tableService.dimaTableTranslations" (navigation)="navigation($event)"
        (rowAllChecked)="rowAllChecked($event)">
        <ng-template Custom [name]="sliderFieldName" let-row="row">
          <mat-slide-toggle (change)="onSliderChange(row)" [checked]="row[sliderFieldName]"
            matTooltip=" {{ translationKey + '.slider-tooltip' + (isSliderDisabled(row) && !isReadonlyUser() ? '-disabled' : '') | translate }}"
            [disabled]="isSliderDisabled(row)">
          </mat-slide-toggle>
        </ng-template>
        <ng-template Custom [name]="'edit'" let-row="row">
          <button matTooltip="{{ translationKey + '.edit-tooltip' | translate }}" mat-icon-button
            (click)="editDialog(row)" color="primary">
            <mat-icon fontIcon="edit"></mat-icon>
          </button>
        </ng-template>
        <ng-template *ngIf="showDeleteButton" Custom [name]="'delete'" let-row="row">
          <button matTooltip="{{ translationKey + '.delete-tooltip' | translate }}" mat-icon-button
            [disabled]="rowDeleteButtonDisabled(row)" (click)="deleteDialog([row.id])" color="warn">
            <mat-icon fontIcon="delete"></mat-icon>
          </button>
        </ng-template>
        <ng-template *ngFor="let checkboxFieldName of checkboxFieldNames" Custom [name]="checkboxFieldName"
          let-row="row">
          <div matTooltip="{{ translationKey + '.' + checkboxFieldName + '-checkbox-tooltip' | translate }}"
            [matTooltipDisabled]="false">
            <mat-checkbox [checked]="row[checkboxFieldName]" [disabled]="true"
              matTooltip="{{ translationKey + '.' + checkboxFieldName + '-checkbox-tooltip' | translate }}">
            </mat-checkbox>
          </div>
        </ng-template>
      </DimaTable>
    </div>
  </div>
</div>