<form [formGroup]="formService.form">
  <div class="container gx-0">
    <div class="row">
      <div class="col-12 mt-3 mb-3">
        <p class="fw-bold fs-30">
          {{ 'navigation.customers-average-price' | translate }}
        </p>
      </div>
    </div>
    <div class="row justify-content-around align-items-stretch mt-3">
      <div>
        <div mat-dialog-content>
          <div class="row main-row-dialog">
            <div class="col-12 mt-3">
              <div class="row">
                <div class="col-12">
                  <div class="row">

                    <div class="col-6" *ngIf="supplierOptionsInitialized">
                      <app-custom-autocomplete [parentGroup]="formService.form" [options]="supplierOptions"
                        controlName="SupplierID" [filterFieldNames]="['supplierName', 'supplierId']"
                        valueFieldName="supplierId" orderFieldName="supplierName"
                        displayFieldPattern="${supplierName} (CVR:${supplierCountry}${supplierId})"
                        labelTranslationKey="customers-average-price.supplier"
                        (selectionChanged)="onSupplierSelectionChange($event)"
                        (valueCleared)="onSupplierValueCleared()">
                      </app-custom-autocomplete>
                    </div>

                    <div class="col-6" style="display: none;">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-average-price.supplier-country' | translate
                          }}</mat-label>
                        <input formControlName="SupplierCountry" matInput placeholder="{{
                            'customers-average-price.supplier-country'
                              | translate
                          }}" />
                      </mat-form-field>
                    </div>

                    <div class="col-6" *ngIf="unspscsOptionsInitialized">
                      <app-custom-autocomplete [parentGroup]="formService.form" [options]="unspscsOptions"
                        controlName="Unspsc" [filterFieldNames]="['idDescriptionUnspsc']" valueFieldName="unspscClassId"
                        displayFieldPattern="${idDescriptionUnspsc}" orderFieldName="idDescriptionUnspsc"
                        labelTranslationKey="customers-average-price.unspsc"
                        (selectionChanged)="onUnspscSelectionChange($event)" (valueCleared)="onUnspscValueCleared()">
                      </app-custom-autocomplete>
                    </div>

                    <div class="col-6" style="display: none;">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-average-price.unspsc' | translate
                          }}</mat-label>
                        <input formControlName="UnspscClassId" matInput placeholder="{{
                            'customers-average-price.unspsc'
                              | translate
                          }}" />
                      </mat-form-field>
                    </div>

                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-6" *ngIf="editData ? unitOptionsInitialized : true">
                      <app-custom-autocomplete [parentGroup]="formService.form" [options]="unitOptions"
                        controlName="Unit" [filterFieldNames]="['unitLabel']" valueFieldName="unit"
                        displayFieldPattern="${unitLabel}" orderFieldName="unit"
                        labelTranslationKey="customers-average-price.unit">
                      </app-custom-autocomplete>
                    </div>

                    <div class="col-3">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-average-price.average-price-per-unit-calc'
                          | translate
                          }}</mat-label>
                        <input formControlName="AveragePricePerUnitCalc" matInput placeholder="{{
                                'customers-average-price.average-price-per-unit-calc'
                                  | translate
                              }}" />
                      </mat-form-field>
                      <!-- <span *ngIf="formService.form.controls.AveragePricePerUnitCalc.hasError('pattern') 
                          && formService.form.controls.AveragePricePerUnitCalc.touched" class="error">
                        {{ 'error-handling.numeric-value-error' | translate }}</span> -->
                    </div>

                    <div class="col-3">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-average-price.average-price-per-unit-entered'
                          | translate
                          }}</mat-label>
                        <input formControlName="AveragePricePerUnitEntered" matInput placeholder="{{
                                'customers-average-price.average-price-per-unit-entered'
                                  | translate
                              }}" />
                        <mat-error *ngIf="formService.form.get('AveragePricePerUnitEntered').hasError('pattern')">
                          {{ 'error-handling.numeric-value-error' | translate }}
                        </mat-error>
                      </mat-form-field>
                      <!-- <span
                        *ngIf="formService.form.controls.AveragePricePerUnitEntered.touched || formService.form.controls.AveragePricePerUnitCalc.touched"
                        [hidden]="!formService.form.hasError('atLeastOne')" class="error">
                        {{ 'customers-average-price.price-fields-error' | translate }}
                      </span> -->
                      <!-- <span *ngIf="formService.form.controls.AveragePricePerUnitEntered.hasError('pattern') 
                          && formService.form.controls.AveragePricePerUnitEntered.touched" class="error">
                        {{ 'error-handling.numeric-value-error' | translate }}
                      </span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div mat-dialog-actions [align]="'end'">
          <button mat-raised-button (click)="cancel()" [mat-dialog-close]="false">
            {{ 'customers-average-price.cancel' | translate }}
          </button>
          <button mat-raised-button color="primary" [disabled]="formService.form.invalid || isReadonlyUser()" (click)="submitForm()">
            {{ 'customers-average-price.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>