import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { DocumentationDetails } from 'src/app/commons/models/documentation-details.model';
import { OrderByPipe } from 'src/app/commons/pipes/order-by.pipe';
import { ApiService } from 'src/app/services/api.service';
import { CurrentLanguageService } from 'src/app/services/current-language.service';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
})
export class UserGuideComponent implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<DocumentationDetails>;
  columns = ['url', 'description', 'updateDate', 'fileName'];
  displayedColumns = ['url', 'description', 'updateDate'];
  appDatabaseLanguageCodeMapping = {
    da: 'DK',
    no: 'NO',
    en: 'UK'
  };

  constructor(private tenantSelectService: TenantSelectService,
    private currentLanguageService: CurrentLanguageService,
    private apiService: ApiService,
    private orderByPipe: OrderByPipe) { }

  ngOnInit(): void {
    this.loadData();
    this.currentLanguageService.languageChanges.subscribe(() => {
      this.loadData();
    });
  }

  async ngAfterViewInit(): Promise<void> {
    await this.tenantSelectService.selectIdpUrlParam();
  }

  private loadData(): void {
    this.apiService.getLanguages().subscribe(languages => {
      const foundLanguage = languages.filter(x => x.languageCode == this.appDatabaseLanguageCodeMapping[this.currentLanguageService.lang])[0];
      this.apiService.getDocumentationDetails(foundLanguage.id).subscribe(details => {
        this.dataSource = new MatTableDataSource(this.orderByPipe.transform(details, 'fileName'));
      });
    });
  }
}
