import { NgModule } from '@angular/core';
import { OrderByNumberPipe, OrderByPipe } from './pipes/order-by.pipe';

@NgModule({
  declarations: [
    OrderByPipe,
    OrderByNumberPipe
  ],
  exports: [
    OrderByPipe,
    OrderByNumberPipe
  ]
})
export class PipesModule { }