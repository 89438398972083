import { FilterValues } from "./filter-values.model";
import { PbiFilter } from "./pbi-filter.model";
import { Period } from "./period.model";

export class Filter {
    timePeriod: Period;
    pbiFilters: PbiFilter[];
    filterValues: FilterValues[];
    pagePbiFilters: PbiFilter[];
    pageFilterValues: FilterValues[];
    frontendKeys?: FilterValues[];
}