import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDataService } from 'src/app/commons/components/data-view/data-service.interface';
import { EntityTrackerError } from 'src/app/commons/models/entity-tracker-error';
import { FiscalYear, FiscalYearValidationModel } from 'src/app/commons/models/fiscal-year.model';
import { PageResponse } from 'src/app/commons/models/page-response';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class FiscalYearService implements IDataService {
  constructor(private apiService: ApiService) { }

  getByUniqueKey(model: FiscalYearValidationModel): Observable<any | EntityTrackerError> {
    return this.apiService.getFiscalYearUnique(model);
  }

  add(fiscalYearFormValues): Observable<number | EntityTrackerError> {
    fiscalYearFormValues['ModifyDate'] = fiscalYearFormValues['CreationDate'];
    fiscalYearFormValues['ModifiedBy'] = fiscalYearFormValues['CreatedBy'];

    return this.apiService.addFiscalYear(fiscalYearFormValues);
  }

  update(updateModel: FiscalYear) {
    return this.apiService.updateFiscalYear(updateModel);
  }

  sliderChange(updateModel: FiscalYear, sliderValue: boolean) {
    throw new Error('Method not implemented.');
  }

  delete(ids: number[]): Observable<PageResponse | EntityTrackerError> {
    return this.apiService.deleteFiscalYear(ids);
  }
}