import { Injectable } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { EntityTrackerError } from '../../../commons/models/entity-tracker-error';
import { PageResponse } from '../../../commons/models/page-response';
import { PageModel } from '../../../commons/models/page-model';
import { CustomersAveragePriceDto } from 'src/app/commons/models/customers-average-price-dto';
import { CapUniqueKeyModel } from 'src/app/commons/models/cap-unique-key-model';
import { IDataService, IGetDataService } from 'src/app/commons/components/data-view/data-service.interface';
import { SupplierDto } from 'src/app/commons/models/supplier-dto';
import { DeepCloneService } from 'src/app/commons/services/deep-clone.service';
import { UnspscUnitDto } from 'src/app/commons/models/unspsc-unit-dto';
import { CurrentLanguageService } from 'src/app/services/current-language.service';

@Injectable({
  providedIn: 'root',
})
export class CustomersAveragePriceGetService implements IGetDataService {
  constructor(private apiService: ApiService, private deepCloneService: DeepCloneService, private languageService: CurrentLanguageService) { }

  get(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    pageModel.languageCode = this.languageService.lang;
    return this.apiService.getCap(pageModel);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomersAveragePriceService implements IDataService {
  constructor(private apiService: ApiService, private deepCloneService: DeepCloneService, private languageService: CurrentLanguageService) { }

  getByUniqueKey(model: CapUniqueKeyModel): Observable<CustomersAveragePriceDto | EntityTrackerError> {
    return this.apiService.getCapByUniqueKey(model);
  }

  add(capFormValues): Observable<number | EntityTrackerError> {
    capFormValues['ModifyDate'] = capFormValues['CreationDate'];
    capFormValues['ModifiedBy'] = capFormValues['CreatedBy'];

    return this.apiService.addCap(capFormValues);
  }

  update(updateModel: CustomersAveragePriceDto): Observable<number | EntityTrackerError> {
    return this.apiService.updateCap(updateModel);
  }

  sliderChange(updateModel: CustomersAveragePriceDto, sliderValue: boolean) {
    return this.update(updateModel);
  }

  delete(ids: number[]): Observable<PageResponse | EntityTrackerError> {
    return this.apiService.deleteCap(ids);
  }

  getSuppliers(): Observable<SupplierDto[] | EntityTrackerError> {
    return this.apiService.getSuppliers();
  }

  getUnspscWithUnits(supplierId?: string, supplierCountry?: string): Observable<UnspscUnitDto[] | EntityTrackerError> {
    return this.apiService.getUnspscWithUnits(supplierId, supplierCountry);
  }
}
