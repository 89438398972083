import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
    standalone: false
})
export class OrderByPipe implements PipeTransform {
    transform(array: any[], field: string): any[] {
        if (!array)
            return array; array = [...array];
        array.sort((a, b) => a[field].toLowerCase().localeCompare(b[field].toLowerCase()));
        return array;
    }
}

@Pipe({
    name: 'orderByNumber',
    standalone: false
})
export class OrderByNumberPipe implements PipeTransform {
    transform(array: any[], field: string): any[] {
        if (!array)
            return array; array = [...array];
        array.sort((a, b) => a[field] - b[field]);
        return array;
    }
}
