import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { Subscription } from 'rxjs';
import { DeepCloneService } from '../../../../commons/services/deep-clone.service';
import { DialogService } from '../../../../services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import {
  CalculateDto,
  CalculateMasterDto,
  CalculateWorkDto,
} from '../../../../commons/models/scenario-dto';
import { ScenariosCrudHelperService } from '@modules/scenarios/services/scenarios/scenarios-crud-helper.service';
import { UnspscHandlerService } from 'src/app/commons/components/unspsc-handler/unspsc-handler.service';
import { CalculatorPrepareDataService } from '@modules/scenarios/services/calculator/calculator-prepare-data.service';
import { CalculatorService } from '@modules/scenarios/services/calculator/calculator.service';
import { CalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.service';

@Component({
    selector: 'app-save-as-scenario-button',
    templateUrl: './save-as-scenario-button.component.html',
    styleUrls: ['./save-as-scenario-button.component.scss'],
    standalone: false
})
export class SaveAsScenarioButtonComponent implements OnInit {
  @Input() scenarioValues: any;
  addScenarioSubscription: Subscription;
  addScenarioWorkCalculationSubscription: Subscription;

  constructor(
    private apiService: ApiService,
    private calculatorPrepareDataService: CalculatorPrepareDataService,
    private calculatorService: CalculatorService,
    private scenariosCrudHelperService: ScenariosCrudHelperService,
    private deepCloneService: DeepCloneService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private unspscHandlerService: UnspscHandlerService,
    private calculatorHelperService: CalculatorHelperService
  ) { }

  ngOnInit(): void { }

  async saveAsScenario(templateFormValues) {
    let forecastLengths = [];
    let calculationUnspscs = [];
    
    templateFormValues.unspscs.forEach((el) =>
      forecastLengths.push(el.forecastLength)
    );
    templateFormValues.unspscs.forEach((el) =>
      calculationUnspscs.push(el.unspscClassId)
    );

    let maxForecastLength = Math.max(...forecastLengths);
    let currentYear = new Date().getFullYear().toString();
    let lastYear = Number(currentYear) + Number(maxForecastLength);

    let templateFormValuesClone =
      this.deepCloneService.deepClone(templateFormValues);

    Object.assign(templateFormValuesClone, { calculatorUnspsc: calculationUnspscs });

    const selectedUnspscs = this.calculatorPrepareDataService.generateSelectedUnspscManually(templateFormValuesClone.unspscs);
    const form = this.calculatorHelperService.getUnspscsFormValues(templateFormValuesClone, selectedUnspscs, 'calculatorUnspsc');

    await this.calculatorService
      .calculateSaveTemplateAsScenario(form)
      .subscribe((res) => {
        let scenarioMasterData: CalculateMasterDto = {
          description: templateFormValues.description,
          name: templateFormValues.name,
          email: templateFormValues.email,
        };

        this.addScenarioSubscription = this.apiService
          .addScenarioMaster(scenarioMasterData)
          .subscribe((scenarioId) => {

            let scenarioWorkData: CalculateWorkDto[] =
              this.calculatorPrepareDataService.prepareWorkData(
                templateFormValues,
                scenarioId,
                selectedUnspscs
              );

            this.addScenarioWorkCalculationSubscription = this.apiService
              .addScenarioWorkCalculations(scenarioWorkData)
              .subscribe((res) => {
                this.calculatorPrepareDataService
                  .prepareCalculateDataForSaving(
                    this.unspscHandlerService.selectedUnspscs,
                    templateFormValues,
                    scenarioId
                  )
                  .subscribe((scenarioCalculateData) => {
                    if (
                      scenarioCalculateData instanceof Array &&
                      scenarioCalculateData.length !== 0
                    ) {
                      this.scenariosCrudHelperService.addcalculations(
                        scenarioCalculateData
                      );
                      this.dialogService.inform({
                        message: this.translateService.instant(
                          'general.scenario-saved'
                        ),
                      });
                    }
                  });
              });
          });
      });
  }
}
