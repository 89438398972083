import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../../services/api.service';
import {
  CalculateDto,
  CalculateWorkDto,
} from '../../../../commons/models/scenario-dto';
import { IScenariosCrudHelperService } from '@modules/scenarios/services/scenarios/scenarios-crud-helper.interface';
import { Unspsc } from 'src/app/commons/models/unspsc';
import { CalculatorPrepareDataService } from '../calculator/calculator-prepare-data.service';
import { CalculatorFormService } from '../calculator/calculator-form.service';

@Injectable({
  providedIn: 'root',
})
export class ScenariosCrudHelperService implements IScenariosCrudHelperService {
  addScenarioSubscription?: Subscription;
  addScenarioWorkCalculationSubscription: Subscription;
  addcalculationsubscription: Subscription;
  updateScenarioSubscription?: Subscription;
  updateScenarioWorkCalculationSubscription: Subscription;
  updatecalculationsubscription: Subscription;

  constructor(
    public scenariosFormService: CalculatorFormService,
    public calculatorPrepareDataService: CalculatorPrepareDataService,
    public apiService: ApiService
  ) { }

  addScenarioMaster(formValue, selectedUnspscs: Unspsc[][]): void {
    let scenarioMasterData =
      this.calculatorPrepareDataService.prepareMasterData(formValue);

    // Working database version
    this.addScenarioSubscription = this.apiService
      .addScenarioMaster(scenarioMasterData)
      .subscribe((id) => {
        this.scenariosFormService.id = id as number;

        let scenarioWorkData = this.calculatorPrepareDataService.prepareWorkData(
          formValue,
          id,
          selectedUnspscs
        );

        this.addScenarioWorkCalculations(selectedUnspscs, scenarioWorkData);
      });
  }

  addScenarioWorkCalculations(selectedUnspscs: Unspsc[][], CalculateWorkDtos: CalculateWorkDto[]): void {
    // Working database version
    this.addScenarioWorkCalculationSubscription = this.apiService
      .addScenarioWorkCalculations(CalculateWorkDtos)
      .subscribe((res) => {
        this.calculatorPrepareDataService
          .prepareCalculateDataForSaving(selectedUnspscs)
          .subscribe((scenarioCalculateData) => {
            if (
              scenarioCalculateData instanceof Array &&
              scenarioCalculateData.length !== 0
            ) {
              this.addcalculations(scenarioCalculateData);
            } else {
              this.scenariosFormService.form.reset();
              this.scenariosFormService.id = undefined;
              this.scenariosFormService.dialogRef.close('save');
            }
          });
      });
  }

  addcalculations(calculations: CalculateDto[]): void {
    // Working database version
    this.addcalculationsubscription = this.apiService
      .addcalculations(calculations)
      .subscribe((res) => {
        this.scenariosFormService.form?.reset();
        this.scenariosFormService.id = undefined;
        this.scenariosFormService.dialogRef?.close('save');
      });
  }

  updateScenario(dwskOld, id, selectedUnspscs: Unspsc[][]): void {
    let updateMasterData = this.calculatorPrepareDataService.prepareMasterData(
      this.scenariosFormService.form.value
    );

    this.updateScenarioSubscription = this.apiService
      .updateMasterScenario(dwskOld, updateMasterData)
      .subscribe((dwskScenarioNew) => {
        this.scenariosFormService.id = dwskScenarioNew as number;

        let scenarioWorkData = this.calculatorPrepareDataService.prepareWorkData(
          this.scenariosFormService.form.value,
          dwskScenarioNew, selectedUnspscs
        );

        this.updateScenarioWorkCalculations(selectedUnspscs, scenarioWorkData, dwskOld);
      });
  }

  updateScenarioWorkCalculations(
    selectedUnspscs: Unspsc[][],
    CalculateWorkDtos: CalculateWorkDto[],
    dwskOld
  ): void {
    this.updateScenarioWorkCalculationSubscription = this.apiService
      .updateWorkScenario(dwskOld, CalculateWorkDtos)
      .subscribe((res) => {
        this.calculatorPrepareDataService
          .prepareCalculateDataForSaving(selectedUnspscs)
          .subscribe((scenarioCalculateData) => {
            this.updatecalculations(scenarioCalculateData, dwskOld);
          });
      });
  }

  updatecalculations(
    calculations: CalculateDto[],
    dwskOld
  ): void {
    this.updatecalculationsubscription = this.apiService
      .updatecalculations(dwskOld, calculations)
      .subscribe((res) => {
        this.scenariosFormService.form.reset();
        this.scenariosFormService.id = undefined;
        this.scenariosFormService.dialogRef.close('update');
      });
  }

  unsubscribe() {
    if (this.addScenarioSubscription) {
      this.addScenarioSubscription.unsubscribe();
    }

    if (this.addScenarioWorkCalculationSubscription) {
      this.addScenarioWorkCalculationSubscription.unsubscribe();
    }

    if (this.addcalculationsubscription) {
      this.addcalculationsubscription.unsubscribe();
    }

    if (this.updateScenarioSubscription) {
      this.updateScenarioSubscription.unsubscribe();
    }

    if (this.updateScenarioWorkCalculationSubscription) {
      this.updateScenarioWorkCalculationSubscription.unsubscribe();
    }

    if (this.updatecalculationsubscription) {
      this.updatecalculationsubscription.unsubscribe();
    }
  }
}
