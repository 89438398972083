import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';

// Custom modules
import { AuthModule } from '@modules/auth/auth.module';

// Components
import { ShellComponent } from './components/shell/shell.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { MenuComponent } from './components/menu/menu.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TenantSelectComponent } from './components/tenant-select/tenant-select.component';
import { UserInformationDialogComponent } from '../../commons/components/user-information-dialog/user-information-dialog.component';
import { PipesModule } from 'src/app/commons/pipes.module';

@NgModule({
  declarations: [
    ShellComponent,
    TopBarComponent,
    SpinnerComponent,
    InformationDialogComponent,
    MenuComponent,
    SettingsComponent,
    TenantSelectComponent,
    UserInformationDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AuthModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    MatExpansionModule,
    MatMenuModule,
    TranslateModule,
    ReactiveFormsModule,
    PipesModule,
    FormsModule
  ],
  exports: [ShellComponent]
})
export class ShellModule {}
