import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DimaTableLibraryModule } from '@dima/table-library';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CodActionMenuComponent } from './components/cod-action-menu/cod-action-menu.component';
import { CodTabViewComponent } from './cod-tab-view/cod-tab-view.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { CodExclusionActionMenuComponent } from '@modules/customers-own-data-exclusion/components/cod-exclusion-action-menu/cod-exclusion-action-menu.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    DimaTableLibraryModule,
    MatFormFieldModule,
    MatIconModule,
    MatTabsModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    AppRoutingModule
  ],
  declarations: [
    CodActionMenuComponent,
    CodExclusionActionMenuComponent,
    CodTabViewComponent
  ]
})
export class CustomersOwnDataModule { }
