import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '../../../../services/error.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-error',
  templateUrl: './custom-error.component.html',
  styleUrls: ['./custom-error.component.scss'],
})
export class CustomErrorComponent {
  @Input() errorCode: string;
  @Input() errorCodeDescription: string;
  @Input() errorTitle: string;
  @Input() errorSubtitle: string;
  @Input() buttonName: string;
  @Input() navigationRoute: string;
  errorIdSubscription: Subscription;
  errorId: any;

  constructor(private router: Router, private errorService: ErrorService) {
    this.errorIdSubscription = errorService.errorIdSubject.subscribe(
      (error) => (this.errorId = error)
    );
  }

  goHome() {
    this.router.navigate([this.navigationRoute]);
  }

  ngOnDestroy(): void {
    this.errorIdSubscription.unsubscribe();
  }
}
