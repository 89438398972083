import { Injectable } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { EntityTrackerError } from '../../../commons/models/entity-tracker-error';
import { PageResponse } from '../../../commons/models/page-response';
import { PageModel } from '../../../commons/models/page-model';
import { CustomersOwnDataDto } from 'src/app/commons/models/customers-own-data-dto';
import { CodUniqueKeyModel } from 'src/app/commons/models/cod-unique-key-model';
import { IDataService, IGetDataService } from 'src/app/commons/components/data-view/data-service.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomersOwnDataGetService implements IGetDataService {
  constructor(private apiService: ApiService) {}

  get(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    return this.apiService.getCod(pageModel);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomersOwnDataService implements IDataService {
  constructor(private apiService: ApiService) {}

  getByUniqueKey(model: CodUniqueKeyModel): Observable<CustomersOwnDataDto | EntityTrackerError> {
    return this.apiService.getCodByUniqueKey(model);
  }

  add(codFormValues): Observable<number | EntityTrackerError> {
    codFormValues['ModifyDate'] = codFormValues['CreationDate'];
    codFormValues['ModifiedBy'] = codFormValues['CreatedBy'];

    return this.apiService.addCod(codFormValues);
  }

  update(updateModel: CustomersOwnDataDto) {
    return this.apiService.updateCod(updateModel);
  }

  sliderChange(updateModel: CustomersOwnDataDto, sliderValue: boolean) {
    return this.update(updateModel);
  }

  delete(ids: number[]): Observable<PageResponse | EntityTrackerError> {
    return this.apiService.deleteCod(ids);
  }
}
