<div [formGroup]="parentGroup">
    <mat-form-field appearance="fill">
        <mat-label>{{
            labelTranslationKey | translate
            }}</mat-label>
        <input type="text" matInput [matAutocomplete]="auto" matInput [formControlName]="controlName" (input)="inputTextChange($event)"
            (click)="reloadOptions()" (blur)="onBlur()">
        <mat-icon class="info" *ngIf="controlName==='Unit'"
            matTooltip="{{ 'customers-average-price.unit-dropdown-tooltip' | translate }}">info</mat-icon>
        <mat-icon class="info" *ngIf="controlName==='Unspsc'"
            matTooltip="{{ 'customers-average-price.unspsc-dropdown-tooltip' | translate }}">info</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
            <cdk-virtual-scroll-viewport #viewPort itemSize="25"
                [style.height.px]="(filteredOptionsList?.length > 5 ? 45 * 5 : 45 * filteredOptionsList?.length)"
                maxBufferPx="144" (scrolledIndexChange)="onScroll()">
                <mat-option class="no-selected-class" *cdkVirtualFor="let option of filteredOptionsList" (click)="onSelectionChange(option)"
                    [value]="option[valueFieldName]" [disabled]="option?.disabled">
                    {{ displayFieldPattern | displayPattern : option }}
                </mat-option>
            </cdk-virtual-scroll-viewport>
        </mat-autocomplete>
    </mat-form-field>
</div>