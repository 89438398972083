import { Injectable } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { EntityTrackerError } from '../../../commons/models/entity-tracker-error';
import { PageResponse } from '../../../commons/models/page-response';
import { PageModel } from '../../../commons/models/page-model';
import { CodUniqueKeyModel } from 'src/app/commons/models/cod-unique-key-model';
import { IDataService, IGetDataService } from 'src/app/commons/components/data-view/data-service.interface';
import { CustomersExclusionRuleDto } from 'src/app/commons/models/customers-exclusion-rule-dto';

@Injectable({
  providedIn: 'root',
})
export class CustomersExclusionRulesGetService implements IGetDataService {
  constructor(private apiService: ApiService) { }

  get(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    return this.apiService.getCodExclusion(pageModel);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomersExclusionRulesService implements IDataService {
  constructor(private apiService: ApiService) { }

  get(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    return this.apiService.getCodExclusion(pageModel);
  }

  getByUniqueKey(model: CodUniqueKeyModel): Observable<CustomersExclusionRuleDto | EntityTrackerError> {
    throw new Error('not implemented');
  }

  add(createModel: CustomersExclusionRuleDto): Observable<number | EntityTrackerError> {
    return this.apiService.addCodExclusion(createModel);
  }

  update(updateModel: CustomersExclusionRuleDto) {
    return this.apiService.updateCodExclusion(updateModel);
  }

  sliderChange(updateModel: CustomersExclusionRuleDto, sliderValue: boolean) {
    return this.update(updateModel);
  }

  delete(ids: number[]): Observable<PageResponse | EntityTrackerError> {
    return this.apiService.deleteCodExclusion(ids);
  }
}
