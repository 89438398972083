<mat-dialog-content class="dialog-content" (keydown.enter)="onEnterKeyPressed($event)">
  <form [formGroup]="bookmarkService.bookmarkForm">
    <div class="order-list">
      <div class="dialog__header">
        {{ isEditMode ? ('bookmarks.edit-bookmark' | translate) : ('bookmarks.save-bookmark' | translate) }}
      </div>

      <div class="dialog__close" (click)="close()">
        <mat-icon>close</mat-icon>
      </div>

      <div class="bookmark-input">
        <mat-form-field>
          <mat-label>{{ 'bookmarks.bookmark-name' | translate }}</mat-label>
          <input appSanityAsyncValidator required formControlName="bookmarkName" matInput
            placeholder="{{ 'bookmarks.bookmark-name' | translate }}" />
        </mat-form-field>
        <mat-error *ngIf="checkIfNameAlreadyExists()">
          {{ 'bookmarks.bookmark-error-part-one' | translate }}
          {{
          bookmarkService.bookmarkForm.get('bookmarkName').getError('sameName')
          }}
          {{ 'bookmarks.bookmark-error-part-two' | translate }}
        </mat-error>
      </div>

      <div class="dialog__buttons pt-3">
        <button id="discardBookmarkBtn" mat-flat-button color="warn" (click)="close()">
          {{ 'bookmarks.close' | translate }}
        </button>
        <button id="saveBookmarkBtn" mat-flat-button color="primary" [disabled]="bookmarkService.bookmarkForm.invalid" (click)="save()">
          {{ 'bookmarks.save' | translate }}
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>