import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProductSpecificEmissionsFormService } from '@modules/product-specific-emissions/services/product-specific-emissions-form.service';
import { ProductSpecificEmissionsService } from '@modules/product-specific-emissions/services/product-specific-emissions.service';
import {
  OverWriteStatus,
  VerifiedStatus,
  ActivatedStatus,
  ProductSpecificEmissionDto,
  UniqueEntityValidationResponse,
} from '../../../../commons/models/product-specific-emission-dto';
import { DialogService } from '../../../../services/dialog.service';
import { CreateEditDialogComponent } from 'src/app/commons/components/create-edit-dialog/create-edit-dialog.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { EntityTrackerError } from 'src/app/commons/models/entity-tracker-error';
import { DialogResultStatus } from 'src/app/commons/existing-record-dialog/dialog-result-status';
import { BaseDto } from 'src/app/commons/models/base-dto';
import { UserService } from '@modules/shell/services/user.service';
import { EmissionSupplierItemDto } from 'src/app/commons/models/emission-supplier-item-dto';
import { SupplierDto } from 'src/app/commons/models/supplier-dto';
import { Method } from 'src/app/commons/models/method';
import { DialogConfigData } from 'src/app/commons/models/dialog-config-data';

@Component({
    selector: 'app-create-edit-scenario-dialog',
    templateUrl: './create-edit-pse-dialog.component.html',
    styleUrls: ['./create-edit-pse-dialog.component.scss'],
    standalone: false
})
export class CreateEditPseDialogComponent extends CreateEditDialogComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef;

  public years = ['1', '2', '3', '4', '5'];
  public editingMode: boolean = false;
  public overWriteStatus: OverWriteStatus[] = [];
  public verifiedStatus: VerifiedStatus[] = [];
  public activatedStatus: ActivatedStatus[] = [];

  fileNameMaxWidth: number = 400;

  emissionSuppliersOptionsObservable: Observable<SupplierDto[] | EntityTrackerError>;
  supplierItemsOptionsObservable: Observable<EmissionSupplierItemDto[] | EntityTrackerError>;

  supplierItems: EmissionSupplierItemDto[];

  supplierItemUnitsOptionsSubject: BehaviorSubject<any[] | EntityTrackerError> = new BehaviorSubject<any[] | EntityTrackerError>([]);
  supplierItemUnits: string[];

  formInitializationSubscription: Subscription;

  supplierOptions: any[] = [];
  supplierItemOptions: any[] = [];
  supplierItemUnitsOptions: any[] = [];

  supplierOptionsInitialized = false;
  supplierItemOptionsInitialized = false;
  itemUnitsOptionsInitialized = false;

  overlappingDatesErrorMessage = '';

  methods = Object.values(Method).filter(value => typeof value !== 'number');

  selectedFile: File;
  selectedFilename: string;

  invalidFileFormat: boolean = false;
  pseDialogUserService: UserService;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    dialogRef: MatDialogRef<CreateEditPseDialogComponent>,
    private translateService: TranslateService,
    public formService: ProductSpecificEmissionsFormService,
    public productSpecificEmissionsService: ProductSpecificEmissionsService,
    dialogService: DialogService,
    userService: UserService,
    private cdRef: ChangeDetectorRef) {
    super(editData,
      productSpecificEmissionsService,
      formService,
      dialogRef,
      dialogService,
      userService);

    this.pseDialogUserService = userService;
    this.overWriteStatus = [
      {
        flag: true,
        translation: translateService.instant(
          'product-specific-emissions.can-overwrite'
        ),
      },
      {
        flag: false,
        translation: translateService.instant(
          'product-specific-emissions.cannot-overwrite'
        ),
      },
    ];

    this.verifiedStatus = [
      {
        flag: true,
        translation: translateService.instant(
          'product-specific-emissions.verified'
        ),
      },
      {
        flag: false,
        translation: translateService.instant(
          'product-specific-emissions.not-verified'
        ),
      },
    ];

    this.activatedStatus = [
      {
        flag: true,
        translation: translateService.instant(
          'product-specific-emissions.active'
        ),
      },
      {
        flag: false,
        translation: translateService.instant(
          'product-specific-emissions.inactive'
        ),
      },
    ];

    this.overlappingDatesErrorMessage = translateService.instant(
      'product-specific-emissions.overlapping-dates'
    );
  }

  ngOnInit(): void {
    if (this.editData) {
      this.selectedFilename = this.editData?.documentFileName;
    }

    this.emissionSuppliersOptionsObservable = this.productSpecificEmissionsService.getEmissionSuppliers();
    this.emissionSuppliersOptionsObservable.subscribe(x => {
      this.supplierOptions = x as any[];
      this.supplierOptionsInitialized = true;
      this.cdRef.detectChanges();
    });
  }

  onSupplierSelectionChange(option: any): void {
    this.formService.form.controls.SupplierCountry
      .setValue(option.supplierCountry ?? '');

    this.formService.form.controls.ItemID.setValue('');
    this.formService.form.controls.ItemID.enable();

    this.formService.form.controls.UnitCode.setValue('');
    this.formService.form.controls.UnitCode.disable();

    this.productSpecificEmissionsService.getEmissionItems(option.supplierId ?? '', option.supplierCountry ?? '').subscribe(x => {
      this.supplierItemOptions = x as any[];
      this.supplierItemOptionsInitialized = true;
      this.cdRef.detectChanges();
    });
  }

  onSupplierItemSelectionChange(option: any): void {
    this.formService.form.controls.UnitCode.setValue('');
    this.formService.form.controls.UnitCode.enable();

    if (option.itemId != '') {
      this.supplierItemUnitsOptions = this.supplierItemOptions.find(x => x.itemId === option.itemId)?.unitCodes.map((x: string) => <UnitCodeOption>{ unitCode: x });
      this.itemUnitsOptionsInitialized = true;
    }
  }

  onSupplierSelectionDataInitialized(): void {
    if (this.formService.editData) {
      this.formService.form.controls.SupplierID.setValue(this.editData.supplierId);
      this.formService.form.controls.SupplierCountry.setValue(this.editData.countryId);

      this.productSpecificEmissionsService.getEmissionItems(this.editData.supplierId, this.editData.countryId).subscribe(x => {
        this.supplierItemOptions = x as any[];
        this.supplierItemOptionsInitialized = true;
        this.cdRef.detectChanges();
      });
    }
  }

  onSupplierItemSelectionDataInitialized(): void {
    if (this.formService.editData) {
      this.formService.form.controls.ItemID.enable();
      this.formService.form.controls.ItemID.setValue(this.editData.itemId);

      this.supplierItemUnitsOptions = this.supplierItemOptions.find(x => x.itemId === this.editData.itemId)?.unitCodes.map((x: string) => <UnitCodeOption>{ unitCode: x });
      this.itemUnitsOptionsInitialized = true;
    }
  }

  onItemUnitCodeSelectionDataInitialized(): void {
    if (this.formService.editData) {
      this.formService.form.controls.UnitCode.enable();
      this.formService.form.controls.UnitCode.setValue(this.editData.unitCode);
    }
  }

  ctaScenario(): void {
    if (this.formService.form.valid) {
      if (!this.editData) {
        this.add();
      } else {
        this.edit();
      }
    }
  }

  add(): void {
    this.dataService.getByUniqueKey(this.formService.getUniqueKeyModel())
      .subscribe((response: UniqueEntityValidationResponse<ProductSpecificEmissionDto>) => {
        if (response.isValid) {
          let createValues = this.formService.prepareCreateValues();
          this.dataService.add(createValues, this.selectedFile).subscribe(() => {
            this.dialogRef.close(DialogResultStatus.CREATE);
          });
        } else {
          this.dialogService.editExistingRecordDialog(response?.isOverlapping ? this.overlappingDatesErrorMessage : response.errorMessage, response?.isOverlapping)
            .afterClosed()
            .subscribe((res) => {
              if (res === DialogResultStatus.UPDATE) {
                this.pseDialogUserService.getUserTenantCode().subscribe(tenantResponse => {
                  if (response.data.tenantId == tenantResponse.tenantCode) {
                    this.update((response.data as BaseDto).id);
                  } else {
                    // TODO: check if can happen
                    let createValues = this.formService.prepareCreateValues();
                    this.dataService.add(createValues, this.selectedFile).subscribe(() => {
                      this.dialogRef.close(DialogResultStatus.CREATE);
                    });
                  }
                });
              }
              if (res === DialogResultStatus.CANCEL) {
                this.dialogRef.close(DialogResultStatus.CANCEL);
              }
            });
        }
      });
  }

  edit(): void {
    this.pseDialogUserService.getUserTenantCode().subscribe(tenantResponse => {
      this.dataService.getByUniqueKey(this.formService.getUniqueKeyModel())
        .subscribe((response: UniqueEntityValidationResponse<ProductSpecificEmissionDto>) => {
          if (response?.isValid) {
            if (this.editData.tenantId == tenantResponse.tenantCode)
              this.update();
            else
              this.add();
          } else {
            this.dialogService.editExistingRecordDialog(response?.isOverlapping ? this.overlappingDatesErrorMessage : response.errorMessage, response?.isOverlapping)
              .afterClosed()
              .subscribe((result) => {
                if (result === DialogResultStatus.UPDATE) {
                  if (response.data.tenantId == tenantResponse.tenantCode)
                    this.update((response.data as BaseDto).id);
                  else
                    this.add();
                }
                if (result === DialogResultStatus.CANCEL) {
                  this.dialogRef.close(DialogResultStatus.CANCEL);
                }
              });
          }
        });
    });
  }

  update(id?: number): void {
    const updateModel = this.formService.prepareUpdateValues(id);
    this.dataService
      .update(updateModel, this.selectedFile)
      .subscribe(() => {
        this.dialogRef.close(DialogResultStatus.UPDATE);
      });
  }


  uploadDatasource(fileInput: any) {
    this.invalidFileFormat = false;
    const file: File = fileInput.target.files[0];
    if (file) {
      const fileName: string = file.name;
      const fileType: string = fileName.split('.').pop()?.toLowerCase() || '';

      // Check if the selected file type is allowed
      if (!['pdf', 'doc', 'docx', 'odt'].includes(fileType)) {
        this.invalidFileFormat = true;
        console.error('File type not allowed:', fileType);
        return;
      }
    }

    this.selectedFile = fileInput.target.files[0];
    this.selectedFilename = this.selectedFile?.name;
  }

  downloadFile(): void {
    if (!this.selectedFile) {
      // download file after being saved
      this.productSpecificEmissionsService.getMethodDocument(this.editData.id).subscribe(blob => {
        this.selectedFile = new File([blob], this.selectedFilename);
        this.download();
      });
    } else {
      // download file before being saved
      this.download();
    }
  }

  splitTextIntoLines(inputText: string, maxLineLength: number): string[] {
    const lines = inputText.split('\n'); // Split by newline characters
    const result: string[] = [];
    for (const line of lines) {
      if (line.length <= maxLineLength) {
        result.push(line); // Add the line as is
      } else {
        // Split the line into chunks of maxLineLength characters
        for (let i = 0; i < line.length; i += maxLineLength) {
          result.push(line.substring(i, i + maxLineLength));
        }
      }
    }
    return result;
  }

  clearChosenFile() {
    this.fileInput.nativeElement.value = null;
    this.selectedFile = null;
    this.selectedFilename = '';
  }

  async submitForm(): Promise<void> {
    if (await this.validateSupplier() && await this.validateUnspscAndItem()) {
      this.ctaScenario();
    } else {
      this.formService.form.markAllAsTouched();

      this.dialogService.inform(<DialogConfigData>{
        message: this.translateService.instant(
          'error-handling.form-error')
      });
    }
  }

  private download(): void {
    let url = window.URL.createObjectURL(this.selectedFile);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = this.selectedFile.name;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  private validateSupplier(): boolean {
    if (!this.formService.form.controls.SupplierID.value) {
      return true;
    }
    const foundSupplier = (this.supplierOptions as any[]).find(option => option.supplierId === this.formService.form.controls.SupplierID.value);
    if (!foundSupplier) {
      this.formService.form.controls.SupplierCountry.setValue('');
      this.formService.form.controls.SupplierID.setErrors({ error: true });
      return false;
    } else {
      return true;
    }
  }

  private validateUnspscAndItem(): boolean {
    let valid = false;
    const foundItem = (this.supplierItemOptions as any[]).find(option => option.itemId == this.formService.form.controls.ItemID.value);
    if (!foundItem) {
      this.formService.form.controls.ItemID.setErrors({ error: true });
      return false;
    } else {
      valid = true;
    }

    const foundUnit = this.supplierItemUnitsOptions.find(option => option.unitCode === this.formService.form.controls.UnitCode.value);
    if (!foundUnit) {
      this.formService.form.controls.UnitCode.setErrors({ error: true });
      return false;
    } else {
      valid = true;
    }

    return valid;
  }

  ngOnDestroy(): void {
    this.formService.resetEditData();
    this.formService.resetForm();
    this.formService.resetProductDataChange();
  }
}

export class UnitCodeOption {
  unitCode: string;
}