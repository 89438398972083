<div [formGroup]="parentGroup">
    <mat-form-field appearance="fill" style="width: 100%">
        <input type="text" placeholder="{{getSelectedItems()}}" [formControlName]="controlName" aria-label="Number"
            matInput (input)="filterChanged($event.target)" [matAutocomplete]="auto" (blur)="onBlur()"
            [readonly]="disabled">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
            <mat-option style="display: none" disabled></mat-option>
            <!-- Please select an item from below -->
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
                    (click)="nodeClicked(node); onChange()"
                    [ngStyle]="{ 'font-weight' : node.organizationId == selectedNodeValue ? 'bold' : '100'}">
                    <button mat-icon-button disabled></button>
                    {{node.organizationDescription}}
                </mat-tree-node>

                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding (click)="nodeClicked(node)"
                    [ngStyle]="{ 'font-weight' : node.organizationId == selectedNodeValue ? 'bold' : '100'}">

                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    {{node.organizationDescription}}
                </mat-tree-node>
            </mat-tree>
        </mat-autocomplete>
    </mat-form-field>
</div>