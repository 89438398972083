<div class="container">
  <div class="row align-items-center">
    <div class="col-12 mb-5">
      <div class="row">
        <div class="col-12 mt-5">
          <div class="row">
            <div class="col-2">
              <img
                class="mt-3 mb-3"
                id="kmd-logo"
                src="../../../../../assets/images/carbonkey-rgb-v2.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-5">
      <div class="row">
        <div
          id="report-main-nav"
          class="col-4"
          [@showSquareOne]="enterScreen"
          (click)="navigateToReports()"
        >
          <div class="tile-wrapper">
            <div class="top-wrapper">
              <div class="fs-30 fw-bold py-5">
                {{ 'navigation.report' | translate }}
              </div>
              <div class="circle">
                <mat-icon fontIcon="co2" class="fs-80 icon-wrapper"></mat-icon>
              </div>
            </div>
            <div class="description-wrapper fs-17 p-4">
              <div>
                <span [innerHTML]="'reports.home-description' | translate">
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-4"
          (click)="navigateToScenarios()"
          [@showSquareTwo]="enterScreen"
        >
          <div class="tile-wrapper">
            <div class="top-wrapper">
              <div class="fs-30 fw-bold py-5">
                {{ 'navigation.scenarios' | translate }}
              </div>
              <div class="circle">
                <mat-icon
                  class="fs-80 icon-wrapper material-symbols-outlined"
                  fontIcon="lightbulb_outline"
                ></mat-icon>
              </div>
            </div>
            <div class="description-wrapper fs-17 p-4">
              <div>
                <span [innerHTML]="'scenarios.home-description' | translate">
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-4"
          (click)="navigateToInitiatives()"
          [@showSquareThree]="enterScreen"
        >
          <div class="tile-wrapper">
            <div class="top-wrapper">
              <div class="fs-30 fw-bold py-5">
                {{ 'navigation.initiatives' | translate }}
              </div>
              <div class="circle">
                <mat-icon
                  class="fs-80 icon-wrapper"
                  fontIcon="query_stats"
                ></mat-icon>
              </div>
            </div>
            <div class="description-wrapper fs-17 p-4">
              <div>
                <span [innerHTML]="'initiatives.home-description' | translate">
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
