<div class="container">
  <div class="row align-items-center">
    <div class="col-12 mb-5">
      <div class="row">
        <div class="col-12 mt-5">
          <div class="row">
            <div class="col-4">
              <img class="mt-3 mb-3" id="kmd-logo" src="../../../../../assets/images/carbonkey-rgb-v2.svg" />
              <h1 class="col-12 mt-5">
                {{ 'navigation.user-guide' | translate }}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div class="example-container mat-elevation-z8" style="margin-top: 2rem;">
        <mat-table #table [dataSource]="dataSource">

          <ng-container matColumnDef="url">
            <mat-header-cell *matHeaderCellDef> {{ ('user-guide.link' | translate).charAt(0).toUpperCase() + ('user-guide.link' | translate).slice(1) }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a [href]="element.url">{{ element.fileName }}</a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef> {{ 'user-guide.description' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="updateDate">
            <mat-header-cell *matHeaderCellDef> {{ 'user-guide.updatedDate' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.updateDate | date:'MM/dd/yyyy'}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>