import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { AdminService } from '../services/admin.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';

@Injectable()
export class HttpRequestBearerInterceptor implements HttpInterceptor {
  private anonymousEndpoints: string[] = ['/api/systeminfo'];
  constructor(
    private oAuthService: OAuthService,
    private adminService: AdminService,
    private location: Location,
    private route: ActivatedRoute,
    private tenantSelectService: TenantSelectService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // all the requests go through this pipeline

    const isTranslationRequest: boolean =
      request.url == '/assets/i18n/en.json' ||
      request.url == '/assets/i18n/da.json' ||
      request.url == '/assets/i18n/no.json';

    // requests that are not related to translations go further here
    if (!isTranslationRequest) {
      const currentUrlPathname: string = new URL(request.url).pathname;

      // from the requests not related to translations we choose the ones
      // that are api calls and are not hitting anonymous endpoints (which
      // not require authentication)
      if (
        currentUrlPathname.indexOf('/api/') === 0 &&
        this.anonymousEndpoints.indexOf(currentUrlPathname) < 0
      ) {
        const token = this.oAuthService.getAccessToken();

        let customReq = request.clone({
          headers: request.headers.append('Authorization', 'Bearer ' + token),
        });

        if (
          this.tenantSelectService.getCurrentRoute().indexOf('/dashboard') > -1
        ) {
          let idpFromUrl = this.tenantSelectService.getIdpFromUrl();
          let tenant = this.tenantSelectService.tenants.filter(
            (el) => el.identityProviderKey === idpFromUrl
          );
        }

        // this part only is relevant for cross customer viewers
        // but due to the fact UserService is not yet initialized
        // when we reload e.g. scenarios, we could not check the
        // property this.adminService.isCrossCustomerViewer
        if (
          this.adminService.selectedIdentityProvider ||
          this.tenantSelectService.getIdpFromUrl()
        ) {
          if (this.adminService.isCrossCustomerViewer) {
            customReq = customReq.clone({
              headers: customReq.headers.append(
                'X-Selected-Tenant',
                this.adminService.selectedIdentityProvider ||
                  (this.tenantSelectService.getIdpFromUrl() as string)
              ),
            });
          }
        }

        return next.handle(customReq);
      }
    }

    return next.handle(request);
  }
}
