import { Inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { CurrentLanguageService } from 'src/app/services/current-language.service';

@Pipe({
    name: 'customDecimal'
})
@Injectable()
export class CustomDecimalPipe implements PipeTransform {
    databaseDecimalLocation = 'en';
    public decimalPlaces: number = 2;

    constructor(@Inject(CurrentLanguageService) private languageService: CurrentLanguageService){
    }

    transform(value: string): string {
        if (!value) {
            return '';
        }

        // Use toFixed to round the number to the specified decimal places
        const roundedValue = (+value).toFixed(this.decimalPlaces);

        // Add commas for thousands separator if needed
        const parts = roundedValue.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Join the parts back together
        return this.convertToValidPrice(parts.join('.'));
    }

    private convertToValidPrice(price: any): string {
        if (!price) {
            return null;
        }
        if (this.languageService.lang !== this.databaseDecimalLocation) {
            price = price.replace('.', '#');
            price = price.replaceAll(',', '.');
            price = price.replace('#', ',');
            return price;
        }

        return price;
    }
}