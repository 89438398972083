<form [formGroup]="formService.form">
    <div class="container gx-0">
        <div class="row">
            <div class="col-12 mt-3 mb-3">
                <p class="fw-bold fs-30">
                    {{ 'navigation.measuring-point' | translate }}
                </p>
            </div>
        </div>
        <div class="row justify-content-around align-items-stretch mt-3">
            <div>
                <div mat-dialog-content>
                    <div class="row main-row-dialog">
                        <div class="col-12 mt-3">
                            <div class="row">
                                <!-- <div class="col-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'measuring-point.fiscal-year-id' | translate }}</mat-label>
                                        <input formControlName="FiscalYearId" matInput
                                            placeholder="{{ 'measuring-point.fiscal-year-id' | translate }}" />
                                    </mat-form-field>
                                </div> -->
                                <div class="col-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'measuring-point.description' | translate }}</mat-label>
                                        <input formControlName="Description" matInput
                                            placeholder="{{ 'measuring-point.description' | translate }}" />
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'measuring-point.label' | translate }}</mat-label>
                                        <input formControlName="Label" matInput
                                            placeholder="{{ 'measuring-point.label' | translate }}" />
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'measuring-point.value' | translate }}</mat-label>
                                        <input formControlName="Value" matInput
                                            placeholder="{{ 'measuring-point.value' | translate }}" />
                                    </mat-form-field>
                                </div>
                                <!-- <div class="col-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'measuring-point.creation-date' | translate }}</mat-label>
                                        <input formControlName="creationDate" matInput [matDatepicker]="picker1"
                                            placeholder="{{ 'measuring-point.creation-date' | translate }}" />
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'measuring-point.created-by' | translate }}</mat-label>
                                        <input formControlName="createdBy" matInput
                                            placeholder="{{ 'measuring-point.created-by' | translate }}" />
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'measuring-point.modify-date' | translate }}</mat-label>
                                        <input formControlName="modifyDate" matInput [matDatepicker]="picker2"
                                            placeholder="{{ 'measuring-point.modify-date' | translate }}" />
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'measuring-point.modified-by' | translate }}</mat-label>
                                        <input formControlName="modifiedBy" matInput
                                            placeholder="{{ 'measuring-point.modified-by' | translate }}" />
                                    </mat-form-field>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div mat-dialog-actions [align]="'end'">
                    <button mat-raised-button (click)="cancel()" [mat-dialog-close]="false">
                        {{ 'measuring-point.cancel' | translate }}
                    </button>
                    <button mat-raised-button color="primary" [disabled]="formService.form.invalid"
                        (click)="submitForm()">
                        {{ 'measuring-point.save' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>