import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SpinnerService } from '@modules/shell/services/spinner.service';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: false
})
export class SpinnerComponent implements OnInit {
  showSpinner: boolean = false;

  constructor(
    private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.spinnerService.loading.subscribe((result) => {
      this.showSpinner = result;
      this.cdr.detectChanges();
    });
  }
}
