import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Unspsc } from '../../../../commons/models/unspsc';
import { DeepCloneService } from '../../../../commons/services/deep-clone.service';
import { ScenariosCrudHelperService } from '@modules/scenarios/services/scenarios/scenarios-crud-helper.service';
import { UnspscHandlerService } from '../../../../commons/components/unspsc-handler/unspsc-handler.service';
import { ScenariosService } from '@modules/scenarios/services/scenarios/scenarios.service';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { CalculatorService } from '@modules/scenarios/services/calculator/calculator.service';
import { CalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.service';
import { CalculatorTableService } from '@modules/scenarios/services/calculator/calculator-table.service';
import { CalculatorFormService } from '@modules/scenarios/services/calculator/calculator-form.service';

@Component({
  selector: 'app-create-edit-scenario-dialog',
  templateUrl: './create-edit-scenario-dialog.component.html',
  styleUrls: ['./create-edit-scenario-dialog.component.scss'],
})
export class CreateEditScenarioDialogComponent implements AfterViewInit, OnDestroy {
  public years = ['1', '2', '3', '4', '5'];
  public header = '';
  calculationValid = false;
  initialCalculation = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    public fb: FormBuilder,
    private dialogRef: MatDialogRef<any>,
    private deepCloneService: DeepCloneService,
    private translateService: TranslateService,
    public scenariosFormService: CalculatorFormService,
    public calculatorService: CalculatorService,
    public calculatorHelperService: CalculatorHelperService,
    public calculatorTableService: CalculatorTableService,
    private scenarioCrudHelperService: ScenariosCrudHelperService,
    public unspscHandlerService: UnspscHandlerService,
    public scenariosService: ScenariosService,
    public tenantSelectService: TenantSelectService
  ) {
    this.scenariosFormService.setDialogRef(dialogRef);
    this.header = this.translateService.instant('scenarios.create-scenario');
    if (editData) {
      this.scenariosFormService.setEditData(editData);
      this.header = this.translateService.instant('scenarios.update-scenario');

      this.calculatorService.areGraphsShown = true;
    }
    this.scenariosFormService.initializeForm();
  }

  ngAfterViewInit(): void {
    this.calculatorService.graphInit.subscribe(x => {
      if (x.expense.change?.length > 0 || x.emission.change?.length > 0) {
        this.calculationValid = true;
      }

      this.unspscHandlerService.unspscsChange.subscribe(() => {
        this.scenariosFormService.form.controls.unspscs.updateValueAndValidity();
        this.scenariosFormService.form.updateValueAndValidity();
        this.calculationValid = false;
        this.calculatorService.resetSummaryData();
        this.calculatorHelperService.resetYears();
        this.calculatorTableService.resetUnspscTable();
        this.calculatorService.totalEmissionChange = undefined;
        this.calculatorService.totalExpenseChange = undefined;
        this.calculatorService.totalQuantityChange = undefined;
      });
    });
  }

  ngOnDestroy(): void {
    this.unspscHandlerService.resetFilteredViewModels();

    this.calculatorTableService.resetUnspscTable();
    this.calculatorService.resetSummaryData();
    this.calculatorHelperService.resetYears();

    this.scenariosFormService.resetEditData();
    this.scenariosFormService.resetForm();

    this.scenarioCrudHelperService.unsubscribe();
  }

  ctaScenario(): void {
    let unspscs: Unspsc[] = this.scenariosFormService.form
      .getRawValue()
      .unspscs.map((el, i) =>
        <Unspsc>{
          unspscClassID: el.unspscClassID,
          forecastLength: el.forecastLength,
          adjustment: Number(el.adjustment),
          organizationID: el.organizationID,
          trendMethod: Number(el.trendMethod),
          simulationMethod: Number(el.simulationMethod),
          unitMethod: Number(el.unitMethod),
          selected: el.selected,
          formIndex: i
        }
      );

    this.scenariosFormService.form.value.unspscs = unspscs;

    this.scenariosFormService.form.value.calculatorUnspsc = unspscs.map(x => x.unspscClassID);

    if (this.scenariosFormService.form.valid) {
      if (!this.editData) {
        this.addScenarioMaster();
      } else {
        this.updateScenario(this.editData.calculationId, this.editData.id);
      }
    }
  }

  addScenarioMaster() {
    this.scenarioCrudHelperService.addScenarioMaster(
      this.scenariosFormService.form.value, this.unspscHandlerService.selectedUnspscs
    );
  }

  updateScenario(dwsk, id): void {
    this.scenarioCrudHelperService.updateScenario(dwsk, id, this.unspscHandlerService.selectedUnspscs);
  }

  calculate(formValues) {
    if (this.scenariosFormService.form.valid) {
      this.calculatorTableService.resetUnspscTable();
      let unspscArray = this.deepCloneService.deepClone(
        formValues.unspscs
      );

      formValues.calculatorUnspsc = unspscArray.map(
        (el) => el.unspscClassID
      );
      this.calculatorService.areGraphsShown = true;

      formValues = this.calculatorHelperService.getUnspscsFormValues(formValues, this.unspscHandlerService.selectedUnspscs, 'calculatorUnspsc');
      this.calculatorService.calculateAndShow(formValues);
      this.calculatorHelperService.unitMethodDropdownReloaded.next(this.unspscHandlerService.unitMethod);
    }
  }

  cancel() {
    this.calculatorService.areGraphsShown = false;
    this.dialogRef.close('cancel');
  }

  onFormChanged(value: boolean) {
    if (value) {
      this.scenariosFormService.form.controls['description'].markAsTouched();
    }
  }
}
