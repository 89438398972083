<div class="container">
    <div class="row justify-content-between">
        <h1 class="col-12 mt-5">
            {{ 'navigation.product-specific-emissions' | translate }}
        </h1>
        <div class="col-12 mt-5">
            <app-pse-action-menu></app-pse-action-menu>
        </div>
        <div class="col-12 mt-5">
            <nav mat-tab-nav-bar [tabPanel]="tabPanel">
                <a mat-tab-link *ngFor="let link of navLinks" class="disable-link" [routerLink]="link.link"
                    routerLinkActive #rla="routerLinkActive" [active]="link.index === activeLinkIndex">{{link.label |
                    translate}}
                </a>
            </nav>

            <mat-tab-nav-panel #tabPanel>
                <router-outlet></router-outlet>
            </mat-tab-nav-panel>

        </div>
    </div>
</div>