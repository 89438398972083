import { Component, OnInit } from '@angular/core';
import { FaqService } from '../../services/faq.service';
import { Faq } from '../../models/faq';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    standalone: false
})
export class FaqComponent implements OnInit {
  dataSource: MatTableDataSource<Faq>;
  columns = ['question', 'answer'];
  displayedColumns = ['question', 'answer'];
  appDatabaseLanguageCodeMapping = {
    da: 'DK',
    no: 'NO',
    en: 'UK'
  };

  mainText: string;

  constructor(private faqService: FaqService, public translate: TranslateService,
    private currentLanguageService: CurrentLanguageService, private apiService: ApiService, private router: Router) {
    this.mainText = translate.instant('faq.mainText');
  }

  ngOnInit(): void {
    this.loadData();
    this.currentLanguageService.languageChanges.subscribe(() => {
      setTimeout(() => {
        this.mainText = this.translate.instant('faq.mainText');
      }, 1000);

      this.loadData();
    });
  }

  navigate(event: Event) {
    const target = event.target as HTMLAnchorElement;
    if (target.tagName === 'A' && target.getAttribute('href')) {
      event.preventDefault();
      this.router.navigate([target.getAttribute('href')]);
    }
  }

  private loadData(): void {
    this.apiService.getLanguages().subscribe(languages => {
      const foundLanguage = languages.filter(x => x.languageCode == this.appDatabaseLanguageCodeMapping[this.currentLanguageService.lang])[0];
      this.faqService.getFaqs(foundLanguage.id).subscribe(flags => {
        this.dataSource = new MatTableDataSource(flags);
      });
    });
  }
}
