export const ROUTENAMES = {
  HOME: 'home',
  LOGIN: 'login',
  REPORT: 'report',
  FORBIDDEN: 'forbidden',
  UNAUTHORIZED: 'unauthorized',
  ABOUT: 'about',
  ACCOUNTING: 'dashboard',
  PROCESSING: 'data-processing',
  SCENARIOS: 'scenarios',
  TEMPLATES: 'templates',
  INITIATIVES: 'initiatives',
  REPORT_BUILDING: 'report-building',
  FORUM: 'forum',
  CONTACT: 'contact',
  FAQ: 'faq',
  SETTINGS: 'settings',
  NOTFOUND: 'not-found',
  ERROR: 'error-page',
  EMPTYHOME: 'select-tenant',
  USER_GUIDE: 'user-guide',
  CUSTOMERSOWNDATAMAIN: 'cod',
  CUSTOMERSOWNDATAEXCLUSION: 'cod-exclusion',
  CUSTOMERSOWNDATA: 'customers-own-data',
  CUSTOMERSAVERAGEPRICES: 'customers-average-price',
  PSE_IN_USE: 'product-specific-emissions',
  PSE_CAN_BE_USED: 'available',
  PSE_CANDIDATES: 'candidates',
  PSE_THE_REST: 'other',
  CSRD_MEASURING_POINTS: 'customers-csrd',
  CSRD_FISCAL_YEARS: 'fiscal-years'
};
