import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentLanguageService } from '../../../../services/current-language.service';
import { ROUTENAMES } from '../../../../app-routing.names';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { UserService } from '@modules/shell/services/user.service';
import {
  PbiReportService
} from '@modules/report/services/pbi-report.service';
import { PreviousRouteService } from 'src/app/commons/services/previous-route.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('showSquareOne', [
      state('outside', style({ opacity: 0, transform: 'translateX(-150px)' })),
      state('inside', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('outside => inside', [animate('2s ease-in-out')]),
    ]),
    trigger('showSquareTwo', [
      state('outside', style({ opacity: 0, transform: 'translateX(-350px)' })),
      state('inside', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('outside => inside', [animate('1s ease-in-out')]),
    ]),
    trigger('showSquareThree', [
      state('outside', style({ opacity: 0, transform: 'translateX(-550px)' })),
      state('inside', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('outside => inside', [animate('0.5s ease-in-out')]),
    ]),
  ],
})
export class HomeComponent implements OnInit, AfterViewInit {
  radius = 50;
  color = 'rgb(245, 245, 246, 0.2)';
  ROUTENAMES = ROUTENAMES;
  enterScreen: string = 'outside';

  constructor(
    private route: ActivatedRoute,
    private currentLanguageService: CurrentLanguageService,
    private router: Router,
    private tenantSelectService: TenantSelectService,
    private userService: UserService,
    private previousRouteService: PreviousRouteService
  ) {
    this.userService.user$.subscribe((user) => {
      this.tenantSelectService.selectIdpUrlParam();
    });

    setTimeout(() => {
      this.getSquaresOnScreen();
    });
  }

  ngOnInit(): void {
    this.currentLanguageService.setLanguagePriorLogin(
      this.route.snapshot.data.langChosenPriorLogin
    );

    if (this.currentLanguageService.isLanguageChosenPriorLogin()) {
      this.currentLanguageService.switchLang(
        this.currentLanguageService.langChosenPriorLogin.value
      );
      this.currentLanguageService.removeLangFromLocalStorage();
    }
  }

  ngAfterViewInit(): void {
    this.previousRouteService.clearData();
  }

  navigateToReports() {
    this.router.navigate([ROUTENAMES.ACCOUNTING]);
  }

  navigateToInitiatives() {
    this.router.navigate([ROUTENAMES.INITIATIVES]);
  }

  navigateToScenarios() {
    this.router.navigate([ROUTENAMES.SCENARIOS]);
  }

  getSquaresOnScreen() {
    this.enterScreen = 'inside';
  }
}
