import { Component, OnInit } from '@angular/core';
import { OpenIDCService } from '@modules/auth/services/open-idc.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {
  constructor(
    private openIDCService: OpenIDCService
  ) { }

  ngOnInit(): void { }

  login() {
    this.openIDCService.login();
  }
}
