import { Injectable } from '@angular/core';
import * as pbi from 'powerbi-client';
import { PbiFilter } from 'src/app/commons/models/report/pbi-filter.model';

@Injectable({
  providedIn: 'root',
})
export class FilterMappingService {
  constructor() {}

  public mapBasicFilterToPbiFilter(
    basicFilter: pbi.models.IBasicFilterWithKeys
  ) {
    if (basicFilter === null) {
      return null;
    }
    return {
      table: basicFilter.target.table,
      column: this.getPbiFilterColumn(basicFilter.target),
      logicalOperator: 'And',
      conditions: [
        {
          operator: 'In',
          values: basicFilter.values
            ? basicFilter.values.map((v: any) => v)
            : [],
        },
      ],
    } as PbiFilter;
  }

  public mapAdvancedFilterToPbiFilter(
    advancedFilter: pbi.models.IAdvancedFilter
  ) {
    if (advancedFilter === null) {
      return null;
    }
    return {
      table: (advancedFilter.target as pbi.models.IFilterKeyTarget).table,
      column: this.getPbiFilterColumn(
        advancedFilter.target as pbi.models.IFilterKeyTarget
      ),
      logicalOperator: advancedFilter.logicalOperator,
      conditions: advancedFilter.conditions.map((c) => {
        return { operator: c.operator, values: [c.value] };
      }),
    } as PbiFilter;
  }

  public getPbiFilterColumn(target: pbi.models.IFilterKeyTarget): string {
    return (target as pbi.models.IFilterKeyColumnsTarget).column;
  }
}
