import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogConfigData } from 'src/app/commons/models/dialog-config-data';

@Component({
    selector: 'app-new-initiative-dialog',
    templateUrl: './new-initiative-dialog.component.html',
    styleUrls: ['./new-initiative-dialog.component.scss'],
    standalone: false
})
export class NewInitiativeDialogComponent implements OnInit {
  public initiativeForm!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<NewInitiativeDialogComponent>,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfigData
  ) {
  }

  ngOnInit(): void {
    this.initiativeForm = this.formBuilder.group({
      initiativeName: ['', [Validators.required, Validators.pattern(/^[^?&^$#@!()+\;<>’'*%=|]+$|^$/)]],
      initiativeDescription: ['', [Validators.required, Validators.pattern(/^[^?&^$#@!()+\;<>’'*%=|]+$|^$/)]],
    });
  }

  submitForm(): void {
    if (this.initiativeForm.valid) {
      this.dialogRef.close({
        initiativeName: this.initiativeForm.controls.initiativeName.value,
        initiativeDescription: this.initiativeForm.controls.initiativeDescription.value,
        status: 'save'
      });
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}
