<ng-container *ngFor="let item of calculatorTableService[uniqueUnspscChangeView];index as i">
  <!--row 1 headers-->
  <div class="row justify-content-center" *ngIf="i == 0">
    <!--## row 1 upper part-->

    <div class="col-sm-1"></div>
    <div class="col-sm-10">
      <div class="grid-container" [ngStyle]="getColumns()">
        <div class="grid-item grid-item-1 text-center">
          <span class="fw-bold fs-20">
            {{ 'scenarios.graphs.actual-' + headerTranslationKey | translate }}
          </span>
        </div>
        <div class="grid-item grid-item-2 text-center"></div>
        <div class="grid-item grid-item-3 text-center">
          <span class="fw-bold fs-20">{{
            'scenarios.forecast' | translate
            }}</span>
        </div>
      </div>
    </div>
    <div class="col-sm-1"></div>

    <!--## row 1 lower part-->
    <!--blank 0 column-->
    <span class="col-sm"></span>

    <!--blank 1-x historical columns-->
    <ng-container *ngFor="let year of calculatorTableService.historicalColumns">
      <div class="col-sm fw-bold text-center fs-16 pt-4">
        <div class="top-row mb-4 bg-color-history"></div>
        {{ year }}
      </div>
    </ng-container>

    <!--label-->
    <div class="col-sm py-2"></div>

    <!--coloured x+n forecasts column-->
    <ng-container *ngFor="let year of calculatorTableService.forecastColumns">
      <div class="col-sm text-center fw-bold fs-16 pt-4 pb-4">
        <div class="top-row mb-4 bg-color-forecast"></div>
        {{ year }}
      </div>
    </ng-container>

    <!--result column-->
    <span class="col-sm fw-bold fs-16 text-center pt-4">
      <div class="top-row mb-4 bg-color-summary"></div>
      {{ 'scenarios.total' | translate }}
    </span>
  </div>

  <!--TRENDLINE-->
  <!--row 2 and next excluding last one-->
  <div class="row justify-content-center" [ngClass]="{ shadow: i % 2 !== 0 }">
    <div class="col-sm pt-4 pb-2 position-relative">
      {{ item.unspsc }}
      <span class="fs-12 mt-2 position-absolute unspsc-description">
        {{ item.unspscClassDescription }}
      </span>
    </div>

    <!--historical expense columns-->
    <ng-container *ngFor="let historicalItem of item[historicalArrayName] | orderByNumber:'year'">
      <div class="col-sm pt-4 pb-2 text-center">
        {{
        (historicalItem[valueFieldName] | number: '1.0-0') ||
        ('general.no-data' | translate)
        }}
      </div>
    </ng-container>

    <!--label-->
    <div class="col-sm pt-4 pb-2 label">Trend</div>

    <!--forecast expense change columns-->
    <!--here we add trend points-->
    <ng-container *ngIf="item.yearlyTrendPoints?.length">
      <ng-container *ngFor="let data of item.yearlyTrendPoints">
        <div class="col-sm text-center pt-4 pb-2">
          {{
          (data.y | number: '1.0-0') ||
          ('general.no-data' | translate)
          }}
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!item[changeFieldName]?.length">
      <ng-container *ngFor="let year of calculatorTableService.forecastColumns">
        <div class="col-sm text-center pt-4 pb-2">
          {{ 'general.no-data' | translate }}
        </div>
      </ng-container>
    </ng-container>

    <!--expense change sum column EMPTY-->
    <div class="col-sm text-center pt-4 pb-2"></div>
  </div>

  <!--SIMULATION-->
  <!--row 2 and next excluding last one-->
  <div class="row justify-content-center" [ngClass]="{ shadow: i % 2 !== 0 }">
    <div class="col-sm py-2"></div>

    <!--historical expense columns-->
    <ng-container *ngFor="let historicalItem of item[historicalArrayName] | orderByNumber:'year'">
      <div class="col-sm py-2 text-center"></div>
    </ng-container>

    <!--label-->
    <div class="col-sm py-2 label">Simulation</div>

    <!--forecast expense change columns-->
    <!--here we add simulation points-->
    <ng-container *ngIf="item.yearlySimulationPoints?.length">
      <ng-container *ngFor="let data of item.yearlySimulationPoints">
        <div class="col-sm text-center py-2">
          {{
          (data.y | number: '1.0-0') ||
          ('general.no-data' | translate)
          }}
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!item[changeFieldName]?.length">
      <ng-container *ngFor="let year of calculatorTableService.forecastColumns">
        <div class="col-sm text-center py-2">
          {{ 'general.no-data' | translate }}
        </div>
      </ng-container>
    </ng-container>

    <!--expense change sum column EMPTY-->
    <div class="col-sm text-center py-2"></div>
  </div>

  <!--DIFFERENCE-->
  <!--row 2 and next excluding last one-->
  <div class="row justify-content-center" [ngClass]="{ shadow: i % 2 !== 0 }">
    <div class="col-sm py-2"></div>

    <!--historical expense columns-->
    <ng-container *ngFor="let historicalItem of item[historicalArrayName] | orderByNumber:'year'">
      <div class="col-sm py-2 text-center"></div>
    </ng-container>

    <!--label-->
    <div class="col-sm pt-2 pb-4 label">Difference</div>

    <!--forecast expense change columns-->
    <ng-container *ngIf="item[changeFieldName]?.length">
      <ng-container *ngFor="let data of item[changeFieldName]">
        <div class="col-sm text-center pt-2 pb-4">
          {{
          (data.y | number: '1.0-0') ||
          ('general.no-data' | translate)
          }}
          <app-amount-indicator [amount]="data.y"></app-amount-indicator>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!item[changeFieldName]?.length">
      <ng-container *ngFor="let year of calculatorTableService.forecastColumns">
        <div class="col-sm text-center pt-2 pb-4">
          {{ 'general.no-data' | translate }}
        </div>
      </ng-container>
    </ng-container>

    <!--expense change sum column-->
    <div class="col-sm text-center pt-2 pb-4">
      {{
      (item[changeSumFieldName] | number: '1.0-0') ||
      ('general.no-data' | translate)
      }}
      <app-amount-indicator [amount]="item[changeSumFieldName]"></app-amount-indicator>
    </div>
  </div>

  <!--SUMMARY-->
  <!--last row-->
  <div class="row justify-content-center pt-4" *ngIf="
                i ==
                calculatorTableService[uniqueUnspscChangeView].length - 1
              ">
    <div class="col-sm fw-bold fs-16 pb-4">
      {{ 'scenarios.total' | translate }}
    </div>

    <!--empty hitorical expense column-->
    <ng-container *ngFor="let historicalItem of item[historicalArrayName] | orderByNumber:'year'">
      <div class="col-sm fw-bold text-center fs-16"></div>
    </ng-container>

    <!--label-->
    <div class="col-sm py-2"></div>

    <ng-container *ngFor="let year of item[changeFieldName]">
      <div class="col-sm fw-bold text-center" *ngIf="calculatorTableService[yearlyTotalFieldName] && year">
        {{
        (calculatorTableService[yearlyTotalFieldName][year.x]
        | number: '1.0-0') || ('general.no-data' | translate)
        }}

        <app-amount-indicator [amount]="calculatorTableService[yearlyTotalFieldName][year.x]"></app-amount-indicator>
      </div>
    </ng-container>

    <div class="col-sm text-center fw-bold">
      {{
      (calculatorService[totalChangeFieldName]
      | number: '1.0-0') || ('general.no-data' | translate)
      }}
      <app-amount-indicator [amount]="calculatorService[totalChangeFieldName]"></app-amount-indicator>
    </div>
  </div>
</ng-container>