import { Injectable } from '@angular/core';
import * as forms from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@modules/shell/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import {
  OverWriteStatus,
  VerifiedStatus,
} from '../../../commons/models/product-specific-emission-dto';
import { DateTime } from 'luxon';
import { IFormService } from 'src/app/commons/models/form-service.interface';
import { CustomersAveragePriceDto } from 'src/app/commons/models/customers-average-price-dto';
import { CapUniqueKeyModel } from 'src/app/commons/models/cap-unique-key-model';
import { CreateEditCapDialogComponent } from '../components/create-edit-cap-dialog/create-edit-cap-dialog.component';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { BehaviorSubject } from 'rxjs';
import { DecimalValueService } from 'src/app/commons/services/decimal-value.service';

@Injectable({
  providedIn: 'root',
})
export class CustomersAveragePriceFormService implements IFormService {
  form!: forms.FormGroup;
  id: number;
  dialogRef: MatDialogRef<CreateEditCapDialogComponent>;
  isDataChanged: boolean = false;
  isDateChanged: boolean = false;
  editData: any;
  private initialFormValue: forms.ɵTypedOrUntyped<any, forms.ɵFormGroupValue<any>, any>;
  private initialDate: any;
  numRegex: RegExp;
  formInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public fb: forms.FormBuilder,
    public userService: UserService,
    private translateService: TranslateService,
    private decimalValueService: DecimalValueService,
    private currentLanguageService: CurrentLanguageService
  ) {
  }

  setEditData(editData) {
    this.editData = editData;
  }

  resetEditData() {
    this.editData = undefined;
  }

  resetForm() {
    this.form.reset();
  }

  setDialogRef(dialogRef) {
    this.dialogRef = dialogRef;
  }

  initializeForm(): void {
    this.form = this.fb.group({
      SupplierID: [null],
      SupplierCountry: [
        { value: '', visible: false },
      ],
      Unspsc: ['', [forms.Validators.required, forms.Validators.maxLength(8)]],
      UnspscClassId: [],
      AveragePricePerUnitCalc: [
        { value: '', disabled: true },
      ],
      AveragePricePerUnitEntered: ['',
        forms.Validators.pattern(this.currentLanguageService.lang == 'da' ? /^-?\d+(?:.\d{3})*(?:\,\d+)?$/ : /^-?\d+(?:,\d{3})*(?:\.\d+)?$/)],
      Unit: [{ value: '', disabled: !this.editData }, forms.Validators.required],
      CreationDate: [
        { value: new Date(), disabled: true },
        forms.Validators.required,
      ],
      CreatedBy: [
        { value: this.userService.currentUser.email, disabled: true },
        forms.Validators.required,
      ],
      ModifyDate: [{ value: new Date(), disabled: true }, forms.Validators.required],
      ModifiedBy: [
        { value: this.userService.currentUser.email, disabled: true },
        forms.Validators.required,
      ]
    }, { validator: atLeastOne(forms.Validators.required, ['AveragePricePerUnitCalc', 'AveragePricePerUnitEntered']) });

    if (this.editData) {
      this.form.patchValue({
        Id: this.editData.id,
        SupplierID: this.editData.supplierID,
        SupplierCountry: this.editData.supplierCountry,
        Unspsc: this.editData.unspsc,
        AveragePricePerUnitCalc: this.decimalValueService.convertToValidPrice(this.editData.averagePricePerUnitCalc),
        AveragePricePerUnitEntered: this.decimalValueService.convertToValidPrice(this.editData.averagePricePerUnitEntered),
        Unit: this.editData.unit,
      });
    }

    this.initialFormValue = this.form.getRawValue();

    this.formInitialized.next(true);

    this.form.valueChanges.subscribe(() => {
      this.checkProductDataChanges();
    });
  }

  onDateChange(event) {
    let initialDate = DateTime.fromJSDate(this.initialDate).toISODate();
    let eventValue = DateTime.fromJSDate(event.value).toISODate();
    if (initialDate != eventValue) {
      this.isDateChanged = true;
    } else {
      this.isDateChanged = false;
    }
  }

  checkIfProductDataChanged() {
    if (this.isDataChanged) {
      return true;
    }
    return false;
  }

  checkProductDataChanges() {
    const isUnspscChanged = this.isFieldChanged('Unspsc');
    const isSupplierIDChanged = this.isFieldChanged('SupplierID');
    const isUnitChanged = this.isFieldChanged('Unit');

    this.isDataChanged = false;

    if (
      isUnspscChanged ||
      isSupplierIDChanged ||
      isUnitChanged
    ) {
      this.isDataChanged = true;
    }
  }

  isFieldChanged(fieldName: string): boolean {
    const currentValue = this.form.get(fieldName).value;
    const initialValue = this.initialFormValue[fieldName];

    return currentValue !== initialValue;
  }

  disableProductData() {
    this.disableField('TransactionDate');
    this.disableField('ProductID');
    this.disableField('SupplierID');
    this.disableField('SupplierCountry');
    this.disableField('Unit');
  }

  disableField(fieldName: string): void {
    let field = this.form.get(fieldName);
    field.disable();
  }

  getSelectedOverWriteStatus(status) {
    let possibleStatuses: OverWriteStatus[] = [
      {
        flag: true,
        translation: this.translateService.instant(
          'customers-own-data.can-overwrite'
        ),
      },
      {
        flag: false,
        translation: this.translateService.instant(
          'customers-own-data.cannot-overwrite'
        ),
      },
    ];
    let selectedStatus = possibleStatuses.find((el) => el.flag == status);

    return selectedStatus.flag;
  }

  getSelectedVerifiedStatus(status) {
    let possibleStatuses: VerifiedStatus[] = [
      {
        flag: true,
        translation: this.translateService.instant(
          'customers-own-data.can-overwrite'
        ),
      },
      {
        flag: false,
        translation: this.translateService.instant(
          'customers-own-data.cannot-overwrite'
        ),
      },
    ];
    let selectedStatus = possibleStatuses.find((el) => el.flag == status);

    return selectedStatus.flag;
  }

  getSelectedActiveStatus(status) {
    let possibleStatuses: VerifiedStatus[] = [
      {
        flag: true,
        translation: this.translateService.instant(
          'customers-own-data.active'
        ),
      },
      {
        flag: false,
        translation: this.translateService.instant(
          'customers-own-data.inactive'
        ),
      },
    ];
    let selectedStatus = possibleStatuses.find((el) => el.flag == status);

    return selectedStatus.flag;
  }

  resetProductDataChange() {
    this.isDataChanged = false;
    this.isDateChanged = false;
  }

  prepareUpdateValues(id: number, originalValues?: any) {
    if (originalValues) {
      originalValues = originalValues as CustomersAveragePriceDto;
      let baseObject = this.prepareValues();
      baseObject.averagePricePerUnitCalc = originalValues.averagePricePerUnitCalc;

      let toupdate = Object.assign(
        {
          id: this.editData?.id ?? id,
          createdBy: this.form.getRawValue().CreatedBy,
        }, baseObject) as CustomersAveragePriceDto;

      return toupdate;
    } else {
      const baseObject = this.prepareValues();
      return Object.assign(
        {
          id: this.editData?.id ?? id,
          createdBy: this.form.getRawValue().CreatedBy,
        }, baseObject) as CustomersAveragePriceDto;
    }
  }

  prepareCreateValues() {
    const baseObject = this.prepareValues();
    return Object.assign({
      id: 0,
      createdBy: this.form.getRawValue().CreatedBy,
    }, baseObject) as CustomersAveragePriceDto;
  }

  getUniqueKeyModel() {
    const supplierCountry = this.form.getRawValue().SupplierCountry;
    const supplierId = this.form.getRawValue().SupplierID;
    const capunique = <CapUniqueKeyModel>{
      SupplierCountry: supplierCountry.value === '' ? null : supplierCountry,
      SupplierID: !supplierId || supplierId === '' ? null : supplierId,
      Unspsc: this.form.getRawValue().Unspsc,
      Unit: this.form.getRawValue().Unit
    };
    return capunique;
  }

  private prepareValues() {
    const supplierCountry = this.form.getRawValue().SupplierCountry;
    const supplierId = this.form.getRawValue().SupplierID;
    return {
      supplierID: !supplierId || supplierId === '' ? null : supplierId,
      supplierCountry: !supplierCountry || supplierCountry?.value === '' ? null : supplierCountry,
      averagePricePerUnitCalc: this.decimalValueService.convertToValidPrice(this.form.getRawValue().AveragePricePerUnitCalc),
      averagePricePerUnitEntered: this.decimalValueService.convertToValidPrice(this.form.getRawValue().AveragePricePerUnitEntered),
      unspsc: this.form.getRawValue().Unspsc,
      unit: this.form.getRawValue().Unit,
      modifiedBy: this.form.getRawValue().ModifiedBy,
      creationDate: new Date(),
      modifyDate: new Date(),
      useEnteredPrice: true
    }
  }
}

export function dateValidator(): forms.ValidatorFn {
  return (control: forms.AbstractControl): { [key: string]: any } | null => {
    if (!(control && control.value)) {
      return null;
    }

    const today = DateTime.fromJSDate(new Date());
    const dateSet = DateTime.fromJSDate(new Date(control.value));

    return dateSet > today
      ? { invalidDate: 'You cannot use future dates' }
      : null;
  }
}

export const atLeastOne = (validator: forms.ValidatorFn, controls: string[] = null) => (
  group: forms.FormGroup,
): forms.ValidationErrors | null => {
  if (!controls) {
    controls = Object.keys(group.controls)
  }

  const hasAtLeastOne = group && group.controls && controls
    .some(k => !validator(group.controls[k]));

  return hasAtLeastOne ? null : {
    atLeastOne: true,
  };
};