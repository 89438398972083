import { Injectable } from '@angular/core';
import * as forms from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@modules/shell/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { IFormService } from 'src/app/commons/models/form-service.interface';
import { BehaviorSubject } from 'rxjs';
import { DecimalValueService } from 'src/app/commons/services/decimal-value.service';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { FiscalYearDialogComponent } from '@modules/customers-csrd/components/fiscal-year-dialog/fiscal-year-dialog.component';
import { FiscalYear, FiscalYearValidationModel } from 'src/app/commons/models/fiscal-year.model';

@Injectable({
  providedIn: 'root'
})
export class FormService implements IFormService {
  public form!: forms.FormGroup;
  id: number;
  dialogRef: MatDialogRef<FiscalYearDialogComponent>;
  isDataChanged: boolean = false;
  isDateChanged: boolean = false;
  editData: any;
  private initialFormValue: forms.ɵTypedOrUntyped<any, forms.ɵFormGroupValue<any>, any>;
  private initialDate: any;
  formInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public fb: forms.FormBuilder,
    public userService: UserService,
    private translateService: TranslateService,
    private decimalValueService: DecimalValueService,
    private languageService: CurrentLanguageService
  ) { }

  onDateChange(event: any) {
    throw new Error('Method not implemented.');
  }
  checkIfProductDataChanged() {
    throw new Error('Method not implemented.');
  }
  checkProductDataChanges() {
    throw new Error('Method not implemented.');
  }
  disableProductData() {
    throw new Error('Method not implemented.');
  }
  getSelectedOverWriteStatus(status: any) {
    throw new Error('Method not implemented.');
  }
  getSelectedVerifiedStatus(status: any) {
    throw new Error('Method not implemented.');
  }
  getSelectedActiveStatus(status: any) {
    throw new Error('Method not implemented.');
  }
  getUniqueKeyModel(): FiscalYearValidationModel {
    return <FiscalYearValidationModel>{
      id: this.editData?.id ?? 0,
      defaultYear: this.form.getRawValue().DefaultYear,
      description: this.form.getRawValue().Description,
      endDate: this.form.getRawValue().EndDate,
      startDate: this.form.getRawValue().StartDate,
      ignoreOverlapping: false,
      ignoreDefaultExistence: false
    };
  }

  resetProductDataChange() {
    this.isDataChanged = false;
    this.isDateChanged = false;
  }

  setEditData(editData) {
    this.editData = editData;
  }

  resetEditData() {
    this.editData = undefined;
  }

  resetForm() {
    this.form.reset();
  }

  setDialogRef(dialogRef) {
    this.dialogRef = dialogRef;
  }

  initializeForm(): void {
    this.form = this.fb.group({
      StartDate: ['', [forms.Validators.required]],
      EndDate: ['', [forms.Validators.required]],
      Description: ['', forms.Validators.required],
      DefaultYear: [false, forms.Validators.required]
    });

    this.form.get('StartDate').valueChanges.subscribe(startDate => {
      if (startDate) {
        const startDateObj = new Date(startDate);
        const endDate = new Date(startDateObj.getFullYear() + 1, startDateObj.getMonth(), startDateObj.getDate() - 1);
        this.form.get('EndDate').setValue(endDate, { emitEvent: false });
      }
    });
    
    this.form.get('EndDate').valueChanges.subscribe(endDate => {
      if (endDate) {
        const endDateObj = new Date(endDate);
        const startDate = new Date(endDateObj.getFullYear() - 1, endDateObj.getMonth(), endDateObj.getDate() + 1);
        this.form.get('StartDate').setValue(startDate, { emitEvent: false });
      }
    });

    if (this.editData) {
      this.form.patchValue({
        // Id: this.editData.id,
        StartDate: this.editData.startDate,
        EndDate: this.editData.endDate,
        Description: this.editData.description,
        DefaultYear: this.editData.defaultYear,
        // CreationDate: this.editData.creationDate,
        // CreatedBy: this.editData.createdBy,
        // ModifyDate: this.editData.modifyDate,
        // ModifiedBy: this.userService.currentUser.email,
      });
    }

    this.initialFormValue = this.form.getRawValue();

    this.formInitialized.next(true);

    this.form.valueChanges.subscribe(() => {
      this.checkFiscalYearDataChanges();
    });
  }

  checkIfFiscalYearDataChanged() {
    if (this.isDataChanged) {
      return true;
    }
    return false;
  }

  checkFiscalYearDataChanges() {
    const isStartDateChanged = this.isFieldChanged('StartDate');
    const isEndDateChanged = this.isFieldChanged('EndDate');
    const isDescriptionChanged = this.isFieldChanged('Description');
    const isDefaultYearChanged = this.isFieldChanged('DefaultYear');

    this.isDataChanged = false;

    if (
      isStartDateChanged ||
      isEndDateChanged ||
      isDescriptionChanged ||
      isDefaultYearChanged
    ) {
      this.isDataChanged = true;
    }
  }

  isFieldChanged(fieldName: string): boolean {
    const currentValue = this.form.get(fieldName).value;
    const initialValue = this.initialFormValue[fieldName];

    return currentValue !== initialValue;
  }

  prepareUpdateValues(id: number) {
    return {
      startDate: this.form.getRawValue().StartDate,
      endDate: this.form.getRawValue().EndDate,
      description: this.form.getRawValue().Description,
      defaultYear: this.form.getRawValue().DefaultYear,
      creationDate: new Date(),
      createdBy: this.form.getRawValue().CreatedBy,
      modifyDate: new Date(),
      modifiedBy: this.form.getRawValue().ModifiedBy,
      id: this.editData?.id ?? id
    } as FiscalYear;
  }

  prepareCreateValues() {
    return {
      startDate: this.form.getRawValue().StartDate,
      endDate: this.form.getRawValue().EndDate,
      description: this.form.getRawValue().Description,
      defaultYear: this.form.getRawValue().DefaultYear,
      creationDate: new Date(),
      createdBy: this.form.getRawValue().CreatedBy,
      modifyDate: new Date(),
      modifiedBy: this.form.getRawValue().ModifiedBy,
      id: 0
    } as FiscalYear;
  }

  resetFiscalYearDataChange() {
    this.isDataChanged = false;
  }
  private prepareValues() {
    const supplierCountry = this.form.getRawValue().SupplierCountry;
    const supplierId = this.form.getRawValue().SupplierID;
    return {
      supplierID: !supplierId || supplierId === '' ? null : supplierId,
      supplierCountry: !supplierCountry || supplierCountry?.value === '' ? null : supplierCountry,
      averagePricePerUnitCalc: this.decimalValueService.convertToValidPrice(this.form.getRawValue().AveragePricePerUnitCalc),
      averagePricePerUnitEntered: this.decimalValueService.convertToValidPrice(this.form.getRawValue().AveragePricePerUnitEntered),
      unspsc: this.form.getRawValue().Unspsc,
      unit: this.form.getRawValue().Unit,
      modifiedBy: this.form.getRawValue().ModifiedBy,
      creationDate: new Date(),
      modifyDate: new Date(),
      useEnteredPrice: true
    }
  }
}

export function dateValidator(): forms.ValidatorFn {
  return (control: forms.AbstractControl): { [key: string]: any } | null => {
    if (!(control && control.value)) {
      return null;
    }

    const today = DateTime.fromJSDate(new Date());
    const dateSet = DateTime.fromJSDate(new Date(control.value));

    return dateSet > today
      ? { invalidDate: 'You cannot use future dates' }
      : null;
  }
}

export const atLeastOne = (validator: forms.ValidatorFn, controls: string[] = null) => (
  group: forms.FormGroup,
): forms.ValidationErrors | null => {
  if (!controls) {
    controls = Object.keys(group.controls)
  }

  const hasAtLeastOne = group && group.controls && controls
    .some(k => !validator(group.controls[k]));

  return hasAtLeastOne ? null : {
    atLeastOne: true,
  };
};