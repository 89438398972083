import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { DialogService } from '../../../../services/dialog.service';
import { InitiativeDto, InitiativeMasterDto } from '../../../../commons/models/initiative-dto';
import { ApiService } from '../../../../services/api.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { InitiativesService } from '@modules/initiatives/initiatives.service';
import { SnackbarService } from '../../../../commons/services/snackbar.service';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { DK } from '../../../../constants/config.constants';
import { PreviousRouteService } from 'src/app/commons/services/previous-route.service';
import { ScenariosService } from '@modules/scenarios/services/scenarios/scenarios.service';
import { ActivityService } from 'src/app/services/activity.service';
import { ActivityEventType, UserActivity } from 'src/app/commons/models/activity.model';
import { UserService } from '@modules/shell/services/user.service';
import { CurrentLanguageService } from 'src/app/services/current-language.service';

@Component({
    selector: 'app-initiatives',
    templateUrl: './initiatives.component.html',
    styleUrls: ['./initiatives.component.scss'],
    standalone: false
})
export class InitiativesComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public columnsConfigs = [
    {
      key: 'id',
      name: this.translateService.stream('initiatives.columns.id'),
      width: '5%',
    },
    {
      key: 'initiativeName',
      name: this.translateService.stream('initiatives.columns.name'),
      width: '30%',
    },
    {
      key: 'initiativeEmail',
      name: this.translateService.stream('initiatives.columns.email'),
      width: '15%',
    },
    {
      key: 'initiativeCreateDate',
      name: this.translateService.stream('initiatives.columns.create-date'),
      width: '15%',
    },
    {
      key: 'initiativePriority',
      name: this.translateService.stream('initiatives.columns.priority'),
      width: '15%',
    },
    {
      key: 'initiativeStatus',
      name: this.translateService.stream('initiatives.columns.status'),
      width: '15%',
    },
  ];

  maxUnspscsLimit: number = 5;
  private expenseAndEmissionChange: any;

  public dataSource: MatTableDataSource<InitiativeMasterDto>;
  private initativesSubscription?: Subscription;
  private initativesDeleteSubscription?: Subscription;

  constructor(
    private dialogService: DialogService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private initiativesService: InitiativesService,
    private snackbarService: SnackbarService,
    private activityService: ActivityService,
    private userService: UserService,
    public tenantSelectService: TenantSelectService,
    public previousRouteService: PreviousRouteService,
    public scenariosService: ScenariosService,
    public translateService: TranslateService,
    private currentLanguageService: CurrentLanguageService
  ) {
  }

  ngOnInit(): void {
    this.initativesSubscription = this.activatedRoute.data.subscribe(
      (data) => {
        let initiatives = data.initiatives;

        initiatives = this.addTotalExpenseAndEmissionChange(initiatives);
        initiatives = this.generateUnspscGridValues(initiatives);
        this.dataSource = new MatTableDataSource(initiatives);
      },
      (error) => {
        console.log(error);
      }
    );
    this.tenantSelectService.setCountrySpecificTranslations(
      this.tenantSelectService.userIdp == 'oslo-aad-prod' ||
      this.tenantSelectService.tenantHandlerForm.get('tenant').value ==
      'oslo-aad-prod'
    );

    this.tenantSelectService.setLanguageSpecificTranslations(this.currentLanguageService.lang === 'no');
  }

  ngAfterViewInit(): void {
    this.activityService.sendActivity(new UserActivity('Initiatives - view activated', this.userService.currentUser.email ?? this.userService.currentUser.username, ActivityEventType.InitiativesViewActivated));
    this.tenantSelectService.selectIdpUrlParam();
    this.previousRouteService.clearData();
  }

  ngOnDestroy(): void {
    this.initativesSubscription.unsubscribe();

    if (this.initativesDeleteSubscription) {
      this.initativesDeleteSubscription.unsubscribe();
    }
  }

  createInitiativeDialog() {
    this.dialogService
      .createInitiative()
      .afterClosed()
      .subscribe((res) => {
        if (res !== 'cancel') {
          this.initiativesService.getInitiatives().subscribe((initiatives) => {
            initiatives = this.addTotalExpenseAndEmissionChange(initiatives);
            initiatives = this.generateUnspscGridValues(initiatives);
            this.dataSource = new MatTableDataSource(
              initiatives as InitiativeMasterDto[]
            );
            this.snackbarService.openSnackBar('initiatives.initiative-created');
          });
        }
      });
  }

  editInitiative(row) {
    this.dialogService
      .editInitiative(row)
      .afterClosed()
      .subscribe((res) => {
        if (res === 'update') {
          this.initiativesService.getInitiatives().subscribe((initiatives) => {
            initiatives = this.addTotalExpenseAndEmissionChange(initiatives);
            initiatives = this.generateUnspscGridValues(initiatives);
            this.dataSource = new MatTableDataSource(
              initiatives as InitiativeMasterDto[]
            );
            this.snackbarService.openSnackBar('initiatives.initiative-updated');
          });
        }
      });
  }

  deleteInitiative({ id }) {
    this.dialogService
      .deleteDialog({
        message: this.translateService.instant('initiatives.confirmatory-text'),
        confirmText: this.translateService.instant('initiatives.yes'),
        cancelText: this.translateService.instant('initiatives.no'),
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === 'confirm') {
          this.initativesDeleteSubscription = this.apiService
            .deleteInitiative(id)
            .subscribe(
              () => { },
              () => { },
              () => {
                this.initiativesService
                  .getInitiatives()
                  .subscribe((initiatives) => {
                    initiatives = this.addTotalExpenseAndEmissionChange(initiatives);
                    initiatives = this.generateUnspscGridValues(initiatives);
                    this.dataSource = new MatTableDataSource(
                      initiatives as InitiativeMasterDto[]
                    );
                    this.snackbarService.openSnackBar(
                      'initiatives.initiative-deleted'
                    );
                  });
              }
            );
        }
      });
  }

  public getGridUnspscs(unspscsGrid: any, unspscIndex: number) {
    return unspscsGrid?.filter(x => x.index == unspscIndex);
  }

  protected readonly DK = DK;

  addTotalExpenseAndEmissionChange(initiatives) {
    this.expenseAndEmissionChange =
      this.calculateChanges(initiatives);
    initiatives.map((scenario) => {
      Object.assign(scenario, {
        totalLineAmountChange: this.expenseAndEmissionChange.find(
          (el) => scenario.id == el.id
        ).expenseChange,
      });
      Object.assign(scenario, {
        totalEmissionChange: this.expenseAndEmissionChange.find(
          (el) => scenario.id == el.id
        ).emissionChange,
      });
    });

    return initiatives;
  }

  isReadonlyUser(): boolean {
    return this.userService.currentUser.permissions.customerReadOnlyUser;
  }

  private calculateChanges(initiatives: InitiativeDto[]) {
    let calculationsByInitiative: {
      id: number;
      emissionChange: number;
      expenseChange: number;
    }[] = [];
    initiatives.map((initiative) => {
      let emissionChange: number = 0,
        expenseChange: number = 0;

      if (initiative.initiativeCalculations) {
        initiative.initiativeCalculations.map((yearCalc) => {
          emissionChange = emissionChange + yearCalc.emissionChange;

          expenseChange = expenseChange + yearCalc.lineAmountChange;
        });
      }

      calculationsByInitiative.push({
        id: initiative.id,
        emissionChange: Math.round(emissionChange),
        expenseChange: Math.round(expenseChange),
      });
    });

    return calculationsByInitiative;
  }

  private generateUnspscGridValues(initiatives: any): any {
    initiatives.forEach((x, i) => {
      let initiativesToDisplay = [];
      x.index = x.id;
      x.unspscs
        .forEach((y, mainIndex) => {
          if (y.unspscClassId?.includes(',')) {
            const descriptions = y.unspscClassDescription.split(';');
            initiativesToDisplay.push(...y.unspscClassId.split(',').map((unspscClassId, i) => <any>{
              unspscClassId: unspscClassId,
              unspscClassDescription: descriptions[i],
              index: mainIndex
            }))
          } else {
            initiativesToDisplay.push(<any>{
              unspscClassId: y?.unspscClassId ?? '',
              unspscClassDescription: y?.unspscClassDescription ?? '',
              index: mainIndex
            });
          }
        });

      x.unspscsGrid = initiativesToDisplay;
    });

    return initiatives;
  }
}
