import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import {
  PbiReportService,
} from '@modules/report/services/pbi-report.service';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import * as pbi from 'powerbi-client';
import { Location } from '@angular/common';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { PbiBookmarkService } from '@modules/report/services/pbi-bookmark.service';
import { GetParamsService } from '../../../../services/get-params.service';
import { PreviousRouteService } from 'src/app/commons/services/previous-route.service';
import { PowerBiReport } from '@modules/report/services/power-bi-report';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { ActivityService } from 'src/app/services/activity.service';
import { ActivityEventType, UserActivity } from 'src/app/commons/models/activity.model';
import { UserService } from '@modules/shell/services/user.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements AfterViewInit, OnDestroy {
  @ViewChild(PowerBIReportEmbedComponent)
  reportObj!: PowerBIReportEmbedComponent;

  eventHandlers;

  private reportLoaded = false;

  activePageFilters: string;
  filters: string;

  isInitialLoad = true;

  constructor(
    public reportService: PbiReportService,
    private location: Location,
    private tenantSelectService: TenantSelectService,
    private bookmarkService: PbiBookmarkService,
    private getParamsService: GetParamsService,
    private previousRouteService: PreviousRouteService,
    private powerBiReportObj: PowerBiReport,
    private currentLanguageService: CurrentLanguageService,
    private activityService: ActivityService,
    private userService: UserService
  ) {
  }

  ngAfterViewInit(): void {
    this.bookmarkService.initializeQueryParamsSubscription();
    this.eventHandlers = new Map<string, string>();

    const queryParams = this.previousRouteService.getCurrentUrlParams();

    const currentUrl = window.location.href;
    const currentQueryParams = this.getParamsService.getParamsFromUrl(currentUrl);

    this.eventHandlers.set('loaded', () => {
      let activityEventType = ActivityEventType.Unknown;
      switch (this.reportService.description) {
        case 'Regnskab': activityEventType = ActivityEventType.RegnskabDataSelected; break;
        case 'GHG rapport': activityEventType = ActivityEventType.GhgReportLoaded; break;
        case 'Fokus': activityEventType = ActivityEventType.FokusReportReportLoaded; break;
        case 'Admin Report': activityEventType = ActivityEventType.AdminReportReportLoaded; break;
      };
      this.logEvent('report loaded', activityEventType);
      this.setPowerBiReport(this.reportObj.getReport());
      if (queryParams?.hasOwnProperty('reportId')) {
        this.reportService.report.bookmarksManager.applyState(queryParams['pbiState']);
      } else if (currentQueryParams?.hasOwnProperty('reportId')) {
        this.reportService.report.bookmarksManager.applyState(currentQueryParams['pbiState']);
      }

      this.previousRouteService.clearData();
    });

    this.eventHandlers.set('rendered', async () => {
      if (
        this.reportService.report &&
        this.reportService.report.iframe &&
        this.reportService.report.iframe.contentWindow
      ) {
        let activityEventType = ActivityEventType.Unknown;
        switch (this.reportService.description) {
          case 'Regnskab': activityEventType = ActivityEventType.RegnskabReportRendered; break;
          case 'GHG rapport': activityEventType = ActivityEventType.GhgReportRendered; break;
          case 'Fokus': activityEventType = ActivityEventType.FokusReportReportRendered; break;
          case 'Admin Report': activityEventType = ActivityEventType.AdminReportReportRendered; break;
        };
        this.logEvent('report rendered', activityEventType);

        await this.bookmarkService
          .getPbiState(this.reportService.report)
          .then((pbiState) => {
            this.reportService.currentPbiState = pbiState;
            this.addPbiStateToUrl(pbiState);
          });
      }
    });

    this.eventHandlers.set('dataSelected', () => {
      let activityEventType = ActivityEventType.Unknown;
      switch (this.reportService.description) {
        case 'Regnskab': activityEventType = ActivityEventType.RegnskabDataSelected; break;
        case 'GHG rapport': activityEventType = ActivityEventType.GhgReportDataSelected; break;
        case 'Fokus': activityEventType = ActivityEventType.FokusReportReportRendered; break;
        case 'Admin Report': activityEventType = ActivityEventType.AdminReportReportRendered; break;
      };
      this.logEvent('data selected', activityEventType);
    });

    this.currentLanguageService.languageChanges.subscribe((lang) => {
      if (lang?.length > 0) {
        const report = this.reportObj.getReport();
        report.iframe.src = this.updateUrlParameter(report.config.embedUrl, 'language', lang);
        report.reload().then(() => { });
      }
    });
  }

  addPbiStateToUrl(pbiState: string) {
    let urlWithState = this.bookmarkService.getPbiConfig(
      pbiState,
      this.tenantSelectService.idp ||
      this.tenantSelectService.tenantHandlerForm.get('tenant').value ||
      null
    );
    this.location.go(urlWithState);
  }

  setPowerBiReport(powerBiReport: pbi.Report) {
    this.reportService.report = powerBiReport;
    this.reportService.reportInitialized.next(false);

    this.reportLoaded = true;

    this.reportService.report.on('rendered', async (e) => {
      if (this.reportLoaded) {
        if (this.reportService.report && this.reportService.report.iframe && this.reportService.report.iframe.contentWindow) {
          await this.powerBiReportObj.setPowerBiToFilter(this.reportService.report);

          this.reportService.reportInitialized.next(true);
        }
      }
    });
  }

  private updateUrlParameter(url: string, paramName: string, paramValue: string): string {
    const urlObj = new URL(url);
    urlObj.searchParams.set(paramName, paramValue);
    return urlObj.toString();
  }

  private logEvent(eventName: string, activityEventType: ActivityEventType) {
    const userId = this.userService.currentUser.username;
    const activity = new UserActivity(`${this.reportService.description} - ${eventName}`, userId, activityEventType);
    this.activityService.sendActivity(activity);
  }

  ngOnDestroy(): void {
    clearTimeout(this.reportService.currentTimeout);
  }
}
