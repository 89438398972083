import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { HomeComponent } from './components/home/home.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { EmptyHomeScreenComponent } from './components/empty-home-screen/empty-home-screen.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [HomeComponent, EmptyHomeScreenComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatRippleModule,
    MatButtonModule,
  ],
})
export class HomeModule {}
