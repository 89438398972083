import { Inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'emptyItemId'
})
@Injectable()
export class EmptyItemIdPipe implements PipeTransform {
    databaseDecimalLocation = 'en';

    constructor(@Inject(TranslateService) private translateService: TranslateService) {
    }

    transform(value: string): string {
        if (!value || value == '' || value == 'Ukendt') {
            return this.translateService.instant('product-specific-emissions.item-unknown');
        }

        return value;
    }
}