import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '../../../../services/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InitiativesService } from '@modules/initiatives/initiatives.service';
import { InitiativesFormService } from '@modules/initiatives/initiatives-form.service';
import { UnspscHandlerService } from '../../../../commons/components/unspsc-handler/unspsc-handler.service';
import { InitiativesCrudHelperService } from '@modules/initiatives/initiatives-crud-helper.service';
import { ScenariosCrudHelperService } from '@modules/scenarios/services/scenarios/scenarios-crud-helper.service';
import { ScenariosService } from '@modules/scenarios/services/scenarios/scenarios.service';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { DeepCloneService } from 'src/app/commons/services/deep-clone.service';
import { CalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.service';
import { CalculatorTableService } from '@modules/scenarios/services/calculator/calculator-table.service';
import { CalculatorService } from '@modules/scenarios/services/calculator/calculator.service';

@Component({
  selector: 'app-create-edit-initiative-dialog',
  templateUrl: './create-edit-initiative-dialog.component.html',
  styleUrls: ['./create-edit-initiative-dialog.component.scss'],
})
export class CreateEditInitiativeDialogComponent implements OnInit, OnDestroy {
  public header: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    public formBuilder: FormBuilder,
    private apiService: ApiService,
    private dialogRef: MatDialogRef<CreateEditInitiativeDialogComponent>,
    private translateService: TranslateService,
    public initiativesService: InitiativesService,
    public initiativesFormService: InitiativesFormService,
    private initiativesCrudHelperService: InitiativesCrudHelperService,
    public calculatorService: CalculatorService,
    public calculatorHelperService: CalculatorHelperService,
    public calculatorTableService: CalculatorTableService,
    private scenarioCrudHelperService: ScenariosCrudHelperService,
    public unspscHandlerService: UnspscHandlerService,
    public scenariosService: ScenariosService,
    public tenantSelectService: TenantSelectService,
    private deepCloneService: DeepCloneService
  ) { }

  ngOnInit(): void {
    this.initiativesService.getMiscInitiativeData();
    this.initiativesFormService.setDialogRef(this.dialogRef);
    this.header = this.translateService.instant(
      'initiatives.create-initiative'
    );

    if (this.editData) {
      this.initiativesFormService.setEditData(this.editData);
      this.header = this.translateService.instant(
        'initiatives.update-initiative'
      );
    }

    this.initiativesFormService.initializeForm();
    this.calculatorService.areGraphsShown = true;
    this.calculate(this.initiativesFormService.form.value);
  }

  ngOnDestroy(): void {
    this.unspscHandlerService.resetFilteredViewModels();

    this.initiativesFormService.resetEditData();
    this.initiativesFormService.resetForm();

    this.initiativesCrudHelperService.unsubscribe();
  }

  ctaInitiative(): void {
    if (this.initiativesFormService.form.valid) {
      this.initiativesFormService.setLastYear(
        this.unspscHandlerService.unspscsLastYear
      );

      if (!this.editData) {
        this.addInitiativeMaster(
          this.initiativesFormService.form.getRawValue()
        );
      } else {
        this.updateInitiativeMaster(
          this.editData.id,
          this.initiativesFormService.form.getRawValue()
        );
      }
    }
  }

  addInitiativeMaster(initiativeFormValues): void {
    this.initiativesCrudHelperService.addInitiativeMaster(initiativeFormValues);
  }

  updateInitiativeMaster(dwsk, initiativeFormValues): void {
    this.initiativesCrudHelperService.updateInitiativeMaster(
      dwsk,
      initiativeFormValues
    );
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  calculate(formValues) {
    if (formValues?.unspscs && this.initiativesFormService.form.valid) {
      this.calculatorTableService.resetUnspscTable();
      let unspscArray = this.deepCloneService.deepClone(
        formValues?.unspscs
      );

      formValues.calculatorUnspsc = unspscArray.map(
        (el) => el.unspscClassID
      );
      this.calculatorService.areGraphsShown = true;
      this.calculatorService.calculateAndShow(formValues);
    }
  }
}
