import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { DimaTableLibraryModule } from '@dima/table-library';
import { CapActionMenuComponent } from './components/cap-action-menu/cap-action-menu.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    DimaTableLibraryModule,
    MatFormFieldModule,
    MatIconModule,
  ],
  declarations: [
    CapActionMenuComponent
  ]
})
export class CustomersAveragePriceModule { }
