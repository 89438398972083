import { Inject, Injectable } from '@angular/core';
import {
  CalculateDto,
  CalculateMasterDto,
  CalculateWorkDto,
} from '../../../../commons/models/scenario-dto';
import { Observable, Subscription } from 'rxjs';
import { HistoricalDataDto, HistoricalDataResponseDto } from '../../../../commons/models/historical-data-dto';
import { map } from 'rxjs/operators';
import { UserService } from '@modules/shell/services/user.service';
import { CalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.service';
import { DeepCloneService } from '../../../../commons/services/deep-clone.service';
import { GraphXYDTO } from '../../../../commons/models/graph-xy-dto';
import { CalculatedGraphData, GraphData } from '../../../../commons/models/calculated-graph.data';
import { ApiService } from '../../../../services/api.service';
import { ICalculatorPrepareDataService } from '@modules/scenarios/services/calculator/calculator-prepare-data.interface';
import { Unspsc, UnspscCalculatorServiceDto } from '../../../../commons/models/unspsc';
import { SearchTrendPointModelDto, TrendRequestModel } from 'src/app/commons/models/search-model-dto';
import { CalculatorFormService } from './calculator-form.service';
import { UnspscHandlerService } from 'src/app/commons/components/unspsc-handler/unspsc-handler.service';

enum CHANGE {
  EXPENSE = 'EXPENSE',
  EMISSION = 'EMISSION',
  QUANTITY = 'QUANTITY'
}

export interface SimulationPointDto extends GraphXYDTO { }
export interface TrendpointDto extends GraphXYDTO { }

interface CalculationConfig {
  adjustment: number;
  trendMethod: number;
  simulationMethod: number;
  unitMethod: number;
  adjustmentProportionPercentage?: number;
}

@Injectable({
  providedIn: 'root',
})
export class CalculatorPrepareDataService implements ICalculatorPrepareDataService {
  constructor(
    public calculatorHelperService: CalculatorHelperService,
    public userService: UserService,
    public deepCloneService: DeepCloneService,
    public apiService: ApiService,
    public formService: CalculatorFormService,
    private unspscHandlerService: UnspscHandlerService
  ) {
  }

  calculateConstantTrendPoints(historicalData: any[], forecastLength: number) {
    let constantLine = [];

    let lastHistoricalYear = Math.max(
      ...this.calculatorHelperService.getYearsOutOfGraphXYDto(
        historicalData
      )
    );

    let lastYearResult = historicalData.find(
      (el) => el.x == lastHistoricalYear
    ).y;

    for (let i = 0; i < historicalData.length; i++) {
      constantLine.push({ x: historicalData[i].x, y: historicalData[i].y });
    }

    for (let i = 0; i < forecastLength; i++) {
      constantLine.push({ x: new Date().getFullYear() + i, y: lastYearResult });
    }

    return constantLine;
  }

  calculateTrendPoints(data: any[], forecastLength: number): TrendpointDto[] {
    let trendLineBase = this.deepCloneService.deepClone(data);
    let lastYear = trendLineBase[trendLineBase.length - 1].x;

    let paramA: number;
    let paramB: number;
    let nParam: number = trendLineBase.length;
    let sumX: number;
    let sumY: number;
    let sumXY: number;
    let sumXX: number;

    let sumValues = (reducerFunction, base) => base.reduce(reducerFunction, 0);
    let reducerXSum = (acc, red) => acc + red.x;
    let reducerYSum = (acc, red) => acc + red.y;
    let reducerYXSum = (acc, red) => acc + red.x * red.y;
    let reducerXXSum = (acc, red) => acc + red.x * red.x;

    sumX = sumValues(reducerXSum, trendLineBase);
    sumY = sumValues(reducerYSum, trendLineBase);
    sumXY = sumValues(reducerYXSum, trendLineBase);
    sumXX = sumValues(reducerXXSum, trendLineBase);
    paramB =
      (nParam * sumXY - sumX * sumY) / (nParam * sumXX - Math.pow(sumX, 2));
    paramA = sumY / nParam - (paramB * sumX) / nParam;

    if (forecastLength) {
      trendLineBase.sort((a, b) => a.x - b.x);

      for (let i = 1; i <= forecastLength; i++) {
        trendLineBase.push({
          x: lastYear + i,
          y: paramA + paramB * (lastYear + i),
        });
      }
    }

    return trendLineBase.map((el) => {
      return {
        x: el.x,
        y: paramA + paramB * el.x >= 0 ? paramA + paramB * el.x : 0,
      };
    });
  }

  calculateSimulationPoints(
    historicalPointsXY,
    quantityHistoricalPointsXY,
    calculationConfig,
    maximumForecastLength,
    forecastLength,
    trendPoints,
    simulationFor
  ): SimulationPointDto[] {
    let simulation = this.deepCloneService.deepClone(historicalPointsXY);

    simulation.sort((a, b) => a.x - b.x);

    if (forecastLength) {
      let initialLength = simulation.length;
      let index: number;
      let lastYear = simulation[initialLength - 1].x;
      let nextYear;

      for (let i = 1; i <= forecastLength; i++) {
        nextYear = lastYear + i;
        index = initialLength + i - 2;

        if (calculationConfig.simulationMethod == 1) { // percent
          if (calculationConfig.unitMethod == '2') { // unit-base calculation
            const quantityReduction = calculationConfig.adjustment / 100 * (quantityHistoricalPointsXY[index]?.y ?? simulation[index]?.y);
            const calculated = simulation[index].y + (quantityReduction / (quantityHistoricalPointsXY[index]?.y ?? simulation[index]?.y) * simulation[index].y);

            simulation.push({
              x: nextYear,
              y: calculated >= 0 ? calculated : 0,
            });
          } else {
            simulation.push({
              x: nextYear,
              y:
                simulation[index].y *
                  (1 + Number(calculationConfig.adjustment) / 100) >=
                  0
                  ? simulation[index].y *
                  (1 + Number(calculationConfig.adjustment) / 100)
                  : 0,
            });
          }
        } else { // amount
          const proportionalAmount = (calculationConfig.adjustmentProportionPercentage == 0 || !calculationConfig.adjustmentProportionPercentage) ? 0 : calculationConfig.adjustmentProportionPercentage ? (Number(calculationConfig.adjustmentProportionPercentage)) / 100 * Number(calculationConfig.adjustment)
            : calculationConfig.adjustment;

          simulation.push({
            x: nextYear,
            y:
              simulation[index].y + Number(proportionalAmount) >= 0
                ? simulation[index].y + Number(proportionalAmount)
                : 0,
          });
        }
      }

      if (forecastLength < maximumForecastLength) {
        for (let i = forecastLength + 1; i <= maximumForecastLength; i++) {
          simulation.push({
            x: lastYear + i,
            y: trendPoints[i + 1].y,
          });
        }
      }
    }

    return simulation;
  }

  calculateEmissionSimulation(
    expenseSimulationData,
    historicalEmissionFactorData,
    emissionHistoricalPoints,
    quantityHistoricalPointsXY,
    maximumForecastLength,
    forecastLength,
    emissionTrendPoints,
    calculationConfig
  ) {
    let emissionSimulation = [];
    if (calculationConfig.unitMethod == '2' && calculationConfig.simulationMethod == 2) { // unit-base amount calculation

      let simulation = this.deepCloneService.deepClone(emissionHistoricalPoints);
      let trendPointsCopy = this.deepCloneService.deepClone(emissionTrendPoints);

      simulation.sort((a, b) => a.x - b.x);

      let lastYearIndex = emissionHistoricalPoints.length - 1;

      if (forecastLength) {
        let initialLength = emissionHistoricalPoints.length;
        let index: number;
        let lastYear = simulation[initialLength - 1].x;
        let nextYear;

        for (let i = 1; i <= forecastLength; i++) {
          nextYear = lastYear + i;
          index = initialLength + i - 2;

          const proportionalQuantity = (calculationConfig.adjustmentProportionPercentage == 0 || !calculationConfig.adjustmentProportionPercentage) ? 0 : calculationConfig.adjustmentProportionPercentage ? (Number(calculationConfig.adjustmentProportionPercentage)) / 100 * Number(calculationConfig.adjustment)
            : calculationConfig.adjustment;

          lastYearIndex = lastYearIndex + 1;
          const totalEmissionFromLastYear = emissionHistoricalPoints[emissionHistoricalPoints.length - 1]?.y;
          const totalUnitsFromLastYear = quantityHistoricalPointsXY[emissionHistoricalPoints.length - 1]?.y;
          const averageUnitEmissionFromLastYear = totalEmissionFromLastYear / totalUnitsFromLastYear;

          const unitsToReduce = proportionalQuantity * i * averageUnitEmissionFromLastYear;

          const calculated = trendPointsCopy[lastYearIndex].y + unitsToReduce;

          simulation.push({
            x: nextYear,
            y: calculated >= 0 ? calculated : 0,
          });
        }

        // fill up the gap if multiple forecast lengths are selected
        if (maximumForecastLength > forecastLength) {
          for (let i = 0; i <= maximumForecastLength - forecastLength; i++) {
            nextYear = lastYear + i;
            simulation.push({
              x: nextYear,
              y: '',
            });
          }
        }
      }

      return simulation;
    } else {
      emissionSimulation = expenseSimulationData.map((el) => {
        let emissionSimulationPoint =
          Number(el.y) *
            Number(
              historicalEmissionFactorData.find((elem) => elem.x == el.x).y
            ) >=
            0
            ? Number(el.y) *
            Number(
              historicalEmissionFactorData.find((elem) => elem.x == el.x).y
            )
            : 0;
        return {
          x: el.x,
          y: emissionSimulationPoint,
        };
      });

      return emissionSimulation;
    }
  }

  calculateQuantitySimulation(historicalPointsXY,
    quantityHistoricalPointsXY,
    calculationConfig,
    maximumForecastLength,
    forecastLength,
    trendPoints,
    simulationFor
  ): SimulationPointDto[] {
    let simulation = this.deepCloneService.deepClone(simulationFor == 'quantity' ? quantityHistoricalPointsXY : historicalPointsXY);
    let trendPointsCopy = this.deepCloneService.deepClone(trendPoints);

    simulation.sort((a, b) => a.x - b.x);

    if (forecastLength) {
      let initialLength = historicalPointsXY.length;
      let index: number;
      let lastYear = simulation[initialLength - 1].x;
      let nextYear;

      let lastYearIndex = historicalPointsXY.length - 1;

      for (let i = 1; i <= forecastLength; i++) {
        nextYear = lastYear + i;
        index = initialLength + i - 2;

        if (calculationConfig.simulationMethod == 1) { // percent
          if (calculationConfig.unitMethod == '2') { // unit-base calculation
            const quantityReduction = calculationConfig.adjustment / 100 * (simulation[index]?.y);
            const calculated = simulation[index]?.y + quantityReduction;

            simulation.push({
              x: nextYear,
              y: calculated >= 0 ? calculated : 0,
            });
          } else {
            simulation.push({
              x: nextYear,
              y:
                simulation[index].y *
                  (1 + Number(calculationConfig.adjustment) / 100) >=
                  0
                  ? simulation[index].y *
                  (1 + Number(calculationConfig.adjustment) / 100)
                  : 0,
            });
          }
        } else { // amount
          if (calculationConfig.unitMethod == '2') { // unit-base calculation

            if (simulationFor == 'quantity') {
              const proportionalQuantity = (calculationConfig.adjustmentProportionPercentage == 0 || !calculationConfig.adjustmentProportionPercentage) ? 0 : calculationConfig.adjustmentProportionPercentage ? (Number(calculationConfig.adjustmentProportionPercentage)) / 100 * Number(calculationConfig.adjustment)
                : calculationConfig.adjustment;

              lastYearIndex = lastYearIndex + 1;

              const unitsToReduce = proportionalQuantity * i;

              const calculated = trendPointsCopy[lastYearIndex].y + unitsToReduce;

              simulation.push({
                x: nextYear,
                y: calculated >= 0 ? calculated : 0,
              });
            } else { // expense
              const proportionalQuantity = (calculationConfig.adjustmentProportionPercentage == 0 || !calculationConfig.adjustmentProportionPercentage) ? 0 : calculationConfig.adjustmentProportionPercentage ? (Number(calculationConfig.adjustmentProportionPercentage)) / 100 * Number(calculationConfig.adjustment)
                : calculationConfig.adjustment;

              const lastYearIndex = historicalPointsXY.length - 1;
              const totalExpenseFromLastYear = historicalPointsXY[lastYearIndex]?.y;
              const totalUnitsFromLastYear = quantityHistoricalPointsXY[lastYearIndex]?.y;
              const averageUnitPriceFromLastYear = totalExpenseFromLastYear / totalUnitsFromLastYear;

              const unitsToReduce = proportionalQuantity * i * averageUnitPriceFromLastYear;

              const calculated = trendPointsCopy[lastYearIndex].y + unitsToReduce;

              simulation.push({
                x: nextYear,
                y: calculated >= 0 ? calculated : 0,
              });
            }
          } else {
            const proportionalAmount = (calculationConfig.adjustmentProportionPercentage == 0 || !calculationConfig.adjustmentProportionPercentage) ? 0 : calculationConfig.adjustmentProportionPercentage ? (Number(calculationConfig.adjustmentProportionPercentage)) / 100 * Number(calculationConfig.adjustment)
              : calculationConfig.adjustment;

            simulation.push({
              x: nextYear,
              y:
                simulation[index].y + Number(proportionalAmount) >= 0
                  ? simulation[index].y + Number(proportionalAmount)
                  : 0,
            });
          }
        }
      }

      if (forecastLength < maximumForecastLength) {
        for (let i = forecastLength + 1; i <= maximumForecastLength; i++) {
          simulation.push({
            x: lastYear + i,
            y: trendPoints[i + 1].y,
          });
        }
      }
    }

    return simulation;
  }

  calculateEmissionTrend(expenseTrendData, historicalEmissionFactorData) {
    let emissionTrend = expenseTrendData.map((el) => {
      let emissionTrendData =
        Number(el.y) *
          Number(
            historicalEmissionFactorData.find((elem) => elem.x == el.x).y
          ) >=
          0
          ? Number(el.y) *
          Number(
            historicalEmissionFactorData.find((elem) => elem.x == el.x).y
          )
          : 0;

      return {
        x: el.x,
        y: emissionTrendData,
      };
    });

    return emissionTrend;
  }

  prepareMasterData(formValues): CalculateMasterDto {
    return {
      name: formValues.name,
      description: formValues.description,
      email: this.userService.currentUser.email,
    };
  }

  prepareWorkData(formValues, id, selectedUnspscs: Unspsc[][]): CalculateWorkDto[] {
    let workDataArray: CalculateWorkDto[] = [];

    for (let i = 0; i < formValues.unspscs.length; i++) {
      workDataArray.push({
        Adjustment: formValues.unspscs[i].adjustment,
        Year: formValues.unspscs[i].forecastLength,
        UnspscClassID: selectedUnspscs[i].map(x => x.unspscClassID).join(','),
        calculationId: id,
        OrganizationID: formValues.unspscs[i].organizationID,
        TrendMethod: formValues.unspscs[i].trendMethod,
        SimulationMethod: formValues.unspscs[i].simulationMethod,
        UnitMethod: formValues.unspscs[i].unitMethod
      });
    }
    return workDataArray;
  }

  public generateSelectedUnspscManually(array: any) {
    let selectedUnspscs = [[]];
    array.forEach((formUnspsc, i) => {
      if (!selectedUnspscs[i]) selectedUnspscs[i] = [];
      if (formUnspsc.unspscClassID.includes(',')) {
        formUnspsc.unspscClassID.split(',').forEach(unspsc => {
          selectedUnspscs[i].push({ unspscClassID: unspsc });
        });
      } else {
        selectedUnspscs[i].push({ unspscClassID: formUnspsc.unspscClassID });
      }
    });

    return selectedUnspscs;
  }

  prepareCalculateDataForSaving(
    selectedUnspscs: Unspsc[][],
    templateValues = null,
    idParam = null
  ): Observable<CalculateDto[]> {
    let scenarioDataArray: CalculateDto[] = [];
    let calculationUnspscs, years, adjustment, id;

    let chosenUnitMethod = -1;
    if (templateValues) {
      selectedUnspscs = this.generateSelectedUnspscManually(templateValues.unspscs);

      const form = this.calculatorHelperService.getUnspscsFormValues(templateValues, selectedUnspscs, 'calculatorUnspsc');
      chosenUnitMethod = form.unitMethod;
      calculationUnspscs = form;
      id = idParam;
      let maxForecastLength =
        this.calculatorHelperService.getMaximumForecastLength(
          calculationUnspscs.unspscs
        );
      years = [];
      for (
        let i = new Date().getFullYear();
        i <= new Date().getFullYear() + maxForecastLength;
        i++
      ) {
        years.push(i);
      }
    } else {
      const form = this.calculatorHelperService.getUnspscsFormValues(this.formService.form.value, selectedUnspscs, 'calculatorUnspsc');
      chosenUnitMethod = form.unitMethod;
      calculationUnspscs = form;

      years =
        this.calculatorHelperService.arrayOfForecastedYears ?? [];
      adjustment =
        this.formService.form.value.adjustment;
      id = this.formService.id;
    }

    return this.getHistoricalDataPointsForMultipleUnspscs(calculationUnspscs).pipe(
      map((historicalData) => {
        // get the historical data based on different unspscs from different unspc form elements
        const grouped = this.groupBy(calculationUnspscs.unspscs, 'formIndex');

        Object.keys(grouped).forEach(formIndex => {
          let newestHistoricalRecords = [];
          const sectionHistoricalData = historicalData.find(x => x.formIndex == formIndex.toString())?.trendPoints;
          const historicalDataGrouped = this.groupBy(
            sectionHistoricalData?.filter(x => grouped[formIndex].map(y => y.unspscClassID).includes(x.unspscClassID)),
            'unspscClassID');

          Object.keys(historicalDataGrouped).forEach(x => {
            newestHistoricalRecords.push(historicalDataGrouped[x].sort(function (a, b) {
              return b.year - a.year;
            })[0]);
          });

          grouped[formIndex].forEach((unspsc) => {
            let historicalDataForUnspsc = sectionHistoricalData?.filter(
              (el) => el?.unspscClassID == unspsc.unspscClassID
            ) || [];

            let unspscs;
            let unspscCode = historicalDataForUnspsc[0]?.unspscClassID;

            let proportionFieldName = chosenUnitMethod == 1 ? 'amount' : 'quantity';
            let arg1 = Math.round(newestHistoricalRecords.filter(x => x.unspscClassID == unspscCode)[0]?.[proportionFieldName] * 1000) / 1000;
            let arg2 = Math.round(newestHistoricalRecords.filter(x => x.unspscClassID != unspscCode).map(y => y[proportionFieldName]).reduce((acc, cur) => acc + Number(cur), 0) * 1000) / 1000;

            let adjustmentProportionPercentage;
            if (arg2 >= 0) {
              adjustmentProportionPercentage = Math.round(((arg1 / (arg1 + arg2)) * 100) * 1000) / 1000;
            }

            if (templateValues) {
              unspscs = templateValues.unspscs;
            } else {
              unspscs = this.formService.form.value.unspscs;
            }

            let unspscObject: UnspscCalculatorServiceDto = unspscs.find(
              (el) => el.formIndex == formIndex && el.unspscClassID == unspscCode
            );

            let {
              forecastLength,
              adjustment,
              trendMethod,
              simulationMethod,
              unitMethod
            } = unspscObject || {};

            let maximumForecastLength =
              this.calculatorHelperService.getMaximumForecastLength(
                unspscs
              );

            let unspscConfig = {
              adjustment,
              trendMethod,
              simulationMethod,
              unitMethod,
              adjustmentProportionPercentage
            };

            if (
              historicalDataForUnspsc instanceof Array &&
              !historicalDataForUnspsc.length
            ) {
              console.log(unspsc, 'empty - go to next unspsc');
            } else {
              this.calculatorHelperService.addZerosWhenNoData(
                historicalDataForUnspsc as HistoricalDataDto[]
              );

              let dataUnspscSpecific = this.prepareCalculatedDataForUnspsc(
                historicalDataForUnspsc,
                maximumForecastLength,
                forecastLength,
                unspscConfig
              );

              if (!dataUnspscSpecific.expense) dataUnspscSpecific.expense = {} as GraphData;
              let expenseTrendUnspscSpecific =
                dataUnspscSpecific.expense.trendPoints;
              let expenseSimulationUnspscSpecific =
                dataUnspscSpecific.expense.simulationPoints;
              let expenseChangeUnspscSpecific = dataUnspscSpecific.expense.change;
              if (!dataUnspscSpecific.emission) dataUnspscSpecific.emission = {} as GraphData;
              let emissionTrendUnspscSpecific =
                dataUnspscSpecific.emission.trendPoints;
              let emissionSimulationUnspscSpecific =
                dataUnspscSpecific.emission.simulationPoints;
              let emissionChangeUnspscSpecific =
                dataUnspscSpecific.emission.change;
              let emissionFactorTrendPoints =
                dataUnspscSpecific.emission.factorTrendPoints;

              for (let year of years) {
                scenarioDataArray.push({
                  Adjustment: Number(adjustment),
                  Year: year,
                  UnspscClassID: unspscCode,
                  LineAmountTrend: expenseTrendUnspscSpecific.find((el) => el.x == year)?.y || 0,
                  LineAmountSimulation: expenseSimulationUnspscSpecific.find((el) => el.x == year)
                    ?.y || 0,
                  lineAmountChange: expenseChangeUnspscSpecific.find((el) => el.x == year)?.y ||
                    0,
                  Co2prOneLineAmountTrend: emissionFactorTrendPoints.find((el) => el.x == year)?.y || 0,
                  EmissionTrend: emissionTrendUnspscSpecific.find((el) => el.x == year)?.y ||
                    0,
                  EmissionSimulation: emissionSimulationUnspscSpecific.find((el) => el.x == year)
                    ?.y || 0,
                  emissionChange: emissionChangeUnspscSpecific.find((el) => el.x == year)?.y ||
                    0,
                  calculationId: id,
                  name: '',
                  description: '',
                  adjustment: 0,
                  years: 0,
                  calculationUnspscs: [],
                  email: ''
                });
              }
            }
          });
        });
        return scenarioDataArray;
      })
    );
  }

  groupBy(array: any[], propertyName: string) {
    return array.reduce((result, item) => {
      result[item[propertyName]] = result[item[propertyName]] || [];
      result[item[propertyName]].push(item);
      return result;
    }, Object.create(null));
  }

  prepareCalculatedDataForUnspsc(
    historicalDataForOneUnspsc,
    maximumForecastLength,
    forecastLength: number,
    calculationConfig: CalculationConfig
  ) {
    let expenseHistoricalPoints =
      this.calculatorHelperService.mapHistoricalDataToXY(
        historicalDataForOneUnspsc,
        'amount'
      );
    console.log(
      '%cexpenseHistoricalPoints',
      'color: red;',
      expenseHistoricalPoints
    );

    let quantityHistoricalPoints =
      this.calculatorHelperService.mapHistoricalDataToXY(
        historicalDataForOneUnspsc,
        'quantity'
      );
    console.log(
      '%quantityHistoricalPoints',
      'color: red;',
      quantityHistoricalPoints
    );

    let quantityTrendPoints;

    if (calculationConfig.trendMethod == 2) {
      quantityTrendPoints = this.calculateConstantTrendPoints(
        quantityHistoricalPoints,
        maximumForecastLength
      );
    } else {
      quantityTrendPoints = this.calculateTrendPoints(
        quantityHistoricalPoints,
        maximumForecastLength
      );
    }

    console.log('%cquantityTrendPoints', 'color: blue;', quantityTrendPoints);

    let xyEmissionFactor =
      this.calculatorHelperService.mapHistoricalDataToXYEmissionFactor(
        historicalDataForOneUnspsc
      );
    console.log('%cxyEmissionFactor', 'color: green;', xyEmissionFactor);

    this.calculatorHelperService.historicalPointsXYEmissionFactorData =
      xyEmissionFactor;

    let emissionHistoricalPoints =
      this.calculatorHelperService.mapHistoricalDataToXYEmission(
        historicalDataForOneUnspsc
      );
    console.log(
      '%cemissionHistoricalPoints',
      'color: orange;',
      emissionHistoricalPoints
    );

    let expenseTrendPoints;

    if (calculationConfig.trendMethod == 2) {
      expenseTrendPoints = this.calculateConstantTrendPoints(
        expenseHistoricalPoints,
        maximumForecastLength
      );
    } else {
      expenseTrendPoints = this.calculateTrendPoints(
        expenseHistoricalPoints,
        maximumForecastLength
      );
    }

    console.log('%cexpenseTrendPoints', 'color: grey;', expenseTrendPoints);

    let expenseSimulationPoints = this.calculateQuantitySimulation(
      expenseHistoricalPoints,
      quantityTrendPoints,
      calculationConfig,
      maximumForecastLength,
      forecastLength,
      expenseTrendPoints,
      'expense'
    );

    console.log(
      '%cexpenseSimulationPoints',
      'color: brown;',
      expenseSimulationPoints
    );

    let expenseChange = this.calculatorHelperService.calculateChange(
      expenseTrendPoints,
      expenseSimulationPoints,
      expenseHistoricalPoints,
      CHANGE.EXPENSE
    );

    let emissionFactorTrendPoints;
    if (calculationConfig.trendMethod == 2) {
      emissionFactorTrendPoints = this.calculateConstantTrendPoints(
        xyEmissionFactor,
        maximumForecastLength
      );
    } else {
      emissionFactorTrendPoints = this.calculateTrendPoints(
        xyEmissionFactor,
        maximumForecastLength
      );
    }

    console.log(
      '%cemissionFactorTrendPoints',
      'color: orange;',
      emissionFactorTrendPoints
    );

    let emissionTrendPoints = this.calculateEmissionTrend(
      expenseTrendPoints,
      emissionFactorTrendPoints
    );

    console.log('%cemissionTrendPoints', 'color: yellow;', emissionTrendPoints);

    let emissionSimulationPoints = this.calculateEmissionSimulation(
      expenseSimulationPoints,
      emissionFactorTrendPoints,
      emissionHistoricalPoints,
      quantityHistoricalPoints,
      maximumForecastLength,
      forecastLength,
      emissionTrendPoints,
      calculationConfig
    );

    console.log(
      '%cemissionSimulationPoints',
      'color: purple;',
      emissionSimulationPoints
    );

    let emissionChange = this.calculatorHelperService.calculateChange(
      emissionTrendPoints,
      emissionSimulationPoints,
      emissionHistoricalPoints,
      CHANGE.EMISSION
    );

    console.log(
      '%cemissionSimulationPoints',
      'color: purple;',
      emissionSimulationPoints
    );

    let quantitySimulationPoints = this.calculateQuantitySimulation(
      expenseHistoricalPoints,
      quantityHistoricalPoints,
      calculationConfig,
      maximumForecastLength,
      forecastLength,
      quantityTrendPoints,
      'quantity'
    );

    console.log(
      '%cquantitySimulationPoints',
      'color: blue;',
      quantitySimulationPoints
    );

    let quantityChange = this.calculatorHelperService.calculateChange(
      quantityTrendPoints,
      quantitySimulationPoints,
      quantityHistoricalPoints,
      CHANGE.QUANTITY
    );

    // TODO: check whether total change is necessary
    // TODO: work on the final naming of the CalculatedGraphData interface fields
    /* TODO: think of the flow (calculating edited data, calculating new data,
        using aggregated data for graph, using individual data for change,
        saving the data do database, updating the data, etc.)
     */

    let allData: CalculatedGraphData = {
      expense: {
        historicalPoints: expenseHistoricalPoints,
        trendPoints: expenseTrendPoints,
        simulationPoints: expenseSimulationPoints,
        change: expenseChange
      } as GraphData,
      emission: {
        historicalPoints: emissionHistoricalPoints,
        factorTrendPoints: emissionFactorTrendPoints,
        trendPoints: emissionTrendPoints,
        simulationPoints: emissionSimulationPoints,
        change: emissionChange
      } as GraphData,
      quantity: {
        change: quantityChange,
        historicalPoints: quantityHistoricalPoints,
        simulationPoints: quantitySimulationPoints,
        trendPoints: quantityTrendPoints
      } as GraphData
    };

    return allData;
  }

  getHistoricalDataPointsForMultipleUnspscs(
    formValues
  ): Observable<HistoricalDataResponseDto[]> {
    return this.apiService.getHistoricalDataPoints(<SearchTrendPointModelDto>{
      trendRequestModels: formValues.unspscs.map(x => <TrendRequestModel>{
        organizationId: x.organizationID,
        unspscClassId: x.unspscClassID,
        formIndex: x.formIndex
      })
    });
  }
}
