import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageItem } from '../commons/models/local-storage-item';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private _localStorage: BehaviorSubject<Array<LocalStorageItem>> =
    new BehaviorSubject([]);
  public readonly localstorage: Observable<Array<LocalStorageItem>> =
    this._localStorage.asObservable();

  constructor() {
    let keys = Object.keys(localStorage);
    let items: Array<LocalStorageItem> = [];
    for (let i = 0; i < keys.length; i++) {
      let value = localStorage.getItem(keys[i]);
      let newLocalStorageItem = new LocalStorageItem();
      newLocalStorageItem.key = keys[i];
      newLocalStorageItem.value = value;
      items.push(newLocalStorageItem);
    }
    this._localStorage.next(items);
  }

  addOrUpdateObject(key: string, value: object) {
    this.addOrUpdateItem(key, JSON.stringify(value));
  }

  addOrUpdateItem(key: string, value: string) {
    localStorage.setItem(key, value);
    let values = this._localStorage.value;
    let newLocalStorageItem = new LocalStorageItem();
    newLocalStorageItem.key = key;
    newLocalStorageItem.value = value;
    if (this._localStorage.value.find((x) => x.key == key) === undefined) {
      values.push(newLocalStorageItem);
    } else {
      let index = this._localStorage.value.findIndex((x) => x.key == key);
      values[index] = newLocalStorageItem;
    }
    this._localStorage.next(values);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
    let values = this._localStorage.value;
    let newValues = values.filter((x) => x.key != key);
    this._localStorage.next(newValues);
  }

  getObject<T>(key: string): T {
    const str = this.getItem(key)?.value;
    return str ? JSON.parse(str) : undefined;
  }

  getItem(key: string): LocalStorageItem {
    return this._localStorage.value.find((x) => x.key == key);
  }
}
