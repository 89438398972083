import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayPattern'
})
export class DisplayPatternPipe implements PipeTransform {

  transform(value: string, args: { [key: string]: string }): string {
    if (typeof args !== 'object') {
      return value;
    }
    try {
      return new Function('return `' + value.replace(/\$\{(.+?)\}/g, '${this.$1}') + '`;').call(args);
    } catch (e) {
      console.error('ES6 syntax not supported', e);
    }
    Object.keys(args).forEach((key) => {
      value = value.replace(new RegExp('\\$\\{' + key + '\\}', 'g'), args[key]);
    });
    return value;
  }
}
