<div class="row">
    <div class="col-9">
        <div>
            <h2 mat-dialog-title>{{ 'csrd.export' | translate }}</h2>
        </div>

    </div>

    <div class="col-3">
        <div class="text-right" style="margin-top: 0.5rem; margin-right: 0.5rem;">
            <button mat-icon-button [mat-dialog-close]="false">
                <mat-icon fontIcon="close"></mat-icon>
            </button>
        </div>
    </div>

    <div class="col-12" style="margin-top: 1rem">
        <div class="full-width mb-3">
            <mat-form-field style="width: 95%; margin-left: 1rem">
                <mat-label>{{
                    'csrd.fiscal-years-tab' | translate
                    }}:</mat-label>
                <mat-select (selectionChange)="onSelectionChange($event)">
                    <ng-container *ngFor="let item of fiscalYears">
                        <mat-option [value]="item.id">{{ item.description }}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="col-12">
        <div mat-dialog-actions [align]="'end'">

            <button mat-raised-button color="primary" (click)="confirm()" [mat-dialog-close]="true">
                Ok
                <!-- {{ 'general.yes' | translate }} -->
            </button>

        </div>
    </div>
</div>