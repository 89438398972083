import { Injectable } from '@angular/core';
import {
  Columns,
  Filter,
  FilterTypes,
  ObjectTypes,
  TranslationKeys,
} from '@dima/table-library';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { VerifiedStatusPipe } from '../../../commons/pipes/verified-status-pipe.pipe';
import { DateTime } from 'luxon';
import { ITableService } from 'src/app/commons/components/data-view/table-service.interface';
import { PageModel } from 'src/app/commons/models/page-model';
import { CustomDecimalPipe } from 'src/app/commons/pipes/custom-decimal.pipe';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DataViewType } from 'src/app/commons/components/create-edit-dialog/data-view-type.enum';

@Injectable({
  providedIn: 'root',
})
export class TableService implements ITableService {
  public cols: Columns[];
  public totalCount: number;
  public pageSize: number = 10;
  public page: number = 0;
  public data = [];
  public langSubscription: Subscription;
  public loading: boolean = false;
  public table: any;
  public selectedRows: any[];
  public dimaTableTranslations: any;
  public pagedModel: PageModel;
  filters: Filter[] = [];

  clearTableSelection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  anyRowSelectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private currentLanguageService: CurrentLanguageService,
    private statusPipe: VerifiedStatusPipe,
    private localStorageService: LocalStorageService
  ) {

    this.langSubscription = this.translateService.onLangChange.subscribe(
      (lang) => {
        this.setCols();
        this.router.navigate([this.getCurrentRoute()]);
      }
    );
  }

  setCols() {
    let visibleColumns: string[];
    const savedColumnsToDisplay = this.localStorageService.getItem(DataViewType.CUSTOMERS_OWN_DATA.toString())?.value;
    if (savedColumnsToDisplay) {
      visibleColumns = JSON.parse(savedColumnsToDisplay) as string[];
    }

    this.dimaTableTranslations = [
      {
        Key: TranslationKeys.globalFilterHeader,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.globalFilterPlaceholder,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.paginatorItemsPerPageLabel,
        Label: this.translateService.instant(
          'general.paginator.per-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.per-page'),
      },
      {
        Key: TranslationKeys.paginatorRangeLabel,
        Label: this.translateService.instant('general.paginator.of'),
        Tooltip: this.translateService.instant('general.paginator.of'),
      },
      {
        Key: TranslationKeys.paginatorFirstPageLabel,
        Label: this.translateService.instant(
          'general.paginator.first-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.first-page'),
      },
      {
        Key: TranslationKeys.paginatorLastPageLabel,
        Label: this.translateService.instant(
          'general.paginator.last-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.last-page'),
      },
      {
        Key: TranslationKeys.paginatorPreviousPageLabel,
        Label: this.translateService.instant(
          'general.paginator.prev-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.prev-page'),
      },
      {
        Key: TranslationKeys.paginatorNextPageLabel,
        Label: this.translateService.instant(
          'general.paginator.next-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.next-page'),
      },
      {
        Key: TranslationKeys.globalFilterPlaceholder,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.columnControlHeader,
        Label: this.translateService.instant(
          'general.paginator.columns'
        ),
        Tooltip: this.translateService.instant('general.paginator.columns'),
      },
      {
        Key: TranslationKeys.saveButton,
        Label: this.translateService.instant('general.paginator.save'),
        Tooltip: this.translateService.instant('general.paginator.save'),
      },
      {
        Key: TranslationKeys.cancelButton,
        Label: this.translateService.instant(
          'general.paginator.cancel'
        ),
        Tooltip: this.translateService.instant('general.paginator.cancel'),
      },
      {
        Key: TranslationKeys.tableEmptyStateMessage,
        Label: this.translateService.instant(
          'general.paginator.no-data'
        ),
        Tooltip: this.translateService.instant('general.paginator.no-data'),
      },
    ];

    const precission0Pipe = new CustomDecimalPipe(this.currentLanguageService);
    precission0Pipe.decimalPlaces = 0;

    this.cols = [
      {
        id: 0,
        columnsOrder: 0,
        type: ObjectTypes.rowSelect,
        name: 'selection',
        filterType: FilterTypes.rowSelect,
        showInColumnControl: false,
        visible: true
      },
      {
        id: 1,
        columnsOrder: 1,
        type: ObjectTypes.date,
        name: 'transactionDate',
        title: this.translateService.instant(
          'customers-own-data.transaction-date'
        ),
        filterType: FilterTypes.date,
        filterFn: (value: any, data: Date) => {
          if (!value.date || DateTime.fromJSDate(new Date(value.date)).toLocaleString()
            == DateTime.fromJSDate(new Date(data.toString())).toLocaleString()) {
            return true;
          }
          return false;
        },
        visible: visibleColumns?.includes('transactionDate') ?? true
      },
      {
        id: 2,
        columnsOrder: 2,
        type: ObjectTypes.string,
        name: 'invoiceNumber',
        title: this.translateService.instant(
          'customers-own-data.invoice-number'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('invoiceNumber') ?? false
      },
      {
        id: 3,
        columnsOrder: 3,
        type: ObjectTypes.string,
        name: 'buyersEAN',
        title: this.translateService.instant(
          'customers-own-data.buyers-ean'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('buyersEAN') ?? false
      },
      {
        id: 4,
        columnsOrder: 4,
        type: ObjectTypes.string,
        name: 'supplierDisplayValue',
        title: this.translateService.instant(
          'customers-own-data.supplier-id'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('checksSearch') ?? true
      },
      {
        id: 5,
        columnsOrder: 5,
        type: ObjectTypes.string,
        name: 'organizationID',
        title: this.translateService.instant(
          'customers-own-data.organization-id'
        ),
        filterType: FilterTypes.input,
        visible: visibleColumns?.includes('organizationID') ?? true
      },
      {
        id: 6,
        columnsOrder: 6,
        type: ObjectTypes.string,
        name: 'unspscDisplayValue',
        title: this.translateService.instant(
          'customers-own-data.unspsc-class-id'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('unspscDisplayValue') ?? true
      },
      {
        id: 7,
        columnsOrder: 7,
        type: ObjectTypes.string,
        name: 'lineAmountWithoutVAT',
        title: this.translateService.instant(
          'customers-own-data.line-amount-without-vat'
        ),
        pipe: precission0Pipe,
        filterType: FilterTypes.range,
        visible: visibleColumns?.includes('lineAmountWithoutVAT') ?? true
      },
      {
        id: 8,
        columnsOrder: 8,
        type: ObjectTypes.string,
        name: 'supplierEmissionCo2',
        title: this.translateService.instant(
          'customers-own-data.supplier-emission-co2'
        ),
        pipe: precission0Pipe,
        filterType: FilterTypes.range,
        visible: visibleColumns?.includes('supplierEmissionCo2') ?? false
      },
      {
        id: 9,
        columnsOrder: 9,
        type: ObjectTypes.string,
        name: 'quantity',
        title: this.translateService.instant(
          'customers-own-data.quantity'
        ),
        filterType: FilterTypes.range,
        pipe: precission0Pipe,
        visible: visibleColumns?.includes('quantity') ?? true
      },
      {
        id: 10,
        columnsOrder: 10,
        type: ObjectTypes.string,
        name: 'productID',
        title: this.translateService.instant(
          'customers-own-data.product-id'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('productID') ?? true
      },
      {
        id: 11,
        columnsOrder: 11,
        type: ObjectTypes.string,
        name: 'productName',
        title: this.translateService.instant(
          'customers-own-data.product-name'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('productName') ?? true
      },
      {
        id: 12,
        columnsOrder: 12,
        type: ObjectTypes.string,
        name: 'productDescription',
        title: this.translateService.instant(
          'customers-own-data.product-description'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('productDescription') ?? true
      },
      {
        id: 13,
        columnsOrder: 13,
        type: ObjectTypes.string,
        name: 'unit',
        title: this.translateService.instant(
          'customers-own-data.unit'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('unit') ?? true
      },
      {
        id: 14,
        columnsOrder: 14,
        type: ObjectTypes.string,
        name: 'filenameFromUpload',
        title: this.translateService.instant(
          'customers-own-data.filename-from-upload'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('filenameFromUpload') ?? false
      },
      {
        id: 15,
        columnsOrder: 15,
        type: ObjectTypes.string,
        name: 'ghgCategoryDisplayValue',
        title: this.translateService.instant(
          'customers-own-data.ghg-category'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('ghgCategoryDisplayValue') ?? false
      },
      {
        id: 16,
        columnsOrder: 16,
        type: ObjectTypes.custom,
        name: 'edit',
        filterType: FilterTypes.custom,
        showInColumnControl: false,
        // stickyRight: true,
        visible: true,
        width: 5
      },
      {
        id: 17,
        columnsOrder: 17,
        type: ObjectTypes.custom,
        name: 'delete',
        filterType: FilterTypes.custom,
        showInColumnControl: false,
        // stickyRight: true,
        visible: true,
        width: 20
      },
    ];
  }

  setData(data) {
    if (data) {
      this.pageSize = Number(data._rowsPerPage);
      this.page = Number(data._PageNumber);
      this.totalCount = Number(data.totalFilteredProducts);
      this.data = data.pagedProductsDaos;
    }
  }

  getCurrentRoute() {
    let urlWithoutParams = this.router.url.split('?')[0];
    return urlWithoutParams;
  }
}
