// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiDomain: window.location.origin,
  powerBiTokenRenewalIntervalMs: 120000,
  // apiDomain: 'https://localhost:44360',
  //apiDomain: 'http://localhost:4300',   // Use this line if you deployed API to docker using RunDocker with the -APIONLY option
  openIdConnect: {
    domain_hint: {
      default: 'dima-idp-prod',
      kmd: 'kmd-ad-prod',
    },
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
