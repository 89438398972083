import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeepCloneService {
  constructor() {}

  deepClone(clonedStructure) {
    return JSON.parse(JSON.stringify(clonedStructure));
  }
}
