import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Tenant, UserInfo } from '../../../commons/models/user-info';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user: BehaviorSubject<UserInfo>;
  public user$: Observable<UserInfo>;
  public currentUser: UserInfo;

  constructor(private apiService: ApiService) {
    this.user = new BehaviorSubject<UserInfo>(null);
    this.user$ = this.user.asObservable();
  }

  getUserTenantCode(): Observable<TenantCodeResponse> {
    return this.apiService.getUserTenantCode();
  }
}

export interface UserDto {
  username: string;
  tenants: Tenant[];
}

export interface TenantCodeResponse {
  tenantCode: string;
}