import { APP_INITIALIZER, NgModule } from '@angular/core';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

// Components
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { OpenIDCService } from './services/open-idc.service';
import { SystemService } from 'src/app/services/system.service';
import { TranslateModule } from '@ngx-translate/core';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CustomErrorComponent } from '@modules/auth/components/custom-error/custom-error.component';
import { SomethingWentWrongComponent } from './components/something-went-wrong/something-went-wrong.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    OAuthModule.forRoot(),
    TranslateModule,
    CommonModule,
  ],
  exports: [
    LoginComponent,
    LogoutComponent,
    ForbiddenComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    CustomErrorComponent,
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
    ForbiddenComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    CustomErrorComponent,
    SomethingWentWrongComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (openIDCService: OpenIDCService) => () =>
        openIDCService.init(),
      deps: [OpenIDCService, OAuthService, SystemService],
      multi: true,
    },
  ],
})
export class AuthModule {
  constructor() {}
}
