import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ROUTENAMES } from '../../../../app-routing.names';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: false
})
export class NotFoundComponent {
  errorTitle: any;
  errorCodeDescription: any;
  errorSubtitle: any;
  buttonName: any;
  navigationRoute: string;

  constructor(public translateService: TranslateService) {
    this.errorTitle = this.translateService.instant('errors.not-found.title');
    this.errorCodeDescription = this.translateService.instant(
      'errors.not-found.type'
    );
    this.errorSubtitle = this.translateService.instant(
      'errors.not-found.subtitle'
    );
    this.buttonName = this.translateService.instant('errors.not-found.cta');

    this.navigationRoute = ROUTENAMES.HOME;
  }
}
