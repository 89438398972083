<header class="top-bar">
  <div class="top-bar__left">
    <button
      *ngIf="user"
      class="sidebar-toggle"
      mat-icon-button
      [attr.aria-label]="isSidebarOpen ? 'Luk sidebar' : 'Åbn Sidebar'"
      [matTooltip]="isSidebarOpen ? 'Luk sidebar' : 'Åbn Sidebar'"
      (click)="toggleSidebar()"
    >
      <mat-icon fontIcon="menu"></mat-icon>
    </button>
    {{ title }}
  </div>

  <div
    class="d-flex align-items-center"
    *ngIf="user?.permissions.crossCustomerViewer"
  >
    <app-tenant-select
      *ngIf="adminService.isCrossCustomerViewer"
    ></app-tenant-select>
  </div>

  <div
    class="d-flex align-items-center"
    *ngIf="user?.permissions.customerUserExternal"
  >
    {{ 'general.identity-provider' | translate }}
    {{ user.customerNameExternal }}
  </div>

  <div class="d-flex align-items-center">
    <div class="me-4">
      <select
        class="form-control p-1"
        #selectedLang
        [value]="currentLanguageService.lang"
        (change)="currentLanguageService.switchLang(selectedLang.value)"
      >
        <option
          *ngFor="let language of translate.getLangs()"
          [value]="language"
        >
          {{ language }}
        </option>
      </select>
    </div>

    <div *ngIf="user" class="me-4">
      {{ user.username }}
    </div>
    <app-settings></app-settings>
  </div>
</header>
