import { Directive } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';

@Directive({
  selector: '[appSanityAsyncValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: SanityAsyncValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class SanityAsyncValidatorDirective implements AsyncValidator {
  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const regexPattern: RegExp = new RegExp(/[?&^$#=.@!|()+\-,:;<>’\\'_*%]/);
    let controlValue = control.value;
    const isMatch: boolean = regexPattern.test(controlValue);
    if (isMatch) return of({ regexFail: `${controlValue} is not allowed.` });
    return of(null);
  }
}
