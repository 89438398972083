import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserActivity } from '../commons/models/activity.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  apiDomain = environment.apiDomain;
  activitiesUrl = `${this.apiDomain}/api/activities`;

  constructor(private http: HttpClient) { }

  sendActivity(activity: UserActivity) {
    return this.http.post(this.activitiesUrl, activity).subscribe();
  }
}
