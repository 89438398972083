import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Unspsc } from '../../../../commons/models/unspsc';
import { DeepCloneService } from '../../../../commons/services/deep-clone.service';
import { TemplatesCrudHelperService } from '@modules/scenarios/services/templates/templates-crud-helper.service';
import { UnspscHandlerService } from '../../../../commons/components/unspsc-handler/unspsc-handler.service';
import { CalculatorService } from '@modules/scenarios/services/calculator/calculator.service';
import { CalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.service';
import { CalculatorTableService } from '@modules/scenarios/services/calculator/calculator-table.service';
import { CalculatorFormService } from '@modules/scenarios/services/calculator/calculator-form.service';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';

@Component({
    selector: 'app-create-edit-template-dialog',
    templateUrl: './create-edit-template-dialog.component.html',
    styleUrls: ['./create-edit-template-dialog.component.scss'],
    standalone: false
})
export class CreateEditTemplateDialogComponent implements AfterViewInit, OnDestroy {
  public years = ['1', '2', '3', '4', '5'];
  public header = '';

  calculationValid = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    public fb: FormBuilder,
    private dialogRef: MatDialogRef<any>,
    private deepCloneService: DeepCloneService,
    private translateService: TranslateService,
    public templatesFormService: CalculatorFormService,
    public calculatorService: CalculatorService,
    public templatesCalculatorHelperService: CalculatorHelperService,
    private templatesTableService: CalculatorTableService,
    private templateCrudHelperService: TemplatesCrudHelperService,
    public unspscHandlerService: UnspscHandlerService,
    public tenantSelectService: TenantSelectService
  ) {
    this.templatesFormService.setDialogRef(dialogRef);
    this.header = this.translateService.instant('templates.create-template');

    if (editData) {
      this.templatesFormService.setEditData(editData);
      this.header = this.translateService.instant('templates.update-template');

      this.calculatorService.areGraphsShown = true;
    }
    this.templatesFormService.initializeForm();
  }

  ngAfterViewInit(): void {
    this.calculatorService.graphInit.subscribe(x => {
      if (x.expense.change?.length > 0 || x.emission.change?.length > 0) {
        this.calculationValid = true;
      }

      this.unspscHandlerService.unspscsChange.subscribe(() => {
        this.templatesFormService.form.controls.unspscs.updateValueAndValidity();
        this.templatesFormService.form.updateValueAndValidity();
        this.calculationValid = false;
        this.calculatorService.resetSummaryData();
        this.templatesCalculatorHelperService.resetYears();
        this.templatesTableService.resetUnspscTable();
        this.calculatorService.totalEmissionChange = undefined;
        this.calculatorService.totalExpenseChange = undefined;
        this.calculatorService.totalQuantityChange = undefined;
      });
    });
  }

  ngOnDestroy(): void {
    this.unspscHandlerService.resetFilteredViewModels();

    this.templatesTableService.resetUnspscTable();
    this.calculatorService.resetSummaryData();
    this.templatesCalculatorHelperService.resetYears();

    this.templatesFormService.resetEditData();
    this.templatesFormService.resetForm();

    this.templateCrudHelperService.unsubscribe();
  }

  ctaTemplate(): void {
    let unspscs: Unspsc[] = this.templatesFormService.form
      .getRawValue()
      .unspscs.map((el, i) =>
        <Unspsc>{
          unspscClassId: el.unspscClassId,
          forecastLength: el.forecastLength,
          adjustment: Number(el.adjustment),
          organizationID: el.organizationID,
          trendMethod: Number(el.trendMethod),
          simulationMethod: Number(el.simulationMethod),
          unitMethod: Number(el.unitMethod),
          selected: el.selected,
          formIndex: i
        }
      );

    this.templatesFormService.form.value.unspscs = unspscs;

    this.templatesFormService.form.value.templateUnspsc = unspscs.map(x => x.unspscClassId);

    if (this.templatesFormService.form.valid) {
      if (!this.editData) {
        this.addTemplateMaster();
      } else {
        this.updateTemplate(this.editData.calculationId, this.editData.id);
      }
    }
  }

  addTemplateMaster() {
    this.templateCrudHelperService.addTemplateMaster(
      this.unspscHandlerService.selectedUnspscs,
      this.templatesFormService.form.value
    );
  }

  updateTemplate(dwsk, id): void {
    this.templateCrudHelperService.updateTemplate(this.unspscHandlerService.selectedUnspscs, dwsk, id);
  }

  calculate(formValues) {
    if (this.templatesFormService.form.valid) {
      this.templatesTableService.resetUnspscTable();
      let unspscArray = this.deepCloneService.deepClone(
        formValues.unspscs
      );

      formValues.templateUnspsc = unspscArray.map(
        (el) => el.unspscClassId
      );

      this.calculatorService.areGraphsShown = true;

      formValues = this.templatesCalculatorHelperService.getUnspscsFormValues(formValues, this.unspscHandlerService.selectedUnspscs, 'calculatorUnspsc');
      this.calculatorService.calculateAndShow(formValues);
      this.templatesCalculatorHelperService.unitMethodDropdownReloaded.next(this.unspscHandlerService.unitMethod);
    }
  }

  cancel() {
    this.calculatorService.areGraphsShown = false;
    this.dialogRef.close('cancel');
  }

  onFormChanged(value: boolean) {
    if (value) {
      this.templatesFormService.form.controls['description'].markAsTouched();
    }
  }
}
