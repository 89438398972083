import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'activatedStatus',
  standalone: true,
  pure: false,
})
export class ActivatedStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  static transform(value, status) {
    return value;
  }
  transform(value: boolean, status): string {
    value = value
      ? this.translateService.instant('product-specific-emissions.active')
      : this.translateService.instant('product-specific-emissions.inactive');
    return ActivatedStatusPipe.transform(value, status);
  }
}
