import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { OrganizationalUnit } from '../../commons/models/organizational-unit';
import { Observable, Subscription } from 'rxjs';
import { StatusDto } from '../../commons/models/status-dto';
import { PriorityDto } from '../../commons/models/priority-dto';
import { InitiativeMasterDto } from '../../commons/models/initiative-dto';
import { EntityTrackerError } from '../../commons/models/entity-tracker-error';

@Injectable({
  providedIn: 'root',
})
export class InitiativesService implements OnDestroy {
  private organizationalUnitSubscription?: Subscription;
  private statusSubscription?: Subscription;
  private prioritySubscription?: Subscription;

  public organizationalUnits: OrganizationalUnit[];
  public statuses: StatusDto[];
  public priorities: PriorityDto[];

  constructor(private apiService: ApiService) {}

  getMiscInitiativeData(): void {
    this.getOrganizationalUnit();
    this.getStatuses();
    this.getPriorities();
  }

  getOrganizationalUnit(): void {
    this.organizationalUnitSubscription = this.apiService
      .getOrganizationalUnit()
      .subscribe((unit: OrganizationalUnit[]) => {
        this.organizationalUnits = unit;
      });
  }

  getStatuses(): void {
    this.statusSubscription = this.apiService
      .getStatuses()
      .subscribe((statuses: StatusDto[]) => {
        this.statuses = statuses;
      });
  }

  getPriorities(): void {
    this.prioritySubscription = this.apiService
      .getPriorities()
      .subscribe((priorities: PriorityDto[]) => {
        this.priorities = priorities;
      });
  }

  getInitiatives(): Observable<InitiativeMasterDto[] | EntityTrackerError> {
    return this.apiService.getInitiatives();
  }

  ngOnDestroy(): void {
    this.organizationalUnitSubscription.unsubscribe();
    this.statusSubscription.unsubscribe();
    this.prioritySubscription.unsubscribe();
  }
}
