<div class="container gx-0">
  <div class="row justify-content-between">
    <form [formGroup]="formService.form" #filterForm>
      <div class="container gx-0">
        <div class="row">
          <div class="col-12 mt-3 mb-3">
            <p class="fw-bold fs-30">
              {{ 'navigation.customers-own-data-exclusion' | translate }}
            </p>
          </div>
        </div>
        <div *ngIf="supplierOptionsInitialized && unspscsOptionsInitialized && organizationOptionsInitialized" class="row justify-content-around align-items-stretch mt-3">

          <div class="row">
            <div class="col-6">
              <app-custom-autocomplete [parentGroup]="formService.form" [options]="invoiceNumberOptions"
                controlName="InvoiceNumber" [filterFieldNames]="['invoiceNumber']" valueFieldName="invoiceNumber"
                displayFieldPattern="${invoiceNumber}" orderFieldName="invoiceNumber" (valueCleared)="setNoDataOption('invoiceNumber', 'invoiceNumberOptions')"
                labelTranslationKey="customers-own-data.invoice-number">
              </app-custom-autocomplete>
            </div>

            <div class="col-6">
              <app-custom-autocomplete [parentGroup]="formService.form" [options]="unspscOptions"
                controlName="UnspscClassId" [filterFieldNames]="['unspscClassId', 'unspscClassDescription']"
                valueFieldName="unspscClassId" displayFieldPattern="${unspscClassDescription}"
                orderFieldName="unspscClassId" labelTranslationKey="customers-own-data.unspsc-class-id"
                (valueCleared)="setNoDataOption('unspscClassDescription', 'unspscOptions')"> 
              </app-custom-autocomplete>
            </div>

            <div class="col-6">
              <app-custom-autocomplete [parentGroup]="formService.form" [options]="organizationOptions"
                displayFieldPattern="${organizationID} ${organizationDescription}" controlName="OrganizationId"
                valueFieldName="organizationID" [filterFieldNames]="['organizationDescription', 'organizationID']"
                (valueCleared)="setNoDataOption('organizationID', 'organizationOptions')"
                orderFieldName="organizationID" labelTranslationKey="customers-own-data.organization-id">
              </app-custom-autocomplete>
            </div>

            <div class="col-6">
              <app-custom-autocomplete [parentGroup]="formService.form" [options]="supplierOptions"
                controlName="SupplierId" [filterFieldNames]="['supplierDisplayValue']"
                valueFieldName="supplierId" orderFieldName="supplierDisplayValue"
                displayFieldPattern="${supplierDisplayValue}" (selectionChanged)="onSupplierSelectionChange($event)"
                (valueCleared)="setNoDataOption('supplierDisplayValue', 'supplierOptions')"
                labelTranslationKey="customers-average-price.supplier">
              </app-custom-autocomplete>
            </div>

            <div class="col-6" style="display: none;">
              <mat-form-field appearance="fill">
                <mat-label>{{
                  'customers-average-price.supplier-country' | translate
                  }}</mat-label>
                <input formControlName="SupplierCountry" matInput placeholder="{{
                'customers-average-price.supplier-country'
                  | translate
              }}" />
              </mat-form-field>
            </div>

            <div class="col-4">
              <app-custom-autocomplete [parentGroup]="formService.form" [options]="itemIdOptions"
                displayFieldPattern="${itemId}" controlName="ItemId" valueFieldName="itemId"
                [filterFieldNames]="['itemId']" orderFieldName="itemId"
                labelTranslationKey="customers-own-data-exclusion.item-id"
                (valueCleared)="setNoDataOption('itemId', 'itemIdOptions')">
              </app-custom-autocomplete>
            </div>

            <div class="col-4">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{
                  'customers-own-data-exclusion.date-from'
                  | translate
                  }}</mat-label>
                <input matInput [matDatepicker]="pickerDateFrom" formControlName="DateFrom"
                  (dateChange)="formService.onDateChange($event)" placeholder="{{
                    'customers-own-data-exclusion.date-from'
                      | translate
                  }}" />
                <mat-datepicker-toggle matSuffix [for]="pickerDateFrom">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerDateFrom></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-4">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{
                  'customers-own-data-exclusion.date-to'
                  | translate
                  }}</mat-label>
                <input matInput [matDatepicker]="pickerDateTo" formControlName="DateTo"
                  (dateChange)="formService.onDateChange($event)" placeholder="{{
                    'customers-own-data-exclusion.date-to'
                      | translate
                  }}" />
                <mat-datepicker-toggle matSuffix [for]="pickerDateTo">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerDateTo></mat-datepicker>
              </mat-form-field>
            </div>

          </div>

          <div class="row">
            <div class="col-12">
              <button mat-raised-button color="primary" [disabled]="formService.form.invalid || !searchButtonEnabled"
                (click)="submitFilters()" style="float: inline-end;">
                {{ 'customers-own-data-exclusion.apply-filters' | translate }}
              </button>
            </div>
          </div>

        </div>
      </div>
    </form>

    <div class="col-12 mt-5">
      <mat-paginator class="paginator" [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSize]="pageSize"
        [pageIndex]="pageIndex" [length]="length" (page)="onPageEvent($event)">
      </mat-paginator>

      <table mat-table #table [dataSource]="tableService.data" matSort (matSortChange)="sortData($event)">

        <ng-container matColumnDef="supplierDisplayValue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'customers-own-data-exclusion.supplier-id' |
            translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.supplierDisplayValue }}
          </td>
        </ng-container>

        <ng-container matColumnDef="invoiceNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'customers-own-data-exclusion.invoice-number' |
            translate }}
          </th>
          <td mat-cell *matCellDef="let element"> {{element.invoiceNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="itemId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'customers-own-data-exclusion.item-id' | translate
            }}
          </th>
          <td mat-cell *matCellDef="let element"> {{element.itemId}} </td>
        </ng-container>

        <ng-container matColumnDef="organizationDisplayValue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'customers-own-data-exclusion.organization-id' |
            translate }}
          </th>
          <td mat-cell *matCellDef="let element"> {{element.organizationDisplayValue}} </td>
        </ng-container>

        <ng-container matColumnDef="unspscDisplayValue">
          <th mat-header-cell *matHeaderCellDef> {{ 'customers-own-data-exclusion.unspsc-class-id' | translate }}
          </th>
          <td mat-cell *matCellDef="let element"> {{element.unspscDisplayValue}} </td>
        </ng-container>

        <ng-container matColumnDef="invoiceDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'customers-own-data-exclusion.invoice-date' |
            translate
            }}
          </th>
          <td mat-cell *matCellDef="let element"> {{element.invoiceDate | date:'MM/dd/yyyy'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
      </table>

      <div mat-dialog-actions [align]="'end'">
        <button mat-raised-button (click)="cancel()" [mat-dialog-close]="false">
          {{ 'customers-own-data.cancel' | translate }}
        </button>
        <button mat-raised-button color="primary" [disabled]="!submitButtonEnabled || isReadonlyUser()" (click)="submitForm()">
          {{ 'customers-own-data.save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>