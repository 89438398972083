import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, Form, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../../../services/dialog.service';
import { CreateEditDialogComponent } from 'src/app/commons/components/create-edit-dialog/create-edit-dialog.component';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { GhgCategoryUnspsc } from 'src/app/commons/models/ghg-category-unspsc-model';
import { GhgCategory } from 'src/app/commons/models/ghg-category-model';
import { OrganizationalUnitViewModel } from 'src/app/commons/models/organizational-unit';
import { EntityTrackerError } from 'src/app/commons/models/entity-tracker-error';
import { ExclusionsViewTableService } from '@modules/customers-own-data-exclusion/services/exclusions-view-table.service';
import { PageResponse } from 'src/app/commons/models/page-response';
import * as tableLibrary from '@dima/table-library';
import { ExclusionsFilterService } from '@modules/customers-own-data-exclusion/services/exclusions-filter.service';
import { CustomersOwnDataExclusionService } from '@modules/customers-own-data-exclusion/services/customers-own-data-exclusion.service';
import { CustomersExclusionRulesService } from '@modules/customers-own-data-exclusion/services/customers-exclusion-rules.service';
import { ExclusionsFormService } from '@modules/customers-own-data-exclusion/services/exclusions-form.service';
import { ApiService } from 'src/app/services/api.service';
import { ObjectTypes } from '@dima/table-library';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { ExclusionsDropdownModel } from 'src/app/commons/models/exclusions-dropdown.model';

export class OptionObject {
  value: string
}

@Component({
  selector: 'app-create-edit-exclusion-dialog',
  templateUrl: './create-edit-exclusion-dialog.component.html',
  styleUrls: ['./create-edit-exclusion-dialog.component.scss'],
})

export class CreateEditExclusionDialogComponent extends CreateEditDialogComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('table') table: tableLibrary.DimaTableComponent;
  @ViewChild('paginator') paginator: tableLibrary.DimaTablePaginatorComponent;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('filterForm') filterForm: Form;

  columns = ['supplierDisplayValue', 'invoiceNumber', 'itemId', 'organizationDisplayValue', 'unspscDisplayValue', 'invoiceDate'];

  ghgCategoryOptions: Observable<GhgCategory[]>;
  ghgCategoryUnspscs: GhgCategoryUnspsc[] = [];
  categoryLabel: string;
  submitButtonEnabled = false;
  searchButtonEnabled = false;

  unitOptions: any[] | EntityTrackerError = [];
  unspscsOptionsSubscription: Subscription;
  formInitializationSubscription: Subscription;

  unspscsOptionsInitialized: boolean = false;
  unitOptionsInitialized: boolean = false;
  supplierOptionsInitialized: boolean = false;

  public filteredOrganizations: OrganizationalUnitViewModel[] = [];
  public filteredOrganizationOptionsSub: Observable<
    OrganizationalUnitViewModel[]>;

  filteredOrganizationOptions: OrganizationalUnitViewModel[] = [];

  organizationOptionsInitialized = false;
  translationKey: string;

  initialDataSubscription: Subscription;
  dataSubscription: Subscription;
  filterSubject: Subject<tableLibrary.Filter[]>;

  orderBy = 'order by InvoiceDate desc';

  organizationOptions: OptionObject[] = [];
  invoiceNumberOptions: OptionObject[] = [];
  supplierOptions: OptionObject[] = [];
  unspscOptions: OptionObject[] = [];
  itemIdOptions: OptionObject[] = [];

  pageSize: number = 10;
  pageIndex: number = 0;
  length: number;

  sortColumn: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    public fb: FormBuilder,
    dialogRef: MatDialogRef<CreateEditExclusionDialogComponent>,
    public translate: TranslateService,
    dialogService: DialogService,
    private cdRef: ChangeDetectorRef,
    public tableService: ExclusionsViewTableService,
    public filterService: ExclusionsFilterService,
    public customersOwnDataExclusionService: CustomersOwnDataExclusionService,
    customersExclusionRulesService: CustomersExclusionRulesService,
    public formService: ExclusionsFormService,
    private apiService: ApiService,
    private datePipe: DatePipe
  ) {
    super(editData,
      customersExclusionRulesService,
      formService,
      dialogRef,
      dialogService);

    tableService.setCols();
    this.categoryLabel = translate.instant('customers-own-data.category');
  }

  ngOnInit(): void {
    this.tableService.setData({
      _rowsPerPage: 10,
      _PageNumber: 0,
      pagedProductsDaos: []
    });
    this.apiService.getExclusionsFilters().subscribe(x => {
      this.invoiceNumberOptions = x['InvoiceNumber'].map((y) => <OptionObject>{ value: y });
      this.itemIdOptions = x['ItemId'].map((y) => <OptionObject>{ value: y });
    });
  }

  ngAfterViewInit(): void {
    if (this.table) {
      this.cdRef.detectChanges();

      if (this.editData) {
        this.submitFilters(true);
      }

      this.apiService.getExclusionsDropdowns().subscribe((x: ExclusionsDropdownModel) => {
        this.supplierOptions = x.suppliers as any[];
        this.supplierOptionsInitialized = true;
        this.organizationOptions = x.organizations as any[];
        this.organizationOptionsInitialized = true;
        this.unspscOptions = x.unspscs as any[];
        this.unspscsOptionsInitialized = true;
      });

      this.formService.form
        .controls.OrganizationId
        .valueChanges.pipe(
          debounceTime(1000),
          startWith<string>(''),
          map((value) => {
            return value;
          }),
          switchMap((organization) => {
            return this.organizationOptions = this.organizationOptions.filter(x => x.value.includes(organization));
          })
        );

      this.formService.form.valueChanges.subscribe(() => {
        this.searchButtonEnabled = true;
        this.submitButtonEnabled = false;
      });
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  sortData(sortState: Sort) {
    this.sortColumn = sortState.active == 'supplierId' ? 'SupplierDisplayValue' : sortState.active;
    switch (sortState.direction) {
      case 'asc':
        this.tableService.pagedModel.orderBy = `order by ${this.sortColumn} ASC`;
        break;
      case 'desc':
        this.tableService.pagedModel.orderBy = `order by ${this.sortColumn} DESC`;
        break;
      default:
        this.tableService.pagedModel.orderBy = this.orderBy;
        break;
    }

    this.dataSubscription = this.customersOwnDataExclusionService
      .get(this.tableService.pagedModel)
      .subscribe((data: PageResponse) => {
        this.tableService.setData(data);
      });
  }

  submitFilters(isInitialEditSubmit?: boolean): void {
    let filters: tableLibrary.Filter[] = [];
    let dateFilter = {
      tableId: 'table',
      column: { id: 1, name: 'InvoiceDate', type: ObjectTypes.int, } as tableLibrary.Columns,
      overValue: 0,
      underValue: 0
    } as tableLibrary.filterRange;

    for (const field in this.formService.form.controls) {
      const control = this.formService.form.get(field);
      const controlName = this.getName(control);
      const value = control.value;

      if (controlName?.toLowerCase() == 'datefrom') {
        dateFilter.overValue = +this.datePipe.transform(value, 'yyyyMMdd');
      } else if (controlName?.toLowerCase() == 'dateto') {
        dateFilter.underValue = +this.datePipe.transform(value, 'yyyyMMdd');
      } else {
        if (value) {
          filters.push({
            tableId: 'table',
            column: { id: 1, name: controlName, type: ObjectTypes.string, } as tableLibrary.Columns,
            text: value
          } as tableLibrary.Filter)
        }
      }
    }

    if (dateFilter.overValue || dateFilter.underValue) {
      dateFilter.overValue = dateFilter.overValue == 0 ? null : dateFilter.overValue;
      dateFilter.underValue = dateFilter.underValue == 0 ? null : dateFilter.underValue;
      filters.push(dateFilter);
    }

    this.tableService.filters = filters;

    this.tableService.pagedModel = {
      search: 1,
      pageSize: '10',
      page: '0',
      orderBy: this.orderBy,
      filters: this.tableService.filters || [],
    };

    this.dataSubscription = this.customersOwnDataExclusionService
      .get(this.tableService.pagedModel)
      .subscribe((data: PageResponse) => {
        this.searchButtonEnabled = false;
        if (!isInitialEditSubmit) {
          this.submitButtonEnabled = true;
        }

        this.tableService.setData(data);
        this.length = data.totalFilteredProducts;

        if (this.length >= 1000) {
          this.dialogService.inform({
            message: this.translate.instant(
              'customers-own-data-exclusion.too-many-rows'
            ),
          });
        }
      });
  }

  onSupplierSelectionChange(option: any): void {
    this.formService.form.controls.SupplierCountry
      .setValue(option.supplierCountry ?? '');
  }

  navigation(navigation: tableLibrary.Navigation): void {
    // When the navigation event is triggered (sorting, paginating, filtering, etc.)
    // navigation event parameters are stored in tableService to be used for purposes
    // of the table after different events (creation, editing, deletion, etc.)
    this.tableService.pageSize = navigation.pageSize;
    this.tableService.page = navigation.page;

    if (navigation.sortDirection !== 'Undefined' && navigation.sortColumn?.toLowerCase() !== 'selection') {
      const orderByStatement = `order by ${navigation.sortColumn.charAt(0).toUpperCase() + navigation.sortColumn.slice(1)}`;
      if (navigation.sortDirection === 'Increasing' && navigation.sortColumn) {
        this.orderBy = `${orderByStatement} asc`;
      }
      if (navigation.sortDirection === 'Decreasing' && navigation.sortColumn) {
        this.orderBy = `${orderByStatement} desc`;
      }
    }

    this.tableService.pagedModel = {
      search: 1,
      pageSize: navigation.pageSize.toString(),
      page: navigation.page.toString(),
      orderBy: this.orderBy,
      filters: this.tableService.filters || [],
    };

    this.dataSubscription = this.customersOwnDataExclusionService
      .get(this.tableService.pagedModel)
      .subscribe((data: PageResponse) => {
        this.tableService.setData(data);
        this.length = data.totalFilteredProducts;
      });
  }

  submitForm(): void {
    if (!this.editData) {
      this.create();
    } else {
      this.update();
    }
  }

  private getName(control: AbstractControl): string | null {
    let group = <FormGroup>control.parent;

    if (!group) {
      return null;
    }

    let name: string;

    Object.keys(group.controls).forEach(key => {
      let childControl = group.get(key);

      if (childControl !== control) {
        return;
      }

      name = key;
    });

    return name;
  }

  onPageEvent(event: PageEvent) {
    this.tableService.pagedModel.page = event.pageIndex.toString();
    this.tableService.pagedModel.pageSize = event.pageSize.toString();

    this.dataSubscription = this.customersOwnDataExclusionService
      .get(this.tableService.pagedModel)
      .subscribe((data: PageResponse) => {
        this.tableService.setData(data);
      });
  }

  ngOnDestroy(): void {
    if (this.dataSubscription)
      this.dataSubscription.unsubscribe();

    if (this.initialDataSubscription)
      this.initialDataSubscription.unsubscribe();

    this.filterService.resetFilters();

    this.formService.resetEditData();
    this.formService.resetForm();
    this.formService.resetProductDataChange();

    this.tableService.setData([]);
    // this.tableElement.nativeElement.remove();
  }
}
