import { Component, ElementRef, Host, OnInit, ViewChild } from '@angular/core';
import { CustomersOwnDataCsvService } from '@modules/customers-own-data/services/customers-own-data-csv.service';
import { CustomersOwnDataGetService, CustomersOwnDataService } from '@modules/customers-own-data/services/customers-own-data.service';
import { FilterService } from '@modules/customers-own-data/services/filter.service';
import { TableService } from '@modules/customers-own-data/services/table.service';
import { InformationDialogService } from '@modules/shell/services/information-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { DataViewComponent } from 'src/app/commons/components/data-view/data-view.component';
import { PageResponse } from 'src/app/commons/models/page-response';
import { FileService } from 'src/app/commons/services/file.service';
import { SnackbarService } from 'src/app/commons/services/snackbar.service';
import { CustomDialogService } from 'src/app/commons/dialog/services/custom-dialog.service';
import { CreateEditCodDialogComponent } from '../create-edit-cod-dialog/create-edit-cod-dialog.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-cod-action-menu',
  templateUrl: './cod-action-menu.component.html',
  styleUrls: ['./cod-action-menu.component.scss'],
  providers: [DataViewComponent,
    { provide: 'ITableService', useExisting: TableService },
    { provide: 'IGetDataService', useExisting: CustomersOwnDataGetService },
    { provide: 'IDataService', useExisting: CustomersOwnDataService },
    { provide: 'IFilterService', useExisting: FilterService },
  ]
})
export class CodActionMenuComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  translationKey = 'customers-own-data';
  parentComponent: DataViewComponent;
  isDeleteButtonDisabled = true;
  private customDialogService: CustomDialogService;

  constructor(@Host() parent: DataViewComponent,
    public translateService: TranslateService,
    dialogService: DialogService,
    private customersOwnDataCsvService: CustomersOwnDataCsvService,
    private informationDialogService: InformationDialogService,
    private snackbarService: SnackbarService,
    private fileService: FileService,
    private tableService: TableService,
    private dataService: CustomersOwnDataService,
    private getDataService: CustomersOwnDataGetService,
    private filterService: FilterService
  ) {
    this.customDialogService = new CustomDialogService(dialogService, getDataService, dataService, tableService, filterService, snackbarService, translateService);
    this.customDialogService.orderBy = 'order by TransactionDate asc, SupplierDisplayValue asc, ProductID asc';
    this.parentComponent = parent;
  }

  ngOnInit() {
    this.customDialogService.dialogComponent = CreateEditCodDialogComponent;
    this.tableService.anyRowSelectedSubject.subscribe(x => {
      this.isDeleteButtonDisabled = !x;
    });

    this.customDialogService.successfulDeleteSubject.subscribe(succeed => {
      if (succeed) {
        this.isDeleteButtonDisabled = true;
      }
    });
  }

  uploadDatasource(fileInput: any) {
    let file = fileInput.target.files[0];
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.fileService.uploadDatasource(formData)
      .subscribe(
        response => {
          if (response) {
            this.customersOwnDataCsvService.importCsv(file.name).subscribe(x => {
              this.getDataService
                .get({
                  search: 1,
                  filters: this.tableService.filters,
                  pageSize: this.tableService.pageSize.toString(),
                  orderBy: this.tableService.pagedModel?.orderBy,
                  page: this.tableService.page.toString()
                }).subscribe((data: PageResponse) => {
                  this.tableService.setData(data);
                  this.filterService.resetFilters();
                  this.filterService.initializeFilters();
                });
              this.snackbarService.openSnackBar('customers-own-data.data-imported');
            },
              error => {
                console.error('error', error)
                this.informationDialogService.showCommonHttpError(error, 'CSV import error');
              });
          }
        },
        error => {
          console.error('error', error)
          this.informationDialogService.showCommonHttpError(error, 'File uploading error')
        });

    this.clearChosenFile();
  }

  createDialog(): void {
    this.customDialogService.createDialog();
  }

  deleteSelected() {
    this.customDialogService.deleteDialog(this.tableService.selectedRows.map(x => x.id));
  }

  private clearChosenFile() {
    this.fileInput.nativeElement.value = null;
  }
}
