import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LanguageDto } from '../commons/models/language-dto';
import { CurrentLanguageService } from '../services/current-language.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageResolver  {
  private langRouteParam: LanguageDto;

  constructor(private currentLanguageService: CurrentLanguageService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<LanguageDto | undefined> {
    this.langRouteParam = this.currentLanguageService.getLangFromLocalStorage()
      ? { value: this.currentLanguageService.getLangFromLocalStorage().value }
      : undefined;
    return of(this.langRouteParam);
  }
}
