import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

    parse(value: any): Date | null {
        if (typeof value === 'string' && /^0001-01-01$/.test(value)) {
          return new Date(1, 0, 1);
        }
    
        return super.parse(value);
      }
}