import { DialogService } from 'src/app/services/dialog.service';
import { ComponentType } from '@angular/cdk/portal';
import { DialogResultStatus } from 'src/app/commons/existing-record-dialog/dialog-result-status';
import { PageResponse } from 'src/app/commons/models/page-response';
import { SnackbarService } from 'src/app/commons/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IDataService, IGetDataService } from '../../components/data-view/data-service.interface';
import { ITableService } from '../../components/data-view/table-service.interface';
import { IFilterService } from '../../components/data-view/filter-service.interface';

export class CustomDialogService {
  dialogComponent: ComponentType<any>;
  orderBy: string;
  translationKey: string = 'customers-own-data';
  deleteSubscription: Subscription;
  successfulDeleteSubject = new BehaviorSubject<boolean>(false);
  translateServiceBase: TranslateService;

  constructor(private dialogService: DialogService,
    public getDataService: IGetDataService,
    public dataService: IDataService,
    public tableService: ITableService,
    public filterService: IFilterService,
    private snackbarService: SnackbarService,
    public translateService: TranslateService) {
    this.translateServiceBase = translateService;
  }

  createDialog() {
    this.dialogService
      .create(this.dialogComponent)
      .afterClosed()
      .subscribe((res) => {
        if (res !== DialogResultStatus.CANCEL) {
          this.getDataService
            .get({
              search: 1,
              filters: this.tableService.filters,
              pageSize: this.tableService.pageSize.toString(),
              orderBy: this.orderBy,
              page: this.tableService.page.toString()
            })
            .subscribe((data: PageResponse) => {
              this.tableService.setData(data);
              this.filterService.resetFilters();
              this.filterService.initializeFilters();
              this.tableService.totalCount = data.totalFilteredProducts;
              this.snackbarService.openSnackBar(
                res === DialogResultStatus.UPDATE ? `${this.translationKey}.updated` : `${this.translationKey}.created`
              );
            });
        }
      });
  }

  editDialog(row) {
    this.dialogService
      .edit(row, this.dialogComponent)
      .afterClosed()
      .subscribe((res) => {
        if (res !== DialogResultStatus.CANCEL) {
          this.getDataService
            .get({
              search: 1,
              filters: this.tableService.filters,
              pageSize: this.tableService.pageSize.toString(),
              orderBy: this.orderBy,
              page: this.tableService.page.toString()
            })
            .subscribe((data: PageResponse) => {
              this.tableService.setData(data);
              this.filterService.resetFilters();
              this.filterService.initializeFilters();
              this.tableService.totalCount = data.totalFilteredProducts;
              this.snackbarService.openSnackBar(
                `${this.translationKey}.updated`
              );
            });
        }
      });
  }

  deleteDialog(param: number[]) {
    this.dialogService
      .deleteDialog({
        message: this.translateService.instant(
          `${this.translationKey}.confirmatory-text`
        ),
        confirmText: this.translateService.instant(
          `${this.translationKey}.yes`
        ),
        cancelText: this.translateService.instant(
          `${this.translationKey}.no`
        ),
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === DialogResultStatus.CONFIRM) {
          this.deleteSubscription = this.dataService
            .delete(param)
            .subscribe(
              () => {
                this.tableService.clearTableSelection.next(true);
              },
              () => { },
              () => {
                this.getDataService
                  .get({
                    search: 1,
                    filters: this.tableService.filters,
                    pageSize: this.tableService.pageSize.toString(),
                    orderBy: this.orderBy,
                    page: this.tableService.page.toString()
                  })
                  .subscribe((data: PageResponse) => {
                    this.tableService.setData(data);
                    this.snackbarService.openSnackBar(
                      `${this.translationKey}.deleted`
                    );
                    this.successfulDeleteSubject.next(true);
                  });
              }
            );
        }
      });
  }
}
