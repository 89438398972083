<div class="container gx-0">
  <div class="row">
    <div class="col-10 mt-3">
      <p class="fw-bold fs-30">
        {{ 'pdf-exports.pdf-order-list-details' | translate }}
      </p>
    </div>
    <div class="col-2 mt-3">
      <div class="dialog__close" (click)="close()">
        <mat-icon>close</mat-icon>
      </div>
    </div>
  </div>
  <div class="row justify-content-around align-items-stretch mt-3">
    <div class="col-12">
      <div mat-dialog-content>

        <div class="order-list">

          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="reportName">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'pdf-exports.report-name' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.reportName }}</td>
            </ng-container>

            <ng-container matColumnDef="orderDate">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'pdf-exports.order-date' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.orderDate | date: 'dd.MM.y, HH:mm' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'pdf-exports.status' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.finished">
                  {{ 'pdf-exports.ordered' | translate }}
                </div>
                <div *ngIf="element.finished && !element.downloaded">
                  {{ 'pdf-exports.delivered' | translate }}
                </div>
                <div *ngIf="element.finished && element.downloaded">
                  {{ 'pdf-exports.downloaded' | translate }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="fileDownload">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'pdf-exports.download-file' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.finished && !element.downloaded">
                  <div class="position-relative">
                    <button color="primary" class="download-btn" matTooltip="Download PDF" aria-label=""
                      (click)="downloadFile(element)">
                      <mat-icon class="position-absolute">download</mat-icon>
                    </button>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow colspan="4">
              <td class="mat-cell" colspan="4">
                {{ 'pdf-exports.no-data' | translate }}
              </td>
            </tr>
          </table>
        </div>
      </div>


      <div mat-dialog-actions [align]="'end'">

        <app-open-pdf-export-button-bar></app-open-pdf-export-button-bar>

        <button mat-flat-button class="closeBtn" color="primary" (click)="close()">
          {{ 'pdf-exports.close' | translate }}
        </button>
      </div>

    </div>