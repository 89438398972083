import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class XbrlService {

  constructor(private apiService: ApiService) { }

  downloadXbrlFile(fiscalYearId: number): void {
    this.apiService.generateXbrlForFiscalYear(fiscalYearId).subscribe(blob => {
      saveAs(blob, 'xbrl.xml');
    });
  }
}
