<mat-form-field appearance="outline" class="example-header search-bar pt-3">
  <mat-label style="margin-left: 0.75rem;">{{ 'general.filter' | translate }}</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'general.filter-placeholder' | translate }}" />
  <mat-icon fontIcon="search" matSuffix></mat-icon>
</mat-form-field>

<div class="example-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" matSort [matSortActive]="orderByColumn" matSortDirection="asc" >
    <ng-container matColumnDef="{{ column.key }}" *ngFor="let column of columnsConfigs">
      <th class="pt-4" [ngStyle]="{ width: column.width }" mat-header-cell mat-sort-header *matHeaderCellDef disableClear>
        {{ column.name | async }}
      </th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{ width: column.width }">

        <ng-container *ngIf="
            column.key != 'initiativeStatus' &&
            column.key != 'initiativeCreateDate' &&
            column.key != 'initiativePriority' &&
            column.key != 'totalEmissionChange' &&
            column.key != 'totalLineAmountChange'
          ">

          {{ element[column.key] || '' }}
        </ng-container>
        <ng-container *ngIf="column.key == 'initiativeStatus'">
          {{ element[column.key]?.value || '' }}
        </ng-container>
        <ng-container *ngIf="column.key == 'initiativePriority'">
          {{ element[column.key]?.value || '' }}
        </ng-container>

        <ng-container *ngIf="column.key == 'initiativeCreateDate'">
          {{ (element[column.key] | date: 'd. MMM yyyy') || '' }}
        </ng-container>

        <ng-container *ngIf="column.key == 'totalEmissionChange'">
          {{ (element[column.key] | number: '1.0-0') || '' }}
        </ng-container>

        <ng-container *ngIf="column.key == 'totalLineAmountChange'">
          {{ (element[column.key] | number: '1.0-0') || '' }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="expand row" (click)="expand(element.index)">
          <mat-icon *ngIf="!expandedRows[element.index]" fontIcon="keyboard_arrow_down"></mat-icon>
          <mat-icon *ngIf="expandedRows[element.index]" fontIcon="keyboard_arrow_up"></mat-icon>
        </button>

      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">

      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
        <mat-expansion-panel hideToggle [expanded]="expandedRows[element.index]">
          <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: element }"></ng-container>
        </mat-expansion-panel>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr mat-row *matRowDef="let element; let i = index; columns: columnsToDisplayWithExpand" class="example-element-row"
      [class.example-expanded-row]="expandedRows[i]"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>
