import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-scenarios-and-templates-wrapper',
    templateUrl: './scenarios-and-templates-wrapper.component.html',
    styleUrls: ['./scenarios-and-templates-wrapper.component.scss'],
    standalone: false
})
export class ScenariosAndTemplatesWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
