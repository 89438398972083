import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(
    private _snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  openSnackBar(translationKey, action?) {
    let message = this.translateService.instant(translationKey);
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['snackbar-colors'],
    });
  }
}
