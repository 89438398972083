import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { EntityTrackerError } from '../commons/models/entity-tracker-error';
import { catchError } from 'rxjs/operators';
import { ROUTENAMES } from '../app-routing.names';
import { TemplatesService } from '@modules/scenarios/services/templates/templates.service';
import { CalculateDto } from '../commons/models/scenario-dto';

@Injectable({
  providedIn: 'root',
})
export class TemplatesResolver
  implements Resolve<CalculateDto[] | EntityTrackerError>
{
  constructor(
    private apiService: ApiService,
    private templatesService: TemplatesService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CalculateDto[] | EntityTrackerError> {
    return this.templatesService.getTemplates().pipe(
      catchError(() => {
        this.router.navigate([ROUTENAMES.UNAUTHORIZED]);
        return EMPTY;
      })
    );
  }
}
