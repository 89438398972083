import { Injectable } from '@angular/core';
import { CurrentLanguageService } from 'src/app/services/current-language.service';

@Injectable({
  providedIn: 'root'
})
export class DecimalValueService {
  public databaseDecimalLocation = 'en';

  constructor(private currentLanguageService: CurrentLanguageService) { }

  public convertToValidPrice(price: any): string {
    if (!price) {
      return null;
    }
    if (this.currentLanguageService.lang === this.databaseDecimalLocation) {
      return price.replace(',', '');
    } else {
      price = price.replaceAll('.', '#');
      price = price.replace(',', '.');
      price = price.replaceAll('#', ',');
      return price;
    }
  }
}
