import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ɵFormGroupValue,
  ɵTypedOrUntyped,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CreateEditPseDialogComponent } from '@modules/product-specific-emissions/components/create-edit-pse-dialog/create-edit-pse-dialog.component';
import { UserService } from '@modules/shell/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import {
  OverWriteStatus,
  ProductSpecificEmissionDto,
  VerifiedStatus,
} from '../../../commons/models/product-specific-emission-dto';
import { DateTime } from 'luxon';
import { IFormService } from 'src/app/commons/models/form-service.interface';
import { PseUniqueKeyModel } from 'src/app/commons/models/pse-unique-key-model';
import { BehaviorSubject } from 'rxjs';
import { DecimalValueService } from 'src/app/commons/services/decimal-value.service';
import { CurrentLanguageService } from 'src/app/services/current-language.service';

@Injectable({
  providedIn: 'root',
})
export class ProductSpecificEmissionsFormService implements IFormService {
  form!: FormGroup;
  id: number;
  dialogRef: MatDialogRef<CreateEditPseDialogComponent>;
  isProductDataChanged: boolean = false;
  isDateChanged: boolean = false;
  isDataChanged: boolean = false;
  editData: ProductSpecificEmissionDto;
  formInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  supplierOptions: any[] = [];
  supplierItemOptions: any[] = [];
  supplierItemUnitsOptions: any[] = [];

  public fieldLengths = {
    SupplierID: 50,
    SupplierCountry: 50,
    ItemID: 50,
    ItemName: 100,
    ItemDescription: 500,
    UnitCode: 50,
    EmissionCo2Kg: 50,
    Method: 250,
    LinkToMethod: 1000,
    EmissionValidFrom: 50,
    EmissionValidTo: 50,
  };

  private initialFormValue: ɵTypedOrUntyped<any, ɵFormGroupValue<any>, any>;
  private initialDate: any;
  constructor(
    public fb: FormBuilder,
    public userService: UserService,
    private translateService: TranslateService,
    private decimalValueService: DecimalValueService,
    private currentLanguageService: CurrentLanguageService
  ) { }

  setEditData(editData) {
    if (editData.supplierDisplayValue == this.translateService.instant('product-specific-emissions.empty-supplier')) {
      editData.supplierId = null;
      editData.itemId = null;
      editData.unitCode = null;
    }
    if (editData.itemIdDisplayValue == this.translateService.instant('product-specific-emissions.item-unknown')) {
      editData.itemId = null;
      editData.unitCode = null;
    }
    this.editData = editData;
  }

  resetEditData() {
    this.editData = undefined;
  }

  resetForm() {
    this.form.reset();
  }

  setDialogRef(dialogRef) {
    this.dialogRef = dialogRef;
  }

  initializeForm(): void {
    this.form = this.fb.group({
      SupplierID: ['', [Validators.required, Validators.maxLength(this.fieldLengths.SupplierID)]],
      SupplierCountry: ['', [Validators.required, Validators.maxLength(this.fieldLengths.SupplierCountry)]],
      ItemID: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(this.fieldLengths.ItemID)]],
      ItemName: ['', Validators.maxLength(this.fieldLengths.ItemName)],
      ItemDescription: ['', Validators.maxLength(this.fieldLengths.ItemDescription)],
      UnitCode: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(this.fieldLengths.UnitCode)]],
      EmissionCo2Kg: [0, [Validators.required, Validators.maxLength(this.fieldLengths.EmissionCo2Kg), Validators.pattern(this.currentLanguageService.lang == 'da' ? /^-?\d+(?:.\d{3})*(?:\,\d+)?$/ : /^-?\d+(?:,\d{3})*(?:\.\d+)?$/)]],
      Method: ['', Validators.maxLength(this.fieldLengths.Method)],
      LinkToMethod: ['', Validators.maxLength(this.fieldLengths.LinkToMethod)],
      MethodDocument: [''],
      EmissionValidFrom: [new Date('1900-01-01'), Validators.required],
      EmissionValidTo: [new Date('2999-12-31'), Validators.required],
      CreationDate: [{ value: new Date(), disabled: true }, Validators.required],
      CreatedBy: [{ value: this.userService.currentUser.email, disabled: true }, Validators.required],
      ModifyDate: [{ value: new Date(), disabled: true }, Validators.required],
      ModifiedBy: [{ value: this.userService.currentUser.email, disabled: true }, Validators.required]
    });

    if (this.editData) {
      this.form.patchValue({
        SupplierID: this.editData.supplierDisplayValue != this.translateService.instant('product-specific-emissions.empty-supplier') ? this.editData.supplierId : '',
        SupplierCountry: this.editData.countryId,
        ItemID: this.editData.itemIdDisplayValue != this.translateService.instant('product-specific-emissions.unknown-item') ? this.editData.itemId : '',
        ItemName: this.editData.itemName,
        ItemDescription: this.editData.itemDescription,
        UnitCode: this.editData.unitCode,
        EmissionCo2Kg: this.decimalValueService.convertToValidPrice(this.editData.emissionCo2Kg) ?? '0',
        EmissionValidFrom: this.editData.emissionValidFrom,
        EmissionValidTo: this.editData.emissionValidTo,
        Method: this.editData.method,
        LinkToMethod: this.editData.linkToMethod,
        CreationDate: this.editData.creationDate,
        CreatedBy: this.editData.createdBy,
        ModifyDate: this.editData.modifyDate,
        ModifiedBy: this.userService.currentUser.email,
        Verified: this.editData.verified
      });
    }

    this.formInitialized.next(true);

    this.initialFormValue = this.form.getRawValue();

    this.initialDate = this.initialFormValue['EmissionValidFrom'];
    this.form.valueChanges.subscribe(() => {
      this.checkProductDataChanges();
    });
  }

  onDateChange(event) {
    let initialDate = DateTime.fromJSDate(this.initialDate).toISODate();
    let eventValue = DateTime.fromJSDate(event.value).toISODate();

    if (initialDate != eventValue) {
      this.isDateChanged = true;
    } else {
      this.isDateChanged = false;
    }
  }

  checkIfProductDataChanged() {
    if (this.isProductDataChanged) {
      return true;
    }
    return false;
  }

  checkProductDataChanges() {
    const isSEmissionFromChanged = this.isFieldChanged('EmissionValidFrom');
    const isSupplierIDChanged = this.isFieldChanged('SupplierID');
    const isCountryIDChanged = this.isFieldChanged('SupplierCountry');
    const isItemIDChanged = this.isFieldChanged('ItemID');
    const isUnitCodeChanged = this.isFieldChanged('UnitCode');

    this.isProductDataChanged = false;

    if (
      isSEmissionFromChanged ||
      isSupplierIDChanged ||
      isCountryIDChanged ||
      isItemIDChanged ||
      isUnitCodeChanged
    ) {
      this.isProductDataChanged = true;
    }
  }

  isFieldChanged(fieldName: string): boolean {
    const currentValue = this.form.get(fieldName).value;
    const initialValue = this.initialFormValue[fieldName];

    return currentValue !== initialValue;
  }

  disableProductData() {
    this.disableField('SupplierID');
    this.disableField('SupplierCountry');
    this.disableField('ItemID');
    this.disableField('UnitCode');
    this.disableField('EmissionValidFrom');
  }

  disableField(fieldName: string): void {
    let field = this.form.get(fieldName);
    field.disable();
  }

  getSelectedOverWriteStatus(status) {
    let possibleStatuses: OverWriteStatus[] = [
      {
        flag: true,
        translation: this.translateService.instant(
          'product-specific-emissions.can-overwrite'
        ),
      },
      {
        flag: false,
        translation: this.translateService.instant(
          'product-specific-emissions.cannot-overwrite'
        ),
      },
    ];
    let selectedStatus = possibleStatuses.find((el) => el.flag == status);

    return selectedStatus.flag;
  }
  getSelectedVerifiedStatus(status) {
    let possibleStatuses: VerifiedStatus[] = [
      {
        flag: true,
        translation: this.translateService.instant(
          'product-specific-emissions.can-overwrite'
        ),
      },
      {
        flag: false,
        translation: this.translateService.instant(
          'product-specific-emissions.cannot-overwrite'
        ),
      },
    ];
    let selectedStatus = possibleStatuses.find((el) => el.flag == status);

    return selectedStatus.flag;
  }

  getSelectedActiveStatus(status) {
    let possibleStatuses: VerifiedStatus[] = [
      {
        flag: true,
        translation: this.translateService.instant(
          'product-specific-emissions.active'
        ),
      },
      {
        flag: false,
        translation: this.translateService.instant(
          'product-specific-emissions.inactive'
        ),
      },
    ];
    let selectedStatus = possibleStatuses.find((el) => el.flag == status);

    return selectedStatus.flag;
  }

  resetProductDataChange() {
    this.isProductDataChanged = false;
    this.isDateChanged = false;
  }

  getUniqueKeyModel(): PseUniqueKeyModel {
    return <PseUniqueKeyModel>{
      id: this.editData?.id ?? null,
      supplierId: this.form.getRawValue().SupplierID,
      countryId: this.form.getRawValue().SupplierCountry,
      itemId: this.form.getRawValue().ItemID,
      unitCode: this.form.getRawValue().UnitCode,
      emissionValidFrom: this.form.getRawValue().EmissionValidFrom,
      emissionValidTo: this.form.getRawValue().EmissionValidTo
    };
  }

  private prepareValues() {
    const supplierCountry = this.form.getRawValue().SupplierCountry;
    const supplierId = this.form.getRawValue().SupplierID;
    return {
      supplierId: !supplierId || supplierId === '' ? null : supplierId,
      countryId: supplierCountry.value === '' ? null : supplierCountry,
      itemId: this.form.getRawValue().ItemID,
      itemName: this.form.getRawValue().ItemName,
      itemDescription: this.form.getRawValue().ItemDescription,
      unitCode: this.form.getRawValue().UnitCode,
      emissionCo2Kg: this.decimalValueService.convertToValidPrice(this.form.getRawValue()?.EmissionCo2Kg) ?? '0',
      method: this.form.getRawValue().Method,
      linkToMethod: this.form.getRawValue().LinkToMethod,
      methodDocument: this.editData?.methodDocument ?? null,
      documentFileName: '',
      emissionValidFrom: this.form.getRawValue().EmissionValidFrom,
      emissionValidTo:
        this.form.value.EmissionValidTo,
      verified: this.editData?.verified ?? false,
      tenantId: this.editData?.tenantId ?? null,
      creationDate: new Date(),
      modifyDate: new Date()
    }
  }

  prepareUpdateValues(id?: number) {
    const baseObject = this.prepareValues();
    return Object.assign(
      {
        id: id ?? this.editData?.id,
      }, baseObject) as ProductSpecificEmissionDto;
  }

  prepareCreateValues() {
    const baseObject = this.prepareValues();
    return Object.assign({
      id: 0,
    }, baseObject) as ProductSpecificEmissionDto;
  }
}