import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Filter } from '@dima/table-library';
import { PBIReportExportService } from '@modules/report/services/pbi-report-export.service';
import { SnackbarService } from '../../../../commons/services/snackbar.service';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../../services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { PdfOrderListComponent } from '@modules/report/components/pdf-order-list/pdf-order-list.component';
import { UserService } from '@modules/shell/services/user.service';
import { ActivityService } from 'src/app/services/activity.service';
import { ActivityEventType, UserActivity } from 'src/app/commons/models/activity.model';

@Component({
  selector: 'app-open-pdf-export-button-bar',
  templateUrl: './open-pdf-export-button.component.html',
  styleUrls: ['./open-pdf-export-button.component.scss'],
})
export class OpenPdfExportButtonComponent {
  sidebarOpen: boolean = false;
  @Input() type: string;
  @Input() reportId: string;
  @Input() editModeButton: boolean = false;
  @Input() viewModeButton: boolean = false;
  @Input() copyButton: boolean = false;
  @Input() saveButton: boolean = false;
  @Input() deleteButton: boolean = false;
  @Input() reportPublished: boolean = false;
  @Output() newReportName = new EventEmitter<string>();
  @Output() publish = new EventEmitter<boolean>();
  @Output() action = new EventEmitter<string>();
  filter: Filter;
  @Input() showExportBtn: boolean = false;
  private orderPdfSubscription: Subscription;

  constructor(
    private exportService: PBIReportExportService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private activityService: ActivityService,
    private userService: UserService,
    public dialogRef: MatDialogRef<PdfOrderListComponent>,
  ) { }

  async openOrderPdfExportDialogAsync() {
    this.dialogService
      .pdfOrderConfirmDialog({
        message: this.translateService.instant(
          'pdf-exports.confirm-export-text'
        ),
        confirmText: this.translateService.instant('pdf-exports.yes'),
        cancelText: this.translateService.instant('pdf-exports.no'),
      })
      .afterClosed()
      .subscribe(async (res) => {
        if (res === 'confirm') {
          this.orderPdfSubscription = (await this.exportService
            .pBIReportExportPostExport())
            .subscribe(
              () => { },
              () => { },
              () => {
                this.activityService.sendActivity(new UserActivity('PDF Export ordered', this.userService.currentUser.username, ActivityEventType.PdfExportOrdered));
                this.dialogRef.close();
                this.snackbarService.openSnackBar('pdf-exports.pdf-ordered');
              }
            );
        }
      });
  }
}
