<form [formGroup]="formService.form">
    <div class="container gx-0">
        <div class="row">
            <div class="col-12 mt-3 mb-3">
                <p class="fw-bold fs-30">
                    {{ 'navigation.fiscal-year' | translate }}
                    {{formService.form.invalid}}
                </p>
            </div>
        </div>
        <div class="row justify-content-around align-items-stretch mt-3">
            <div>
                <div mat-dialog-content>
                    <div class="row main-row-dialog">
                        <div class="col-12 mt-3">
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'fiscal-year.start-date' | translate }}</mat-label>
                                        <input formControlName="StartDate" matInput [matDatepicker]="picker1"
                                            placeholder="{{ 'fiscal-year.start-date' | translate }}" />
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'fiscal-year.end-date' | translate }}</mat-label>
                                        <input formControlName="EndDate" matInput [matDatepicker]="picker2"
                                            placeholder="{{ 'fiscal-year.end-date' | translate }}" />
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'fiscal-year.description' | translate }}</mat-label>
                                        <input formControlName="Description" matInput
                                            placeholder="{{ 'fiscal-year.description' | translate }}" />
                                    </mat-form-field>
                                </div>
                                <div class="col-6 default-year">
                                    <mat-checkbox formControlName="DefaultYear">{{ 'fiscal-year.default-year' |
                                        translate }}</mat-checkbox>
                                </div>
                                <!-- <div class="col-6">
                    <mat-form-field appearance="fill">
                      <mat-label>{{ 'fiscal-year.creation-date' | translate }}</mat-label>
                      <input formControlName="creationDate" matInput [matDatepicker]="picker3" placeholder="{{ 'fiscal-year.creation-date' | translate }}" />
                      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                      <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="fill">
                      <mat-label>{{ 'fiscal-year.created-by' | translate }}</mat-label>
                      <input formControlName="createdBy" matInput placeholder="{{ 'fiscal-year.created-by' | translate }}" />
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="fill">
                      <mat-label>{{ 'fiscal-year.modify-date' | translate }}</mat-label>
                      <input formControlName="modifyDate" matInput [matDatepicker]="picker4" placeholder="{{ 'fiscal-year.modify-date' | translate }}" />
                      <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                      <mat-datepicker #picker4></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="fill">
                      <mat-label>{{ 'fiscal-year.modified-by' | translate }}</mat-label>
                      <input formControlName="modifiedBy" matInput placeholder="{{ 'fiscal-year.modified-by' | translate }}" />
                    </mat-form-field> -->
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div mat-dialog-actions [align]="'end'">
                    <button mat-raised-button (click)="cancel()" [mat-dialog-close]="false">
                        {{ 'fiscal-year.cancel' | translate }}
                    </button>
                    <button mat-raised-button color="primary" [disabled]="formService.form.invalid"
                        (click)="ctaScenario()">
                        {{ 'fiscal-year.save' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>