import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-amount-indicator',
    templateUrl: './amount-indicator.component.html',
    styleUrls: ['./amount-indicator.component.scss'],
    standalone: false
})
export class AmountIndicatorComponent implements OnInit {
  @Input() amount: number;

  constructor() {}

  ngOnInit(): void {}
}
