import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { OpenIDCService } from './open-idc.service';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  private idleSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  idle$: Observable<boolean> = this.idleSource.asObservable();
  private userInteractionTime: DateTime = DateTime.now();
  private checkSubscription: Subscription;
  readonly INTERVALCHECKSECONDS = 10; // interval the service should check idletime
  readonly IDLEMAXTIMESECONDS = 60 * 30; // how long time can a user be idle before logout

  idleSubscription: Subscription;

  constructor(private openIDCService: OpenIDCService) {
    this.initiateIdle();
  }

  private initiateIdle() {
    // subscribe on isAuthenticate and starte idleService if true else stop it
    this.openIDCService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.trackEvents();
        this.checkIdle();
        this.idleSubscription = this.idle$.subscribe((result) => {
          if (result) {
            this.openIDCService.logout();
          }
        });
      } else {
        if (this.idleSubscription) {
          this.idleSubscription.unsubscribe();
        }
        if (this.checkSubscription) {
          this.checkSubscription.unsubscribe();
        }
        this.stopTrackEvents();
      }
    });
  }

  private checkIdle() {
    this.checkSubscription = interval(
      1000 * this.INTERVALCHECKSECONDS
    ).subscribe(() => {
      if (
        DateTime.now().diff(this.userInteractionTime, 'seconds').seconds >
        this.IDLEMAXTIMESECONDS
      ) {
        this.idleSource.next(true);
      }
    });
  }

  private updateUserInteractionTime() {
    this.userInteractionTime = DateTime.now();
  }

  private trackEvents() {
    window.addEventListener(
      'mousemove',
      this.updateUserInteractionTime.bind(this)
    );
    window.addEventListener(
      'scroll',
      this.updateUserInteractionTime.bind(this)
    );
    window.addEventListener(
      'keydown',
      this.updateUserInteractionTime.bind(this)
    );
    window.addEventListener('click', this.updateUserInteractionTime.bind(this));
  }

  private stopTrackEvents() {
    window.removeEventListener(
      'mousemove',
      this.updateUserInteractionTime.bind(this)
    );
    window.removeEventListener(
      'scroll',
      this.updateUserInteractionTime.bind(this)
    );
    window.removeEventListener(
      'keydown',
      this.updateUserInteractionTime.bind(this)
    );
    window.removeEventListener(
      'click',
      this.updateUserInteractionTime.bind(this)
    );
  }
}
