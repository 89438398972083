import { Injectable } from '@angular/core';
import { FilterOption } from '@dima/table-library';
import { ApiService } from '../../../services/api.service';
import { TableService } from './table.service';
import { IFilterService } from 'src/app/commons/components/data-view/filter-service.interface';
import { TranslateService } from '@ngx-translate/core';
import * as tableLibrary from '@dima/table-library';
import { CurrentLanguageService } from 'src/app/services/current-language.service';

@Injectable({
  providedIn: 'root',
})
export class FilterService implements IFilterService {
  public apiFilters = {
    AveragePricePerUnitCalc: [],
    AveragePricePerUnitEntered: [],
    Unit: [],
    UnspscDisplayValue: [],
    SupplierDisplayValue: [],
    UseEnteredPrice: [],
    CreationDate: [],
    CreatedBy: [],
    ModifyDate: [],
    ModifiedBy: [],
  };

  useText = '';

  constructor(
    private apiService: ApiService,
    private tableService: TableService,
    private translate: TranslateService,
    private languageService: CurrentLanguageService
  ) { }

  async initializeFilters(): Promise<void> {
    const filters = await this.apiService.getCapFilters().toPromise();

    for (const [key, value] of Object.entries(filters)) {
      filters[key].map((el) => {
        this.apiFilters[key].push({
          value: el,
        });
      });

      let column = this.tableService?.cols?.find((col) => {
        let colName = `${key[0].toLowerCase() + key.slice(1)}`;
        return col.name == colName;
      });

      if (key == 'Verified') {
        column.options = [
          { value: 'true', checked: false },
          { value: 'false', checked: false },
        ] as FilterOption[];
      }
      else if (key == 'Overwrite') {
        column.options = [
          { value: 'true', checked: false },
        ] as FilterOption[];
      }
      else if (key == 'UseEnteredPrice') {
        column.options = [
          { value: this.translate.instant('general.use'), checked: false },
          { value: this.translate.instant('general.dont-use'), checked: false },
        ] as FilterOption[];
      } else {
        column.options = [...this.apiFilters[key]];
      }

      this.tableService.table?.applyFilter({
        column: column,
        tableId: 'table',
        text: '',
      });
    }
  }

  async initializeTranslations(): Promise<void> {
    this.useText = this.translate.instant('general.use');

    await this.languageService.languageChanges.subscribe(async x => {
      if (x) {
        this.useText = this.translate.instant('general.use');
        this.tableService.filters = [];
        this.resetFilters();
      }
    });
  }

  public setFilter(filter: tableLibrary.Filter): string {
    if (filter?.column?.name == 'useEnteredPrice') {
      if (filter.text == this.useText) {
        filter.text = 'true';
      } else {
        filter.text = 'false';
      }
    }

    return filter.text;
  }

  resetFilters(): void {
    this.apiFilters = {
      AveragePricePerUnitCalc: [],
      AveragePricePerUnitEntered: [],
      Unit: [],
      UnspscDisplayValue: [],
      SupplierDisplayValue: [],
      UseEnteredPrice: [],
      CreationDate: [],
      CreatedBy: [],
      ModifyDate: [],
      ModifiedBy: [],
    };
  }
}
