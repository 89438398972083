import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../commons/dialog/dialog.component';
import { DialogConfigData } from '../commons/models/dialog-config-data';
import { CreateEditInitiativeDialogComponent } from '@modules/initiatives/components/create-edit-initiative-dialog/create-edit-initiative-dialog.component';
import { CreateEditScenarioDialogComponent } from '@modules/scenarios/components/create-edit-scenario-dialog/create-edit-scenario-dialog.component';
import { UserInformationDialogComponent } from '../commons/components/user-information-dialog/user-information-dialog.component';
import { CreateEditTemplateDialogComponent } from '@modules/scenarios/components/create-edit-template-dialog/create-edit-template-dialog.component';
import { CreateBookmarkDialogComponent } from '@modules/report/components/create-bookmark-dialog/create-bookmark-dialog.component';
import { ShowFilterDialogComponent } from '@modules/report/components/show-filter-dialog/show-filter-dialog.component';
import { PdfOrderListComponent } from '@modules/report/components/pdf-order-list/pdf-order-list.component';
import { ComponentType } from '@angular/cdk/portal';
import { ExistingRecordDialogComponent } from '../commons/existing-record-dialog/existing-record-dialog-dialog.component';
import { NewInitiativeDialogComponent } from '@modules/initiatives/components/new-initiative-dialog/new-initiative-dialog.component';
import { ExportXbrlDialogComponent } from '../commons/export-xbrl-dialog/export-xbrl-dialog.component';
import { FiscalYear } from '../commons/models/fiscal-year.model';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) { }

  deleteDialog(data: DialogConfigData) {
    return this.dialog.open(DialogComponent, {
      data,
      width: '700px',
    });
  }

  inform(data: DialogConfigData = null) {
    return this.dialog.open(UserInformationDialogComponent, {
      data,
      width: '600px',
    });
  }

  createInitiative() {
    return this.dialog.open(CreateEditInitiativeDialogComponent, {
      width: '60%',
      disableClose: true,
      hasBackdrop: true,
    });
  }

  editInitiative(row) {
    return this.dialog.open(CreateEditInitiativeDialogComponent, {
      width: '60%',
      data: row,
      disableClose: true,
      hasBackdrop: true,
    });
  }

  createScenario() {
    return this.dialog.open(CreateEditScenarioDialogComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      disableClose: true,
      hasBackdrop: true,
    });
  }

  editScenario(row) {
    return this.dialog.open(CreateEditScenarioDialogComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: row,
      disableClose: true,
      hasBackdrop: true,
    });
  }

  createTemplate() {
    return this.dialog.open(CreateEditTemplateDialogComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      disableClose: true,
      hasBackdrop: true,
    });
  }

  editTemplate(row) {
    return this.dialog.open(CreateEditTemplateDialogComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: row,
      disableClose: true,
      hasBackdrop: true,
    });
  }


  edit(row: any, component: ComponentType<any>) {
    return this.dialog.open(component, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: row,
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false
    });
  }

  create(component: ComponentType<any>) {
    return this.dialog.open(component, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false
    });
  }

  editExistingRecordDialog(message: string = '', showUpdateButton: boolean = false, showYesNoButtons: boolean = false, showAbortButton: boolean = false) {
    return this.dialog.open(ExistingRecordDialogComponent, {
      data: { message: message, showUpdateButton: showUpdateButton, showYesNoButtons: showYesNoButtons, showAbortButton: showAbortButton }
    });
  }

  pdfOrderListDialog() {
    return this.dialog.open(PdfOrderListComponent, {
      width: '1024px',
      autoFocus: false,
    });
  }

  pdfOrderConfirmDialog(data: DialogConfigData) {
    return this.dialog.open(DialogComponent, {
      data,
      width: '400px',
    });
  }

  createBookmarkDialog() {
    return this.dialog.open(CreateBookmarkDialogComponent, {
      width: '614px',
      maxWidth: '80vw',
      autoFocus: false,
    });
  }

  editBookmarkDialog(data: DialogConfigData) {
    return this.dialog.open(CreateBookmarkDialogComponent, {
      width: '614px',
      maxWidth: '80vw',
      autoFocus: false,
      data: data
    });
  }

  openPbiFilterDialog() {
    return this.dialog.open(ShowFilterDialogComponent, {
      width: 'auto',
    });
  }

  createNewInitiativeDialog() {
    return this.dialog.open(NewInitiativeDialogComponent, {
      width: '614px',
      maxWidth: '80vw',
      autoFocus: false,
    });
  }

  exportXbrlDialog(fiscalYears: FiscalYear[]) {
    return this.dialog.open(ExportXbrlDialogComponent, {
      data: { fiscalYears: fiscalYears }
    });
  }
}
