<div class="row">
  <div class="col-9">
    <div>
      <h2 mat-dialog-title>{{ 'customers-own-data.existing-cod-dialog-title' | translate }}</h2>
    </div>

    <div mat-dialog-content>{{ data?.message ? data.message : 'customers-own-data.existing-cod-dialog-message' |
      translate }}</div>
  </div>

  <div class="col-3">
    <div class="text-right">
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon fontIcon="close"></mat-icon>
      </button>
    </div>
  </div>

  <div class="col-12">
    <div mat-dialog-actions [align]="'end'">
      <div *ngIf="!data?.showYesNoButtons">
        <button mat-raised-button (click)="cancel()" [mat-dialog-close]="false">
          {{ 'customers-own-data.confirm-cancel' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="confirmEdit()" [mat-dialog-close]="true">
          {{ 'customers-own-data.confirm-edit' | translate }}
        </button>
        <button *ngIf="!data?.showUpdateButton" mat-raised-button color="primary" (click)="confirmUpdate()"
          [mat-dialog-close]="true">
          {{ 'customers-own-data.confirm-update' | translate }}
        </button>
      </div>

      <!-- YES/NO handling -->
      <button *ngIf="data?.showYesNoButtons && data?.showAbortButton" mat-raised-button (click)="confirmAbort()"
        [mat-dialog-close]="true">
        {{ 'general.abort' | translate }}
      </button>
      <button *ngIf="data?.showYesNoButtons" mat-raised-button (click)="cancel()" [mat-dialog-close]="true">
        {{ 'general.no' | translate }}
      </button>
      <button *ngIf="data?.showYesNoButtons" mat-raised-button color="primary" (click)="confirmYes()"
        [mat-dialog-close]="true">
        {{ 'general.yes' | translate }}
      </button>

    </div>
  </div>
</div>