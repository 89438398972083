import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from "@angular/core";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from "@angular/material/tree";
import { BehaviorSubject, Subject } from "rxjs";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ApiService } from "src/app/services/api.service";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { DisplayPatternPipe } from "../../pipes/display-pattern.pipe";

/**
 * Node for to-do item
 */
export class ItemNode {
  children?: ItemNode[];
  id: number;
  organizationDescription: string;
  organizationId: string;
  organizationDescriptionLabel: string;
}

/** Flat to-do item node with expandable and level information */
export class ItemFlatNode {
  id: number;
  level: number;
  organizationDescription: string;
  organizationId: string;
  expandable: boolean;
}

/**
 * The Json object for to-do list data.
 */
const TREE_DATA: ItemNode[] = [];

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable({ providedIn: "root" })
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<ItemNode[]>([]);
  treeData: any[];

  get data(): ItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    this.treeData = TREE_DATA;
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const data = TREE_DATA;

    // Notify the change.
    this.dataChange.next(data);
  }

  public filter(filterText: string) {
    let filteredTreeData;
    if (filterText) {
      // Filter the tree
      function filter(array, text) {
        const getChildren = (result, object) => {
          if (object.organizationDescription.toLowerCase().includes(text.toLowerCase())) {
            result.push(object);
            return result;
          }
          if (Array.isArray(object.children)) {
            const children = object.children.reduce(getChildren, []);
            if (children.length) result.push({ ...object, children });
          }
          return result;
        };

        return array.reduce(getChildren, []);
      }

      filteredTreeData = filter(this.treeData, filterText);
    } else {
      // Return the initial tree
      filteredTreeData = this.treeData;
    }

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    const data = filteredTreeData;
    // Notify the change.
    this.dataChange.next(data);
  }
}

@Component({
  selector: "app-tree-autocomplete",
  templateUrl: "tree-autocomplete.component.html",
  styleUrls: ["tree-autocomplete.component.scss"],
  providers: [ChecklistDatabase, DisplayPatternPipe]
})
export class TreeAutocompleteComponent implements OnInit {

  @Input() parentGroup: FormGroup;
  @Input() controlName: string;
  @Input() displayFieldPattern: string;
  @Input() valueFieldName: string;
  @Input() options: ItemNode[];
  @Input() disabled: boolean = false;
  @Output() blur = new EventEmitter<boolean>();
  @Output() change = new EventEmitter<boolean>();

  control: AbstractControl<any, any>;

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ItemFlatNode, ItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<ItemNode, ItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: ItemFlatNode | null = null;

  /** The new item's name */
  newItemName = "";

  treeControl: FlatTreeControl<ItemFlatNode>;

  treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;

  dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<ItemFlatNode>(true /* multiple */);

  /// Filtering
  filteredOptions: Observable<string[]>;

  selectedNodeValue: string;

  filterSubject: Subject<string>;

  constructor(private _database: ChecklistDatabase, private apiService: ApiService, private patternPipe: DisplayPatternPipe) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<ItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  ngOnInit() {
    this.dataSource.data = this.options as ItemNode[];
    this._database.treeData = this.dataSource.data;
  }

  ngAfterViewInit(): void {
    this.control = this.parentGroup.controls[this.controlName];

    if (this.control.value) {
      this.selectedNodeValue = this.control.value;
      this._database.filter(this.control.value);
      if (this.control.value) {
        this.treeControl.expandAll();
      } else {
        this.treeControl.collapseAll();
      }
    }

    this.filterSubject = new Subject<string>();
    this.filterSubject.pipe(debounceTime(500)).subscribe((filterText: string) => {
      this._database.filter(filterText);
      if (filterText) {
        this.treeControl.expandAll();
      } else {
        this.treeControl.collapseAll();
      }
    });
  }

  onBlur(): void {
    let result = [];

    this.findNode(this._database.treeData, this.control.value, result);
    if (this.control.value && result?.length < 1) {
      this.blur.emit(false);
    }

    if (result?.length > 0) {
      this.blur.emit(true);
    }
  }

  onChange(): void {
    this.change.emit(true);
  }

  findNode(treeData, value, result: any[]): any {
    treeData.forEach(object => {
      if (object.organizationId == value) {
        result.push(object);
      } else if (Array.isArray(object.children)) {
        return this.findNode(object.children, value, result);
      }
    });

    return result;
  };

  displayFn(value?: string) {
    if (value) {
      let result = [];
      const res = this.findNode(this._database.treeData, value, result);
      return res[0]?.organizationDescription;
    } else {
      return '';
    }
  }

  getLevel = (node: ItemFlatNode) => node.level;

  isExpandable = (node: ItemFlatNode) => node.expandable;

  getChildren = (node: ItemNode): ItemNode[] => node.children;

  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: ItemFlatNode) => _nodeData.id === 0;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.id === node.id
        ? existingNode
        : new ItemFlatNode();
    flatNode.id = node.id;
    flatNode.organizationDescription = node.organizationDescription;
    flatNode.organizationId = node.organizationId;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );


    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ItemFlatNode): void {
    let parent: ItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ItemFlatNode): ItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  getSelectedItems(): string {
    if (!this.checklistSelection.selected.length) return "Organizations";
    return this.checklistSelection.selected.map(s => s.id).join(",");
  }

  filterChanged(eventTarget: EventTarget) {
    this.filterSubject.next((eventTarget as HTMLInputElement).value);
  }

  nodeClicked(node: ItemFlatNode): void {
    this.selectedNodeValue = node.organizationId;
    this.autocomplete.closePanel();
    this.control.setValue(node.organizationId);
  }
}
