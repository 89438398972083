import { Component, Host, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataViewComponent } from 'src/app/commons/components/data-view/data-view.component';
import { CustomDialogService } from 'src/app/commons/dialog/services/custom-dialog.service';
import { DialogService } from 'src/app/services/dialog.service';
import { SnackbarService } from 'src/app/commons/services/snackbar.service';
import { FiscalYearDialogComponent } from '../fiscal-year-dialog/fiscal-year-dialog.component';
import { FiscalYearsGetService } from '@modules/customers-csrd/services/fiscal-years/fiscal-years-get.service';
import { TableService } from '@modules/customers-csrd/services/fiscal-years/table.service';
import { FilterService } from '@modules/customers-csrd/services/fiscal-years/filter.service';
import { FiscalYearService } from '@modules/customers-csrd/services/fiscal-years/fiscal-years.service';
import { UserService } from '@modules/shell/services/user.service';

@Component({
    selector: 'app-fiscal-year-action-menu',
    templateUrl: './fiscal-year-action-menu.component.html',
    styleUrls: ['./fiscal-year-action-menu.component.scss'],
    providers: [DataViewComponent,
        { provide: 'ITableService', useExisting: TableService },
        { provide: 'IGetDataService', useExisting: FiscalYearsGetService },
        { provide: 'IDataService', useExisting: FiscalYearService },
        { provide: 'IFilterService', useExisting: FilterService },
    ],
    standalone: false
})
export class FiscalYearActionMenuComponent implements OnInit {
  translationKey = 'csrd';
  parentComponent: DataViewComponent;
  isDeleteButtonDisabled = true;
  private customDialogService: CustomDialogService;

  constructor(@Host() parent: DataViewComponent,
    public translateService: TranslateService,
    dialogService: DialogService,
    private tableService: TableService,
    dataService: FiscalYearService,
    getDataService: FiscalYearsGetService,
    filterService: FilterService,
    snackbarService: SnackbarService,
    private userService: UserService) {
    this.customDialogService = new CustomDialogService(dialogService, getDataService, dataService, tableService, filterService, snackbarService, translateService);
    this.customDialogService.orderBy = 'order by Id asc';
    this.parentComponent = parent;
  }

  ngOnInit() {
    this.customDialogService.dialogComponent = FiscalYearDialogComponent;

    this.tableService.anyRowSelectedSubject.subscribe(x => {
      this.isDeleteButtonDisabled = !x;
    });

    this.customDialogService.successfulDeleteSubject.subscribe(succeed => {
      if (succeed) {
        this.isDeleteButtonDisabled = true;
      }
    });
  }

  createDialog(): void {
    this.customDialogService.createDialog();
  }

  deleteSelected() {
    this.customDialogService.deleteDialog(this.tableService.selectedRows.map(x => x.id));
  }

  isReadonlyUser(): boolean {
    return this.userService.currentUser.permissions.customerReadOnlyUser;
  }
}
