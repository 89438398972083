import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitiativesComponent } from '@modules/initiatives/components/initiatives/initiatives.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { CreateEditInitiativeDialogComponent } from './components/create-edit-initiative-dialog/create-edit-initiative-dialog.component';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ScenariosModule } from '@modules/scenarios/scenarios.module';
import { ExpandableTableModule } from '../../commons/components/expandable-table/expandable-table.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { NewInitiativeDialogComponent } from './components/new-initiative-dialog/new-initiative-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PipesModule } from 'src/app/commons/pipes.module';

@NgModule({
  declarations: [InitiativesComponent, CreateEditInitiativeDialogComponent, NewInitiativeDialogComponent ],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSnackBarModule,
    ScenariosModule,
    ExpandableTableModule,
    MatFormFieldModule,
    MatCardModule,
    MatExpansionModule,
    PipesModule
  ],
})
export class InitiativesModule {}
