<ng-container *ngIf="tenantSelectService.tenants.length && showTenantSelectElement">
  <form [formGroup]="tenantSelectService.tenantHandlerForm">
    <div class="me-4">
      <select id="tenant-select" class="p-1" formControlName="tenant">
        <option *ngFor="let tenant of tenantSelectService.tenants | orderBy:'name'"
          [value]="tenant.identityProviderKey">
          {{ tenant.name }}
        </option>
      </select>
    </div>
  </form>
</ng-container>

<ng-container  *ngIf="tenantSelectService.tenants.length && !showTenantSelectElement">
  <form [formGroup]="tenantSelectService.tenantHandlerForm">
    <div class="me-4">
      <select [attr.disabled]="true" id="tenant-select" class="p-1" formControlName="tenant" style="width: 228px">
        <option [value]="">
        </option>
      </select>
    </div>
  </form>
</ng-container>