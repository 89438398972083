import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PbiReportService } from '@modules/report/services/pbi-report.service';
import { PowerBiReport } from '@modules/report/services/power-bi-report';
import { QueryParamsFilterService } from '@modules/report/services/query-params-filter.service';
import { Observable, Subscription, of } from 'rxjs';
import { FilterValues } from 'src/app/commons/models/report/filter-values.model';
import { Filter } from 'src/app/commons/models/report/filter.model';
import { PbiFilterTranslation } from 'src/app/commons/models/report/pbi-filter-translation.model';
import { Period } from 'src/app/commons/models/report/period.model';
import * as pbi from 'powerbi-client';

@Component({
    selector: 'app-show-filter-dialog',
    templateUrl: './show-filter-dialog.component.html',
    styleUrls: ['./show-filter-dialog.component.scss'],
    standalone: false
})
export class ShowFilterDialogComponent implements OnInit, OnDestroy {
  queryParamsFilterServiceSubscription$: Subscription;
  filterPeriod: Period;
  filterValues: FilterValues[];
  groupingValues: FilterValues[];
  displayedColumns = ['Key', 'Value', 'Delete'];

  constructor(
    private dialogRef: MatDialogRef<ShowFilterDialogComponent>,
    private queryParamsFilterService: QueryParamsFilterService,
    public pbiReportService: PbiReportService,
    private powerBiReportObj: PowerBiReport
  ) { }

  async ngOnInit(): Promise<void> {
    this.queryParamsFilterServiceSubscription$ =
      this.queryParamsFilterService.paramQuery.subscribe((filter: Filter) => {
        this.filterPeriod = filter.timePeriod;
      }
      );

    this.pbiReportService.reportInitialized.subscribe(async x => {

      if (x) {
        await this.powerBiReportObj.setPowerBiToFilter(this.pbiReportService.report).then(() => {
          this.filterValues = [];
          this.groupingValues = [];

          this.powerBiReportObj.filterValues.forEach((e) => {
            e.columnKey = e.key;
            if (e.key == 'Grouping') this.groupingValues.push(e);
            else if (e.key == 'Issuedate' || e.key == 'Postingdate')
              this.filterValues.push(this.parseDate(e));
            else {
              this.filterValues.push(e);
            }
          });

          this.getColumnNameTranslation(this.filterValues.map(x => x.key)).subscribe(translation => {
            for (let item of translation.columnKeys) {
              const found = this.filterValues.find(f => f.key === item.columnKey);
              if (found && item.displayValue) {
                found.key = item.displayValue;
                found.columnKey = item.columnKey;
              }
            }
          });
        });
      }
    });
  }

  parseDate(filter): FilterValues {
    var date =
      filter.values[0].substring(0, 4) +
      '-' +
      filter.values[0].substring(4, 6) +
      '-' +
      filter.values[0].substring(6, 8);
    filter.values[0] = date;
    return filter;
  }

  getColumnNameTranslation(columnKeys: string[]): Observable<PbiFilterTranslation> {
    return this.pbiReportService.getColumnNameDisplayValue(columnKeys);
  }

  ngOnDestroy(): void {
    this.queryParamsFilterServiceSubscription$.unsubscribe();
  }

  cancel() {
    this.dialogRef.close();
  }

  async deleteFilter(filterValue: FilterValues) {
    const tableColumnNames = filterValue.columnKey.split('.');

    const basicPageFilters = (await this.powerBiReportObj.getBasicPageFilters(this.pbiReportService.report)).filter(x => !x.displaySettings?.isHiddenInViewMode);
    await this.deletePageFilter(basicPageFilters, filterValue, tableColumnNames);

    const advancedPageFilters = (await this.powerBiReportObj.getAdvancedPageFilters(this.pbiReportService.report)).filter(x => !x.displaySettings?.isHiddenInViewMode);
    await this.deletePageFilter(advancedPageFilters, filterValue, tableColumnNames);

    const globalFilters = (await this.pbiReportService.report.getFilters()) as any[];
    const globalFiltersFiltered = globalFilters.filter(x => !(x.target.table == tableColumnNames[0] && x.target.column == tableColumnNames[1]));

    if (globalFilters?.length > globalFiltersFiltered?.length) {
      await this.pbiReportService.report.setFilters(globalFiltersFiltered);
      this.filterValues = this.filterValues.filter(x => x.columnKey != filterValue.columnKey);
      return;
    }
  }

  private async deletePageFilter<T extends pbi.models.IFilter>(
    filters: T[],
    filterValue: FilterValues,
    tableColumnNames: any[]
  ) {
    const filteredFilters = filters.filter(x => !((x.target as pbi.models.IFilterKeyColumnsTarget).table == tableColumnNames[0] && (x.target as pbi.models.IFilterKeyColumnsTarget).column == tableColumnNames[1]));

    if (filters?.length > filteredFilters?.length) {
      await this.powerBiReportObj.updatePageFilters(this.pbiReportService.report, filteredFilters);
      this.filterValues = this.filterValues.filter(x => x.columnKey != filterValue.columnKey);
      return;
    }
  }
}
