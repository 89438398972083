import { Component, ElementRef, Host, OnInit, ViewChild } from '@angular/core';
import { FilterService } from '@modules/customers-own-data-exclusion/services/filter.service';
import { TableService } from '@modules/customers-own-data-exclusion/services/table.service';
import { InformationDialogService } from '@modules/shell/services/information-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { DataViewComponent } from 'src/app/commons/components/data-view/data-view.component';
import { FileService } from 'src/app/commons/services/file.service';
import { SnackbarService } from 'src/app/commons/services/snackbar.service';
import { CustomDialogService } from 'src/app/commons/dialog/services/custom-dialog.service';
import { DialogService } from 'src/app/services/dialog.service';
import { CreateEditExclusionDialogComponent } from '../create-edit-exclusion-dialog/create-edit-exclusion-dialog.component';
import { CustomersExclusionRulesGetService, CustomersExclusionRulesService } from '@modules/customers-own-data-exclusion/services/customers-exclusion-rules.service';

@Component({
  selector: 'app-cod-exclusion-action-menu',
  templateUrl: './cod-exclusion-action-menu.component.html',
  styleUrls: ['./cod-exclusion-action-menu.component.scss'],
  providers: [DataViewComponent,
    { provide: 'ITableService', useExisting: TableService },
    { provide: 'IDataService', useExisting: CustomersExclusionRulesService },
    { provide: 'IFilterService', useExisting: FilterService }
  ]
})
export class CodExclusionActionMenuComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  translationKey = 'customers-own-data-exclusion';
  parentComponent: DataViewComponent;
  isDeleteButtonDisabled = true;
  private customDialogService: CustomDialogService;

  constructor(@Host() parent: DataViewComponent,
    public translateService: TranslateService,
    dialogService: DialogService,
    private informationDialogService: InformationDialogService,
    private snackbarService: SnackbarService,
    private fileService: FileService,
    private tableService: TableService,
    private dataService: CustomersExclusionRulesService,
    private getDataService: CustomersExclusionRulesGetService,
    private filterService: FilterService
  ) {
    this.customDialogService = new CustomDialogService(dialogService, getDataService, dataService, tableService, filterService, snackbarService, translateService);
    this.customDialogService.orderBy = 'order by Id desc';
    this.parentComponent = parent;
  }

  ngOnInit() {
    this.customDialogService.dialogComponent = CreateEditExclusionDialogComponent;
    this.tableService.anyRowSelectedSubject.subscribe(x => {
      this.isDeleteButtonDisabled = !x;
    });

    this.customDialogService.successfulDeleteSubject.subscribe(succeed => {
      if (succeed) {
        this.isDeleteButtonDisabled = true;
      }
    });
  }

  createDialog(): void {
    this.customDialogService.createDialog();
  }

  deleteSelected() {
    this.customDialogService.deleteDialog(this.tableService.selectedRows.map(x => x.id));
  }
}
