import * as i0 from '@angular/core';
import { Injectable, Optional, Inject, NgModule, InjectionToken } from '@angular/core';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpParams, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Subject, of, from, race, throwError, combineLatest, merge } from 'rxjs';
import { filter, tap, debounceTime, delay, switchMap, map, first, catchError, timeout, take, mergeMap } from 'rxjs/operators';
import fsha256 from 'fast-sha256';
class DateTimeProvider {}
class SystemDateTimeProvider extends DateTimeProvider {
  now() {
    return Date.now();
  }
  new() {
    return new Date();
  }
}
SystemDateTimeProvider.ɵfac = /* @__PURE__ */(() => {
  let ɵSystemDateTimeProvider_BaseFactory;
  return function SystemDateTimeProvider_Factory(__ngFactoryType__) {
    return (ɵSystemDateTimeProvider_BaseFactory || (ɵSystemDateTimeProvider_BaseFactory = i0.ɵɵgetInheritedFactory(SystemDateTimeProvider)))(__ngFactoryType__ || SystemDateTimeProvider);
  };
})();
SystemDateTimeProvider.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: SystemDateTimeProvider,
  factory: SystemDateTimeProvider.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SystemDateTimeProvider, [{
    type: Injectable
  }], null, null);
})();

/**
 * Additional options that can be passed to tryLogin.
 */
class LoginOptions {
  constructor() {
    /**
     * Set this to true to disable the nonce
     * check which is used to avoid
     * replay attacks.
     * This flag should never be true in
     * production environments.
     */
    this.disableNonceCheck = false;
    /**
     * Normally, you want to clear your hash fragment after
     * the lib read the token(s) so that they are not displayed
     * anymore in the url. If not, set this to true. For code flow
     * this controls removing query string values.
     */
    this.preventClearHashAfterLogin = false;
  }
}
/**
 * Defines the logging interface the OAuthService uses
 * internally. Is compatible with the `console` object,
 * but you can provide your own implementation as well
 * through dependency injection.
 */
class OAuthLogger {}
/**
 * Defines a simple storage that can be used for
 * storing the tokens at client side.
 * Is compatible to localStorage and sessionStorage,
 * but you can also create your own implementations.
 */
class OAuthStorage {}
class MemoryStorage {
  constructor() {
    this.data = new Map();
  }
  getItem(key) {
    return this.data.get(key);
  }
  removeItem(key) {
    this.data.delete(key);
  }
  setItem(key, data) {
    this.data.set(key, data);
  }
}
MemoryStorage.ɵfac = function MemoryStorage_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MemoryStorage)();
};
MemoryStorage.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: MemoryStorage,
  factory: MemoryStorage.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MemoryStorage, [{
    type: Injectable
  }], null, null);
})();
/**
 * Represents the received tokens, the received state
 * and the parsed claims from the id-token.
 */
class ReceivedTokens {}
class OAuthEvent {
  constructor(type) {
    this.type = type;
  }
}
class OAuthSuccessEvent extends OAuthEvent {
  constructor(type, info = null) {
    super(type);
    this.info = info;
  }
}
class OAuthInfoEvent extends OAuthEvent {
  constructor(type, info = null) {
    super(type);
    this.info = info;
  }
}
class OAuthErrorEvent extends OAuthEvent {
  constructor(type, reason, params = null) {
    super(type);
    this.reason = reason;
    this.params = params;
  }
}

// see: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_.22Unicode_Problem.22
function b64DecodeUnicode(str) {
  const base64 = str.replace(/\-/g, '+').replace(/\_/g, '/');
  return decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}
function base64UrlEncode(str) {
  const base64 = btoa(str);
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}
class AuthConfig {
  constructor(json) {
    /**
     * The client's id as registered with the auth server
     */
    this.clientId = '';
    /**
     * The client's redirectUri as registered with the auth server
     */
    this.redirectUri = '';
    /**
     * An optional second redirectUri where the auth server
     * redirects the user to after logging out.
     */
    this.postLogoutRedirectUri = '';
    /**
     * Defines whether to use 'redirectUri' as a replacement
     * of 'postLogoutRedirectUri' if the latter is not set.
     */
    this.redirectUriAsPostLogoutRedirectUriFallback = true;
    /**
     * The auth server's endpoint that allows to log
     * the user in when using implicit flow.
     */
    this.loginUrl = '';
    /**
     * The requested scopes
     */
    this.scope = 'openid profile';
    this.resource = '';
    this.rngUrl = '';
    /**
     * Defines whether to use OpenId Connect during
     * implicit flow.
     */
    this.oidc = true;
    /**
     * Defines whether to request an access token during
     * implicit flow.
     */
    this.requestAccessToken = true;
    this.options = null;
    /**
     * The issuer's uri.
     */
    this.issuer = '';
    /**
     * The logout url.
     */
    this.logoutUrl = '';
    /**
     * Defines whether to clear the hash fragment after logging in.
     */
    this.clearHashAfterLogin = true;
    /**
     * Url of the token endpoint as defined by OpenId Connect and OAuth 2.
     */
    this.tokenEndpoint = null;
    /**
     * Url of the revocation endpoint as defined by OpenId Connect and OAuth 2.
     */
    this.revocationEndpoint = null;
    /**
     * Names of known parameters sent out in the TokenResponse. https://tools.ietf.org/html/rfc6749#section-5.1
     */
    this.customTokenParameters = [];
    /**
     * Url of the userinfo endpoint as defined by OpenId Connect.
     */
    this.userinfoEndpoint = null;
    this.responseType = '';
    /**
     * Defines whether additional debug information should
     * be shown at the console. Note that in certain browsers
     * the verbosity of the console needs to be explicitly set
     * to include Debug level messages.
     */
    this.showDebugInformation = false;
    /**
     * The redirect uri used when doing silent refresh.
     */
    this.silentRefreshRedirectUri = '';
    this.silentRefreshMessagePrefix = '';
    /**
     * Set this to true to display the iframe used for
     * silent refresh for debugging.
     */
    this.silentRefreshShowIFrame = false;
    /**
     * Timeout for silent refresh.
     * @internal
     * depreacted b/c of typo, see silentRefreshTimeout
     */
    this.siletRefreshTimeout = 1000 * 20;
    /**
     * Timeout for silent refresh.
     */
    this.silentRefreshTimeout = 1000 * 20;
    /**
     * Some auth servers don't allow using password flow
     * w/o a client secret while the standards do not
     * demand for it. In this case, you can set a password
     * here. As this password is exposed to the public
     * it does not bring additional security and is therefore
     * as good as using no password.
     */
    this.dummyClientSecret = null;
    /**
     * Defines whether https is required.
     * The default value is remoteOnly which only allows
     * http for localhost, while every other domains need
     * to be used with https.
     */
    this.requireHttps = 'remoteOnly';
    /**
     * Defines whether every url provided by the discovery
     * document has to start with the issuer's url.
     */
    this.strictDiscoveryDocumentValidation = true;
    /**
     * JSON Web Key Set (https://tools.ietf.org/html/rfc7517)
     * with keys used to validate received id_tokens.
     * This is taken out of the disovery document. Can be set manually too.
     */
    this.jwks = null;
    /**
     * Map with additional query parameter that are appended to
     * the request when initializing implicit flow.
     */
    this.customQueryParams = null;
    this.silentRefreshIFrameName = 'angular-oauth-oidc-silent-refresh-iframe';
    /**
     * Defines when the token_timeout event should be raised.
     * If you set this to the default value 0.75, the event
     * is triggered after 75% of the token's life time.
     */
    this.timeoutFactor = 0.75;
    /**
     * If true, the lib will try to check whether the user
     * is still logged in on a regular basis as described
     * in http://openid.net/specs/openid-connect-session-1_0.html#ChangeNotification
     */
    this.sessionChecksEnabled = false;
    /**
     * Interval in msec for checking the session
     * according to http://openid.net/specs/openid-connect-session-1_0.html#ChangeNotification
     */
    this.sessionCheckIntervall = 3 * 1000;
    /**
     * Url for the iframe used for session checks
     */
    this.sessionCheckIFrameUrl = null;
    /**
     * Name of the iframe to use for session checks
     */
    this.sessionCheckIFrameName = 'angular-oauth-oidc-check-session-iframe';
    /**
     * This property has been introduced to disable at_hash checks
     * and is indented for Identity Provider that does not deliver
     * an at_hash EVEN THOUGH its recommended by the OIDC specs.
     * Of course, when disabling these checks then we are bypassing
     * a security check which means we are more vulnerable.
     */
    this.disableAtHashCheck = false;
    /**
     * Defines wether to check the subject of a refreshed token after silent refresh.
     * Normally, it should be the same as before.
     */
    this.skipSubjectCheck = false;
    this.useIdTokenHintForSilentRefresh = false;
    /**
     * Defined whether to skip the validation of the issuer in the discovery document.
     * Normally, the discovey document's url starts with the url of the issuer.
     */
    this.skipIssuerCheck = false;
    /**
     * final state sent to issuer is built as follows:
     * state = nonce + nonceStateSeparator + additional state
     * Default separator is ';' (encoded %3B).
     * In rare cases, this character might be forbidden or inconvenient to use by the issuer so it can be customized.
     */
    this.nonceStateSeparator = ';';
    /**
     * Set this to true to use HTTP BASIC auth for AJAX calls
     */
    this.useHttpBasicAuth = false;
    /**
     * The interceptors waits this time span if there is no token
     */
    this.waitForTokenInMsec = 0;
    /**
     * Code Flow is by defauld used together with PKCI which is also higly recommented.
     * You can disbale it here by setting this flag to true.
     * https://tools.ietf.org/html/rfc7636#section-1.1
     */
    this.disablePKCE = false;
    /**
     * Set this to true to preserve the requested route including query parameters after code flow login.
     * This setting enables deep linking for the code flow.
     */
    this.preserveRequestedRoute = false;
    /**
     * This property allows you to override the method that is used to open the login url,
     * allowing a way for implementations to specify their own method of routing to new
     * urls.
     */
    this.openUri = uri => {
      location.href = uri;
    };
    if (json) {
      Object.assign(this, json);
    }
  }
}

/**
 * This custom encoder allows charactes like +, % and / to be used in passwords
 */
class WebHttpUrlEncodingCodec {
  encodeKey(k) {
    return encodeURIComponent(k);
  }
  encodeValue(v) {
    return encodeURIComponent(v);
  }
  decodeKey(k) {
    return decodeURIComponent(k);
  }
  decodeValue(v) {
    return decodeURIComponent(v);
  }
}

/**
 * Interface for Handlers that are hooked in to
 * validate tokens.
 */
class ValidationHandler {}
/**
 * This abstract implementation of ValidationHandler already implements
 * the method validateAtHash. However, to make use of it,
 * you have to override the method calcHash.
 */
class AbstractValidationHandler {
  /**
   * Validates the at_hash in an id_token against the received access_token.
   */
  async validateAtHash(params) {
    let hashAlg = this.inferHashAlgorithm(params.idTokenHeader);
    let tokenHash = await this.calcHash(params.accessToken, hashAlg); // sha256(accessToken, { asString: true });
    let leftMostHalf = tokenHash.substr(0, tokenHash.length / 2);
    let atHash = base64UrlEncode(leftMostHalf);
    let claimsAtHash = params.idTokenClaims['at_hash'].replace(/=/g, '');
    if (atHash !== claimsAtHash) {
      console.error('exptected at_hash: ' + atHash);
      console.error('actual at_hash: ' + claimsAtHash);
    }
    return atHash === claimsAtHash;
  }
  /**
   * Infers the name of the hash algorithm to use
   * from the alg field of an id_token.
   *
   * @param jwtHeader the id_token's parsed header
   */
  inferHashAlgorithm(jwtHeader) {
    let alg = jwtHeader['alg'];
    if (!alg.match(/^.S[0-9]{3}$/)) {
      throw new Error('Algorithm not supported: ' + alg);
    }
    return 'sha-' + alg.substr(2);
  }
}
class UrlHelperService {
  getHashFragmentParams(customHashFragment) {
    let hash = customHashFragment || window.location.hash;
    hash = decodeURIComponent(hash);
    if (hash.indexOf('#') !== 0) {
      return {};
    }
    const questionMarkPosition = hash.indexOf('?');
    if (questionMarkPosition > -1) {
      hash = hash.substr(questionMarkPosition + 1);
    } else {
      hash = hash.substr(1);
    }
    return this.parseQueryString(hash);
  }
  parseQueryString(queryString) {
    const data = {};
    let pairs, pair, separatorIndex, escapedKey, escapedValue, key, value;
    if (queryString === null) {
      return data;
    }
    pairs = queryString.split('&');
    for (let i = 0; i < pairs.length; i++) {
      pair = pairs[i];
      separatorIndex = pair.indexOf('=');
      if (separatorIndex === -1) {
        escapedKey = pair;
        escapedValue = null;
      } else {
        escapedKey = pair.substr(0, separatorIndex);
        escapedValue = pair.substr(separatorIndex + 1);
      }
      key = decodeURIComponent(escapedKey);
      value = decodeURIComponent(escapedValue);
      if (key.substr(0, 1) === '/') {
        key = key.substr(1);
      }
      data[key] = value;
    }
    return data;
  }
}
UrlHelperService.ɵfac = function UrlHelperService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || UrlHelperService)();
};
UrlHelperService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: UrlHelperService,
  factory: UrlHelperService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UrlHelperService, [{
    type: Injectable
  }], null, null);
})();

/**
 * [js-sha256]{@link https://github.com/emn178/js-sha256}
 *
 * @version 0.9.0
 * @author Chen, Yi-Cyuan [emn178@gmail.com]
 * @copyright Chen, Yi-Cyuan 2014-2017
 * @license MIT
 */
/*jslint bitwise: true */
function factory() {
  var ERROR = 'input is invalid type';
  var WINDOW = typeof window === 'object';
  var root = WINDOW ? window : {};
  if (root.JS_SHA256_NO_WINDOW) {
    WINDOW = false;
  }
  var WEB_WORKER = !WINDOW && typeof self === 'object';
  var NODE_JS = !root.JS_SHA256_NO_NODE_JS && typeof process === 'object' && process.versions && process.versions.node;
  if (NODE_JS) {
    root = global;
  } else if (WEB_WORKER) {
    root = self;
  }
  var COMMON_JS = !root.JS_SHA256_NO_COMMON_JS && typeof module === 'object' && module.exports;
  var AMD = typeof define === 'function' && define.amd;
  var ARRAY_BUFFER = !root.JS_SHA256_NO_ARRAY_BUFFER && typeof ArrayBuffer !== 'undefined';
  const HEX_CHARS = '0123456789abcdef'.split('');
  const EXTRA = [-2147483648, 8388608, 32768, 128];
  const SHIFT = [24, 16, 8, 0];
  const K = [0x428a2f98, 0x71374491, 0xb5c0fbcf, 0xe9b5dba5, 0x3956c25b, 0x59f111f1, 0x923f82a4, 0xab1c5ed5, 0xd807aa98, 0x12835b01, 0x243185be, 0x550c7dc3, 0x72be5d74, 0x80deb1fe, 0x9bdc06a7, 0xc19bf174, 0xe49b69c1, 0xefbe4786, 0x0fc19dc6, 0x240ca1cc, 0x2de92c6f, 0x4a7484aa, 0x5cb0a9dc, 0x76f988da, 0x983e5152, 0xa831c66d, 0xb00327c8, 0xbf597fc7, 0xc6e00bf3, 0xd5a79147, 0x06ca6351, 0x14292967, 0x27b70a85, 0x2e1b2138, 0x4d2c6dfc, 0x53380d13, 0x650a7354, 0x766a0abb, 0x81c2c92e, 0x92722c85, 0xa2bfe8a1, 0xa81a664b, 0xc24b8b70, 0xc76c51a3, 0xd192e819, 0xd6990624, 0xf40e3585, 0x106aa070, 0x19a4c116, 0x1e376c08, 0x2748774c, 0x34b0bcb5, 0x391c0cb3, 0x4ed8aa4a, 0x5b9cca4f, 0x682e6ff3, 0x748f82ee, 0x78a5636f, 0x84c87814, 0x8cc70208, 0x90befffa, 0xa4506ceb, 0xbef9a3f7, 0xc67178f2];
  const OUTPUT_TYPES = ['hex', 'array', 'digest', 'arrayBuffer'];
  var blocks = [];
  if (root.JS_SHA256_NO_NODE_JS || !Array.isArray) {
    Array.isArray = function (obj) {
      return Object.prototype.toString.call(obj) === '[object Array]';
    };
  }
  if (ARRAY_BUFFER && (root.JS_SHA256_NO_ARRAY_BUFFER_IS_VIEW || !ArrayBuffer.isView)) {
    ArrayBuffer.isView = function (obj) {
      return typeof obj === 'object' && obj.buffer && obj.buffer.constructor === ArrayBuffer;
    };
  }
  var createOutputMethod = function (outputType, is224) {
    return function (message) {
      return new Sha256(is224, true).update(message)[outputType]();
    };
  };
  var createMethod = function (is224) {
    var method = createOutputMethod('hex', is224);
    if (NODE_JS) {
      method = nodeWrap(method, is224);
    }
    method.create = function () {
      return new Sha256(is224);
    };
    method.update = function (message) {
      return method.create().update(message);
    };
    for (var i = 0; i < OUTPUT_TYPES.length; ++i) {
      var type = OUTPUT_TYPES[i];
      method[type] = createOutputMethod(type, is224);
    }
    return method;
  };
  var nodeWrap = function (method, is224) {
    var crypto = eval("require('crypto')");
    var Buffer = eval("require('buffer').Buffer");
    var algorithm = is224 ? 'sha224' : 'sha256';
    var nodeMethod = function (message) {
      if (typeof message === 'string') {
        return crypto.createHash(algorithm).update(message, 'utf8').digest('hex');
      } else {
        if (message === null || message === undefined) {
          throw new Error(ERROR);
        } else if (message.constructor === ArrayBuffer) {
          message = new Uint8Array(message);
        }
      }
      if (Array.isArray(message) || ArrayBuffer.isView(message) || message.constructor === Buffer) {
        return crypto.createHash(algorithm).update(new Buffer(message)).digest('hex');
      } else {
        return method(message);
      }
    };
    return nodeMethod;
  };
  var createHmacOutputMethod = function (outputType, is224) {
    return function (key, message) {
      return new HmacSha256(key, is224, true).update(message)[outputType]();
    };
  };
  var createHmacMethod = function (is224) {
    var method = createHmacOutputMethod('hex', is224);
    method.create = function (key) {
      return new HmacSha256(key, is224);
    };
    method.update = function (key, message) {
      return method.create(key).update(message);
    };
    for (var i = 0; i < OUTPUT_TYPES.length; ++i) {
      var type = OUTPUT_TYPES[i];
      method[type] = createHmacOutputMethod(type, is224);
    }
    return method;
  };
  function Sha256(is224, sharedMemory) {
    if (sharedMemory) {
      blocks[0] = blocks[16] = blocks[1] = blocks[2] = blocks[3] = blocks[4] = blocks[5] = blocks[6] = blocks[7] = blocks[8] = blocks[9] = blocks[10] = blocks[11] = blocks[12] = blocks[13] = blocks[14] = blocks[15] = 0;
      this.blocks = blocks;
    } else {
      this.blocks = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }
    if (is224) {
      this.h0 = 0xc1059ed8;
      this.h1 = 0x367cd507;
      this.h2 = 0x3070dd17;
      this.h3 = 0xf70e5939;
      this.h4 = 0xffc00b31;
      this.h5 = 0x68581511;
      this.h6 = 0x64f98fa7;
      this.h7 = 0xbefa4fa4;
    } else {
      // 256
      this.h0 = 0x6a09e667;
      this.h1 = 0xbb67ae85;
      this.h2 = 0x3c6ef372;
      this.h3 = 0xa54ff53a;
      this.h4 = 0x510e527f;
      this.h5 = 0x9b05688c;
      this.h6 = 0x1f83d9ab;
      this.h7 = 0x5be0cd19;
    }
    this.block = this.start = this.bytes = this.hBytes = 0;
    this.finalized = this.hashed = false;
    this.first = true;
    this.is224 = is224;
  }
  Sha256.prototype.update = function (message) {
    if (this.finalized) {
      return;
    }
    var notString,
      type = typeof message;
    if (type !== 'string') {
      if (type === 'object') {
        if (message === null) {
          throw new Error(ERROR);
        } else if (ARRAY_BUFFER && message.constructor === ArrayBuffer) {
          message = new Uint8Array(message);
        } else if (!Array.isArray(message)) {
          if (!ARRAY_BUFFER || !ArrayBuffer.isView(message)) {
            throw new Error(ERROR);
          }
        }
      } else {
        throw new Error(ERROR);
      }
      notString = true;
    }
    var code,
      index = 0,
      i,
      length = message.length,
      blocks = this.blocks;
    while (index < length) {
      if (this.hashed) {
        this.hashed = false;
        blocks[0] = this.block;
        blocks[16] = blocks[1] = blocks[2] = blocks[3] = blocks[4] = blocks[5] = blocks[6] = blocks[7] = blocks[8] = blocks[9] = blocks[10] = blocks[11] = blocks[12] = blocks[13] = blocks[14] = blocks[15] = 0;
      }
      if (notString) {
        for (i = this.start; index < length && i < 64; ++index) {
          blocks[i >> 2] |= message[index] << SHIFT[i++ & 3];
        }
      } else {
        for (i = this.start; index < length && i < 64; ++index) {
          code = message.charCodeAt(index);
          if (code < 0x80) {
            blocks[i >> 2] |= code << SHIFT[i++ & 3];
          } else if (code < 0x800) {
            blocks[i >> 2] |= (0xc0 | code >> 6) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | code & 0x3f) << SHIFT[i++ & 3];
          } else if (code < 0xd800 || code >= 0xe000) {
            blocks[i >> 2] |= (0xe0 | code >> 12) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | code >> 6 & 0x3f) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | code & 0x3f) << SHIFT[i++ & 3];
          } else {
            code = 0x10000 + ((code & 0x3ff) << 10 | message.charCodeAt(++index) & 0x3ff);
            blocks[i >> 2] |= (0xf0 | code >> 18) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | code >> 12 & 0x3f) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | code >> 6 & 0x3f) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (0x80 | code & 0x3f) << SHIFT[i++ & 3];
          }
        }
      }
      this.lastByteIndex = i;
      this.bytes += i - this.start;
      if (i >= 64) {
        this.block = blocks[16];
        this.start = i - 64;
        this.hash();
        this.hashed = true;
      } else {
        this.start = i;
      }
    }
    if (this.bytes > 4294967295) {
      this.hBytes += this.bytes / 4294967296 << 0;
      this.bytes = this.bytes % 4294967296;
    }
    return this;
  };
  Sha256.prototype.finalize = function () {
    if (this.finalized) {
      return;
    }
    this.finalized = true;
    var blocks = this.blocks,
      i = this.lastByteIndex;
    blocks[16] = this.block;
    blocks[i >> 2] |= EXTRA[i & 3];
    this.block = blocks[16];
    if (i >= 56) {
      if (!this.hashed) {
        this.hash();
      }
      blocks[0] = this.block;
      blocks[16] = blocks[1] = blocks[2] = blocks[3] = blocks[4] = blocks[5] = blocks[6] = blocks[7] = blocks[8] = blocks[9] = blocks[10] = blocks[11] = blocks[12] = blocks[13] = blocks[14] = blocks[15] = 0;
    }
    blocks[14] = this.hBytes << 3 | this.bytes >>> 29;
    blocks[15] = this.bytes << 3;
    this.hash();
  };
  Sha256.prototype.hash = function () {
    var a = this.h0,
      b = this.h1,
      c = this.h2,
      d = this.h3,
      e = this.h4,
      f = this.h5,
      g = this.h6,
      h = this.h7,
      blocks = this.blocks,
      j,
      s0,
      s1,
      maj,
      t1,
      t2,
      ch,
      ab,
      da,
      cd,
      bc;
    for (j = 16; j < 64; ++j) {
      // rightrotate
      t1 = blocks[j - 15];
      s0 = (t1 >>> 7 | t1 << 25) ^ (t1 >>> 18 | t1 << 14) ^ t1 >>> 3;
      t1 = blocks[j - 2];
      s1 = (t1 >>> 17 | t1 << 15) ^ (t1 >>> 19 | t1 << 13) ^ t1 >>> 10;
      blocks[j] = blocks[j - 16] + s0 + blocks[j - 7] + s1 << 0;
    }
    bc = b & c;
    for (j = 0; j < 64; j += 4) {
      if (this.first) {
        if (this.is224) {
          ab = 300032;
          t1 = blocks[0] - 1413257819;
          h = t1 - 150054599 << 0;
          d = t1 + 24177077 << 0;
        } else {
          ab = 704751109;
          t1 = blocks[0] - 210244248;
          h = t1 - 1521486534 << 0;
          d = t1 + 143694565 << 0;
        }
        this.first = false;
      } else {
        s0 = (a >>> 2 | a << 30) ^ (a >>> 13 | a << 19) ^ (a >>> 22 | a << 10);
        s1 = (e >>> 6 | e << 26) ^ (e >>> 11 | e << 21) ^ (e >>> 25 | e << 7);
        ab = a & b;
        maj = ab ^ a & c ^ bc;
        ch = e & f ^ ~e & g;
        t1 = h + s1 + ch + K[j] + blocks[j];
        t2 = s0 + maj;
        h = d + t1 << 0;
        d = t1 + t2 << 0;
      }
      s0 = (d >>> 2 | d << 30) ^ (d >>> 13 | d << 19) ^ (d >>> 22 | d << 10);
      s1 = (h >>> 6 | h << 26) ^ (h >>> 11 | h << 21) ^ (h >>> 25 | h << 7);
      da = d & a;
      maj = da ^ d & b ^ ab;
      ch = h & e ^ ~h & f;
      t1 = g + s1 + ch + K[j + 1] + blocks[j + 1];
      t2 = s0 + maj;
      g = c + t1 << 0;
      c = t1 + t2 << 0;
      s0 = (c >>> 2 | c << 30) ^ (c >>> 13 | c << 19) ^ (c >>> 22 | c << 10);
      s1 = (g >>> 6 | g << 26) ^ (g >>> 11 | g << 21) ^ (g >>> 25 | g << 7);
      cd = c & d;
      maj = cd ^ c & a ^ da;
      ch = g & h ^ ~g & e;
      t1 = f + s1 + ch + K[j + 2] + blocks[j + 2];
      t2 = s0 + maj;
      f = b + t1 << 0;
      b = t1 + t2 << 0;
      s0 = (b >>> 2 | b << 30) ^ (b >>> 13 | b << 19) ^ (b >>> 22 | b << 10);
      s1 = (f >>> 6 | f << 26) ^ (f >>> 11 | f << 21) ^ (f >>> 25 | f << 7);
      bc = b & c;
      maj = bc ^ b & d ^ cd;
      ch = f & g ^ ~f & h;
      t1 = e + s1 + ch + K[j + 3] + blocks[j + 3];
      t2 = s0 + maj;
      e = a + t1 << 0;
      a = t1 + t2 << 0;
    }
    this.h0 = this.h0 + a << 0;
    this.h1 = this.h1 + b << 0;
    this.h2 = this.h2 + c << 0;
    this.h3 = this.h3 + d << 0;
    this.h4 = this.h4 + e << 0;
    this.h5 = this.h5 + f << 0;
    this.h6 = this.h6 + g << 0;
    this.h7 = this.h7 + h << 0;
  };
  Sha256.prototype.hex = function () {
    this.finalize();
    var h0 = this.h0,
      h1 = this.h1,
      h2 = this.h2,
      h3 = this.h3,
      h4 = this.h4,
      h5 = this.h5,
      h6 = this.h6,
      h7 = this.h7;
    var hex = HEX_CHARS[h0 >> 28 & 0x0F] + HEX_CHARS[h0 >> 24 & 0x0F] + HEX_CHARS[h0 >> 20 & 0x0F] + HEX_CHARS[h0 >> 16 & 0x0F] + HEX_CHARS[h0 >> 12 & 0x0F] + HEX_CHARS[h0 >> 8 & 0x0F] + HEX_CHARS[h0 >> 4 & 0x0F] + HEX_CHARS[h0 & 0x0F] + HEX_CHARS[h1 >> 28 & 0x0F] + HEX_CHARS[h1 >> 24 & 0x0F] + HEX_CHARS[h1 >> 20 & 0x0F] + HEX_CHARS[h1 >> 16 & 0x0F] + HEX_CHARS[h1 >> 12 & 0x0F] + HEX_CHARS[h1 >> 8 & 0x0F] + HEX_CHARS[h1 >> 4 & 0x0F] + HEX_CHARS[h1 & 0x0F] + HEX_CHARS[h2 >> 28 & 0x0F] + HEX_CHARS[h2 >> 24 & 0x0F] + HEX_CHARS[h2 >> 20 & 0x0F] + HEX_CHARS[h2 >> 16 & 0x0F] + HEX_CHARS[h2 >> 12 & 0x0F] + HEX_CHARS[h2 >> 8 & 0x0F] + HEX_CHARS[h2 >> 4 & 0x0F] + HEX_CHARS[h2 & 0x0F] + HEX_CHARS[h3 >> 28 & 0x0F] + HEX_CHARS[h3 >> 24 & 0x0F] + HEX_CHARS[h3 >> 20 & 0x0F] + HEX_CHARS[h3 >> 16 & 0x0F] + HEX_CHARS[h3 >> 12 & 0x0F] + HEX_CHARS[h3 >> 8 & 0x0F] + HEX_CHARS[h3 >> 4 & 0x0F] + HEX_CHARS[h3 & 0x0F] + HEX_CHARS[h4 >> 28 & 0x0F] + HEX_CHARS[h4 >> 24 & 0x0F] + HEX_CHARS[h4 >> 20 & 0x0F] + HEX_CHARS[h4 >> 16 & 0x0F] + HEX_CHARS[h4 >> 12 & 0x0F] + HEX_CHARS[h4 >> 8 & 0x0F] + HEX_CHARS[h4 >> 4 & 0x0F] + HEX_CHARS[h4 & 0x0F] + HEX_CHARS[h5 >> 28 & 0x0F] + HEX_CHARS[h5 >> 24 & 0x0F] + HEX_CHARS[h5 >> 20 & 0x0F] + HEX_CHARS[h5 >> 16 & 0x0F] + HEX_CHARS[h5 >> 12 & 0x0F] + HEX_CHARS[h5 >> 8 & 0x0F] + HEX_CHARS[h5 >> 4 & 0x0F] + HEX_CHARS[h5 & 0x0F] + HEX_CHARS[h6 >> 28 & 0x0F] + HEX_CHARS[h6 >> 24 & 0x0F] + HEX_CHARS[h6 >> 20 & 0x0F] + HEX_CHARS[h6 >> 16 & 0x0F] + HEX_CHARS[h6 >> 12 & 0x0F] + HEX_CHARS[h6 >> 8 & 0x0F] + HEX_CHARS[h6 >> 4 & 0x0F] + HEX_CHARS[h6 & 0x0F];
    if (!this.is224) {
      hex += HEX_CHARS[h7 >> 28 & 0x0F] + HEX_CHARS[h7 >> 24 & 0x0F] + HEX_CHARS[h7 >> 20 & 0x0F] + HEX_CHARS[h7 >> 16 & 0x0F] + HEX_CHARS[h7 >> 12 & 0x0F] + HEX_CHARS[h7 >> 8 & 0x0F] + HEX_CHARS[h7 >> 4 & 0x0F] + HEX_CHARS[h7 & 0x0F];
    }
    return hex;
  };
  Sha256.prototype.toString = Sha256.prototype.hex;
  Sha256.prototype.digest = function () {
    this.finalize();
    var h0 = this.h0,
      h1 = this.h1,
      h2 = this.h2,
      h3 = this.h3,
      h4 = this.h4,
      h5 = this.h5,
      h6 = this.h6,
      h7 = this.h7;
    var arr = [h0 >> 24 & 0xFF, h0 >> 16 & 0xFF, h0 >> 8 & 0xFF, h0 & 0xFF, h1 >> 24 & 0xFF, h1 >> 16 & 0xFF, h1 >> 8 & 0xFF, h1 & 0xFF, h2 >> 24 & 0xFF, h2 >> 16 & 0xFF, h2 >> 8 & 0xFF, h2 & 0xFF, h3 >> 24 & 0xFF, h3 >> 16 & 0xFF, h3 >> 8 & 0xFF, h3 & 0xFF, h4 >> 24 & 0xFF, h4 >> 16 & 0xFF, h4 >> 8 & 0xFF, h4 & 0xFF, h5 >> 24 & 0xFF, h5 >> 16 & 0xFF, h5 >> 8 & 0xFF, h5 & 0xFF, h6 >> 24 & 0xFF, h6 >> 16 & 0xFF, h6 >> 8 & 0xFF, h6 & 0xFF];
    if (!this.is224) {
      arr.push(h7 >> 24 & 0xFF, h7 >> 16 & 0xFF, h7 >> 8 & 0xFF, h7 & 0xFF);
    }
    return arr;
  };
  Sha256.prototype.array = Sha256.prototype.digest;
  Sha256.prototype.arrayBuffer = function () {
    this.finalize();
    var buffer = new ArrayBuffer(this.is224 ? 28 : 32);
    var dataView = new DataView(buffer);
    dataView.setUint32(0, this.h0);
    dataView.setUint32(4, this.h1);
    dataView.setUint32(8, this.h2);
    dataView.setUint32(12, this.h3);
    dataView.setUint32(16, this.h4);
    dataView.setUint32(20, this.h5);
    dataView.setUint32(24, this.h6);
    if (!this.is224) {
      dataView.setUint32(28, this.h7);
    }
    return buffer;
  };
  function HmacSha256(key, is224, sharedMemory) {
    var i,
      type = typeof key;
    if (type === 'string') {
      var bytes = [],
        length = key.length,
        index = 0,
        code;
      for (i = 0; i < length; ++i) {
        code = key.charCodeAt(i);
        if (code < 0x80) {
          bytes[index++] = code;
        } else if (code < 0x800) {
          bytes[index++] = 0xc0 | code >> 6;
          bytes[index++] = 0x80 | code & 0x3f;
        } else if (code < 0xd800 || code >= 0xe000) {
          bytes[index++] = 0xe0 | code >> 12;
          bytes[index++] = 0x80 | code >> 6 & 0x3f;
          bytes[index++] = 0x80 | code & 0x3f;
        } else {
          code = 0x10000 + ((code & 0x3ff) << 10 | key.charCodeAt(++i) & 0x3ff);
          bytes[index++] = 0xf0 | code >> 18;
          bytes[index++] = 0x80 | code >> 12 & 0x3f;
          bytes[index++] = 0x80 | code >> 6 & 0x3f;
          bytes[index++] = 0x80 | code & 0x3f;
        }
      }
      key = bytes;
    } else {
      if (type === 'object') {
        if (key === null) {
          throw new Error(ERROR);
        } else if (ARRAY_BUFFER && key.constructor === ArrayBuffer) {
          key = new Uint8Array(key);
        } else if (!Array.isArray(key)) {
          if (!ARRAY_BUFFER || !ArrayBuffer.isView(key)) {
            throw new Error(ERROR);
          }
        }
      } else {
        throw new Error(ERROR);
      }
    }
    if (key.length > 64) {
      key = new Sha256(is224, true).update(key).array();
    }
    var oKeyPad = [],
      iKeyPad = [];
    for (i = 0; i < 64; ++i) {
      var b = key[i] || 0;
      oKeyPad[i] = 0x5c ^ b;
      iKeyPad[i] = 0x36 ^ b;
    }
    Sha256.call(this, is224, sharedMemory);
    this.update(iKeyPad);
    this.oKeyPad = oKeyPad;
    this.inner = true;
    this.sharedMemory = sharedMemory;
  }
  HmacSha256.prototype = new Sha256();
  HmacSha256.prototype.finalize = function () {
    Sha256.prototype.finalize.call(this);
    if (this.inner) {
      this.inner = false;
      var innerHash = this.array();
      Sha256.call(this, this.is224, this.sharedMemory);
      this.update(this.oKeyPad);
      this.update(innerHash);
      Sha256.prototype.finalize.call(this);
    }
  };
  var exports = createMethod();
  exports.sha256 = exports;
  exports.sha224 = createMethod(true);
  exports.sha256.hmac = createHmacMethod();
  exports.sha224.hmac = createHmacMethod(true);
  return exports;
}
const sha256 = factory();
/**
 * Abstraction for crypto algorithms
 */
class HashHandler {}
function decodeUTF8(s) {
  if (typeof s !== 'string') throw new TypeError('expected string');
  var i,
    d = s,
    b = new Uint8Array(d.length);
  for (i = 0; i < d.length; i++) b[i] = d.charCodeAt(i);
  return b;
}
function encodeUTF8(arr) {
  var i,
    s = [];
  for (i = 0; i < arr.length; i++) s.push(String.fromCharCode(arr[i]));
  return s.join('');
}
class DefaultHashHandler {
  async calcHash(valueToHash, algorithm) {
    // const encoder = new TextEncoder();
    // const hashArray = await window.crypto.subtle.digest(algorithm, data);
    // const data = encoder.encode(valueToHash);
    // const fhash = fsha256(valueToHash);
    const candHash = encodeUTF8(fsha256(decodeUTF8(valueToHash)));
    // const hashArray = (sha256 as any).array(valueToHash);
    // // const hashString = this.toHashString(hashArray);
    // const hashString = this.toHashString2(hashArray);
    // console.debug('hash orig - cand', candHash, hashString);
    // alert(1);
    return candHash;
  }
  toHashString2(byteArray) {
    let result = '';
    for (let e of byteArray) {
      result += String.fromCharCode(e);
    }
    return result;
  }
  toHashString(buffer) {
    const byteArray = new Uint8Array(buffer);
    let result = '';
    for (let e of byteArray) {
      result += String.fromCharCode(e);
    }
    return result;
  }
}
DefaultHashHandler.ɵfac = function DefaultHashHandler_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DefaultHashHandler)();
};
DefaultHashHandler.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: DefaultHashHandler,
  factory: DefaultHashHandler.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultHashHandler, [{
    type: Injectable
  }], null, null);
})();

/**
 * Service for logging in and logging out with
 * OIDC and OAuth2. Supports implicit flow and
 * password flow.
 */
class OAuthService extends AuthConfig {
  constructor(ngZone, http, storage, tokenValidationHandler, config, urlHelper, logger, crypto, document, dateTimeService) {
    super();
    this.ngZone = ngZone;
    this.http = http;
    this.config = config;
    this.urlHelper = urlHelper;
    this.logger = logger;
    this.crypto = crypto;
    this.dateTimeService = dateTimeService;
    /**
     * @internal
     * Deprecated:  use property events instead
     */
    this.discoveryDocumentLoaded = false;
    /**
     * The received (passed around) state, when logging
     * in with implicit flow.
     */
    this.state = '';
    this.eventsSubject = new Subject();
    this.discoveryDocumentLoadedSubject = new Subject();
    this.grantTypesSupported = [];
    this.inImplicitFlow = false;
    this.saveNoncesInLocalStorage = false;
    this.debug('angular-oauth2-oidc v10');
    // See https://github.com/manfredsteyer/angular-oauth2-oidc/issues/773 for why this is needed
    this.document = document;
    if (!config) {
      config = {};
    }
    this.discoveryDocumentLoaded$ = this.discoveryDocumentLoadedSubject.asObservable();
    this.events = this.eventsSubject.asObservable();
    if (tokenValidationHandler) {
      this.tokenValidationHandler = tokenValidationHandler;
    }
    if (config) {
      this.configure(config);
    }
    try {
      if (storage) {
        this.setStorage(storage);
      } else if (typeof sessionStorage !== 'undefined') {
        this.setStorage(sessionStorage);
      }
    } catch (e) {
      console.error('No OAuthStorage provided and cannot access default (sessionStorage).' + 'Consider providing a custom OAuthStorage implementation in your module.', e);
    }
    // in IE, sessionStorage does not always survive a redirect
    if (this.checkLocalStorageAccessable()) {
      const ua = window?.navigator?.userAgent;
      const msie = ua?.includes('MSIE ') || ua?.includes('Trident');
      if (msie) {
        this.saveNoncesInLocalStorage = true;
      }
    }
    this.setupRefreshTimer();
  }
  checkLocalStorageAccessable() {
    if (typeof window === 'undefined') return false;
    const test = 'test';
    try {
      if (typeof window['localStorage'] === 'undefined') return false;
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
  /**
   * Use this method to configure the service
   * @param config the configuration
   */
  configure(config) {
    // For the sake of downward compatibility with
    // original configuration API
    Object.assign(this, new AuthConfig(), config);
    this.config = Object.assign({}, new AuthConfig(), config);
    if (this.sessionChecksEnabled) {
      this.setupSessionCheck();
    }
    this.configChanged();
  }
  configChanged() {
    this.setupRefreshTimer();
  }
  restartSessionChecksIfStillLoggedIn() {
    if (this.hasValidIdToken()) {
      this.initSessionCheck();
    }
  }
  restartRefreshTimerIfStillLoggedIn() {
    this.setupExpirationTimers();
  }
  setupSessionCheck() {
    this.events.pipe(filter(e => e.type === 'token_received')).subscribe(e => {
      this.initSessionCheck();
    });
  }
  /**
   * Will setup up silent refreshing for when the token is
   * about to expire. When the user is logged out via this.logOut method, the
   * silent refreshing will pause and not refresh the tokens until the user is
   * logged back in via receiving a new token.
   * @param params Additional parameter to pass
   * @param listenTo Setup automatic refresh of a specific token type
   */
  setupAutomaticSilentRefresh(params = {}, listenTo, noPrompt = true) {
    let shouldRunSilentRefresh = true;
    this.clearAutomaticRefreshTimer();
    this.automaticRefreshSubscription = this.events.pipe(tap(e => {
      if (e.type === 'token_received') {
        shouldRunSilentRefresh = true;
      } else if (e.type === 'logout') {
        shouldRunSilentRefresh = false;
      }
    }), filter(e => e.type === 'token_expires' && (listenTo == null || listenTo === 'any' || e.info === listenTo)), debounceTime(1000)).subscribe(_ => {
      if (shouldRunSilentRefresh) {
        // this.silentRefresh(params, noPrompt).catch(_ => {
        this.refreshInternal(params, noPrompt).catch(_ => {
          this.debug('Automatic silent refresh did not work');
        });
      }
    });
    this.restartRefreshTimerIfStillLoggedIn();
  }
  refreshInternal(params, noPrompt) {
    if (!this.useSilentRefresh && this.responseType === 'code') {
      return this.refreshToken();
    } else {
      return this.silentRefresh(params, noPrompt);
    }
  }
  /**
   * Convenience method that first calls `loadDiscoveryDocument(...)` and
   * directly chains using the `then(...)` part of the promise to call
   * the `tryLogin(...)` method.
   *
   * @param options LoginOptions to pass through to `tryLogin(...)`
   */
  loadDiscoveryDocumentAndTryLogin(options = null) {
    return this.loadDiscoveryDocument().then(doc => {
      return this.tryLogin(options);
    });
  }
  /**
   * Convenience method that first calls `loadDiscoveryDocumentAndTryLogin(...)`
   * and if then chains to `initLoginFlow()`, but only if there is no valid
   * IdToken or no valid AccessToken.
   *
   * @param options LoginOptions to pass through to `tryLogin(...)`
   */
  loadDiscoveryDocumentAndLogin(options = null) {
    options = options || {};
    return this.loadDiscoveryDocumentAndTryLogin(options).then(_ => {
      if (!this.hasValidIdToken() || !this.hasValidAccessToken()) {
        const state = typeof options.state === 'string' ? options.state : '';
        this.initLoginFlow(state);
        return false;
      } else {
        return true;
      }
    });
  }
  debug(...args) {
    if (this.showDebugInformation) {
      this.logger.debug.apply(this.logger, args);
    }
  }
  validateUrlFromDiscoveryDocument(url) {
    const errors = [];
    const httpsCheck = this.validateUrlForHttps(url);
    const issuerCheck = this.validateUrlAgainstIssuer(url);
    if (!httpsCheck) {
      errors.push('https for all urls required. Also for urls received by discovery.');
    }
    if (!issuerCheck) {
      errors.push('Every url in discovery document has to start with the issuer url.' + 'Also see property strictDiscoveryDocumentValidation.');
    }
    return errors;
  }
  validateUrlForHttps(url) {
    if (!url) {
      return true;
    }
    const lcUrl = url.toLowerCase();
    if (this.requireHttps === false) {
      return true;
    }
    if ((lcUrl.match(/^http:\/\/localhost($|[:\/])/) || lcUrl.match(/^http:\/\/localhost($|[:\/])/)) && this.requireHttps === 'remoteOnly') {
      return true;
    }
    return lcUrl.startsWith('https://');
  }
  assertUrlNotNullAndCorrectProtocol(url, description) {
    if (!url) {
      throw new Error(`'${description}' should not be null`);
    }
    if (!this.validateUrlForHttps(url)) {
      throw new Error(`'${description}' must use HTTPS (with TLS), or config value for property 'requireHttps' must be set to 'false' and allow HTTP (without TLS).`);
    }
  }
  validateUrlAgainstIssuer(url) {
    if (!this.strictDiscoveryDocumentValidation) {
      return true;
    }
    if (!url) {
      return true;
    }
    return url.toLowerCase().startsWith(this.issuer.toLowerCase());
  }
  setupRefreshTimer() {
    if (typeof window === 'undefined') {
      this.debug('timer not supported on this plattform');
      return;
    }
    if (this.hasValidIdToken() || this.hasValidAccessToken()) {
      this.clearAccessTokenTimer();
      this.clearIdTokenTimer();
      this.setupExpirationTimers();
    }
    if (this.tokenReceivedSubscription) this.tokenReceivedSubscription.unsubscribe();
    this.tokenReceivedSubscription = this.events.pipe(filter(e => e.type === 'token_received')).subscribe(_ => {
      this.clearAccessTokenTimer();
      this.clearIdTokenTimer();
      this.setupExpirationTimers();
    });
  }
  setupExpirationTimers() {
    if (this.hasValidAccessToken()) {
      this.setupAccessTokenTimer();
    }
    if (this.hasValidIdToken()) {
      this.setupIdTokenTimer();
    }
  }
  setupAccessTokenTimer() {
    const expiration = this.getAccessTokenExpiration();
    const storedAt = this.getAccessTokenStoredAt();
    const timeout = this.calcTimeout(storedAt, expiration);
    this.ngZone.runOutsideAngular(() => {
      this.accessTokenTimeoutSubscription = of(new OAuthInfoEvent('token_expires', 'access_token')).pipe(delay(timeout)).subscribe(e => {
        this.ngZone.run(() => {
          this.eventsSubject.next(e);
        });
      });
    });
  }
  setupIdTokenTimer() {
    const expiration = this.getIdTokenExpiration();
    const storedAt = this.getIdTokenStoredAt();
    const timeout = this.calcTimeout(storedAt, expiration);
    this.ngZone.runOutsideAngular(() => {
      this.idTokenTimeoutSubscription = of(new OAuthInfoEvent('token_expires', 'id_token')).pipe(delay(timeout)).subscribe(e => {
        this.ngZone.run(() => {
          this.eventsSubject.next(e);
        });
      });
    });
  }
  /**
   * Stops timers for automatic refresh.
   * To restart it, call setupAutomaticSilentRefresh again.
   */
  stopAutomaticRefresh() {
    this.clearAccessTokenTimer();
    this.clearIdTokenTimer();
    this.clearAutomaticRefreshTimer();
  }
  clearAccessTokenTimer() {
    if (this.accessTokenTimeoutSubscription) {
      this.accessTokenTimeoutSubscription.unsubscribe();
    }
  }
  clearIdTokenTimer() {
    if (this.idTokenTimeoutSubscription) {
      this.idTokenTimeoutSubscription.unsubscribe();
    }
  }
  clearAutomaticRefreshTimer() {
    if (this.automaticRefreshSubscription) {
      this.automaticRefreshSubscription.unsubscribe();
    }
  }
  calcTimeout(storedAt, expiration) {
    const now = this.dateTimeService.now();
    const delta = (expiration - storedAt) * this.timeoutFactor - (now - storedAt);
    return Math.max(0, delta);
  }
  /**
   * DEPRECATED. Use a provider for OAuthStorage instead:
   *
   * { provide: OAuthStorage, useFactory: oAuthStorageFactory }
   * export function oAuthStorageFactory(): OAuthStorage { return localStorage; }
   * Sets a custom storage used to store the received
   * tokens on client side. By default, the browser's
   * sessionStorage is used.
   * @ignore
   *
   * @param storage
   */
  setStorage(storage) {
    this._storage = storage;
    this.configChanged();
  }
  /**
   * Loads the discovery document to configure most
   * properties of this service. The url of the discovery
   * document is infered from the issuer's url according
   * to the OpenId Connect spec. To use another url you
   * can pass it to to optional parameter fullUrl.
   *
   * @param fullUrl
   */
  loadDiscoveryDocument(fullUrl = null) {
    return new Promise((resolve, reject) => {
      if (!fullUrl) {
        fullUrl = this.issuer || '';
        if (!fullUrl.endsWith('/')) {
          fullUrl += '/';
        }
        fullUrl += '.well-known/openid-configuration';
      }
      if (!this.validateUrlForHttps(fullUrl)) {
        reject("issuer  must use HTTPS (with TLS), or config value for property 'requireHttps' must be set to 'false' and allow HTTP (without TLS).");
        return;
      }
      this.http.get(fullUrl).subscribe(doc => {
        if (!this.validateDiscoveryDocument(doc)) {
          this.eventsSubject.next(new OAuthErrorEvent('discovery_document_validation_error', null));
          reject('discovery_document_validation_error');
          return;
        }
        this.loginUrl = doc.authorization_endpoint;
        this.logoutUrl = doc.end_session_endpoint || this.logoutUrl;
        this.grantTypesSupported = doc.grant_types_supported;
        this.issuer = doc.issuer;
        this.tokenEndpoint = doc.token_endpoint;
        this.userinfoEndpoint = doc.userinfo_endpoint || this.userinfoEndpoint;
        this.jwksUri = doc.jwks_uri;
        this.sessionCheckIFrameUrl = doc.check_session_iframe || this.sessionCheckIFrameUrl;
        this.discoveryDocumentLoaded = true;
        this.discoveryDocumentLoadedSubject.next(doc);
        this.revocationEndpoint = doc.revocation_endpoint || this.revocationEndpoint;
        if (this.sessionChecksEnabled) {
          this.restartSessionChecksIfStillLoggedIn();
        }
        this.loadJwks().then(jwks => {
          const result = {
            discoveryDocument: doc,
            jwks: jwks
          };
          const event = new OAuthSuccessEvent('discovery_document_loaded', result);
          this.eventsSubject.next(event);
          resolve(event);
          return;
        }).catch(err => {
          this.eventsSubject.next(new OAuthErrorEvent('discovery_document_load_error', err));
          reject(err);
          return;
        });
      }, err => {
        this.logger.error('error loading discovery document', err);
        this.eventsSubject.next(new OAuthErrorEvent('discovery_document_load_error', err));
        reject(err);
      });
    });
  }
  loadJwks() {
    return new Promise((resolve, reject) => {
      if (this.jwksUri) {
        this.http.get(this.jwksUri).subscribe(jwks => {
          this.jwks = jwks;
          this.eventsSubject.next(new OAuthSuccessEvent('discovery_document_loaded'));
          resolve(jwks);
        }, err => {
          this.logger.error('error loading jwks', err);
          this.eventsSubject.next(new OAuthErrorEvent('jwks_load_error', err));
          reject(err);
        });
      } else {
        resolve(null);
      }
    });
  }
  validateDiscoveryDocument(doc) {
    let errors;
    if (!this.skipIssuerCheck && doc.issuer !== this.issuer) {
      this.logger.error('invalid issuer in discovery document', 'expected: ' + this.issuer, 'current: ' + doc.issuer);
      return false;
    }
    errors = this.validateUrlFromDiscoveryDocument(doc.authorization_endpoint);
    if (errors.length > 0) {
      this.logger.error('error validating authorization_endpoint in discovery document', errors);
      return false;
    }
    errors = this.validateUrlFromDiscoveryDocument(doc.end_session_endpoint);
    if (errors.length > 0) {
      this.logger.error('error validating end_session_endpoint in discovery document', errors);
      return false;
    }
    errors = this.validateUrlFromDiscoveryDocument(doc.token_endpoint);
    if (errors.length > 0) {
      this.logger.error('error validating token_endpoint in discovery document', errors);
    }
    errors = this.validateUrlFromDiscoveryDocument(doc.revocation_endpoint);
    if (errors.length > 0) {
      this.logger.error('error validating revocation_endpoint in discovery document', errors);
    }
    errors = this.validateUrlFromDiscoveryDocument(doc.userinfo_endpoint);
    if (errors.length > 0) {
      this.logger.error('error validating userinfo_endpoint in discovery document', errors);
      return false;
    }
    errors = this.validateUrlFromDiscoveryDocument(doc.jwks_uri);
    if (errors.length > 0) {
      this.logger.error('error validating jwks_uri in discovery document', errors);
      return false;
    }
    if (this.sessionChecksEnabled && !doc.check_session_iframe) {
      this.logger.warn('sessionChecksEnabled is activated but discovery document' + ' does not contain a check_session_iframe field');
    }
    return true;
  }
  /**
   * Uses password flow to exchange userName and password for an
   * access_token. After receiving the access_token, this method
   * uses it to query the userinfo endpoint in order to get information
   * about the user in question.
   *
   * When using this, make sure that the property oidc is set to false.
   * Otherwise stricter validations take place that make this operation
   * fail.
   *
   * @param userName
   * @param password
   * @param headers Optional additional http-headers.
   */
  fetchTokenUsingPasswordFlowAndLoadUserProfile(userName, password, headers = new HttpHeaders()) {
    return this.fetchTokenUsingPasswordFlow(userName, password, headers).then(() => this.loadUserProfile());
  }
  /**
   * Loads the user profile by accessing the user info endpoint defined by OpenId Connect.
   *
   * When using this with OAuth2 password flow, make sure that the property oidc is set to false.
   * Otherwise stricter validations take place that make this operation fail.
   */
  loadUserProfile() {
    if (!this.hasValidAccessToken()) {
      throw new Error('Can not load User Profile without access_token');
    }
    if (!this.validateUrlForHttps(this.userinfoEndpoint)) {
      throw new Error("userinfoEndpoint must use HTTPS (with TLS), or config value for property 'requireHttps' must be set to 'false' and allow HTTP (without TLS).");
    }
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getAccessToken());
      this.http.get(this.userinfoEndpoint, {
        headers,
        observe: 'response',
        responseType: 'text'
      }).subscribe(response => {
        this.debug('userinfo received', JSON.stringify(response));
        if (response.headers.get('content-type').startsWith('application/json')) {
          let info = JSON.parse(response.body);
          const existingClaims = this.getIdentityClaims() || {};
          if (!this.skipSubjectCheck) {
            if (this.oidc && (!existingClaims['sub'] || info.sub !== existingClaims['sub'])) {
              const err = 'if property oidc is true, the received user-id (sub) has to be the user-id ' + 'of the user that has logged in with oidc.\n' + 'if you are not using oidc but just oauth2 password flow set oidc to false';
              reject(err);
              return;
            }
          }
          info = Object.assign({}, existingClaims, info);
          this._storage.setItem('id_token_claims_obj', JSON.stringify(info));
          this.eventsSubject.next(new OAuthSuccessEvent('user_profile_loaded'));
          resolve({
            info
          });
        } else {
          this.debug('userinfo is not JSON, treating it as JWE/JWS');
          this.eventsSubject.next(new OAuthSuccessEvent('user_profile_loaded'));
          resolve(JSON.parse(response.body));
        }
      }, err => {
        this.logger.error('error loading user info', err);
        this.eventsSubject.next(new OAuthErrorEvent('user_profile_load_error', err));
        reject(err);
      });
    });
  }
  /**
   * Uses password flow to exchange userName and password for an access_token.
   * @param userName
   * @param password
   * @param headers Optional additional http-headers.
   */
  fetchTokenUsingPasswordFlow(userName, password, headers = new HttpHeaders()) {
    const parameters = {
      username: userName,
      password: password
    };
    return this.fetchTokenUsingGrant('password', parameters, headers);
  }
  /**
   * Uses a custom grant type to retrieve tokens.
   * @param grantType Grant type.
   * @param parameters Parameters to pass.
   * @param headers Optional additional HTTP headers.
   */
  fetchTokenUsingGrant(grantType, parameters, headers = new HttpHeaders()) {
    this.assertUrlNotNullAndCorrectProtocol(this.tokenEndpoint, 'tokenEndpoint');
    /**
     * A `HttpParameterCodec` that uses `encodeURIComponent` and `decodeURIComponent` to
     * serialize and parse URL parameter keys and values.
     *
     * @stable
     */
    let params = new HttpParams({
      encoder: new WebHttpUrlEncodingCodec()
    }).set('grant_type', grantType).set('scope', this.scope);
    if (this.useHttpBasicAuth) {
      const header = btoa(`${this.clientId}:${this.dummyClientSecret}`);
      headers = headers.set('Authorization', 'Basic ' + header);
    }
    if (!this.useHttpBasicAuth) {
      params = params.set('client_id', this.clientId);
    }
    if (!this.useHttpBasicAuth && this.dummyClientSecret) {
      params = params.set('client_secret', this.dummyClientSecret);
    }
    if (this.customQueryParams) {
      for (const key of Object.getOwnPropertyNames(this.customQueryParams)) {
        params = params.set(key, this.customQueryParams[key]);
      }
    }
    // set explicit parameters last, to allow overwriting
    for (const key of Object.keys(parameters)) {
      params = params.set(key, parameters[key]);
    }
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return new Promise((resolve, reject) => {
      this.http.post(this.tokenEndpoint, params, {
        headers
      }).subscribe(tokenResponse => {
        this.debug('tokenResponse', tokenResponse);
        this.storeAccessTokenResponse(tokenResponse.access_token, tokenResponse.refresh_token, tokenResponse.expires_in || this.fallbackAccessTokenExpirationTimeInSec, tokenResponse.scope, this.extractRecognizedCustomParameters(tokenResponse));
        if (this.oidc && tokenResponse.id_token) {
          this.processIdToken(tokenResponse.id_token, tokenResponse.access_token).then(result => {
            this.storeIdToken(result);
            resolve(tokenResponse);
          });
        }
        this.eventsSubject.next(new OAuthSuccessEvent('token_received'));
        resolve(tokenResponse);
      }, err => {
        this.logger.error('Error performing ${grantType} flow', err);
        this.eventsSubject.next(new OAuthErrorEvent('token_error', err));
        reject(err);
      });
    });
  }
  /**
   * Refreshes the token using a refresh_token.
   * This does not work for implicit flow, b/c
   * there is no refresh_token in this flow.
   * A solution for this is provided by the
   * method silentRefresh.
   */
  refreshToken() {
    this.assertUrlNotNullAndCorrectProtocol(this.tokenEndpoint, 'tokenEndpoint');
    return new Promise((resolve, reject) => {
      let params = new HttpParams({
        encoder: new WebHttpUrlEncodingCodec()
      }).set('grant_type', 'refresh_token').set('scope', this.scope).set('refresh_token', this._storage.getItem('refresh_token'));
      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      if (this.useHttpBasicAuth) {
        const header = btoa(`${this.clientId}:${this.dummyClientSecret}`);
        headers = headers.set('Authorization', 'Basic ' + header);
      }
      if (!this.useHttpBasicAuth) {
        params = params.set('client_id', this.clientId);
      }
      if (!this.useHttpBasicAuth && this.dummyClientSecret) {
        params = params.set('client_secret', this.dummyClientSecret);
      }
      if (this.customQueryParams) {
        for (const key of Object.getOwnPropertyNames(this.customQueryParams)) {
          params = params.set(key, this.customQueryParams[key]);
        }
      }
      this.http.post(this.tokenEndpoint, params, {
        headers
      }).pipe(switchMap(tokenResponse => {
        if (tokenResponse.id_token) {
          return from(this.processIdToken(tokenResponse.id_token, tokenResponse.access_token, true)).pipe(tap(result => this.storeIdToken(result)), map(_ => tokenResponse));
        } else {
          return of(tokenResponse);
        }
      })).subscribe(tokenResponse => {
        this.debug('refresh tokenResponse', tokenResponse);
        this.storeAccessTokenResponse(tokenResponse.access_token, tokenResponse.refresh_token, tokenResponse.expires_in || this.fallbackAccessTokenExpirationTimeInSec, tokenResponse.scope, this.extractRecognizedCustomParameters(tokenResponse));
        this.eventsSubject.next(new OAuthSuccessEvent('token_received'));
        this.eventsSubject.next(new OAuthSuccessEvent('token_refreshed'));
        resolve(tokenResponse);
      }, err => {
        this.logger.error('Error refreshing token', err);
        this.eventsSubject.next(new OAuthErrorEvent('token_refresh_error', err));
        reject(err);
      });
    });
  }
  removeSilentRefreshEventListener() {
    if (this.silentRefreshPostMessageEventListener) {
      window.removeEventListener('message', this.silentRefreshPostMessageEventListener);
      this.silentRefreshPostMessageEventListener = null;
    }
  }
  setupSilentRefreshEventListener() {
    this.removeSilentRefreshEventListener();
    this.silentRefreshPostMessageEventListener = e => {
      const message = this.processMessageEventMessage(e);
      this.tryLogin({
        customHashFragment: message,
        preventClearHashAfterLogin: true,
        customRedirectUri: this.silentRefreshRedirectUri || this.redirectUri
      }).catch(err => this.debug('tryLogin during silent refresh failed', err));
    };
    window.addEventListener('message', this.silentRefreshPostMessageEventListener);
  }
  /**
   * Performs a silent refresh for implicit flow.
   * Use this method to get new tokens when/before
   * the existing tokens expire.
   */
  silentRefresh(params = {}, noPrompt = true) {
    const claims = this.getIdentityClaims() || {};
    if (this.useIdTokenHintForSilentRefresh && this.hasValidIdToken()) {
      params['id_token_hint'] = this.getIdToken();
    }
    if (!this.validateUrlForHttps(this.loginUrl)) {
      throw new Error("loginUrl  must use HTTPS (with TLS), or config value for property 'requireHttps' must be set to 'false' and allow HTTP (without TLS).");
    }
    if (typeof this.document === 'undefined') {
      throw new Error('silent refresh is not supported on this platform');
    }
    const existingIframe = this.document.getElementById(this.silentRefreshIFrameName);
    if (existingIframe) {
      this.document.body.removeChild(existingIframe);
    }
    this.silentRefreshSubject = claims['sub'];
    const iframe = this.document.createElement('iframe');
    iframe.id = this.silentRefreshIFrameName;
    this.setupSilentRefreshEventListener();
    const redirectUri = this.silentRefreshRedirectUri || this.redirectUri;
    this.createLoginUrl(null, null, redirectUri, noPrompt, params).then(url => {
      iframe.setAttribute('src', url);
      if (!this.silentRefreshShowIFrame) {
        iframe.style['display'] = 'none';
      }
      this.document.body.appendChild(iframe);
    });
    const errors = this.events.pipe(filter(e => e instanceof OAuthErrorEvent), first());
    const success = this.events.pipe(filter(e => e.type === 'token_received'), first());
    const timeout = of(new OAuthErrorEvent('silent_refresh_timeout', null)).pipe(delay(this.silentRefreshTimeout));
    return race([errors, success, timeout]).pipe(map(e => {
      if (e instanceof OAuthErrorEvent) {
        if (e.type === 'silent_refresh_timeout') {
          this.eventsSubject.next(e);
        } else {
          e = new OAuthErrorEvent('silent_refresh_error', e);
          this.eventsSubject.next(e);
        }
        throw e;
      } else if (e.type === 'token_received') {
        e = new OAuthSuccessEvent('silently_refreshed');
        this.eventsSubject.next(e);
      }
      return e;
    })).toPromise();
  }
  /**
   * This method exists for backwards compatibility.
   * {@link OAuthService#initLoginFlowInPopup} handles both code
   * and implicit flows.
   */
  initImplicitFlowInPopup(options) {
    return this.initLoginFlowInPopup(options);
  }
  initLoginFlowInPopup(options) {
    options = options || {};
    return this.createLoginUrl(null, null, this.silentRefreshRedirectUri, false, {
      display: 'popup'
    }).then(url => {
      return new Promise((resolve, reject) => {
        /**
         * Error handling section
         */
        const checkForPopupClosedInterval = 500;
        let windowRef = null;
        // If we got no window reference we open a window
        // else we are using the window already opened
        if (!options.windowRef) {
          windowRef = window.open(url, 'ngx-oauth2-oidc-login', this.calculatePopupFeatures(options));
        } else if (options.windowRef && !options.windowRef.closed) {
          windowRef = options.windowRef;
          windowRef.location.href = url;
        }
        let checkForPopupClosedTimer;
        const tryLogin = hash => {
          this.tryLogin({
            customHashFragment: hash,
            preventClearHashAfterLogin: true,
            customRedirectUri: this.silentRefreshRedirectUri
          }).then(() => {
            cleanup();
            resolve(true);
          }, err => {
            cleanup();
            reject(err);
          });
        };
        const checkForPopupClosed = () => {
          if (!windowRef || windowRef.closed) {
            cleanup();
            reject(new OAuthErrorEvent('popup_closed', {}));
          }
        };
        if (!windowRef) {
          reject(new OAuthErrorEvent('popup_blocked', {}));
        } else {
          checkForPopupClosedTimer = window.setInterval(checkForPopupClosed, checkForPopupClosedInterval);
        }
        const cleanup = () => {
          window.clearInterval(checkForPopupClosedTimer);
          window.removeEventListener('storage', storageListener);
          window.removeEventListener('message', listener);
          if (windowRef !== null) {
            windowRef.close();
          }
          windowRef = null;
        };
        const listener = e => {
          const message = this.processMessageEventMessage(e);
          if (message && message !== null) {
            window.removeEventListener('storage', storageListener);
            tryLogin(message);
          } else {
            console.log('false event firing');
          }
        };
        const storageListener = event => {
          if (event.key === 'auth_hash') {
            window.removeEventListener('message', listener);
            tryLogin(event.newValue);
          }
        };
        window.addEventListener('message', listener);
        window.addEventListener('storage', storageListener);
      });
    });
  }
  calculatePopupFeatures(options) {
    // Specify an static height and width and calculate centered position
    const height = options.height || 470;
    const width = options.width || 500;
    const left = window.screenLeft + (window.outerWidth - width) / 2;
    const top = window.screenTop + (window.outerHeight - height) / 2;
    return `location=no,toolbar=no,width=${width},height=${height},top=${top},left=${left}`;
  }
  processMessageEventMessage(e) {
    let expectedPrefix = '#';
    if (this.silentRefreshMessagePrefix) {
      expectedPrefix += this.silentRefreshMessagePrefix;
    }
    if (!e || !e.data || typeof e.data !== 'string') {
      return;
    }
    const prefixedMessage = e.data;
    if (!prefixedMessage.startsWith(expectedPrefix)) {
      return;
    }
    return '#' + prefixedMessage.substr(expectedPrefix.length);
  }
  canPerformSessionCheck() {
    if (!this.sessionChecksEnabled) {
      return false;
    }
    if (!this.sessionCheckIFrameUrl) {
      console.warn('sessionChecksEnabled is activated but there is no sessionCheckIFrameUrl');
      return false;
    }
    const sessionState = this.getSessionState();
    if (!sessionState) {
      console.warn('sessionChecksEnabled is activated but there is no session_state');
      return false;
    }
    if (typeof this.document === 'undefined') {
      return false;
    }
    return true;
  }
  setupSessionCheckEventListener() {
    this.removeSessionCheckEventListener();
    this.sessionCheckEventListener = e => {
      const origin = e.origin.toLowerCase();
      const issuer = this.issuer.toLowerCase();
      this.debug('sessionCheckEventListener');
      if (!issuer.startsWith(origin)) {
        this.debug('sessionCheckEventListener', 'wrong origin', origin, 'expected', issuer, 'event', e);
        return;
      }
      // only run in Angular zone if it is 'changed' or 'error'
      switch (e.data) {
        case 'unchanged':
          this.ngZone.run(() => {
            this.handleSessionUnchanged();
          });
          break;
        case 'changed':
          this.ngZone.run(() => {
            this.handleSessionChange();
          });
          break;
        case 'error':
          this.ngZone.run(() => {
            this.handleSessionError();
          });
          break;
      }
      this.debug('got info from session check inframe', e);
    };
    // prevent Angular from refreshing the view on every message (runs in intervals)
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('message', this.sessionCheckEventListener);
    });
  }
  handleSessionUnchanged() {
    this.debug('session check', 'session unchanged');
    this.eventsSubject.next(new OAuthInfoEvent('session_unchanged'));
  }
  handleSessionChange() {
    this.eventsSubject.next(new OAuthInfoEvent('session_changed'));
    this.stopSessionCheckTimer();
    if (!this.useSilentRefresh && this.responseType === 'code') {
      this.refreshToken().then(_ => {
        this.debug('token refresh after session change worked');
      }).catch(_ => {
        this.debug('token refresh did not work after session changed');
        this.eventsSubject.next(new OAuthInfoEvent('session_terminated'));
        this.logOut(true);
      });
    } else if (this.silentRefreshRedirectUri) {
      this.silentRefresh().catch(_ => this.debug('silent refresh failed after session changed'));
      this.waitForSilentRefreshAfterSessionChange();
    } else {
      this.eventsSubject.next(new OAuthInfoEvent('session_terminated'));
      this.logOut(true);
    }
  }
  waitForSilentRefreshAfterSessionChange() {
    this.events.pipe(filter(e => e.type === 'silently_refreshed' || e.type === 'silent_refresh_timeout' || e.type === 'silent_refresh_error'), first()).subscribe(e => {
      if (e.type !== 'silently_refreshed') {
        this.debug('silent refresh did not work after session changed');
        this.eventsSubject.next(new OAuthInfoEvent('session_terminated'));
        this.logOut(true);
      }
    });
  }
  handleSessionError() {
    this.stopSessionCheckTimer();
    this.eventsSubject.next(new OAuthInfoEvent('session_error'));
  }
  removeSessionCheckEventListener() {
    if (this.sessionCheckEventListener) {
      window.removeEventListener('message', this.sessionCheckEventListener);
      this.sessionCheckEventListener = null;
    }
  }
  initSessionCheck() {
    if (!this.canPerformSessionCheck()) {
      return;
    }
    const existingIframe = this.document.getElementById(this.sessionCheckIFrameName);
    if (existingIframe) {
      this.document.body.removeChild(existingIframe);
    }
    const iframe = this.document.createElement('iframe');
    iframe.id = this.sessionCheckIFrameName;
    this.setupSessionCheckEventListener();
    const url = this.sessionCheckIFrameUrl;
    iframe.setAttribute('src', url);
    iframe.style.display = 'none';
    this.document.body.appendChild(iframe);
    this.startSessionCheckTimer();
  }
  startSessionCheckTimer() {
    this.stopSessionCheckTimer();
    this.ngZone.runOutsideAngular(() => {
      this.sessionCheckTimer = setInterval(this.checkSession.bind(this), this.sessionCheckIntervall);
    });
  }
  stopSessionCheckTimer() {
    if (this.sessionCheckTimer) {
      clearInterval(this.sessionCheckTimer);
      this.sessionCheckTimer = null;
    }
  }
  checkSession() {
    const iframe = this.document.getElementById(this.sessionCheckIFrameName);
    if (!iframe) {
      this.logger.warn('checkSession did not find iframe', this.sessionCheckIFrameName);
    }
    const sessionState = this.getSessionState();
    if (!sessionState) {
      this.stopSessionCheckTimer();
    }
    const message = this.clientId + ' ' + sessionState;
    iframe.contentWindow.postMessage(message, this.issuer);
  }
  async createLoginUrl(state = '', loginHint = '', customRedirectUri = '', noPrompt = false, params = {}) {
    const that = this;
    let redirectUri;
    if (customRedirectUri) {
      redirectUri = customRedirectUri;
    } else {
      redirectUri = this.redirectUri;
    }
    const nonce = await this.createAndSaveNonce();
    if (state) {
      state = nonce + this.config.nonceStateSeparator + encodeURIComponent(state);
    } else {
      state = nonce;
    }
    if (!this.requestAccessToken && !this.oidc) {
      throw new Error('Either requestAccessToken or oidc or both must be true');
    }
    if (this.config.responseType) {
      this.responseType = this.config.responseType;
    } else {
      if (this.oidc && this.requestAccessToken) {
        this.responseType = 'id_token token';
      } else if (this.oidc && !this.requestAccessToken) {
        this.responseType = 'id_token';
      } else {
        this.responseType = 'token';
      }
    }
    const seperationChar = that.loginUrl.indexOf('?') > -1 ? '&' : '?';
    let scope = that.scope;
    if (this.oidc && !scope.match(/(^|\s)openid($|\s)/)) {
      scope = 'openid ' + scope;
    }
    let url = that.loginUrl + seperationChar + 'response_type=' + encodeURIComponent(that.responseType) + '&client_id=' + encodeURIComponent(that.clientId) + '&state=' + encodeURIComponent(state) + '&redirect_uri=' + encodeURIComponent(redirectUri) + '&scope=' + encodeURIComponent(scope);
    if (this.responseType.includes('code') && !this.disablePKCE) {
      const [challenge, verifier] = await this.createChallangeVerifierPairForPKCE();
      if (this.saveNoncesInLocalStorage && typeof window['localStorage'] !== 'undefined') {
        localStorage.setItem('PKCE_verifier', verifier);
      } else {
        this._storage.setItem('PKCE_verifier', verifier);
      }
      url += '&code_challenge=' + challenge;
      url += '&code_challenge_method=S256';
    }
    if (loginHint) {
      url += '&login_hint=' + encodeURIComponent(loginHint);
    }
    if (that.resource) {
      url += '&resource=' + encodeURIComponent(that.resource);
    }
    if (that.oidc) {
      url += '&nonce=' + encodeURIComponent(nonce);
    }
    if (noPrompt) {
      url += '&prompt=none';
    }
    for (const key of Object.keys(params)) {
      url += '&' + encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }
    if (this.customQueryParams) {
      for (const key of Object.getOwnPropertyNames(this.customQueryParams)) {
        url += '&' + key + '=' + encodeURIComponent(this.customQueryParams[key]);
      }
    }
    return url;
  }
  initImplicitFlowInternal(additionalState = '', params = '') {
    if (this.inImplicitFlow) {
      return;
    }
    this.inImplicitFlow = true;
    if (!this.validateUrlForHttps(this.loginUrl)) {
      throw new Error("loginUrl  must use HTTPS (with TLS), or config value for property 'requireHttps' must be set to 'false' and allow HTTP (without TLS).");
    }
    let addParams = {};
    let loginHint = null;
    if (typeof params === 'string') {
      loginHint = params;
    } else if (typeof params === 'object') {
      addParams = params;
    }
    this.createLoginUrl(additionalState, loginHint, null, false, addParams).then(this.config.openUri).catch(error => {
      console.error('Error in initImplicitFlow', error);
      this.inImplicitFlow = false;
    });
  }
  /**
   * Starts the implicit flow and redirects to user to
   * the auth servers' login url.
   *
   * @param additionalState Optional state that is passed around.
   *  You'll find this state in the property `state` after `tryLogin` logged in the user.
   * @param params Hash with additional parameter. If it is a string, it is used for the
   *               parameter loginHint (for the sake of compatibility with former versions)
   */
  initImplicitFlow(additionalState = '', params = '') {
    if (this.loginUrl !== '') {
      this.initImplicitFlowInternal(additionalState, params);
    } else {
      this.events.pipe(filter(e => e.type === 'discovery_document_loaded')).subscribe(_ => this.initImplicitFlowInternal(additionalState, params));
    }
  }
  /**
   * Reset current implicit flow
   *
   * @description This method allows resetting the current implict flow in order to be initialized again.
   */
  resetImplicitFlow() {
    this.inImplicitFlow = false;
  }
  callOnTokenReceivedIfExists(options) {
    const that = this;
    if (options.onTokenReceived) {
      const tokenParams = {
        idClaims: that.getIdentityClaims(),
        idToken: that.getIdToken(),
        accessToken: that.getAccessToken(),
        state: that.state
      };
      options.onTokenReceived(tokenParams);
    }
  }
  storeAccessTokenResponse(accessToken, refreshToken, expiresIn, grantedScopes, customParameters) {
    this._storage.setItem('access_token', accessToken);
    if (grantedScopes && !Array.isArray(grantedScopes)) {
      this._storage.setItem('granted_scopes', JSON.stringify(grantedScopes.split(' ')));
    } else if (grantedScopes && Array.isArray(grantedScopes)) {
      this._storage.setItem('granted_scopes', JSON.stringify(grantedScopes));
    }
    this._storage.setItem('access_token_stored_at', '' + this.dateTimeService.now());
    if (expiresIn) {
      const expiresInMilliSeconds = expiresIn * 1000;
      const now = this.dateTimeService.new();
      const expiresAt = now.getTime() + expiresInMilliSeconds;
      this._storage.setItem('expires_at', '' + expiresAt);
    }
    if (refreshToken) {
      this._storage.setItem('refresh_token', refreshToken);
    }
    if (customParameters) {
      customParameters.forEach((value, key) => {
        this._storage.setItem(key, value);
      });
    }
  }
  /**
   * Delegates to tryLoginImplicitFlow for the sake of competability
   * @param options Optional options.
   */
  tryLogin(options = null) {
    if (this.config.responseType === 'code') {
      return this.tryLoginCodeFlow(options).then(_ => true);
    } else {
      return this.tryLoginImplicitFlow(options);
    }
  }
  parseQueryString(queryString) {
    if (!queryString || queryString.length === 0) {
      return {};
    }
    if (queryString.charAt(0) === '?') {
      queryString = queryString.substr(1);
    }
    return this.urlHelper.parseQueryString(queryString);
  }
  async tryLoginCodeFlow(options = null) {
    options = options || {};
    const querySource = options.customHashFragment ? options.customHashFragment.substring(1) : window.location.search;
    const parts = this.getCodePartsFromUrl(querySource);
    const code = parts['code'];
    const state = parts['state'];
    const sessionState = parts['session_state'];
    if (!options.preventClearHashAfterLogin) {
      const href = location.origin + location.pathname + location.search.replace(/code=[^&\$]*/, '').replace(/scope=[^&\$]*/, '').replace(/state=[^&\$]*/, '').replace(/session_state=[^&\$]*/, '').replace(/^\?&/, '?').replace(/&$/, '').replace(/^\?$/, '').replace(/&+/g, '&').replace(/\?&/, '?').replace(/\?$/, '') + location.hash;
      history.replaceState(null, window.name, href);
    }
    let [nonceInState, userState] = this.parseState(state);
    this.state = userState;
    if (parts['error']) {
      this.debug('error trying to login');
      this.handleLoginError(options, parts);
      const err = new OAuthErrorEvent('code_error', {}, parts);
      this.eventsSubject.next(err);
      return Promise.reject(err);
    }
    if (!options.disableNonceCheck) {
      if (!nonceInState) {
        this.saveRequestedRoute();
        return Promise.resolve();
      }
      if (!options.disableOAuth2StateCheck) {
        const success = this.validateNonce(nonceInState);
        if (!success) {
          const event = new OAuthErrorEvent('invalid_nonce_in_state', null);
          this.eventsSubject.next(event);
          return Promise.reject(event);
        }
      }
      this.storeSessionState(sessionState);
      if (code) {
        await this.getTokenFromCode(code, options);
        this.restoreRequestedRoute();
        return Promise.resolve();
      } else {
        return Promise.resolve();
      }
    }
    return Promise.reject();
  }
  saveRequestedRoute() {
    if (this.config.preserveRequestedRoute) {
      this._storage.setItem('requested_route', window.location.pathname + window.location.search);
    }
  }
  restoreRequestedRoute() {
    const requestedRoute = this._storage.getItem('requested_route');
    if (requestedRoute) {
      history.replaceState(null, '', window.location.origin + requestedRoute);
    }
  }
  /**
   * Retrieve the returned auth code from the redirect uri that has been called.
   * If required also check hash, as we could use hash location strategy.
   */
  getCodePartsFromUrl(queryString) {
    if (!queryString || queryString.length === 0) {
      return this.urlHelper.getHashFragmentParams();
    }
    // normalize query string
    if (queryString.charAt(0) === '?') {
      queryString = queryString.substr(1);
    }
    return this.urlHelper.parseQueryString(queryString);
  }
  /**
   * Get token using an intermediate code. Works for the Authorization Code flow.
   */
  getTokenFromCode(code, options) {
    let params = new HttpParams({
      encoder: new WebHttpUrlEncodingCodec()
    }).set('grant_type', 'authorization_code').set('code', code).set('redirect_uri', options.customRedirectUri || this.redirectUri);
    if (!this.disablePKCE) {
      let PKCEVerifier;
      if (this.saveNoncesInLocalStorage && typeof window['localStorage'] !== 'undefined') {
        PKCEVerifier = localStorage.getItem('PKCE_verifier');
      } else {
        PKCEVerifier = this._storage.getItem('PKCE_verifier');
      }
      if (!PKCEVerifier) {
        console.warn('No PKCE verifier found in oauth storage!');
      } else {
        params = params.set('code_verifier', PKCEVerifier);
      }
    }
    return this.fetchAndProcessToken(params, options);
  }
  fetchAndProcessToken(params, options) {
    options = options || {};
    this.assertUrlNotNullAndCorrectProtocol(this.tokenEndpoint, 'tokenEndpoint');
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    if (this.useHttpBasicAuth) {
      const header = btoa(`${this.clientId}:${this.dummyClientSecret}`);
      headers = headers.set('Authorization', 'Basic ' + header);
    }
    if (!this.useHttpBasicAuth) {
      params = params.set('client_id', this.clientId);
    }
    if (!this.useHttpBasicAuth && this.dummyClientSecret) {
      params = params.set('client_secret', this.dummyClientSecret);
    }
    return new Promise((resolve, reject) => {
      if (this.customQueryParams) {
        for (let key of Object.getOwnPropertyNames(this.customQueryParams)) {
          params = params.set(key, this.customQueryParams[key]);
        }
      }
      this.http.post(this.tokenEndpoint, params, {
        headers
      }).subscribe(tokenResponse => {
        this.debug('refresh tokenResponse', tokenResponse);
        this.storeAccessTokenResponse(tokenResponse.access_token, tokenResponse.refresh_token, tokenResponse.expires_in || this.fallbackAccessTokenExpirationTimeInSec, tokenResponse.scope, this.extractRecognizedCustomParameters(tokenResponse));
        if (this.oidc && tokenResponse.id_token) {
          this.processIdToken(tokenResponse.id_token, tokenResponse.access_token, options.disableNonceCheck).then(result => {
            this.storeIdToken(result);
            this.eventsSubject.next(new OAuthSuccessEvent('token_received'));
            this.eventsSubject.next(new OAuthSuccessEvent('token_refreshed'));
            resolve(tokenResponse);
          }).catch(reason => {
            this.eventsSubject.next(new OAuthErrorEvent('token_validation_error', reason));
            console.error('Error validating tokens');
            console.error(reason);
            reject(reason);
          });
        } else {
          this.eventsSubject.next(new OAuthSuccessEvent('token_received'));
          this.eventsSubject.next(new OAuthSuccessEvent('token_refreshed'));
          resolve(tokenResponse);
        }
      }, err => {
        console.error('Error getting token', err);
        this.eventsSubject.next(new OAuthErrorEvent('token_refresh_error', err));
        reject(err);
      });
    });
  }
  /**
   * Checks whether there are tokens in the hash fragment
   * as a result of the implicit flow. These tokens are
   * parsed, validated and used to sign the user in to the
   * current client.
   *
   * @param options Optional options.
   */
  tryLoginImplicitFlow(options = null) {
    options = options || {};
    let parts;
    if (options.customHashFragment) {
      parts = this.urlHelper.getHashFragmentParams(options.customHashFragment);
    } else {
      parts = this.urlHelper.getHashFragmentParams();
    }
    this.debug('parsed url', parts);
    const state = parts['state'];
    let [nonceInState, userState] = this.parseState(state);
    this.state = userState;
    if (parts['error']) {
      this.debug('error trying to login');
      this.handleLoginError(options, parts);
      const err = new OAuthErrorEvent('token_error', {}, parts);
      this.eventsSubject.next(err);
      return Promise.reject(err);
    }
    const accessToken = parts['access_token'];
    const idToken = parts['id_token'];
    const sessionState = parts['session_state'];
    const grantedScopes = parts['scope'];
    if (!this.requestAccessToken && !this.oidc) {
      return Promise.reject('Either requestAccessToken or oidc (or both) must be true.');
    }
    if (this.requestAccessToken && !accessToken) {
      return Promise.resolve(false);
    }
    if (this.requestAccessToken && !options.disableOAuth2StateCheck && !state) {
      return Promise.resolve(false);
    }
    if (this.oidc && !idToken) {
      return Promise.resolve(false);
    }
    if (this.sessionChecksEnabled && !sessionState) {
      this.logger.warn('session checks (Session Status Change Notification) ' + 'were activated in the configuration but the id_token ' + 'does not contain a session_state claim');
    }
    if (this.requestAccessToken && !options.disableNonceCheck) {
      const success = this.validateNonce(nonceInState);
      if (!success) {
        const event = new OAuthErrorEvent('invalid_nonce_in_state', null);
        this.eventsSubject.next(event);
        return Promise.reject(event);
      }
    }
    if (this.requestAccessToken) {
      this.storeAccessTokenResponse(accessToken, null, parts['expires_in'] || this.fallbackAccessTokenExpirationTimeInSec, grantedScopes);
    }
    if (!this.oidc) {
      this.eventsSubject.next(new OAuthSuccessEvent('token_received'));
      if (this.clearHashAfterLogin && !options.preventClearHashAfterLogin) {
        this.clearLocationHash();
      }
      this.callOnTokenReceivedIfExists(options);
      return Promise.resolve(true);
    }
    return this.processIdToken(idToken, accessToken, options.disableNonceCheck).then(result => {
      if (options.validationHandler) {
        return options.validationHandler({
          accessToken: accessToken,
          idClaims: result.idTokenClaims,
          idToken: result.idToken,
          state: state
        }).then(_ => result);
      }
      return result;
    }).then(result => {
      this.storeIdToken(result);
      this.storeSessionState(sessionState);
      if (this.clearHashAfterLogin && !options.preventClearHashAfterLogin) {
        this.clearLocationHash();
      }
      this.eventsSubject.next(new OAuthSuccessEvent('token_received'));
      this.callOnTokenReceivedIfExists(options);
      this.inImplicitFlow = false;
      return true;
    }).catch(reason => {
      this.eventsSubject.next(new OAuthErrorEvent('token_validation_error', reason));
      this.logger.error('Error validating tokens');
      this.logger.error(reason);
      return Promise.reject(reason);
    });
  }
  parseState(state) {
    let nonce = state;
    let userState = '';
    if (state) {
      const idx = state.indexOf(this.config.nonceStateSeparator);
      if (idx > -1) {
        nonce = state.substr(0, idx);
        userState = state.substr(idx + this.config.nonceStateSeparator.length);
      }
    }
    return [nonce, userState];
  }
  validateNonce(nonceInState) {
    let savedNonce;
    if (this.saveNoncesInLocalStorage && typeof window['localStorage'] !== 'undefined') {
      savedNonce = localStorage.getItem('nonce');
    } else {
      savedNonce = this._storage.getItem('nonce');
    }
    if (savedNonce !== nonceInState) {
      const err = 'Validating access_token failed, wrong state/nonce.';
      console.error(err, savedNonce, nonceInState);
      return false;
    }
    return true;
  }
  storeIdToken(idToken) {
    this._storage.setItem('id_token', idToken.idToken);
    this._storage.setItem('id_token_claims_obj', idToken.idTokenClaimsJson);
    this._storage.setItem('id_token_expires_at', '' + idToken.idTokenExpiresAt);
    this._storage.setItem('id_token_stored_at', '' + this.dateTimeService.now());
  }
  storeSessionState(sessionState) {
    this._storage.setItem('session_state', sessionState);
  }
  getSessionState() {
    return this._storage.getItem('session_state');
  }
  handleLoginError(options, parts) {
    if (options.onLoginError) {
      options.onLoginError(parts);
    }
    if (this.clearHashAfterLogin && !options.preventClearHashAfterLogin) {
      this.clearLocationHash();
    }
  }
  getClockSkewInMsec(defaultSkewMsc = 600000) {
    if (!this.clockSkewInSec) {
      return defaultSkewMsc;
    }
    return this.clockSkewInSec * 1000;
  }
  /**
   * @ignore
   */
  processIdToken(idToken, accessToken, skipNonceCheck = false) {
    const tokenParts = idToken.split('.');
    const headerBase64 = this.padBase64(tokenParts[0]);
    const headerJson = b64DecodeUnicode(headerBase64);
    const header = JSON.parse(headerJson);
    const claimsBase64 = this.padBase64(tokenParts[1]);
    const claimsJson = b64DecodeUnicode(claimsBase64);
    const claims = JSON.parse(claimsJson);
    let savedNonce;
    if (this.saveNoncesInLocalStorage && typeof window['localStorage'] !== 'undefined') {
      savedNonce = localStorage.getItem('nonce');
    } else {
      savedNonce = this._storage.getItem('nonce');
    }
    if (Array.isArray(claims.aud)) {
      if (claims.aud.every(v => v !== this.clientId)) {
        const err = 'Wrong audience: ' + claims.aud.join(',');
        this.logger.warn(err);
        return Promise.reject(err);
      }
    } else {
      if (claims.aud !== this.clientId) {
        const err = 'Wrong audience: ' + claims.aud;
        this.logger.warn(err);
        return Promise.reject(err);
      }
    }
    if (!claims.sub) {
      const err = 'No sub claim in id_token';
      this.logger.warn(err);
      return Promise.reject(err);
    }
    /* For now, we only check whether the sub against
     * silentRefreshSubject when sessionChecksEnabled is on
     * We will reconsider in a later version to do this
     * in every other case too.
     */
    if (this.sessionChecksEnabled && this.silentRefreshSubject && this.silentRefreshSubject !== claims['sub']) {
      const err = 'After refreshing, we got an id_token for another user (sub). ' + `Expected sub: ${this.silentRefreshSubject}, received sub: ${claims['sub']}`;
      this.logger.warn(err);
      return Promise.reject(err);
    }
    if (!claims.iat) {
      const err = 'No iat claim in id_token';
      this.logger.warn(err);
      return Promise.reject(err);
    }
    if (!this.skipIssuerCheck && claims.iss !== this.issuer) {
      const err = 'Wrong issuer: ' + claims.iss;
      this.logger.warn(err);
      return Promise.reject(err);
    }
    if (!skipNonceCheck && claims.nonce !== savedNonce) {
      const err = 'Wrong nonce: ' + claims.nonce;
      this.logger.warn(err);
      return Promise.reject(err);
    }
    // at_hash is not applicable to authorization code flow
    // addressing https://github.com/manfredsteyer/angular-oauth2-oidc/issues/661
    // i.e. Based on spec the at_hash check is only true for implicit code flow on Ping Federate
    // https://www.pingidentity.com/developer/en/resources/openid-connect-developers-guide.html
    if (this.hasOwnProperty('responseType') && (this.responseType === 'code' || this.responseType === 'id_token')) {
      this.disableAtHashCheck = true;
    }
    if (!this.disableAtHashCheck && this.requestAccessToken && !claims['at_hash']) {
      const err = 'An at_hash is needed!';
      this.logger.warn(err);
      return Promise.reject(err);
    }
    const now = this.dateTimeService.now();
    const issuedAtMSec = claims.iat * 1000;
    const expiresAtMSec = claims.exp * 1000;
    const clockSkewInMSec = this.getClockSkewInMsec(); // (this.getClockSkewInMsec() || 600) * 1000;
    if (issuedAtMSec - clockSkewInMSec >= now || expiresAtMSec + clockSkewInMSec <= now) {
      const err = 'Token has expired';
      console.error(err);
      console.error({
        now: now,
        issuedAtMSec: issuedAtMSec,
        expiresAtMSec: expiresAtMSec
      });
      return Promise.reject(err);
    }
    const validationParams = {
      accessToken: accessToken,
      idToken: idToken,
      jwks: this.jwks,
      idTokenClaims: claims,
      idTokenHeader: header,
      loadKeys: () => this.loadJwks()
    };
    if (this.disableAtHashCheck) {
      return this.checkSignature(validationParams).then(_ => {
        const result = {
          idToken: idToken,
          idTokenClaims: claims,
          idTokenClaimsJson: claimsJson,
          idTokenHeader: header,
          idTokenHeaderJson: headerJson,
          idTokenExpiresAt: expiresAtMSec
        };
        return result;
      });
    }
    return this.checkAtHash(validationParams).then(atHashValid => {
      if (!this.disableAtHashCheck && this.requestAccessToken && !atHashValid) {
        const err = 'Wrong at_hash';
        this.logger.warn(err);
        return Promise.reject(err);
      }
      return this.checkSignature(validationParams).then(_ => {
        const atHashCheckEnabled = !this.disableAtHashCheck;
        const result = {
          idToken: idToken,
          idTokenClaims: claims,
          idTokenClaimsJson: claimsJson,
          idTokenHeader: header,
          idTokenHeaderJson: headerJson,
          idTokenExpiresAt: expiresAtMSec
        };
        if (atHashCheckEnabled) {
          return this.checkAtHash(validationParams).then(atHashValid => {
            if (this.requestAccessToken && !atHashValid) {
              const err = 'Wrong at_hash';
              this.logger.warn(err);
              return Promise.reject(err);
            } else {
              return result;
            }
          });
        } else {
          return result;
        }
      });
    });
  }
  /**
   * Returns the received claims about the user.
   */
  getIdentityClaims() {
    const claims = this._storage.getItem('id_token_claims_obj');
    if (!claims) {
      return null;
    }
    return JSON.parse(claims);
  }
  /**
   * Returns the granted scopes from the server.
   */
  getGrantedScopes() {
    const scopes = this._storage.getItem('granted_scopes');
    if (!scopes) {
      return null;
    }
    return JSON.parse(scopes);
  }
  /**
   * Returns the current id_token.
   */
  getIdToken() {
    return this._storage ? this._storage.getItem('id_token') : null;
  }
  padBase64(base64data) {
    while (base64data.length % 4 !== 0) {
      base64data += '=';
    }
    return base64data;
  }
  /**
   * Returns the current access_token.
   */
  getAccessToken() {
    return this._storage ? this._storage.getItem('access_token') : null;
  }
  getRefreshToken() {
    return this._storage ? this._storage.getItem('refresh_token') : null;
  }
  /**
   * Returns the expiration date of the access_token
   * as milliseconds since 1970.
   */
  getAccessTokenExpiration() {
    if (!this._storage.getItem('expires_at')) {
      return null;
    }
    return parseInt(this._storage.getItem('expires_at'), 10);
  }
  getAccessTokenStoredAt() {
    return parseInt(this._storage.getItem('access_token_stored_at'), 10);
  }
  getIdTokenStoredAt() {
    return parseInt(this._storage.getItem('id_token_stored_at'), 10);
  }
  /**
   * Returns the expiration date of the id_token
   * as milliseconds since 1970.
   */
  getIdTokenExpiration() {
    if (!this._storage.getItem('id_token_expires_at')) {
      return null;
    }
    return parseInt(this._storage.getItem('id_token_expires_at'), 10);
  }
  /**
   * Checkes, whether there is a valid access_token.
   */
  hasValidAccessToken() {
    if (this.getAccessToken()) {
      const expiresAt = this._storage.getItem('expires_at');
      const now = this.dateTimeService.new();
      if (expiresAt && parseInt(expiresAt, 10) < now.getTime() - this.getClockSkewInMsec()) {
        return false;
      }
      return true;
    }
    return false;
  }
  /**
   * Checks whether there is a valid id_token.
   */
  hasValidIdToken() {
    if (this.getIdToken()) {
      const expiresAt = this._storage.getItem('id_token_expires_at');
      const now = this.dateTimeService.new();
      if (expiresAt && parseInt(expiresAt, 10) < now.getTime() - this.getClockSkewInMsec()) {
        return false;
      }
      return true;
    }
    return false;
  }
  /**
   * Retrieve a saved custom property of the TokenReponse object. Only if predefined in authconfig.
   */
  getCustomTokenResponseProperty(requestedProperty) {
    return this._storage && this.config.customTokenParameters && this.config.customTokenParameters.indexOf(requestedProperty) >= 0 && this._storage.getItem(requestedProperty) !== null ? JSON.parse(this._storage.getItem(requestedProperty)) : null;
  }
  /**
   * Returns the auth-header that can be used
   * to transmit the access_token to a service
   */
  authorizationHeader() {
    return 'Bearer ' + this.getAccessToken();
  }
  logOut(customParameters = {}, state = '') {
    let noRedirectToLogoutUrl = false;
    if (typeof customParameters === 'boolean') {
      noRedirectToLogoutUrl = customParameters;
      customParameters = {};
    }
    const id_token = this.getIdToken();
    this._storage.removeItem('access_token');
    this._storage.removeItem('id_token');
    this._storage.removeItem('refresh_token');
    if (this.saveNoncesInLocalStorage) {
      localStorage.removeItem('nonce');
      localStorage.removeItem('PKCE_verifier');
    } else {
      this._storage.removeItem('nonce');
      this._storage.removeItem('PKCE_verifier');
    }
    this._storage.removeItem('expires_at');
    this._storage.removeItem('id_token_claims_obj');
    this._storage.removeItem('id_token_expires_at');
    this._storage.removeItem('id_token_stored_at');
    this._storage.removeItem('access_token_stored_at');
    this._storage.removeItem('granted_scopes');
    this._storage.removeItem('session_state');
    if (this.config.customTokenParameters) {
      this.config.customTokenParameters.forEach(customParam => this._storage.removeItem(customParam));
    }
    this.silentRefreshSubject = null;
    this.eventsSubject.next(new OAuthInfoEvent('logout'));
    if (!this.logoutUrl) {
      return;
    }
    if (noRedirectToLogoutUrl) {
      return;
    }
    if (!id_token && !this.postLogoutRedirectUri) {
      return;
    }
    let logoutUrl;
    if (!this.validateUrlForHttps(this.logoutUrl)) {
      throw new Error("logoutUrl  must use HTTPS (with TLS), or config value for property 'requireHttps' must be set to 'false' and allow HTTP (without TLS).");
    }
    // For backward compatibility
    if (this.logoutUrl.indexOf('{{') > -1) {
      logoutUrl = this.logoutUrl.replace(/\{\{id_token\}\}/, encodeURIComponent(id_token)).replace(/\{\{client_id\}\}/, encodeURIComponent(this.clientId));
    } else {
      let params = new HttpParams({
        encoder: new WebHttpUrlEncodingCodec()
      });
      if (id_token) {
        params = params.set('id_token_hint', id_token);
      }
      const postLogoutUrl = this.postLogoutRedirectUri || this.redirectUriAsPostLogoutRedirectUriFallback && this.redirectUri || '';
      if (postLogoutUrl) {
        params = params.set('post_logout_redirect_uri', postLogoutUrl);
        if (state) {
          params = params.set('state', state);
        }
      }
      for (let key in customParameters) {
        params = params.set(key, customParameters[key]);
      }
      logoutUrl = this.logoutUrl + (this.logoutUrl.indexOf('?') > -1 ? '&' : '?') + params.toString();
    }
    this.config.openUri(logoutUrl);
  }
  /**
   * @ignore
   */
  createAndSaveNonce() {
    const that = this;
    return this.createNonce().then(function (nonce) {
      // Use localStorage for nonce if possible
      // localStorage is the only storage who survives a
      // redirect in ALL browsers (also IE)
      // Otherwiese we'd force teams who have to support
      // IE into using localStorage for everything
      if (that.saveNoncesInLocalStorage && typeof window['localStorage'] !== 'undefined') {
        localStorage.setItem('nonce', nonce);
      } else {
        that._storage.setItem('nonce', nonce);
      }
      return nonce;
    });
  }
  /**
   * @ignore
   */
  ngOnDestroy() {
    this.clearAccessTokenTimer();
    this.clearIdTokenTimer();
    this.removeSilentRefreshEventListener();
    const silentRefreshFrame = this.document.getElementById(this.silentRefreshIFrameName);
    if (silentRefreshFrame) {
      silentRefreshFrame.remove();
    }
    this.stopSessionCheckTimer();
    this.removeSessionCheckEventListener();
    const sessionCheckFrame = this.document.getElementById(this.sessionCheckIFrameName);
    if (sessionCheckFrame) {
      sessionCheckFrame.remove();
    }
  }
  createNonce() {
    return new Promise(resolve => {
      if (this.rngUrl) {
        throw new Error('createNonce with rng-web-api has not been implemented so far');
      }
      /*
       * This alphabet is from:
       * https://tools.ietf.org/html/rfc7636#section-4.1
       *
       * [A-Z] / [a-z] / [0-9] / "-" / "." / "_" / "~"
       */
      const unreserved = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
      let size = 45;
      let id = '';
      const crypto = typeof self === 'undefined' ? null : self.crypto || self['msCrypto'];
      if (crypto) {
        let bytes = new Uint8Array(size);
        crypto.getRandomValues(bytes);
        // Needed for IE
        if (!bytes.map) {
          bytes.map = Array.prototype.map;
        }
        bytes = bytes.map(x => unreserved.charCodeAt(x % unreserved.length));
        id = String.fromCharCode.apply(null, bytes);
      } else {
        while (0 < size--) {
          id += unreserved[Math.random() * unreserved.length | 0];
        }
      }
      resolve(base64UrlEncode(id));
    });
  }
  async checkAtHash(params) {
    if (!this.tokenValidationHandler) {
      this.logger.warn('No tokenValidationHandler configured. Cannot check at_hash.');
      return true;
    }
    return this.tokenValidationHandler.validateAtHash(params);
  }
  checkSignature(params) {
    if (!this.tokenValidationHandler) {
      this.logger.warn('No tokenValidationHandler configured. Cannot check signature.');
      return Promise.resolve(null);
    }
    return this.tokenValidationHandler.validateSignature(params);
  }
  /**
   * Start the implicit flow or the code flow,
   * depending on your configuration.
   */
  initLoginFlow(additionalState = '', params = {}) {
    if (this.responseType === 'code') {
      return this.initCodeFlow(additionalState, params);
    } else {
      return this.initImplicitFlow(additionalState, params);
    }
  }
  /**
   * Starts the authorization code flow and redirects to user to
   * the auth servers login url.
   */
  initCodeFlow(additionalState = '', params = {}) {
    if (this.loginUrl !== '') {
      this.initCodeFlowInternal(additionalState, params);
    } else {
      this.events.pipe(filter(e => e.type === 'discovery_document_loaded')).subscribe(_ => this.initCodeFlowInternal(additionalState, params));
    }
  }
  initCodeFlowInternal(additionalState = '', params = {}) {
    if (!this.validateUrlForHttps(this.loginUrl)) {
      throw new Error("loginUrl  must use HTTPS (with TLS), or config value for property 'requireHttps' must be set to 'false' and allow HTTP (without TLS).");
    }
    let addParams = {};
    let loginHint = null;
    if (typeof params === 'string') {
      loginHint = params;
    } else if (typeof params === 'object') {
      addParams = params;
    }
    this.createLoginUrl(additionalState, loginHint, null, false, addParams).then(this.config.openUri).catch(error => {
      console.error('Error in initAuthorizationCodeFlow');
      console.error(error);
    });
  }
  async createChallangeVerifierPairForPKCE() {
    if (!this.crypto) {
      throw new Error('PKCE support for code flow needs a CryptoHander. Did you import the OAuthModule using forRoot() ?');
    }
    const verifier = await this.createNonce();
    const challengeRaw = await this.crypto.calcHash(verifier, 'sha-256');
    const challenge = base64UrlEncode(challengeRaw);
    return [challenge, verifier];
  }
  extractRecognizedCustomParameters(tokenResponse) {
    let foundParameters = new Map();
    if (!this.config.customTokenParameters) {
      return foundParameters;
    }
    this.config.customTokenParameters.forEach(recognizedParameter => {
      if (tokenResponse[recognizedParameter]) {
        foundParameters.set(recognizedParameter, JSON.stringify(tokenResponse[recognizedParameter]));
      }
    });
    return foundParameters;
  }
  /**
   * Revokes the auth token to secure the vulnarability
   * of the token issued allowing the authorization server to clean
   * up any security credentials associated with the authorization
   */
  revokeTokenAndLogout(customParameters = {}, ignoreCorsIssues = false) {
    let revokeEndpoint = this.revocationEndpoint;
    let accessToken = this.getAccessToken();
    let refreshToken = this.getRefreshToken();
    if (!accessToken) {
      return;
    }
    let params = new HttpParams({
      encoder: new WebHttpUrlEncodingCodec()
    });
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    if (this.useHttpBasicAuth) {
      const header = btoa(`${this.clientId}:${this.dummyClientSecret}`);
      headers = headers.set('Authorization', 'Basic ' + header);
    }
    if (!this.useHttpBasicAuth) {
      params = params.set('client_id', this.clientId);
    }
    if (!this.useHttpBasicAuth && this.dummyClientSecret) {
      params = params.set('client_secret', this.dummyClientSecret);
    }
    if (this.customQueryParams) {
      for (const key of Object.getOwnPropertyNames(this.customQueryParams)) {
        params = params.set(key, this.customQueryParams[key]);
      }
    }
    return new Promise((resolve, reject) => {
      let revokeAccessToken;
      let revokeRefreshToken;
      if (accessToken) {
        let revokationParams = params.set('token', accessToken).set('token_type_hint', 'access_token');
        revokeAccessToken = this.http.post(revokeEndpoint, revokationParams, {
          headers
        });
      } else {
        revokeAccessToken = of(null);
      }
      if (refreshToken) {
        let revokationParams = params.set('token', refreshToken).set('token_type_hint', 'refresh_token');
        revokeRefreshToken = this.http.post(revokeEndpoint, revokationParams, {
          headers
        });
      } else {
        revokeRefreshToken = of(null);
      }
      if (ignoreCorsIssues) {
        revokeAccessToken = revokeAccessToken.pipe(catchError(err => {
          if (err.status === 0) {
            return of(null);
          }
          return throwError(err);
        }));
        revokeRefreshToken = revokeRefreshToken.pipe(catchError(err => {
          if (err.status === 0) {
            return of(null);
          }
          return throwError(err);
        }));
      }
      combineLatest([revokeAccessToken, revokeRefreshToken]).subscribe(res => {
        this.logOut(customParameters);
        resolve(res);
        this.logger.info('Token successfully revoked');
      }, err => {
        this.logger.error('Error revoking token', err);
        this.eventsSubject.next(new OAuthErrorEvent('token_revoke_error', err));
        reject(err);
      });
    });
  }
  /**
   * Clear location.hash if it's present
   */
  clearLocationHash() {
    // Checking for empty hash is necessary for Firefox
    // as setting an empty hash to an empty string adds # to the URL
    if (location.hash != '') {
      location.hash = '';
    }
  }
}
OAuthService.ɵfac = function OAuthService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OAuthService)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(OAuthStorage, 8), i0.ɵɵinject(ValidationHandler, 8), i0.ɵɵinject(AuthConfig, 8), i0.ɵɵinject(UrlHelperService), i0.ɵɵinject(OAuthLogger), i0.ɵɵinject(HashHandler, 8), i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(DateTimeProvider));
};
OAuthService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: OAuthService,
  factory: OAuthService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OAuthService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: i1.HttpClient
    }, {
      type: OAuthStorage,
      decorators: [{
        type: Optional
      }]
    }, {
      type: ValidationHandler,
      decorators: [{
        type: Optional
      }]
    }, {
      type: AuthConfig,
      decorators: [{
        type: Optional
      }]
    }, {
      type: UrlHelperService
    }, {
      type: OAuthLogger
    }, {
      type: HashHandler,
      decorators: [{
        type: Optional
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: DateTimeProvider
    }];
  }, null);
})();
class OAuthModuleConfig {}
class OAuthResourceServerConfig {}
class OAuthResourceServerErrorHandler {}
class OAuthNoopResourceServerErrorHandler {
  handleError(err) {
    return throwError(err);
  }
}
class DefaultOAuthInterceptor {
  constructor(oAuthService, errorHandler, moduleConfig) {
    this.oAuthService = oAuthService;
    this.errorHandler = errorHandler;
    this.moduleConfig = moduleConfig;
  }
  checkUrl(url) {
    if (this.moduleConfig.resourceServer.customUrlValidation) {
      return this.moduleConfig.resourceServer.customUrlValidation(url);
    }
    if (this.moduleConfig.resourceServer.allowedUrls) {
      return !!this.moduleConfig.resourceServer.allowedUrls.find(u => url.toLowerCase().startsWith(u.toLowerCase()));
    }
    return true;
  }
  intercept(req, next) {
    const url = req.url.toLowerCase();
    if (!this.moduleConfig || !this.moduleConfig.resourceServer || !this.checkUrl(url)) {
      return next.handle(req);
    }
    const sendAccessToken = this.moduleConfig.resourceServer.sendAccessToken;
    if (!sendAccessToken) {
      return next.handle(req).pipe(catchError(err => this.errorHandler.handleError(err)));
    }
    return merge(of(this.oAuthService.getAccessToken()).pipe(filter(token => !!token)), this.oAuthService.events.pipe(filter(e => e.type === 'token_received'), timeout(this.oAuthService.waitForTokenInMsec || 0), catchError(_ => of(null)),
    // timeout is not an error
    map(_ => this.oAuthService.getAccessToken()))).pipe(take(1), mergeMap(token => {
      if (token) {
        const header = 'Bearer ' + token;
        const headers = req.headers.set('Authorization', header);
        req = req.clone({
          headers
        });
      }
      return next.handle(req).pipe(catchError(err => this.errorHandler.handleError(err)));
    }));
  }
}
DefaultOAuthInterceptor.ɵfac = function DefaultOAuthInterceptor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DefaultOAuthInterceptor)(i0.ɵɵinject(OAuthService), i0.ɵɵinject(OAuthResourceServerErrorHandler), i0.ɵɵinject(OAuthModuleConfig, 8));
};
DefaultOAuthInterceptor.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: DefaultOAuthInterceptor,
  factory: DefaultOAuthInterceptor.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultOAuthInterceptor, [{
    type: Injectable
  }], function () {
    return [{
      type: OAuthService
    }, {
      type: OAuthResourceServerErrorHandler
    }, {
      type: OAuthModuleConfig,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * A validation handler that isn't validating nothing.
 * Can be used to skip validation (at your own risk).
 */
class NullValidationHandler {
  validateSignature(validationParams) {
    return Promise.resolve(null);
  }
  validateAtHash(validationParams) {
    return Promise.resolve(true);
  }
}
function createDefaultLogger() {
  return console;
}
function createDefaultStorage() {
  return typeof sessionStorage !== 'undefined' ? sessionStorage : new MemoryStorage();
}
class OAuthModule {
  static forRoot(config = null, validationHandlerClass = NullValidationHandler) {
    return {
      ngModule: OAuthModule,
      providers: [OAuthService, UrlHelperService, {
        provide: OAuthLogger,
        useFactory: createDefaultLogger
      }, {
        provide: OAuthStorage,
        useFactory: createDefaultStorage
      }, {
        provide: ValidationHandler,
        useClass: validationHandlerClass
      }, {
        provide: HashHandler,
        useClass: DefaultHashHandler
      }, {
        provide: OAuthResourceServerErrorHandler,
        useClass: OAuthNoopResourceServerErrorHandler
      }, {
        provide: OAuthModuleConfig,
        useValue: config
      }, {
        provide: HTTP_INTERCEPTORS,
        useClass: DefaultOAuthInterceptor,
        multi: true
      }, {
        provide: DateTimeProvider,
        useClass: SystemDateTimeProvider
      }]
    };
  }
}
OAuthModule.ɵfac = function OAuthModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OAuthModule)();
};
OAuthModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: OAuthModule
});
OAuthModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OAuthModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [],
      exports: []
    }]
  }], null, null);
})();
const err = `PLEASE READ THIS CAREFULLY:

Beginning with angular-oauth2-oidc version 9, the JwksValidationHandler
has been moved to an library of its own. If you need it for implementing
OAuth2/OIDC **implicit flow**, please install it using npm:

  npm i angular-oauth2-oidc-jwks --save

After that, you can import it into your application:

  import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

Please note, that this dependency is not needed for the **code flow**,
which is nowadays the **recommented** one for single page applications.
This also results in smaller bundle sizes.
`;
/**
 * This is just a dummy of the JwksValidationHandler
 * telling the users that the real one has been moved
 * to an library of its own, namely angular-oauth2-oidc-utils
 */
class JwksValidationHandler extends NullValidationHandler {
  constructor() {
    super();
    console.error(err);
  }
}
const AUTH_CONFIG = new InjectionToken('AUTH_CONFIG');

/**
 * Generated bundle index. Do not edit.
 */

export { AUTH_CONFIG, AbstractValidationHandler, AuthConfig, DateTimeProvider, DefaultHashHandler, DefaultOAuthInterceptor, HashHandler, JwksValidationHandler, LoginOptions, MemoryStorage, NullValidationHandler, OAuthErrorEvent, OAuthEvent, OAuthInfoEvent, OAuthLogger, OAuthModule, OAuthModuleConfig, OAuthNoopResourceServerErrorHandler, OAuthResourceServerConfig, OAuthResourceServerErrorHandler, OAuthService, OAuthStorage, OAuthSuccessEvent, ReceivedTokens, SystemDateTimeProvider, UrlHelperService, ValidationHandler };