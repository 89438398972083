import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormService } from '@modules/customers-csrd/services/measuring-points/form.service';
import { MeasuringPointsService } from '@modules/customers-csrd/services/measuring-points/measuring-points.service';
import { Subscription } from 'rxjs';
import { CreateEditDialogComponent } from 'src/app/commons/components/create-edit-dialog/create-edit-dialog.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-measuring-point-dialog',
  templateUrl: './measuring-point-dialog.component.html',
  styleUrls: ['./measuring-point-dialog.component.scss']
})
export class MeasuringPointDialogComponent extends CreateEditDialogComponent implements OnInit, AfterViewChecked, OnDestroy {
  formInitializationSubscription: Subscription;

  unspscsOptionsInitialized: boolean = false;
  unitOptionsInitialized: boolean = false;
  supplierOptionsInitialized: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    dialogRef: MatDialogRef<any>,
    public formService: FormService,
    dialogService: DialogService,
    private cdRef: ChangeDetectorRef,
    measuringPointsService: MeasuringPointsService) {
    super(editData,
      measuringPointsService,
      formService,
      dialogRef,
      dialogService)
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  submitForm(): void {

    this.create(); // this.ctaScenario();
  }

  ngOnDestroy(): void {
    this.formService.resetEditData();
    this.formService.resetForm();
    this.formService.resetProductDataChange();

    if (this.formInitializationSubscription)
      this.formInitializationSubscription.unsubscribe();
  }
}
