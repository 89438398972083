import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { Subscription } from 'rxjs';
import { InitiativeMasterDto } from '../../../../commons/models/initiative-dto';
import { InitiativesPrepareDataService } from '@modules/initiatives/initiatives-prepare-data.service';
import { InitiativesFormService } from '@modules/initiatives/initiatives-form.service';
import { DeepCloneService } from '../../../../commons/services/deep-clone.service';
import { DialogService } from '../../../../services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { CalculatorService } from '@modules/scenarios/services/calculator/calculator.service';
import { CalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.service';
import { UnspscHandlerService } from 'src/app/commons/components/unspsc-handler/unspsc-handler.service';
import { CalculatorPrepareDataService } from '@modules/scenarios/services/calculator/calculator-prepare-data.service';
import { StatusDto } from 'src/app/commons/models/status-dto';
import { PriorityDto } from 'src/app/commons/models/priority-dto';

@Component({
  selector: 'app-save-as-initiative-button',
  templateUrl: './save-as-initiative-button.component.html',
  styleUrls: ['./save-as-initiative-button.component.scss'],
})
export class SaveAsInitiativeButtonComponent implements OnInit {
  @Input() scenarioValues: any;
  addInitiativeSubscription: Subscription;

  constructor(
    private apiService: ApiService,
    private initiativePrepareDataService: InitiativesPrepareDataService,
    private initiativesFormService: InitiativesFormService,
    private calculatorService: CalculatorService,
    private deepCloneService: DeepCloneService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private calculatorHelperService: CalculatorHelperService,
    private unspscHandlerService: UnspscHandlerService,
    private calculatorPrepareDataService: CalculatorPrepareDataService
  ) { }

  ngOnInit(): void { }

  saveAsInitiative(scenarioFormValues): void {
    this.dialogService.createNewInitiativeDialog()
      .afterClosed()
      .subscribe(async (res) => {
        if (res?.status == 'save') {
          await this.proceedSavingAsInitiative(scenarioFormValues, res.initiativeName, res.initiativeDescription);
        }
      });
  }

  async proceedSavingAsInitiative(scenarioFormValues, initiativeName: string, initiativeDescription: string): Promise<void> {
    let forecastLengths = [];
    let calculationUnspscs = [];

    scenarioFormValues.unspscs.forEach((el) =>
      forecastLengths.push(el.forecastLength)
    );
    scenarioFormValues.unspscs.forEach((el) =>
      calculationUnspscs.push(el.unspscClassID)
    );

    let maxForecastLength = Math.max(...forecastLengths);
    let currentYear = new Date().getFullYear().toString();
    let lastYear = Number(currentYear) + Number(maxForecastLength);

    let scenarioFormValuesClone =
      this.deepCloneService.deepClone(scenarioFormValues);

    Object.assign(scenarioFormValues, { calculatorUnspsc: calculationUnspscs });

    const selectedUnspscs = this.calculatorPrepareDataService.generateSelectedUnspscManually(scenarioFormValuesClone.unspscs);
    const form = this.calculatorHelperService.getUnspscsFormValues(scenarioFormValuesClone, selectedUnspscs, 'calculatorUnspsc');

    this.calculatorService
      .calculateSaveScenarioAsInitiative(form)
      .subscribe((res) => {
        let initiativeMasterData: InitiativeMasterDto = {
          initiativePriority: 1,
          initiativeStatus: 1,
          initiativeDescription: initiativeDescription,
          scenarioDescription: scenarioFormValues.description,
          initiativeCreateDate: new Date().toISOString(),
          initiativeStartYear: currentYear,
          initiativeEndYear: lastYear.toString(),
          initiativeName: initiativeName,
          initiativeEmail: scenarioFormValues.email,
          scenarioName: scenarioFormValues.name,
          scenarioEmail: scenarioFormValues.email,
          calculationId: scenarioFormValues.calculationId,
          unspscs: []
        };

        this.addInitiativeSubscription = this.apiService
          .addInitiativeMaster(initiativeMasterData)
          .subscribe((initiativeId) => {
            let initiativeWorkData = this.initiativePrepareDataService.prepareWorkData(
              scenarioFormValues,
              initiativeId
            );

            this.apiService
              .addInitiativeWorkCalculations(initiativeWorkData)
              .subscribe((res) => {
                this.apiService.addInitiativeCalculations(scenarioFormValues.calculationId, initiativeId).subscribe(() => {
                  this.initiativesFormService.form?.reset();
                  this.initiativesFormService.dialogRef?.close('save');
                  this.dialogService.inform({
                    message: this.translateService.instant(
                      'general.initiative-saved'
                    ),
                  });
                });
              });
          });
      });
  }
}
