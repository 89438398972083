import { Component, OnInit } from '@angular/core';
import { OpenIDCService } from '@modules/auth/services/open-idc.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(private openIDCService: OpenIDCService) {}

  ngOnInit(): void {}

  logout() {
    this.openIDCService.logout();
  }
}
