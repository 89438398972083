import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@modules/shell/services/user.service';
import { CalculatorServiceDto } from '../../../../commons/models/calculator-service-dto';
import { ICalculatorFormService } from './calculator-form.interface';

@Injectable({
  providedIn: 'root',
})
export class CalculatorFormService implements ICalculatorFormService {
  form!: FormGroup;
  id: number;
  dialogRef: MatDialogRef<any>;
  editData: any;

  constructor(public fb: FormBuilder, public userService: UserService) { }

  setEditData(editData) {
    this.editData = editData;
  }

  resetEditData() {
    this.editData = undefined;
  }

  resetForm() {
    this.form.reset();
  }

  setDialogRef(dialogRef) {
    this.dialogRef = dialogRef;
  }

  initializeForm(): void {
    this.form = this.fb.group({
      id: [''],
      name: ['', [Validators.required, Validators.pattern(/^[^?&^$#@!()+\;<>’'*%=|]+$|^$/)]],
      description: ['', [Validators.required, Validators.pattern(/^[^?&^$#@!()+\;<>’'*%=|]+$|^$/)]],
      email: [
        { value: this.userService.currentUser.email, disabled: true },
        Validators.required,
      ],
      unspscClassId: ['']
    });

    if (this.editData) {
      this.form.patchValue({
        id: this.editData.id,
        name: this.editData.name,
        description: this.editData.description,
        email: this.editData.email,
      });
    }
  }

  getCalculatorServiceDto(formData): CalculatorServiceDto {
    let calculatorData: CalculatorServiceDto = {
      description: formData.description,
      email: formData.email,
      name: formData.name,
      calculatorUnspsc: formData.unspscs.map((el) => el.unspscClassId.includes(',') ? el.unspscClassId.split(',') : [el.unspscClassId]),
      unspscs: formData.unspscs,
    };

    return calculatorData;
  }
}
