<div class="container mt-5">
  <div class="row align-items-center justify-content-between">
    <div class="col-2">
      <img
        class="my-3"
        id="kmd-logo"
        src="../../../../../assets/images/carbonkey-rgb-v2.svg"
      />
    </div>
    <div class="col-3 justify-content-end align-items-center">
      <div class="row">
        <div class="col-12">
          <p class="fs-20 fw-bold text-right">
            {{ errorCode + ' ' + errorCodeDescription }}
          </p>
          <p class="decoration"></p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center align-items-center mt-5">
      <div class="col-4">
        <div class="full-width text-center position-relative person-wrapper">
          <img
            class="position-absolute person-image"
            height="400px"
            src="../../../../../assets/images/404.jpg"
          />
          <div class="position-absolute big-headings error--code fs-80">
            {{ errorCode }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row justify-content-center align-items-center">
          <div class="col-12">
            <div class="text-center fs-80">
              <p class="fs-50 fw-bold big-headings">{{ errorTitle }}</p>
              <mat-icon
                [inline]="true"
                class="fs-80 mt-5"
                color="warn"
                fontIcon="report"
              ></mat-icon>
              <div class="fs-26 color-gray my-5 fw-lighter error--subtitle">
                {{ errorSubtitle }}
              </div>

              <div class="fs-26 color-gray my-5 fw-lighter error--subtitle">
                {{ errorId }}
              </div>

              <button mat-raised-button color="primary" (click)="goHome()">
                {{ buttonName }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
