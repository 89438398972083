import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../services/dialog.service';
import { DateTime } from 'luxon';
import { DialogResultStatus } from '../../existing-record-dialog/dialog-result-status';
import { IDataService } from '../data-view/data-service.interface';
import { BaseDto } from '../../models/base-dto';
import { IFormService } from '../../models/form-service.interface';

@Component({
  selector: 'app-create-edit-dialog',
  template: ''
})
export class CreateEditDialogComponent implements OnInit, OnDestroy {
  public header = '';
  public editingMode: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    @Inject('IDataService') protected dataService: IDataService,
    @Inject('IFormService') public formService: IFormService,
    protected dialogRef: MatDialogRef<CreateEditDialogComponent>,
    protected dialogService: DialogService) {
    this.formService.setDialogRef(dialogRef);

    if (editData) {
      this.editingMode = true;
      this.formService.setEditData(editData);
    }
    this.formService.initializeForm();
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.formService.resetEditData();
    this.formService.resetForm();
    this.formService.resetProductDataChange();
  }

  ctaScenario(): void {
    if (this.formService.form.valid) {
      if (!this.editData) {
        this.add();
      } else {
        if (this.formService.checkIfProductDataChanged()) {

          this.dataService.getByUniqueKey(this.formService.getUniqueKeyModel())
            .subscribe((row) => {
              if (!row) {
                this.update();
              } else {
                this.dialogService.editExistingRecordDialog()
                  .afterClosed()
                  .subscribe((result) => {
                    if (result === DialogResultStatus.UPDATE) {
                      this.dataService
                        .delete([(row as BaseDto).id])
                        .subscribe(() => {
                          this.update();
                        });
                    }
                    if (result === DialogResultStatus.CANCEL) {
                      this.dialogRef.close(DialogResultStatus.CANCEL);
                    }
                  });
              }
            });
        } else {
          this.update();
        }
      }
    }
  }

  add(): void {
    this.dataService.getByUniqueKey(this.formService.getUniqueKeyModel())
      .subscribe((row) => {
        if (!row) {
          this.create();
        } else {
          this.dialogService.editExistingRecordDialog()
            .afterClosed()
            .subscribe((res) => {
              if (res === DialogResultStatus.UPDATE) {
                this.update((row as BaseDto).id, row);
              }
              if (res === DialogResultStatus.CANCEL) {
                this.dialogRef.close(DialogResultStatus.CANCEL);
              }
            });
        }
      });
  }

  create(): void {
    let createValues = this.formService.prepareCreateValues();
    this.dataService.add(createValues).subscribe(() => {
      this.dialogRef.close(DialogResultStatus.CREATE);
    });
  }

  update(id?: number, originalValues?: any): void {
    const updateModel = this.formService.prepareUpdateValues(id, originalValues);
    this.dataService
      .update(updateModel)
      .subscribe(() => {
        this.dialogRef.close(DialogResultStatus.UPDATE);
      });
  }

  getStringDate(jsDate: Date) {
    let textDateTime = DateTime.fromJSDate(jsDate).toString();
    return textDateTime;
  }

  cancel() {
    this.dialogRef.close(DialogResultStatus.CANCEL);
  }
}
