import { Injectable } from '@angular/core';
import {
  Columns,
  Filter,
  FilterTypes,
  ObjectTypes,
  TranslationKeys,
} from '@dima/table-library';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ITableService } from 'src/app/commons/components/data-view/table-service.interface';
import { CustomDecimalPipe } from 'src/app/commons/pipes/custom-decimal.pipe';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { EmptySupplierPipe } from 'src/app/commons/pipes/empty-supplier.pipe';
import { EmptyItemIdPipe } from 'src/app/commons/pipes/empty-item-id.pipe';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DataViewType } from 'src/app/commons/components/create-edit-dialog/data-view-type.enum';

@Injectable({
  providedIn: 'root',
})
export class TableService implements ITableService {
  public cols: Columns[];
  public totalCount: number;
  public pageSize: number = 10;
  public page: number = 0;
  public data = [];
  public langSubscription: Subscription;
  public loading: boolean = false;
  public table: any;
  public dimaTableTranslations: any;
  filters: Filter[] = [];
  pagedModel: any;
  selectedRows: any[];

  clearTableSelection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  anyRowSelectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private currentLanguageService: CurrentLanguageService,
    private localStorageService: LocalStorageService
  ) {
    this.langSubscription = this.translateService.onLangChange.subscribe(
      (lang) => {
        this.setCols();
        this.router.navigate([this.getCurrentRoute()]);
      }
    );
  }

  setCols() {
    let visibleColumns: string[];
    const savedColumnsToDisplay = this.localStorageService.getItem(DataViewType.PSE_IN_USE.toString())?.value;
    if (savedColumnsToDisplay) {
      visibleColumns = JSON.parse(savedColumnsToDisplay) as string[];
    }

    this.dimaTableTranslations = [
      {
        Key: TranslationKeys.globalFilterHeader,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.globalFilterPlaceholder,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.paginatorItemsPerPageLabel,
        Label: this.translateService.instant(
          'general.paginator.per-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.per-page'),
      },
      {
        Key: TranslationKeys.paginatorRangeLabel,
        Label: this.translateService.instant('general.paginator.of'),
        Tooltip: this.translateService.instant('general.paginator.of'),
      },
      {
        Key: TranslationKeys.paginatorFirstPageLabel,
        Label: this.translateService.instant(
          'general.paginator.first-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.first-page'),
      },
      {
        Key: TranslationKeys.paginatorLastPageLabel,
        Label: this.translateService.instant(
          'general.paginator.last-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.last-page'),
      },
      {
        Key: TranslationKeys.paginatorPreviousPageLabel,
        Label: this.translateService.instant(
          'general.paginator.prev-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.prev-page'),
      },
      {
        Key: TranslationKeys.paginatorNextPageLabel,
        Label: this.translateService.instant(
          'general.paginator.next-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.next-page'),
      },
      {
        Key: TranslationKeys.globalFilterPlaceholder,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.columnControlHeader,
        Label: this.translateService.instant(
          'general.paginator.columns'
        ),
        Tooltip: this.translateService.instant('general.paginator.columns'),
      },
      {
        Key: TranslationKeys.saveButton,
        Label: this.translateService.instant('general.paginator.save'),
        Tooltip: this.translateService.instant('general.paginator.save'),
      },
      {
        Key: TranslationKeys.cancelButton,
        Label: this.translateService.instant(
          'general.paginator.cancel'
        ),
        Tooltip: this.translateService.instant('general.paginator.cancel'),
      },
      {
        Key: TranslationKeys.tableEmptyStateMessage,
        Label: this.translateService.instant(
          'general.paginator.no-data'
        ),
        Tooltip: this.translateService.instant('general.paginator.no-data'),
      },
    ];

    const precission0Pipe = new CustomDecimalPipe(this.currentLanguageService);
    precission0Pipe.decimalPlaces = 0;

    this.cols = [
      {
        id: 0,
        columnsOrder: 0,
        type: ObjectTypes.rowSelect,
        name: 'selection',
        filterType: FilterTypes.rowSelect,
        showInColumnControl: false,
        visible: true
      },
      {
        id: 1,
        columnsOrder: 1,
        type: ObjectTypes.string,
        name: 'supplierDisplayValue',
        title: this.translateService.instant(
          'product-specific-emissions.supplier-id'
        ),
        pipe: new EmptySupplierPipe(this.translateService),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('supplierDisplayValue') ?? true
      },
      {
        id: 2,
        columnsOrder: 2,
        type: ObjectTypes.string,
        name: 'countryId',
        title: this.translateService.instant(
          'product-specific-emissions.country-id'
        ),
        filterType: FilterTypes.input,
        showInColumnControl: false,
        visible: visibleColumns?.includes('countryId') ?? false
      },
      {
        id: 3,
        columnsOrder: 3,
        type: ObjectTypes.string,
        name: 'itemIdDisplayValue',
        title: this.translateService.instant(
          'product-specific-emissions.item-id'
        ),
        pipe: new EmptyItemIdPipe(this.translateService),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('itemIdDisplayValue') ?? true
      },
      {
        id: 4,
        columnsOrder: 4,
        type: ObjectTypes.string,
        name: 'itemName',
        title: this.translateService.instant(
          'product-specific-emissions.item-name'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('itemName') ?? true
      },
      {
        id: 5,
        columnsOrder: 5,
        type: ObjectTypes.string,
        name: 'itemDescription',
        title: this.translateService.instant(
          'product-specific-emissions.item-description'
        ),
        filterType: FilterTypes.input,
        visible: visibleColumns?.includes('itemDescription') ?? false
      },
      {
        id: 6,
        columnsOrder: 6,
        type: ObjectTypes.string,
        name: 'unitCode',
        title: this.translateService.instant(
          'product-specific-emissions.unit-code'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('unitCode') ?? true
      },
      {
        id: 7,
        columnsOrder: 7,
        type: ObjectTypes.int,
        name: 'emissionCo2Kg',
        title: this.translateService.instant(
          'product-specific-emissions.emission-co2'
        ),
        filterType: FilterTypes.range,
        pipe: precission0Pipe,
        visible: visibleColumns?.includes('emissionCo2Kg') ?? true
      },
      {
        id: 8,
        columnsOrder: 8,
        type: ObjectTypes.date,
        name: 'emissionValidFrom',
        title: this.translateService.instant(
          'product-specific-emissions.emission-valid-from'
        ),
        filterType: FilterTypes.date,
        filterFn: (value: any, data: any) => {
          if (value.date <= data.getTime()) {
            return true;
          }
          return false;
        },
        visible: visibleColumns?.includes('emissionValidFrom') ?? false
      },
      {
        id: 9,
        columnsOrder: 9,
        type: ObjectTypes.date,
        name: 'emissionValidTo',
        title: this.translateService.instant(
          'product-specific-emissions.emission-valid-to'
        ),
        filterType: FilterTypes.date,
        filterFn: (value: any, data: any) => {
          if (value.date >= data.getTime()) {
            return true;
          }
          return false;
        },
        visible: visibleColumns?.includes('emissionValidTo') ?? false
      },
      {
        id: 10,
        columnsOrder: 10,
        type: ObjectTypes.string,
        name: 'method',
        title: this.translateService.instant(
          'product-specific-emissions.method'
        ),
        filterType: FilterTypes.input,
        visible: visibleColumns?.includes('method') ?? true
      },
      {
        id: 11,
        columnsOrder: 11,
        type: ObjectTypes.custom,
        name: 'isActive',
        title: this.translateService.instant(
          'product-specific-emissions.is-active'
        ),
        exDataObj: { customTitle: this.translateService.instant(
          'general.use'
        )},
        filterType: FilterTypes.select,
        showInColumnControl: false,
        visible: true
      },
      {
        id: 12,
        columnsOrder: 12,
        type: ObjectTypes.boolean,
        name: 'isOwnRecord',
        filterType: FilterTypes.select,
        showInColumnControl: false,
        exDataObj: { headerIcon: 'person.svg' },
        visible: true
      },
      {
        id: 13,
        columnsOrder: 13,
        type: ObjectTypes.boolean,
        name: 'verified',
        filterType: FilterTypes.select,
        showInColumnControl: false,
        exDataObj: { headerIcon: 'check_circle.svg' },
        visible: true
      },
      {
        id: 14,
        columnsOrder: 14,
        type: ObjectTypes.custom,
        name: 'edit',
        filterType: FilterTypes.none,
        showInColumnControl: false,
        visible: true
      },

      // {
      //   id: 11,
      //   columnsOrder: 11,
      //   type: ObjectTypes.date,
      //   name: 'creationDate',
      //   title: this.translateService.instant(
      //     'product-specific-emissions.creation-date'
      //   ),
      //   filterType: FilterTypes.date,
      //   filterFn: (value: any, data: any) => {
      //     if (value.date == data.getTime()) {
      //       return true;
      //     }
      //     return false;
      //   },
      //   visible: false,
      // },
      // {
      //   id: 12,
      //   columnsOrder: 12,
      //   type: ObjectTypes.string,
      //   name: 'createdBy',
      //   title: this.translateService.instant(
      //     'product-specific-emissions.created-by'
      //   ),
      //   filterType: FilterTypes.checksSearch,
      //   visible: false,
      // },

      // {
      //   id: 13,
      //   columnsOrder: 13,
      //   type: ObjectTypes.string,
      //   name: 'modifiedBy',
      //   title: this.translateService.instant(
      //     'product-specific-emissions.modify-by'
      //   ),
      //   filterType: FilterTypes.checksSearch,
      //   visible: false,
      // },
      // {
      //   id: 14,
      //   columnsOrder: 14,
      //   type: ObjectTypes.date,
      //   name: 'modifyDate',
      //   title: this.translateService.instant(
      //     'product-specific-emissions.modify-date'
      //   ),
      //   filterType: FilterTypes.date,
      //   filterFn: (value: any, data: any) => {
      //     if (value.date == data.getTime()) {
      //       return true;
      //     }
      //     return false;
      //   },
      //   visible: false,
      // },
      // {
      //   id: 15,
      //   columnsOrder: 15,
      //   type: ObjectTypes.string,
      //   name: 'active',
      //   title: this.translateService.instant(
      //     'product-specific-emissions.active'
      //   ),
      //   filterType: FilterTypes.checksSearch,
      //   visible: false,
      //   pipe: new ActivatedStatusPipe(this.translateService),
      // },
      // {
      //   id: 16,
      //   columnsOrder: 16,
      //   type: ObjectTypes.string,
      //   name: 'overwrite',
      //   title: this.translateService.instant(
      //     'product-specific-emissions.overwrite'
      //   ),
      //   filterType: FilterTypes.checksSearch,
      //   visible: false,
      //   pipe: new OverwrittenStatusPipe(this.translateService),
      // },
      // {
      //   id: 15,
      //   columnsOrder: 15,
      //   type: ObjectTypes.string,
      //   name: 'verified',
      //   title: this.translateService.instant(
      //     'product-specific-emissions.verified'
      //   ),
      //   filterType: FilterTypes.checksSearch,
      //   pipe: new VerifiedStatusPipe(this.translateService),
      // },

      {
        id: 15,
        columnsOrder: 15,
        type: ObjectTypes.custom,
        name: 'delete',
        filterType: FilterTypes.custom,
        showInColumnControl: false,
        stickyRight: true,
      },
    ];
  }

  setData(data) {
    if (data) {
      this.pageSize = Number(data._rowsPerPage);
      this.page = Number(data._PageNumber);
      this.totalCount = Number(data.totalFilteredProducts);
      this.data = data.pagedProductsDaos;
    }
  }

  getCurrentRoute() {
    let urlWithoutParams = this.router.url.split('?')[0];
    return urlWithoutParams;
  }
}
