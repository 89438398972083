import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GetParamsService {
  getParamsFromUrl(url: string): { [key: string]: string } {
    const params: { [key: string]: string } = {};

    // Extract the query string from the URL
    const queryString = url.split('?')[1];

    if (queryString) {
      // Split the query string into key-value pairs
      const pairs = queryString.split('&');

      pairs.forEach((pair) => {
        const [key, value] = pair.split('=');

        // Decode the key and value and store them in the params object
        params[decodeURIComponent(key)] = decodeURIComponent(value || '');
      });
    }

    return params;
  }
}
