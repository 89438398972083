import { Injectable } from '@angular/core';
import { InitiativesPrepareDataService } from '@modules/initiatives/initiatives-prepare-data.service';
import { ApiService } from '../../services/api.service';
import { InitiativeWorkDto } from '../../commons/models/initiative-dto';
import { Subscription } from 'rxjs';
import { InitiativesFormService } from '@modules/initiatives/initiatives-form.service';

@Injectable({
  providedIn: 'root',
})
export class InitiativesCrudHelperService {
  private addInitiativeSubscription: Subscription;
  private addInitiativesWorkCalculationSubscription: Subscription;
  private updateScenarioSubscription: Subscription;
  private updateScenarioWorkCalculationSubscription: Subscription;

  constructor(
    private initiativePrepareDataService: InitiativesPrepareDataService,
    private initiativesFormService: InitiativesFormService,
    private apiService: ApiService
  ) {}

  addInitiativeMaster(initiativeFormValue, scenarioDwSkScenario = null): void {
    let initiativeMasterData =
      this.initiativePrepareDataService.prepareMasterData(
        initiativeFormValue,
        scenarioDwSkScenario
      );

    this.addInitiativeSubscription = this.apiService
      .addInitiativeMaster(initiativeMasterData)
      .subscribe((scenarioId) => {
        let initiativeWorkData =
          this.initiativePrepareDataService.prepareWorkData(
            initiativeFormValue,
            scenarioId
          );

        this.addInitiativeWorkCalculations(initiativeWorkData);
      });
  }

  addInitiativeWorkCalculations(initiativeWorkDtos: InitiativeWorkDto[]): void {
    this.addInitiativesWorkCalculationSubscription = this.apiService
      .addInitiativeWorkCalculations(initiativeWorkDtos)
      .subscribe((res) => {
        this.initiativesFormService.form.reset();
        this.initiativesFormService.dialogRef.close('save');
      });
  }

  updateInitiativeMaster(
    dwSkOld,
    initiativeFormValue,
    scenarioDwSkScenario = null
  ): void {
    let updateMasterData = this.initiativePrepareDataService.prepareMasterData(
      initiativeFormValue,
      scenarioDwSkScenario
    );

    this.updateScenarioSubscription = this.apiService
      .updateInitiativeMaster(dwSkOld, updateMasterData)
      .subscribe((dwSkNew) => {
        let initiativeWorkData =
          this.initiativePrepareDataService.prepareWorkData(
            this.initiativesFormService.form.getRawValue(),
            dwSkNew
          );

        this.updateInitiativeWorkCalculations(initiativeWorkData, dwSkOld);
      });
  }

  updateInitiativeWorkCalculations(
    initiativeWorkDtos: InitiativeWorkDto[],
    dwSkOld
  ): void {
    this.updateScenarioWorkCalculationSubscription = this.apiService
      .updateInitiativeWorkCalculations(dwSkOld, initiativeWorkDtos)
      .subscribe((res) => {
        this.initiativesFormService.form.reset();
        this.initiativesFormService.dialogRef.close('update');
      });
  }

  unsubscribe() {
    if (this.addInitiativeSubscription) {
      this.addInitiativeSubscription.unsubscribe();
    }

    if (this.addInitiativesWorkCalculationSubscription) {
      this.addInitiativesWorkCalculationSubscription.unsubscribe();
    }

    if (this.updateScenarioSubscription) {
      this.updateScenarioSubscription.unsubscribe();
    }

    if (this.updateScenarioWorkCalculationSubscription) {
      this.updateScenarioWorkCalculationSubscription.unsubscribe();
    }
  }
}
