import { Component, Input, OnInit } from '@angular/core';
import { CalculatorTableService } from '@modules/scenarios/services/calculator/calculator-table.service';
import { CalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.service';
import { CalculatorService } from '@modules/scenarios/services/calculator/calculator.service';

@Component({
  selector: 'app-table-display',
  templateUrl: './table-display.component.html',
  styleUrls: ['./table-display.component.scss'],
})
export class TableDisplayComponent implements OnInit {
  @Input() valueFieldName: string;
  @Input() changeFieldName: string;
  @Input() changeSumFieldName: string;
  @Input() totalChangeFieldName: string;
  @Input() yearlyTotalFieldName: string;
  @Input() panelOpenStateFieldName: string;
  @Input() headerTranslationKey: string;
  @Input() uniqueUnspscChangeView: string;
  @Input() historicalArrayName: string;

  constructor(
    public calculatorService: CalculatorService,
    public calculatorHelperService: CalculatorHelperService,
    public calculatorTableService: CalculatorTableService
  ) { }

  ngOnInit(): void {
    // this.calculatorHelperService.expensePanelOpenState = true;
    // this.calculatorHelperService.emissionPanelOpenState = true;
    // this.calculatorHelperService.quantityPanelOpenState = true;
  }

  getColumns() {
    let historyColWidth = this.calculatorTableService.historicalColumns.length;
    let forecastColWidth = this.calculatorTableService.forecastColumns.length;
    return {
      display: 'grid',
      'grid-template-columns': `${historyColWidth}fr 1fr ${forecastColWidth}fr`,
      'grid-template-rows': '1fr',
      gap: '0px 0px',
    };
  }
}
