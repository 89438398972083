import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import {
  InitiativeMasterDto,
  InitiativeWorkDto,
} from '../../commons/models/initiative-dto';

@Injectable({
  providedIn: 'root',
})
export class InitiativesPrepareDataService {
  constructor(public apiService: ApiService) {}

  prepareMasterData(
    initiativeFormValues,
    scenarioDwSkScenario = null
  ): InitiativeMasterDto {
    return {
      initiativeEmail: initiativeFormValues.initiativeEmail,
      initiativeStatus: initiativeFormValues.initiativeStatus,
      initiativePriority: initiativeFormValues.initiativePriority,
      initiativeDescription: initiativeFormValues.initiativeDescription,
      scenarioDescription: initiativeFormValues.scenarioDescription,
      scenarioEmail: initiativeFormValues.scenarioEmail,
      scenarioName: initiativeFormValues.scenarioName,
      unspscs: [],
      initiativeCreateDate: new Date(
        initiativeFormValues.initiativeCreateDate
      ).toISOString(),
      initiativeStartYear: initiativeFormValues.initiativeStartYear,
      initiativeEndYear: initiativeFormValues.initiativeEndYear.toString(),
      initiativeName: initiativeFormValues.initiativeName,
      calculationId: scenarioDwSkScenario ? scenarioDwSkScenario : undefined,
    };
  }

  prepareWorkData(initiativeFormValues, id): InitiativeWorkDto[] {
    let workDataArray: InitiativeWorkDto[] = [];
    for (let i = 0; i < initiativeFormValues.unspscs.length; i++) {
      workDataArray.push({
        Adjustment: initiativeFormValues.unspscs[i].adjustment,
        Year: initiativeFormValues.unspscs[i].forecastLength,
        InitiativeUnspscClassID: initiativeFormValues.unspscs[i].unspscClassID,
        id: id,
        InitiativeOrganizationID:
          initiativeFormValues.unspscs[i].organizationID,
        TrendMethod: Number(initiativeFormValues.unspscs[i].trendMethod),
        SimulationMethod: Number(
          initiativeFormValues.unspscs[i].simulationMethod
        ),
        UnitMethod: Number(initiativeFormValues.unspscs[i].unitMethod),
      });
    }
    return workDataArray;
  }
}
