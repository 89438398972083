<div class="report-wrapper">
  <div class="report-menu">
    <div class="report-row">
      <div class="">
        <div class="row gx-0">
          <div class="col-6">
            <div class="fw-bold fs-22 report-name ms-3">
              {{ this.reportService.description }}
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <div class="pe-1" style="display: flex">

                <button id="showFiltersBtn" mat-flat-button color="primary" class="menuitem actionBtn color-light"
                  (click)="showFilters()" [disabled]="!enableActionButtons">
                  {{ 'pbi-filters.show' | translate }}
                </button>

                <button id="resetFiltersBtn" mat-flat-button color="primary" class="menuitem actionBtn color-light"
                  (click)="resetFilters()" [disabled]="!enableActionButtons">
                  {{ 'pbi-filters.reset' | translate }}
                </button>

                <button id="createBookmarkBtn" mat-flat-button color="primary" class="menuitem color-light"
                  (click)="createBookmark()">
                  {{ 'reports.create-bookmark' | translate }}
                </button>

              </div>

              <div class="ps-4">
                <button mat-flat-button color="primary" class="menuitem color-light" (click)="openPdfOrderDialog()">
                  {{ 'pdf-exports.pdf-order-list' | translate }}
                </button>
              </div>

              <div class="pe-1 ps-3">
                <app-header-user-report-select></app-header-user-report-select>
              </div>

              <div class="pe-5 ps-1">
                <app-header-bookmark-select></app-header-bookmark-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-container">
    <app-report></app-report>
  </div>
</div>