import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  reportId: string;
  parentPath: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.reportId = this.route.children[0]?.snapshot?.url[2]?.path;
    this.router.events.subscribe((e) => {
      this.reportId = this.route.children[0]?.snapshot?.url[2]?.path;
      this.parentPath = this.route.children[0]?.routeConfig.path;
    });
  }

  public isStandardReport() {
    return false;
  }

  public isPbiReport() {
    return true;
  }
}
