import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGuideComponent } from '@modules/user-guide/user-guide.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { OrderByPipe } from 'src/app/commons/pipes/order-by.pipe';

@NgModule({
  declarations: [UserGuideComponent],
  imports: [CommonModule, TranslateModule, MatTableModule],
  providers: [
    OrderByPipe
  ]
})

export class UserGuideModule { }
