import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'overwrittenStatus',
  standalone: true,
  pure: false,
})
export class OverwrittenStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  static transform(value, status) {
    return value;
  }
  transform(value: boolean, status): string {
    value = value
      ? this.translateService.instant(
          'product-specific-emissions.can-overwrite'
        )
      : this.translateService.instant(
          'product-specific-emissions.cannot-overwrite'
        );
    return OverwrittenStatusPipe.transform(value, status);
  }
}
