import { Injectable } from '@angular/core';


import * as pbi from 'powerbi-client';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QueryParamsFilterService } from './query-params-filter.service';
import { Filter } from 'src/app/commons/models/report/filter.model';
import { FilterValues } from 'src/app/commons/models/report/filter-values.model';
import { Condition, PbiFilter } from 'src/app/commons/models/report/pbi-filter.model';
import { RouteService } from './route.service';
import { FilterMappingService } from './filter-mapping.service';
import { ApiService } from 'src/app/services/api.service';

export const pbiStateParamName = 'pbiState';
export const valuelessOperators = [
  'IsBlank',
  'IsEmptyString',
  'IsNotBlank',
  'IsNotEmptyString',
];

@Injectable({
  providedIn: 'root',
})
export class PowerBiReport {
  private activeBasicFilters: pbi.models.IBasicFilterWithKeys[] = [];
  private activeAdvancedFilters: pbi.models.IAdvancedFilter[] = [];
  private powerBiReport: pbi.Report;
  public reportLoading = false;
  private filter: Filter;
  filterValues: FilterValues[] = [];
  pageFilterValues: FilterValues[] = [];

  private searchLimit = 9000;

  constructor(
    private queryParamsFilterService: QueryParamsFilterService,
    private filterMappingService: FilterMappingService,
    private snackBar: MatSnackBar,
    private routeService: RouteService,
    private apiService: ApiService
  ) { }

  public async setPowerBiToFilter(pbiReport: pbi.Report) {
    if (this.reportLoading !== true && pbiReport) {
      const basicGlobalFilters = (await this.getBasicGlobalFilters(pbiReport)).filter(x => !x.displaySettings?.isHiddenInViewMode);
      const basicPageFilters = (await this.getBasicPageFilters(pbiReport)).filter(x => !x.displaySettings?.isHiddenInViewMode);

      const advancedGlobalFilters = (await this.getAdvancedGlobalFilters(pbiReport)).filter(x => !x.displaySettings?.isHiddenInViewMode);
      const advancedPageFilters = (await this.getAdvancedPageFilters(pbiReport)).filter(x => !x.displaySettings?.isHiddenInViewMode);

      const basicFilters = basicGlobalFilters.concat(basicPageFilters);
      const advancedFilters = advancedGlobalFilters.concat(advancedPageFilters);

      if (this.isPbiFilterChanged(basicFilters, advancedFilters)) {
        let globalBasicFilterValues = [];
        let pageBasicFilterValues = [];
        let globalAdvancedFilterValues = [];
        let pageAdvancedFilterValues = [];

        if (basicFilters.length > 0) {
          globalBasicFilterValues = basicGlobalFilters.map((f) =>
            this.filterMappingService.mapBasicFilterToPbiFilter(f)
          );
          pageBasicFilterValues = basicPageFilters.map((f) =>
            this.filterMappingService.mapBasicFilterToPbiFilter(f)
          );
        }

        if (advancedFilters.length > 0) {
          globalAdvancedFilterValues = advancedGlobalFilters.map((f) =>
            this.filterMappingService.mapAdvancedFilterToPbiFilter(f)
          );

          pageAdvancedFilterValues = advancedPageFilters.map((f) =>
            this.filterMappingService.mapAdvancedFilterToPbiFilter(f)
          );
        }

        this.filterValues = [];

        await this.apiService.mapPbiFilters(pageBasicFilterValues
        ).toPromise().then(x => {
          x.forEach((fv) => {
            this.addFilterValueToFilter(fv);
          });
        });

        await this.apiService.mapPbiFilters(pageAdvancedFilterValues
        ).toPromise().then(x => {
          x.forEach((fv) => {
            this.addFilterValueToFilter(fv);
          });
        });

        await this.apiService.mapPbiFilters(globalBasicFilterValues
        ).toPromise().then(x => {
          x.forEach((fv) => {
            this.addFilterValueToFilter(fv);
          });
        });

        await this.apiService.mapPbiFilters(globalAdvancedFilterValues
          .filter(x => !x.displaySettings?.isHiddenInViewMode)).toPromise().then(x => {
            x.forEach((fv) => {
              this.addFilterValueToFilter(fv);
            });
          });

        this.activeBasicFilters = basicFilters;
        this.activeAdvancedFilters = advancedFilters;
      }
    }
  }

  async getAdvancedPageFilters(pbiReport: pbi.Report) {
    const activePage = await pbiReport.getActivePage();
    if (activePage) {
      return await (activePage)
        .getFilters()
        .then((filters) => {
          filters = filters
            .filter((f) => f.filterType === 0)
            .map((f: pbi.models.IAdvancedFilter) => f)
            .filter(
              (f) =>
                this.hasValues(f) ||
                valuelessOperators.includes(f.conditions[0]?.operator)
            );
          if (this.routeService.isStandardReport()) {
            return filters
              .filter((f) => f.filterType === 0)
              .filter((f: pbi.models.IAdvancedFilter) => this.isVisible(f))
              .map((f: pbi.models.IAdvancedFilter) => f);
          } else {
            return filters.map((f: pbi.models.IAdvancedFilter) => f);
          }
        });
    } else {
      return [];
    }
  }

  async getAdvancedGlobalFilters(pbiReport: pbi.Report) {
    return await pbiReport.getFilters().then((filters) => {
      filters = filters
        .filter((f) => f.filterType === 0)
        .map((f: pbi.models.IAdvancedFilter) => f)
        .filter(
          (f) =>
            this.hasValues(f) ||
            valuelessOperators.includes(f.conditions[0]?.operator)
        );

      if (this.routeService.isStandardReport()) {
        return filters
          .filter((f) => f.filterType === 0)
          .filter((f: pbi.models.IAdvancedFilter) => this.isVisible(f))
          .map((f: pbi.models.IAdvancedFilter) => f);
      } else {
        return filters.map((f: pbi.models.IAdvancedFilter) => f);
      }
    });
  }

  private addFilterValueToFilter(fv: FilterValues) {
    const filterValuesInFilter = this.filterValues.find(
      (f) => f.key === fv.key
    );
    if (filterValuesInFilter) {
      fv.values.forEach((val) => {
        if (
          !this.filterValues
            .find((f) => f.key === fv.key)
            .values.includes(val)
        ) {
          this.filterValues
            .find((f) => f.key === fv.key)
            .values.push(val);
        }
      });
    } else {
      this.filterValues.push(fv);
    }
  }

  public async getBasicPageFilters(pbiReport: pbi.Report) {
    const activePage = await pbiReport.getActivePage();
    if (activePage) {
      return (await (activePage)
        .getFilters()
        .then((filters) => {
          if (this.routeService.isStandardReport()) {
            return filters
              .filter((f) => f.filterType === 1)
              .map((f: pbi.models.IBasicFilterWithKeys) => f)
              .filter((f) => this.hasValues(f) && this.isVisible(f));
          } else {
            return filters
              .filter((f) => f.filterType === 1)
              .map((f: pbi.models.IBasicFilterWithKeys) => f)
              .filter((f) => this.hasValues(f));
          }
        }));
    } else {
      return [];
    }
  }

  public async updatePageFilters(pbiReport: pbi.Report, filtersArray: pbi.models.IFilter[]) {
    const activePage = await pbiReport.getActivePage();
    await activePage.updateFilters(pbi.models.FiltersOperations.ReplaceAll, filtersArray);
  }

  public async getBasicGlobalFilters(pbiReport: pbi.Report) {
    return await pbiReport.getFilters().then((filters) => {
      return filters
        .filter((f) => f.filterType === 1)
        .map((f: pbi.models.IBasicFilterWithKeys) => f)
        .filter((f) => this.hasValues(f));
    });
  }

  async pbiFilterEqualToStandardFilter(
    filters: pbi.models.IBasicFilterWithKeys[]
  ): Promise<boolean> {
    const filter = this.queryParamsFilterService.getFilter();
    filter.filterValues = filter.filterValues.filter(
      (fv) => fv.key !== 'pbiState'
    );
    return JSON.stringify(filter.filterValues) === JSON.stringify(filters);
  }

  public async loadFilterValues(
    filterValues: FilterValues[],
    pbiReport: pbi.Report,
    timePeriod: { from; to } = null
  ) {
    if (this.reportLoading === false) {
      this.reportLoading = true;
      if (filterValues.length > 0) {

        let reportFilters;
        await pbiReport.getFilters().then((filters) => {
          reportFilters = filters;
        });
        let filterValuesPbi = filterValues.map(x => <PbiFilter>{
          table: x.key,
          column: x.key + '_col',
          conditions: x.values.map(v =>
            <Condition>{ operator: 'IN', values: [] }),
        });

        if (JSON.stringify(filterValuesPbi) !== JSON.stringify(filterValues)) {
          filterValuesPbi.map((filter) => {
            const pbiFilterLookup = reportFilters.filter(
              (f) =>
                f.target.table === filter.table &&
                this.getPbiFilterColumn(f.target) === filter.column
            )[0];
            if (pbiFilterLookup !== undefined) {
              pbiFilterLookup.operator = 'In';
              pbiFilterLookup.values = pbiFilterLookup.values
                ? pbiFilterLookup.values.concat(
                  filter.conditions[0].values.map(
                    (x: unknown) => x as string | number | boolean
                  )
                )
                : filter.conditions[0].values.map(
                  (x: unknown) => x as string | number | boolean
                );
              pbiFilterLookup.filterType = 1;
              pbiFilterLookup.$schema =
                'http://powerbi.com/product/schema#basic';
              pbiFilterLookup.target = {
                table: filter.table,
                column: filter.column,
              } as pbi.models.IFilterKeyColumnsTarget;
            }
          });
          await pbiReport.setFilters(reportFilters);
          this.activeBasicFilters = reportFilters.filter(
            (f) => reportFilters.values.length > 0
          );
        }
      }

      if (timePeriod != null) {
        if (this.routeService.isStandardReport()) {
          await this.updateTimePeriodInReport(pbiReport, timePeriod);
        } else if (!(await this.reportHasPeriodComponent())) {
          this.setVeryWidePeriodInReport();
        }
      }
      this.reportLoading = false;
    }
  }

  setVeryWidePeriodInReport() {
    this.queryParamsFilterService.updatePeriod(
      this.convertIsoStringToDate('1900-01-02T23:00:00.000Z', true),
      this.convertIsoStringToDate('2098-12-25T23:00:00.000Z', false)
    );
  }

  public async getTablesInsidePbiReport(): Promise<pbi.VisualDescriptor[]> {
    let tables;
    await this.powerBiReport.getActivePage().then((page) =>
      page.getVisuals().then((visuals) => {
        tables = visuals.filter((visual) => visual.type === 'tableEx');
      })
    );
    return tables;
  }

  public async getTableColumns(tableName: string): Promise<{
    pbiFilter: PbiFilter[];
    // pbiMeasures: PbiMeasure[];
  }> {
    let tableColumns, measures;
    await this.powerBiReport.getActivePage().then(async (page) => {
      await page.getVisuals().then(async (visuals) => {
        let table = visuals.find((v) => v.name == tableName);

        await table.getFilters().then((filters) => {
          tableColumns = filters
            .map((f: pbi.models.IBasicFilterWithKeys) => f)
            .map((f) => this.filterMappingService.mapBasicFilterToPbiFilter(f))
            .filter((f) => !this.isMeasure(f));
          measures = filters
            .map((f) => f.target as pbi.models.IFilterMeasureTarget)
            .filter((f) => f.measure != null);

          // set the order of columns to match the table
          tableColumns.forEach((col: any, index) => {
            col.TempOrder = filters
              .map((f: pbi.models.IBasicFilterWithKeys) => f)
              .map((f) =>
                this.filterMappingService.mapBasicFilterToPbiFilter(f)
              )
              .map((f) => {
                return { table: f.table, column: f.column };
              })
              .reduce(function (a, e, index) {
                if (e.column == col.column && e.table == col.table)
                  a.push(index);
                return a;
              }, []);
          });

          // Remove duplicate columns in a fancy es6 way
          tableColumns = tableColumns
            .filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) => t.column === value.column && t.table === value.table
                )
            )
            // flatten columns to arrays of single occurences
            .map((col) => {
              const newCols = [];
              col.TempOrder.forEach((occurenceIndex) => {
                newCols.push({
                  table: col.table,
                  column: col.column,
                  values: col.values,
                  order: occurenceIndex,
                });
              });
              return newCols;
            })
            // flatten arrays of single occurences to single occurences
            .flat();
        });
      });
    });
    return { pbiFilter: tableColumns }; // , pbiMeasures: measures
  }

  public setPowerBiReport(powerBiReport: pbi.Report) {
    this.powerBiReport = powerBiReport;
  }

  private isMeasure(f: PbiFilter): boolean {
    return f.column == null;
  }

  public hasValues(
    f: pbi.models.IBasicFilterWithKeys | pbi.models.IAdvancedFilter
  ): boolean {
    if (f.filterType === 1) {
      return (f as pbi.models.IBasicFilterWithKeys).values.length > 0;
    } else {
      return (f as pbi.models.IAdvancedFilter).conditions &&
        (f as pbi.models.IAdvancedFilter).conditions.length > 0
        ? (f as pbi.models.IAdvancedFilter).conditions
          .map((c) => c.value)
          .some((x) => x !== undefined && x.toString().length > 0)
        : false;
    }
  }

  public isVisible(f: pbi.models.ReportLevelFilters): boolean {
    return f.displaySettings?.isHiddenInViewMode !== true;
  }

  public isVisibleBasicPBIFilter(
    filter: pbi.models.IBasicFilterWithKeys
  ): boolean {
    return (
      filter.filterType === 1 &&
      filter.displaySettings?.isHiddenInViewMode != true
    );
  }

  private async updateTimePeriodInReport(
    pbiReport: pbi.Report,
    timePeriod: { from: any; to: any }
  ) {
    await pbiReport.getPages().then(async (pages) => {
      let pageWithSlicer = pages.filter(function (page) {
        return page.isActive;
      })[0];
      const visuals = await pageWithSlicer.getVisuals();
      let slicers = visuals.filter(function (visual) {
        return visual.type === 'slicer';
      });
      const filter: any = {
        $schema: 'http://powerbi.com/product/schema#advanced',
        target: {
          table: 'Date',
          column: 'Date',
        },
        filterType: 0,
        logicalOperator: 'And',
        conditions: [
          {
            operator: 'GreaterThanOrEqual',
            value: new Date(
              new Date(timePeriod.from).setHours(0)
            ).toISOString(),
          },
          {
            operator: 'LessThanOrEqual',
            value: new Date(timePeriod.to).toISOString(),
          },
        ],
      };
      await slicers.forEach(async (slicer) => {
        const slicerState = await slicer.getSlicerState();
        if (slicerState.targets.map((t) => t.table).includes('Date')) {
          await slicer.setSlicerState({ filters: [filter] });
        }
      });
    });
  }

  public getPbiFilterColumn(target: pbi.models.IFilterKeyTarget): string {
    return (
      (target as pbi.models.IFilterKeyColumnsTarget).column ??
      (target as pbi.models.IFilterKeyHierarchyTarget).hierarchyLevel
    );
  }

  private isPbiFilterChanged(
    basicFilters: pbi.models.IBasicFilterWithKeys[],
    advancedFilters: pbi.models.IAdvancedFilter[]
  ): boolean {
    if (
      this.activeBasicFilters.length !== basicFilters.length ||
      this.activeAdvancedFilters.length !== advancedFilters.length
    ) {
      return true;
    }
    for (const filter of basicFilters) {
      const matchingFilter = this.activeBasicFilters.find(
        (f) =>
          f.filterType === filter.filterType &&
          f.target.table === filter.target.table &&
          f.target['column'] === filter.target['column']
      );

      if (
        matchingFilter === undefined ||
        !this.arraysEqual(filter.values, matchingFilter.values)
      ) {
        return true;
      }
    }
    for (const filter of advancedFilters) {
      const matchingFilter = this.activeAdvancedFilters.find(
        (f) =>
          f.filterType === filter.filterType &&
          (f.target as pbi.models.IFilterKeyTarget).table ==
          (filter.target as pbi.models.IFilterKeyTarget).table &&
          (f.target as pbi.models.IFilterKeyColumnsTarget).column ==
          (filter.target as pbi.models.IFilterKeyColumnsTarget).column
      );

      if (
        matchingFilter === undefined ||
        !this.conditionsEqual(filter.conditions, matchingFilter.conditions)
      ) {
        return true;
      }
    }
    return false;
  }

  private arraysEqual(
    left: (string | number | boolean)[],
    right: (string | number | boolean)[]
  ): boolean {
    if (left.length !== right.length) {
      return false;
    }
    const uniqueValues = new Set([...left, ...right]);
    for (const val of uniqueValues) {
      const leftCount = left.filter((elem) => elem === val).length;
      const rightCount = right.filter((elem) => elem === val).length;
      if (leftCount !== rightCount) {
        return false;
      }
    }
    return true;
  }

  private conditionsEqual(
    leftConditions: pbi.models.IAdvancedFilterCondition[],
    rightConditions: pbi.models.IAdvancedFilterCondition[]
  ) {
    if (leftConditions.length != rightConditions.length) {
      return false;
    }
    let result = true;
    leftConditions.forEach((c) => {
      if (
        rightConditions.find(
          (rc) => c.operator === rc.operator && rc.value === c.value
        ) === undefined
      ) {
        result = false;
      }
    });
    return result;
  }

  private convertIsoStringToDate(isoString: string, isFrom: boolean) {
    let dateIsoString = isoString.substring(0, 10);
    const yearMonthLabel: string[] = dateIsoString.split('-');
    const year: number = Number.parseInt(yearMonthLabel[0], null);
    const month: number = parseInt(yearMonthLabel[1], null) - 1;
    const day: number = parseInt(yearMonthLabel[2], null);
    const hour = parseInt(isoString.split('T')[1].split(':')[0], null);

    let date: Date = undefined;

    if (isFrom) {
      // date = this.mapPbiPeriodFromValueToUrl(new Date(year, month, day, hour));
    } else {
      date = new Date(year, month, day);
    }

    return date;
  }

  private mapPbiPeriodFromValueToUrl(date: Date) {
    // in a PBI slicer date is set to 22:00 of previous day
    // or 23:00, it depends on the report for some reason
    if (date.getHours() == 22) {
      date.setHours(date.getHours() + 2);
    } else {
      date.setHours(date.getHours() + 1);
    }
    return date;
  }

  private async reportHasPeriodComponent() {
    let hasPeriode = false;
    await this.powerBiReport.getPages().then(async (pages) => {
      let pageWithSlicer = pages.filter(function (page) {
        return page.isActive;
      })[0];
      const visuals = await pageWithSlicer.getVisuals();
      let periodSlicers = visuals.filter(
        (visual) => visual.type === 'slicer' && visual.title === 'Periode'
      );
      hasPeriode = periodSlicers.length == 1;
    });
    return hasPeriode;
  }

  public async resetReportFilters(powerBiReport: pbi.Report) {
    let filters = await powerBiReport.getFilters();

    filters = filters.map((fv: pbi.models.IBasicFilter) => {
      return {
        $schema: fv.$schema,
        target: fv.target,
        filterType: 1,
        values: [],
        operator: 'All',
        displaySettings: fv.displaySettings,
      } as pbi.models.IFilter;
    });

    await powerBiReport.setFilters(filters);

    return powerBiReport;
  }

  public hasAdvancedFilters() {
    return this.activeAdvancedFilters.length > 0;
  }
}
