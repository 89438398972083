import { Component, OnInit } from '@angular/core';
import { PbiReportService } from '@modules/report/services/pbi-report.service';
import { PbiBookmarkService } from '@modules/report/services/pbi-bookmark.service';
import { ReportData } from '../../../../commons/models/powerbi-model';
import { Bookmark } from '../../../../commons/models/bookmark-model';
import { SnackbarService } from 'src/app/commons/services/snackbar.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DialogConfigData } from 'src/app/commons/models/dialog-config-data';
import { UserService } from '@modules/shell/services/user.service';
import { UserActivity, ActivityEventType } from 'src/app/commons/models/activity.model';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-header-bookmark-select',
  templateUrl: './header-bookmark-select.component.html',
  styleUrls: ['./header-bookmark-select.component.scss']
})
export class HeaderBookmarkSelectComponent implements OnInit {
  public list: ReportData;

  constructor(
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private activityService: ActivityService,
    private userService: UserService,
    public bookmarkService: PbiBookmarkService,
    public reportService: PbiReportService
  ) { }

  ngOnInit(): void { }

  delete(event: any, bookmark: Bookmark) {
    event.stopPropagation();
    this.bookmarkService.deleteBookmark(bookmark.id).subscribe(() => {
      this.activityService.sendActivity(new UserActivity(`Bookmark '${bookmark.title.toUpperCase()}' - deleted`, this.userService.currentUser.username, ActivityEventType.BookmarkDeleted));
      this.bookmarkService.bookmarks = this.bookmarkService?.bookmarks.filter(x => x.id !== bookmark.id);
      this.snackbarService.openSnackBar('bookmarks.deleted');
    });
  }

  edit(event: any, bookmark: Bookmark) {
    event.stopPropagation();
    this.dialogService
      .editBookmarkDialog(<DialogConfigData>{ value: bookmark.title })
      .afterClosed()
      .subscribe((res) => {
        if (res == 'save') {
          this.bookmarkService.setCurrentBookmark();

          this.activityService.sendActivity(new UserActivity(`Bookmark '${bookmark.title.toUpperCase()}' - renamed to '${this.bookmarkService.bookmark.title.toUpperCase()}'`, this.userService.currentUser.username, ActivityEventType.BookmarkModified));
          bookmark.title = this.bookmarkService.bookmark.title;

          this.bookmarkService.updateBookmark(bookmark)
            .subscribe(() => {
              this.bookmarkService.getBookmarks();
              this.snackbarService.openSnackBar('bookmarks.updated');
            });
        }
      });
  }

  logUsedActivity(bookmarkName: string): void {
    this.activityService.sendActivity(new UserActivity(`Bookmark '${bookmarkName}' - used`, this.userService.currentUser.username, ActivityEventType.BookmarkUsed));
  }
}
