import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TranslateModule } from '@ngx-translate/core';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDateFormats, MatNativeDateModule, MatOptionModule, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';

// Components
import { DisplayPatternPipe } from '../../pipes/display-pattern.pipe';
import { CustomAutocompleteComponent } from '../custom-autocomplete/custom-autocomplete.component';
import { CreateEditDialogComponent } from './create-edit-dialog.component';
import { CreateEditCapDialogComponent } from '@modules/customers-average-price/components/create-edit-cap-dialog/create-edit-cap-dialog.component';
import { CreateEditCodDialogComponent } from '@modules/customers-own-data/components/create-edit-cod-dialog/create-edit-cod-dialog.component';
import { ExistingRecordDialogComponent } from '../../existing-record-dialog/existing-record-dialog-dialog.component';
import { CreateEditPseDialogComponent } from '@modules/product-specific-emissions/components/create-edit-pse-dialog/create-edit-pse-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { CustomDateAdapter } from '../../services/custom-datepicker-adapter';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CreateEditExclusionDialogComponent } from '@modules/customers-own-data-exclusion/components/create-edit-exclusion-dialog/create-edit-exclusion-dialog.component';
import { DimaTableLibraryModule } from '@dima/table-library';
import { DataViewModule } from '../data-view/data-view.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { FiscalYearDialogComponent } from '@modules/customers-csrd/components/fiscal-year-dialog/fiscal-year-dialog.component';
import { MeasuringPointDialogComponent } from '@modules/customers-csrd/components/measuring-point-dialog/measuring-point-dialog.component';
import { ExportXbrlDialogComponent } from '../../export-xbrl-dialog/export-xbrl-dialog.component';

const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: '0001-01-01',
  },
  display: {
    dateInput: '0001-01-01',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    CreateEditDialogComponent,
    ExistingRecordDialogComponent,
    ExportXbrlDialogComponent,
    CreateEditCapDialogComponent,
    CreateEditCodDialogComponent,
    CreateEditExclusionDialogComponent,
    CreateEditPseDialogComponent,
    CustomAutocompleteComponent,
    FiscalYearDialogComponent,
    MeasuringPointDialogComponent,
    DisplayPatternPipe,
    TruncatePipe
  ],
  imports: [
    BrowserModule,
    TranslateModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,
    MatTreeModule,
    MatCheckboxModule,
    DimaTableLibraryModule,
    DataViewModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule
  ],
  exports: [CreateEditDialogComponent],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  { provide: DateAdapter, useClass: CustomDateAdapter },
  { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },]
})
export class CreateEditDialogModule { }
