import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogResultStatus } from '../existing-record-dialog/dialog-result-status';
import { FiscalYear } from '../models/fiscal-year.model';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-export-xbrl-dialog',
    templateUrl: './export-xbrl-dialog.component.html',
    styleUrls: ['./export-xbrl-dialog.component.scss'],
    standalone: false
})
export class ExportXbrlDialogComponent {
  fiscalYears: FiscalYear[];
  selectedFiscalYear: number;

  constructor(
    private dialogRef: MatDialogRef<ExportXbrlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fiscalYears = data.fiscalYears as FiscalYear[];
  }

  onSelectionChange(event: MatSelectChange) {
    this.selectedFiscalYear = event.value;
  }

  cancel() {
    this.dialogRef.close({ result: DialogResultStatus.CANCEL });
  }

  confirm(): void {
    this.dialogRef.close({ result: DialogResultStatus.CONFIRM, value: this.selectedFiscalYear });
  }
}
