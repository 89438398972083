import { Component, Host } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataViewComponent } from 'src/app/commons/components/data-view/data-view.component';
import { DialogService } from 'src/app/services/dialog.service';
import { TableService } from '@modules/customers-csrd/services/measuring-points/table.service';
import { FilterService } from '@modules/customers-csrd/services/measuring-points/filter.service';
import { MeasuringPointsGetService } from '@modules/customers-csrd/services/measuring-points/measuring-points-get.service';
import { MeasuringPointsService } from '@modules/customers-csrd/services/measuring-points/measuring-points.service';
import { FiscalYearsGetService } from '@modules/customers-csrd/services/fiscal-years/fiscal-years-get.service';
import { PageModel } from 'src/app/commons/models/page-model';
import { FiscalYear } from 'src/app/commons/models/fiscal-year.model';
import { PageResponse } from 'src/app/commons/models/page-response';
import { DialogResultStatus } from 'src/app/commons/existing-record-dialog/dialog-result-status';
import { XbrlService } from 'src/app/commons/services/xbrl.service';
import { ActivityService } from 'src/app/services/activity.service';
import { ActivityEventType, UserActivity } from 'src/app/commons/models/activity.model';
import { UserService } from '@modules/shell/services/user.service';

@Component({
    selector: 'app-measuring-point-action-menu',
    templateUrl: './measuring-point-action-menu.component.html',
    styleUrls: ['./measuring-point-action-menu.component.scss'],
    providers: [DataViewComponent,
        { provide: 'ITableService', useExisting: TableService },
        { provide: 'IGetDataService', useExisting: MeasuringPointsGetService },
        { provide: 'IDataService', useExisting: MeasuringPointsService },
        { provide: 'IFilterService', useExisting: FilterService },
    ],
    standalone: false
})
export class MeasuringPointActionMenuComponent {
  translationKey = 'csrd';
  parentComponent: DataViewComponent;
  isDeleteButtonDisabled = true;

  constructor(@Host() parent: DataViewComponent,
    public translateService: TranslateService,
    private dialogService: DialogService,
    private fiscalYearGetService: FiscalYearsGetService,
    private xbrlService: XbrlService,
    private activityService: ActivityService,
    private userService: UserService) {
    this.parentComponent = parent;
  }

  exportDialog(): void {
    this.fiscalYearGetService.get(<PageModel>{ page: '0', pageSize: '1000', orderBy: 'order by Id desc', search: 1 })
      .subscribe(data => {
        this.dialogService.exportXbrlDialog((data as PageResponse).pagedProductsDaos as FiscalYear[])
          .afterClosed()
          .subscribe((x) => {
            if (x.result === DialogResultStatus.CONFIRM) {
              this.xbrlService.downloadXbrlFile(x.value);
              this.activityService.sendActivity(new UserActivity('Measuring points - XBRL generated', this.userService.currentUser.username, ActivityEventType.MeasuringPointXbrlExported));
            }
            if (x.result === DialogResultStatus.CANCEL) {

            }
          });
      });
  }
}
