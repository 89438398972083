<button id="bookmarkBtn" mat-button [matMenuTriggerFor]="menuReport" class="fs-16 fw-bold dropdown-menu ps-3 pe-4">
  {{ 'reports.bookmarks' | translate }}
  <mat-icon fontIcon="arrow_drop_down" class="arrow-drop-down"></mat-icon>
</button>

<mat-menu #menuReport="matMenu">
  <ng-container *ngIf="bookmarkService?.bookmarks?.length > 0; else noOptions">
    <ng-container *ngFor="let item of bookmarkService.bookmarks | orderBy:'title'; index as i">

      <div class="menuRow">
        <button [id]="'titleItem'+i" class="title" mat-menu-item
          (click)="reportService.loadReportFromUrl(item.path); logUsedActivity(item.title)">
          {{ item.title }}
        </button>

        <button [id]="'editItemBtn'+i" class="editBtn" mat-icon-button color="primary" (click)="edit($event, item)">
          <mat-icon fontIcon="edit"></mat-icon>
        </button>

        <button [id]="'deleteItemBtn'+i" class="deleteBtn" mat-icon-button color="warn"
          (click)="delete($event, item)">
          <mat-icon fontIcon="delete"></mat-icon>
        </button>
      </div>

    </ng-container>
  </ng-container>
  <ng-template #noOptions>
    <div class="p-2">{{ 'bookmarks.no-bookmarks' | translate }}</div>
  </ng-template>
</mat-menu>