<span class="action-button">
  <button mat-raised-button color="primary" [disabled]="isReadonlyUser()" (click)="createDialog()">
    <mat-icon fontIcon="file_upload"></mat-icon>
    {{ translationKey + '.create' | translate }}
  </button>
</span>

<span >
  <DimaTable-columns-display class="action-button" columnsId="table"></DimaTable-columns-display>
</span>

<span class="action-button">
  <input #fileInput type="file" style="display: none;" name="datasource_upload" id="datasource_upload" accept=".csv"
    ngf-max-size="20MB" fd-input (change)="uploadDatasource($event)" />
  <button mat-raised-button color="primary" [disabled]="isReadonlyUser()" (click)="fileInput.click()">
    <mat-icon fontIcon="file_upload"></mat-icon>
    {{ 'customers-own-data.upload-csv' | translate }}
  </button>
</span>

<span class="action-button">
  <button mat-raised-button color="warn" [disabled]="isDeleteButtonDisabled || isReadonlyUser()"
    (click)="deleteSelected()">
    <mat-icon fontIcon="delete"></mat-icon>
    {{ translationKey + '.delete-selected' | translate }}
  </button>
</span>

<span class="action-button">
  <button mat-raised-button color="primary"
    [disabled]="isStatusChangeButtonDisabled || preventStatusChange || isReadonlyUser()" (click)="changeStatus(true)">
    <mat-icon fontIcon="check_box_outline"></mat-icon>
    {{ translationKey + '.activate-selected' | translate }}
  </button>
</span>

<span class="action-button">
  <button mat-raised-button color="primary"
    [disabled]="isStatusChangeButtonDisabled || preventStatusChange || isReadonlyUser()" (click)="changeStatus(false)">
    <mat-icon fontIcon="check_box_outline_blank"></mat-icon>
    {{ translationKey + '.deactivate-selected' | translate }}
  </button>
</span>