<form [formGroup]="formService.form">
  <div class="container gx-0">
    <div class="row">
      <div class="col-12 mt-3 mb-3">
        <p class="fw-bold fs-30">
          {{ 'navigation.customers-own-data' | translate }}
        </p>
      </div>
    </div>
    <div class="row justify-content-around align-items-stretch mt-3">
      <div class="col-12">
        <div mat-dialog-content>
          <div class="row main-row-dialog">
            <div class="col-12 mt-3">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>{{
                          'customers-own-data.transaction-date'
                          | translate
                          }}</mat-label>
                        <input matInput [matDatepicker]="pickerTransactionDate" formControlName="TransactionDate"
                          (dateChange)="formService.onDateChange($event)" placeholder="{{
                                'customers-own-data.transaction-date'
                                  | translate
                              }}" />
                        <mat-datepicker-toggle matSuffix [for]="pickerTransactionDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerTransactionDate></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-own-data.product-id' | translate
                          }}</mat-label>
                        <input formControlName="ProductID" matInput placeholder="{{
                                'customers-own-data.product-id' | translate
                              }}" />
                      </mat-form-field>
                    </div>

                  </div>
                </div>


                <div class="col-12">
                  <div class="row">

                    <div class="col-6" *ngIf="supplierOptionsInitialized">
                      <app-custom-autocomplete [parentGroup]="formService.form" [options]="supplierOptions"
                        controlName="SupplierID" [filterFieldNames]="['supplierName', 'supplierId']"
                        valueFieldName="supplierId" orderFieldName="supplierName"
                        displayFieldPattern="${supplierName} (CVR:${supplierCountry}${supplierId})"
                        labelTranslationKey="customers-average-price.supplier"
                        (selectionChanged)="onSupplierSelectionChange($event)"
                        (valueCleared)="onSupplierValueCleared()"
                        (inputTextChanged)="onSupplierTextChanged($event)">
                      </app-custom-autocomplete>
                    </div>

                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-average-price.supplier-country' | translate
                          }}</mat-label>
                        <input formControlName="SupplierCountry" matInput placeholder="{{
                            'customers-average-price.supplier-country'
                              | translate
                          }}" />
                      </mat-form-field>
                    </div>

                    <div class="col-6" *ngIf="unspscsOptionsInitialized">
                      <app-custom-autocomplete [parentGroup]="formService.form" [options]="unspscsOptions"
                        controlName="UnspscClassId" [filterFieldNames]="['idDescriptionUnspsc']"
                        valueFieldName="unspscClassId" displayFieldPattern="${idDescriptionUnspsc}"
                        orderFieldName="idDescriptionUnspsc" labelTranslationKey="customers-average-price.unspsc"
                        (selectionChanged)="onUnspscSelectionChange($event)" (valueCleared)="onUnspscValueCleared()">
                      </app-custom-autocomplete>
                    </div>

                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-own-data.unit' | translate
                          }}</mat-label>
                        <input formControlName="Unit" matInput placeholder="{{
                                'customers-own-data.unit' | translate
                              }}" />
                      </mat-form-field>
                    </div>

                  </div>
                </div>

                <div class="col-12">
                  <div class="row">

                    <div class="col-6" *ngIf="organizationOptionsInitialized">
                      <app-custom-autocomplete [parentGroup]="formService.form" [options]="filteredOrganizationOptions"
                        controlName="OrganizationID" [filterFieldNames]="['organizationID']"
                        valueFieldName="organizationID" orderFieldName="organizationID"
                        displayFieldPattern="${organizationalUnitViewDescription}"
                        labelTranslationKey="customers-own-data.organization-id">
                      </app-custom-autocomplete>
                    </div>

                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-own-data.supplier-emission-co2'
                          | translate
                          }}</mat-label>
                        <input formControlName="SupplierEmissionCo2" matInput placeholder="{{
                            'customers-own-data.supplier-emission-co2'
                              | translate
                          }}" />
                        <mat-error *ngIf="formService.form.get('SupplierEmissionCo2').hasError('pattern')">
                          {{ 'error-handling.numeric-value-error' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                  </div>
                </div>

                <div class="col-12">
                  <div class="row">

                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-own-data.matching-ghg-category'
                          | translate
                          }}</mat-label>
                        <input style="display: none;" #matchingGhgCategory formControlName="MatchingGhgCategory"
                          matInput [matTooltip]="matchingGhgCategory.value" />
                        <input [value]="matchingGhgCategory.value | truncate : '40'" matInput
                          [matTooltip]="matchingGhgCategory.value" disabled />
                      </mat-form-field>
                    </div>

                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>
                          {{ 'customers-own-data.ghg-category' | translate }}
                        </mat-label>
                        <mat-select formControlName="GhgCategory">
                          <mat-option
                            *ngIf="!(ghgCategoryUnspscs?.length == 0 && formService.form.controls.UnspscClassId?.value)"
                            [value]="0">Default</mat-option>
                          <ng-container *ngFor="let item of ghgCategoryOptions | async">
                            <mat-option [value]="item.ghgCatId">{{ 'Scope: ' + item.scope + ', ' +
                              ('customers-own-data.category' | translate) + ' ' + item.ghgCatNumber + ', ' +
                              item.ghgCatTitle}}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>

                  </div>
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-own-data.invoice-number'
                          | translate
                          }}</mat-label>
                        <input formControlName="InvoiceNumber" matInput placeholder="{{
                                  'customers-own-data.invoice-number'
                                    | translate
                                }}" />
                      </mat-form-field>
                    </div>

                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-own-data.buyers-ean' | translate
                          }}</mat-label>
                        <input formControlName="BuyersEAN" matInput placeholder="{{
                                'customers-own-data.buyers-ean' | translate
                              }}" />
                      </mat-form-field>
                    </div>

                  </div>
                </div>

                <div class="col-12">
                  <div class="row">

                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-own-data.line-amount-without-vat'
                          | translate
                          }}</mat-label>
                        <input formControlName="LineAmountWithoutVAT" matInput placeholder="{{
                                'customers-own-data.line-amount-without-vat'
                                  | translate
                              }}" />
                        <mat-error *ngIf="formService.form.get('LineAmountWithoutVAT').hasError('pattern')">
                          {{ 'error-handling.numeric-value-error' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-own-data.quantity' | translate
                          }}</mat-label>
                        <input formControlName="Quantity" matInput placeholder="{{
                                'customers-own-data.quantity' | translate
                              }}" />
                        <mat-error *ngIf="formService.form.get('Quantity').hasError('pattern')">
                          {{ 'error-handling.numeric-value-error' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                  </div>
                </div>

                <div class="col-12">
                  <div class="row">

                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-own-data.product-name' | translate
                          }}</mat-label>
                        <input formControlName="ProductName" matInput placeholder="{{
                                'customers-own-data.product-name' | translate
                              }}" />
                      </mat-form-field>
                    </div>

                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-own-data.product-description' | translate
                          }}</mat-label>
                        <input formControlName="ProductDescription" matInput placeholder="{{
                                'customers-own-data.product-description' | translate
                              }}" />
                      </mat-form-field>
                    </div>

                    <div class="col-12 fs-16 mt-4 mb-3 fw-bold" [hidden]="true">
                      {{
                      'customers-own-data.created-modified'
                      | translate
                      }}
                    </div>

                    <div class="col-6" [hidden]="true">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>{{
                          'customers-own-data.creation-date'
                          | translate
                          }}</mat-label>
                        <input matInput [matDatepicker]="pickerCreationDate" formControlName="CreationDate" />
                        <mat-datepicker-toggle matSuffix [for]="pickerCreationDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerCreationDate></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="col-6" [hidden]="true">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-own-data.created-by'
                          | translate
                          }}</mat-label>
                        <input formControlName="CreatedBy" matInput placeholder="{{
                                'customers-own-data.created-by'
                                  | translate
                              }}" />
                      </mat-form-field>
                    </div>

                    <div class="col-6" [hidden]="true" *ngIf="editingMode">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>{{
                          'customers-own-data.modify-date'
                          | translate
                          }}</mat-label>
                        <input matInput [matDatepicker]="pickerModificationDate" formControlName="ModifyDate"
                          placeholder="{{
                                'customers-own-data.modify-date'
                                  | translate
                              }}" />
                        <mat-datepicker-toggle matSuffix [for]="pickerModificationDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerModificationDate></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="col-6" [hidden]="true" *ngIf="editingMode">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'customers-own-data.modify-by' | translate
                          }}</mat-label>
                        <input formControlName="ModifiedBy" matInput placeholder="{{
                                'customers-own-data.modify-by'
                                  | translate
                              }}" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div mat-dialog-actions [align]="'end'">
          <button mat-raised-button (click)="cancel()" [mat-dialog-close]="false">
            {{ 'customers-own-data.cancel' | translate }}
          </button>

          <button mat-raised-button color="primary" [disabled]="formService.form.invalid || isReadonlyUser()" (click)="submitForm()">
            {{ 'customers-own-data.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>