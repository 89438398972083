import { Component, OnInit } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { SidebarService } from '@modules/shell/services/sidebar.service';
import { MenuItem } from './menu-item';
import { UserService } from '@modules/shell/services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  isSidebarOpen: boolean = false;
  menuItems: MenuItem[] = [];

  constructor(private sidebarService: SidebarService, private router: Router, public userService: UserService) {
    this.createMenu(this.menuItems, this.router.config);
  }

  ngOnInit(): void {
    this.sidebarService.isSidebarOpen$.subscribe((sidebarState) => {
      this.isSidebarOpen = sidebarState;
    });
  }

  // Create menu from routes, only add if route has data property
  private createMenu(MenuItems: MenuItem[], routes: Routes, path: string = '') {
    routes = routes.find((el) => el.path == '')?.children;
    if (routes) {
      routes.forEach((route) => {
        if (route.data) {
          var menuItem = {
            title: route.data.title,
            icon: route.data.icon,
            translationKey: route.data.translationKey,
            description: route.data?.description,
            path: path + '/' + route.path,
            status: route.data.status || undefined,
          } as MenuItem;

          MenuItems.push(menuItem);
        }
      });
    }
  }

  onMouseLeave() {
    this.sidebarService.setSidebarState(false);
  }
}
