import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ROUTENAMES } from '../app-routing.names';
import { InitiativesService } from '@modules/initiatives/initiatives.service';
import { InitiativeMasterDto } from '../commons/models/initiative-dto';
import { EntityTrackerError } from '../commons/models/entity-tracker-error';

@Injectable({
  providedIn: 'root',
})
export class InitiativesResolver
  
{
  constructor(
    private router: Router,
    private initiativesService: InitiativesService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<InitiativeMasterDto[] | EntityTrackerError> {
    return this.initiativesService.getInitiatives().pipe(
      catchError(() => {
        this.router.navigate([ROUTENAMES.UNAUTHORIZED]);
        return EMPTY;
      })
    );
  }
}
