import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ROUTENAMES } from '../../../../app-routing.names';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent {
  errorTitle: any;
  errorCodeDescription: any;
  errorSubtitle: any;
  buttonName: any;
  navigationRoute: string;

  constructor(public translateService: TranslateService) {
    this.errorTitle = this.translateService.instant('errors.forbidden.title');
    this.errorCodeDescription = this.translateService.instant(
      'errors.forbidden.type'
    );
    this.errorSubtitle = this.translateService.instant(
      'errors.forbidden.subtitle'
    );
    this.buttonName = this.translateService.instant('errors.forbidden.cta');

    this.navigationRoute = ROUTENAMES.HOME;
  }
}
