import { Component, OnInit } from '@angular/core';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { AdminService } from '../../../../services/admin.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-tenant-select',
  templateUrl: './tenant-select.component.html',
  styleUrls: ['./tenant-select.component.scss'],
})
export class TenantSelectComponent implements OnInit {
  public showTenantSelectElement: boolean = true;

  constructor(
    public tenantSelectService: TenantSelectService,
    private adminService: AdminService,
    private router: Router
  ) {

    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
    ).subscribe((event: NavigationStart) => {
      this.showTenantSelectElement = false;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.showTenantSelectElement = true;
    });
  }

  getTenantDisplayValue(): string {
    return this.tenantSelectService.tenants.find((el) => el.identityProviderKey == this.tenantSelectService.idp)?.name
  }

  ngOnInit(): void {
    // sets the select in the top bar when the webpage is reloaded
    if (!this.tenantSelectService.getIdpFromUrl()) {
      let idpKey =
        this.tenantSelectService.getDefaultTenant().identityProviderKey;
      this.tenantSelectService.tenantHandlerForm.controls['tenant'].patchValue(
        idpKey
      );
      this.adminService.selectedIdentityProvider =
        this.tenantSelectService.getDefaultTenant().identityProviderKey;
    } else {
      this.tenantSelectService.tenantHandlerForm.controls['tenant'].patchValue(
        this.tenantSelectService.getIdpFromUrl()
      );

      this.adminService.selectedIdentityProvider =
        this.tenantSelectService.getIdpFromUrl() as string;
    }

    this.tenantSelectService.onSelectChanges();
  }
}
