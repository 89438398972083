import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { DialogService } from '../../../../services/dialog.service';
import { ApiService } from '../../../../services/api.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { CalculateDto } from '../../../../commons/models/scenario-dto';
import { ActivatedRoute, Router } from '@angular/router';
import { ScenariosService } from '@modules/scenarios/services/scenarios/scenarios.service';
import { ROUTENAMES } from '../../../../app-routing.names';
import { SnackbarService } from '../../../../commons/services/snackbar.service';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { DK } from '../../../../constants/config.constants';
import { PreviousRouteService } from 'src/app/commons/services/previous-route.service';
import { UserService } from '@modules/shell/services/user.service';
import { UserActivity, ActivityEventType } from 'src/app/commons/models/activity.model';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-scenarios-list',
  templateUrl: './scenarios-list.component.html',
  styleUrls: ['./scenarios-list.component.scss'],
})
export class ScenariosListComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public columnsConfigs = [
    {
      key: 'id',
      name: this.translateService.stream('scenarios.columns.id'),
      width: '5%',
    },
    {
      key: 'name',
      name: this.translateService.stream('scenarios.columns.name'),
      width: '45%',
    },
    {
      key: 'totalLineAmountChange',
      name: this.translateService.stream('scenarios.columns.expense-change'),
      width: '15%',
    },
    {
      key: 'totalEmissionChange',
      name: this.translateService.stream('scenarios.columns.emission-change'),
      width: '15%',
    },
    {
      key: 'email',
      name: this.translateService.stream('scenarios.columns.email'),
      width: '15%',
    },
  ];

  public dataSource: MatTableDataSource<CalculateDto>;
  private scenariosSubscription?: Subscription;
  private scenariosDeleteSubscription?: Subscription;
  private expenseAndEmissionChange: any;

  maxUnspscsLimit: number = 5;

  constructor(
    private dialogService: DialogService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackbarService: SnackbarService,
    private previousRouteService: PreviousRouteService,
    private activityService: ActivityService,
    private userService: UserService,
    public tenantSelectService: TenantSelectService,
    public translateService: TranslateService,
    public scenariosService: ScenariosService,
  ) {
  }

  ngOnInit(): void {
    this.scenariosSubscription = this.activatedRoute.data.subscribe(
      (data) => {
        let scenarios = data.scenarios;

        scenarios = this.addTotalExpenseAndEmissionChange(scenarios);
        scenarios = this.generateUnspscGridValues(scenarios);
        this.dataSource = new MatTableDataSource(scenarios);
      },
      (error) => {
        console.log(error);
      }
    );

    this.tenantSelectService.setCountrySpecificTranslations(
      this.tenantSelectService.userIdp == 'oslo-aad-prod' ||
      this.tenantSelectService.tenantHandlerForm.get('tenant').value ==
      'oslo-aad-prod'
    );
  }

  ngAfterViewInit(): void {
    this.activityService.sendActivity(new UserActivity('Scenarios - view activated', this.userService.currentUser.username, ActivityEventType.ScenariosViewActivated));
    this.tenantSelectService.selectIdpUrlParam();
    this.previousRouteService.clearData();
  }

  ngOnDestroy(): void {
    this.scenariosSubscription.unsubscribe();
    if (this.scenariosDeleteSubscription) {
      this.scenariosDeleteSubscription.unsubscribe();
    }
  }

  addTotalExpenseAndEmissionChange(scenarios) {
    this.expenseAndEmissionChange =
      this.scenariosService.calculateChanges(scenarios);
    scenarios.map((scenario) => {
      Object.assign(scenario, {
        totalLineAmountChange: this.expenseAndEmissionChange.find(
          (el) => scenario.calculationId == el.calculationId
        ).expenseChange,
      });
      Object.assign(scenario, {
        totalEmissionChange: this.expenseAndEmissionChange.find(
          (el) => scenario.calculationId == el.calculationId
        ).emissionChange,
      });
    });

    return scenarios;
  }

  createScenarioDialog() {
    this.dialogService
      .createScenario()
      .afterClosed()
      .subscribe((res) => {
        if (res !== 'cancel') {
          this.scenariosService.getScenarios().subscribe((scenarios) => {
            scenarios = this.addTotalExpenseAndEmissionChange(scenarios);
            scenarios = this.generateUnspscGridValues(scenarios);
            this.dataSource = new MatTableDataSource(
              scenarios as CalculateDto[]
            );
            this.snackbarService.openSnackBar('scenarios.scenario-created');
          });
        }
      });
  }

  goToTemplates() {
    this.router.navigate([ROUTENAMES.TEMPLATES]);
  }

  editScenario(row) {
    this.dialogService
      .editScenario(row)
      .afterClosed()
      .subscribe((res) => {
        if (res === 'update') {
          this.scenariosService.getScenarios().subscribe((scenarios) => {
            scenarios = this.addTotalExpenseAndEmissionChange(scenarios);
            scenarios = this.generateUnspscGridValues(scenarios);
            this.dataSource = new MatTableDataSource(
              scenarios as CalculateDto[]
            );
            this.snackbarService.openSnackBar('scenarios.scenario-updated');
          });
        }
      });
  }

  deleteScenario({ calculationId }) {
    this.dialogService
      .deleteDialog({
        message: this.translateService.instant('scenarios.confirmatory-text'),
        confirmText: this.translateService.instant('scenarios.yes'),
        cancelText: this.translateService.instant('scenarios.no'),
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === 'confirm') {
          this.scenariosDeleteSubscription = this.apiService
            .deleteScenario(calculationId)
            .subscribe(
              (scenarios: CalculateDto[]) => { },
              () => { },
              () => {
                this.scenariosService.getScenarios().subscribe((scenarios) => {
                  scenarios = this.addTotalExpenseAndEmissionChange(scenarios);
                  scenarios = this.generateUnspscGridValues(scenarios);
                  this.dataSource = new MatTableDataSource(
                    scenarios as CalculateDto[]
                  );
                  this.snackbarService.openSnackBar(
                    'scenarios.scenario-deleted'
                  );
                });
              }
            );
        }
      });
  }

  public getGridUnspscs(unspscsGrid: any, unspscIndex: number) {
    return unspscsGrid.filter(x => x.index == unspscIndex);
  }

  protected readonly DK = DK;

  private generateUnspscGridValues(scenarios: any): any {
    scenarios.forEach((x, i) => {
      let scenariosToDisplay = [];
      x.index = x.id;
      x.unspscs
        .forEach((y, mainIndex) => {
          if (y.unspscClassID.includes(',')) {
            const descriptions = y.unspscClassDescription.split(';');
            scenariosToDisplay.push(...y.unspscClassID.split(',').map((unspscClassID, i) => <any>{
              unspscClassID: unspscClassID,
              unspscClassDescription: descriptions[i],
              index: mainIndex
            }))
          } else {
            scenariosToDisplay.push(<any>{
              unspscClassID: y.unspscClassID,
              unspscClassDescription: y.unspscClassDescription,
              index: mainIndex
            });
          }
        });

      x.unspscsGrid = scenariosToDisplay;
    });

    return scenarios;
  }
}
