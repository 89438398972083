<form [formGroup]="formService.form">
  <div class="container gx-0">
    <div class="row">
      <div class="col-12 mt-3 mb-3">
        <p class="fw-bold fs-30">
          {{ 'navigation.product-specific-emissions' | translate }}
        </p>
      </div>
    </div>
    <div class="row justify-content-around align-items-stretch mt-3">
      <div class="col-12">
        <div mat-dialog-content>
          <div class="row main-row-dialog">
            <div class="col-12">
              <div class="row">
                <div class="col-12 fs-16 mt-4 mb-3 fw-bold">
                  {{
                  'product-specific-emissions.product-data'
                  | translate
                  }}
                </div>
                <div class="col-6" *ngIf="supplierOptionsInitialized">
                  <app-custom-autocomplete #suppliersAuto [parentGroup]="formService.form" [options]="supplierOptions"
                    controlName="SupplierID" [filterFieldNames]="['supplierName', 'supplierId']"
                    valueFieldName="supplierId" orderFieldName="supplierName"
                    displayFieldPattern="${supplierName} (CVR:${supplierCountry}${supplierId})"
                    labelTranslationKey="customers-average-price.supplier"
                    (selectionChanged)="onSupplierSelectionChange($event)"
                    (selectionDataInitialized)="onSupplierSelectionDataInitialized()">
                  </app-custom-autocomplete>
                  <mat-error *ngIf="formService.form.get('SupplierID').hasError('maxlength')" style="margin-top: -1rem; padding-bottom: 0.5rem;">
                    {{ ('error-handling.max-length-exceeded' | translate) +
                    formService.fieldLengths['SupplierID'] }}
                  </mat-error>
                  <mat-error *ngIf="formService.form.get('SupplierID').hasError('error')" style="margin-top: -1rem; padding-bottom: 0.5rem;">
                    {{ 'error-handling.validation' | translate }}
                  </mat-error>
                </div>

                <div class="col-6" *ngIf="editData ? supplierItemOptionsInitialized : true">
                  <app-custom-autocomplete #itemsAuto [parentGroup]="formService.form" [options]="supplierItemOptions"
                    controlName="ItemID" [filterFieldNames]="['itemId']" valueFieldName="itemId" orderFieldName="itemId"
                    displayFieldPattern="${itemId}" labelTranslationKey="product-specific-emissions.item-id"
                    (selectionChanged)="onSupplierItemSelectionChange($event)"
                    (blur)="onSupplierItemSelectionChange($event)"
                    (selectionDataInitialized)="onSupplierItemSelectionDataInitialized()">
                  </app-custom-autocomplete>
                  <mat-error *ngIf="formService.form.get('ItemID').hasError('maxlength')" style="margin-top: -1rem; padding-bottom: 0.5rem;">
                    {{ ('error-handling.max-length-exceeded' | translate) +
                    formService.fieldLengths['ItemID'] }}
                  </mat-error>
                  <mat-error *ngIf="formService.form.get('ItemID').hasError('error')" style="margin-top: -1rem; padding-bottom: 0.5rem;">
                    {{ 'error-handling.validation' | translate }}
                  </mat-error>
                </div>

                <div class="col-6" *ngIf="editData ? itemUnitsOptionsInitialized : true">
                  <app-custom-autocomplete #unitsAuto [parentGroup]="formService.form"
                    [options]="supplierItemUnitsOptions" controlName="UnitCode" [filterFieldNames]="['unitCode']"
                    valueFieldName="unitCode" orderFieldName="unitCode" displayFieldPattern="${unitCode}"
                    labelTranslationKey="product-specific-emissions.unit-code"
                    (selectionDataInitialized)="onItemUnitCodeSelectionDataInitialized()">
                  </app-custom-autocomplete>
                  <mat-error *ngIf="formService.form.get('UnitCode').hasError('maxlength')">
                    {{ ('error-handling.max-length-exceeded' | translate) +
                    formService.fieldLengths['UnitCode'] }}
                  </mat-error>
                </div>

                <div class="col-6" style="display: none;">
                  <mat-form-field appearance="fill">
                    <mat-label>{{
                      'customers-average-price.supplier-country' | translate
                      }}</mat-label>
                    <input formControlName="SupplierCountry" matInput placeholder="{{
                            'customers-average-price.supplier-country'
                              | translate
                          }}" />
                  </mat-form-field>
                  <mat-error *ngIf="formService.form.get('SupplierCountry').hasError('maxlength')">
                    {{ ('error-handling.max-length-exceeded' | translate) +
                    formService.fieldLengths['SupplierCountry'] }}
                  </mat-error>
                </div>

              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 fs-16 mt-4 mb-3 fw-bold">
                  {{
                  'product-specific-emissions.emissions-data'
                  | translate
                  }}
                </div>

                <div class="col-12">
                  <div class="row">

                    <div class="col-6">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>{{
                          'product-specific-emissions.emission-valid-from'
                          | translate
                          }}</mat-label>
                        <input matInput [matDatepicker]="pickerEmissionValidFrom" formControlName="EmissionValidFrom"
                          (dateChange)="formService.onDateChange($event)" placeholder="{{
                                'product-specific-emissions.emission-valid-from'
                                  | translate
                              }}" />
                        <mat-datepicker-toggle matSuffix [for]="pickerEmissionValidFrom">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerEmissionValidFrom></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="col-6">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>{{
                          'product-specific-emissions.emission-valid-to'
                          | translate
                          }}</mat-label>
                        <input matInput [matDatepicker]="pickerEmissionValidTo" formControlName="EmissionValidTo"
                          placeholder="{{
                                'product-specific-emissions.emission-valid-to'
                                  | translate
                              }}" />
                        <mat-datepicker-toggle matSuffix [for]="pickerEmissionValidTo">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerEmissionValidTo></mat-datepicker>
                      </mat-form-field>
                    </div>

                  </div>
                </div>

                <div class="col-6">
                  <mat-form-field appearance="fill">
                    <mat-label>{{
                      'product-specific-emissions.item-name' | translate
                      }}</mat-label>
                    <input formControlName="ItemName" matInput placeholder="{{
                                'product-specific-emissions.item-name'
                                  | translate
                              }}" />
                    <mat-error *ngIf="formService.form.get('ItemName').hasError('maxlength')">
                      {{ ('error-handling.max-length-exceeded' | translate) +
                      formService.fieldLengths['ItemName'] }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-6">
                  <mat-form-field appearance="fill">
                    <mat-label>{{
                      'product-specific-emissions.item-description'
                      | translate
                      }}</mat-label>
                    <input formControlName="ItemDescription" matInput placeholder="{{
                                'product-specific-emissions.item-description'
                                  | translate
                              }}" />
                    <mat-error *ngIf="formService.form.get('ItemDescription').hasError('maxlength')">
                      {{ ('error-handling.max-length-exceeded' | translate) +
                      formService.fieldLengths['ItemDescription'] }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-6">
                  <mat-form-field appearance="fill">
                    <mat-label>{{
                      'product-specific-emissions.emission-co2'
                      | translate
                      }}</mat-label>
                    <input formControlName="EmissionCo2Kg" matInput placeholder="{{
                        'product-specific-emissions.emission-co2'
                          | translate
                      }}" />
                    <mat-error *ngIf="formService.form.get('EmissionCo2Kg').hasError('maxlength')">
                      {{ ('error-handling.max-length-exceeded' | translate) +
                      formService.fieldLengths['EmissionCo2Kg'] }}
                    </mat-error>
                    <mat-error *ngIf="formService.form.get('EmissionCo2Kg').hasError('pattern')">
                      {{ 'error-handling.numeric-value-error' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-6">
                  <mat-form-field appearance="fill">
                    <mat-label>{{
                      'product-specific-emissions.method' | translate
                      }}</mat-label>
                    <mat-select class="margin-custom-adjustment" required formControlName="Method">
                      <mat-option *ngFor="let item of methods" [value]="item">{{ item }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-12">
                  <div class="row">

                    <div class="col-6">
                      <div class="file-upload">
                        <span>
                          <input #fileInput type="file" style="display: none;" name="datasource_upload"
                            id="datasource_upload" accept=".pdf,.doc,.docx,.odt" ngf-max-size="20MB" fd-input
                            (change)="uploadDatasource($event)" />
                          <button mat-raised-button color="primary" (click)="fileInput.click()"
                            style="margin-top: 1rem;">
                            {{ 'product-specific-emissions.upload-method-document' | translate }}
                            <mat-icon fontIcon="file_upload"></mat-icon>
                          </button>
                          <mat-error *ngIf="invalidFileFormat">
                            {{ 'error-handling.invalid-document-file-format' | translate }}
                          </mat-error>
                        </span>
                        <div style="display: flex;">

                          <p class="truncate" [style.max-width.px]="fileNameMaxWidth"> {{selectedFilename}}</p>

                          <button style="margin-left: 0.1rem" *ngIf="selectedFilename" mat-icon-button color="primary"
                            (click)="downloadFile()">
                            <mat-icon>
                              file_download
                            </mat-icon>
                            <span class="mat-mdc-focus-indicator"></span>
                            <span matripple="" class="mat-ripple mat-mdc-button-ripple" ng-reflect-disabled="false"
                              ng-reflect-centered="true"></span>
                            <span class="mat-mdc-button-touch-target"></span>
                          </button>
                          <button style="margin-left: 0.1rem" *ngIf="selectedFilename" mat-icon-button color="warn"
                            (click)="clearChosenFile()">
                            <mat-icon>
                              delete
                            </mat-icon>
                            <span class="mat-mdc-focus-indicator"></span>
                            <span matripple="" class="mat-ripple mat-mdc-button-ripple" ng-reflect-disabled="false"
                              ng-reflect-centered="true"></span>
                            <span class="mat-mdc-button-touch-target"></span>
                          </button>

                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>{{
                          'product-specific-emissions.link-to-method'
                          | translate
                          }}</mat-label>
                        <input formControlName="LinkToMethod" matInput placeholder="{{
                                    'product-specific-emissions.link-to-method'
                                      | translate
                                  }}" />
                        <mat-error *ngIf="formService.form.get('LinkToMethod').hasError('maxlength')">
                          {{ ('error-handling.max-length-exceeded' | translate) +
                          formService.fieldLengths['LinkToMethod'] }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                  </div>
                </div>

                <div class="col-12 fs-16 mt-4 mb-3 fw-bold">
                  {{
                  'product-specific-emissions.created-modified'
                  | translate
                  }}
                </div>

                <div class="col-6">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>{{
                      'product-specific-emissions.creation-date'
                      | translate
                      }}</mat-label>
                    <input matInput [matDatepicker]="pickerCreationDate" formControlName="CreationDate" />
                    <mat-datepicker-toggle matSuffix [for]="pickerCreationDate">
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerCreationDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-6">
                  <mat-form-field appearance="fill">
                    <mat-label>{{
                      'product-specific-emissions.created-by'
                      | translate
                      }}</mat-label>
                    <input formControlName="CreatedBy" matInput placeholder="{{
                                'product-specific-emissions.created-by'
                                  | translate
                              }}" />
                  </mat-form-field>
                </div>

                <div class="col-6" *ngIf="editingMode">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>{{
                      'product-specific-emissions.modify-date'
                      | translate
                      }}</mat-label>
                    <input matInput [matDatepicker]="pickerModificationDate" formControlName="ModifyDate" placeholder="{{
                                'product-specific-emissions.modify-date'
                                  | translate
                              }}" />
                    <mat-datepicker-toggle matSuffix [for]="pickerModificationDate">
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerModificationDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-6" *ngIf="editingMode">
                  <mat-form-field appearance="fill">
                    <mat-label>{{
                      'product-specific-emissions.modify-by' | translate
                      }}</mat-label>
                    <input formControlName="ModifiedBy" matInput placeholder="{{
                                'product-specific-emissions.modify-by'
                                  | translate
                              }}" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div mat-dialog-actions [align]="'end'">
          <button mat-raised-button (click)="cancel()" [mat-dialog-close]="false">
            {{ 'product-specific-emissions.cancel' | translate }}
          </button>

          <button mat-raised-button color="primary" [disabled]="formService.form.invalid" (click)="submitForm()">
            {{ 'product-specific-emissions.save' | translate }}
          </button>
        </div>

      </div>
    </div>
  </div>
</form>