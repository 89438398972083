import { Injectable } from '@angular/core';
import * as forms from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@modules/shell/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { IFormService } from 'src/app/commons/models/form-service.interface';
import { BehaviorSubject } from 'rxjs';
import { DecimalValueService } from 'src/app/commons/services/decimal-value.service';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { MeasuringPointDialogComponent } from '@modules/customers-csrd/components/measuring-point-dialog/measuring-point-dialog.component';
import { MeasuringPoint } from 'src/app/commons/models/measuring-point.model';

@Injectable({
  providedIn: 'root'
})
export class FormService implements IFormService {
  public form!: forms.FormGroup;
  id: number;
  dialogRef: MatDialogRef<MeasuringPointDialogComponent>;
  isDataChanged: boolean = false;
  isDateChanged: boolean = false;
  editData: any;
  private initialFormValue: forms.ɵTypedOrUntyped<any, forms.ɵFormGroupValue<any>, any>;
  private initialDate: any;
  formInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public fb: forms.FormBuilder,
    public userService: UserService,
    private translateService: TranslateService,
    private decimalValueService: DecimalValueService,
    private languageService: CurrentLanguageService
  ) { }

  setEditData(editData) {
    this.editData = editData;
  }

  resetEditData() {
    this.editData = undefined;
  }

  resetForm() {
    this.form.reset();
  }

  setDialogRef(dialogRef) {
    this.dialogRef = dialogRef;
  }

  initializeForm(): void {
    this.form = this.fb.group({
      FiscalYearId: ['', forms.Validators.required],
      TechnicalName: ['', forms.Validators.required],
      Label: ['', forms.Validators.required],
      Value: [''],
      // CreationDate: [
      //   { value: new Date(), disabled: true },
      //   forms.Validators.required,
      // ],
      // CreatedBy: [
      //   { value: this.userService.currentUser.email, disabled: true },
      //   forms.Validators.required,
      // ],
      // ModifyDate: [{ value: new Date(), disabled: true }],
      // ModifiedBy: [
      //   { value: this.userService.currentUser.email, disabled: true },
      // ]
    });

    if (this.editData) {
      this.form.patchValue({
        // Id: this.editData.id,
        // FiscalYearId: this.editData.fiscalYearId,
        Description: this.editData.description,
        Label: this.editData.label,
        Value: this.editData.value,
        // CreationDate: this.editData.creationDate,
        // CreatedBy: this.editData.createdBy,
        // ModifyDate: this.editData.modifyDate,
        // ModifiedBy: this.userService.currentUser.email,
      });
    }

    this.initialFormValue = this.form.getRawValue();

    this.formInitialized.next(true);

    this.form.valueChanges.subscribe(() => {
      this.checkProductDataChanges();
    });
  }

  checkIfProductDataChanged() {
    if (this.isDataChanged) {
      return true;
    }
    return false;
  }

  checkProductDataChanges() {
    const isFiscalYearIdChanged = this.isFieldChanged('FiscalYearId');
    const isTechnicalNameChanged = this.isFieldChanged('TechnicalName');
    const isLabelChanged = this.isFieldChanged('Label');
    const isValueChanged = this.isFieldChanged('Value');

    this.isDataChanged = false;

    if (
      isFiscalYearIdChanged ||
      isTechnicalNameChanged ||
      isLabelChanged ||
      isValueChanged
    ) {
      this.isDataChanged = true;
    }
  }

  isFieldChanged(fieldName: string): boolean {
    const currentValue = this.form.get(fieldName).value;
    const initialValue = this.initialFormValue[fieldName];

    return currentValue !== initialValue;
  }

  prepareUpdateValues(id: number) {
    return {
      description: this.form.getRawValue().TechnicalName,
      label: this.form.getRawValue().Label,
      value: this.form.getRawValue().Value,
      id: this.editData?.id ?? id
    } as MeasuringPoint;
  }

  prepareCreateValues() {
    return {
      description: this.form.getRawValue().Description,
      label: this.form.getRawValue().Label,
      value: this.form.getRawValue().Value,
    } as MeasuringPoint;
  }

  resetProductDataChange() {
    this.isDataChanged = false;
    this.isDateChanged = false;
  }

  getUniqueKeyModel() {
    throw new Error('Method not implemented.');
  }

  onDateChange(event: any) {
    throw new Error('Method not implemented.');
  }
  disableProductData() {
    throw new Error('Method not implemented.');
  }
  getSelectedOverWriteStatus(status: any) {
    throw new Error('Method not implemented.');
  }
  getSelectedVerifiedStatus(status: any) {
    throw new Error('Method not implemented.');
  }
  getSelectedActiveStatus(status: any) {
    throw new Error('Method not implemented.');
  }
}

export function dateValidator(): forms.ValidatorFn {
  return (control: forms.AbstractControl): { [key: string]: any } | null => {
    if (!(control && control.value)) {
      return null;
    }

    const today = DateTime.fromJSDate(new Date());
    const dateSet = DateTime.fromJSDate(new Date(control.value));

    return dateSet > today
      ? { invalidDate: 'You cannot use future dates' }
      : null;
  }
}