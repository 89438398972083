import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTENAMES } from 'src/app/app-routing.names';

@Component({
    selector: 'app-pse-tab-view',
    templateUrl: './pse-tab-view.component.html',
    styleUrls: ['./pse-tab-view.component.scss'],
    standalone: false
})
export class PseTabViewComponent {
  title = 'angular-material-tab-router';
  navLinks: any[];
  activeLinkIndex = 2;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.navLinks = [
      {
        label: 'product-specific-emissions.default-tab',
        link: './',
        index: 0
      },
      {
        label: 'product-specific-emissions.tab-1',
        link: `./${ROUTENAMES.PSE_CAN_BE_USED}`,
        index: 1
      },
      {
        label: 'product-specific-emissions.tab-2',
        link: `./${ROUTENAMES.PSE_CANDIDATES}`,
        index: 2
      },
      {
        label: 'product-specific-emissions.tab-3',
        link: `./${ROUTENAMES.PSE_THE_REST}`,
        index: 3
      },
    ];
  }

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.find(tab => tab.link == './' + this.route.snapshot.firstChild.url[0]?.path)?.index ?? 0;
    });
  }
}
