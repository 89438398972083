<div>
  <button
    id="login-btn"
    mat-raised-button
    color="primary"
    aria-label="Log ind"
    matTooltip="Log ind"
    (click)="login()"
  >
    {{ 'navigation.login' | translate }}&nbsp;<mat-icon fontIcon="login" iconPositionEnd></mat-icon>
  </button>
</div>
