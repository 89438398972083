import { Injectable } from '@angular/core';
import { FilterOption } from '@dima/table-library';
import { ApiService } from '../../../services/api.service';
import { TableService } from '@modules/customers-own-data/services/table.service';
import { IFilterService } from 'src/app/commons/components/data-view/filter-service.interface';
import * as tableLibrary from '@dima/table-library';

@Injectable({
  providedIn: 'root',
})
export class FilterService implements IFilterService {
  public apiFilters = {
    TransactionDate: [],
    InvoiceNumber: [],
    BuyersEAN: [],
    SupplierDisplayValue: [],
    OrganizationID: [],
    UnspscDisplayValue: [],
    LineAmountWithoutVAT: [],
    Quantity: [],
    ProductID: [],
    ProductName: [],
    ProductDescription: [],
    Unit: [],
    CreationDate: [],
    CreatedBy: [],
    ModifyDate: [],
    ModifiedBy: [],
    FilenameFromUpload: [],
    GhgCategoryDisplayValue: [],
    SupplierEmissionCo2: []
  };

  constructor(
    private apiService: ApiService,
    private tableService: TableService
  ) { }

  async initializeFilters(): Promise<void> {
    const filters = await this.apiService.getCodFilters().toPromise();
    for (const [key, value] of Object.entries(filters)) {
      filters[key].map((el) => {
        this.apiFilters[key].push({
          value: el,
        });
      });

      let column = this.tableService.cols.find((col) => {
        let colName = `${key[0].toLowerCase() + key.slice(1)}`;
        return col.name == colName;
      });

      if (key == 'Verified') {
        column.options = [
          { value: 'true', checked: false },
          { value: 'false', checked: false },
        ] as FilterOption[];
      } else if (key == 'Active') {
        column.options = [
          { value: 'true', checked: false },
        ] as FilterOption[];
      } else if (key == 'Overwrite') {
        column.options = [
          { value: 'true', checked: false },
        ] as FilterOption[];
      } else {
        column.options = [...this.apiFilters[key]];
      }

      this.tableService.table?.applyFilter({
        column: column,
        tableId: 'table',
        text: '',
      });
    }
  }

  async initializeTranslations(): Promise<void> {
  }

  public setFilter(filter: tableLibrary.Filter): string {
    return filter.text;
  }

  resetFilters() {
    this.apiFilters = {
      TransactionDate: [],
      InvoiceNumber: [],
      BuyersEAN: [],
      SupplierDisplayValue: [],
      OrganizationID: [],
      UnspscDisplayValue: [],
      LineAmountWithoutVAT: [],
      Quantity: [],
      ProductID: [],
      ProductName: [],
      ProductDescription: [],
      Unit: [],
      CreationDate: [],
      CreatedBy: [],
      ModifyDate: [],
      ModifiedBy: [],
      FilenameFromUpload: [],
      GhgCategoryDisplayValue: [],
      SupplierEmissionCo2: []
    };
  }
}
