<div class="dialog">
    <h1 class="dialog__header">
        <mat-icon fontIcon="error_outline"></mat-icon>{{ data.title || 'Der er sket en fejl!'}}
    </h1>
    <div class="dialog__content">
        <table>
            <tr *ngIf="data.httpStatusCode">
                <td>Statuskode:</td>
                <td>{{ data.httpStatusCode }}</td>
            </tr>
            <tr *ngIf="data.correlationId">
                <td>Korrelation id:</td>
                <td>{{ data.correlationId }}</td>
            </tr>
            <tr *ngIf="data.url">
                <td>Url:</td>
                <td>{{ data.url }}</td>
            </tr>
            <tr *ngIf="data.responseTimeInMS">
                <td>Tid:</td>
                <td>{{ data.responseTimeInMS }} ms.</td>
            </tr>
            <tr *ngIf="data.messages && data.messages.length >0">
                <td>Besked:</td>
                <td>
                    <ul>
                        <li [innerHTML]="message" *ngFor="let message of data.messages; index as i; first as isFirst">
                        </li>
                    </ul>
                </td>
            </tr>
        </table>
    </div>
</div>