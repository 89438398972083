import { ChangeDetectorRef, Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
    standalone: false
})
export class SupportComponent {
  safeHtmlContent: SafeHtml;

  constructor(public translate: TranslateService,
    private sanitizer: DomSanitizer, private router: Router, private currentLanguageService: CurrentLanguageService, private cdk: ChangeDetectorRef) {
    const translated = translate.instant('support.mainText');
    this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(translated.replace('{supportEmail}', environment.supportEmail));
  }

  ngOnInit(): void {
    this.currentLanguageService.languageChanges.subscribe(() => {
      setTimeout(() => {
        const translated = this.translate.instant('support.mainText');
        this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(translated.replace('{supportEmail}', environment.supportEmail));
        console.log('safeHtmlContent', this.safeHtmlContent);
      }, 1000)
    });
  }

  navigate(event: Event) {
    const target = event.target as HTMLAnchorElement;
    if (target.tagName === 'A' && target.getAttribute('href')) {
      event.preventDefault();
      this.router.navigate([target.getAttribute('href')]);
    }
  }
}