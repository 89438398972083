import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import {
  CorrelationId,
  ResponseTimeInMs,
} from '../constants/http-header.constants';
import { Router } from '@angular/router';
import { ROUTENAMES } from '../app-routing.names';
import { InformationDialogService } from '@modules/shell/services/information-dialog.service';
import { ErrorService } from '../services/error.service';
import { TranslateService } from '@ngx-translate/core';

export const BYPASS_REDIRECT = new HttpContextToken(() => false);

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private informationService: InformationDialogService,
    private errorService: ErrorService,
    private translateService: TranslateService
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let ok: any;

    const noCacheReq = request.clone({
      setHeaders: {
        'Cache-Control': 'no-store',
        'Pragma': 'no-cache'
      }
    });

    if (noCacheReq.context.get(BYPASS_REDIRECT) === true) {
      return next.handle(noCacheReq).pipe(
        tap(
          (event) => (ok = event instanceof HttpResponse ? 1 : 0),
          (error: HttpErrorResponse) => {
            this.errorService.setErrorId(error);
          }
        )
      );
    }
    return next.handle(noCacheReq).pipe(
      tap(
        (event) => (ok = event instanceof HttpResponse ? 1 : 0),
        (error: HttpErrorResponse) => {
          console.log('Error id: ', error.headers.get(CorrelationId));
          switch (error.status) {
            case 400:
              this.informationService.showErrorMessage({
                title: this.translateService.instant(
                  'error-handling.validation'
                ),
                httpStatusCode: error.status,
                messages: Object.keys(error?.error?.errors ?? error?.error),
                correlationId: error.headers.get(CorrelationId),
                responseTimeInMS: error.headers.get(ResponseTimeInMs),
              });
              break;
            case 401:
              this.errorService.showError(ROUTENAMES.UNAUTHORIZED, error);
              break;
            case 403:
              this.errorService.showError(ROUTENAMES.FORBIDDEN, error);
              break;
            case 404:
              this.errorService.showError(ROUTENAMES.NOTFOUND, error);
              break;
            default:
              this.errorService.showError(ROUTENAMES.ERROR, error);
              break;
          }
        }
      )
    );
  }
}
