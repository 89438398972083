import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { DimaTableLibraryModule } from '@dima/table-library';
import { PseActionMenuComponent } from './components/pse-action-menu/pse-action-menu.component';
import { PseTabViewComponent } from './pse-tab-view/pse-tab-view.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppRoutingModule } from 'src/app/app-routing.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    DimaTableLibraryModule,
    MatTabsModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatIconModule,
    AppRoutingModule
  ],
  declarations: [
    PseActionMenuComponent,
    PseTabViewComponent
  ]
})
export class ProductSpecificEmissionsModule { }
