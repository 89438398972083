import { Component } from '@angular/core';
import { IdleService } from '@modules/auth/services/idle.service';
import { OpenIDCService } from '@modules/auth/services/open-idc.service';
import { SidebarService } from '@modules/shell/services/sidebar.service';
import { UserService } from '@modules/shell/services/user.service';
import { TranslateHelperService } from './services/translate-helper.service';
import { CurrentLanguageService } from './services/current-language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private userService: UserService,
    private sidebarService: SidebarService,
    private openIDCService: OpenIDCService,
    private IdleService: IdleService, // Needs to be referenced, else it wont start
    private translateHelperService: TranslateHelperService,
    private currentLanguageService: CurrentLanguageService
  ) {
    this.openIDCService.isAuthenticated$.subscribe((result) => {
    });
  }
}
