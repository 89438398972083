﻿<button mat-button [matMenuTriggerFor]="menuReport" class="fs-16 fw-bold dropdown-menu ps-3 pe-4">
  {{ 'reports.user-reports' | translate }}
  <mat-icon fontIcon="arrow_drop_down" class="arrow-drop-down"></mat-icon>
</button>

<mat-menu #menuReport="matMenu">
  <ng-container *ngFor="let item of (reportService.userReports$ | async) | orderBy:'description'">
    <button mat-menu-item (click)="triggerReportChange(item)">
      {{ item.description }}
    </button>
  </ng-container>
</mat-menu>