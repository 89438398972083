import { APP_INITIALIZER, NgModule, LOCALE_ID } from '@angular/core';
import localeDa from '@angular/common/locales/da';
import localeEn from '@angular/common/locales/en';
import localeNo from '@angular/common/locales/no';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
registerLocaleData(localeDa);
registerLocaleData(localeEn);
registerLocaleData(localeNo);

import { BrowserModule } from '@angular/platform-browser';

import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpSpinnerInterceptor } from './interceptores/http-spinner.interceptor';
import { HttpRequestBearerInterceptor } from './interceptores/http-request-bearer.interceptor';
import { HttpResponseInterceptor } from './interceptores/http-response.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './modules/auth/auth.module';
import { ShellModule } from './modules/shell/shell.module';
import { ReportModule } from '@modules/report/report.module';
import { HomeModule } from '@modules/home/home.module';
import { SystemService } from './services/system.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InitiativesModule } from '@modules/initiatives/initiatives.module';
import { ScenariosModule } from '@modules/scenarios/scenarios.module';
import { DialogComponent } from './commons/dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProductSpecificEmissionsModule } from '@modules/product-specific-emissions/product-specific-emissions.module';
import { UserGuideModule } from '@modules/user-guide/user-guide.module';
import { VerifiedStatusPipe } from './commons/pipes/verified-status-pipe.pipe';
import { OverwrittenStatusPipe } from './commons/pipes/overwritten-status-pipe.pipe';
import { ActivatedStatusPipe } from './commons/pipes/activated-status-pipe.pipe';
import { MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { CustomersOwnDataModule } from '@modules/customers-own-data/customers-own-data.module';
import { CustomersAveragePriceModule } from '@modules/customers-average-price/customers-average-price.module';
import { DimaTableLibraryModule } from '@dima/table-library';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CreateEditDialogFactory } from './commons/components/create-edit-dialog/create-edit-dialog-factory';
import { CreateEditDialogModule } from './commons/components/create-edit-dialog/create-edit-dialog.module';
import { DataViewModule } from './commons/components/data-view/data-view.module';
import { CsrdModule } from '@modules/customers-csrd/csrd.module';
import { FaqModule } from './commons/components/faq/faq.module';
import { SupportComponent } from './modules/support/support.component';

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({ declarations: [
        AppComponent,
        DialogComponent,
        SupportComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
        AuthModule,
        ShellModule,
        HomeModule,
        ReportModule,
        ScenariosModule,
        InitiativesModule,
        ProductSpecificEmissionsModule,
        CustomersOwnDataModule,
        CustomersAveragePriceModule,
        UserGuideModule,
        DimaTableLibraryModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatToolbarModule,
        ReactiveFormsModule,
        TranslateModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatOptionModule,
        MatSelectModule,
        CreateEditDialogModule,
        DataViewModule,
        CsrdModule,
        FaqModule], providers: [
        { provide: LOCALE_ID, useValue: 'da-DK' },
        { provide: MAT_DATE_LOCALE, useValue: 'dk' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpResponseInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpSpinnerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestBearerInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (systemService: SystemService) => () => systemService.init(),
            deps: [SystemService],
            multi: true,
        },
        VerifiedStatusPipe,
        OverwrittenStatusPipe,
        ActivatedStatusPipe,
        DecimalPipe,
        CreateEditDialogFactory,
        DatePipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
