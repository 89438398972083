import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { EntityTrackerError } from '../commons/models/entity-tracker-error';
import { catchError } from 'rxjs/operators';
import { ROUTENAMES } from '../app-routing.names';
import { PageResponse } from '../commons/models/page-response';
import { CustomersAveragePriceGetService } from '@modules/customers-average-price/services/customers-average-price.service';
import { LocalStorageService } from '../services/local-storage.service';
import { MainDataViewType } from '../commons/components/create-edit-dialog/main-data-view-type.enum';

@Injectable({
  providedIn: 'root',
})
export class CustomersAveragePriceResolver {
  constructor(
    private customersAveragePriceGetService: CustomersAveragePriceGetService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PageResponse | EntityTrackerError | boolean> {

    return this.customersAveragePriceGetService
      .get({
        search: 1,
        filters: null,
        pageSize: this.localStorageService.getItem(`${MainDataViewType.CUSTOMERS_AVERAGE_PRICE.toString()}-pageSize`)?.value ?? '10',
        // TODO: do a dynamic sorting when it will be available in the Navigation event
        // orderBy: `order by ${navigation.sortColumn} ${navigation.sortDirection}`,
        orderBy: `order by Supplier.SupplierName asc, Unspsc asc, Unit asc`,
        page: '0',
      })
      .pipe(
        catchError(() => {
          this.router.navigate([ROUTENAMES.UNAUTHORIZED]);
          return EMPTY;
        })
      );
  }
}
