import { Injectable } from '@angular/core';
import { Bookmark } from '../../../commons/models/bookmark-model';
import { DateTime } from 'luxon';
import { UserService } from '@modules/shell/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { PbiReportService } from '@modules/report/services/pbi-report.service';
import { bookmarkNameValidator } from '../../../commons/validators/bookmark-name.validator';
import { ApiService } from '../../../services/api.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PbiBookmarkService {
  bookmark: Bookmark;
  bookmarkForm!: FormGroup;
  bookmarks: Bookmark[];
  pbiState: string;
  paramReportId: any;
  paramWorkspaceId: any;
  queryParamsSubscription: Subscription;
  constructor(
    private userService: UserService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private tenantSelectService: TenantSelectService,
    private reportService: PbiReportService,
    private apiService: ApiService
  ) {
    this.initializeForm();
    this.setCurrentBookmark();
  }

  initializeForm() {
    this.bookmarkForm = this.fb.group({
      bookmarkName: [
        '',
        {
          validators: [Validators.required, Validators.maxLength(20)],
          updateOn: 'change',
        },
      ],
    });
  }

  resetForm() {
    this.bookmarkForm = this.fb.group({
      bookmarkName: [
        '',
        {
          validators: [Validators.required, Validators.maxLength(100)],
          updateOn: 'change',
        },
      ],
    });
  }

  getBookmarks() {
    this.apiService
      .getBookmarks(this.userService.currentUser.email)
      .subscribe((res) => {
        this.bookmarks = res as Bookmark[];
      });
  }

  deleteBookmark(id: number) {
    return this.apiService
      .deleteBookmark(id);
  }

  updateBookmark(bookmark: Bookmark) {
    return this.apiService
      .updateBookmark(bookmark);
  }

  async getPbiState(report) {
    let pbiState;
    await report.bookmarksManager.capture().then((bookmark) => {
      pbiState = bookmark.state;
      this.reportService.currentPbiState = bookmark.state;
    });
    return pbiState;
  }

  setCurrentBookmark() {
    let urlWithState = this.getPbiConfig(
      this.reportService.currentPbiState,
      this.tenantSelectService.idp
    );

    this.bookmark = {
      title: this.bookmarkForm.get('bookmarkName')?.value || '',
      path: urlWithState,
      queryParams: '',
      favorite: true,
      userId: this.userService.currentUser.email,
      creationDate: DateTime.now(),
      createdBy: this.userService.currentUser.email,
      modifyDate: DateTime.now(),
      modifiedBy: this.userService.currentUser.email,
    };
  }

  getPbiConfig(pbiState: string, idp: string) {
    let encodedPbiState = encodeURIComponent(pbiState);
    let currentUrl = this.getUrl().join('/');
    let reportId = this.reportService.pbiReportId;
    let workspaceId = this.reportService.workspaceId;

    let urlWithState =
      currentUrl +
      '/dashboard?reportId=' +
      reportId +
      '&workspaceId=' +
      workspaceId +
      '&pbiState=' +
      encodedPbiState;

    if (idp) {
      urlWithState = urlWithState + '&idp=' + idp;
    }

    return urlWithState;
  }

  initializeQueryParamsSubscription() {
    this.queryParamsSubscription = this.route.queryParams.subscribe((param) => {
      if (param['pbiState']) {
        this.pbiState = param['pbiState'];
      }

      if (param['reportId']) {
        this.paramReportId = param['reportId'];
      }

      if (param['workspaceId']) {
        this.paramWorkspaceId = param['workspaceId'];
      }
    });
  }

  private getUrl() {
    return this.route.snapshot.url;
  }
}
