import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Subscription } from 'rxjs';
import { PbiReportService } from '@modules/report/services/pbi-report.service';
import { TenantSelectService } from '@modules/shell/components/tenant-select/tenant-select.service';
import { DialogService } from '../../../../services/dialog.service';
import { ApiService } from '../../../../services/api.service';
import { PbiBookmarkService } from '@modules/report/services/pbi-bookmark.service';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { UserService } from '@modules/shell/services/user.service';
import { UserActivity, ActivityEventType } from 'src/app/commons/models/activity.model';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
    selector: 'app-report-wrapper',
    templateUrl: './report-wrapper.component.html',
    styleUrls: ['./report-wrapper.component.scss'],
    standalone: false
})
export class ReportWrapperComponent implements OnInit, OnDestroy {
  initializedSubscription: Subscription;
  routeSubscription: Subscription;
  enableActionButtons = true;

  @Input() reportId: string;
  showReport: boolean = false;
  constructor(
    private route: ActivatedRoute,
    public reportService: PbiReportService,
    private tenantSelectService: TenantSelectService,
    private dialogService: DialogService,
    private apiService: ApiService,
    private bookmarkService: PbiBookmarkService,
    private cdr: ChangeDetectorRef,
    private currentLanguageService: CurrentLanguageService,
    private activityService: ActivityService,
    private userService: UserService
  ) { }

  async ngOnInit(): Promise<void> {
    this.bookmarkService.getBookmarks();
    await this.tenantSelectService.selectIdpUrlParam();

    this.reportService.getReport(this.currentLanguageService.lang);

    this.reportService.getReport(this.currentLanguageService.lang);

    this.routeSubscription = this.route.data.subscribe((data) => {
      this.reportService.refreshToken(this.currentLanguageService.lang, true);
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  openPdfOrderDialog() {
    this.dialogService
      .pdfOrderListDialog()
      .afterClosed()
      .subscribe((res) => { });
  }

  showFilters() {
    this.dialogService
      .openPbiFilterDialog()
      .afterClosed()
      .subscribe((res) => { });
  }

  async resetFilters() {
    this.enableActionButtons = false;
    await this.reportService.resetFilters().then(x => {
      this.enableActionButtons = true;
    });
  }

  createBookmark() {
    this.dialogService
      .createBookmarkDialog()
      .afterClosed()
      .subscribe((res) => {
        if (res == 'save') {
          this.bookmarkService.setCurrentBookmark();
          this.apiService
            .addBookmark(this.bookmarkService.bookmark)
            .subscribe(() => {
              this.activityService.sendActivity(new UserActivity(`Bookmark '${this.bookmarkService.bookmark.title.toUpperCase()}' - added`, this.userService.currentUser.email ?? this.userService.currentUser.username, ActivityEventType.BookmarkAdded));
              this.bookmarkService.getBookmarks();
              this.cdr.detectChanges();
            });
        }
      });
  }

  private loadReport(data: Data) {
    this.reportService.loadReport(data.reports.embedTokenInfo, this.currentLanguageService.lang);
  }
}
