import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from '@modules/shell/services/user.service';
import { SidebarService } from '@modules/shell/services/sidebar.service';
import { SystemService } from 'src/app/services/system.service';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '@modules/shell/services/spinner.service';
import { UserInfo } from '../../../../commons/models/user-info';
import { AdminService } from '../../../../services/admin.service';
import { CurrentLanguageService } from '../../../../services/current-language.service';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
    standalone: false
})
export class TopBarComponent implements OnInit, OnDestroy {
  title: string = '';
  public user: UserInfo;
  isSidebarOpen: boolean = false;

  constructor(
    private titleService: Title,
    public userService: UserService,
    private sidebarService: SidebarService,
    private systemService: SystemService,
    public translate: TranslateService,
    private spinnerService: SpinnerService,
    public adminService: AdminService,
    public currentLanguageService: CurrentLanguageService,
    private cdr: ChangeDetectorRef
  ) {
    this.systemService.systemInfo$.subscribe((systemInfo) => {
      this.title = systemInfo.title;
      this.titleService.setTitle(this.title);
    });

    this.userService.user$.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    this.sidebarService.isSidebarOpen$.subscribe((sidebarState) => {
      this.isSidebarOpen = sidebarState;
    });
  }

  ngOnDestroy(): void {
    this.adminService.isCrossCustomerViewer = false;
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.sidebarService.setSidebarState(this.isSidebarOpen);
  }
}
