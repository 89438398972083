import { Injectable } from '@angular/core';
import {
  Columns,
  Filter,
  FilterTypes,
  ObjectTypes,
  TranslationKeys,
} from '@dima/table-library';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { VerifiedStatusPipe } from '../../../../commons/pipes/verified-status-pipe.pipe';
import { DateTime } from 'luxon';
import { ITableService } from 'src/app/commons/components/data-view/table-service.interface';
import { PageModel } from 'src/app/commons/models/page-model';
import { CustomDecimalPipe } from 'src/app/commons/pipes/custom-decimal.pipe';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DataViewType } from 'src/app/commons/components/create-edit-dialog/data-view-type.enum';

@Injectable({
  providedIn: 'root',
})
export class TableService implements ITableService {
  public cols: Columns[];
  public totalCount: number;
  public pageSize: number = 10;
  public page: number = 0;
  public data = [];
  public langSubscription: Subscription;
  public loading: boolean = false;
  public table: any;
  public selectedRows: any[];
  public dimaTableTranslations: any;
  public pagedModel: PageModel;
  filters: Filter[] = [];

  clearTableSelection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  anyRowSelectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private currentLanguageService: CurrentLanguageService,
    private statusPipe: VerifiedStatusPipe,
    private localStorageService: LocalStorageService
  ) {


    this.langSubscription = this.translateService.onLangChange.subscribe(
      (lang) => {
        this.setCols();
        this.router.navigate([this.getCurrentRoute()]);
      }
    );
  }

  setCols() {
    let visibleColumns: string[];
    const savedColumnsToDisplay = this.localStorageService.getItem(DataViewType.CUSTOMERS_OWN_DATA.toString())?.value;
    if (savedColumnsToDisplay) {
      visibleColumns = JSON.parse(savedColumnsToDisplay) as string[];
    }

    this.dimaTableTranslations = [
      {
        Key: TranslationKeys.globalFilterHeader,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.globalFilterPlaceholder,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.paginatorItemsPerPageLabel,
        Label: this.translateService.instant(
          'general.paginator.per-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.per-page'),
      },
      {
        Key: TranslationKeys.paginatorRangeLabel,
        Label: this.translateService.instant('general.paginator.of'),
        Tooltip: this.translateService.instant('general.paginator.of'),
      },
      {
        Key: TranslationKeys.paginatorFirstPageLabel,
        Label: this.translateService.instant(
          'general.paginator.first-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.first-page'),
      },
      {
        Key: TranslationKeys.paginatorLastPageLabel,
        Label: this.translateService.instant(
          'general.paginator.last-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.last-page'),
      },
      {
        Key: TranslationKeys.paginatorPreviousPageLabel,
        Label: this.translateService.instant(
          'general.paginator.prev-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.prev-page'),
      },
      {
        Key: TranslationKeys.paginatorNextPageLabel,
        Label: this.translateService.instant(
          'general.paginator.next-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.next-page'),
      },
      {
        Key: TranslationKeys.globalFilterPlaceholder,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.columnControlHeader,
        Label: this.translateService.instant(
          'general.paginator.columns'
        ),
        Tooltip: this.translateService.instant('general.paginator.columns'),
      },
      {
        Key: TranslationKeys.saveButton,
        Label: this.translateService.instant('general.paginator.save'),
        Tooltip: this.translateService.instant('general.paginator.save'),
      },
      {
        Key: TranslationKeys.cancelButton,
        Label: this.translateService.instant(
          'general.paginator.cancel'
        ),
        Tooltip: this.translateService.instant('general.paginator.cancel'),
      },
      {
        Key: TranslationKeys.tableEmptyStateMessage,
        Label: this.translateService.instant(
          'general.paginator.no-data'
        ),
        Tooltip: this.translateService.instant('general.paginator.no-data'),
      },
    ];

    const precission0Pipe = new CustomDecimalPipe(this.currentLanguageService);
    precission0Pipe.decimalPlaces = 0;

    this.cols = [
      {
        id: 0,
        columnsOrder: 0,
        type: ObjectTypes.rowSelect,
        name: 'selection',
        filterType: FilterTypes.rowSelect,
        showInColumnControl: false,
        visible: true,
        stickyLeft: true
      },
      {
        id: 1,
        columnsOrder: 1,
        type: ObjectTypes.date,
        name: 'startDate',
        title: this.translateService.instant('fiscal-year.start-date'),
        filterType: FilterTypes.date,
        filterFn: (value: any, data: Date) => {
          if (!value.date || DateTime.fromJSDate(new Date(value.date)).toLocaleString()
            == DateTime.fromJSDate(new Date(data.toString())).toLocaleString()) {
            return true;
          }
          return false;
        },
        visible: visibleColumns?.includes('startDate') ?? true
      },
      {
        id: 2,
        columnsOrder: 2,
        type: ObjectTypes.date,
        name: 'endDate',
        title: this.translateService.instant('fiscal-year.end-date'),
        filterType: FilterTypes.date,
        visible: visibleColumns?.includes('endDate') ?? true
      },
      {
        id: 3,
        columnsOrder: 3,
        type: ObjectTypes.string,
        name: 'description',
        title: this.translateService.instant('fiscal-year.description'),
        filterType: FilterTypes.input,
        visible: visibleColumns?.includes('description') ?? true
      },
      {
        id: 4,
        columnsOrder: 4,
        type: ObjectTypes.boolean,
        name: 'defaultYear',
        title: this.translateService.instant('fiscal-year.default-year'),
        filterType: FilterTypes.checks,
        visible: visibleColumns?.includes('defaultYear') ?? true
      },
      // {
      //   id: 5,
      //   columnsOrder: 5,
      //   type: ObjectTypes.date,
      //   name: 'creationDate',
      //   title: this.translateService.instant('fiscal-year.creation-date'),
      //   filterType: FilterTypes.date,
      //   visible: visibleColumns?.includes('creationDate') ?? true
      // },
      // {
      //   id: 6,
      //   columnsOrder: 6,
      //   type: ObjectTypes.string,
      //   name: 'createdBy',
      //   title: this.translateService.instant('fiscal-year.created-by'),
      //   filterType: FilterTypes.input,
      //   visible: visibleColumns?.includes('createdBy') ?? true
      // },
      // {
      //   id: 7,
      //   columnsOrder: 7,
      //   type: ObjectTypes.date,
      //   name: 'modifyDate',
      //   title: this.translateService.instant('fiscal-year.modify-date'),
      //   filterType: FilterTypes.date,
      //   visible: visibleColumns?.includes('modifyDate') ?? true
      // },
      // {
      //   id: 8,
      //   columnsOrder: 8,
      //   type: ObjectTypes.string,
      //   name: 'modifiedBy',
      //   title: this.translateService.instant('fiscal-year.modified-by'),
      //   filterType: FilterTypes.input,
      //   visible: visibleColumns?.includes('modifiedBy') ?? true
      // },
      {
        id: 5,
        columnsOrder: 5,
        type: ObjectTypes.custom,
        name: 'edit',
        filterType: FilterTypes.custom,
        showInColumnControl: false,
        stickyRight: true,
        visible: true
      },
      {
        id: 6,
        columnsOrder: 6,
        type: ObjectTypes.custom,
        name: 'delete',
        filterType: FilterTypes.custom,
        showInColumnControl: false,
        stickyRight: true,
        visible: true
      }
    ];
  }

  setData(data) {
    if (data) {
      this.pageSize = Number(data._rowsPerPage);
      this.page = Number(data._PageNumber);
      this.totalCount = Number(data.totalFilteredProducts);
      this.data = data.pagedProductsDaos;
    }
  }

  getCurrentRoute() {
    let urlWithoutParams = this.router.url.split('?')[0];
    return urlWithoutParams;
  }
}
