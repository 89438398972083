import { Injectable } from '@angular/core';
import { HistoricalDataDto } from '../../../../commons/models/historical-data-dto';
import { GraphXYDTO } from '../../../../commons/models/graph-xy-dto';
import { DeepCloneService } from '../../../../commons/services/deep-clone.service';
import { ICalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.interface';
import { Unspsc } from 'src/app/commons/models/unspsc';
import { CalculatedGraphData, GraphData } from 'src/app/commons/models/calculated-graph.data';
import { UnitMethod } from 'src/app/commons/models/unit-method.model';
import { BehaviorSubject, Subject } from 'rxjs';

export interface EmissionDto extends GraphXYDTO { }
export interface ExpenseDto extends GraphXYDTO { }
export interface EmissionFactorDto extends GraphXYDTO { }
export interface ChangeTableDto extends GraphXYDTO { }

@Injectable({
  providedIn: 'root',
})
export class CalculatorHelperService
  implements ICalculatorHelperService {
  historicalPointsXYEmissionFactorData: GraphXYDTO[];

  expenseAggregateChangeView: any[];
  emissionAggregateChangeView: any[];
  quantityAggregateChangeView: any[];
  arrayOfForecastedYears: any[];
  emissionPanelOpenState: boolean = true;
  expensePanelOpenState: boolean = true;
  quantityPanelOpenState: boolean = true;
  firstYear: number;

  unitMethodDropdownReloaded: Subject<UnitMethod[][]>;

  constructor(public deepCloneService: DeepCloneService) {
    this.unitMethodDropdownReloaded = new Subject<UnitMethod[][]>();
  }

  mapHistoricalDataToXYEmissionFactor(
    data: HistoricalDataDto[]
  ): EmissionFactorDto[] {
    data.sort((a, b) => a.year - b.year);

    return data.map((el, index) => {
      let emissionFactor;

      if (el.amount == 0) {
        emissionFactor = {
          x: el.year,
          y: 0,
        };
      } else {
        emissionFactor = {
          x: el.year,
          y: Number(el.cO2EmissionKG) / Number(el.amount),
        };
      }

      return emissionFactor;
    });
  }

  mapHistoricalDataToXY(data: HistoricalDataDto[], fieldName: string): ExpenseDto[] {
    data.sort((a, b) => a.year - b.year);

    return data.map((el, index) => {
      return { x: el.year, y: Number(el[fieldName]) };
    });
  }

  mapHistoricalDataToXYEmission(data: HistoricalDataDto[]): EmissionDto[] {
    data.sort((a, b) => a.year - b.year);

    return data.map((el, index) => {
      return { x: el.year, y: Number(el.cO2EmissionKG) };
    });
  }

  calculateChange(
    trendLineData,
    simulationData,
    inputData,
    typeEnum
  ): ChangeTableDto[] {
    let changeTable: { x: number; y: number }[] = [];
    for (let i = inputData.length; i < trendLineData.length; i++) {
      changeTable.push({
        x: trendLineData[i].x,
        y: (simulationData[i]?.y ?? 0) - trendLineData[i].y,
      });
    }

    if (typeEnum == 'EXPENSE') {
      this.expenseAggregateChangeView =
        this.deepCloneService.deepClone(changeTable);
    }

    if (typeEnum == 'EMISSION') {
      this.emissionAggregateChangeView =
        this.deepCloneService.deepClone(changeTable);
    }

    if (typeEnum == 'QUANTITY') {
      this.quantityAggregateChangeView =
        this.deepCloneService.deepClone(changeTable);
    }

    return changeTable;
  }

  aggregateGraphDto(arrayOfGraphData): CalculatedGraphData {
    let years = this.getCalculationYears(arrayOfGraphData);

    let expenseHistoricalPoints,
      expenseTrendPoints,
      expenseSimulationPoints,
      emissionHistoricalPoints,
      emissionTrendPoints,
      emissionSimulationPoints,
      quantityHistoricalPoints,
      quantityTrendPoints,
      quantitySimulationPoints,
      expenseChange,
      emissionChange,
      quantityChange;

    expenseHistoricalPoints = this.aggregateData(
      years.arrayOfHistoricalYears,
      arrayOfGraphData,
      'expenseHistoricalPoints'
    );
    expenseTrendPoints = this.aggregateData(
      years.arrayOfYears,
      arrayOfGraphData,
      'expenseTrendPoints'
    );
    expenseSimulationPoints = this.aggregateData(
      years.arrayOfForecastedYears,
      arrayOfGraphData,
      'expenseSimulationPoints'
    );
    emissionHistoricalPoints = this.aggregateData(
      years.arrayOfHistoricalYears,
      arrayOfGraphData,
      'emissionHistoricalPoints'
    );
    emissionTrendPoints = this.aggregateData(
      years.arrayOfYears,
      arrayOfGraphData,
      'emissionTrendPoints'
    );
    emissionSimulationPoints = this.aggregateData(
      years.arrayOfForecastedYears,
      arrayOfGraphData,
      'emissionSimulationPoints'
    );
    quantityHistoricalPoints = this.aggregateData(
      years.arrayOfHistoricalYears,
      arrayOfGraphData,
      'quantityHistoricalPoints'
    );
    quantityTrendPoints = this.aggregateData(
      years.arrayOfYears,
      arrayOfGraphData,
      'quantityTrendPoints'
    );
    quantitySimulationPoints = this.aggregateData(
      years.arrayOfForecastedYears,
      arrayOfGraphData,
      'quantitySimulationPoints'
    );
    expenseChange = this.aggregateData(
      years.arrayOfForecastedYears,
      arrayOfGraphData,
      'expenseChange'
    );
    emissionChange = this.aggregateData(
      years.arrayOfForecastedYears,
      arrayOfGraphData,
      'emissionChange'
    );
    quantityChange = this.aggregateData(
      years.arrayOfForecastedYears,
      arrayOfGraphData,
      'quantityChange'
    );

    return {
      expense: {
        historicalPoints: expenseHistoricalPoints,
        trendPoints: expenseTrendPoints,
        simulationPoints: expenseSimulationPoints,
        change: expenseChange
      } as GraphData,
      emission: {
        historicalPoints: emissionHistoricalPoints,
        factorTrendPoints: [],
        trendPoints: emissionTrendPoints,
        simulationPoints: emissionSimulationPoints,
        change: emissionChange
      } as GraphData,
      quantity: {
        change: quantityChange,
        historicalPoints: quantityHistoricalPoints,
        simulationPoints: quantitySimulationPoints,
        trendPoints: quantityTrendPoints
      } as GraphData
    };
  }

  aggregateData(years, arrayOfGraphData, name) {
    let dataAggregated = [];
    for (let i = 0; i < years.length; i++) {
      let aggregatedExpense = 0;
      arrayOfGraphData.forEach((el) => {
        let filtered =
          el[name].filter((nestedEl) => {
            return nestedEl.x == years[i];
          })[0]?.y || 0;
        aggregatedExpense += filtered;
      });

      let aggregatedPoint = {
        x: years[i],
        y: aggregatedExpense,
      };

      dataAggregated.push(aggregatedPoint);
    }

    return dataAggregated;
  }

  sumChangeData(changeArray: GraphXYDTO[]): number {
    let sum = 0;

    if (changeArray instanceof Array && changeArray.length !== 0) {
      changeArray.map((el, index) => {
        sum = sum + el.y;
      });
    }

    return sum;
  }

  getNestedYears(yearlyExpenseChangesByUnspsc) {
    let tempYearsArray = [];
    yearlyExpenseChangesByUnspsc.map((singleUnspscArray) => {
      singleUnspscArray.map((nestedEl) => {
        tempYearsArray.push(nestedEl.x);
      });
    });
    return [...new Set(tempYearsArray)];
  }

  getYearsOutOfGraphXYDto(array: GraphXYDTO[]): number[] {
    let tempArray = [];
    array.map((el) => tempArray.push(el.x));
    return tempArray;
  }

  getYearsOutOfHistoricalDataDto(array: HistoricalDataDto[]): number[] {
    let tempArray = [];
    array.map((el) => tempArray.push(el.year));
    return tempArray;
  }

  getCalculationYears(arrayOfGraphData) {
    let arrayOfHistoricalYears = [];
    let arrayOfForecastedYears = [];

    arrayOfGraphData.forEach((el) => {
      arrayOfHistoricalYears.push(
        ...this.getYearsOutOfGraphXYDto(el.expenseHistoricalPoints)
      );
      arrayOfForecastedYears.push(
        ...this.getYearsOutOfGraphXYDto(el.expenseSimulationPoints)
      );
    });

    arrayOfHistoricalYears = [...new Set(arrayOfHistoricalYears)];

    arrayOfForecastedYears = [...new Set(arrayOfForecastedYears)];

    let cleanArrayOfForecastedYears = [];
    arrayOfHistoricalYears.forEach((el) => {
      cleanArrayOfForecastedYears = arrayOfForecastedYears.filter(
        (simEl) => simEl != el
      );
      arrayOfForecastedYears = cleanArrayOfForecastedYears;
    });

    let arrayOfYears = [...arrayOfHistoricalYears, ...arrayOfForecastedYears];
    this.arrayOfForecastedYears = arrayOfForecastedYears;

    return {
      arrayOfHistoricalYears,
      arrayOfForecastedYears,
      arrayOfYears,
    };
  }

  getHistoricalYears(): number[] {
    let firstYear = this.firstYear;
    let lastYear = new Date().getFullYear();
    let listOfYears = [];

    for (let i = firstYear; i < lastYear; i++) {
      listOfYears.push(i);
    }
    return listOfYears;
  }

  resetYears() {
    this.arrayOfForecastedYears = undefined;
  }

  getEmptyHistoricalDataDto(year, unspsc, isNull = true) {
    return {
      year: year,
      unspscClassId: unspsc,
      unspscClassDescription: null,
      amount: isNull ? null : 0,
      cO2EmissionKG: isNull ? null : 0,
    };
  }

  getUnspsc(historicalDataForOneUnspsc: HistoricalDataDto[]): string {
    let unspsc;
    historicalDataForOneUnspsc.forEach((el) => {
      if (el.unspscClassId != null) {
        unspsc = el.unspscClassId;
      }
    });
    return unspsc;
  }

  getUnspscsFormValues(formValues: any, selectedUnspscs: Unspsc[][], fieldName: string): any {
    formValues[fieldName] = [].concat(...selectedUnspscs.map(x => x.map(y => y.unspscClassId)));

    let unspscs = [];

    for (let i = 0; i < selectedUnspscs.length; i++) {
      selectedUnspscs[i].forEach(unspsc => {
        const x = formValues.unspscs[i];
        if (x) {
          unspscs.push({
            adjustment: x.adjustment,
            forecastLength: x.forecastLength,
            organizationDescription: x.organizationDescription,
            organizationID: x.organizationID,
            simulationMethod: x.simulationMethod,
            trendMethod: x.trendMethod,
            unitMethod: x.unitMethod,
            unspscClassDescription: x.unspscClassDescription,
            unspscClassId: unspsc.unspscClassId,
            formIndex: i
          });
        }
      })
    }

    formValues.unspscs = unspscs;
    return formValues;
  }

  addZerosWhenNoData(historicalDataForOneUnspsc: HistoricalDataDto[]): void {
    let unspsc = this.getUnspsc(historicalDataForOneUnspsc);

    for (const year of this.getHistoricalYears()) {
      let filteredYear = historicalDataForOneUnspsc.filter((el) => {
        return Number(el.year) == Number(year);
      });
      if (filteredYear.length == 0) {
        historicalDataForOneUnspsc.push(
          this.getEmptyHistoricalDataDto(year, unspsc, false)
        );
      }
    }
  }

  addZeroWhenYearlyExpenseChangesByUnspscEmpty(
    yearlyExpenseChangesByUnspscEmpty,
    years
  ): void {
    yearlyExpenseChangesByUnspscEmpty.map((el) => {
      years.map((year) => {
        if (el.filter((nestedEl) => nestedEl.x == year).length > 0) {
          return;
        }
        el.push({ x: year, y: 0 });
      });
    });
  }

  sumYearlyTotals(yearlyTotalsExpenses: any[]) {
    const result = yearlyTotalsExpenses.reduce((acc, curr) => {
      const { x, y } = curr;
      const findObj = acc.find((o) => o.x === x);
      if (!findObj) {
        acc.push({ x: x, y: y });
      } else {
        findObj.y = y;
      }
      return acc;
    }, []);

    let totalsObject = result.reduce((acc, obj) => {
      return Object.assign({ [obj.x]: obj.y }, acc);
    }, {});

    return totalsObject;
  }

  getMaximumForecastLength(unspscsData) {
    let tempArray = [];
    unspscsData.map((el) => {
      tempArray.push(el.forecastLength);
    });

    let maxForecastLength = Math.max(...tempArray);

    return maxForecastLength;
  }
}
