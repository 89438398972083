import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InitiativeDto, InitiativeMasterDto } from '../../models/initiative-dto';
import { CalculateDto } from '../../models/scenario-dto';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { PriorityDto } from '../../models/priority-dto';
import { StatusDto } from '../../models/status-dto';

interface ColumnConfig {
  key: string;
  name: Observable<any>;
  width: string;
}

/**
 * @title Table with expandable rows
 */
@Component({
  selector: 'expandable-table-component',
  styleUrls: ['expandable-table.component.scss'],
  templateUrl: 'expandable-table.component.html'
})
export class ExpandableTableComponent implements OnInit {
  expandedElement: PeriodicElement | null;
  columnsToDisplay: any;
  columnsToDisplayWithExpand: any;

  expandedRows: { [key: number]: boolean } = {};

  @Input() dataSource: MatTableDataSource<
    InitiativeMasterDto | CalculateDto | CalculateDto>;
  @Input() columnsConfigs: ColumnConfig[];
  @Input() itemTemplate: TemplateRef<any>;
  @Input() orderByColumn = 'name';

  private sortValue: MatSort;
  private paginator: MatPaginator;
  @ViewChild(MatSort) set sort(value: MatSort) {
    this.sortValue = value;
    this.dataSource.sort = value;
  }
  @ViewChild(MatPaginator) set matPaginator(value: MatPaginator) {
    this.paginator = value;
    this.dataSource.paginator = value;
  }

  ngOnInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'initiativePriority': return ((item as any).initiativePriority as PriorityDto).value;
        case 'initiativeStatus': return ((item as any).initiativeStatus as StatusDto).value;
        default: return item[property];
      }
    };

    this.columnsToDisplay = this.getColumnsToDisplay(
      this.columnsConfigs
    ).columnsToDisplay;

    this.columnsToDisplayWithExpand = this.getColumnsToDisplay(
      this.columnsConfigs
    ).columnsToDisplayWithExpand;
  }

  getColumnsToDisplay(columnConfig: ColumnConfig[]) {
    let columnsToDisplay, columnsToDisplayWithExpand;
    columnsToDisplay = columnConfig.map((columnConfig) => columnConfig.key);
    columnsToDisplayWithExpand = [...columnsToDisplay, 'expand'];

    return { columnsToDisplay, columnsToDisplayWithExpand };
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  expand(position: number) {
    this.expandedRows[position] = !this.expandedRows[position]
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}

export class PaginatorI18n extends MatPaginatorIntl {
  translate: TranslateService;

  getRangeLabel = function (page, pageSize, length) {
    const of = this.translate
      ? this.translate.instant('general.paginator.of')
      : 'of';
    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  translateLabels() {
    super.itemsPerPageLabel = this.translate.instant(
      'general.paginator.per-page'
    );
    this.changes.next();
  }
}
