import { Component, Inject, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomersAveragePriceFormService } from '@modules/customers-average-price/services/customers-average-price-form.service';
import { CustomersAveragePriceService } from '@modules/customers-average-price/services/customers-average-price.service';
import { DialogService } from '../../../../services/dialog.service';
import { CreateEditDialogComponent } from 'src/app/commons/components/create-edit-dialog/create-edit-dialog.component';
import { Subscription } from 'rxjs';
import { EntityTrackerError } from 'src/app/commons/models/entity-tracker-error';
import { UnitViewDto, UnspscUnitDto } from 'src/app/commons/models/unspsc-unit-dto';
import { UserService } from '@modules/shell/services/user.service';

@Component({
    selector: 'app-create-edit-cap-dialog',
    templateUrl: './create-edit-cap-dialog.component.html',
    styleUrls: ['./create-edit-cap-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CreateEditCapDialogComponent extends CreateEditDialogComponent implements OnInit, AfterViewChecked, OnDestroy {
  unspscsOptions: any[] | EntityTrackerError = [];
  supplierOptions: any[] | EntityTrackerError = [];
  unitOptions: any[] | EntityTrackerError = [];
  unspscsOptionsSubscription: Subscription;
  formInitializationSubscription: Subscription;

  unspscsOptionsInitialized: boolean = false;
  unitOptionsInitialized: boolean = false;
  supplierOptionsInitialized: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    dialogRef: MatDialogRef<any>,
    public formService: CustomersAveragePriceFormService,
    private customersAveragePriceService: CustomersAveragePriceService,
    dialogService: DialogService,
    userService: UserService,
    private cdRef: ChangeDetectorRef) {
    super(editData,
      customersAveragePriceService,
      formService,
      dialogRef,
      dialogService,
      userService)
  }

  ngOnInit(): void {
    this.customersAveragePriceService.getSuppliers().subscribe(x => {
      this.supplierOptions = x as any[];
      this.supplierOptionsInitialized = true;

      if (this.editData) {
        if (this.supplierOptions?.find(z => z.supplierId === this.editData.supplierID
          && z.supplierCountry === this.editData.supplierCountry)) {
          this.getUnspscAndUnits(this.editData.supplierID, this.editData.supplierCountry, false);
        } else {
          this.getUnspscAndUnits(null, null, false);
        }
      } else {
        this.customersAveragePriceService.getUnspscWithUnits().subscribe(y => {
          this.unspscsOptions = y as any[];
          this.unspscsOptionsInitialized = true;

          this.unitOptions = (y as any[])?.find(z => z.unspscClassId === this.editData?.unspsc)?.units?.map(x => <UnitViewDto>{ unit: x.unit.toUpperCase(), unitLabel: x.unitLabel }) ?? [];

          this.unitOptionsInitialized = true;
        });
      }
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  onSupplierSelectionChange(option: any): void {
    this.formService.form.controls.SupplierCountry
      .setValue(option.supplierCountry ?? '');
    this.getUnspscAndUnits(this.formService.form.controls.SupplierID.value,
      this.formService.form.controls.SupplierCountry.value, true);
  }

  onUnspscSelectionChange(option: UnspscUnitDto): void {
    this.formService.form.controls.Unit.setValue('');
    this.formService.form.controls.Unit.enable();
    this.unitOptions = option.units;
    this.cdRef.detectChanges();
  }

  onSupplierValueCleared(): void {
    this.getUnspscAndUnits(null, null, false);
  }

  onUnspscValueCleared(): void {
    this.formService.form.controls.Unit.setValue('');
    this.formService.form.controls.Unit.disable();
    this.unitOptions = [];
    this.cdRef.detectChanges();
  }

  getUnspscAndUnits(supplierId: string, supplierCountry: string, supplierSelectionChanged: boolean): void {
    this.unitOptionsInitialized = false;

    this.customersAveragePriceService.getUnspscWithUnits(supplierId, supplierCountry)
      .subscribe(x => {
        this.unspscsOptions = x as any[];
        this.unspscsOptionsInitialized = true;

        this.unitOptions = (x as any[])?.find(z => z.unspscClassId === this.formService.form.controls.Unspsc.value)?.units?.map(x => <UnitViewDto>{ unit: x.unit.toUpperCase(), unitLabel: x.unitLabel }) ?? [];
        this.unitOptionsInitialized = true;

        if (supplierSelectionChanged && !(this.unspscsOptions?.map(x => x.unspscClassId.toString())?.includes(this.formService.form.controls.Unspsc.value))) {
          this.formService.form.controls.Unspsc.setValue('');
          this.formService.form.controls.Unit.setValue('');
          this.formService.form.controls.Unit.disable();
        }
      });
  }

  async submitForm(): Promise<void> {
    if (await this.validateSupplier() && await this.validateUnspscAndUnit()) {
      this.ctaScenario();
    }
  }

  private validateSupplier(): boolean {
    if (!this.formService.form.controls.SupplierID.value) {
      return true;
    }
    const foundSupplier = (this.supplierOptions as any[]).find(option => option.supplierId === this.formService.form.controls.SupplierID.value);
    if (!foundSupplier) {
      this.formService.form.controls.SupplierCountry.setValue('');
      this.formService.form.controls.SupplierID.setErrors({ error: true });
      return false;
    } else {
      return true;
    }
  }

  private validateUnspscAndUnit(): boolean {
    let valid = false;
    const foundUnspsc = (this.unspscsOptions as any[]).find(option => option.unspscClassId == this.formService.form.controls.Unspsc.value);
    if (!foundUnspsc) {
      this.formService.form.controls.Unspsc.setErrors({ error: true });
      return false;
    } else {
      valid = true;
    }

    const foundUnit = foundUnspsc.units.find(option => option.unit.toUpperCase() === this.formService.form.controls.Unit.value.toUpperCase());
    if (!foundUnit) {
      this.formService.form.controls.Unit.setErrors({ error: true });
      return false;
    } else {
      valid = true;
    }

    return valid;
  }

  ngOnDestroy(): void {
    this.formService.resetEditData();
    this.formService.resetForm();
    this.formService.resetProductDataChange();

    if (this.unspscsOptionsSubscription)
      this.unspscsOptionsSubscription.unsubscribe();

    if (this.formInitializationSubscription)
      this.formInitializationSubscription.unsubscribe();
  }
}
