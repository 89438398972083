<div class="container">
  <div class="row justify-content-between">
    <h1 class="col-8 mt-5">
      {{ 'navigation.initiatives' | translate }}
    </h1>
  </div>

  <expandable-table-component *ngIf="dataSource"
    [itemTemplate]="initiativesTemplate"
    [dataSource]="dataSource"
    [columnsConfigs]="columnsConfigs"
    orderByColumn="initiativeName"
  >
  </expandable-table-component>
</div>

<ng-template #initiativesTemplate let-element>
  <div class="d-block fs-14">
    <div class="row mt-4 mb-4">
      <div class="col-4">
        <div class="mb-4">
          <div class="fw-bold fs-18 mb-4">
            {{ 'expandable-row.description' | translate }}
          </div>
          {{ element.initiativeDescription }}
        </div>

        <div>
          <span class="fw-bold d-inline-block mb-2">
            {{ 'expandable-row.expense' | translate }}
          </span>

          {{ element.totalEmissionChange | number: '1.0-0' }}
          <app-amount-indicator
            [amount]="element.totalEmissionChange"
          >
          </app-amount-indicator>
        </div>

        <div class="mb-4">
          <span class="fw-bold d-inline-block mb-2"
            >{{ 'expandable-row.emission' | translate }}
          </span>

          {{ element.totalLineAmountChange | number: '1.0-0' }}
          <app-amount-indicator
            [amount]="element.totalLineAmountChange"
          >
          </app-amount-indicator>
        </div>

        <div>
          <span class="fw-bold d-inline-block mb-2">
            {{ 'expandable-row.start-date' | translate }}
          </span>
          {{ element.initiativeStartYear }}
        </div>
        <div>
          <span class="fw-bold d-inline-block mb-2"
            >{{ 'expandable-row.end-date' | translate }}
          </span>
          {{ element.initiativeEndYear | date: 'yyyy' }}
        </div>
      </div>

      <div class="col-6">
        <p class="fs-18 fw-bold mb-4 ps-4">
          {{ 'expandable-row.unspsc' | translate }}
        </p>
        <div *ngFor="let unspsc of element.unspscs; let unspscIndex = index">
          <div class="wrapper p-4 mb-4 me-2">
            <div class="row">
              <div class="col-6">
                <span class="fw-bold d-inline-block mb-2">
                  {{ 'expandable-row.unspsc' | translate }}
                </span>
                <div *ngFor="let unspscGrid of getGridUnspscs(element.unspscsGrid, unspscIndex)?.slice(0, maxUnspscsLimit)">
                  <div class="mb-2">{{ unspscGrid.unspscClassID }} {{ unspscGrid.unspscClassDescription }}</div>
                </div>
                <div class="mb-2" *ngIf="getGridUnspscs(element.unspscsGrid, unspscIndex)?.length > maxUnspscsLimit">...</div>
                  <span class="fw-bold d-inline-block mb-2">
                    {{ 'expandable-row.forecast-length' | translate }}
                  </span>
                  {{ unspsc.forecastLength }}
              </div>
              <div class="col-6">
                <div>
                  <span class="fw-bold d-inline-block mb-2">{{ 'expandable-row.organization' | translate }}
                  </span>
                  {{ unspsc.organizationID || '' }}
                </div>
                <div class="mb-2">
                  {{
                  unspsc.organizationDescription ||
                  ('expandable-row.all-organizations' | translate)
                  }}
                </div>
                <div>
                  <span class="fw-bold d-inline-block mb-2">{{ 'expandable-row.adjustment.description' | translate }}
                  </span>
                  {{ unspsc.adjustment || '' }}
                </div>
                <div>
                  <span class="fw-bold d-inline-block mb-2">{{
                    'expandable-row.adjustment.simulation-method' | translate
                    }}
                  </span>
                  {{
                  unspsc.simulationMethod === 1
                  ? ('unspsc.methods.percentage' | translate)
                  : ('unspsc.methods.quantity' | translate) || ''
                  }}
                </div>
                <div>
                  <span class="fw-bold d-inline-block mb-2">{{ 'expandable-row.adjustment.trend-method' | translate }}
                  </span>
                  {{
                  unspsc.trendMethod == 1
                  ? ('unspsc.methods.linear' | translate)
                  : ('unspsc.methods.constant' | translate) || ''
                  }}
                </div>
                <div>
                  <span class="fw-bold d-inline-block mb-2">{{ 'expandable-row.adjustment.unit-method' | translate }}
                  </span>
                  {{
                  unspsc.unitMethod == 1
                  ? this.tenantSelectService.userCurrency == DK
                  ? 'DKK'
                  : 'NOK'
                  : 'KG' || ''
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-2 text-center">
        <button
          mat-icon-button
          (click)="editInitiative(element)"
          color="primary"
        >
          <mat-icon fontIcon="edit"></mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="deleteInitiative(element)"
          color="warn"
        >
          <mat-icon fontIcon="delete"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
