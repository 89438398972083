<div class="action-buttons">
    <span>
      <button mat-raised-button color="primary" (click)="createDialog()">
        {{ translationKey + '.create' | translate }}
      </button>
    </span>
    <span class="action-button">
      <DimaTable-columns-display columnsId="table"></DimaTable-columns-display>
    </span>
    <span class="action-button">
      <button mat-raised-button color="warn" [disabled]="isDeleteButtonDisabled"
        (click)="deleteSelected()" style="float: left;">
        <mat-icon fontIcon="delete"></mat-icon>
        {{ translationKey + '.delete-selected' | translate }}
      </button>
    </span>
  </div>