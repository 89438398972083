import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { GhgCategory } from '../models/ghg-category-model';
import { GhgCategoryUnspsc } from '../models/ghg-category-unspsc-model';

@Injectable({
  providedIn: 'root'
})
export class GhgCategoryService {

  constructor(private apiService: ApiService) { }

  getAllGhgCategories(): Observable<GhgCategory[]> {
    return this.apiService.getAllGhgCategories();
  }

  getGhgCategoriesByUnspsc(unspsc: string): Observable<GhgCategoryUnspsc[]> {
    return this.apiService.getGhgCategoriesByUnspsc(unspsc);
  }
}
