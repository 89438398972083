<div class="dialog">
  <div class="container gx-0">
    <p class="fw-bold fs-30">
      {{'pbi-filters.current-filters' | translate}}
    </p>
    <div class="dialog__close" (click)="cancel()">
    </div>
    <div mat-dialog-content class="dialog-content">
      <div *ngIf="filterValues?.length === 0">{{ 'pbi-filters.no-data' | translate }}</div>
      <table mat-table [dataSource]="filterValues" class="mat-elevation-z0">
        <ng-container matColumnDef="Key">
          <th mat-header-cell *matHeaderCellDef> No. </th>
          <td mat-cell *matCellDef="let filterValue">{{filterValue.key}}&nbsp;<span *ngIf="filterValue.invert"
              class="bold-text">({{'InvertedFiltering' |
              translate}})</span></td>
        </ng-container>
        <ng-container matColumnDef="Value">
          <th mat-header-cell *matHeaderCellDef> No2. </th>
          <td mat-cell *matCellDef="let filterValue">
            <div *ngFor="let value of filterValue.values">
              <p [innerHtml]="value"></p>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef> No2. </th>
          <td mat-cell *matCellDef="let filterValue" style="width: 10%;">
            <button matTooltip="{{ 'general.delete-tooltip' | translate }}" mat-icon-button
              (click)="deleteFilter(filterValue)" color="warn">
              <mat-icon fontIcon="delete"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" style="display: none;"></tr>
        <tr mat-row [class.striped-row]="even" [class.gray-hover]="even" [class.white-hover]="!even"
          *matRowDef="let row; let even = even; columns: displayedColumns;">
        </tr>
      </table>
    </div>
  </div>
  <div mat-dialog-actions class="dialog__buttons">
    <button mat-flat-button color="primary" (click)="cancel()">{{ 'pbi-filters.close' | translate }}</button>
  </div>
</div>