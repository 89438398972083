import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ROUTENAMES } from '../../../../app-routing.names';
import { Router } from '@angular/router';

@Component({
    selector: 'app-something-went-wrong',
    templateUrl: './something-went-wrong.component.html',
    styleUrls: ['./something-went-wrong.component.scss'],
    standalone: false
})
export class SomethingWentWrongComponent implements OnInit {
  errorTitle: any;
  errorCodeDescription: any;
  errorSubtitle: any;
  buttonName: any;
  navigationRoute: string;
  errorCode = '500';

  constructor(public translateService: TranslateService, private router: Router) {
    const state = this.router.getCurrentNavigation().extras.state;

    this.errorTitle = state?.title ?? this.translateService.instant(
      'errors.something-went-wrong-error.title'
    );
    this.errorCodeDescription = this.translateService.instant(
      'errors.something-went-wrong-error.type'
    );
    this.errorSubtitle = state?.message ?? this.translateService.instant(
      'errors.something-went-wrong-error.subtitle'
    );
    this.buttonName = this.translateService.instant(
      'errors.something-went-wrong-error.cta'
    );

    this.errorCode = state?.status.toString() ?? this.errorCode;
    this.navigationRoute = ROUTENAMES.HOME;
  }

  ngOnInit(): void { }
}
