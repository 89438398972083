import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogConfigData } from '../../models/dialog-config-data';

@Component({
    selector: 'app-information-dialog',
    templateUrl: './user-information-dialog.component.html',
    styleUrls: ['./user-information-dialog.component.scss'],
    standalone: false
})
export class UserInformationDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogConfigData) {}

  ngOnInit(): void {}
}
