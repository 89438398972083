import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScenariosListComponent } from '@modules/scenarios/components/scenarios-list/scenarios-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { CreateEditScenarioDialogComponent } from './components/create-edit-scenario-dialog/create-edit-scenario-dialog.component';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import {
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ScenarioCalculatorComponent } from './components/scenario-calculator/scenario-calculator.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UnspscHandlerComponent } from '../../commons/components/unspsc-handler/unspsc-handler.component';
import { SaveAsInitiativeButtonComponent } from './components/save-as-initiative-button/save-as-initiative-button.component';
import { DialogService } from '../../services/dialog.service';
import { TableDisplayComponent } from './components/table-display/table-display.component';
import { TableDisplayTemplatesComponent } from './components/table-display-templates/table-display-templates.component';
import { AmountIndicatorComponent } from './components/amount-indicator/amount-indicator.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { ExpandableTableModule } from '../../commons/components/expandable-table/expandable-table.module';
import { TemplatesListComponent } from '@modules/scenarios/components/templates-list/templates-list.component';
import { ScenariosAndTemplatesWrapperComponent } from './components/scenarios-and-templates-wrapper/scenarios-and-templates-wrapper.component';
import { CreateEditTemplateDialogComponent } from '@modules/scenarios/components/create-edit-template-dialog/create-edit-template-dialog.component';
import { SaveAsScenarioButtonComponent } from '@modules/scenarios/components/save-as-scenario-button/save-as-scenario-button.component';
import { SanityAsyncValidatorDirective } from '../../commons/directives/sanity-async-validator.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatTreeModule } from '@angular/material/tree';
import { TreeAutocompleteComponent } from 'src/app/commons/components/tree-autocomplete/tree-autocomplete.component';
import { PipesModule } from 'src/app/commons/pipes.module';
import { CalculationsComponent } from './components/calculations/calculations.component';

@NgModule({
  declarations: [
    ScenariosListComponent,
    CreateEditScenarioDialogComponent,
    CreateEditTemplateDialogComponent,
    ScenarioCalculatorComponent,
    TreeAutocompleteComponent,
    UnspscHandlerComponent,
    SaveAsInitiativeButtonComponent,
    TableDisplayComponent,
    TableDisplayTemplatesComponent,
    AmountIndicatorComponent,
    TemplatesListComponent,
    ScenariosAndTemplatesWrapperComponent,
    SaveAsScenarioButtonComponent,
    CalculationsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatExpansionModule,
    MatSliderModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    ExpandableTableModule,
    SanityAsyncValidatorDirective,
    PipesModule
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    DialogService,
  ],
  exports: [UnspscHandlerComponent, AmountIndicatorComponent, ScenarioCalculatorComponent, TableDisplayComponent, CalculationsComponent],
})
export class ScenariosModule { }
