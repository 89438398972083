import { Injectable, OnDestroy } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHelperService } from './translate-helper.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';
import { LanguageDto } from '../commons/models/language-dto';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentLanguageService implements OnDestroy {
  private routerSubscription: Subscription;
  public lang: string = 'da';
  public langChosenPriorLogin: LanguageDto | undefined;
  public languageChanges = new BehaviorSubject<string>('');

  constructor(
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private translateHelperService: TranslateHelperService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private apiService: ApiService
  ) {
    this.routerSubscription = this.router.events.subscribe((events) => {
      if (
        events instanceof NavigationStart &&
        events.url.toString().indexOf('?lang=') !== -1
      ) {
        let lang = this.getLangParamFromUrl(events.url);
        if (lang && this.translateHelperService.langList.find((el) => el == lang)?.length) {
          this.switchLang(lang, true);
        } else {
          console.log('[Current Language Service] Wrong lang parameter added');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  switchLang(lang: string, parameterBased = false) {
    this.lang = lang;

    this.apiService.setLanguage(this.lang).subscribe();

    console.log(
      '[Current Language Service] Current lang in current language service',
      this.lang
    );

    if (
      this.router.url.toString().indexOf('/login') !== -1 ||
      parameterBased
    ) {
      this.addOrUpdateLangInLocalStorage(this.lang);
    }
    else {
      this.removeLangFromLocalStorage();
    }

    console.log(
      '[Current Language Service] Current lang in local storage',
      this.getLangFromLocalStorage()
    );

    this.translateService.use(lang);
    this.removeLangParamFromUrl();
    this.languageChanges.next(lang);
  }

  setLanguagePriorLogin(lang: LanguageDto | undefined): void {
    this.langChosenPriorLogin = lang;
  }

  removeLangFromLocalStorage() {
    this.localStorageService.removeItem('lang');
  }

  getLangFromLocalStorage() {
    return this.localStorageService.getItem('lang');
  }

  addOrUpdateLangInLocalStorage(lang) {
    this.localStorageService.addOrUpdateItem('lang', lang);
  }

  isLanguageChosenPriorLogin() {
    return !!this.langChosenPriorLogin;
  }

  private getLangParamFromUrl(url: string): string {
    let startingIndex = url.lastIndexOf('?lang=');
    let lang = url.slice(startingIndex + 6, startingIndex + 8);
    return lang;
  }

  private removeLangParamFromUrl(): void {
    if (this.route.snapshot.firstChild) {
      let currentPath = this.route.snapshot.firstChild.url[0].path;
      this.location.replaceState(currentPath);
      console.warn(
        '[Current Language Service] Lang parameter removed from url'
      );
    }
  }
}
