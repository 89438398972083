import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportComponent } from './components/report/report.component';
import { ReportWrapperComponent } from './components/report-wrapper/report-wrapper.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { PdfOrderListComponent } from '@modules/report/components/pdf-order-list/pdf-order-list.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { OpenPdfExportButtonComponent } from '@modules/report/components/button-bar/open-pdf-export-button.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderUserReportSelectComponent } from '@modules/report/components/header-user-report-select/header-user-report-select.component';
import { HeaderBookmarkSelectComponent } from '@modules/report/components/header-bookmark-select/header-bookmark-select.component';
import { CreateBookmarkDialogComponent } from '@modules/report/components/create-bookmark-dialog/create-bookmark-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SanityAsyncValidatorDirective } from '../../commons/directives/sanity-async-validator.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { ShowFilterDialogComponent } from './components/show-filter-dialog/show-filter-dialog.component';
import { PipesModule } from 'src/app/commons/pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    ReportComponent,
    ReportWrapperComponent,
    PdfOrderListComponent,
    CreateBookmarkDialogComponent,
    OpenPdfExportButtonComponent,
    HeaderUserReportSelectComponent,
    HeaderBookmarkSelectComponent,
    ShowFilterDialogComponent
  ],
  imports: [
    CommonModule,
    PowerBIEmbedModule,
    MatIconModule,
    MatTableModule,
    MatMenuModule,
    MatButtonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    SanityAsyncValidatorDirective,
    MatDialogModule,
    MatTooltipModule,
    PipesModule
  ]
})
export class ReportModule {}
