import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGetDataService } from 'src/app/commons/components/data-view/data-service.interface';
import { EntityTrackerError } from 'src/app/commons/models/entity-tracker-error';
import { PageModel } from 'src/app/commons/models/page-model';
import { PageResponse } from 'src/app/commons/models/page-response';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class MeasuringPointsGetService implements IGetDataService {

  constructor(private apiService: ApiService) { }

  get(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    return this.apiService.getMeasuringPoints(pageModel);
  }
}