import { Component, ElementRef, Host, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataViewComponent } from 'src/app/commons/components/data-view/data-view.component';
import { CustomDialogService } from 'src/app/commons/dialog/services/custom-dialog.service';
import { CreateEditPseDialogComponent } from '../create-edit-pse-dialog/create-edit-pse-dialog.component';
import { ProductSpecificEmissionsGetService, ProductSpecificEmissionsService } from '@modules/product-specific-emissions/services/product-specific-emissions.service';
import { TableService } from '@modules/product-specific-emissions/services/table.service';
import { PageResponse } from 'src/app/commons/models/page-response';
import { DialogService } from 'src/app/services/dialog.service';
import { SnackbarService } from 'src/app/commons/services/snackbar.service';
import { CustomersProductEmissionCsvService } from '@modules/product-specific-emissions/services/customers-product-emission-csv.service';
import { FileService } from 'src/app/commons/services/file.service';
import { InformationDialogService } from '@modules/shell/services/information-dialog.service';
import { FilterInUseService } from '@modules/product-specific-emissions/services/filter/filter-in-use.service';
import { UserService } from '@modules/shell/services/user.service';

@Component({
    selector: 'app-pse-action-menu',
    templateUrl: './pse-action-menu.component.html',
    styleUrls: ['./pse-action-menu.component.scss'],
    providers: [DataViewComponent,
        { provide: 'IGetDataService', useExisting: ProductSpecificEmissionsGetService },
        { provide: 'IDataService', useExisting: ProductSpecificEmissionsService },
        { provide: 'ITableService', useExisting: TableService },
        { provide: 'IFilterService', useExisting: FilterInUseService },
    ],
    standalone: false
})
export class PseActionMenuComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  preventStatusChange: boolean = false;
  translationKey = 'product-specific-emissions';
  parentComponent: DataViewComponent;
  isStatusChangeButtonDisabled = true;
  private customDialogService: CustomDialogService;

  constructor(@Host() parent: DataViewComponent,
    public translateService: TranslateService,
    dialogService: DialogService,
    private tableService: TableService,
    private getDataService: ProductSpecificEmissionsGetService,
    private dataService: ProductSpecificEmissionsService,
    private filterService: FilterInUseService,
    private snackbarService: SnackbarService,
    private fileService: FileService,
    private pseCsvService: CustomersProductEmissionCsvService,
    private informationDialogService: InformationDialogService,
    private userService: UserService) {
    this.customDialogService = new CustomDialogService(dialogService, getDataService, dataService, tableService, filterService, snackbarService, translateService);
    this.customDialogService.orderBy = 'order by SupplierDisplayValue asc, ItemIdDisplayValue asc, ItemName asc, UnitCode asc',
      this.parentComponent = parent;
  }

  ngOnInit() {
    this.customDialogService.dialogComponent = CreateEditPseDialogComponent;
    this.tableService.anyRowSelectedSubject.subscribe(isSelected => {
      this.isStatusChangeButtonDisabled = !isSelected;

      if (isSelected) {
        const failingRows = this.tableService.selectedRows.filter(x => x?.verified);
        this.preventStatusChange = failingRows?.length > 0 && failingRows?.length == this.tableService.selectedRows?.length;
      }
    });
  }

  createDialog(): void {
    this.customDialogService.createDialog();
  }

  uploadDatasource(fileInput: any) {
    let file = fileInput.target.files[0];
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.fileService.uploadDatasource(formData)
      .subscribe(
        response => {
          if (response) {
            this.pseCsvService.importCsv(file.name).subscribe(x => {
              this.getDataService
                .get({
                  search: 1,
                  filters: this.tableService.filters,
                  pageSize: this.tableService.pageSize.toString(),
                  orderBy: this.tableService.pagedModel?.orderBy ?? this.customDialogService.orderBy,
                  page: this.tableService.page.toString()
                }).subscribe((data: PageResponse) => {
                  this.tableService.setData(data);
                  this.filterService.resetFilters();
                  this.filterService.initializeFilters();
                });
              this.snackbarService.openSnackBar('product-specific-emissions.data-imported');
            },
              error => {
                console.error('error', error);
                this.informationDialogService.showCommonHttpError(error, 'CSV import error');
              });
          }
        },
        error => {
          console.error('error', error);
          this.informationDialogService.showCommonHttpError(error, 'File uploading error');
        });

    this.clearChosenFile();
  }

  async changeStatus(enable: boolean) {
    return (await this.dataService.enableDisableRow(this.tableService.selectedRows.map(x => x.id), enable)).subscribe(() => {
      this.tableService.selectedRows = [];
      this.tableService.clearTableSelection.next(true);
      this.getDataService
        .get({
          search: 1,
          filters: this.tableService.filters,
          pageSize: this.tableService.pageSize.toString(),
          orderBy: this.tableService.pagedModel?.orderBy ?? this.customDialogService.orderBy,
          page: this.tableService.page.toString()
        })
        .subscribe((pageResponse: PageResponse) => {
          this.tableService.setData(pageResponse);
          this.filterService.resetFilters();
          this.filterService.initializeFilters();
          this.tableService.totalCount = pageResponse.totalFilteredProducts;
          this.snackbarService.openSnackBar(
            `${this.translationKey}.updated`
          );
          this.isStatusChangeButtonDisabled = true;
        });
    });
  }

  isReadonlyUser(): boolean {
    return this.userService.currentUser.permissions.customerReadOnlyUser;
  }

  private clearChosenFile() {
    this.fileInput.nativeElement.value = null;
  }
}
