import { Injectable } from '@angular/core';
import {
  Unspsc,
  UnspscCalculatorServiceDto,
  UnspscViewModel,
} from '../../models/unspsc';
import { SearchModelDto } from '../../models/search-model-dto';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import {
  OrganizationalUnit,
  OrganizationalUnitViewModel,
} from '../../models/organizational-unit';
import { UnitMethod } from '../../models/unit-method.model';

@Injectable({
  providedIn: 'root',
})
export class UnspscHandlerService {
  public filteredUnspscViewModels: UnspscCalculatorServiceDto[] = [];
  // public unspscsViewModels: UnspscViewModel[];
  private unspscSubscription?: Subscription;
  public unspscs: Unspsc[];
  public unspscsLastYear: number = new Date().getFullYear();
  public selectedUnspscs: Unspsc[][] = [[]];
  public unspscsChange = new Subject<boolean>();

  public filteredOrganizationViewModels: UnspscCalculatorServiceDto[] = [];
  public organizationUnitViewModels: OrganizationalUnitViewModel[];
  private organizationUnitSubscription?: Subscription;

  unitMethod: UnitMethod[][];

  constructor(private apiService: ApiService) { }

  searchUnspsc(searchModel: SearchModelDto): Observable<UnspscViewModel[]> {
    return this.apiService.searchUnspsc(searchModel).pipe(
      switchMap((unspscs: UnspscViewModel[]) => {
        this.filteredUnspscViewModels = [
          ...unspscs,
          ...this.filteredUnspscViewModels,
        ];

        return of(unspscs);
      })
    );
  }

  searchOrganization(
    searchModel: SearchModelDto
  ): Observable<OrganizationalUnitViewModel[]> {
    return this.apiService.searchOrganization(searchModel).pipe(
      switchMap((organizationalUnits: OrganizationalUnit[]) => {
        this.organizationUnitViewModels =
          organizationalUnits.map<OrganizationalUnitViewModel>((el) => {
            return {
              organizationalUnitViewDescription:
                el.organizationID + ' ' + el.organizationDescription,
              organizationDescription: el.organizationDescription,
              organizationID: el.organizationID,
            };
          });

        this.filteredOrganizationViewModels = [
          ...this.organizationUnitViewModels,
          ...this.filteredOrganizationViewModels,
        ];

        return of(this.organizationUnitViewModels);
      })
    );
  }

  resetFilteredViewModels(): void {
    this.filteredUnspscViewModels = [];
    this.filteredOrganizationViewModels = [];
  }

  ngOnDestroy(): void {
    this.unspscSubscription.unsubscribe();
    this.organizationUnitSubscription.unsubscribe();
  }

  getLastYear(unspscs, currentYear) {
    let forecastLengths = [];
    let firstYear: number = Number(currentYear);
    let maxForecastLength: number;
    let lastYear: number;

    unspscs.forEach((el) => forecastLengths.push(el.forecastLength));

    maxForecastLength = Math.max(...forecastLengths);
    lastYear = firstYear + maxForecastLength;

    return lastYear;
  }

  resetUnspscLastYear() {
    this.unspscsLastYear = new Date().getFullYear();
  }
}
