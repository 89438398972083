import { Injectable } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Observable, of } from 'rxjs';
import { EntityTrackerError } from '../../../commons/models/entity-tracker-error';
import { PageResponse } from '../../../commons/models/page-response';
import { PageModel } from '../../../commons/models/page-model';
import { IDataService } from 'src/app/commons/components/data-view/data-service.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomersOwnDataExclusionService implements IDataService {
  constructor(private apiService: ApiService) { }

  get(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    return this.apiService.getAllCustomersExclusions(pageModel);
  }

  getByUniqueKey(model: any): Observable<any | EntityTrackerError> {
    return of(new EntityTrackerError());
  }
  add(formValues, file?: File): Observable<number | EntityTrackerError> {
    return of(new EntityTrackerError());
  }
  update(updateModel: any, file?: File): Observable<number | EntityTrackerError> {
    return of(new EntityTrackerError());
  }
  sliderChange(updateModel: any, sliderValue: boolean) {

  }
  delete(ids: number[]) {

  }
}
