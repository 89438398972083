import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { PbiReportService } from '@modules/report/services/pbi-report.service';
import { catchError, tap } from 'rxjs/operators';
import { ROUTENAMES } from '../app-routing.names';
import { ReportData } from '../commons/models/powerbi-model';

@Injectable({
  providedIn: 'root',
})
export class PbiReportsResolver  {
  constructor(
    private reportService: PbiReportService,
    private router: Router
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ReportData> {
    return this.reportService.getDefaultReport().pipe(
      tap((info) => {
        this.reportService.setReportsMetaData(
          info.pbiReportId,
          info.workspaceId,
          info.description
        );
        this.reportService.userReports$ = of(info.reports);
      }),
      catchError((error) => {
        if (error.error.status == 401) {
          this.router.navigate([ROUTENAMES.UNAUTHORIZED]);
        } else {
          this.router.navigate([ROUTENAMES.ERROR], { state: { title: error.error.title, message: error.error.detail, status: error.error.status } });
        }

        return EMPTY;
      })
    );
  }
}
