import { Injectable } from '@angular/core';
import { FilterOption } from '@dima/table-library';
import { ApiService } from '../../../services/api.service';
import { IFilterService } from 'src/app/commons/components/data-view/filter-service.interface';
import * as tableLibrary from '@dima/table-library';
import { ExclusionsViewTableService } from './exclusions-view-table.service';

@Injectable({
  providedIn: 'root',
})
export class ExclusionsFilterService implements IFilterService {
  public apiFilters = {
    InvoiceDate: [],
    InvoiceNumber: [],
    ItemId: [],
    OrganizationId: [],
    UnspscClassId: [],
    CustomersExclusion: [],
    SupplierDisplayValue: [],
  };

  constructor(
    private apiService: ApiService,
    private tableService: ExclusionsViewTableService
  ) { }

  async initializeFilters(): Promise<void> {
    const filters = await this.apiService.getExclusionsFilters().toPromise();
    for (const [key, value] of Object.entries(filters)) {
      filters[key].map((el) => {
        this.apiFilters[key].push({
          value: el,
        });
      });

      let column = this.tableService.cols.find((col) => {
        let colName = `${key[0].toLowerCase() + key.slice(1)}`;
        return col.name == colName;
      });

      if (key == 'CustomersExclusion') {
        column.options = [
          { value: 'true', checked: false },
          { value: 'false', checked: false },
        ] as FilterOption[];
      } else {
        column.options = [...this.apiFilters[key]];
      }

      this.tableService.table?.applyFilter({
        column: column,
        tableId: 'table',
        text: '',
      });

    }
  }

  async initializeTranslations(): Promise<void> {
  }

  public setFilter(filter: tableLibrary.Filter): string {
    return filter.text;
  }

  resetFilters() {
    this.apiFilters = {
      InvoiceDate: [],
      InvoiceNumber: [],
      ItemId: [],
      OrganizationId: [],
      UnspscClassId: [],
      CustomersExclusion: [],
      SupplierDisplayValue: [],
    };
  }
}
