import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import {
  InitiativeMasterDto,
  InitiativeWorkDto,
} from '../commons/models/initiative-dto';
import { EntityTrackerError } from '../commons/models/entity-tracker-error';
import { Unspsc, UnspscViewModel } from '../commons/models/unspsc';
import { OrganizationalUnit } from '../commons/models/organizational-unit';
import {
  CalculateDto,
  CalculateMasterDto,
  CalculateWorkDto,
} from '../commons/models/scenario-dto';
import { StatusDto } from '../commons/models/status-dto';
import { PriorityDto } from '../commons/models/priority-dto';
import { HistoricalDataResponseDto } from '../commons/models/historical-data-dto';
import {
  SearchModelDto,
  SearchTrendPointModelDto,
} from '../commons/models/search-model-dto';
import { ProductSpecificEmissionDto, UniqueEntityValidationResponse } from '../commons/models/product-specific-emission-dto';
import { PageModel } from '../commons/models/page-model';
import { PageResponse } from '../commons/models/page-response';
import * as FileSaver from 'file-saver';
import { CustomersOwnDataDto } from '../commons/models/customers-own-data-dto';
import { CodApiFilterModelDao } from '../commons/models/cod-filter-model';
import { CodUniqueKeyModel } from '../commons/models/cod-unique-key-model';
import { BYPASS_REDIRECT } from '../interceptores/http-response.interceptor';
import { ExportDto, OrderExportDto } from '../commons/models/export-dto';
import { CapApiFilterModelDao } from '../commons/models/cap-filter-model';
import { CapUniqueKeyModel } from '../commons/models/cap-unique-key-model';
import { CustomersAveragePriceDto } from '../commons/models/customers-average-price-dto';
import { SupplierDto } from '../commons/models/supplier-dto';
import { UnspscUnitDto } from '../commons/models/unspsc-unit-dto';
import { PseUniqueKeyModel } from '../commons/models/pse-unique-key-model';
import { EmissionSupplierItemDto } from '../commons/models/emission-supplier-item-dto';
import { PseFilterModelDao } from '../commons/models/pse-filter-model';
import { GhgCategoryUnspsc } from '../commons/models/ghg-category-unspsc-model';
import { GhgCategory } from '../commons/models/ghg-category-model';

import { Bookmark } from '../commons/models/bookmark-model';
import { ReportData } from '../commons/models/powerbi-model';
import { PbiFilter } from '../commons/models/report/pbi-filter.model';
import { FilterValues } from '../commons/models/report/filter-values.model';
import { ColumnKeyValue, PbiFilterTranslation } from '../commons/models/report/pbi-filter-translation.model';

import { InformationDialogService } from '@modules/shell/services/information-dialog.service';
import { DocumentationDetails } from '../commons/models/documentation-details.model';
import { Language } from '../commons/models/language.model';
import { TenantCodeResponse } from '@modules/shell/services/user.service';
import { ItemNode } from '../commons/components/tree-autocomplete/tree-autocomplete.component';
import { CodExclusionFilterModel } from '../commons/models/cod-exclusion-filter.model';
import { UnspscSingleUnitResponse } from '../commons/models/unspsc-single-unit-response.model';
import { ExclusionsFilterModel } from '../commons/models/exclusions-filter.model';
import { CustomersExclusionRuleDto } from '../commons/models/customers-exclusion-rule-dto';
import { ExclusionsDropdownModel } from '../commons/models/exclusions-dropdown.model';
import { FiscalYear, FiscalYearFilterModel, FiscalYearValidationModel } from '../commons/models/fiscal-year.model';
import { MeasuringPoint, MeasuringPointFilterModel } from '../commons/models/measuring-point.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiDomain = environment.apiDomain;
  initiativesUrl = `${this.apiDomain}/api/initiatives`;
  initiativesCalculateUrl = `${this.apiDomain}/api/initiatives/add/data`;
  organizationalUnitUrl = `${this.apiDomain}/api/units`;
  statusUrl = `${this.apiDomain}/api/statuses`;
  priorityUrl = `${this.apiDomain}/api/priorities`;
  historicalDataUrl = `${this.apiDomain}/api/history`;
  historicalDataFirstYearUrl = `${this.apiDomain}/api/history/details`;
  historicalDataExistsUrl = `${this.apiDomain}/api/history/exists`;

  scenariosUrl = `${this.apiDomain}/api/scenarios`;
  scenariosMasterUrl = `${this.apiDomain}/api/scenarios/add`;
  scenariosWorkUrl = `${this.apiDomain}/api/scenarios/add/details`;
  scenariosCalculateUrl = `${this.apiDomain}/api/scenarios/add/data`;

  scenariosUpdateMasterUrl = `${this.apiDomain}/api/scenarios/update`;
  scenariosUpdateWorkUrl = `${this.apiDomain}/api/scenarios/update/details`;
  scenariosUpdateCalculateUrl = `${this.apiDomain}/api/scenarios/update/data`;

  templatesUrl = `${this.apiDomain}/api/templates`;
  templatesMasterUrl = `${this.apiDomain}/api/templates/add`;
  templatesWorkUrl = `${this.apiDomain}/api/templates/add/details`;
  templatesCalculateUrl = `${this.apiDomain}/api/templates/add/data`;

  templatesUpdateMasterUrl = `${this.apiDomain}/api/templates/update`;
  templatesUpdateWorkUrl = `${this.apiDomain}/api/templates/update/details`;
  templatesUpdateCalculateUrl = `${this.apiDomain}/api/templates/update/data`;

  pseGetUrl = `${this.apiDomain}/api/pse`;
  pseAvailableGetUrl = `${this.apiDomain}/api/pse-available`;
  pseCandidatesUrl = `${this.apiDomain}/api/pse-candidates`;
  pseOthersUrl = `${this.apiDomain}/api/pse-others`;
  pseMethodDocumentUrl = `${this.apiDomain}/api/pse-method-document`;
  pseAddUrl = `${this.apiDomain}/api/pse/add`;
  pseActivityStatusChangeUrl = `${this.apiDomain}/api/pse/activity-status-change`;
  pseUpdateUrl = `${this.apiDomain}/api/pse/update`;
  pseDeleteUrl = `${this.apiDomain}/api/pse/delete`;
  pseFiltersInUseUrl = `${this.apiDomain}/api/pse/filters/in-use`;
  pseFiltersAvailableUrl = `${this.apiDomain}/api/pse/filters/available`;
  pseFiltersCandidatesUrl = `${this.apiDomain}/api/pse/filters/candidates`;
  pseFiltersOthersUrl = `${this.apiDomain}/api/pse/filters/others`;
  pseGetByUniqueKeyUrl = `${this.apiDomain}/api/pse/uniquekey`;

  codGetUrl = `${this.apiDomain}/api/cod`;
  codGetByUniqueKeyUrl = `${this.apiDomain}/api/cod/uniquekey`;
  codAddUrl = `${this.apiDomain}/api/cod/add`;
  codUpdateUrl = `${this.apiDomain}/api/cod/update`;
  codDeleteUrl = `${this.apiDomain}/api/cod/delete`;
  codFiltersUrl = `${this.apiDomain}/api/cod/filters`;
  codExclusionFiltersUrl = `${this.apiDomain}/api/cod-exclusion/filters`;
  exclusionFiltersUrl = `${this.apiDomain}/api/exclusions/filters`;
  exclusionDropdownsUrl = `${this.apiDomain}/api/exclusions/dropdowns`;
  codExclusionRuleAddUrl = `${this.apiDomain}/api/cod-exclusion-rule/add`;
  codExclusionRuleUpdateUrl = `${this.apiDomain}/api/cod-exclusion-rule/update`;
  codExclusionRuleDeleteUrl = `${this.apiDomain}/api/cod-exclusion-rule/delete`;
  codExclusionRuleGetUrl = `${this.apiDomain}/api/cod-exclusion-rule`;
  exclusionsGetUrl = `${this.apiDomain}/api/customers-exclusions`;
  codExclusionActivityStatusChangeUrl = `${this.apiDomain}/api/customers-exclusions/activity-status-change`;

  capGetUrl = `${this.apiDomain}/api/cap`;
  capGetByUniqueKeyUrl = `${this.apiDomain}/api/cap/uniquekey`;
  capAddUrl = `${this.apiDomain}/api/cap/add`;
  capUpdateUrl = `${this.apiDomain}/api/cap/update`;
  capDeleteUrl = `${this.apiDomain}/api/cap/delete`;
  capFiltersUrl = `${this.apiDomain}/api/cap/filters`;

  unspscSearchUrl = `${this.apiDomain}/api/unspsc/search`;
  organizationSearchUrl = `${this.apiDomain}/api/organization/search`;
  organizationTreeUrl = `${this.apiDomain}/api/organizations-tree`;
  unspscGetUrl = `${this.apiDomain}/api/unspsc/get`;

  exportsUrl = `${this.apiDomain}/api/exports`;
  orderExportUrl = `${this.apiDomain}/api/order`;
  exportPDFGetFileUrl = `${this.apiDomain}/api/file`;

  downloadScenariosUrl = `${this.apiDomain}/api/scenarios/download`;

  fileUploadUrl = `${this.apiDomain}/api/file/upload`;
  fileUploadWithDtoUrl = `${this.apiDomain}/api/file/upload-with-dto`;
  codCsvImportUrl = `${this.apiDomain}/api/cod/import`;
  exclusionsCsvImportUrl = `${this.apiDomain}/api/customers-exclusions/import`;

  pseCsvImportUrl = `${this.apiDomain}/api/pse/import`;

  languageSetUrl = `${this.apiDomain}/api/language`;

  suppliersUrl = `${this.apiDomain}/api/suppliers`;
  suppliersPagedUrl = `${this.apiDomain}/api/suppliers-paged`;

  unspscUnitsUrl = `${this.apiDomain}/api/unspscunits/all`;
  emissionSuppliersUrl = `${this.apiDomain}/api/emissionsuppliers/all`;
  emissionItemsUrl = `${this.apiDomain}/api/emissionitems`;

  tenantCodeUrl = `${this.apiDomain}/api/tenantcode`;

  ghgCategoriesUrl = `${this.apiDomain}/api/ghg-categories`;

  getBookmarkUrl = `${this.apiDomain}/api/bookmarks`;
  addBookmarkUrl = `${this.apiDomain}/api/bookmarks/add`;
  deleteBookmarkUrl = `${this.apiDomain}/api/bookmarks/delete`;
  updateBookmarkUrl = `${this.apiDomain}/api/bookmarks/update`;
  getDefaultReportUrl = `${this.apiDomain}/api/workspaces/reports/embedtoken`;

  reportFilterMapUrl = `${this.apiDomain}/api/reportFilter/map-pbi-filter`;
  reportFilterTranslationsUrl = `${this.apiDomain}/api/reportFilter/get-translations`;

  documentationDetailsUrl = `${this.apiDomain}/api/documentation`;
  documentationLanguagesUrl = `${this.apiDomain}/api/documentation/languages`;

  unspscUnitUrl = `${this.apiDomain}/api/unspscunit`;

  measuringPointsUrl = `${this.apiDomain}/api/measuring-point`;

  measuringPointsFiltersUrl = `${this.apiDomain}/api/measuring-points/filters`;
  measuringPointAddUrl = `${this.apiDomain}/api/measuring-point/add`;
  measuringPointUpdateUrl = `${this.apiDomain}/api/measuring-point/update`;
  measuringPointDeleteUrl = `${this.apiDomain}/api/measuring-point/delete`;
  measuringPointGetUrl = `${this.apiDomain}/api/measuring-point/get`;

  fiscalYearsFiltersUrl = `${this.apiDomain}/api/fiscal-years/filters`;
  fiscalYearsUrl = `${this.apiDomain}/api/fiscal-year`;
  fiscalYearAddUrl = `${this.apiDomain}/api/fiscal-year/add`;
  fiscalYearUpdateUrl = `${this.apiDomain}/api/fiscal-year/update`;
  fiscalYearDeleteUrl = `${this.apiDomain}/api/fiscal-year/delete`;
  fiscalYearGetUrl = `${this.apiDomain}/api/fiscal-year/get`;
  fiscalYearUniqueUrl = `${this.apiDomain}/api/fiscal-year/uniquekey`;

  generateXbrlForFiscalYearUrl = `${this.apiDomain}/api/generate-xbrl`;

  timezone: string;

  constructor(private http: HttpClient, private informationDialogService: InformationDialogService) {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getInitiatives(): Observable<InitiativeMasterDto[] | EntityTrackerError> {
    return this.http
      .get<InitiativeMasterDto[]>(this.initiativesUrl, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getHistoricalDataPoints(
    searchTrendPointModelDto: SearchTrendPointModelDto
  ): Observable<HistoricalDataResponseDto[]> {
    return this.http.post<HistoricalDataResponseDto[]>(
      this.historicalDataUrl,
      searchTrendPointModelDto
    );
  }

  getHistoricalDataFirstYear(): Observable<number> {
    return this.http.get<number>(this.historicalDataFirstYearUrl, {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    });
  }

  checkIfHistoricalDataExists(): Observable<boolean> {
    return this.http.get<boolean>(this.historicalDataExistsUrl, {
      headers: new HttpHeaders({
        Accept: '*/*',
      }),
    });
  }

  getOrganizationalUnit(): Observable<
    OrganizationalUnit[] | EntityTrackerError
  > {
    return this.http
      .get<OrganizationalUnit[]>(this.organizationalUnitUrl, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getOrganizationsTreeData(): Observable<
    ItemNode[] | EntityTrackerError> {
    return this.http
      .get<ItemNode[]>(this.organizationTreeUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getStatuses(): Observable<StatusDto[] | EntityTrackerError> {
    return this.http
      .get<StatusDto[]>(this.statusUrl, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getPriorities(): Observable<PriorityDto[] | EntityTrackerError> {
    return this.http
      .get<PriorityDto[]>(this.priorityUrl, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addInitiativeMaster(
    initiative: InitiativeMasterDto
  ): Observable<any | EntityTrackerError> {
    return this.http
      .post<any>(this.initiativesUrl + '/add', initiative)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addInitiativeWorkCalculations(
    initiativeWorkDtos: InitiativeWorkDto[]
  ): Observable<void | EntityTrackerError> {
    return this.http
      .post<any>(this.initiativesUrl + '/add/details', initiativeWorkDtos)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addInitiativeCalculations(scenarioId: number, masterInitiativeId: number
  ): Observable<void | EntityTrackerError> {
    return this.http
      .post<any>(this.initiativesCalculateUrl, { scenarioId: scenarioId, masterInitiativeId: masterInitiativeId })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updateInitiativeMaster(
    dwSkOld: number,
    initiative: InitiativeMasterDto
  ): Observable<any | EntityTrackerError> {
    return this.http
      .put<any>(`${this.initiativesUrl}/update/${dwSkOld}`, initiative)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updateInitiativeWorkCalculations(
    dwSkOld: number,
    initiativeWorkDtos: InitiativeWorkDto[]
  ): Observable<any | EntityTrackerError> {
    return this.http
      .post<any>(
        `${this.initiativesUrl}/update/details/${dwSkOld}`,
        initiativeWorkDtos
      )
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  deleteInitiative(dwSk: number): Observable<any | EntityTrackerError> {
    return this.http
      .delete<any>(`${this.initiativesUrl}/${dwSk}`)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getScenarios(): Observable<CalculateDto[] | EntityTrackerError> {
    return this.http
      .get<CalculateDto[]>(this.scenariosUrl, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addScenarioMaster(
    scenario: CalculateMasterDto
  ): Observable<number | EntityTrackerError> {
    return this.http
      .post<any>(this.scenariosMasterUrl, scenario)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addScenarioWorkCalculations(
    CalculateWorkDtos: CalculateWorkDto[]
  ): Observable<void | EntityTrackerError> {
    return this.http
      .post<any>(this.scenariosWorkUrl, CalculateWorkDtos)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addcalculations(
    scenarioCalculateDtos: CalculateDto[]
  ): Observable<void | EntityTrackerError> {
    return this.http
      .post<any>(this.scenariosCalculateUrl, scenarioCalculateDtos)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updateMasterScenario(
    dwskOld: number,
    scenario: CalculateMasterDto
  ): Observable<any | EntityTrackerError> {
    return this.http
      .put<any>(`${this.scenariosUpdateMasterUrl}/${dwskOld}`, scenario)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updateWorkScenario(
    dwskOld: number,
    CalculateWorkDtos: CalculateWorkDto[]
  ): Observable<any | EntityTrackerError> {
    console.log(
      'Update CalculateWorkDtos id',
      dwskOld,
      'CalculateWorkDtos',
      CalculateWorkDtos
    );
    return this.http
      .post<any>(`${this.scenariosUpdateWorkUrl}/${dwskOld}`, CalculateWorkDtos)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updatecalculations(
    dwskOld: number,
    scenarioCalculateDtos: CalculateDto[]
  ): Observable<any | EntityTrackerError> {
    console.log(
      'Update scenarioCalculateDtos dwskold',
      dwskOld,
      'scenarioCalculateDtos',
      scenarioCalculateDtos
    );
    return this.http
      .post<any>(
        `${this.scenariosUpdateCalculateUrl}/${dwskOld}`,
        scenarioCalculateDtos
      )
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  deleteScenario(dwSk: number): Observable<any | EntityTrackerError> {
    return this.http
      .delete<any>(`${this.scenariosUrl}/${dwSk}`)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getTemplates(): Observable<CalculateDto[] | EntityTrackerError> {
    return this.http
      .get<CalculateDto[]>(this.templatesUrl, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addTemplateMaster(
    template: CalculateMasterDto
  ): Observable<number | EntityTrackerError> {
    return this.http
      .post<any>(this.templatesMasterUrl, template)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addTemplateWorkCalculations(
    CalculateWorkDtos: CalculateWorkDto[]
  ): Observable<void | EntityTrackerError> {
    return this.http
      .post<any>(this.templatesWorkUrl, CalculateWorkDtos)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addTemplateCalculations(
    CalculateDtos: CalculateDto[]
  ): Observable<void | EntityTrackerError> {
    return this.http
      .post<any>(this.templatesCalculateUrl, CalculateDtos)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updateMasterTemplate(
    dwskOld: number,
    template: CalculateMasterDto
  ): Observable<any | EntityTrackerError> {
    return this.http
      .put<any>(`${this.templatesUpdateMasterUrl}/${dwskOld}`, template)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updateWorkTemplate(
    dwSkOld: number,
    CalculateWorkDtos: CalculateWorkDto[]
  ): Observable<any | EntityTrackerError> {
    return this.http
      .post<any>(`${this.templatesUpdateWorkUrl}/${dwSkOld}`, CalculateWorkDtos)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updateTemplateCalculations(
    dwSkOld: number,
    CalculateDtos: CalculateDto[]
  ): Observable<any | EntityTrackerError> {
    return this.http
      .post<any>(
        `${this.templatesUpdateCalculateUrl}/${dwSkOld}`,
        CalculateDtos
      )
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  deleteTemplate(dwSk: number): Observable<any | EntityTrackerError> {
    return this.http
      .delete<any>(`${this.templatesUrl}/${dwSk}`)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  searchUnspsc(
    searchModel: SearchModelDto
  ): Observable<UnspscViewModel[] | EntityTrackerError> {
    return this.http
      .post<UnspscViewModel[]>(this.unspscSearchUrl, searchModel)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  searchOrganization(
    searchModel: SearchModelDto
  ): Observable<OrganizationalUnit[] | EntityTrackerError> {
    return this.http
      .post<OrganizationalUnit[]>(this.organizationSearchUrl, searchModel)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addPse(pse: ProductSpecificEmissionDto, file?: File): Observable<number | EntityTrackerError> {
    let headers = new HttpHeaders({ 'Timezone': this.timezone });

    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }
    formData.append('request', JSON.stringify(pse));

    return this.http
      .post<any>(this.pseAddUrl, formData, {
        headers: headers,
        context: new HttpContext().set(BYPASS_REDIRECT, true),
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updatePse(updateModel: ProductSpecificEmissionDto, file?: File): Observable<number | EntityTrackerError> {
    let headers = new HttpHeaders({ 'Timezone': this.timezone });

    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }
    formData.append('request', JSON.stringify(updateModel));

    return this.http
      .put<any>(this.pseUpdateUrl, formData, {
        headers: headers,
        context: new HttpContext().set(BYPASS_REDIRECT, true),
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  pseActivityStatusChange(ids: number[], enable: boolean): Observable<any[] | EntityTrackerError> {
    return this.http
      .post<any[]>(this.pseActivityStatusChangeUrl, { Ids: ids, Enable: enable })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  deletePse(ids: number[], options: any): Observable<any> {
    return this.http
      .delete<any>(this.pseDeleteUrl, options);
  }

  getPse(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    return this.http
      .post<PageResponse>(this.pseGetUrl, pageModel)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getAvailablePse(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    return this.http
      .post<PageResponse>(this.pseAvailableGetUrl, pageModel)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getCandidatesPse(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    return this.http
      .post<PageResponse>(this.pseCandidatesUrl, pageModel)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getOtherPse(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    return this.http
      .post<PageResponse>(this.pseOthersUrl, pageModel)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getPseMethodDocument(id: number): Observable<any> {
    return this.http
      .get(`${this.pseMethodDocumentUrl}/${id}`, { responseType: 'blob' })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getPseInUseFilters(): Observable<PseFilterModelDao | EntityTrackerError> {
    return this.http
      .get<PseFilterModelDao>(this.pseFiltersInUseUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getPseAvailableFilters(): Observable<PseFilterModelDao | EntityTrackerError> {
    return this.http
      .get<PseFilterModelDao>(this.pseFiltersAvailableUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getPseCandidatesFilters(): Observable<PseFilterModelDao | EntityTrackerError> {
    return this.http
      .get<PseFilterModelDao>(this.pseFiltersCandidatesUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getPseOthersFilters(): Observable<PseFilterModelDao | EntityTrackerError> {
    return this.http
      .get<PseFilterModelDao>(this.pseFiltersOthersUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getPseByUniqueKey(model: PseUniqueKeyModel) {
    let headers = new HttpHeaders({ 'Timezone': this.timezone });

    return this.http
      .post<UniqueEntityValidationResponse<ProductSpecificEmissionDto>>(this.pseGetByUniqueKeyUrl, model, { headers: headers })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addCod(cod: CustomersOwnDataDto): Observable<number | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<any>(this.codAddUrl, cod, { headers: headers })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updateCod(
    updateModel: CustomersOwnDataDto
  ): Observable<number | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .put<any>(this.codUpdateUrl, updateModel, { headers: headers })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  deleteCod(ids: number[]): Observable<any | EntityTrackerError> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { Ids: ids },
    };

    return this.http
      .delete<any>(this.codDeleteUrl, options)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getCod(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<PageResponse>(this.codGetUrl, pageModel, {
        headers
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addCodExclusion(cod: CustomersExclusionRuleDto): Observable<number | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<any>(this.codExclusionRuleAddUrl, cod, {
        headers: headers,
        context: new HttpContext().set(BYPASS_REDIRECT, true)
      });
  }

  updateCodExclusion(
    updateModel: CustomersExclusionRuleDto
  ): Observable<number | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .put<any>(this.codExclusionRuleUpdateUrl, updateModel, {
        headers: headers,
        context: new HttpContext().set(BYPASS_REDIRECT, true)
      });
  }

  deleteCodExclusion(ids: number[]): Observable<any | EntityTrackerError> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { Ids: ids },
    };

    return this.http
      .delete<any>(this.codExclusionRuleDeleteUrl, options)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getCodExclusion(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<PageResponse>(this.codExclusionRuleGetUrl, pageModel, {
        headers
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getAllCustomersExclusions(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<PageResponse>(this.exclusionsGetUrl, pageModel, {
        headers
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  codExclusionsActivityStatusChange(ids: number[], enable: boolean): Observable<any[] | EntityTrackerError> {
    return this.http
      .post<any[]>(this.codExclusionActivityStatusChangeUrl, { Ids: ids, Enable: enable })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getCodByUniqueKey(model: CodUniqueKeyModel) {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<CustomersOwnDataDto>(this.codGetByUniqueKeyUrl, model, {
        headers: headers,
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getCodFilters(): Observable<CodApiFilterModelDao | EntityTrackerError> {
    return this.http
      .get<CodApiFilterModelDao>(this.codFiltersUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getCodExclusionFilters(): Observable<CodExclusionFilterModel | EntityTrackerError> {
    return this.http
      .get<CodExclusionFilterModel>(this.codExclusionFiltersUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getExclusionsFilters(): Observable<ExclusionsFilterModel | EntityTrackerError> {
    return this.http
      .get<ExclusionsFilterModel>(this.exclusionFiltersUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getExclusionsDropdowns(): Observable<ExclusionsDropdownModel | EntityTrackerError> {
    return this.http
      .get<ExclusionsDropdownModel>(this.exclusionDropdownsUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addCap(cap: CustomersAveragePriceDto): Observable<number | EntityTrackerError> {
    let headers = new HttpHeaders({ 'Timezone': this.timezone });

    return this.http
      .post<any>(this.capAddUrl, cap, { headers: headers })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updateCap(updateModel: CustomersAveragePriceDto): Observable<number | EntityTrackerError> {
    let headers = new HttpHeaders({ 'Timezone': this.timezone });

    return this.http
      .put<any>(this.capUpdateUrl, updateModel, { headers: headers })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  deleteCap(ids: number[]): Observable<any | EntityTrackerError> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { Ids: ids, },
    };

    return this.http
      .delete<any>(this.capDeleteUrl, options)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getCap(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    let headers = new HttpHeaders({ 'Timezone': this.timezone });

    return this.http
      .post<PageResponse>(this.capGetUrl, pageModel, { headers: headers })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getCapByUniqueKey(model: CapUniqueKeyModel) {
    let headers = new HttpHeaders({ 'Timezone': this.timezone });

    return this.http
      .post<CustomersAveragePriceDto>(this.capGetByUniqueKeyUrl, model, { headers: headers })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getCapFilters(): Observable<CapApiFilterModelDao | EntityTrackerError> {
    return this.http
      .get<CapApiFilterModelDao>(this.capFiltersUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getSuppliers(): Observable<SupplierDto[] | EntityTrackerError> {
    return this.http
      .get<SupplierDto[]>(this.suppliersUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getSuppliersPaged(pageModel: any): Observable<SupplierDto[] | EntityTrackerError> {
    return this.http
      .post<SupplierDto[]>(this.suppliersPagedUrl, pageModel)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getUnspscWithUnits(supplierId?: string, supplierCountry?: string): Observable<UnspscUnitDto[] | EntityTrackerError> {
    let url = this.unspscUnitsUrl;
    if (supplierId && supplierCountry) {
      url = `${this.unspscUnitsUrl}/${supplierId}/${supplierCountry}`;
    }

    return this.http
      .get<UnspscUnitDto[]>(url)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getEmissionSuppliers(): Observable<SupplierDto[] | EntityTrackerError> {
    return this.http
      .get<SupplierDto[]>(this.emissionSuppliersUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getEmissionItems(supplierId: string, supplierCountry: string): Observable<EmissionSupplierItemDto[] | EntityTrackerError> {
    return this.http
      .get<EmissionSupplierItemDto[]>(`${this.emissionItemsUrl}/${supplierId}/${supplierCountry}`)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  downloadScenarios(scenario: CalculateDto) {
    this.http
      .post(this.downloadScenariosUrl, scenario, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe((response: HttpResponse<Blob>) => {
        const contentDispositionHeader = response.headers.get(
          'content-disposition'
        );
        let regExp = 'filename[^;=\\n]*=(([\'"]).*?\\2|[^;\\n]*)';
        let fileName = contentDispositionHeader.match(regExp);
        const file = new File([response.body], fileName[1], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(file);
      });
  }

  uploadDatasource(payload) {
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json, text/plain,');
    return this.http.post(this.fileUploadUrl, payload, {
      headers: headers,
      context: new HttpContext().set(BYPASS_REDIRECT, true),
    });
  }

  uploadDatasourceWithDto(file: File, additionalData: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('additionalData', JSON.stringify(additionalData));

    return this.http.post<any>(this.fileUploadWithDtoUrl, formData);
  }

  importCodCsv(fileName: string, languageCode: string) {
    const body = { fileName: fileName, languageCode: languageCode };
    return this.http.post(this.codCsvImportUrl, body, {
      context: new HttpContext().set(BYPASS_REDIRECT, true),
    });
  }

  importExclusionsCsv(fileName: string, languageCode: string) {
    const body = { fileName: fileName, languageCode: languageCode };
    return this.http.post(this.exclusionsCsvImportUrl, body, {
      context: new HttpContext().set(BYPASS_REDIRECT, true),
    });
  }

  importPseCsv(fileName: string, languageCode: string) {
    const body = { fileName: fileName, languageCode: languageCode };
    return this.http.post(this.pseCsvImportUrl, body, {
      context: new HttpContext().set(BYPASS_REDIRECT, true),
    });
  }

  getPbiPdfExports(): Observable<ExportDto[] | EntityTrackerError> {
    return this.http
      .get<ExportDto[]>(this.exportsUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  pBIReportExportPostExport(
    orderExportDto: OrderExportDto
  ): Observable<string | EntityTrackerError> {
    return this.http
      .post<any>(this.orderExportUrl, orderExportDto)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  pBIReportExportGetFile(element) {
    return this.http.post(
      this.exportPDFGetFileUrl,
      { exportId: element.id },
      {
        observe: 'response',
        responseType: 'blob',
        context: new HttpContext().set(BYPASS_REDIRECT, true)
      })
      .pipe(catchError((err) => this.handleFileRequestError(err, element.id)));
  }

  setLanguage(language: string) {
    let formData: FormData = new FormData();
    formData.append('language', language);

    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json, text/plain,');
    return this.http.put(this.languageSetUrl, formData, {
      headers: headers,
      context: new HttpContext().set(BYPASS_REDIRECT, true),
    });
  }

  getUserTenantCode(): Observable<TenantCodeResponse> {
    return this.http.get<TenantCodeResponse>(this.tenantCodeUrl);
  }

  getAllGhgCategories(): Observable<GhgCategory[]> {
    return this.http.get<GhgCategory[]>(this.ghgCategoriesUrl);
  }

  getGhgCategoriesByUnspsc(unspsc: string): Observable<GhgCategoryUnspsc[]> {
    return this.http.get<GhgCategoryUnspsc[]>(`${this.ghgCategoriesUrl}/${unspsc}`);
  }

  getBookmarks(userId: string) {
    return this.http
      .post<Bookmark[]>(this.getBookmarkUrl, { userId })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addBookmark(bookmark: Bookmark) {
    return this.http
      .post<any>(this.addBookmarkUrl, bookmark)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  deleteBookmark(id: number) {
    return this.http
      .delete<any>(`${this.deleteBookmarkUrl}/${id}`)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updateBookmark(bookmark: Bookmark) {
    return this.http
      .put<any>(this.updateBookmarkUrl, bookmark)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getDefultReport(): Observable<ReportData | EntityTrackerError> {
    console.trace();
    return this.http.get<ReportData>(this.getDefaultReportUrl, {
      context: new HttpContext().set(BYPASS_REDIRECT, true)
    });
  }

  getSpecificReport(
    workspaceId: string,
    reportId: string
  ): Observable<ReportData | EntityTrackerError> {
    let urlPart = `/api/workspaces/${workspaceId}/reports/${reportId}/embedtoken`;
    return this.http.get<ReportData>(this.apiDomain + urlPart,
      {
        context: new HttpContext().set(BYPASS_REDIRECT, true)
      });
  }

  mapPbiFilters(pbiFilters: PbiFilter[]): Observable<FilterValues[]> {
    return this.http
      .post<any>(this.reportFilterMapUrl, pbiFilters)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getPbiFilterNameTranslation(reportName: string, columnKeys: string[]): Observable<PbiFilterTranslation> {
    return this.http
      .post<PbiFilterTranslation>(this.reportFilterTranslationsUrl, <PbiFilterTranslation>
        { reportName: reportName, columnKeys: columnKeys.map(x => <ColumnKeyValue>{ columnKey: x }) });
  }

  getDocumentationDetails(languageId: number): Observable<DocumentationDetails[]> {
    return this.http.get<DocumentationDetails[]>(`${this.documentationDetailsUrl}/${languageId}`);
  }

  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(this.documentationLanguagesUrl);
  }

  getUnspscUnit(unspscs: string[]): Observable<UnspscSingleUnitResponse> {
    return this.http.post<UnspscSingleUnitResponse>(
      this.unspscUnitUrl,
      { Unspscs: unspscs }
    );
  }

  getMeasuringPoints(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<PageResponse>(this.measuringPointsUrl, pageModel, {
        headers
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getFiscalYears(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<PageResponse>(this.fiscalYearsUrl, pageModel, {
        headers
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getMeasuringPointsFilters(): Observable<MeasuringPointFilterModel | EntityTrackerError> {
    return this.http
      .get<MeasuringPointFilterModel>(this.measuringPointsFiltersUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getFiscalYearsFilters(): Observable<FiscalYearFilterModel | EntityTrackerError> {
    return this.http
      .get<FiscalYearFilterModel>(this.fiscalYearsFiltersUrl)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  addMeasuringPoint(measuringPoint: MeasuringPoint): Observable<number | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<any>(this.measuringPointAddUrl, measuringPoint, { headers: headers })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updateMeasuringPoint(
    updateModel: MeasuringPoint
  ): Observable<number | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .put<any>(this.measuringPointUpdateUrl, updateModel, { headers: headers })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  deleteMeasuringPoint(ids: number[]): Observable<any | EntityTrackerError> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { Ids: ids },
    };

    return this.http
      .delete<any>(this.measuringPointDeleteUrl, options)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getMeasuringPoint(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<PageResponse>(this.measuringPointGetUrl, pageModel, {
        headers
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  private handleHttpError(
    error: HttpErrorResponse
  ): Observable<EntityTrackerError> {
    // TODO: Error tracker interface to be determined
    let dataError = new EntityTrackerError();
    dataError.errorNumber = 100;
    dataError.message = error.statusText;
    dataError.friendlyMessage = 'An error occured retrieving data.';
    return throwError(dataError);
  }

  addFiscalYear(fiscalYear: FiscalYear): Observable<number | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<any>(this.fiscalYearAddUrl, fiscalYear, { headers: headers })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  updateFiscalYear(
    updateModel: FiscalYear
  ): Observable<number | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .put<any>(this.fiscalYearUpdateUrl, updateModel, { headers: headers })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  deleteFiscalYear(ids: number[]): Observable<any | EntityTrackerError> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { Ids: ids },
    };

    return this.http
      .delete<any>(this.fiscalYearDeleteUrl, options)
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getFiscalYear(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<PageResponse>(this.fiscalYearGetUrl, pageModel, {
        headers
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  getFiscalYearUnique(model: FiscalYearValidationModel): Observable<UniqueEntityValidationResponse<FiscalYear> | EntityTrackerError> {
    let headers = new HttpHeaders({ Timezone: this.timezone });

    return this.http
      .post<UniqueEntityValidationResponse<FiscalYear>>(this.fiscalYearUniqueUrl, model, {
        headers
      })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  generateXbrlForFiscalYear(fiscalYearId: number): Observable<Blob | EntityTrackerError> {
    return this.http
      .get(`${this.generateXbrlForFiscalYearUrl}/${fiscalYearId}`, { responseType: 'blob' })
      .pipe(catchError((err) => this.handleHttpError(err)));
  }

  private handleFileRequestError(error: any, additionalInfo?: string): Observable<never> {
    console.error('An error occurred:', error);

    if (error.error instanceof Blob) {
      return this.handleBlobError(error.error, error.statusText, error.status, additionalInfo ?? '');
    } else {
      return throwError('Something went wrong; please try again later.');
    }
  }

  private handleBlobError(blob: Blob, statusText: string, status: number, additionalInfo?: string): Observable<never> {
    const reader = new FileReader();
    reader.onload = (event) => {
      const errorMessage = event.target?.result as string;
      console.error('Blob Error Message:', errorMessage);
      this.informationDialogService.showPdfDownloadErrorMessage(status, additionalInfo);
    };

    reader.readAsText(blob);

    return throwError('Blob Error: Check console for details.');
  }
}
