import { Injectable } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Observable, of, throwError } from 'rxjs';
import { EntityTrackerError } from '../../../commons/models/entity-tracker-error';
import { PageResponse } from '../../../commons/models/page-response';
import { PageModel } from '../../../commons/models/page-model';
import { IDataService, IGetDataService } from 'src/app/commons/components/data-view/data-service.interface';
import { PseUniqueKeyModel } from 'src/app/commons/models/pse-unique-key-model';
import { ProductSpecificEmissionDto, UniqueEntityValidationResponse } from 'src/app/commons/models/product-specific-emission-dto';
import { EmissionSupplierItemDto } from 'src/app/commons/models/emission-supplier-item-dto';
import { SupplierDto } from 'src/app/commons/models/supplier-dto';
import { DialogService } from 'src/app/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { catchError } from 'rxjs/operators';
import { HttpContext, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BYPASS_REDIRECT } from 'src/app/interceptores/http-response.interceptor';

@Injectable({
  providedIn: 'root',
})
export class ProductSpecificEmissionsGetService implements IGetDataService {
  constructor(private apiService: ApiService, private dialogService: DialogService, private translateService: TranslateService, private languageService: CurrentLanguageService) { }
  get(pageModel: PageModel): Observable<PageResponse | EntityTrackerError> {
    const emptyItemId = pageModel.filters?.filter(x => x.text == this.translateService.instant('product-specific-emissions.item-unknown'))[0];
    if (emptyItemId) {
      emptyItemId.text = null;
      emptyItemId.exactText = '';
    }
    const emptySupplier = pageModel.filters?.filter(x => x.text == this.translateService.instant('product-specific-emissions.empty-supplier'))[0];
    if (emptySupplier) {
      emptySupplier.text = null;
      emptySupplier.exactText = '';
    }
    pageModel.languageCode = this.languageService.lang;
    return this.apiService.getPse(pageModel);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductSpecificEmissionsService implements IDataService {
  constructor(private apiService: ApiService, private dialogService: DialogService, private translateService: TranslateService, private languageService: CurrentLanguageService) { }
  getByUniqueKey(model: PseUniqueKeyModel): Observable<UniqueEntityValidationResponse<ProductSpecificEmissionDto> | EntityTrackerError> {
    return this.apiService.getPseByUniqueKey(model);
  }

  getMethodDocument(id: number): Observable<ArrayBuffer> {
    return this.apiService.getPseMethodDocument(id);
  }

  add(pse: ProductSpecificEmissionDto, file?: File): Observable<number | EntityTrackerError> {
    return this.apiService.addPse(pse, file).pipe(catchError((err) => this.handleBackendError(err)));
  }

  update(updateModel: ProductSpecificEmissionDto, file?: File) {
    return this.apiService.updatePse(updateModel, file).pipe(catchError((err) => this.handleBackendError(err, this.translateService.instant('errors.something-went-wrong-error.subtitle'))));
  }

  delete(ids: number[]): Observable<PageResponse | EntityTrackerError> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'LanguageCode': this.languageService.lang
      }),
      body: { Ids: ids, },
      context: new HttpContext().set(BYPASS_REDIRECT, true)
    };

    return this.apiService.deletePse(ids, options).pipe(catchError((err) => this.handleBackendError(err)));
  }

  async sliderChange(updateModel: ProductSpecificEmissionDto, sliderValue: boolean) {
    return await this.enableDisableRow([updateModel.id], sliderValue);
  }

  async enableDisableRow(ids: number[], enable: boolean) {
    return of(await this.apiService.pseActivityStatusChange(ids, enable).toPromise().then(conflictedRows => {
      if ((conflictedRows as any[]).length > 0) {
        this.dialogService.inform({
          message: this.translateService.instant(
            'product-specific-emissions.duplicates-warning'
          ),
        });
      }
    }));
  }

  getEmissionSuppliers(): Observable<SupplierDto[] | EntityTrackerError> {
    return this.apiService.getEmissionSuppliers();
  }

  getEmissionItems(supplierId: string, supplierCountry: string): Observable<EmissionSupplierItemDto[] | EntityTrackerError> {
    return this.apiService.getEmissionItems(supplierId, supplierCountry);
  }

  private handleBackendError(
    error: HttpErrorResponse,
    friendlyMessage?: string
  ): Observable<EntityTrackerError> {
    this.dialogService.inform({
      message:
        friendlyMessage ?? error.error
    });

    let dataError = new EntityTrackerError();
    dataError.errorNumber = 100;
    dataError.message = error.statusText;
    dataError.friendlyMessage = 'An error occured retrieving data.';
    return throwError(dataError);
  }
}
