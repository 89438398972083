import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-empty-home-screen',
    templateUrl: './empty-home-screen.component.html',
    styleUrls: ['./empty-home-screen.component.scss'],
    standalone: false
})
export class EmptyHomeScreenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
