import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CreateEditInitiativeDialogComponent } from '@modules/initiatives/components/create-edit-initiative-dialog/create-edit-initiative-dialog.component';
import { UserService } from '@modules/shell/services/user.service';
import { Subscription } from 'rxjs';
import { UnspscHandlerService } from '../../commons/components/unspsc-handler/unspsc-handler.service';
import { CalculatorService } from '@modules/scenarios/services/calculator/calculator.service';

@Injectable({
  providedIn: 'root',
})
export class InitiativesFormService implements OnDestroy {
  public dialogRef: MatDialogRef<CreateEditInitiativeDialogComponent>;
  public form!: FormGroup;
  private editData: any;
  currentYear = new Date().getFullYear().toString();
  public dropdownYears: string[];
  public dropdownCurrentYear = [this.currentYear];
  private simulationCalculatorSubscription: Subscription;

  constructor(
    public formBuilder: FormBuilder,
    private userService: UserService,
    public calculatorService: CalculatorService,
    public unspscsHandlerService: UnspscHandlerService
  ) {
    this.dropdownYears = this.getYears();
  }

  ngOnDestroy() {
    this.simulationCalculatorSubscription.unsubscribe();
  }

  initializeForm(): void {
    this.form = this.formBuilder.group({
      initiativeName: ['', Validators.required],
      initiativeDescription: ['', Validators.required],
      scenarioName: ['',],
      scenarioDescription: [''],
      scenarioEmail: [''],
      initiativeExpectedExpenseChange: [
        '',
        Validators.pattern('^-?[0-9]\\d*(\\.\\d+)?$'),
      ],
      initiativeExpectedEmissionChange: [
        '',
        Validators.pattern('^-?[0-9]\\d*(\\.\\d+)?$'),
      ],
      initiativePriority: ['', Validators.required],
      initiativeStartYear: [
        { value: this.currentYear, disabled: true },
        Validators.required,
      ],
      initiativeEndYear: [
        { value: (Number(this.currentYear) + 1).toString(), disabled: true },
        Validators.required,
      ],
      initiativeStatus: ['', Validators.required],
      initiativeCreateDate: [
        { value: new Date(), disabled: true },
        Validators.required,
      ],
      initiativeEmail: [
        { value: this.userService.currentUser.email, disabled: true },
        Validators.required,
      ],
    });

    if (this.editData) {
      let lastYear = this.unspscsHandlerService.getLastYear(
        this.editData.unspscs,
        this.currentYear
      );

      this.form.patchValue({
        initiativeId: this.editData.initiativeId,
        initiativeName: this.editData.initiativeName,
        initiativeDescription: this.editData.initiativeDescription,
        scenarioName: this.editData.scenarioName,
        scenarioDescription: this.editData.scenarioDescription,
        scenarioEmail: this.editData.scenarioEmail,
        initiativePriority:
          this.editData.initiativePriority.dW_SK_Priority.toString(),
        initiativeStartYear: new Date(this.editData.initiativeStartYear)
          .getFullYear()
          .toString(),
        initiativeEndYear: lastYear,
        initiativeStatus:
          this.editData.initiativeStatus.dW_SK_Status.toString(),
        unspscs: this.editData.unspscs,
        initiativeCreateDate: new Date(this.editData.initiativeCreateDate),
        initiativeEmail: this.editData.initiativeEmail,
      });
    }
  }

  setDialogRef(dialogRef) {
    this.dialogRef = dialogRef;
  }

  setEditData(editData) {
    this.editData = editData;
  }

  resetEditData() {
    this.editData = undefined;
  }

  resetForm() {
    this.form.reset();
  }

  getYears(): string[] {
    let currentYear = new Date().getFullYear();

    let yearArray = [];
    for (let i = currentYear + 1; i < currentYear + 5; i++) {
      yearArray.push(`${i}`);
    }

    return yearArray;
  }

  simulate() {
    if (this.form.valid) {
      this.simulationCalculatorSubscription = this.calculatorService
        .calculateSaveScenarioAsInitiative(this.form.value)
        .subscribe((response) => {
          this.form.patchValue({
            initiativeExpectedExpenseChange: Math.round(
              response.totalExpenseChange
            ),
            initiativeExpectedEmissionChange: Math.round(
              response.totalEmissionChange
            ),
          });
        });
    }
  }

  setLastYear(lastYear) {
    this.form.patchValue({
      initiativeEndYear: lastYear,
    });
  }
}
