<div class="container">
  <div class="row align-items-center">
    <div class="col-12 mb-5">
      <div class="row">
        <div class="col-12 mt-5">
          <div class="row">
            <div class="col-4">
              <img class="mt-3 mb-3" id="kmd-logo" src="../../../../../assets/images/carbonkey-rgb-v2.svg" />
              <h1 class="col-12 mt-5">
                {{ 'navigation.help' | translate }}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div [innerHtml]="mainText" (click)="navigate($event)" class="col-12 mt-5" style="font-size: 15.5px;">
      </div>

      <div class="example-container mat-elevation-z8" style="margin-top: 2rem;">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="question">
            <th mat-header-cell *matHeaderCellDef class="question-cell"> {{ 'faq.question' | translate }} </th>
            <td mat-cell *matCellDef="let element" class="question-cell"> {{element.question}} </td>
          </ng-container>
          <ng-container matColumnDef="answer">
            <th mat-header-cell *matHeaderCellDef> {{ 'faq.answer' | translate }} </th>
            <td mat-cell *matCellDef="let element" [innerHtml]="element.answer">
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

    </div>
  </div>
</div>
