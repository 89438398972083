import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { ROUTENAMES } from 'src/app/app-routing.names';
import { Filter } from 'src/app/commons/models/report/filter.model';
import { FilterValues } from 'src/app/commons/models/report/filter-values.model';
import { Period } from 'src/app/commons/models/report/period.model';
import { QueryParamsVisibleColumnsService } from './query-params-visible-columns.service';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsFilterService {
  private paramQuerySource$: BehaviorSubject<Filter>;
  public paramQuery: Observable<Filter>;
  private startUrl: string = ROUTENAMES.REPORT;
  private oldParams: Params;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private queryParamsVisisbleColumnsService: QueryParamsVisibleColumnsService
  ) {
    this.initBehaviorSubject();
    this.createFilterFromSnapShot();
  }

  private initBehaviorSubject() {
    const filter: Filter = new Filter();
    filter.filterValues = [];
    filter.timePeriod = this.getDefaultPeriod();
    this.paramQuerySource$ = new BehaviorSubject<Filter>(filter);
    this.paramQuery = this.paramQuerySource$.asObservable();
  }

  private createFilterFromSnapShot() {
    this.route.queryParams.subscribe((paramsValues: Params) => {
      const newparamsValues = Object.keys(paramsValues)
        .filter((key) => key !== '_invisible')
        .reduce((obj, key) => {
          obj[key] = paramsValues[key];
          return obj;
        }, {});
      if (
        this.oldParams == undefined ||
        JSON.stringify(this.oldParams) != JSON.stringify(newparamsValues)
      ) {
        const filter = this.paramQuerySource$.value;
        if (Object.keys(paramsValues).length !== 0) {
          filter.filterValues = [];
        }
        Object.keys(paramsValues).forEach((key) => {
          if (filter.timePeriod.hasOwnProperty(key)) {
            filter.timePeriod[key] = paramsValues[key];
          } else {
            const inverted = key.split('_');

            if (inverted[inverted.length - 1] !== 'inv') {
              const invertKey = paramsValues[key + '_inv'] ?? undefined;
              const filterValue: FilterValues = {
                key,
                values: paramsValues[key].split(',,') ?? undefined,
                invert: false,
              };

              if (invertKey !== undefined) {
                filterValue.invert = true;
              }

              if (
                !this.isTypeExisting(key) &&
                filterValue.values !== undefined
              ) {
                filter.filterValues.push(filterValue);
              }
            }
          }
        });
        if (this.isNotEqualWithSnapshot(filter.filterValues))
          this.paramQuerySource$.next(filter);
      }
      this.oldParams = newparamsValues;
    });
  }

  public resetSnapshotqueryParamToDefault() {
    const filter: Filter = this.paramQuerySource$.value;
    filter.filterValues = [];
    filter.timePeriod = this.getDefaultPeriod();
    this.updateURL(filter, true);
  }

  public clearFilterValues(except: string[] = []) {
    const filter: Filter = this.paramQuerySource$.value;
    filter.filterValues = filter.filterValues.filter((f) =>
      except.includes(f.key)
    );
    this.updateURL(filter, false);
  }

  private getDefaultPeriod(): Period {
    let periodEndDate = new Date();
    periodEndDate.setMonth(periodEndDate.getMonth() - 1);
    let periodStartDate = new Date();
    periodStartDate.setMonth(periodStartDate.getMonth() - 12);
    return {
      from: this.convertDate(this.setFirstDayOfMonth(periodStartDate)),
      to: this.convertDate(this.setLastDayOfMonth(periodEndDate)),
    };
  }

  public updatePeriodAndSetDaysOfMonths(from: Date, to: Date): void {
    const filter: Filter = this.paramQuerySource$.value;

    filter.timePeriod = {
      from: this.convertDate(this.setFirstDayOfMonth(from)),
      to: this.convertDate(this.setLastDayOfMonth(to)),
    } as Period;
    this.updateURL(filter);
  }

  public updatePeriod(from: Date, to: Date): void {
    const filter: Filter = this.paramQuerySource$.value;

    filter.timePeriod = {
      from: this.convertDate(from),
      to: this.convertDate(to),
    };
    this.updateURL(filter);
  }

  public setFirstDayOfMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  public setLastDayOfMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  public convertDate(date: Date): string {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    } as Intl.DateTimeFormatOptions;

    return date.toLocaleString('en-GB', options).split('/').reverse().join('-');
  }

  public updateFilterValues(
    filterType: string,
    filterIds: string[],
    invert: boolean = false
  ): void {
    const filter: Filter = this.paramQuerySource$.value;

    if (filterIds !== undefined) {
      if (this.isTypeExisting(filterType)) {
        const index = this.getIndex(filterType);
        if (
          filterIds.length === 0 ||
          (filterIds.length === 1 && filterIds[0].length === 0)
        ) {
          filter.filterValues.splice(index, 1);
        } else {
          filter.filterValues[index].values = filterIds;
          filter.filterValues[index].invert = invert;
        }
      } else if (
        filterIds.length > 1 ||
        (filterIds.length === 1 && filterIds[0].length > 0)
      ) {
        const filtervalue: FilterValues = {
          key: filterType,
          values: filterIds,
          invert,
        };
        filter.filterValues.push(filtervalue);
      }
    }
    this.updateURL(filter);
  }

  private getIndex(filterType: string): number {
    const filter: Filter = this.paramQuerySource$.value;
    return filter.filterValues.map((keys) => keys.key).indexOf(filterType);
  }

  private isTypeExisting(type: string): boolean {
    const filter: Filter = this.paramQuerySource$.value;
    return filter.filterValues
      .map((filtervalue: FilterValues) => filtervalue.key)
      .includes(type);
  }

  public updateFilter(filter: Filter) {
    this.updateURL(filter);
    this.paramQuerySource$.next(filter);
  }

  public getFilter(): Filter {
    return this.paramQuerySource$.value;
  }

  private updateURL(filter: Filter, refresh: boolean = false): void {
    const queryParams: Params = this.convertToQueryParamsObject(filter);
    if (
      this.queryParamsVisisbleColumnsService.invisibleColumnQueryParams !==
      undefined &&
      this.queryParamsVisisbleColumnsService.invisibleColumnQueryParams
        .length !== 0
    ) {
      queryParams['_invisible'] =
        this.queryParamsVisisbleColumnsService.invisibleColumnQueryParams[
        '_invisible'
        ];
    }
    if (this.isNotEqualWithSnapshot(queryParams) && refresh === false) {
      this.router.navigate([this.startUrl], {
        queryParams,
      });
    } else if (refresh === true) {
      this.router.navigate([this.startUrl], {
        queryParams,
      });
    }
  }

  private isNotEqualWithSnapshot(queryparams: object): boolean {
    const queryParmsSnapShot = this.route.snapshot.queryParams;
    if (JSON.stringify(queryparams) !== JSON.stringify(queryParmsSnapShot)) {
      return true;
    } else {
      return false;
    }
  }

  public changeStartUrl(newStartPath: string, filter: Filter = null): void {
    if (filter === null) {
      filter = this.paramQuerySource$.value;
    }
    this.startUrl = newStartPath;
    if (this.startUrl !== ROUTENAMES.REPORT) {
      filter = this.removeFrontendKeys(filter);
    }

    const queryParams = this.convertToQueryParamsObject(filter);
    this.router.navigate([this.startUrl], {
      queryParams,
    });
  }

  private removeFrontendKeys(filter: Filter): Filter {
    const grouping = '_grouping';
    if (this.isTypeExisting(grouping)) {
      filter.filterValues = filter.filterValues.filter(
        (fv) => !fv.key.startsWith('_')
      );
      return filter;
    } else {
      return filter;
    }
  }

  private convertToQueryParamsObject(filter: Filter): object {
    const URLFriendly: object = {};

    Object.keys(filter.timePeriod).forEach((key) => {
      const periodValue: string = filter.timePeriod[key];
      URLFriendly[key] = periodValue.toString();
    });

    filter.filterValues.forEach((filtervalue) => {
      let filterValue: string;
      if (filtervalue.values !== undefined && filtervalue.values.length !== 0) {
        filterValue = filtervalue.values.join(',,');
        URLFriendly[filtervalue.key] = filterValue;

        if (filtervalue.invert === true) {
          URLFriendly[filtervalue.key + '_inv'] = 1;
        }
      }
    });
    return URLFriendly;
  }

  public updatePbiPageFilters(filterValues: FilterValues[]) {
    let filter: Filter = this.paramQuerySource$.value;
    filter.pageFilterValues = filterValues;
    this.paramQuerySource$.next(filter);
  }
}
