import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { EntityTrackerError } from '../commons/models/entity-tracker-error';
import { catchError } from 'rxjs/operators';
import { ROUTENAMES } from '../app-routing.names';
import { PageResponse } from '../commons/models/page-response';
import { FiscalYearsGetService } from '@modules/customers-csrd/services/fiscal-years/fiscal-years-get.service';

@Injectable({
  providedIn: 'root',
})
export class FiscalYearsResolver
  implements Resolve<PageResponse | EntityTrackerError | boolean> {
  constructor(
    private apiService: ApiService,
    private fiscalYearsGetService: FiscalYearsGetService,
    private router: Router
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PageResponse | EntityTrackerError | boolean> {
    return this.fiscalYearsGetService
      .get({
        search: 1,
        filters: null,
        pageSize: '10',
        // TODO: do a dynamic sorting when it will be available in the Navigation event
        // orderBy: `order by ${navigation.sortColumn} ${navigation.sortDirection}`,
        orderBy: `order by Id asc`,
        page: '0',
      })
      .pipe(
        catchError(() => {
          this.router.navigate([ROUTENAMES.UNAUTHORIZED]);
          return EMPTY;
        })
      );
  }
}
