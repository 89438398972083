import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private isSidebarOpen: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isSidebarOpen$: Observable<boolean> =
    this.isSidebarOpen.asObservable();

  constructor() {}

  toggleSidebar() {
    this.isSidebarOpen.next(!this.isSidebarOpen.value);
  }

  setSidebarState(value: boolean) {
    this.isSidebarOpen.next(value);
  }
}
