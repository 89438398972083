import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  // TODO: merge other file services including pdf exports of pbi reports and xls exports of scenarios to one service
  constructor(private apiService: ApiService) {
  }

  uploadDatasource(payload: any): Observable<any> {
    return this.apiService.uploadDatasource(payload);
  }

  uploadDatasourceWithDto(payload: File, additionalData: any): Observable<any> {
    return this.apiService.uploadDatasourceWithDto(payload, additionalData);
  }
}
