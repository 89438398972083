import { Injectable } from '@angular/core';
import {
  Columns,
  Filter,
  FilterTypes,
  ObjectTypes,
  TranslationKeys,
} from '@dima/table-library';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ITableService } from 'src/app/commons/components/data-view/table-service.interface';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { CustomDecimalPipe } from 'src/app/commons/pipes/custom-decimal.pipe';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DataViewType } from 'src/app/commons/components/create-edit-dialog/data-view-type.enum';

@Injectable({
  providedIn: 'root',
})
export class TableService implements ITableService {
  public cols: Columns[];
  public totalCount: number;
  public pageSize: number = 10;
  public page: number = 0;
  public data = [];
  public langSubscription: Subscription;
  public loading: boolean = false;
  public table: any;
  public dimaTableTranslations: any;
  filters: Filter[] = [];
  pagedModel: any;
  selectedRows: any[];

  clearTableSelection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  anyRowSelectedSubject: BehaviorSubject<boolean>;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private currentLanguageService: CurrentLanguageService,
    private localStorageService: LocalStorageService
  ) {
    this.langSubscription = this.translateService.onLangChange.subscribe(
      (lang) => {
        this.setCols();
        this.router.navigate([this.getCurrentRoute()]);
      }
    );
  }

  setCols() {
    let visibleColumns: string[];
    const savedColumnsToDisplay = this.localStorageService.getItem(DataViewType.CUSTOMERS_AVERAGE_PRICE.toString())?.value;
    if (savedColumnsToDisplay) {
      visibleColumns = JSON.parse(savedColumnsToDisplay) as string[];
    }

    this.dimaTableTranslations = [
      {
        Key: TranslationKeys.globalFilterHeader,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.globalFilterPlaceholder,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.paginatorItemsPerPageLabel,
        Label: this.translateService.instant(
          'general.paginator.per-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.per-page'),
      },
      {
        Key: TranslationKeys.paginatorRangeLabel,
        Label: this.translateService.instant('general.paginator.of'),
        Tooltip: this.translateService.instant('general.paginator.of'),
      },
      {
        Key: TranslationKeys.paginatorFirstPageLabel,
        Label: this.translateService.instant(
          'general.paginator.first-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.first-page'),
      },
      {
        Key: TranslationKeys.paginatorLastPageLabel,
        Label: this.translateService.instant(
          'general.paginator.last-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.last-page'),
      },
      {
        Key: TranslationKeys.paginatorPreviousPageLabel,
        Label: this.translateService.instant(
          'general.paginator.prev-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.prev-page'),
      },
      {
        Key: TranslationKeys.paginatorNextPageLabel,
        Label: this.translateService.instant(
          'general.paginator.next-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.next-page'),
      },
      {
        Key: TranslationKeys.globalFilterPlaceholder,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.columnControlHeader,
        Label: this.translateService.instant(
          'general.paginator.columns'
        ),
        Tooltip: this.translateService.instant('general.paginator.columns'),
      },
      {
        Key: TranslationKeys.saveButton,
        Label: this.translateService.instant('general.paginator.save'),
        Tooltip: this.translateService.instant('general.paginator.save'),
      },
      {
        Key: TranslationKeys.cancelButton,
        Label: this.translateService.instant(
          'general.paginator.cancel'
        ),
        Tooltip: this.translateService.instant('general.paginator.cancel'),
      },
      {
        Key: TranslationKeys.tableEmptyStateMessage,
        Label: this.translateService.instant(
          'general.paginator.no-data'
        ),
        Tooltip: this.translateService.instant('general.paginator.no-data'),
      },
    ];

    const precission0Pipe = new CustomDecimalPipe(this.currentLanguageService);
    precission0Pipe.decimalPlaces = 0;

    this.cols = [
      {
        id: 0,
        columnsOrder: 0,
        type: ObjectTypes.string,
        name: 'supplierDisplayValue',
        title: this.translateService.instant(
          'customers-average-price.supplier'
        ),
        filterType: FilterTypes.input,
        visible: visibleColumns?.includes('supplierDisplayValue') ?? true
      },
      {
        id: 1,
        columnsOrder: 1,
        type: ObjectTypes.string,
        name: 'unspscDisplayValue',
        title: this.translateService.instant(
          'customers-average-price.unspsc'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('unspscDisplayValue') ?? true
      },
      {
        id: 2,
        columnsOrder: 2,
        type: ObjectTypes.string,
        name: 'unit',
        title: this.translateService.instant(
          'customers-average-price.unit'
        ),
        filterType: FilterTypes.checksSearch,
        visible: visibleColumns?.includes('unit') ?? true
      },
      {
        id: 3,
        columnsOrder: 3,
        type: ObjectTypes.string,
        name: 'averagePricePerUnitCalc',
        title: this.translateService.instant(
          'customers-average-price.average-price-per-unit-calc'
        ),
        pipe: precission0Pipe,
        filterType: FilterTypes.range,
        alignRight: true,
        visible: visibleColumns?.includes('averagePricePerUnitCalc') ?? true
      },
      {
        id: 4,
        columnsOrder: 4,
        type: ObjectTypes.string,
        name: 'averagePricePerUnitEntered',
        title: this.translateService.instant(
          'customers-average-price.average-price-per-unit-entered'
        ),
        pipe: precission0Pipe,
        filterType: FilterTypes.range,
        alignRight: true,
        visible: visibleColumns?.includes('averagePricePerUnitEntered') ?? true
      },
      {
        id: 5,
        columnsOrder: 5,
        type: ObjectTypes.custom,
        name: 'useEnteredPrice',
        filterType: FilterTypes.select,
        showInColumnControl: false,
        stickyRight: true,
        // regular title property does not work for 'ObjectTypes.custom' type of column
        exDataObj: { customTitle: this.translateService.instant(
          'general.use'
        )},
        visible: true
      },
      {
        id: 6,
        columnsOrder: 6,
        type: ObjectTypes.custom,
        name: 'edit',
        filterType: FilterTypes.custom,
        showInColumnControl: false,
        stickyRight: true,
        visible: true
      },
      {
        id: 7,
        columnsOrder: 7,
        type: ObjectTypes.custom,
        name: 'delete',
        filterType: FilterTypes.custom,
        showInColumnControl: false,
        stickyRight: true,
        visible: true
      }
    ];
  }

  setData(data) {
    if (data) {
      this.pageSize = Number(data._rowsPerPage);
      this.page = Number(data._PageNumber);
      this.totalCount = Number(data.totalFilteredProducts);
      this.data = data.pagedProductsDaos;
    }
  }

  getCurrentRoute() {
    let urlWithoutParams = this.router.url.split('?')[0];
    return urlWithoutParams;
  }
}
