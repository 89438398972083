<div class="row" style="margin: 0.2rem; margin-top: 1rem; margin-bottom: 0.5rem;">
  <div class="row" style="max-height: 6em; margin-left: 0.1rem; margin-bottom: 0.6rem;">
    <div class="col-9" style="width: 80%;">
      <div>
        <h2>{{ configData?.title }}</h2>
      </div>

      <div mat-dialog-content class="custom-dialog-content">{{ configData?.message }}</div>
    </div>

    <div class="col-3" style="width: 20%;padding-left: 5rem;">
      <div>
        <button mat-icon-button [mat-dialog-close]="false" style="margin-top: 0.5rem;float: right;">
          <mat-icon fontIcon="close"></mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div mat-dialog-actions class="custom-dialog-actions" [align]="'end'">
      <button mat-raised-button (click)="cancel()" [mat-dialog-close]="false">
        {{ configData?.cancelText }}
      </button>
      <button mat-raised-button color="primary" (click)="confirm()" [mat-dialog-close]="true">
        {{ configData?.confirmText }}
      </button>
    </div>
  </div>
</div>