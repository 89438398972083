<form [formGroup]="templatesFormService.form">
  <div class="container gx-0">
    <div class="row">
      <div class="col-12 mt-3">
        <p class="fw-bold fs-30">
          {{ header }}
        </p>
      </div>
    </div>
    <div class="row justify-content-around align-items-stretch mt-3">
      <div class="col-12">
        <div mat-dialog-content>
          <div class="row">
            <div class="col-12">

              <mat-card style="margin-bottom: 0.75rem;">
                <mat-card-content class="mat-body-1">
                  <div class="fw-bold fs-22">
                    {{ 'templates.template' | translate }}
                  </div>
                  <div class="row">
                    <div class="col-12 mt-3">
                      <div class="row">
                        <div class="col-6">
                          <div class="mb-2">
                            <mat-form-field appearance="fill">
                              <mat-label>{{
                                'templates.template-name' | translate
                                }}</mat-label>
                              <input required formControlName="name" matInput placeholder="{{
                                'templates.template-name' | translate
                              }}" />
                              <mat-error *ngIf="templatesFormService.form.get('name').hasError('pattern')">
                                {{ 'error-handling.not-allowed-character' | translate }}
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-6">
                          <mat-form-field appearance="fill">
                            <mat-label>{{
                              'templates.created-by' | translate
                              }}</mat-label>
                            <input formControlName="email" matInput placeholder="{{
                              'templates.created-by' | translate
                            }}" />
                          </mat-form-field>
                        </div>
                        <div class="col-12">
                          <mat-form-field appearance="fill">
                            <mat-label>{{
                              'templates.description' | translate
                              }}</mat-label>
                            <textarea required formControlName="description" matInput cdkTextareaAutosize placeholder="{{
                              'templates.description' | translate
                            }}"></textarea>
                            <mat-error *ngIf="templatesFormService.form.get('description').hasError('pattern')">
                              {{ 'error-handling.not-allowed-character' | translate }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card style="margin-bottom: 0.75rem;">
                <mat-card-content class="mat-body-1">
                  <div class="fw-bold fs-22">
                    {{ 'templates.unspscs' | translate }}
                  </div>
                  <div class="row">
                    <div class="col-12 mt-4">
                      <app-unspsc-handler [handlerForm]="templatesFormService.form" [formBuilder]="fb"
                        [editData]="editData" [formType]="'templates'"
                        (formChange)="onFormChanged($event)"></app-unspsc-handler>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>

              <app-calculations></app-calculations>

            </div>
          </div>
        </div>

        <div mat-dialog-actions [align]="'end'">
          <button mat-raised-button (click)="cancel()" [mat-dialog-close]="false">
            {{ 'general.close' | translate }}
          </button>

          <button mat-raised-button color="calculate" [disabled]="templatesFormService.form.invalid || calculationValid"
            (click)="calculate(templatesFormService.form.value)">
            {{ 'templates.calculate' | translate }}
          </button>

          <button mat-raised-button color="primary" [disabled]="templatesFormService.form.invalid || !calculationValid"
            (click)="ctaTemplate()">
            {{ 'templates.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>