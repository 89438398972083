import { Injectable } from '@angular/core';
import {
  Columns,
  Filter,
  FilterTypes,
  ObjectTypes,
  TranslationKeys,
} from '@dima/table-library';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { PageModel } from 'src/app/commons/models/page-model';
import { CustomDecimalPipe } from 'src/app/commons/pipes/custom-decimal.pipe';
import { CurrentLanguageService } from 'src/app/services/current-language.service';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class ExclusionsViewTableService {
  public cols: Columns[];
  public totalCount: number;
  public pageSize: number = 10;
  public page: number = 0;
  public data = [];
  public langSubscription: Subscription;
  public loading: boolean = false;
  public table: any;
  public selectedRows: any[];
  public dimaTableTranslations: any;
  public pagedModel: PageModel;
  filters: Filter[] = [];

  clearTableSelection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  anyRowSelectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private currentLanguageService: CurrentLanguageService
  ) {

    this.langSubscription = this.translateService.onLangChange.subscribe(
      (lang) => {
        this.setCols();
        this.router.navigate([this.getCurrentRoute()]);
      }
    );
  }

  setCols() {
    this.dimaTableTranslations = [
      {
        Key: TranslationKeys.globalFilterHeader,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.globalFilterPlaceholder,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.paginatorItemsPerPageLabel,
        Label: this.translateService.instant(
          'general.paginator.per-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.per-page'),
      },
      {
        Key: TranslationKeys.paginatorRangeLabel,
        Label: this.translateService.instant('general.paginator.of'),
        Tooltip: this.translateService.instant('general.paginator.of'),
      },
      {
        Key: TranslationKeys.paginatorFirstPageLabel,
        Label: this.translateService.instant(
          'general.paginator.first-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.first-page'),
      },
      {
        Key: TranslationKeys.paginatorLastPageLabel,
        Label: this.translateService.instant(
          'general.paginator.last-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.last-page'),
      },
      {
        Key: TranslationKeys.paginatorPreviousPageLabel,
        Label: this.translateService.instant(
          'general.paginator.prev-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.prev-page'),
      },
      {
        Key: TranslationKeys.paginatorNextPageLabel,
        Label: this.translateService.instant(
          'general.paginator.next-page'
        ),
        Tooltip: this.translateService.instant('general.paginator.next-page'),
      },
      {
        Key: TranslationKeys.globalFilterPlaceholder,
        Label: this.translateService.instant(
          'general.paginator.filter'
        ),
        Tooltip: this.translateService.instant('general.paginator.filter'),
      },
      {
        Key: TranslationKeys.columnControlHeader,
        Label: this.translateService.instant(
          'general.paginator.columns'
        ),
        Tooltip: this.translateService.instant('general.paginator.columns'),
      },
      {
        Key: TranslationKeys.saveButton,
        Label: this.translateService.instant('general.paginator.save'),
        Tooltip: this.translateService.instant('general.paginator.save'),
      },
      {
        Key: TranslationKeys.cancelButton,
        Label: this.translateService.instant(
          'general.paginator.cancel'
        ),
        Tooltip: this.translateService.instant('general.paginator.cancel'),
      },
      {
        Key: TranslationKeys.tableEmptyStateMessage,
        Label: this.translateService.instant(
          'general.paginator.no-data'
        ),
        Tooltip: this.translateService.instant('general.paginator.no-data'),
      },
    ];

    const precission0Pipe = new CustomDecimalPipe(this.currentLanguageService);
    precission0Pipe.decimalPlaces = 0;

    this.cols = [
      {
        id: 1,
        columnsOrder: 1,
        type: ObjectTypes.string,
        name: 'supplierDisplayValue',
        title: this.translateService.instant(
          'customers-own-data-exclusion.supplier-id'
        ),
        filterType: FilterTypes.selectSearch
      },
      {
        id: 2,
        columnsOrder: 2,
        type: ObjectTypes.string,
        name: 'invoiceNumber',
        title: this.translateService.instant(
          'customers-own-data-exclusion.invoice-number'
        ),
        filterType: FilterTypes.selectSearch
      },
      {
        id: 3,
        columnsOrder: 3,
        type: ObjectTypes.string,
        name: 'itemId',
        title: this.translateService.instant(
          'customers-own-data-exclusion.item-id'
        ),
        filterType: FilterTypes.selectSearch
      },
      {
        id: 4,
        columnsOrder: 4,
        type: ObjectTypes.string,
        name: 'organizationDisplayValue',
        title: this.translateService.instant(
          'customers-own-data-exclusion.organization-id'
        ),
        filterType: FilterTypes.selectSearch
      },
      {
        id: 5,
        columnsOrder: 5,
        type: ObjectTypes.string,
        name: 'unspscDisplayValue',
        title: this.translateService.instant(
          'customers-own-data-exclusion.unspsc-class-id'
        ),
        filterType: FilterTypes.selectSearch
      },
      {
        id: 6,
        columnsOrder: 6,
        type: ObjectTypes.string,
        name: 'invoiceDate',
        title: this.translateService.instant(
          'customers-own-data-exclusion.invoice-date'
        ),
        filterType: FilterTypes.dateRange,
        filterFn: (value: any, data: Date) => {
          if (!value.date || DateTime.fromJSDate(new Date(value.date)).toLocaleString()
            == DateTime.fromJSDate(new Date(data.toString())).toLocaleString()) {
            return true;
          }
          return false;
        },
      },
      {
        id: 7,
        columnsOrder: 7,
        type: ObjectTypes.custom,
        name: 'customersExclusion',
        filterType: FilterTypes.select,
        showInColumnControl: false,
        stickyRight: true,
        visible: false
      }
    ];
  }

  setData(data) {
    if (data) {
      this.pageSize = Number(data._rowsPerPage);
      this.page = Number(data._PageNumber);
      this.totalCount = Number(data.totalFilteredProducts);
      this.data = data.pagedProductsDaos;
    }
  }

  getCurrentRoute() {
    let urlWithoutParams = this.router.url.split('?')[0];
    return urlWithoutParams;
  }
}
