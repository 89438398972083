import { Injectable } from '@angular/core';

import { Subscription } from 'rxjs';
import { ApiService } from '../../../../services/api.service';
import { ITemplatesCrudHelperService } from '@modules/scenarios/services/templates/templates-crud-helper.interface';
import { Unspsc } from 'src/app/commons/models/unspsc';
import { CalculatorPrepareDataService } from '../calculator/calculator-prepare-data.service';
import { CalculateWorkDto, CalculateDto } from 'src/app/commons/models/scenario-dto';
import { CalculatorFormService } from '../calculator/calculator-form.service';

@Injectable({
  providedIn: 'root',
})
export class TemplatesCrudHelperService implements ITemplatesCrudHelperService {
  addTemplateSubscription?: Subscription;
  addTemplateWorkCalculationSubscription: Subscription;
  addTemplateCalculationSubscription: Subscription;
  updateTemplateSubscription?: Subscription;
  updateTemplateWorkCalculationSubscription: Subscription;
  updateTemplateCalculationSubscription: Subscription;

  constructor(
    public templatesFormService: CalculatorFormService,
    public templatePrepareDataService: CalculatorPrepareDataService,
    public apiService: ApiService
  ) { }

  addTemplateMaster(selectedUnspscs: Unspsc[][], templateFormValue): void {
    let templateMasterData =
      this.templatePrepareDataService.prepareMasterData(templateFormValue);

    // Working database version
    this.addTemplateSubscription = this.apiService
      .addTemplateMaster(templateMasterData)
      .subscribe((id) => {
        this.templatesFormService.id = id as number;

        let templateWorkData = this.templatePrepareDataService.prepareWorkData(
          templateFormValue,
          id,
          selectedUnspscs
        );

        this.addTemplateWorkCalculations(selectedUnspscs, templateWorkData);
      });
  }

  addTemplateWorkCalculations(selectedUnspscs: Unspsc[][], CalculateWorkDtos: CalculateWorkDto[]): void {
    // Working database version
    this.addTemplateWorkCalculationSubscription = this.apiService
      .addTemplateWorkCalculations(CalculateWorkDtos)
      .subscribe((res) => {
        this.templatePrepareDataService
          .prepareCalculateDataForSaving(selectedUnspscs)
          .subscribe((templateCalculateData) => {
            if (
              templateCalculateData instanceof Array &&
              templateCalculateData.length !== 0
            ) {
              this.addTemplateCalculations(templateCalculateData);
            } else {
              this.templatesFormService.form.reset();
              this.templatesFormService.id = undefined;
              this.templatesFormService.dialogRef.close('save');
            }
          });
      });
  }

  addTemplateCalculations(templateCalculations: CalculateDto[]): void {
    // Working database version
    this.addTemplateCalculationSubscription = this.apiService
      .addTemplateCalculations(templateCalculations)
      .subscribe((res) => {
        this.templatesFormService.form.reset();
        this.templatesFormService.id = undefined;
        this.templatesFormService.dialogRef.close('save');
      });
  }

  updateTemplate(selectedUnspscs: Unspsc[][], dwskOld, id): void {
    let updateMasterData = this.templatePrepareDataService.prepareMasterData(
      this.templatesFormService.form.value
    );

    this.updateTemplateSubscription = this.apiService
      .updateMasterTemplate(dwskOld, updateMasterData)
      .subscribe((dwskNew) => {
        this.templatesFormService.id = dwskNew as number;

        let templateWorkData = this.templatePrepareDataService.prepareWorkData(
          this.templatesFormService.form.value,
          dwskNew,
          selectedUnspscs
        );

        this.updateTemplateWorkCalculations(selectedUnspscs, templateWorkData, dwskOld);
      });
  }

  updateTemplateWorkCalculations(
    selectedUnspscs: Unspsc[][],
    CalculateWorkDtos: CalculateWorkDto[],
    dwskOld
  ): void {
    this.updateTemplateWorkCalculationSubscription = this.apiService
      .updateWorkTemplate(dwskOld, CalculateWorkDtos)
      .subscribe((res) => {
        this.templatePrepareDataService
          .prepareCalculateDataForSaving(selectedUnspscs)
          .subscribe((templateCalculateData) => {
            this.updateTemplateCalculations(templateCalculateData, dwskOld);
          });
      });
  }

  updateTemplateCalculations(
    templateCalculations: CalculateDto[],
    dwskOld
  ): void {
    this.updateTemplateCalculationSubscription = this.apiService
      .updateTemplateCalculations(dwskOld, templateCalculations)
      .subscribe((res) => {
        this.templatesFormService.form.reset();
        this.templatesFormService.id = undefined;
        this.templatesFormService.dialogRef.close('update');
      });
  }

  unsubscribe() {
    if (this.addTemplateSubscription) {
      this.addTemplateSubscription.unsubscribe();
    }

    if (this.addTemplateWorkCalculationSubscription) {
      this.addTemplateWorkCalculationSubscription.unsubscribe();
    }

    if (this.addTemplateCalculationSubscription) {
      this.addTemplateCalculationSubscription.unsubscribe();
    }

    if (this.updateTemplateSubscription) {
      this.updateTemplateSubscription.unsubscribe();
    }

    if (this.updateTemplateWorkCalculationSubscription) {
      this.updateTemplateWorkCalculationSubscription.unsubscribe();
    }

    if (this.updateTemplateCalculationSubscription) {
      this.updateTemplateCalculationSubscription.unsubscribe();
    }
  }
}
