import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Bookmark } from '../models/bookmark-model';
import { Observable, of } from 'rxjs';

export function bookmarkNameValidator(bookmarks: Bookmark[]) {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    let sameNameBookmark = bookmarks.filter((bookmark) => {
      return (
        bookmark.title.toLowerCase().trim() ==
        control.value.toLowerCase().trim()
      );
    });

    if (sameNameBookmark.length > 0) {
      return of({ sameName: sameNameBookmark[0].title });
    }
    return of(null);
  };
}
