<div class="container">
  <div class="row justify-content-between">
    <h1 class="col-8 mt-5">
      {{ 'navigation.templates' | translate }}
    </h1>

    <div class="col-2 mt-5 text-right">
      <button *ngIf="adminService.ScenarioTemplateCreator" mat-raised-button color="primary"
        (click)="createTemplateDialog()">
        {{ 'templates.create-template' | translate }}
      </button>
    </div>
    <div class="col-2 mt-5 text-right">
      <button mat-raised-button color="save" (click)="backToScenarios()">
        {{ 'scenarios.type' | translate }}
      </button>
    </div>
  </div>

  <expandable-table-component [itemTemplate]="templatesTemplate" [dataSource]="dataSource"
    [columnsConfigs]="columnsConfigs">
  </expandable-table-component>
</div>

<ng-template #templatesTemplate let-element>
  <div class="d-block fs-14">
    <div class="row mt-4 mb-4">
      <div class="col-4">
        <div class="mb-4">
          <div class="fw-bold fs-18 mb-4">
            {{ 'expandable-row.description' | translate }}
          </div>
          {{ element.description }}
        </div>
        <div>
          <span class="fw-bold d-inline-block mb-2">
            {{ 'expandable-row.emission' | translate }}
          </span>

          {{ element.totalEmissionChange | number: '1.0-0' }}
          <app-amount-indicator [amount]="element.totalEmissionChange">
          </app-amount-indicator>
        </div>
        <div class="mb-4">
          <span class="fw-bold d-inline-block mb-2">
            {{ 'expandable-row.expense' | translate }}</span>

          {{ element.totalLineAmountChange | number: '1.0-0' }}
          <app-amount-indicator [amount]="element.totalLineAmountChange">
          </app-amount-indicator>
        </div>
      </div>
      <div class="col-6">
        <p class="fs-18 fw-bold mb-4 ps-4">
          {{ 'expandable-row.unspsc' | translate }}
        </p>
        <div *ngFor="let unspsc of element.unspscs; let unspscIndex = index">
          <div class="wrapper p-4 mb-4 me-2">
            <div class="row">
              <div class="col-6">
                <span class="fw-bold d-inline-block mb-2">
                  {{ 'expandable-row.unspsc' | translate }}
                </span>
                <div *ngFor="let unspscGrid of getGridUnspscs(element.unspscsGrid, unspscIndex)?.slice(0, maxUnspscsLimit)">
                  <div class="mb-2">{{ unspscGrid.unspscClassID }} {{ unspscGrid.unspscClassDescription }}</div>
                </div>
                <div class="mb-2" *ngIf="getGridUnspscs(element.unspscsGrid, unspscIndex)?.length > maxUnspscsLimit">...</div>
                  <span class="fw-bold d-inline-block mb-2">
                    {{ 'expandable-row.forecast-length' | translate }}
                  </span>
                  {{ unspsc.forecastLength }}
              </div>
                <div class="col-6">
                  <div>
                    <span class="fw-bold d-inline-block mb-2">{{ 'expandable-row.organization' | translate }}
                    </span>
                    {{ unspsc.organizationID || '' }}
                  </div>
                  <div class="mb-2">
                    {{
                    unspsc.organizationDescription ||
                    ('expandable-row.all-organizations' | translate)
                    }}
                  </div>
                  <div>
                    <span class="fw-bold d-inline-block mb-2">{{ 'expandable-row.adjustment.description' | translate }}
                    </span>
                    {{ unspsc.adjustment || '' }}
                  </div>
                  <div>
                    <span class="fw-bold d-inline-block mb-2">{{
                      'expandable-row.adjustment.simulation-method' | translate
                      }}
                    </span>
                    {{
                    unspsc.simulationMethod === 1
                    ? ('unspsc.methods.percentage' | translate)
                    : ('unspsc.methods.quantity' | translate) || ''
                    }}
                  </div>
                  <div>
                    <span class="fw-bold d-inline-block mb-2">{{ 'expandable-row.adjustment.trend-method' | translate }}
                    </span>
                    {{
                    unspsc.trendMethod == 1
                    ? ('unspsc.methods.linear' | translate)
                    : ('unspsc.methods.constant' | translate) || ''
                    }}
                  </div>
                  <div>
                    <span class="fw-bold d-inline-block mb-2">{{ 'expandable-row.adjustment.unit-method' | translate }}
                    </span>
                    {{
                    unspsc.unitMethod == 1
                    ? this.tenantSelectService.userCurrency == DK
                    ? 'DKK'
                    : 'NOK'
                    : 'KG' || ''
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 text-center">
          <button *ngIf="adminService.ScenarioTemplateCreator" mat-icon-button (click)="editTemplate(element)"
            color="primary" class="mb-2">
            <mat-icon fontIcon="edit"></mat-icon>
          </button>
          <button *ngIf="adminService.ScenarioTemplateCreator" mat-icon-button (click)="deleteTemplate(element)"
            color="warn">
            <mat-icon fontIcon="delete"></mat-icon>
          </button>

          <app-save-as-scenario-button [scenarioValues]="element">
          </app-save-as-scenario-button>
        </div>
      </div>
    </div>
</ng-template>