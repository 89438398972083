import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InformationDialogComponent } from '../components/information-dialog/information-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class InformationDialogService {
  constructor(public dialog: MatDialog, private translate: TranslateService) { }

  showErrorMessage(data: InformationDialogDto) {
    this.dialog.open(InformationDialogComponent, {
      data: data,
    });
  }

  showCommonHttpError(error: HttpErrorResponse, title?: string): void {
    this.showErrorMessage(<InformationDialogDto>{
      title: title ?? this.translate.instant('errors.something-went-wrong-error.type'),
      httpStatusCode: error.status,
      messages: [error.error]
    });
  }

  showPdfDownloadErrorMessage(status: number, additionalInfo: string) {
    this.showErrorMessage(<InformationDialogDto>{
      title: this.translate.instant('errors.something-went-wrong-error.type'),
      httpStatusCode: status,
      messages: [`${this.translate.instant('pdf-exports.file-download-error')} ${additionalInfo}`]
    });
  }
}

export interface InformationDialogDto {
  title?: string;
  httpStatusCode?: number;
  correlationId?: string;
  responseTimeInMS?: string;
  url?: string;
  messages?: string[];
}
