import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDataService } from 'src/app/commons/components/data-view/data-service.interface';
import { EntityTrackerError } from 'src/app/commons/models/entity-tracker-error';
import { MeasuringPoint } from 'src/app/commons/models/measuring-point.model';
import { PageResponse } from 'src/app/commons/models/page-response';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class MeasuringPointsService implements IDataService {
  constructor(private apiService: ApiService) { }

  getByUniqueKey(model: any): Observable<any | EntityTrackerError> {
    throw new Error('Method not implemented.');
  }

  add(measuringPointFormValues): Observable<number | EntityTrackerError> {
    measuringPointFormValues['ModifyDate'] = measuringPointFormValues['CreationDate'];
    measuringPointFormValues['ModifiedBy'] = measuringPointFormValues['CreatedBy'];

    return this.apiService.addMeasuringPoint(measuringPointFormValues);
  }

  update(updateModel: MeasuringPoint): Observable<number | EntityTrackerError>  {
    return this.apiService.updateMeasuringPoint(updateModel);
  }

  sliderChange(updateModel: MeasuringPoint, sliderValue: boolean) {
    throw new Error('Method not implemented.');
  }

  delete(ids: number[]): Observable<PageResponse | EntityTrackerError> {
    return this.apiService.deleteMeasuringPoint(ids);
  }
}