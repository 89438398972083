import { Injectable } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { Subject } from 'rxjs';
import { Chart, registerables } from 'chart.js';
import { CalculatedGraphData } from '../../../../commons/models/calculated-graph.data';
import { map } from 'rxjs/operators';
import { UnspscCalculatorServiceDto } from '../../../../commons/models/unspsc';
import { SnackbarService } from '../../../../commons/services/snackbar.service';
import { UnspscHandlerService } from 'src/app/commons/components/unspsc-handler/unspsc-handler.service';
import { ICalculatorService } from './calculator.interface';
import { CalculatorHelperService } from './calculator-helper.service';
import { CalculatorTableService } from './calculator-table.service';
import { CalculatorPrepareDataService } from './calculator-prepare-data.service';
import { HistoricalDataDto, HistoricalDataResponseDto } from 'src/app/commons/models/historical-data-dto';
import { GraphXYDTO } from 'src/app/commons/models/graph-xy-dto';

@Injectable({
  providedIn: 'root',
})
export class CalculatorService implements ICalculatorService {
  graphInit = new Subject<CalculatedGraphData>();
  public areGraphsShown: boolean = false;

  totalExpenseChange: number;
  totalEmissionChange: number;
  totalQuantityChange: number;

  constructor(
    public apiService: ApiService,
    public calculatorHelperService: CalculatorHelperService,
    public tableService: CalculatorTableService,
    public prepareDataService: CalculatorPrepareDataService,
    private snackbarService: SnackbarService,
    private unspscHandlerService: UnspscHandlerService
  ) {
    Chart.register(...registerables);
  }

  calculateCore(historicalData: HistoricalDataResponseDto[], scenarioFormValues) {
    let arrayOfGraphData = [];
    let maximumForecastLength =
      this.calculatorHelperService.getMaximumForecastLength(
        scenarioFormValues.unspscs
      );
    // get the historical data based on different unspscs from different unspc form elements
    const grouped = this.groupBy(scenarioFormValues.unspscs, 'formIndex');

    Object.keys(grouped).forEach(formIndex => {
      const sectionHistoricalData = historicalData.find(x => x.formIndex == formIndex.toString())?.trendPoints;
      const historicalDataGrouped = this.groupBy(
        sectionHistoricalData?.filter(x => grouped[formIndex].map(y => y.unspscClassID).includes(x.unspscClassID)),
        'unspscClassID');

      let newestHistoricalRecords = [];
      Object.keys(historicalDataGrouped).forEach(x => {
        newestHistoricalRecords.push(historicalDataGrouped[x].sort(function (a, b) {
          return b.year - a.year;
        })[0]);
      });

      grouped[formIndex].forEach((unspsc) => {
        let historicalDataForOneUnspsc = sectionHistoricalData?.filter(x => x.unspscClassID === unspsc.unspscClassID);
        let preparedDataForUnspsc;
        let unspscCode = historicalDataForOneUnspsc[0]?.unspscClassID;
        let unspscObject: UnspscCalculatorServiceDto =
          scenarioFormValues.unspscs.find((el) => el.formIndex == formIndex && el.unspscClassID == unspscCode);

        if (!historicalDataGrouped.formIndex) {
          unspscObject = scenarioFormValues.unspscs.find((el) => el.unspscClassID == unspscCode)
        }

        // calculate adjustment proportions
        let chosenUnitMethod = unspscObject.unitMethod;
        let proportionFieldName = chosenUnitMethod == 1 ? 'amount' : 'quantity';
        const arg1 = Math.round(newestHistoricalRecords.filter(x => x.unspscClassID == unspscCode)[0]?.[proportionFieldName] * 1000) / 1000;
        const arg2 = Math.round(newestHistoricalRecords.filter(x => !(x.unspscClassID == unspscCode)).map(y => y[proportionFieldName]).reduce((acc, cur) => acc + Number(cur), 0) * 1000) / 1000;

        let adjustmentProportionPercentage;
        if (arg2 >= 0) {
          adjustmentProportionPercentage = Math.round(((arg1 / (arg1 + Number(arg2))) * 100) * 1000) / 1000;
        }

        let {
          forecastLength,
          adjustment,
          trendMethod,
          simulationMethod,
          unitMethod,
        } = unspscObject || {};

        let unspscConfig = {
          adjustment,
          trendMethod,
          simulationMethod,
          unitMethod,
          adjustmentProportionPercentage
        };

        // only if there is no data at all we are showing no graph for particular unspsc
        if (
          historicalDataForOneUnspsc instanceof Array &&
          !historicalDataForOneUnspsc.length
        ) {
          this.snackbarService.openSnackBar('snackbar.no-data');
          return;
        }

        this.calculatorHelperService.addZerosWhenNoData(
          historicalDataForOneUnspsc
        );

        preparedDataForUnspsc =
          this.prepareDataService.prepareCalculatedDataForUnspsc(
            historicalDataForOneUnspsc,
            maximumForecastLength,
            forecastLength,
            unspscConfig
          );

        let tempCalculatedGraphDataByUnspsc = {
          expenseHistoricalPoints: preparedDataForUnspsc.expense.historicalPoints,
          expenseTrendPoints: preparedDataForUnspsc.expense.trendPoints,
          expenseSimulationPoints: preparedDataForUnspsc.expense.simulationPoints,
          expenseChange: preparedDataForUnspsc.expense.change,
          emissionHistoricalPoints:
            preparedDataForUnspsc.emission.historicalPoints,
          emissionFactorTrendPoints:
            preparedDataForUnspsc.emission.factorTrendPoints,
          emissionTrendPoints: preparedDataForUnspsc.emission.trendPoints,
          emissionSimulationPoints:
            preparedDataForUnspsc.emission.simulationPoints,
          emissionChange: preparedDataForUnspsc.emission.change,
          quantityHistoricalPoints: preparedDataForUnspsc.quantity.historicalPoints,
          quantityTrendPoints: preparedDataForUnspsc.quantity.trendPoints,
          quantitySimulationPoints: preparedDataForUnspsc.quantity.simulationPoints,
          quantityChange: unitMethod == 2 ? preparedDataForUnspsc.quantity.change : [] as GraphXYDTO[] // only unit-based, otherwise leave empty
        };

        arrayOfGraphData.push(tempCalculatedGraphDataByUnspsc);
      });
    });

    let aggregateGraphData =
      this.calculatorHelperService.aggregateGraphDto(arrayOfGraphData);

    this.totalExpenseChange =
      this.calculatorHelperService.sumChangeData(
        aggregateGraphData.expense.change
      );

    this.totalEmissionChange =
      this.calculatorHelperService.sumChangeData(
        aggregateGraphData.emission.change
      );

    this.totalQuantityChange =
      this.calculatorHelperService.sumChangeData(
        aggregateGraphData.quantity.change
      );

    return {
      aggregateGraphData,
      totalExpenseChange: this.totalExpenseChange,
      totalEmissionChange: this.totalEmissionChange,
      totalQuantityChange: this.totalQuantityChange
    };
  }

  groupBy(array: any[], propertyName: string) {
    return array.reduce((result, item) => {
      result[item[propertyName]] = result[item[propertyName]] || [];
      result[item[propertyName]].push(item);
      return result;
    }, Object.create(null));
  }

  calculateSaveScenarioAsInitiative(scenarioFormValues) {
    scenarioFormValues.unspscs.forEach((element, index) => {
      element.formIndex = index;
    });

    return this.prepareDataService
      .getHistoricalDataPointsForMultipleUnspscs(scenarioFormValues)
      .pipe(
        map((historicalData) => {
          return this.calculateCore(historicalData, scenarioFormValues);
        })
      );
  }

  calculateSaveTemplateAsScenario(scenarioFormValues) {
    scenarioFormValues.unspscs.forEach((element, index) => {
      element.formIndex = index;
    });

    return this.prepareDataService
      .getHistoricalDataPointsForMultipleUnspscs(scenarioFormValues)
      .pipe(
        map((historicalData) => {
          return this.calculateCore(historicalData, scenarioFormValues);
        })
      );
  }

  calculateAndShow(scenarioFormValues) {
    let firstYear = this.calculatorHelperService.firstYear;
    let currentYear = new Date().getFullYear();
    let historicalColumns = [];

    for (let i = firstYear; i < currentYear; i++) {
      historicalColumns.push(i);
    }

    this.prepareDataService
      .getHistoricalDataPointsForMultipleUnspscs(scenarioFormValues)
      .subscribe((historicalData) => {
        let newHistoricalDataGrouped: HistoricalDataResponseDto[] = Array<HistoricalDataResponseDto>();
        historicalColumns.forEach(year => {
          this.unspscHandlerService.selectedUnspscs.forEach((unspscs, formIndex) => {
            let newHistoricalData = [];
            unspscs.forEach(unspsc => {
              const sectionHistoricalData = historicalData.find(x => x.formIndex == formIndex.toString());
              const found = sectionHistoricalData.trendPoints.filter(x => x.unspscClassID == unspsc.unspscClassID && x.year == year)?.[0];
              const alreadyExist = newHistoricalData.filter(x => x.unspscClassID == unspsc.unspscClassID && x.year == year)?.[0];

              if (alreadyExist) {

              }
              else if (found) {
                newHistoricalData.push(found);
              }
              else {
                newHistoricalData.push(<HistoricalDataDto>{
                  amount: 0,
                  cO2EmissionKG: 0,
                  organizationDescriptionId: null,
                  organizationID: null,
                  unspscClassDescription: '',
                  unspscClassID: unspsc.unspscClassID,
                  year: year,
                  formIndex: formIndex
                });
              }
            });

            const found = newHistoricalDataGrouped.find(x => x.formIndex == formIndex.toString());

            if (!found)
              newHistoricalDataGrouped.push(<HistoricalDataResponseDto>{ formIndex: formIndex.toString(), trendPoints: newHistoricalData });
            else
              found.trendPoints.push(...newHistoricalData);
          });
        });

        this.graphInit.next(
          this.calculateCore(newHistoricalDataGrouped, scenarioFormValues)
            .aggregateGraphData
        );

        this.tableService.getChangeDataByUnspscForTable(
          newHistoricalDataGrouped,
          scenarioFormValues.calculatorUnspsc,
          scenarioFormValues.unspscs
        );
      });
  }

  resetSummaryData(): void {
    this.totalEmissionChange = undefined;
    this.totalExpenseChange = undefined;
    this.totalQuantityChange = undefined;
  }
}
