import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CorrelationId } from '../constants/http-header.constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  errorIdSubject = new BehaviorSubject<any>(undefined);

  constructor(private router: Router) {}

  showError(route: any, error: HttpErrorResponse) {
    this.setErrorId(error);
    this.router.navigate([route]);
  }

  setErrorId(error) {
    this.errorIdSubject.next(error?.headers.get(CorrelationId));
  }
}
