import { Component, ElementRef, Host, OnInit, ViewChild } from '@angular/core';
import { FilterService } from '@modules/customers-own-data-exclusion/services/filter.service';
import { TableService } from '@modules/customers-own-data-exclusion/services/table.service';
import { InformationDialogService } from '@modules/shell/services/information-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { DataViewComponent } from 'src/app/commons/components/data-view/data-view.component';
import { FileService } from 'src/app/commons/services/file.service';
import { SnackbarService } from 'src/app/commons/services/snackbar.service';
import { CustomDialogService } from 'src/app/commons/dialog/services/custom-dialog.service';
import { DialogService } from 'src/app/services/dialog.service';
import { CreateEditExclusionDialogComponent } from '../create-edit-exclusion-dialog/create-edit-exclusion-dialog.component';
import { CustomersExclusionRulesGetService, CustomersExclusionRulesService } from '@modules/customers-own-data-exclusion/services/customers-exclusion-rules.service';
import { UserService } from '@modules/shell/services/user.service';
import { CustomersExclusionsDataCsvService } from '@modules/customers-own-data-exclusion/services/customers-exclusions-data-csv.service';
import { PageResponse } from 'src/app/commons/models/page-response';

@Component({
    selector: 'app-cod-exclusion-action-menu',
    templateUrl: './cod-exclusion-action-menu.component.html',
    styleUrls: ['./cod-exclusion-action-menu.component.scss'],
    providers: [DataViewComponent,
        { provide: 'ITableService', useExisting: TableService },
        { provide: 'IDataService', useExisting: CustomersExclusionRulesService },
        { provide: 'IFilterService', useExisting: FilterService }
    ],
    standalone: false
})
export class CodExclusionActionMenuComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  translationKey = 'customers-own-data-exclusion';
  parentComponent: DataViewComponent;
  isDeleteButtonDisabled = true;
  preventStatusChange: boolean = false;
  isStatusChangeButtonDisabled = true;
  private customDialogService: CustomDialogService;

  constructor(@Host() parent: DataViewComponent,
    public translateService: TranslateService,
    dialogService: DialogService,
    private informationDialogService: InformationDialogService,
    private snackbarService: SnackbarService,
    private fileService: FileService,
    private tableService: TableService,
    private dataService: CustomersExclusionRulesService,
    private getDataService: CustomersExclusionRulesGetService,
    private filterService: FilterService,
    private userService: UserService,
    private customersExclusionsCsvService: CustomersExclusionsDataCsvService
  ) {
    this.customDialogService = new CustomDialogService(dialogService, getDataService, dataService, tableService, filterService, snackbarService, translateService);
    this.customDialogService.orderBy = 'order by order by SupplierDisplayValue asc, UnspscDisplayValue asc, InvoiceNumber asc, ItemId asc';
    this.parentComponent = parent;
  }

  ngOnInit() {
    this.customDialogService.dialogComponent = CreateEditExclusionDialogComponent;
    this.tableService.anyRowSelectedSubject.subscribe(isSelected => {
      this.isDeleteButtonDisabled = !isSelected;
      this.isStatusChangeButtonDisabled = !isSelected;

      if (isSelected) {
        const failingRows = this.tableService.selectedRows.filter(x => x?.verified);
        this.preventStatusChange = failingRows?.length > 0 && failingRows?.length == this.tableService.selectedRows?.length;
      }
    });

    this.customDialogService.successfulDeleteSubject.subscribe(succeed => {
      if (succeed) {
        this.isDeleteButtonDisabled = true;
        this.isStatusChangeButtonDisabled = true;
      }
    });
  }

  uploadDatasource(fileInput: any) {
    let file = fileInput.target.files[0];
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.fileService.uploadDatasource(formData)
      .subscribe(
        response => {
          if (response) {
            this.customersExclusionsCsvService.importCsv(file.name).subscribe(x => {
              this.getDataService
                .get({
                  search: 1,
                  filters: this.tableService.filters,
                  pageSize: this.tableService.pageSize.toString(),
                  orderBy: this.tableService.pagedModel?.orderBy ?? this.customDialogService.orderBy,
                  page: this.tableService.page.toString()
                }).subscribe((data: PageResponse) => {
                  this.tableService.setData(data);
                  this.filterService.resetFilters();
                  this.filterService.initializeFilters();
                });
              this.snackbarService.openSnackBar('customers-own-data.data-imported');
            },
              error => {
                console.error('error', error)
                this.informationDialogService.showCommonHttpError(error, 'CSV import error');
              });
          }
        },
        error => {
          console.error('error', error)
          this.informationDialogService.showCommonHttpError(error, 'File uploading error')
        });

    this.clearChosenFile();
  }

  async changeStatus(enable: boolean) {
    return (await this.dataService.enableDisableRow(this.tableService.selectedRows.map(x => x.id), enable)).subscribe(() => {
      this.tableService.selectedRows = [];
      this.tableService.clearTableSelection.next(true);
      this.getDataService
        .get({
          search: 1,
          filters: this.tableService.filters,
          pageSize: this.tableService.pageSize.toString(),
          orderBy: this.tableService.pagedModel?.orderBy ?? this.customDialogService.orderBy,
          page: this.tableService.page.toString()
        })
        .subscribe((pageResponse: PageResponse) => {
          this.tableService.setData(pageResponse);
          this.filterService.resetFilters();
          this.filterService.initializeFilters();
          this.tableService.totalCount = pageResponse.totalFilteredProducts;
          this.snackbarService.openSnackBar(
            `${this.translationKey}.updated`
          );
          this.isStatusChangeButtonDisabled = true;
          this.isDeleteButtonDisabled = true;
        });
    });
  }

  createDialog(): void {
    this.customDialogService.createDialog();
  }

  deleteSelected() {
    this.customDialogService.deleteDialog(this.tableService.selectedRows.map(x => x.id));
  }
  
  isReadonlyUser(): boolean {
    return this.userService.currentUser.permissions.customerReadOnlyUser;
  }

  private clearChosenFile() {
    this.fileInput.nativeElement.value = null;
  }
}
