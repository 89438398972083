export class UserActivity {
    id: number;
    activity: string;
    eventId: ActivityEventType;
    timestamp: Date;
    userId: string;

    constructor(activity: string, userId: string, eventId?: ActivityEventType, id?: number, timestamp?: Date) {
        this.id = id || 0;
        this.activity = activity;
        this.timestamp = timestamp || new Date();
        this.userId = userId;
        this.eventId = eventId || ActivityEventType.Unknown;
    }
}

export enum ActivityEventType {
    BookmarkAdded = 1,
    BookmarkModified = 2,
    BookmarkUsed = 3,
    BookmarkDeleted = 4,
    CustomersAveragePriceAdded = 5,
    CustomersAveragePriceModified = 6,
    CustomersAveragePriceDeleted = 7,
    CustomersAveragePriceViewActivated = 8,
    CustomersExclusionRuleAdded = 9,
    CustomersExclusionRuleDeleted = 10,
    CustomersExclusionRuleModified = 11,
    CustomersExclusionRuleViewActivated = 12,
    CustomersOwnDataAdded = 13,
    CustomersOwnDataDeleted = 14,
    CustomersOwnDataModified = 15,
    CustomersOwnDataViewActivated = 16,
    CustomersProductEmissionAdded = 17,
    CustomersProductEmissionModified = 18,
    CustomersProductEmissionDeleted = 19,
    CustomersProductEmissionViewActivated = 20,
    CustomersTriggerCustomersAveragePriceModified = 21,
    CustomersTriggerCustomersOwnDataModified = 22,
    CustomersTriggerCustomersProductEmissionModified = 23,
    CustomersTriggerCustomersExclusionRulesModified = 24,
    // GhgReportLoaded = 25,
    // GhgReportRendered = 26,
    // GhgReportDataSelected = 27,
    InitiativeMasterAdded = 28,
    InitiativeMasterModified = 29,
    InitiativesViewActivated = 30,
    ReportDataSelected = 31,
    ReportLoaded = 32,
    ReportRendered = 33,
    ScenarioMasterAdded = 34,
    ScenarioMasterModified = 35,
    ScenariosViewActivated = 36,
    TemplateMasterAdded = 37,
    TemplateMasterModified = 38,
    TemplatesViewActivated = 39,
    UserLogin = 40,
    UserLogout = 41,
    // FokusReportDataSelected = 42,
    // FokusReportReportLoaded = 43,
    // FokusReportReportRendered = 44,
    // AdminReportDataSelected = 45,
    // AdminReportReportLoaded = 46,
    // AdminReportReportRendered = 47,
    PdfExportOrdered = 48,
    PdfExportDownloaded = 49,
    Unknown = 50,
    FiscalYearViewActivated = 51,
    FiscalYearAdded = 52,
    FiscalYearModified = 53,
    FiscalYearDeleted = 54,
    MeasuringPointViewActivated = 55,
    MeasuringPointXbrlExported = 56
}