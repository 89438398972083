import { Component, OnInit } from '@angular/core';
import { CalculatorHelperService } from '@modules/scenarios/services/calculator/calculator-helper.service';
import { CalculatorTableService } from '@modules/scenarios/services/calculator/calculator-table.service';
import { CalculatorService } from '@modules/scenarios/services/calculator/calculator.service';

@Component({
    selector: 'app-table-display-templates',
    templateUrl: './table-display-templates.component.html',
    styleUrls: ['./table-display-templates.component.scss'],
    standalone: false
})
export class TableDisplayTemplatesComponent implements OnInit {
  constructor(
    public templatesCalculatorService: CalculatorService,
    public templatesCalculatorHelperService: CalculatorHelperService,
    public templatesTableService: CalculatorTableService
  ) {}

  ngOnInit(): void {
    this.templatesCalculatorHelperService.expensePanelOpenState = true;
    this.templatesCalculatorHelperService.emissionPanelOpenState = true;
    this.templatesCalculatorHelperService.quantityPanelOpenState = true;
  }

  getColumns() {
    let historyColWidth = this.templatesTableService.historicalColumns.length;
    let forecastColWidth = this.templatesTableService.forecastColumns.length;
    return {
      display: 'grid',
      'grid-template-columns': `${historyColWidth}fr 1fr ${forecastColWidth}fr`,
      'grid-template-rows': '1fr',
      gap: '0px 0px',
    };
  }
}
