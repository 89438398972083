import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DimaTableLibraryModule } from '@dima/table-library';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { CsrdTabViewComponent } from './components/csrd-tab-view/csrd-tab-view.component';
import { MeasuringPointActionMenuComponent } from './components/measuring-point-action-menu/measuring-point-action-menu.component';
import { FiscalYearActionMenuComponent } from './components/fiscal-year-action-menu/fiscal-year-action-menu.component';
import { FiscalYearDialogComponent } from './components/fiscal-year-dialog/fiscal-year-dialog.component';
import { MeasuringPointDialogComponent } from './components/measuring-point-dialog/measuring-point-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    DimaTableLibraryModule,
    MatFormFieldModule,
    MatIconModule,
    MatTabsModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    AppRoutingModule
  ],
  declarations: [
    MeasuringPointActionMenuComponent,
    FiscalYearActionMenuComponent,
    CsrdTabViewComponent
  ]
})
export class CsrdModule { }
