import { Component, OnInit } from '@angular/core';
import { OpenIDCService } from '@modules/auth/services/open-idc.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    standalone: false
})
export class SettingsComponent implements OnInit {
  constructor(private openIDCService: OpenIDCService) {}

  ngOnInit(): void {}

  handleLogoutClick() {
    this.openIDCService.logout();
  }
}
