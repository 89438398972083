import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService<T> {
  apiDomain = environment.apiDomain;

  constructor(private http: HttpClient) { }

  search(searchPhrase: string, endpointName: string): Observable<T[]> {
    if (searchPhrase == '' || !searchPhrase) return of([]);
    return this.http.get<T[]>(`${this.apiDomain}/api/${endpointName}?phrase=${searchPhrase}`);
  }
}