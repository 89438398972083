<form [formGroup]="initiativeForm">
    <div class="container gx-0">
        <div class="row">
            <div class="col-12 mt-3 mb-3">
                <p class="fw-bold fs-30">
                    {{ 'scenarios.save-as-initiative-title' | translate }}
                </p>
            </div>
        </div>
        <div class="row justify-content-around align-items-stretch mt-3">
            <div class="col-12">
                <div mat-dialog-content style="padding-top: 0rem !important;">
                    <div class="row main-row-dialog">
                        <div class="col-12 mt-3">
                            <div class="row">

                                <div class="col-12">
                                    <div class="mb-4">
                                        <mat-form-field appearance="fill">
                                            <mat-label>{{
                                                'initiatives.initiative-name' | translate
                                                }}</mat-label>
                                            <input required formControlName="initiativeName" matInput placeholder="{{
                                'initiatives.initiative-name' | translate
                              }}" />
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="mb-4">
                                        <mat-form-field appearance="fill">
                                            <mat-label>{{
                                                'initiatives.description' | translate
                                                }}</mat-label>
                                            <textarea required formControlName="initiativeDescription" matInput
                                                cdkTextareaAutosize
                                                placeholder="{{ 'initiatives.description' | translate }}"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div mat-dialog-actions [align]="'end'">
                    <button mat-raised-button (click)="cancel()" [mat-dialog-close]="false">
                        {{ 'initiatives.cancel' | translate }}
                    </button>

                    <button mat-raised-button color="primary" [disabled]="initiativeForm.invalid"
                        (click)="submitForm()">
                        {{ 'initiatives.save' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>